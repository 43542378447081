import * as echarts from 'echarts';
import React, { useEffect } from 'react';
import { generateUniqueID } from '../../util/UniqueIdGenerator';
import { formatValue } from '../../util/ChartHelper';
import { UpdateFilterData } from '../../util/FilterHelper';
import { FilterData } from '../../Redux/Action';
import { connect, useDispatch } from 'react-redux';
const EBarChart = (props) => {
    const id = generateUniqueID()
    const dispatch = useDispatch();
    const handleFilter = (fields, value) => {
        const updatedFields = UpdateFilterData(props.filterformdata, fields, 'is', value);
        dispatch(FilterData(updatedFields));
    }
    useEffect(() => {
        if (props.seriesData && props.seriesData.length > 0) {
            const chartDom = document.getElementById(id + 'ebm');
            const myChart = echarts.init(chartDom);
            const textcolor = props.theme == 'dark-theme' ? '#fff' : '#000'
            const linecolor = props.theme == 'dark-theme' ? '#595757' : '#d9d9d9'
            const xAxisData = []
            for (let i = 0; i < props.seriesData.length; i++) {
                for (let j = 0; j < props.seriesData[i].Top_agent.buckets.length; j++) {
                    const xvalue = props.seriesData[i].Top_agent.buckets[j].key;
                    xAxisData.push({ x: xvalue })
                }
            }

            const seriesData = props.seriesData.map((item) => ({
                name: item.key,
                type: 'bar',
                barGap: 0,
                barWidth: 10,
                emphasis: {
                    focus: 'series',
                },
                data: item.Top_agent.buckets.map((dataItem) => dataItem.doc_count),
            }));

            const legendData = props.seriesData.map((item) => item.key.toString());

            const option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    },
                    textStyle: {
                        color: "#000"
                    }
                },
                title: {
                    text: 'Agent Name',
                    top: 'bottom',
                    left: 'center',
                    textStyle: {
                        fontWeight: 450,
                        fontSize: 14,
                        color: textcolor,
                        fontfamily: 'RedHatText, helvetica, arial, sans-serif',
                    },
                },
                legend: {
                    type: 'scroll',
                    orient: 'vertical',
                    right: 'right',
                    top: 10,
                    // bottom: 20,
                    data: legendData,
                    itemWidth: 9.5,
                    itemHeight: 10,
                    itemGap: 6.5,
                    tooltip: {
                        trigger: 'item',
                        show: true,
                        textStyle: {
                            color: "#000"
                        }
                    },
                    icon: "rect",
                    pageIconSize: 12,
                    textStyle: {
                        width: 200,
                        overflow: 'truncate',
                        fontSize: 12,
                        color: textcolor
                    }
                    // data: data.legendData
                },

                grid: {
                    left: '2%',
                    right: '15%',
                    bottom: '10%',
                    top: '10%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: true,
                        axisLabel: {
                            interval: 0,
                            rotate: -75,
                            width: 70,
                            overflow: 'truncate',
                            fontSize: 12,
                            color: textcolor
                        },
                        data: [...new Set(xAxisData.map(item => item.x))].slice(0,10),
                        axisTick: {
                            alignWithLabel: true
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        splitLine: {
                            lineStyle: {
                                color: linecolor
                            }
                        },
                        axisLabel: {
                            formatter: formatValue,
                            color: textcolor
                        },
                        axisLine: {
                            show: true
                        },
                    }
                ],
                dataZoom: [
                    {
                        type: 'inside',
                        start: 10,
                        end: 100
                    },
                ],
                color: ['#0066cc', '#f4c145', '#4cb140', '#5752d1', '#ec7a08', '#40199A', '#C8EB79', '#C9190B', '#008BAD'],
                series: seriesData
            };
            option && myChart.setOption(option);
            myChart.on('legendselectchanged', function (param) {

                handleFilter(props.filterFields, param.name)
            });
            const resizeChart = () => {
                if (myChart) { // Check if chart exists before resizing
                  myChart.resize();
                }
              };
    
            window.addEventListener('resize', resizeChart);
            return () => {
                myChart.dispose();
                window.removeEventListener('resize', resizeChart);
            };
        }

    }, [props.seriesData, props.filterFields, props.theme,props.sidebarState,props?.height])

    return <div className="container">
        <div id={id + 'ebm'} style={{ width: '100%', height: props?.height }} ></div>
    </div>;
}

const mapStateToProps = (state) => ({
    dates: { startDate: state.startdate, endDate: state.enddate },
    filterformdata: state.FilterData,
    theme: state.theme,
    sidebarState:state.sidebarstate
    // mapStateToProps content remains unchanged if you have it
});

export default connect(
    mapStateToProps,
    null,
    null,
    { forwardRef: true }
)(EBarChart)
