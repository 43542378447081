import React, { useState, useRef, useEffect } from 'react';
import {
    PageSection,
    PageSectionVariants,
    Tooltip,
    Tabs,
    Tab,
    TabTitleText,
    TabContentBody,

} from '@patternfly/react-core';
import EventBar from '../CommonEventPages/EventBar';
import ThreatIntelDashboard from './ThreatIntelDashboard';
import ThreatIntelEvent from './ThreatIntelEvent';
import AddFilterForm from '../CommonEventPages/AddFilterForm';

const ThreatIntel = () => {
    const [activeTabKey, setActiveTabKey] = useState(0);
    const handleTabClick = (_event, tabIndex) => {
        setActiveTabKey(tabIndex);
    };

    const filterfield = [{
        'fields': 'rule.groups',
        'condition': 'is_one_of',
        'value': ['active_response', 'misp','tip']
    }, {
        'fields': 'rule.groups',
        'condition': 'is_not',
        'value': 'misp_error'

    }
    ]
    return (
        <div>
            <PageSection variant={PageSectionVariants.light} padding={{ default: 'noPadding' }}>
                <Tabs variant='light300' activeKey={activeTabKey} onSelect={handleTabClick} aria-label="Tabs-individual" role="region" isOverflowHorizontal isBox width={40}>
                    <Tab eventKey={0} title={<TabTitleText className='pf-v5-u-font-weight-bold'>Dashboard</TabTitleText>} aria-label="Horizontal overflow content users">
                        {activeTabKey == 0 &&
                            <TabContentBody className='pf-v5-u-p-xs'>
                                <EventBar />
                                <AddFilterForm filter={filterfield} />
                                <ThreatIntelDashboard  hideCard={false}/>
                            </TabContentBody>}
                    </Tab>
                    <Tab eventKey={1} title={<TabTitleText className='pf-v5-u-font-weight-bold'>Events</TabTitleText>} aria-label="Horizontal overflow content users">
                        {activeTabKey == 1 &&
                            <TabContentBody className='pf-v5-u-p-xs'>
                                <EventBar />
                                <AddFilterForm filter={filterfield} />
                                <ThreatIntelEvent />
                            </TabContentBody>}
                    </Tab>
                </Tabs>
            </PageSection>
        </div>
    );
};

export default ThreatIntel;