import * as echarts from 'echarts';
import React, { useEffect } from 'react';
import { generateUniqueID } from 'src/util/UniqueIdGenerator';
import { addInterval, calculateTimeDifference, formatTime, formatTimestamp } from 'src/util/Timeutils';
import { formatValue } from 'src/util/ChartHelper';
import { connect, useDispatch } from 'react-redux';
import { StartEndDate } from 'src/Redux/Action';
const HomeBarChart = (props) => {
  const id = generateUniqueID()
  const dispatch = useDispatch();
  useEffect(() => {
    if (props.EventSeries && props.EventSeries.length > 0) {
      const chartDom = document.getElementById(id + 'barpf');
      const myChart = echarts.init(chartDom);
      const textcolor = props.theme == 'dark-theme' ? '#fff' : '#000'
      const linecolor = props.theme == 'dark-theme' ? '#595757' : '#d9d9d9'
      const { resultInMinutes, resultInHours, resultInDays } = calculateTimeDifference(props.StartDateTime, props.EndDateTime);
      const Label = props.EventLabel;
      const uniqueDatesSet = new Set();
      for (let i = 0; i < props.EventSeries.length; i++) {
        const xvalue = props.EventSeries[i].key_as_string;
        uniqueDatesSet.add(xvalue);
      }

      const xAxisData = []
      const uniqueDatesArray = [...uniqueDatesSet];
      for (let i = 0; i < uniqueDatesArray.length; i++) {
        const { ConvertTimestamp, hours, minutes, seconds } = formatTimestamp(uniqueDatesArray[i]);
        const formattedTime = formatTime(resultInMinutes, resultInHours, resultInDays, hours, minutes, seconds, ConvertTimestamp);
        xAxisData.push({ x: formattedTime });
      }
      // console.log(xAxisData)
      let interval = ''
      let lineheight = ''
       if (Label === 'hour') {
        interval = 1
        lineheight = 30
      }
      const option = {
        animation: false,
        tooltip: {
          trigger: 'axis',
          textStyle: {
            color: "#000"
          },
        },
        grid: {
          left: '2%',
          right: 0,
          bottom: '10%',
          top: '5%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: true,
          axisLabel: { interval: interval, color: textcolor },
          data: xAxisData.map(item => item.x),
          axisTick: {
            alignWithLabel: true
          },
          name: `Timestamp per ${Label}`,
          nameLocation: "middle",
          nameTextStyle: {
            align: "center",
            lineHeight: lineheight,
            fontSize: 14,
            color: textcolor,
          },
        },
        yAxis: {
          axisLine: {
            show: true
          },
          splitLine: {
            lineStyle: {
              color: linecolor
            }
          },
          axisLabel: {
            formatter: formatValue,
            color: textcolor
          }
        },
        color: ['#0066cc'],
        // const ItemStyle=rule_level_filter.rule_level_counts.buckets
        series: [
          {
            name: 'count',
            data: props.EventSeries.map(item => {
              if (item.rule_level_filter.rule_level_counts.buckets.length > 0) {
                return {
                  name: item.key_as_string,
                  value: item.doc_count,
                  itemStyle: {
                    color: '#a90000'
                  }
                }
              } else {
                return {
                  name: item.key_as_string,
                  value: item.doc_count,
                }
              }
            }),
            type: 'bar',
            barWidth: 20,
          }
        ]
      };
      option && myChart.setOption(option);
      myChart.on('click', 'series.bar', function (param) {
        const startDate = param.name
        const compareDates = props.EventSeries.find(item => {
          // const { ConvertTimestamp, hours, minutes, seconds } = formatTimestamp(item.key_as_string);
          // const formattedTime = formatTime(resultInMinutes, resultInHours, resultInDays, hours, minutes, seconds, ConvertTimestamp);
          return item.key_as_string === startDate
        })

        if (compareDates) {
          // console.log("startDate", compareDates.key_as_string)
          let StartDate = new Date(compareDates.key_as_string);
          let clickInterval = ''
          let clickUnit = ''
           if (Label === 'hour') {
            clickInterval = 1
            clickUnit = 'hour'
          }

          let EndDate = addInterval(StartDate, clickInterval, clickUnit);
          // console.log("SD", StartDate, "ED", EndDate);
          dispatch(StartEndDate(StartDate, EndDate, `${StartDate}-${EndDate}`));
          window.location.href = "#/security-events";
        }
      });
      const resizeChart = () => {
        if (myChart) { // Check if chart exists before resizing
          myChart.resize();
        }
      };

    window.addEventListener('resize', resizeChart);
    return () => {
        myChart.dispose();
        window.removeEventListener('resize', resizeChart);
    };
    }
  }, [props.EventSeries, props.EventLabel, props.StartDateTime, 
    props.EndDateTime, props.TotalEvents, props.CrticalCount, 
    props.TDCount, props.theme,props.sidebarState])

  return <> <div className="container">
    <div id={id + 'barpf'} style={{ width: '100%', height: '180px' }} > </div>
  </div>
  </>
}
const mapStateToProps = (state) => ({
  dates: { startDate: state.startdate, endDate: state.enddate },
  filterformdata: state.FilterData,
  theme: state.theme,
  sidebarState:state.sidebarstate
  // mapStateToProps content remains unchanged if you have it
});

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(HomeBarChart)