import React, { useEffect, useState } from "react";
import {
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    Table,
    InnerScrollContainer,
} from "@patternfly/react-table";
import {
    Badge,
    Button,
    Divider,
    Icon,
    Pagination,
    SearchInput,
    Text,
    TextVariants,
    Toolbar,
    ToolbarContent,
    ToolbarItem,
    Tooltip,
    Truncate
} from "@patternfly/react-core";
import { connect } from "react-redux";
import { RedoIcon } from '@patternfly/react-icons';
import dbApi from 'src/services/DbApi'
import { Loader } from "src/util/Loadingstate";
import { Emptystatefornodata } from "src/util/EmptyStateCard";

const Packages = (props) => {
    const columns = {
        name: 'Name',
        architecture: 'Architecture',
        version: 'Version',
        vendor: 'Vendor',
    }

    const [packageload, setPackageLoad] = useState(false)
    const [rows, setRows] = useState([]);
    const [activeSortIndex, setActiveSortIndex] = useState(0);
    const [activeSortDirection, setActiveSortDirection] = useState('asc');
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10)
    const [selectedRowName, setSelectedRowName] = useState('');
    const [searchValue, setSearchValue] = useState('')
    const [inputValue, setInputValue] = useState('')
    const [totalRecord, setTotalRecord] = useState(0)

    const getPackageLog = async () => {
        let sort = ''
        if (activeSortDirection === 'asc') {
            sort = `+${Object.keys(columns)[activeSortIndex]}`
        }
        else {
            sort = `-${Object.keys(columns)[activeSortIndex]}`
        }
        let data = {
            agentId: props.agentId,
            offset: (perPage * page) - perPage,
            limit: perPage,
            search: searchValue,
            sort: sort,
        }
        try {
            setPackageLoad(true)
            let res = await dbApi.postApi("Opensearch/Packages", data)
            console.log(res)
            let PackageLog = []
            let totalcount = 0
            if (Object.keys(res.Packages).length > 0) {
                let log = res.Packages.data
                PackageLog = log.affected_items
                totalcount = log.total_affected_items
                setRows(PackageLog)
                setTotalRecord(totalcount)
            }
            else {
                setRows(PackageLog)
                setTotalRecord(totalcount)
            }
            setPackageLoad(false)
        } catch (error) {
            console.log(error)
            setPackageLoad(false)
        }
    }

    const onSearch = event => {
        if (event.key && event.key !== 'Enter') {
            return;
        }
        setSearchValue(inputValue)
    }

    useEffect(() => {
        getPackageLog()
    }, [props.agentId, page, perPage, activeSortDirection, activeSortIndex, searchValue])


    //sorting
    const onSort = (event, index, direction) => {
        setActiveSortIndex(index);
        setActiveSortDirection(direction);
    };

    //Pagination
    const handleSetPage = (_evt, newPage) => {
        setPage(newPage);
    };
    const handlePerPageSelect = (_evt, newPerPage,newPage) => {
        setPerPage(newPerPage);
        setPage(newPage);
    };
    const renderPagination = (variant) =>
        <Pagination
            itemCount={totalRecord}
            page={page}
            perPage={perPage}
            onSetPage={handleSetPage}
            onPerPageSelect={handlePerPageSelect}
            variant={variant}
        />;

    const toolbarItems = <Toolbar id="sortable-toolbar">
        <ToolbarContent>
            <ToolbarItem>
                <Text component={TextVariants.h1} className='pf-v5-u-font-weight-bold'>
                    Packages ({totalRecord})
                </Text>
            </ToolbarItem>
            <ToolbarItem align={{ default: 'alignRight' }}>
                <Button variant="secondary" size="sm" icon={<RedoIcon />} onClick={() => {getPackageLog() }}/>
            </ToolbarItem>
        </ToolbarContent>
    </Toolbar>
    return (
        <React.Fragment>
            <>
                {toolbarItems}
                <Divider />
                <SearchInput
                    className="pf-v5-u-p-sm"
                    placeholder="Search..."
                    value={inputValue}
                    onChange={(_event, value) => setInputValue(value)}
                    onSearch={onSearch}
                    onClear={() => { setInputValue(''); setSearchValue('') }} />

                {packageload ? <Loader /> :
                    <>
                        <InnerScrollContainer>
                            <Table variant="compact" aria-label="inventory" id="inventory-table" isStickyHeader gridBreakPoint="grid-md">
                                <Thead >
                                    <Tr key={'NS-table'}>
                                        {Object.values(columns).map((column, columnIndex) => {
                                            const stickyColumnsIndex = [0, 1];
                                            const isSticky = stickyColumnsIndex.includes(columnIndex);
                                            const sortParams = {
                                                sort: {
                                                    sortBy: {
                                                        index: activeSortIndex,
                                                        direction: activeSortDirection
                                                    },
                                                    onSort,
                                                    columnIndex
                                                }
                                            };
                                            return <Th key={columnIndex} isStickyColumn={isSticky} {...sortParams}>{column}</Th>
                                        }
                                        )}
                                    </Tr>
                                </Thead>
                                <Tbody key={'tablebody'}>
                                    {rows.length > 0 ?
                                        rows && rows.map((row, rowIndex) => {
                                            return (
                                                <Tr key={rowIndex + 'row'}
                                                    onRowClick={() => setSelectedRowName(rowIndex)}
                                                    isSelectable
                                                    isClickable
                                                    isRowSelected={selectedRowName === rowIndex}
                                                >
                                                    <Td width={40} dataLabel={'Name'} key={'packagename' + rowIndex}>{row?.name}</Td>
                                                    <Td width={20} dataLabel={'Architecture'} key={'packagearchitect' + rowIndex} >{row?.architecture}</Td>
                                                    <Td width={10} dataLabel={'Version'} key={'packageversion' + rowIndex}>{row?.version}</Td>
                                                    <Td width={30} dataLabel={'Vendor'} key={'packageVendor' + rowIndex}>{row?.vendor}</Td>
                                                </Tr>

                                            )
                                        }) :
                                        <Tr key='empty-row'>
                                            <Td colSpan={10} key='empty-data'>
                                                <Emptystatefornodata />
                                            </Td>
                                        </Tr>
                                    }
                                </Tbody>
                            </Table>

                        </InnerScrollContainer>
                        {renderPagination('bottom', true)}
                    </>
                }
            </>
        </React.Fragment>
    );
}
const mapStateToProps = (state) => ({
    dates: { startDate: state.startdate, endDate: state.enddate },
    filterformdata: state.FilterData,
    // mapStateToProps content remains unchanged if you have it
});

export default connect(
    mapStateToProps,
    null,
    null,
    { forwardRef: true }
)(Packages)
