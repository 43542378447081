import {
    Toolbar,
    ToolbarContent,
    ToolbarItem,
    Icon,
    Pagination,
    SearchInput,
    Flex,
    FlexItem,
    Badge,
    Tooltip,
    Text,
    TextVariants,
    Button,
    ToolbarGroup,
    Select,
    MenuToggle,
    SelectGroup,
    SelectList,
    SelectOption,
} from '@patternfly/react-core'
import { ExportIcon, EyeIcon, RedoIcon, SortAmountDownIcon, TrashAltIcon } from '@patternfly/react-icons'
import { Table, Tbody, Td, Th, Thead, Tr } from '@patternfly/react-table'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import dbApi from 'src/services/DbApi'
import { Emptystatefornodata } from 'src/util/EmptyStateCard'
import { Loader } from 'src/util/Loadingstate'
import ConfirmBox from '../ConfirmBox/ConfirmBox'
import { alertBoxData } from 'src/Redux/Action'
import { useDispatch } from 'react-redux'

const GroupAgentTable = (props) => {
    const dispatch = useDispatch()
    const defaultColumns = {
        id: 'ID',
        name: "Name",
        ip: "IP Address",
        os: 'Operating System',
        version: 'Version',
        status: 'Status',
        actions: "Action",
    };
    const [columns, setColumns] = useState(defaultColumns);
    const [rows, setRows] = useState([]);
    const [selectedRowName, setSelectedRowName] = useState('');
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [activeSortIndex, setActiveSortIndex] = useState(0);
    const [totalItem, setTotalItem] = useState(0)
    const [isSortDropdownOpen, setIsSortDropdownOpen] = useState(false);
    const [activeSortDirection, setActiveSortDirection] = useState('desc');
    const [inputValue, setInputValue] = useState('')
    const [searchValue, setSearchValue] = useState('');
    const [isloadagents, setIsloadAgents] = useState(false)
    const [confirmModalData, setConfirmBox] = useState({
        show: false, msg: '',
        funCall: () => { }, funParam: {}, title: '',
        position: '',
        iconvariant: '',
        submitbtnvariant: '',
    })

    const excludeOrder = ['Action']
    const getAgentofGroup = async () => {
        let sort = ''
        if (activeSortDirection === 'asc') {
            sort = `+${Object.keys(columns)[activeSortIndex]}`
        }
        else {
            sort = `-${Object.keys(columns)[activeSortIndex]}`
        }
        let param = {
            offset: (perPage * page) - perPage,
            limit: perPage,
            search: searchValue,
            sort: sort,
            name: props.GroupName
        }
        try {
            setIsloadAgents(true)
            let res = await dbApi.postApi("Opensearch/Agent_Group", param)
            console.log(res)
            let agents = []
            if (res.Agent_List.length > 0) {
                agents = res.Agent_List
                setRows(agents)
                setTotalItem(res.total)
            } else {
                setRows([])
                setTotalItem(0)
            }
            setIsloadAgents(false)
        } catch (error) {
            console.log(error)
            setIsloadAgents(false)
        }
    }

    useEffect(() => {
        getAgentofGroup()
    }, [page,
        perPage,
        activeSortIndex,
        activeSortDirection, searchValue, props.GroupName])


    const onSort = (event, index, direction) => {
        setActiveSortIndex(index);
        setActiveSortDirection(direction);
    };
    const handleSetPage = (_evt, newPage) => {
        setPage(newPage);
    };
    const handlePerPageSelect = (_evt, newPerPage) => {
        setPerPage(newPerPage);
    };

    const onSearch = event => {
        if (event.key && event.key !== 'Enter') {
            return;
        }
        setSearchValue(inputValue)
        setPage(1)
    }
    const renderPagination = (variant) => (
        <Pagination
            itemCount={totalItem}
            page={page}
            perPage={perPage}
            onSetPage={handleSetPage}
            onPerPageSelect={handlePerPageSelect}
            variant={variant}
            titles={{
                paginationAriaLabel: `${variant} pagination`
            }}
        />
    );
    const tabletoolbar = <React.Fragment>
        <Toolbar>
            <ToolbarContent>
                <ToolbarItem >
                    <Text component={TextVariants.h1} className="pf-v5-u-font-weight-bold">Agents ({totalItem})
                        <br />
                        <Text component={TextVariants.small}>From here you can list and manage your agents</Text>
                    </Text>
                </ToolbarItem>
                <ToolbarGroup align={{ default: 'alignRight' }}>
                    <ToolbarItem spacer={{ default: 'spacerSm' }}>
                        <Button variant="link" icon={<RedoIcon />} onClick={() => { getAgentofGroup() }}>Refresh</Button>
                    </ToolbarItem>
                    <ToolbarItem >
                        <Button variant="link" icon={<ExportIcon />} onClick={() => { }}>Export Formatted</Button>
                    </ToolbarItem>
                </ToolbarGroup>
            </ToolbarContent>
        </Toolbar>
        <Toolbar>
            <ToolbarContent>
                <ToolbarItem visibility={{ md: 'hidden' }}>
                    <Select isOpen={isSortDropdownOpen} selected={[activeSortDirection, activeSortIndex]} onOpenChange={isOpen => setIsSortDropdownOpen(isOpen)} onSelect={(event, value) => {
                        if (value === 'asc' || value === 'desc') {
                            onSort(event, activeSortIndex, value);
                        } else {
                            onSort(event, value, activeSortDirection !== 'none' ? activeSortDirection : 'asc');
                        }
                    }} toggle={toggleRef => <MenuToggle ref={toggleRef} onClick={() => setIsSortDropdownOpen(!isSortDropdownOpen)} isExpanded={isSortDropdownOpen} variant="plain">
                        <SortAmountDownIcon />
                    </MenuToggle>}>
                        <SelectGroup label="Sort column">
                            <SelectList>
                                {Object.keys(columns).map((column, columnIndex) => <SelectOption key={column} value={columnIndex} isSelected={activeSortIndex === columnIndex}>
                                    {column}
                                </SelectOption>)}
                            </SelectList>
                        </SelectGroup>
                        <SelectGroup label="Sort direction">
                            <SelectList>
                                <SelectOption isSelected={activeSortDirection === 'asc'} value="asc" key="ascending">
                                    Ascending
                                </SelectOption>
                                <SelectOption isSelected={activeSortDirection === 'desc'} value="desc" key="descending">
                                    Descending
                                </SelectOption>
                            </SelectList>
                        </SelectGroup>
                    </Select>
                </ToolbarItem>
                <ToolbarItem style={{ width: '60%' }} variant="search-filter">
                    <SearchInput placeholder="Search..."
                        value={inputValue}
                        onChange={(_event, value) => setInputValue(value)}
                        onSearch={onSearch}
                        onClear={() => { setSearchValue(''); setInputValue('') }} />
                </ToolbarItem>
                <ToolbarItem align={{ default: 'alignRight' }}>
                    <ToolbarItem> {renderPagination('top')}</ToolbarItem>
                </ToolbarItem>
            </ToolbarContent>
        </Toolbar>
    </React.Fragment>

    const renderStatuscolumn = (status) => {
        let badgeStyle = {};
        if (status == "active") {
            badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#3e8635" };
        }
        else if (status == "disconnected") {
            badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#A30000 " };
        }

        return (
            <Badge style={badgeStyle} className='pf-v5-u-color-light-100'>{status}</Badge>
        );
    };

    const finalDelete = async (deletedid) => {
        try {
            let param = {
                Agent_ID: deletedid,
                Group_Name: props.GroupName
            }
            let res = await dbApi.postApi("Opensearch/Remove_Agent_Assigned_Group ", param)
            console.log(res)
            if (!res.error) {
                dispatch(alertBoxData([{ title: 'Agent removed from the group.', variant: 'success' }]));
                getAgentofGroup()
            }
            else {
                dispatch(alertBoxData([{ title: res.message, variant: 'danger' }]));
            }
        } catch (error) {
            console.log(error)
        }
        // Opensearch/Agent_Assigned_To_Group
    }

    const onDelete = (id, agentname) => {
        setConfirmBox({
            show: true,
            title: `Remove ${agentname} agent from this group?`,
            msg: "",
            funCall: () => { finalDelete(id) },
            funParam: {},
            position: 'default',
            iconvariant: 'warning',
            submitbtnvariant: 'danger',
        })
    }

    return (<React.Fragment>
        <ConfirmBox confirmModal={confirmModalData} />
        {isloadagents ? <Loader /> :
            <>
                {tabletoolbar}
                <Table variant='compact'>
                    <Thead>
                        <Tr>
                            {Object.values(columns).map((column, columnIndex) => {
                                const stickyColumnsIndex = [0, 1];
                                const isSticky = stickyColumnsIndex.includes(columnIndex);
                                const sortParams = {
                                    sort: {
                                        sortBy: {
                                            index: activeSortIndex,
                                            direction: activeSortDirection
                                        },
                                        onSort,
                                        columnIndex
                                    }
                                };
                                return !excludeOrder.includes(column) ? <Th key={columnIndex} {...sortParams} isStickyColumn={isSticky} >{column}</Th> : <Th key={columnIndex} isStickyColumn={isSticky}>{column}</Th>;
                            })}
                        </Tr>
                    </Thead>
                    <Tbody>
                        {rows.length > 0 ?
                            rows && rows.map((row, rowIndex) => {
                                return (
                                    <>
                                        <Tr key={rowIndex + 'agentid'}
                                            onRowClick={() => setSelectedRowName(rowIndex)}
                                            isSelectable
                                            isClickable
                                            isRowSelected={selectedRowName === rowIndex}
                                        >

                                            <Td dataLabel={columns.id} key={rowIndex + 'id'}>{row?.id}</Td>
                                            <Td dataLabel={columns.name} key={rowIndex + 'name'}>{row?.name}</Td>
                                            <Td dataLabel={columns.ip} key={rowIndex + 'ip'}>{row?.ip}</Td>
                                            <Td dataLabel={columns.os} key={rowIndex + 'osname'}>{row?.os?.name}</Td>
                                            <Td dataLabel={columns.version} key={rowIndex + 'osversion'}> {row?.os?.version ? row.os.version.replace("Wazuh ", "") : ""}</Td>
                                            <Td dataLabel={columns.status} key={rowIndex + 'status'}>{renderStatuscolumn(row?.status)}</Td>
                                            <Td dataLabel={columns.actions} key={rowIndex + 'action'} isActionCell>
                                                <div>
                                                    <Flex spaceItems={{ default: 'spaceItemsSm' }}>
                                                        <FlexItem> <Link to={"/individual-agent-dashboard/" + row?.name + '/' + row?.id}><Tooltip content={'Go to the agent'} ><Icon status="info"><EyeIcon /></Icon></Tooltip> </Link></FlexItem>
                                                        <FlexItem> <Tooltip content={'Remove agent from this group'} ><Icon status="danger" onClick={() => onDelete(row?.id, row?.name)}><TrashAltIcon /></Icon></Tooltip></FlexItem>
                                                    </Flex>
                                                </div>


                                            </Td>

                                        </Tr>
                                    </>
                                )
                            })
                            :
                            <Tr key='empty-row'>
                                <Td colSpan={10} key='empty-data'>
                                    <Emptystatefornodata />
                                </Td>
                            </Tr>
                        }

                    </Tbody>
                </Table>
                {renderPagination('bottom')}
            </>
        }
    </React.Fragment>

    )
}

export default GroupAgentTable