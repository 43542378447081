
import React from 'react'
import { Navigate } from "react-router-dom";
const Protectedroutes = (props) => {
  const token = localStorage.getItem('token')
  // console.log(localStorage.getItem('token'))
  if (token == null || token == 'undefined' || token == '') {
    return <Navigate to="/" replace />;
  } else if (!props?.role.includes(localStorage.getItem('role'))) {
    return <Navigate to="/home" replace />;
  }
  return props.children;
};
export default Protectedroutes;
