import React, { useState, useEffect } from 'react';
import { Modal, ModalVariant, Button } from '@patternfly/react-core';

const ConfirmBox = (props) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const handleModalToggle = (CancelCall = 'No') => {
    setIsModalOpen(isModalOpen => !isModalOpen);
    if (CancelCall == 'Yes' && typeof props.confirmModal.funCancel != 'undefined') {
      props.confirmModal.funCancel()
    }
  };
  React.useEffect(() => {
    var DataConfirmBox = props.confirmModal
    if (typeof DataConfirmBox !== 'undefined' && typeof DataConfirmBox.show !== 'undefined') {
      setIsModalOpen(DataConfirmBox.show);
    }
  }, [props?.confirmModal])

  return <React.Fragment key="conform-box">
    <Modal
      position={props.confirmModal.position}
      className='confirmBox'
      title={props.confirmModal.title}
      titleIconVariant={props?.confirmModal?.iconvariant}
      aria-label="No header/footer modal"
      aria-describedby="modal-no-header-description"
      variant={ModalVariant.small}
      isOpen={isModalOpen}
      onClose={() => handleModalToggle('Yes')}

      // {...(ruleCharacters === 'success' && {
      //     labelInfo: passStrLabel
      //   })}
     {...(props?.confirmModal?.action!== 'hide' && {actions:[
        <Button
          key="confirm"
          variant={props.confirmModal.submitbtnvariant}
          onClick={() => {
            props?.confirmModal?.funCall(props?.confirmModal?.funParam);
            handleModalToggle();
          }}
        >
          Confirm
        </Button>,
        <Button key="cancel" variant="link" onClick={() => handleModalToggle('Yes')}>
          Cancel
        </Button>]})}
        
        >
      {props.confirmModal.msg}
    </Modal>
  </React.Fragment>;
};
export default React.memo(ConfirmBox)