import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts/core';
import { generateUniqueID } from 'src/util/UniqueIdGenerator';
import { connect, useDispatch } from 'react-redux';
import { UpdateFilterData } from 'src/util/FilterHelper';
import { FilterData } from 'src/Redux/Action';
import { Emptystatefornodata } from 'src/util/EmptyStateCard';

const GdprLastAlerts = (props) => {
    const id = generateUniqueID()
    const dispatch = useDispatch();
    const handleFilter = (fields, value) => {
        const updatedFields = UpdateFilterData(props.filterformdata, fields, 'is', value);
        dispatch(FilterData(updatedFields));
    }
    useEffect(() => {
        if (props.SeriesData && props.SeriesData.length > 0) {
            const chartDom = document.getElementById(id + 'gdprla');
            const textcolor = props.theme == 'dark-theme' ? '#fff' : '#000'
            const linecolor = props.theme == 'dark-theme' ? '#595757' : '#d9d9d9'
            const myChart = echarts.init(chartDom);

            const seriesData = props.SeriesData.flatMap(requirement =>
                requirement.Top_Agent.map(agent => {
                    const value = agent.doc_count;
                    return [requirement.key, agent.key, value];
                })
            );
            // const seriesData = props.SeriesData.flatMap(req => {
            //     // Access agents data within the Top_Agent array
            //     const agents = req.Top_Agent.slice(0,10);
            //     return agents.map(agent => {
            //         const agentName = agent.key;
            //         const value = req.key;
            //         const docCount = agent.doc_count;

            //         return [value, docCount, agentName]; // Assuming category is empty
            //     });
            // });

            const colors = ['#E7F1FA', '#BEE1F4', '#73BCF7', '#2B9AF3', '#0066CC', '#004080', '#002952']
            const maxcount = props.SeriesData.length > 0
                ? Math.max(...props.SeriesData.map(bucket => parseFloat(bucket.doc_count)))
                : 380000;

            const mincount = props.SeriesData.length > 0
                ? Math.min(...props.SeriesData.map(bucket => parseFloat(bucket.doc_count)))
                : 380000;
            // Extract unique requirements and agent names for the axis labels
            const uniqueRequirements = [...new Set(seriesData.map(item => item[0]))];
            const uniqueAgents = [...new Set(seriesData.map(item => item[1]))];

            const option = {
                tooltip: {
                    position: 'top',
                    position: [50, 50],
                    textStyle: {
                        color: "#000"
                    },
                    formatter: function (params) {
                        return `<b>Requirement:</b> ${params.value[0]}<br/><b>Count:</b> ${params.value[2]}<br/><b>Agent:</b> ${params.value[1]}`;
                    }
                },
                animation: false,
                title: {
                    text: `Requirements`,
                    top: 'bottom',
                    left: 'center',
                    textStyle: {
                        fontWeight: 450,
                        fontSize: 14,
                        color: textcolor,
                        fontfamily: 'RedHatText, helvetica, arial, sans-serif',
                    },
                },
                grid: {
                    left: '0%',
                    right: '12%',
                    bottom: '10%',
                    top: '5%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    axisLabel: { rotate: -40, color: textcolor },
                    data: uniqueRequirements.slice(0, 7),
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: linecolor
                        }
                    },
                },
                yAxis: {
                    type: 'category',
                    data: uniqueAgents.slice(0,10),
                    axisLabel: {
                        interval: 0,
                        rotate: 20,
                        width: 70,
                        overflow: 'truncate',
                        fontSize: 12,
                        color: textcolor
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: linecolor
                        }
                    },
                    axisLine: {
                        show: true
                    },
                },
                visualMap: {
                    min: mincount,
                    max: maxcount,
                    textStyle: {
                        color: textcolor
                    },
                    calculable: true,
                    orient: 'vertical',
                    left: 'right',
                    bottom: '38%',
                    inRange: {
                        color: colors
                    },
                    text: ['Count'],
                    show: true,
                },
                series: [{
                    name: 'Count',
                    type: 'heatmap',
                    data: seriesData,
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                        }
                    },

                    itemStyle: {
                        color: params => {
                            const value = params.value[2];
                            const colorPalette = colors
                            const step = 40;  // Define the step size for color change

                            // Determine the color index based on the value
                            const colorIndex = Math.floor(value / step) % colorPalette.length;

                            return colorPalette[colorIndex];
                        },
                        borderColor: '#B8BBBE',  // Change border color as needed
                        borderWidth: 1
                    }
                }]
            };
            option && myChart.setOption(option);
            myChart.on('click', function (params) {
                const Fields = props.filterFields
                handleFilter(Fields, params.value[0])
            });
            const resizeChart = () => {
                if (myChart) { // Check if chart exists before resizing
                    myChart.resize();
                }
            };

            window.addEventListener('resize', resizeChart);
            return () => {
                myChart.dispose();
                window.removeEventListener('resize', resizeChart);
            };
        }
    }, [props.SeriesData, props.filterFields, props.theme, props.sidebarState, props.height]);

    return <> <div className="container">
        {props.SeriesData.length == 0 ?
            (<>
                <div id={id + 'gdprla'} style={{ width: '1px', height: '1px' }}> </div>
                < Emptystatefornodata />
            </>)
            : (
                <div id={id + 'gdprla'} style={{ width: '100%', height: props.height }} > </div>
            )
        }
    </div>
    </>
};


const mapStateToProps = (state) => ({
    dates: { startDate: state.startdate, endDate: state.enddate },
    filterformdata: state.FilterData,
    theme: state.theme,
    sidebarState: state.sidebarstate
    // mapStateToProps content remains unchanged if you have it
});

export default connect(
    mapStateToProps,
    null,
    null,
    { forwardRef: true }
)(GdprLastAlerts)