import * as echarts from 'echarts';
import React, { useEffect } from 'react';
import { generateUniqueID } from 'src/util/UniqueIdGenerator';
import { UpdateFilterData } from 'src/util/FilterHelper';
import { FilterData } from 'src/Redux/Action';
import { connect, useDispatch } from 'react-redux';
const OsQueyDonutchart = (props) => {
    const id = generateUniqueID()
    const dispatch = useDispatch();
    const handleFilter = (fields, value) => {
        const updatedFields = UpdateFilterData(props.filterformdata, fields, 'is', value);
        dispatch(FilterData(updatedFields));
    }
    useEffect(() => {
        const chartDom = document.getElementById(id + 'osquerydonut');
        const myChart = echarts.init(chartDom);
        const textcolor = props.theme == 'dark-theme' ? '#fff' : '#000'
        const bordercolor = props.theme == 'dark-theme' ? '#262525' : '#fff'
        const option = {
            tooltip: {
                trigger: 'item',
                textStyle: {
                    color: "#000"
                },
                position: [40, 90],
            },

            legend: {
                orient: 'vertical',
                right: 3,
                top: 0,
                // left:190,
                itemWidth: 9.5,
                itemHeight: 10,
                itemGap: 6.5,
                icon: "rect",
                tooltip: {
                    trigger: 'item',
                    show: true,
                    position: [50, 100],
                    textStyle: {
                        color: "#000"
                    }
                },
                textStyle: {
                    width: 100,
                    overflow: 'truncate',
                    fontSize: 12,
                    color: textcolor
                }
                // data: data.legendData
            },
            color: ['#8BC1F7', '#06C', '#002F5D', '#519DE9', '#004B95'],
            series: [
                {
                    type: 'pie',
                    top: 40,
                    radius: ['80%', '70%'],
                    center: ['37%', '55%'],
                    avoidLabelOverlap: false,
                    itemStyle: {
                        borderRadius: 0,
                        borderColor: bordercolor,
                        borderWidth: 2
                    },
                    label: {
                        show: false,
                        position: 'center',
                    },
                    emphasis: {
                        label: {
                            show: false,
                        }
                    },
                    labelLine: {
                        show: false
                    },
                    data: props.OsQueryData.map((bucket) => ({
                        value: bucket.doc_count, // Use log scale for word size
                        name: bucket.key,
                    })
                    )
                }
            ]
        };

        option && myChart.setOption(option);
        myChart.on('legendselectchanged', function (param) {
            handleFilter(props.filterFields, param.name)
        });
        const resizeChart = () => {
            if (myChart) { // Check if chart exists before resizing
                myChart.resize();
            }
        };

        window.addEventListener('resize', resizeChart);
        return () => {
            myChart.dispose();
            window.removeEventListener('resize', resizeChart);
        };

    }, [props.height, props.OsQueryData, props.filterFields, props.theme, props.sidebarState])

    return <div className="container">
        <div id={id + 'osquerydonut'} style={{ width: '100%', height: props.height }} ></div>
    </div>;
}
const mapStateToProps = (state) => ({
    dates: { startDate: state.startdate, endDate: state.enddate },
    filterformdata: state.FilterData,
    theme: state.theme,
    sidebarState: state.sidebarstate
    // mapStateToProps content remains unchanged if you have it
});

export default connect(
    mapStateToProps,
    null,
    null,
    { forwardRef: true }
)(OsQueyDonutchart)