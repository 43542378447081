import React, { useState, useRef, useEffect } from 'react';
import {
    PageSection,
    PageSectionVariants,
    Tabs,
    Tab,
    TabTitleText,
    TabContentBody,
    Toolbar,
    ToolbarItem,
    Switch,
} from '@patternfly/react-core';
import EventBar from '../CommonEventPages/EventBar';
import SecurityDashboard from './SecurityDashboard';
import SecurityEvents from './SecurityEvents';
import AddFilterForm from '../CommonEventPages/AddFilterForm';
import AgentHeatMap from './AgentHeatMap';
import AlertBox from '../AlertBox/AlertBox';

const SecurityDashEvents = () => {
    const [activeTabKey, setActiveTabKey] = useState(0);
    const handleTabClick = (_event, tabIndex) => {
        setActiveTabKey(tabIndex);
    };
    const filterfield = [{
        "fields": 'rule.groups',
        'condition': 'is_not',
        'value': 'zeek'
    }]

    return (
        <div>
            <PageSection variant={PageSectionVariants.light} padding={{ default: 'noPadding' }}>
                {/* <Toolbar><ToolbarItem > <AlertBox /> </ToolbarItem> </Toolbar> */}
                <Tabs variant='light300' id="sec-tabs" activeKey={activeTabKey} onSelect={handleTabClick} aria-label="Tabs-individual" role="region" isOverflowHorizontal isBox width={40}>
                    <Tab eventKey={0} title={<TabTitleText className='pf-v5-u-font-weight-bold'>Dashboard</TabTitleText>} aria-label="Horizontal overflow content users">
                        {activeTabKey == 0 &&
                            <TabContentBody className='pf-v5-u-p-xs'>
                                <EventBar />
                                <AddFilterForm filter={filterfield} />
                                <SecurityDashboard />
                            </TabContentBody>
                        }
                    </Tab>
                    <Tab eventKey={1} title={<TabTitleText className='pf-v5-u-font-weight-bold'>Events</TabTitleText>} aria-label="Horizontal overflow content users">
                        {activeTabKey == 1 &&
                            <TabContentBody className='pf-v5-u-p-xs'>
                                <EventBar />
                                <AddFilterForm filter={filterfield} />
                                <SecurityEvents />
                            </TabContentBody>
                        }
                    </Tab>
                    <Tab eventKey={2} title={<TabTitleText className='pf-v5-u-font-weight-bold'>Heat Map</TabTitleText>} aria-label="Horizontal overflow content users">
                        {activeTabKey == 2 &&
                            <TabContentBody className='pf-v5-u-p-xs'>
                                <div id="search-filter">
                                    <EventBar />
                                    <AddFilterForm filter={filterfield} />
                                </div>
                                <AgentHeatMap />
                            </TabContentBody>
                        }
                    </Tab>
                    {/* <Tab eventKey={3} title={<TabTitleText className='pf-v5-u-font-weight-bold'>Test Trafficgeo</TabTitleText>} aria-label="Horizontal overflow content users">
                        {activeTabKey == 3 &&
                            <TabContentBody className='pf-v5-u-p-xs'>
                                <EventBar />
                                <AddFilterForm filter={filterfield} />

                                Real Time Dashboard
                            </TabContentBody>
                        }
                    </Tab> */}

                </Tabs>
            </PageSection>
        </div>
    );
};

export default SecurityDashEvents;
