import * as React from "react";
import {
  ActionList,
  ActionListItem,
  Button,
  ButtonVariant,
  Chip,
  ChipGroup,
  DescriptionList,
  DescriptionListGroup,
  DescriptionListTerm,
  Divider,
  Dropdown,
  DropdownList,
  DropdownItem,
  Flex,
  FlexItem,
  Form,
  FormGroup,
  Grid,
  GridItem,
  InputGroup,
  InputGroupItem,
  MenuToggle,
  MenuToggleElement,
  MenuSearch,
  MenuSearchInput,
  Modal,
  ModalVariant,
  Popover,
  SearchInput,
  // Select,
  // SelectOption,
  SelectList,
  Switch,
  Text,
  TextArea,
  TextInput,
  TextVariants,
} from "@patternfly/react-core";
import {
  // ContextSelector,
  // ContextSelectorItem,
  SelectVariant,
  Select,
  SelectOption,
} from "@patternfly/react-core/deprecated";
import {
  SaveIcon,
  SearchIcon,
  RedoIcon,
  PlusIcon,
  AngleDownIcon,
  AngleLeftIcon,
  AngleRightIcon,
  OutlinedCalendarAltIcon,
  PlayIcon,
} from "@patternfly/react-icons/dist/esm/icons";
// import Events from "./Events";
import dbApi from '../../services/DbApi'

import { connect } from "react-redux";
import { TimeHandler } from "../../util/Timeutils";
class BkEventBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      currentTime: "",
      entire_startDate: "",
      refreshCounter: 0,
      isOpen: false,
      isOpenRefreshTime: false,
      isOpenSelectedTime: false,
      selected: null,
      isModalOpen: false,
      isChecked: true,
      isExpanded: false,
      selectedItem: "Last 24 hours",
      selectedTime: 24,
      selectedUnit: "hours",
      selectedTimeUnit: "seconds",
      isPopoverOpen: false,
      selectedFields: "select field name",
      selectedOperator: "select",
      isSelectedOperator: false,
      isDisabledselectOperator: true,
      selectedValue: "",
      isSelectedValue: false,
      isDisabledselectValue: true,
      Fielddata: [],
      isFilterPopoverOpen: false,
      addedFields: [],
      EventsFunction: null,
      timeValue: "",
      unitValue: "",
      InputValue: "Last",
      searchField: "",
      searchValue: "",
      filteredFields: "",
      FilterValue: "",
      operatorFilter: [],
      // StartDate:startDate,
      // EndDate:endDate,
      fields: {
        savequery: {
          name: { value: "", error: "", status: "" },
          desc: { value: "", error: "", status: "" },
        },
      },
    };
    // this.popoverRef = React.createRef();
    this.Timelist = [
      { term: "All Times" },
      { term: "Today" },
      { term: "This week" },
      { term: "Last 15 minutes" },
      { term: "Last 30 minutes" },
      { term: "Last 1 hour" },
      { term: "Last 24 hours" },
      { term: "Last 7 days" },
      { term: "Last 30 days" },
      { term: "Last 90 days" },
      { term: "Last 1 year" },

    ];
    this.fieldoption = [
      <SelectOption key={0} value="agent.id" />,
      <SelectOption key={1} value="agent.name" />,
      <SelectOption key={2} value="rule.id" />,
    ];

    this.FilterOperator = [
      <SelectOption key={0} value="is" >is</SelectOption>,
      <SelectOption key={1} value="is_not" >is_not</SelectOption>,
      <SelectOption key={2} value="is_one_of" >is_one_of</SelectOption>,
      <SelectOption key={3} value="is_not_one_of" >is_not_one_of</SelectOption>,
      <SelectOption key={4} value="exists" >exists</SelectOption>,
      <SelectOption key={5} value="does_not_exist" >does_not_exist</SelectOption>,
    ];
    this.FilterValue = [
      <SelectOption key={0} value="proxygw.indrasakha.local" />,
      <SelectOption key={1} value="MUM-INFIZI-2" />,
      <SelectOption key={2} value="05" />,
      <SelectOption key={3} value="013" />,
      <SelectOption key={4} value="014" />,
    ];

    this.timeoptions = [
      <SelectOption key={0} value="seconds" />,
      <SelectOption key={1} value="minutes" />,
      <SelectOption key={2} value="hours" />,
    ];
    this.unitOptions = [
      <SelectOption key={0} value="seconds" />,
      <SelectOption key={1} value="minutes" />,
      <SelectOption key={2} value="hours" />,
      <SelectOption key={3} value="days" />,
      <SelectOption key={4} value="months" />,
      <SelectOption key={5} value="years" />,
    ];

    this.time2options = [
      <SelectOption key={0} value="Last" />,
      <SelectOption key={1} value="Next" />,
    ];
    this.onToggle = (isOpen, isExpanded) => {
      this.setState({
        isOpenSelectedTime: isOpen,
        isOpen: isExpanded,
        isExpanded,
      });
    };
    this.onToggleTime = (isOpen, isExpanded) => {
      this.setState({
        isOpenRefreshTime: isOpen,
        isOpen: isExpanded,
        isExpanded,
      });
    };

    this.onSelect = (event, value, isPlaceholder, isSelectedOperator) => {
      if (isPlaceholder) {
        // this.clearSelection()
        this.setState({
          isSelectedOperator: false,
          isDisabledselectOperator: true,
        });
      } else {
        this.setState({
          selectedFields: value,
          isOpenSelectedTime: false,
          isSelectedOperator: true,
          isDisabledselectOperator: false,
          selectedOperator: "",
          isOpen: !this.state.isOpen,
        });
        // this.props.onFieldselect(selection);
        // this.setState({ selectedOption: selection, isOpen: false });
      }
    };
    this.onRefreshTime = (event, selection) => {
      this.setState({
        selectedTimeUnit: selection,
        isOpenRefreshTime: false,
      });
    };

    this.onSelectTime = (event, selection) => {
      this.setState({
        selectedUnit: selection,
        isOpenSelectedTime: false,
      });
    };
    this.onSearchInputFieldChange = (value) => {
      this.setState({ searchField: value });
    };
    this.onSearchInputValueChange = (value) => {
      this.setState({ searchValue: value });
    };

    this.clearSelection = () => {
      this.setState({
        selectedFields: null,
        isOpen: false,
        isDisabledselectOperator: true,
        isDisabledselectValue: true,
        // isSelectedOperator:false
      });
    };
  }

  FilterOperator = [
    { term: "is", key: "is" },
    { term: "is_not", key: "is_not" },
    { term: "is_one_of", key: "is_one_of" },
    { term: "is_not_one_of", key: "is_not_one_of" },
    { term: "exists", key: "exists" },
    { term: "does_not_exist", key: "does_not_exist" },
  ]
  TimeApi = async () => {
    try {
      let currentDateTime = await dbApi.getApi("elasticsearch/getCurrentUtcDateTime");
      // console.log(currentDateTime)
      const CurrentTime = new Date(currentDateTime);
      this.setState({
        currentTime: CurrentTime,
      })
      return CurrentTime;
    }
    catch (error) {
      console.log(error);
    }
  }
  // elasticsearch/Entire_log
  entireLog = async () => {
    try {
      let entirelog = await dbApi.getApi("elasticsearch/Entire_log");
      const timestamp = entirelog.Entire_log.body.hits.hits[0]._source.timestamp;
      const entireStartDate = new Date(timestamp);
      this.setState({
        entire_startDate: entireStartDate,
      })
      return entireStartDate;
    }
    catch (error) {
      console.log(error);
    }
  }


  handleItemClick = async (term) => {
    // alert("Test")
    const { startDate, endDate, selectedTime, selectedUnit } = await TimeHandler(term,
      this.TimeApi,
      this.entireLog,
      this.props.onClick,
      this.props.selectedButton
    );
    this.setState({
      selectedItem: term,
      isPopoverOpen: false,
      loading: true,
      startDate,
      endDate,
      selectedTime,
      selectedUnit,
    });
  }
  handleModalToggle1() {
    this.setState({ isModalOpen: !this.state.isModalOpen });
  }
  handleModalToggle(event) {
    this.setState({ isModalOpen: !this.state.isModalOpen });
  }

  fieldChange(value, type) {
    let form = this.state.currentForm;
    let fields = this.state.fields;
    fields[form][type]["value"] = value;
    this.setState({ fields: fields });
  }
  handleChange = (checked, _event) => {
    this.setState({ isChecked: checked });
  };
  handleSelectOperatorToggle = (isSelectedOperator) => {
    this.setState({ isSelectedOperator });
    // this.setState((prevState) => ({
    //   isOpen: !prevState.isOpen
    // }))

  };

  // renderToggle = (toggleRef) =>{
  //   <MenuToggle
  //     ref={toggleRef}
  //     onClick={this.handleSelectOperatorToggle(!this.state.isSelectedOperator)}
  //     isExpanded={this.state.isSelectedOperator}
  //     isDisabled={this.state.isDisabledselectOperator}
  //   >
  //     {this.state.selectedOperator}
  //   </MenuToggle>

  // }
  handleSelectOperator = (event, selection) => {
    this.setState({
      selectedOperator: selection,
      isSelectedOperator: false,
      isOpen: false,
      isSelectedValue: true,
      isDisabledselectValue: false,
    });
  };
  handleSelectValueToggle = (isSelectedValue) => {
    this.setState({ isSelectedValue });
  };
  handleSelectValue = (event, selection) => {
    this.setState({
      selectedValue: selection,
      isSelectedValue: false,
      isOpen: false,
      isSelectedOperator: false,
    });
  };
  isSaveButtonEnabled = () => {
    const { selectedFields, selectedOperator, selectedValue } = this.state;
    // console.log("-->>>", selectedFields)
    return (
      selectedFields !== "" && selectedOperator !== "" && selectedValue !== ""
    );
  };
  handleSaveFilter = (event) => {
    const { selectedFields, selectedOperator, selectedValue, addedFields } =
      this.state;
    // console.log("selectedfield->", selectedFields)
    let obj = {
      fields: selectedFields,
      condition: selectedOperator,
      value: selectedValue,
    };
    // console.log("object-->>", obj)
    const updatedFields = [...addedFields];
    updatedFields.push(obj);
    this.setState({
      addedFields: updatedFields,
    });

    // const operatorFilter = `${selectedFields}:${selectedValue}`;
    let operatorFilter = "";
    switch (selectedOperator) {
      case "is_not":
        operatorFilter = `NOT ${selectedFields}:${selectedValue}`;
        break;
      case "is_one_of":
        operatorFilter = `${selectedFields}: is one of ${selectedValue}`;
        break;
      case "is_not_one_of":
        operatorFilter = `NOT ${selectedFields}: is one of ${selectedValue}`;

        break;
      case "does_not_exis":
        operatorFilter = `NOT ${selectedFields}: ${selectedValue}`;

        break;
      default:
        operatorFilter = `${selectedFields}:${selectedValue}`;
        break;
    }
    let selectedButton = this.props.selectedButton;
    this.props.onClick(selectedButton, this.state.selectedOperator, this.state.selectedValue);
    this.setState((prevState) => ({
      operatorFilter: [...prevState.operatorFilter, operatorFilter],
      selectedFields: "select field name",
      selectedOperator: "",
      selectedValue: "",
      isDisabledselectValue: true,
      isDisabledselectOperator: true,

    }));
  };

  handleCancelFilter = (event) => {
    this.setState({
      isFilterPopoverOpen: false,
      selectedFields: "select field name",
      selectedOperator: "",
      selectedValue: "",
      isDisabledselectValue: true,
      isDisabledselectOperator: true,
    });
  };
  handleDeleteChip = (operatorFilter) => {
    this.setState((prevState) => ({
      operatorFilter: prevState.operatorFilter.filter(
        (value) => value !== operatorFilter
      ),
    }));
  };
  togglePopover = () => {
    this.setState((prevState) => ({
      isPopoverOpen: !prevState.isPopoverOpen,
    }));
  };
  handleApplyClick = async (event) => {

    const timeValue = this.timeInputRef.value;
    const unitValue = this.state.selectedUnit;
    const inputValue = this.state.InputValue;
    const endDate = await this.TimeApi();
    let startDate = new Date(endDate);
    // let interval;
    switch (unitValue) {
      case "seconds":
        startDate.setSeconds(startDate.getSeconds() - timeValue);
        startDate = startDate;
        // interval='1s'
        break;
      case "minutes":
        startDate.setMinutes(startDate.getMinutes() - timeValue);
        startDate = startDate;
        // interval="1m"
        break;
      case "hours":
        startDate.setHours(startDate.getHours() - timeValue);
        startDate = startDate;
        // interval="5m"
        break;
      case "days":
        startDate.setDate(startDate.getDate() - timeValue);
        startDate = startDate;
        // interval="1h"
        break;

      case "months":
        startDate.setMonth(startDate.getMonth() - timeValue);
        startDate = startDate;
        // interval="1d"
        break;
      case "years":
        startDate.setFullYear(startDate.getFullYear() - timeValue);
        startDate = startDate;
        // interval="1M"
        break;
      default:
        break;
    }
    this.setState({
      timeValue,
      unitValue,
      selectedItem: `${inputValue} ${timeValue} ${unitValue}`,
      isPopoverOpen: false,
    });
    let selectedButton = this.props.selectedButton;
    this.props.onClick(startDate, endDate, selectedButton);


    // this.onSearchFieldButtonClick =()=>{
    //   const{ searchField}=this.state;
    //   // const Fields = this.props.Field;
    //   const{Fields}=this.props;
    //   const filtered = searchField === '' ? Fields : Fields.filter((item) => {
    //     const str = typeof item === 'string' ? item : item.text;
    //     return str.toLowerCase().indexOf(searchField.toLowerCase()) !== -1;
    //   });
    //   this.setState({ filteredFields: filtered || [] })
    // }

  };
  componentDidMount() {

    if (!this.state.loading) {
      this.handleItemClick("Last 24 hours")
    }
    // const { location } = this.props;
    // const currentPage = location.pathname
    // if (currentPage === "/vulnerabilities")
    //   this.handleItemClick("All Times");
    // else {
    //   this.handleItemClick("Last 24 hours");
    // }

  }


  // filterValueApi = async (startDate, endDate, selectedFields) => {
  //   this.setState({ selectedFields: selectedFields });
  //   const data = {
  //     StartDate: startDate,
  //     EndDate: endDate,
  //     field: selectedFields,
  //   };
  //   console.log("data",data)
  //   try {
  //     let res = await dbApi.postApi("elasticsearch/EventLog", data);
  //     let currentDateTime = await dbApi.postApi("elasticsearch/getCurrentUtcDateTime")
  //     console.log(currentDateTime);
  //     console.log(res);
  //     const filtervalue = res.event.keys;
  //     this.setState({
  //       FilterValue: filtervalue
  //     })
  //   }
  //   catch (error) {
  //     console.error(error, { loading: false });
  //   }

  // }

  handleInputChange = (event) => {
    this.setState({ inputValue: event.target.value });
  };
  // refreshPage = () => {
  //   window.location.reload(false);
  // };

  onEnterPressed = (event) => {
    if (event.key === "Enter") {
      // alert("ok")
      this.onSearchFieldButtonClick();
    }
  };
  onToggleClick = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  }

  onSearchFieldButtonClick = () => {
    const { searchField, Fielddata } = this.state;
    const filtered = searchField === '' ? Fielddata : Fielddata.filter((item) => {
      const str = typeof item === 'string' ? item : item;
      return str.toLowerCase().indexOf(searchField.toLowerCase()) !== -1;
    });
    this.setState({
      filteredFields: filtered || [],
      isOpen: true,
    });
  };

  toggleoperator=(toggleRef)=>(
<MenuToggle ref={toggleRef} onClick={this.handleSelectOperatorToggle} isExpanded={this.state.isOpen}>
{this.state.selectedOperator}
</MenuToggle>
  );
  // -------------------
  toggle = (toggleRef) => (
    <MenuToggle
      ref={toggleRef}
      onClick={this.handleSelectOperatorToggle}
      isExpanded={this.state.isOpen}
      // isDisabled={this.state.isDisabledselectOperator}
      style={{ width: '200px' }}
    >
      {this.state.selected}
    </MenuToggle>
  );
  render() {

    const savequery = this.state.fields.savequery;
    const {
      isOpen,
      selectedFields,
      isPopoverOpen,
      selectedOperator,
      isSelectedOperator,
      isDisabledselectOperator,
      selectedValue,
      isSelectedValue,
      isDisabledselectValue,
      isChecked,
      selectedItem,
      selectedTime,
      selectedUnit,
      selectedTimeUnit,
      FilterValue,
      filteredFields,
      searchField,
      searchValue,
      operatorFilter,
      isOpenRefreshTime,
      isOpenSelectedTime,
      InputValue,
     
    } = this.state;
    const { FieldData } = this.props;
    const isSaveButtonEnabled = this.isSaveButtonEnabled();
    const Addfilterform = (
      <div className="pf-v5-u-mb-sm">
        <Form>
          <Grid hasGutter>
            <GridItem  md={8} sm={6}>
              <FormGroup label="Field" fieldId="simple-form-name">
                <div>
                  <Dropdown
                    isOpen={isOpen}
                    onOpenChange={(isOpen) => this.setState({ isOpen })}
                    // onToggle={this.onToggle}
                    toggle={(toggleRef) => (
                      <MenuToggle
                        ref={toggleRef}
                        onClick={this.onToggleClick}
                        isExpanded={isOpen}
                      >
                        {selectedFields}
                      </MenuToggle>
                    )}
                    ref={this.menuRef}
                    id="context-selector"
                    onSelect={this.onSelect}
                    isScrollable
                  >
                    <MenuSearch>
                      <MenuSearchInput>
                        <InputGroup>
                          <InputGroupItem isFill>
                            <SearchInput
                              value={searchField}
                              placeholder="Search"
                              onChange={(_event, value) =>
                                this.onSearchInputFieldChange(value)
                              }
                              onKeyDown={this.onEnterPressed}
                              aria-labelledby="pf-v5-context-selector-search-button-id-1"
                            />
                          </InputGroupItem>
                          <InputGroupItem>
                            <Button
                              variant={ButtonVariant.control}
                              aria-label="Search menu items"
                              id="pf-v5-context-selector-search-button-id-1"
                              onClick={this.onSearchFieldButtonClick}
                            >
                              <SearchIcon aria-hidden="true" />
                            </Button>
                          </InputGroupItem>
                        </InputGroup>
                      </MenuSearchInput>
                    </MenuSearch>
                    <Divider />
                    <DropdownList>
                      {FieldData && Object.entries(FieldData).sort().map(([key, item]) => {
                        return (
                          <DropdownItem key={key}>
                            {item}
                          </DropdownItem>
                        );
                      })}
                    </DropdownList>
                  </Dropdown>
                </div>
              </FormGroup>
            </GridItem>
            <GridItem md={4} sm={6}>
              <FormGroup label="Operator" fieldId="simple-form-name2">
                {/* <Select
                  id="single-select"
                  isOpen={isOpen}
                  selected={selectedOperator}
                  onSelect={this.handleSelectOperator}
                  onOpenChange={(isOpen) => this.setState({ isOpen })}
                  toggle={this.toggle}
                  shouldFocusToggleOnSelect>
                  <SelectList>
                    {this.FilterOperator}
                  </SelectList>
                </Select> */}
                {/* ------------ */}
                {/* <Dropdown
                  isOpen={isSelectedOperator}
                  onSelect={this.handleSelectOperator}
                  isDisabled={isDisabledselectOperator}
                  onOpenChange={this.state.isOpen}
                  toggle={this.toggleoperator}
                  ouiaId="OperatorDropdown"
                  shouldFocusToggleOnSelect>
                  {this.FilterOperator.map((item, index) => (
                    <DropdownList key={index}>
                      <DropdownItem key={item.key}
                        value={item.term}
                        // onClick={() => { { handleItemClick(item.term) } }}
                      >{item.term}</DropdownItem>
                    </DropdownList>
                  ))}
                </Dropdown> */}
                <Select
                  id="simple-form-name2"
                  variant={SelectVariant.single}
                  isDisabled={isDisabledselectOperator}
                  onToggle={(_event, isSelectedOperator) =>
                    this.handleSelectOperatorToggle(isSelectedOperator)
                  }
                  onSelect={this.handleSelectOperator}
                  isOpen={isSelectedOperator}
                  selections={selectedOperator}
                  placeholderText="Select"
                  aria-label="Select Operator"
                >
                  {this.FilterOperator}
                </Select>
              </FormGroup>
            </GridItem>
          </Grid>
          <FormGroup label="Value" fieldId="grid-form-value-01">
            {/* ---------------- */}
            
            <Select
              id="simple-form-name2"
              isDisabled={isDisabledselectValue}
              onToggle={(_event, isSelectedValue) =>
                this.handleSelectValueToggle(isSelectedValue)
              }
              onSelect={this.handleSelectValue}
              isOpen={isSelectedValue}
              selections={selectedValue}
              placeholderText="Select a value"
              aria-label="Select Input"
            >
              <SelectList> {this.FilterValue}</SelectList>
              {Object.entries(FilterValue).map(([key, value]) => (
                <SelectOption key={key} value={value} />
              ))}
            </Select>
          </FormGroup>
          <FormGroup>
            <Switch
              id="uncontrolled-switch-with-label"
              label="Create Custom Label?"
              defaultChecked={false}
              spacers="4px"
            />
          </FormGroup>
        </Form>
      </div>
    );
    return (
      <div>
        <Grid hasGutter>
          <GridItem sm={6} md={7} lg={8}>
            <InputGroup>
              <InputGroupItem>
                <Popover
                  showClose={false}
                  aria-label="Basic popover"
                  headerContent={
                    <div>
                      SAVED QUERIES <Divider />
                    </div>
                  }
                  bodyContent={
                    <div>
                      <Text>
                        There are no saved queries. Save query text and filters
                        that you want to use again.
                      </Text>
                      <Divider />
                    </div>
                  }
                  footerContent={
                    <div>
                      <Button
                        size="sm"
                        onClick={() => {this.handleModalToggle(); }}
                        style={{ marginTop: "20px" }}
                      >
                        Save current query
                      </Button>
                      <Modal
                        variant={ModalVariant.small}
                        title="Save query"
                        isOpen={this.state.isModalOpen}
                        onClose={() => this.handleModalToggle1()}
                        actions={[
                          <Button key="create" variant="primary" form="modal-with-form-form"  onClick={() => this.handleModalToggle1()}> Save</Button>,
                          <Button key="cancel" variant="link" onClick={() => this.handleModalToggle1()} >Cancel</Button>,
                        ]}
                      >
                        <small> Save query text and filters that you want to use again </small>
                        <div className=" pf-v5-u-mt-md">
                          <Form id="modal-with-form-form">
                            <FormGroup
                              label="Name"
                              fieldId="modal-with-form-form-type"
                              helperText="Name is required. Name cannot contain leading or trailing whitespace. Name must be unique."
                            >
                              <TextInput
                                isRequired
                                type="text"
                                id="simple-form-name-01"
                                name="simple-form-name-01"
                                value={savequery.name.value}
                                onChange={(_event, value) =>
                                  this.fieldChange(value, "name")
                                }
                              />
                            </FormGroup>
                            <FormGroup
                              label="Description"
                              isRequired
                              fieldId="modal-with-form-form-desc"
                            >
                              <TextArea
                                isRequired
                                id="modal-with-form-form-desc"
                                name="modal-with-form-form-desc"
                                value={savequery.desc.value}
                                onChange={(_event, value) =>
                                  this.fieldChange(value, "desc")
                                }
                              />
                            </FormGroup>
                            <Switch
                              id="uncontrolled-switch-with-sslabel-filter"
                              label="Include filters"
                              isChecked={isChecked}
                              spacers="4px"
                            />
                            <Switch
                              id="uncontrolled-switch-with-label-time-filter"
                              label="Include time filters"
                              defaultChecked={false}
                              isChecked={isChecked}
                              spacers="4px"
                            />
                          </Form>
                        </div>
                      </Modal>
                    </div>
                  }
                >
                  <Button variant="control" icon={<SaveIcon />}><AngleDownIcon /></Button>
                </Popover>
              </InputGroupItem>
              <InputGroupItem isFill>
                <TextInput
                  name="text1"
                  id="text1"
                  aria-label="text with buttons"
                  placeholder="Search"
                />
              </InputGroupItem>
            </InputGroup>
          </GridItem>
          <GridItem sm={6} md={3} lg={3}>
            <InputGroup>
              {/* <InputGroupItem>
                <Button variant="control" onClick={this.togglePopover} icon={<OutlinedCalendarAltIcon />} ><AngleDownIcon /> </Button>
              </InputGroupItem> */}
              <InputGroupItem>
                <Popover
                  aria-label="Basic popover"
                  showClose={false}
                  hasAutoWidth
                  headerContent={(hide) => (
                    <div>
                      <Flex>
                        <FlexItem>
                          <Text component={TextVariants.small}>
                            Quick Select
                          </Text>
                        </FlexItem>
                        <FlexItem align={{ default: "alignRight" }}>
                          <AngleLeftIcon />
                        </FlexItem>
                        <FlexItem>
                          <AngleRightIcon />
                        </FlexItem>
                      </Flex>
                      <Form>
                        <Flex spaceItems={{ default: "spaceItemsXs" }}>
                          <FlexItem>
                            <FormGroup>
                              {/* <TextInput type="text" id="simple-text" value={InputValue} onChange={this.handleInputChange} /> */}
                              <TextInput
                                type="text"
                                id="simple-text"
                                value={InputValue}
                                style={{ width: "100px" }}
                              />
                            </FormGroup>
                          </FlexItem>
                          <FlexItem>
                            <FormGroup>
                              <TextInput
                                type="number"
                                id="simple-form-text"
                                ref={(ref) => (this.timeInputRef = ref)}
                                style={{ width: "100px" }}
                                value={selectedTime}
                                onChange={(_event, value) =>
                                  this.setState({ selectedTime: value })
                                }
                              />
                            </FormGroup>
                          </FlexItem>
                          <FlexItem>
                            <FormGroup>
                              <Select
                                id="simple-form-name"
                                placeholderText="select Unit"
                                onToggle={this.onToggle}
                                onSelect={this.onSelectTime}
                                isOpen={isOpenSelectedTime}
                                selections={selectedUnit}
                                aria-label="Select Input"
                              >
                                {this.unitOptions}
                              </Select>
                            </FormGroup>
                          </FlexItem>
                          <FlexItem>
                            <FormGroup>
                              <Button variant="secondary"onClick={() => {this.handleApplyClick();hide(); }}>  Apply </Button>
                            </FormGroup>
                          </FlexItem>
                        </Flex>
                      </Form>
                    </div>
                  )}
                  bodyContent={(hide) => (
                    <div className="pf-v5-u-mt-sm">
                      <Text component={TextVariants.h6}>Commonly Used </Text>
                      <DescriptionList
                        columnModifier={{ default: "2Col" }}
                        isInlineGrid
                      >
                        {this.Timelist.map((item, index) => (
                          <DescriptionListGroup key={index}>
                            <DescriptionListTerm onClick={() => this.handleItemClick(item.term)} >
                              <Button variant="link" isInline onClick={hide}> {item.term} </Button>
                            </DescriptionListTerm>
                          </DescriptionListGroup>
                        ))}
                      </DescriptionList>
                      <Divider />
                    </div>
                  )}
                  footerContent={
                    <div>
                      <Text component={TextVariants.h6}>Refresh every</Text>
                      <Form>
                        <Flex spaceItems={{ default: "spaceItemsMd" }}>
                          <FlexItem>
                            <TextInput
                              type="number"
                              id="simple-form-number"
                              style={{ width: "150px" }}
                            />
                          </FlexItem>
                          <FlexItem>
                            <Select
                              id="simple-form-name"
                              onToggle={this.onToggleTime}
                              onSelect={this.onRefreshTime}
                              selections={selectedTimeUnit}
                              isOpen={isOpenRefreshTime}
                              placeholderText="seconds"
                              aria-label="Select Input"
                            >
                              <SelectList> {this.timeoptions}</SelectList>
                            </Select>
                          </FlexItem>
                          <FlexItem>
                            <Button isDisabled icon={<PlayIcon />}>
                              Start
                            </Button>
                          </FlexItem>
                        </Flex>
                      </Form>
                    </div>
                  }
                >
                  <Button
                    variant="control"
                    onClick={this.togglePopover}
                    icon={<OutlinedCalendarAltIcon />}
                  >
                    <AngleDownIcon />
                  </Button>
                </Popover>
              </InputGroupItem>
              <InputGroupItem isFill>
                <TextInput
                  name="text2"
                  id="text2"
                  value={selectedItem}
                  onChange={(e) =>
                    this.setState({ selectedItem: e.target.value })
                  }
                  aria-label="text with buttons"
                />
              </InputGroupItem>
            </InputGroup>
          </GridItem>
          <GridItem sm={6} md={2} lg={1}>
            <Button icon={<RedoIcon />} onClick={() => this.handleItemClick(this.state.selectedItem)} style={{ width: '100%', maxWidth: '100%' }} />
          </GridItem>
        </Grid>

        <Flex>
          <FlexItem>
            <ChipGroup>
              {operatorFilter.map((value, index) => (
                <Chip key={index} onClick={() => this.handleDeleteChip(value)}>
                  {value}
                </Chip>
              ))}
            </ChipGroup>
          </FlexItem>
          <FlexItem>
            <Popover
              aria-label="Basic popover"
              showClose={false}
              hasAutoWidth
              headerContent={
                <div>
                  <Flex
                    direction={{ default: "row" }}
                    justifyContent={{ default: "justifyContentSpaceBetween" }}
                  >
                    <FlexItem>
                      <Text component={TextVariants.small}>Edit Filter</Text>
                    </FlexItem>
                    <FlexItem>
                      <Button variant="link" size="sm">
                        Edit As Query DSL{" "}
                      </Button>
                    </FlexItem>
                  </Flex>
                  {/* <Divider /> */}
                </div>
              }
              bodyContent={
                <React.Fragment>
                  {/* <Divider/> */}
                  {Addfilterform}
                  <Divider />
                </React.Fragment>
              }
              footerContent={(hide) => (
                <div>
                  <ActionList>
                    <ActionListItem key="cancel">
                      <Button
                        variant="link"
                        id="with-cancel-cancel-button"
                        onClick={() => {
                          this.handleCancelFilter();
                          hide();
                        }}
                      >
                        Cancel
                      </Button>
                    </ActionListItem>
                    <ActionListItem key="save">
                      <Button
                        variant="primary"
                        id="with-cancel-save-button"
                        isDisabled={!isSaveButtonEnabled}
                        onClick={() => {
                          this.handleSaveFilter();
                          hide();
                        }}
                      >
                        Save
                      </Button>
                    </ActionListItem>
                  </ActionList>
                </div>
              )}
            >
              <Button
                variant="link"
                size="sm"
                icon={<PlusIcon />}
                onClick={() => this.setState({ isFilterPopoverOpen: true })}
              >
                Add Filter
              </Button>
            </Popover>
          </FlexItem>
        </Flex>

      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  FieldData: state.FieldData,
})
export default connect(mapStateToProps)(BkEventBar)

// export default BkEventBar
