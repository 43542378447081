import React, { useState, useRef, useEffect, forwardRef } from "react";
import { connect, useDispatch } from "react-redux";
import {
    Bullseye,
    Grid,
    GridItem,
    MenuToggle,
    Page,
    Sidebar,
    Select,
    SelectOption,
    SelectList,
    SidebarContent,
    Text,

} from "@patternfly/react-core";
import dbApi from '../../services/DbApi';
import EventLogTable from "../CommonEventPages/EventLogTable";
import Eventsidebar from "../CommonEventPages/EventSidebar";
import EventChart from "../CommonEventPages/EventChart";
import { formatTableTimestamp } from "../../util/Timeutils";
import { Loader } from "../../util/Loadingstate";
import { Emptystatefornodata } from "../../util/EmptyStateCard";
import { eventfieldData } from "src/Redux/Action";

const NistEvents = forwardRef(function NistEvents(props, ref) {
    const dispatch = useDispatch();
    const [isSidebarExpanded, setIsSidebarExpanded] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [selected, setSelected] = useState("select");
    const [nistscrollid, setNistScrollid] = useState('');
    const [logtabledata, setLogTableData] = useState([]);
    const [totalcount, setTotalCount] = useState("0");
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [chartlabel, setChartLabel] = useState('')
    const [chartseries, setChartSeries] = useState([])
    const [fieldKeys, setFieldKeys] = useState([])
    const [isLoading, setIsLoading] = useState(false);

    const childSecurityEventRef = useRef(null);
    const time1options = [
        <SelectOption key={0} value="seconds"> seconds</SelectOption>,
        <SelectOption key={1} value="minutes">minutes </SelectOption>,
        <SelectOption key={2} value="hours">hours</SelectOption>,
        <SelectOption key={3} value="days">days</SelectOption>,
        <SelectOption key={4} value="weeks">weeks</SelectOption>,
        <SelectOption key={5} value="months">months</SelectOption>,
        <SelectOption key={6} value="years">years</SelectOption>,
    ];
    const NistColumn = ["Time", "Agent Name", "Rule Nist_800_53", "Rule Description", "Rule level", "Rule ID"]

    // "Syscheck Path", "Syscheck event",
    const clearselection = () => {
        setSelected(null)
        setIsOpen(false)
    }
    const onToggle = (isOpen) => {
        setIsOpen(isOpen)
    }
    const onSelect = (event, selection, isplaceholder) => {
        if (isplaceholder) {
            clearselection()
        } else {
            setSelected(selection)
            setIsOpen(false)
        }
    }

    const getEventlog = async (data) => {
        try {
            setIsLoading(true)
            let res = await dbApi.postApi("SecurityCompliances/Nist_Event", data);
            console.log(res);
            let LogTableData = []
            let ChartLabel = ''
            let ChartSeries = []
            let TotalCount = '0'
            let scrollID = ''
            if (Object.keys(res.Nist_Event).length !== 0) {
                let fieldkeys = res.Nist_Event.keys
                ChartSeries = res.Nist_Event.Nist_Event.body.aggregations.date_histogram_agg.buckets;
                ChartLabel = res.Nist_Event.interval
                scrollID = res.Nist_Event.Nist_Event.body._scroll_id
                LogTableData = res.Nist_Event.Nist_Event.body.hits.hits;
                TotalCount = res.Nist_Event.Nist_Event.body.hits.total.value;

                setLogTableData(LogTableData)
                setFieldKeys(fieldkeys)
                setNistScrollid(res)
                setTotalCount(TotalCount)
                setChartLabel(ChartLabel)
                setChartSeries(ChartSeries)
            }
            else {
                setLogTableData(LogTableData)
                setFieldKeys([])
                setNistScrollid(res)
                setTotalCount(TotalCount)
                setChartLabel(ChartLabel)
                setChartSeries(ChartSeries)
            }
            setIsLoading(false)
        }
        catch (error) {
            console.log(error)
            setLogTableData([])
            setFieldKeys([])
            setNistScrollid('')
            setTotalCount(0)
            setChartLabel('')
            setChartSeries([])
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (childSecurityEventRef.current) {
            childSecurityEventRef.current.setSecurityEventTable(logtabledata, nistscrollid, NistColumn);
        }
    }, [logtabledata, nistscrollid, NistColumn]);

    const eventsApi = async (startDate, endDate, allFields) => {
        const Fields = allFields
        const data = {
            startDate: startDate,
            endDate: endDate,
            filter: JSON.stringify(Fields),
        };
        try {
            setIsLoading(true)
            setStartDate(startDate)
            setEndDate(endDate)
            await getEventlog(data)
        }
        catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {

        if (props.dates.startDate != "") {
            let Dates = props.dates
            eventsApi(Dates.startDate, Dates.endDate, props.filterformdata,)
        }
    }, [props?.dates.startDate, props?.dates.endDate, props?.filterformdata]);

    const toggle = toggleRef => <MenuToggle
        ref={toggleRef}
        onClick={onToggle}
        isExpanded={isOpen}
        style={{ width: '150px' }}>
        {selected}
    </MenuToggle>

    // console.log("sd",startDate)
    // console.log("ed",endDate)
    const { formattedTimeStamp: StartDate } = formatTableTimestamp(startDate);
    const { formattedTimeStamp: EndDate } = formatTableTimestamp(endDate);
    return (
        <div>
            <Grid hasGutter>
                <GridItem>
                    <Page isManagedSidebar>
                        <Sidebar style={{ overflow: "auto" }} >
                            <Eventsidebar
                                Count={totalcount}
                                Loading={isLoading}
                                FieldKeys={fieldKeys}
                                filterfield={[{
                                    'fields': 'rule.groups',
                                    'condition': 'is',
                                    'value': 'syscheck'
                                }]} />
                            <div style={{ width: "100%" }}>
                                <SidebarContent className="Sidebar-content">
                                    <Text align="center"><b>{`${totalcount.toLocaleString('hi-IN')} hits`}</b></Text>
                                    <Text align='center'><small><b>{StartDate} - {EndDate}</b></small></Text>
                                    {/* <div>
                    <Bullseye>
                      <div>
                        <Select
                          id="single-select"
                          isOpen={isOpen}
                          selected={selected}
                          onSelect={onSelect}
                          // onOpenChange={isOpen}
                          toggle={toggle}
                          shouldFocusToggleOnSelect
                        >
                          <SelectList>
                            {time1options}
                          </SelectList>
                        </Select>
                      </div>
                    </Bullseye>
                  </div> */}
                                    {isLoading ? <Loader /> :
                                        chartseries.length === 0 ? <Emptystatefornodata /> :
                                            <>
                                                <EventChart EventSeries={chartseries} EventLabel={chartlabel} StartDateTime={startDate} EndDateTime={endDate} />

                                                <EventLogTable ref={childSecurityEventRef} />
                                            </>
                                    }
                                </SidebarContent>
                            </div>
                        </Sidebar>
                    </Page>
                </GridItem>
            </Grid>
        </div>
    );
})

const mapStateToProps = (state) => ({
    dates: { startDate: state.startdate, endDate: state.enddate },
    filterformdata: state.FilterData,
    selectedGroup: state.SelectedGroup,
    // mapStateToProps content remains unchanged if you have it
});

const mapDispatchToProps = (dispatch) => { return {} };

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(NistEvents)
