import React, { useEffect } from 'react';
import * as echarts from 'echarts';
import 'echarts-wordcloud';
import { generateUniqueID } from '../../util/UniqueIdGenerator';
import { UpdateFilterData } from '../../util/FilterHelper';
import { FilterData } from '../../Redux/Action';
import { connect, useDispatch } from 'react-redux';
const TopTactics = (props) => {
    const id = generateUniqueID()
    const dispatch = useDispatch();
    const handleFilter = (fields, value) => {
        const updatedFields = UpdateFilterData(props.filterformdata, fields, 'is', value);
        dispatch(FilterData(updatedFields));
    }
    useEffect(() => {
        const chartDom = document.getElementById(id + 'tactic');
        const myChart = echarts.init(chartDom);
        const textcolor = props.theme == 'dark-theme' ? '#fff' : '#000'

        const option = {
            tooltip: {
                trigger: 'item',
            },
            series: [
                {
                    textStyle: {
                        color: function () {
                            return 'rgb(' + [
                                Math.round(200 + Math.random() * 55),
                                Math.round(200 + Math.random() * 55),
                                Math.round(200 + Math.random() * 55)
                            ].join(',') + ')';
                        }
                    },
                    emphasis: {
                        focus: 'self',

                        textStyle: {
                            textShadowBlur: 5,
                            textShadowColor: '#333'
                        }
                    },
                    type: 'wordCloud',
                    gridSize: 2,
                    sizeRange: [12, 60],
                    shape: 'rectangle',
                    rotationRange: [-90, 90],
                    // rotationStep: 45,
                    left: 'center',
                    top: 'center',
                    width: '100%',
                    height: '100%',
                    right: null,
                    bottom: null,
                    drawOutOfBound: false,
                    shrinkToFit: true,
                    // roam: true,
                    data: props.TacticsData.map((bucket) => ({
                        value: bucket.doc_count,
                        name: bucket.key
                    })
                    ),
                },
            ],
        };

        // Set chart options
        option && myChart.setOption(option);
        myChart.on('click', 'series.wordCloud', function (param) {
            handleFilter(props.filterFields, param.name)
        });
        return () => {
            myChart.dispose();
        };
    }, [props.TacticsData,props.theme,props.sidebarState]);

    return <div className="container">
        <div id={id + 'tactic'} style={{ width: '100%', height: props.height }} ></div>
    </div>;
}
const mapStateToProps = (state) => ({
    dates: { startDate: state.startdate, endDate: state.enddate },
    filterformdata: state.FilterData,
    theme: state.theme,
    sidebarState:state.sidebarstate
    // mapStateToProps content remains unchanged if you have it
});

export default connect(
    mapStateToProps,
    null,
    null,
    { forwardRef: true }
)(TopTactics)