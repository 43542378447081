import React, { useState, useRef, useEffect } from 'react';
import {
    PageSection,
    PageSectionVariants,
    Tooltip,
    Tabs,
    Tab,
    TabTitleText,
    TabContentBody,
} from '@patternfly/react-core';
import OverviewDashboard from './OverviewDashboard'
// import Incidents from './Incidents'
import IncidentAlertMain from './IncidentAlert/IndicentAlertMain';
import IncidentTaskMain from './IncidentTasks/IncidentTaskMain';
import { Link, useParams } from 'react-router-dom';
import IncidentList from './IncidentList'

const IncidentManagement = () => {
    const { tabname } = useParams();

    const [activeTabKey, setActiveTabKey] = useState(0);
    const handleTabClick = (_event, tabIndex) => {
        setActiveTabKey(tabIndex);
    };

    useEffect(()=>{
        if(tabname=="incident")
        {
            setActiveTabKey(1);
        }    
    },[tabname])

    return (
        <div>
            <PageSection variant={PageSectionVariants.light} padding={{ default: 'noPadding' }} >
                <Tabs variant='light300' activeKey={activeTabKey} onSelect={handleTabClick} aria-label="Tabs-individual" role="region" isOverflowHorizontal isBox width={40}>
                    <Tab eventKey={0} title={<TabTitleText className='pf-v5-u-font-weight-bold'>Dashboard</TabTitleText>} aria-label="Horizontal overflow content users">
                        {activeTabKey == 0 &&
                            <TabContentBody className='pf-v5-u-p-xs'>
                                <OverviewDashboard />
                            </TabContentBody>}
                    </Tab>
                    <Tab eventKey={1} title={<TabTitleText className='pf-v5-u-font-weight-bold'>Incidents</TabTitleText>} aria-label="Horizontal overflow content users">
                        {activeTabKey == 1 &&
                            <TabContentBody className='pf-v5-u-p-xs'>
                                <IncidentList />
                            </TabContentBody>}
                    </Tab>
                </Tabs>
            </PageSection>
        </div>
    );
};

export default IncidentManagement;


/*
 <Tab eventKey={2} title={<TabTitleText className='pf-v5-u-font-weight-bold'>Alerts</TabTitleText>} aria-label="Horizontal overflow content users">
                        {activeTabKey == 2 &&
                            <TabContentBody className='pf-v5-u-p-xs'>
                                <IncidentAlertMain />
                            </TabContentBody>}
                    </Tab>
                    <Tab eventKey={3} title={<TabTitleText className='pf-v5-u-font-weight-bold'>Tasks</TabTitleText>} aria-label="Horizontal overflow content users">
                        {activeTabKey == 3 &&
                            <TabContentBody className='pf-v5-u-p-xs'>
                                <IncidentTaskMain />
                            </TabContentBody>}
                    </Tab>

*/