import React, { Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import routes from '../Routes/routes'
import Protectedroutes from '../Routes/Protectedroutes';

const AppContent = () => {
  return (
       <Suspense>
        <Routes>
        {routes.map((route, idx) => {
          let routeTag = '';
          if(typeof route?.roles != 'undefined')
          {
              routeTag = <Route
              key={idx}
              path={route.path}
              exact={route.exact}
              name={route.name}
              element={
              <Protectedroutes role={route?.roles}>
                 <route.element />
              </Protectedroutes>
              }
            />
          }else
          {
            routeTag = <Route
                key={idx}
                path={route.path}
                exact={route.exact}
                name={route.name}
                element={route.element && <route.element />}
              />
          }
          return (
            route.element && (
              routeTag
            )
          );
        })}
        </Routes>
      </Suspense>
  )
}
export default React.memo(AppContent)
