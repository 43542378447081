import {
    ActionGroup,
    Badge,
    Button,
    Checkbox,
    ClipboardCopy,
    ClipboardCopyVariant,
    FileUpload,
    Flex,
    FlexItem,
    Form,
    FormGroup,
    FormHelperText,
    HelperText,
    HelperTextItem,
    Icon,
    Label,
    MenuToggle,
    MenuToggleElement,
    ModalVariant,
    PageSection,
    Pagination,
    PaginationVariant,
    SearchInput,
    Select,
    SelectGroup,
    SelectList,
    SelectOption,
    Tab,
    TabContentBody,
    TabTitleText,
    Tabs,
    TextInput,
    ToggleGroup,
    ToggleGroupItem,
    Toolbar,
    ToolbarChipGroup,
    ToolbarContent,
    ToolbarGroup,
    ToolbarItem,
    Tooltip
} from '@patternfly/react-core'
import { Table, Thead, Tr, Th, Tbody, Td, InnerScrollContainer } from '@patternfly/react-table';
import React, { useEffect, useRef, useState } from 'react'
import dbApi from '../../services/DbApi';
import { CheckCircleIcon, CopyIcon, ExclamationCircleIcon, ExclamationTriangleIcon, PlusCircleIcon, PlusIcon, SortAmountDownIcon, TrashAltIcon, UserIcon } from '@patternfly/react-icons';
import { useDispatch } from 'react-redux';
import ConfirmBox from '../ConfirmBox/ConfirmBox';
import { Loader } from 'src/util/Loadingstate';
import { Emptystatefornodata } from 'src/util/EmptyStateCard';
import { alertBoxData } from 'src/Redux/Action';
import { formatTableTimestamp } from 'src/util/Timeutils';
import { Link, useNavigate } from 'react-router-dom';
import { handleCopy } from 'src/util/FilterHelper';
const OnBoardingMainpage = (props) => {
    const childGenerateRefModalref = useRef(null)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const Linkredirect = process.env.REACT_APP_REDIRECT_LINK;
    const defaultColumns = {
        createdby: 'Created By',
        link: 'Link',
        last_datetime: 'Created Date Time',
        status: "Status",
        Action: 'Action'
    };
    const [isLoading, setIsLoading] = useState(false);
    const [loadlink, setLoadLink] = useState(false);
    const [columns, setColumns] = useState(defaultColumns);
    const [rows, setRows] = useState([]);
    const [selectedRowName, setSelectedRowName] = useState('');
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [inputValue, setInputValue] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const [activeSortIndex, setActiveSortIndex] = useState(2);
    const [isSortDropdownOpen, setIsSortDropdownOpen] = useState(false);
    const [totalItem, setTotalItem] = useState(0);
    const [activeSortDirection, setActiveSortDirection] = useState('desc');
    const [copyTip, setCopytip] = useState('Copy')
    const excludeOrder = ['Action',]
    //for modal confirmation
    const [confirmModalData, setConfirmBox] = useState({
        show: false, msg: '',
        funCall: () => { },
        funParam: {},
        title: '',
        position: '',
        iconvariant: '',
        submitbtnvariant: '',
    })

    const getAllLinkList = async () => {
        let data = {
            limitStart: (perPage * page) - perPage,
            limitLength: perPage,
            search: searchValue,
            sortBy: Object.keys(columns)[activeSortIndex],
            sortOrder: activeSortDirection,

        }
        try {
            setIsLoading(true)
            let res = await dbApi.postApi("Opensearch/OnBoard_Link_List ", data)
            console.log(res)
            let alllinks = []
            if (!res.OnBoard_Link_List.error) {
                alllinks = res.OnBoard_Link_List.data.data
                setRows(alllinks)
                setTotalItem(res.OnBoard_Link_List.data.totalRecord)
            }
            else {
                setRows([])
                setTotalItem(0)
            }
            setIsLoading(false)
        } catch (error) {
            console.log(error)
            setIsLoading(false)
        }
    }

    useEffect(() => {
        getAllLinkList()
    }, [
        page,
        perPage,
        activeSortIndex,
        activeSortDirection,
        searchValue,])


    const generateFormLink = async () => {
        try {
            setLoadLink(true)
            let res = await dbApi.getApi('Opensearch/Generate_OnBoard_Link ')
            console.log(res)
            if (!res.Generate_OnBoard_Link.error) {
                dispatch(alertBoxData([{ title: res.Generate_OnBoard_Link.message, variant: 'success' }]));
                getAllLinkList()
            }
            else {
                dispatch(alertBoxData([{ title: res.Generate_OnBoard_Link.message, variant: 'danger' }]));
            }
            setLoadLink(false)
        } catch (error) {
            console.log(error)
            setLoadLink(false)
        }
    }

    const handleSetPage = (event, newPage) => {
        setPage(newPage);
    };

    const handlePerPageSelect = (event, newPerPage) => {
        setPerPage(newPerPage);
    };

    const renderPagination = (variant) => (
        <Pagination
            itemCount={totalItem}
            page={page}
            perPage={perPage}
            onSetPage={handleSetPage}
            onPerPageSelect={handlePerPageSelect}
            variant={variant}
        />
    );
    const onSort = (event, index, direction) => {
        setActiveSortIndex(index);
        setActiveSortDirection(direction);
    };
    const onSearch = (event) => {
        if (event.key && event.key !== 'Enter') {
            return;
        }
        setSearchValue(inputValue)
        setPage(1)
    }
    const tableToolbar = (
        <Toolbar id="sortable-toolbar">
            <ToolbarContent>
                <ToolbarItem visibility={{ md: 'hidden' }}>
                    <Select isOpen={isSortDropdownOpen} selected={[activeSortDirection, activeSortIndex]} onOpenChange={isOpen => setIsSortDropdownOpen(isOpen)} onSelect={(event, value) => {
                        if (value === 'asc' || value === 'desc') {
                            onSort(event, activeSortIndex, value);
                        } else {
                            onSort(event, value, activeSortDirection !== 'none' ? activeSortDirection : 'asc');
                        }
                    }} toggle={toggleRef => <MenuToggle ref={toggleRef} onClick={() => setIsSortDropdownOpen(!isSortDropdownOpen)} isExpanded={isSortDropdownOpen} variant="plain">
                        <SortAmountDownIcon />
                    </MenuToggle>}>
                        <SelectGroup label="Sort column">
                            <SelectList>
                                {Object.keys(columns).map((column, columnIndex) => <SelectOption key={column} value={columnIndex} isSelected={activeSortIndex === columnIndex}>
                                    {column}
                                </SelectOption>)}
                            </SelectList>
                        </SelectGroup>
                        <SelectGroup label="Sort direction">
                            <SelectList>
                                <SelectOption isSelected={activeSortDirection === 'asc'} value="asc" key="ascending">
                                    Ascending
                                </SelectOption>
                                <SelectOption isSelected={activeSortDirection === 'desc'} value="desc" key="descending">
                                    Descending
                                </SelectOption>
                            </SelectList>
                        </SelectGroup>
                    </Select>
                </ToolbarItem>
                <ToolbarItem key={'search-input'} variant="search-filter" style={{ width: '35%' }} spacer={{ default: 'spacerSm' }}>
                    <SearchInput placeholder="Search..."
                        value={inputValue}
                        onChange={(_event, value) => setInputValue(value)}
                        onSearch={onSearch}
                        onClear={() => { setSearchValue(''); setInputValue('') }} />
                </ToolbarItem>

                <ToolbarItem align={{ default: 'alignLeft', md: 'alignRight' }}>
                    <Button variant="primary" icon={<PlusCircleIcon />} onClick={() => { generateFormLink() }} isLoading={loadlink} isDisabled={loadlink}>Generate Onboarding link </Button>
                </ToolbarItem>
                {/* <ToolbarItem variant="pagination">{renderPagination('top',)}</ToolbarItem> */}
            </ToolbarContent>
        </Toolbar >
    );
    const linkStatus = (status) => {
        let color = '';
        let icon = ''
        let label = ''
        if (status == "1") {
            color = 'orange';
            icon = <ExclamationTriangleIcon />
            label = 'Pending'
        }
        else if (status == "2") {
            color = 'green';
            icon = <CheckCircleIcon />
            label = 'Completed'
        }
        else if (status == "3") {
            color = 'red';
            icon = <ExclamationCircleIcon />
            label = 'Expired'
        }
        return (
            <Label isCompact icon={icon} color={color}> {label}</Label>
        );
    };


    // const verifyLink = async (uniqueId, CreatedBy) => {
    //     console.log(Linkredirect)
    //     console.log(uniqueId)
    //     let param = {
    //         created_by: CreatedBy,
    //         unique_id: uniqueId
    //     }
    //     try {
    //         let res = await dbApi.postApi("Opensearch/Verify_OnBoard_Link", param)
    //         console.log(res)
    //         if (res.Verify_OnBoard_Link.error) {
    //             dispatch(alertBoxData([{ title: res.Verify_OnBoard_Link.message, variant: "danger" }]));
    //         } else {
    //             dispatch(alertBoxData([{ title: res.Verify_OnBoard_Link.message, variant: "success" }]));
    //             // navigate(`${Linkredirect}/#/onboardingform/${uniqueId}`)
    //             window.open(`${Linkredirect}/#/onboardingform/${uniqueId}`, '_blank');
    //         }
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    const finalDelete = async (uniqueId) => {
        try {
            let param = {
                unique_id: uniqueId
            }
            let res = await dbApi.deleteApi("Opensearch/Delete_OnBoard_Link", param)
            console.log('delete', res)
            if (!res.Delete_OnBoard_Link.error) {
                dispatch(alertBoxData([{ title: res.Delete_OnBoard_Link.message, variant: 'success' }]));
                getAllLinkList()
            }
            else {
                dispatch(alertBoxData([{ title: res.Delete_OnBoard_Link.message, variant: 'danger' }]))
            }
        } catch (error) {
            console.log(error)
        }

    }
    const deleteLink = (uniqueId) => {
        setConfirmBox({
            show: true,
            msg: 'This link will be deleted from your list',
            funCall: finalDelete,
            funParam: uniqueId,
            title: 'Permanently delete link?',
            position: 'default',
            iconvariant: 'warning',
            submitbtnvariant: 'danger',
        })
    }

    // const handleCopy = (unique_id) => {
    //     const textToCopy = `${Linkredirect}/#/onboardingform/${unique_id}`;
    //     var textField = document.createElement('textarea')
    //     textField.innerText = textToCopy
    //     document.body.appendChild(textField)
    //     textField.select()
    //     document.execCommand('copy')
    //     setCopytip('Copied!')
    //     textField.remove()
    // };
    return (
        <React.Fragment>
            <PageSection isFilled padding={{ default: 'noPadding' }} className='pf-v5-u-p-sm'>
                <ConfirmBox confirmModal={confirmModalData} />
                {tableToolbar}
                {isLoading ? <Loader /> :
                    <InnerScrollContainer >
                        <Table variant='compact' isStickyHeader gridBreakPoint="grid-md" >
                            <Thead>
                                <Tr key={"header-row"}>
                                    {Object.values(columns).map((column, columnIndex) => {
                                        const stickyColumnsIndex = [0, 1];
                                        const isSticky = stickyColumnsIndex.includes(columnIndex);
                                        const sortParams = {
                                            sort: {
                                                sortBy: {
                                                    index: activeSortIndex,
                                                    direction: activeSortDirection
                                                },
                                                onSort,
                                                columnIndex
                                            }
                                        };
                                        return !excludeOrder.includes(column) ?
                                            <Th key={columnIndex} {...sortParams} modifier="wrap" isStickyColumn={isSticky} >{column}</Th>
                                            : <Th isStickyColumn={isSticky} key={columnIndex} modifier="wrap">{column}</Th>;
                                    })}
                                </Tr>
                            </Thead>
                            <Tbody>
                                {rows.length > 0 ?
                                    rows && rows.map((row, rowIndex) => {
                                        const { formattedTimeStamp } = formatTableTimestamp(row?.last_datetime)
                                        return (
                                            <>
                                                <Tr key={rowIndex}
                                                    onRowClick={() => setSelectedRowName(rowIndex)}
                                                    isSelectable
                                                    isClickable
                                                    isRowSelected={selectedRowName == rowIndex}
                                                >
                                                    <Td dataLabel="Created By" key={rowIndex + 'CreatedBy'}>{row?.CreatedBy}</Td>
                                                    {/* unique_id */}
                                                    <Td dataLabel="Link" key={rowIndex + 'link'}>
                                                        {
                                                            row?.status == '1' ?
                                                                <>
                                                                    <a href={`${Linkredirect}/#/onboardingform/${row?.unique_id}`} target="_blank" rel="noopener noreferrer">{row?.unique_id}</a>
                                                                    <Button variant="plain" aria-label="Action" onClick={() => { handleCopy(`${Linkredirect}/#/onboardingform/${row?.unique_id}`);setCopytip('Copied!') }}><Tooltip content={copyTip}><CopyIcon /></Tooltip></Button>

                                                                </>
                                                                :
                                                                row?.unique_id
                                                        }
                                                        {/* <a href={"http://10.17.17.22:3001/#/onboardingform/" + row?.unique_id} target="_blank" rel="noopener noreferrer">{row?.unique_id}</a> */}
                                                        {/* <a onClick={() => { verifyLink(row?.unique_id, row?.CreatedBy) }}>{row?.unique_id}</a> */}
                                                    </Td>
                                                    <Td dataLabel="Created By" key={rowIndex + 'teamname'}>{formattedTimeStamp}</Td>
                                                    <Td dataLabel="Status" key={rowIndex + 'status'}>{linkStatus(row?.status)}</Td>
                                                    <Td dataLabel="Action" key={rowIndex + 'action'}>
                                                        <Flex spaceItems={{ default: 'spaceItemsSm' }}>
                                                            <FlexItem key={'delref'}>
                                                                <Tooltip content='delete'><Icon status='danger' onClick={() => deleteLink(row?.unique_id)} ><TrashAltIcon /></Icon></Tooltip>
                                                            </FlexItem>
                                                        </Flex>
                                                    </Td>
                                                </Tr>
                                            </>
                                        )
                                    }) :
                                    <Tr key='empty-row'>
                                        <Td colSpan={10} key='empty-data'>
                                            <Emptystatefornodata />
                                        </Td>
                                    </Tr>
                                }
                            </Tbody>
                        </Table>
                    </InnerScrollContainer>
                }
                {renderPagination('bottom')}
            </PageSection>
        </React.Fragment>

    )
}
export default OnBoardingMainpage

