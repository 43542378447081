//2nd which is perfectly run and usable
import React, { useEffect, useState } from "react";

import {
    ExpandableRowContent,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    Table,
} from "@patternfly/react-table";
import {
    Badge,
    ListItem,
    List,
    Pagination,
    ListVariant,
    FlexItem,
    Flex,
} from "@patternfly/react-core";
import { generateUniqueID } from "../../util/UniqueIdGenerator";
import { Emptystatefornodata } from "../../util/EmptyStateCard";
import { UpdateFilterData } from "src/util/FilterHelper";
import { FilterData } from "src/Redux/Action";
import { connect, useDispatch } from "react-redux";

// const { forwardRef, useRef, useImperativeHandle } = React;

 const ConnectionSummary = (props) => {
    const dispatch = useDispatch()
    const expandedColumnName = {
        srcport: 'Source Port',
        proto: 'Protocol',
        app: 'Application',
        dstport: 'Destination Port',
        dstip: 'Destination IP',

    };

    const defaultColumns = {
        srcip: "Source IP",
        uniquetag: "Application & Protocol",
    };
    // const [loading, setLoading] = useState(true);
    const [rows, setRows] = useState([]);
    const initialExpandedRowNames = [];
    const [expandedRowNames, setExpandedRowNames] = React.useState(initialExpandedRowNames);
    const [expandedRowData, setExpandedRowData] = useState({});
    const [selectedRowName, setSelectedRowName] = React.useState('');
    const [page, setPage] = React.useState(1);
    const [perPage, setPerPage] = React.useState(5);
    const resetPagination = () => {
        setPage(1);
        setPerPage(5); // Set to your desired default perPage value
    };

    const onSetPage = (_event, newPage) => {
        setPage(newPage);
    };
    const onPerPageSelect = (_event, newPerPage, newPage) => {
        setPerPage(newPerPage);
        setPage(newPage);
    };
    //useEffect for main data 
    useEffect(() => {
        const newdefaultRows = props.Tablelog.map((log) => {
            const rowid = generateUniqueID();
            const exrowid = generateUniqueID();
            const details = log.data.map((data) => ({
                id: exrowid,
                srcport: `${data.srcport.key} (${data.srcport.value})`,
                proto: `${data.proto.key} (${data.proto.value})`,
                app: `${data.application.key} (${data.application.value})`,
                dstport: `${data.dstport.key} (${data.dstport.value})`,
                dstip: `${data.dstip.key} (${data.dstip.value})`,
            }));
            return {
                id: rowid + 'iplog',
                srcip: log.key,
                srccount: log.count,
                uniquetag: log.unique,
                details: details
            };
        });
        setRows(newdefaultRows);
       
    }, [props.Tablelog, rows.length]);

   
    //code for row  expansion 
    const setRowExpanded = (row, isExpanding) => {
        resetPagination();
        const otherExpandedRowNames = expandedRowNames.filter((r) => r !== row.id);
        setExpandedRowNames(
            isExpanding ? [row.id] : []
        );
        if (!expandedRowData[row.id]) {
            const keyifiedData = keyify(row?.details);
            setExpandedRowData({ [row.id]: keyifiedData });
        }
        // setRowExpanded(row, !isRowExpanded(row));
        const updatedExpandedRowNames = isRowExpanded(row)
            ? otherExpandedRowNames
            : [row.id];
        setExpandedRowNames(updatedExpandedRowNames);

    };
    const isRowExpanded = (row) => {
        return expandedRowNames.includes(row.id);
    };


    const keyify = (arr) => {
        const result = [];

        for (let i = 0; i < arr.length; i++) {
            const item = arr[i];
            const sourceport = item.srcport;
            const app = item.app;
            const protocol = item.proto;
            const dstport = item.dstport;
            const dstip = item.dstip;

            // Push the extracted properties into the result array
            result.push(
                { sourceport: sourceport, app: app, protocol: protocol, dstport: dstport, dstip: dstip },
            );
        }
        return result;
    };
    //Code for nested table
    const NestedTable = ({ UniqueID, page, perPage }) => {
        // console.log("gchgcj",UniqueID)
        const startIndex = (page - 1) * perPage;
        const endIndex = startIndex + perPage;
        const totalrecord=UniqueID?.length

        return (<>
            {renderPagination('top', totalrecord)}
            <Table aria-label="Simple table" variant="compact">
                <Thead>
                    <Tr>
                        {Object.keys(expandedColumnName).map(key => (
                            <Th key={key}>{expandedColumnName[key]}</Th>
                        ))}
                    </Tr>
                </Thead>
                {UniqueID && UniqueID.slice(startIndex, endIndex).map((item, index) => (
                    <React.Fragment key={'data' + index}>
                        <Tbody key={index}>
                            <Tr key={'exrow' + index}>
                                <Td width={20} dataLabel="Source Port ">{item.sourceport}</Td>
                                <Td width={20} dataLabel="Protocol">{item.protocol}</Td>
                                <Td width={20} dataLabel="Application">{item.app}</Td>
                                <Td width={20} dataLabel="Destination Port ">{item.dstport}</Td>
                                <Td width={20} dataLabel="Destination IP ">{item.dstip}</Td>
                            </Tr>
                        </Tbody>

                    </React.Fragment>
                ))}
            </Table>
            {renderPagination('bottom', totalrecord)}
            </>
        )
    }

    //pagination code
    const renderPagination = (variant, itemcount) => <Pagination
        itemCount={itemcount}
        perPage={perPage}
        page={page}
        onSetPage={onSetPage}
        variant={variant}
        onPerPageSelect={onPerPageSelect}
    />

    const handleFilter = (fields, value) => {
        const updatedFields = UpdateFilterData(props.filterformdata, fields, 'is', value);
        dispatch(FilterData(updatedFields));
      }
    return (
        <React.Fragment>
            {props.Tablelog.length == 0 ?
                (<Emptystatefornodata />)
                : (<>
                   {/* { renderPagination('top', rows?.length)} */}
                    <Table variant="compact"
                        aria-label="anamolyalerts"
                        id="anamolyalerts-log-table"
                        isStickyHeader
                        gridBreakPoint="grid-md"
                    >
                        <Thead key={`th-${rows.id}`}>
                            <Tr key={`head-${rows.id}`} >
                              <Th key={'expand'} aria-label='expandall' />
                                {Object.keys(defaultColumns).map(key => (
                                    <Th key={key}>{defaultColumns[key]}</Th>
                                ))}
                            </Tr>
                        </Thead>
                        {rows.length > 0 && rows.map((row, rowIndex) => (
                            <Tbody isExpanded={isRowExpanded(row)} key={rowIndex}>
                                <Tr
                                    key={`row-${rowIndex}`}
                                    onRowClick={() => setSelectedRowName(rowIndex)}
                                    isSelectable
                                    isClickable
                                    isRowSelected={selectedRowName === rowIndex}   >
                                    <Td
                                        expand={
                                            row.details
                                                ? {
                                                    rowIndex: rowIndex,
                                                    isExpanded: isRowExpanded(row),
                                                    onToggle: () =>
                                                        setRowExpanded(row, !isRowExpanded(row)),
                                                }
                                                : undefined
                                        }
                                        key={"exp-" + rowIndex}
                                    >
                                        <ExpandableRowContent key={rowIndex}>
                                            {/* <pre> {keyify(row.details)} </pre> */}
                                            <pre> {row.details ?
                                                (keyify(row.details)) : (<p>No details available.</p>)}</pre>
                                        </ExpandableRowContent>
                                    </Td>
                                    <Td width={30} key={"srcip-" + rowIndex} dataLabel="Source IP" >
                                        <Flex spaceItems={{default:'spaceItemsXs'}} display={{default:'inlineFlex'}}>
                                            <FlexItem><a className="pf-v5-u-color-100" onClick={() => { handleFilter('data.srcip', row?.srcip) }}>{row?.srcip}</a></FlexItem>
                                            <FlexItem>({row?.srccount})</FlexItem>
                                        </Flex>
                                        </Td>
                                    <Td width={70} key={"appproto-" + rowIndex} dataLabel="Application & Protocol">
                                        {row?.uniquetag !== undefined && row?.uniquetag !== null ?
                                            (<List isPlain>
                                                {row?.uniquetag?.map((item, index) =>
                                                (<Badge key={index}><ListItem key={index}>{item}{''}</ListItem></Badge>
                                                ))} </List>)
                                            : (" ")}
                                    </Td>
                                </Tr>
                                <Tr isExpanded={isRowExpanded(row)} key={"exp-more-" + rowIndex} >
                                    <Td colSpan={11} noPadding key={`td-${rowIndex}`}>
                                        <ExpandableRowContent key={rowIndex}>
                                            {row.details && row.details.length > 0 ?
                                                (<> <NestedTable UniqueID={expandedRowData[row.id]} page={page} perPage={perPage} /> </>
                                                ) : (
                                                    <p>No details available</p>
                                                )
                                            }

                                        </ExpandableRowContent>
                                    </Td>
                                </Tr>
                            </Tbody>
                        ))}
                    </Table>
                    {/* { renderPagination('bottom', rows?.length)} */}
                    </>
                )
            }
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({
    dates: { startDate: state.startdate, endDate: state.enddate },
    filterformdata: state.FilterData,
    selectedGroup: state.SelectedGroup,
    allgroups: state.Allgroups,
    // mapStateToProps content remains unchanged if you have it
  });

  const mapDispatchToProps = (dispatch) => { return {} };
  export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
  )(ConnectionSummary)