import * as echarts from 'echarts';
import React, { useEffect } from 'react';
import { generateUniqueID } from '../../util/UniqueIdGenerator';
import { addInterval, calculateTimeDifference, formatTableTimestamp, formatTime, formatTimestamp } from '../../util/Timeutils';
import { formatValue } from '../../util/ChartHelper';
import { Emptystatefornodata } from '../../util/EmptyStateCard';
import { connect, useDispatch } from 'react-redux';
import { FilterData, StartEndDate } from '../../Redux/Action';
import { UpdateFilterData } from '../../util/FilterHelper';
const EventChart = ({ EventSeries, EventLabel, theme, StartDateTime, EndDateTime, sidebarState, RealTimeDashApi }) => {
  const id = generateUniqueID()
  const dispatch = useDispatch();
  useEffect(() => {
    if (EventSeries && EventSeries.length > 0) {

      const chartDom = document.getElementById(id + 'eventchart');
      const myChart = echarts.init(chartDom);
      const textcolor = theme == 'dark-theme' ? '#fff' : '#000'
      const linecolor = theme == 'dark-theme' ? '#595757' : '#d9d9d9'
      const { resultInMinutes, resultInHours, resultInDays } = calculateTimeDifference(StartDateTime, EndDateTime);

      const Label = EventLabel;
      const uniqueDatesSet = new Set();
      for (let i = 0; i < EventSeries.length; i++) {
        const xvalue = EventSeries[i].key_as_string;
        uniqueDatesSet.add(xvalue);
      }


      const { ConvertTimestamp: minct, hours: minhr, minutes: minmin, seconds: minsec } = formatTimestamp(StartDateTime);
      const minimumlabel = formatTime(resultInMinutes, resultInHours, resultInDays, minhr, minmin, minsec, minct);

      const { ConvertTimestamp, hours: maxhr, minutes: maxmin, seconds: maxsec } = formatTimestamp(EndDateTime);
      const maximumlabel = formatTime(resultInMinutes, resultInHours, resultInDays, maxhr, maxmin, maxsec, ConvertTimestamp);

      // console.log(minimumlabel)
      // console.log(maximumlabel)

      const xAxisData = []
      // const WholeDate = []
      const uniqueDatesArray = [...uniqueDatesSet];
      for (let i = 0; i < uniqueDatesArray.length; i++) {
        const { ConvertTimestamp, hours, minutes, seconds } = formatTimestamp(uniqueDatesArray[i]);
        const formattedTime = formatTime(resultInMinutes, resultInHours, resultInDays, hours, minutes, seconds, ConvertTimestamp);
        xAxisData.push({ x: formattedTime });
        // WholeDate.push({ Date: `${ConvertTimestamp}:${hours}:${minutes}:${seconds}` });
      }

      let interval = ''
      let lineheight = ''
      if (Label === '30 seconds') {
        interval = 4
        lineheight = 100
      }
      else if (Label === 'hour') {
        interval = 2
        lineheight = 110
      }
      else {
        interval = 1
        lineheight = 110
      }
      const option = {
        tooltip: {
          trigger: 'axis',
          textStyle: {
            color: "#000"
          },
        },
        title: {
          text: `Timestamp per ${Label}`,
          top: 'bottom',
          left: 'center',
          textStyle: {
            fontWeight: 450,
            fontSize: 14,
            color: textcolor,
            fontfamily: 'RedHatText, helvetica, arial, sans-serif',
          },

        },
        grid: {
          left: '2%',
          // right: '5%',
          bottom: '8%',
          top: '8%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: true,
          axisLabel: { interval: interval, rotate: -50, color: textcolor },
          data: xAxisData.map(item => item.x),
          axisTick: {
            alignWithLabel: true
          },
        },
        yAxis: {
          axisLine: {
            show: true
          }, splitLine: {
            lineStyle: {
              color: linecolor
            }
          },
          axisLabel: {
            formatter: formatValue,
            color: textcolor
          }
        },
        color: ['#0066cc'],
        series: [
          {
            name: 'count',
            data: EventSeries.map((item => ({
              name: item.key_as_string,
              value: item.doc_count
            }))),
            type: 'bar',
            barWidth: '100%',
          },
          // {
          //   type: 'line',
          //   data: EventSeries.map((item => ({
          //     name: item.key_as_string,
          //     value: item.doc_count
          //   })))
          // }
        ]
      };
      option && myChart.setOption(option);

      myChart.on('click', 'series.bar', function (param) {
        const startDate = param.name;
        console.log(startDate)
        const compareDates = EventSeries.find(item => {
          return item.key_as_string === startDate
        })

        if (compareDates) {
          // console.log("startDate", compareDates.key_as_string)
          let StartDate = new Date(compareDates.key_as_string);
          let clickInterval = ''
          let clickUnit = ''
          if (Label === '30 seconds') {
            clickInterval = 30
            clickUnit = 'seconds'
          }
          else if (Label === '30 minutes') {
            clickInterval = 30
            clickUnit = 'minutes'
          }
          else if (Label === 'hour') {
            clickInterval = 1
            clickUnit = 'hour'
          }
          else if (Label === 'day') {
            clickInterval = 24
            clickUnit = 'hour'
          }
          else if (Label === 'week') {
            clickInterval = 168
            clickUnit = 'hour'
          }
          else if (Label === 'year') {
            clickInterval = 365 * 24
            clickUnit = 'hour'
          }

          let EndDate = addInterval(StartDate, clickInterval, clickUnit);
          dispatch(StartEndDate(StartDate, EndDate, `${StartDate}-${EndDate}`));

        }
      });

      const resizeChart = () => {
        if (myChart) { // Check if chart exists before resizing
          myChart.resize();
        }
      };

    window.addEventListener('resize', resizeChart);
    return () => {
        myChart.dispose();
        window.removeEventListener('resize', resizeChart);
    };
    }

  }, [EventSeries, EventLabel, StartDateTime, EndDateTime, theme,sidebarState])

  return <> <div className="container">
    {EventSeries.length == 0 ?
      RealTimeDashApi ?
        <div id={id + 'eventchart'} style={{ width: '100%', height: '290px' }} > </div>
        :
        (<>
          <div id={id + 'eventchart'} style={{ width: '1px', height: '1px' }}> </div>
          < Emptystatefornodata />
        </>)
      : (
        <div id={id + 'eventchart'} style={{ width: '100%', height: '290px' }} > </div>
      )
    }
    {/* <div id={id + 'eventchart'} style={{ width: '100%', height: '290px' }} > </div> */}
  </div>
  </>
}
const mapStateToProps = (state) => ({
  dates: { startDate: state.startdate, endDate: state.enddate },
  filterformdata: state.FilterData,
  theme: state.theme,
  sidebarState:state.sidebarstate
  // mapStateToProps content remains unchanged if you have it
});

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(EventChart)