import React, { useEffect, useState } from 'react'
import * as echarts from 'echarts';
import { generateUniqueID } from '../../util/UniqueIdGenerator';
import { connect } from 'react-redux';
const SecurityHeatMap = (props) => {
    const [height, setHeight] = useState(0)
    const id = generateUniqueID()

    useEffect(() => {

        const chartDom = document.getElementById(id + 'hme');
        const headerDom = document.getElementById('stack-inline-masthead');
        var winHeight = window.innerHeight;
        const tabDom = document.getElementById('sec-tabs');
        const searchFilterDom = document.getElementById('search-filter');
        let totalReduce = headerDom.clientHeight + tabDom.clientHeight + searchFilterDom.clientHeight + 8
        props.severity.sort((a, b) => b.doc_count - a.doc_count)
        // Calculate the remaining blank space
        var remainingSpace = winHeight - totalReduce;
        chartDom.style.height = remainingSpace + 'px';
        const heightVal = remainingSpace

        const myChart = echarts.init(chartDom);


        // function transformData(input) {
        //     const severityLevels = {
        //         "Critical": [],
        //         "High": [],
        //         "Low": []
        //     };

        //     input.forEach(agent => {
        //         const agentID = agent.Agent_ID[0];
        //         agentID.level_ranges.buckets.forEach(bucket => {
        //             if (bucket.doc_count > 0) {
        //                 severityLevels[bucket.Status].push({
        //                     name: `${agent.key} (${agentID.key})`,
        //                     value: bucket.doc_count
        //                 });
        //             }
        //         });
        //     });

        //     return Object.keys(severityLevels).map(level => ({
        //         name: level,
        //         children: severityLevels[level],
        //         itemStyle: { color: getColorBySeverity(level) }
        //     }));
        // }

        // function getColorBySeverity(severity) {
        //     switch (severity) {
        //         case 'Critical': return '#A30000';
        //         case 'High': return '#f0ab00';
        //         case 'Low': return '#3E8635';
        //     }
        // }
        // const transformedData = transformData( props.severity);



        const option = {
            tooltip: {
                trigger: 'item',
                formatter: function (params) {
                    return params.data.name + `(${params.data.nestedkey})` + ' : ' + params.value;
                },

            },
            title: {
                show: false,
            },
            series: [
                {
                    textStyle: {
                        color: "#fff"
                    },
                    type: 'treemap',
                    layout: 'squarify',
                    roam: false,
                    squareRatio: 0.65,
                    visibleMin: 1,
                    width: "100%",
                    height: heightVal + 'px',
                    nodeClick: false,
                    sort: "true",
                    clipWindow: "origin",
                    animation: false,
                    itemStyle: {
                        borderWidth: 0,
                        gapWidth: 1
                    },
                    breadcrumb:
                        { show: false },
                    data: props.severity.map(bucket => {
                        let color;
                        let value = 0;
                        let nestedkey = '';
                        let itemStyleColor = '';
                        const docCount12AndAbove = bucket.Agent_ID[0].level_ranges.buckets.find(range => range.key === '12-and-above');
                        if (docCount12AndAbove && docCount12AndAbove.doc_count > 0) {
                            value = docCount12AndAbove.doc_count;
                            nestedkey = 'Critical';
                            itemStyleColor = '#A30000';
                        } else {
                            switch (bucket.Agent_ID[0].level_ranges.highest_doc_count_bucket.Status) {
                                case 'Critical':
                                    color = '#A30000';
                                    break;
                                case 'High':
                                    color = '#f0ab00';
                                    break;
                                case 'Low':
                                    color = '#3E8635';
                                    break;
                            }
                            value = bucket.Agent_ID[0].level_ranges.highest_doc_count_bucket.doc_count;
                            nestedkey = bucket.Agent_ID[0].level_ranges.highest_doc_count_bucket.Status;
                            itemStyleColor = color;
                        }
                        return {
                            value: value,
                            name: bucket.key,
                            nestedkey: nestedkey,
                            nestedkey1: bucket.Agent_ID[0].key,
                            itemStyle: {
                                color: itemStyleColor
                            }
                        };
                    })
                },
            ],
        };
        // Set chart options
        option && myChart.setOption(option);
        myChart.on('click', 'series.treemap', function (param) {
            window.location.href = "#/individual-agent-dashboard/" + param.data.name + '/' + param.data.nestedkey1;
        });
        // Clean up when component unmounts
        const resizeChart = () => {
            if (myChart) { // Check if chart exists before resizing
              myChart.resize();
            }
          };

        window.addEventListener('resize', resizeChart);
        return () => {
            myChart.dispose();
            window.removeEventListener('resize', resizeChart);
        };
    }, [props.severity,props.theme,props.sidebarState]);

    return (<div className="container">
        <div id={id + 'hme'} style={{ width: '100%', height: height + 'px' }}> </div>
    </div>
    )
}
const mapStateToProps = (state) => ({
    filterformdata: state.FilterData,
    sidebarState:state.sidebarstate,
    theme: state.theme,
});
export default connect(
    mapStateToProps,
    null,
    null,
    { forwardRef: true }
)(SecurityHeatMap)