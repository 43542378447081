import React, { Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import HomeRoutes from '../Routes/HomeRoutes'

const AppHomeContent = () => {
  return (
       <Suspense>
        <Routes>
        {HomeRoutes.map((route, idx) => {
          return <Route
                key={idx}
                path={route.path}
                exact={route.exact}
                name={route.name}
                element={route.element && <route.element />}
              />
        })}
        </Routes>
      </Suspense>
  )
}
export default React.memo(AppHomeContent)
