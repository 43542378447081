import React, { useState } from 'react';
import {
    InputGroup,
    InputGroupItem,
    DatePicker,
    isValidDate,
    TimePicker,
    yyyyMMddFormat,
    Form,
    FormGroup,
    ActionGroup,
    Button,
    FormHelperText,
    HelperText,
    HelperTextItem,
} from '@patternfly/react-core';
import { useDispatch } from 'react-redux';
import { StartEndDate } from '../../Redux/Action';

const DateTimeRangePicker = (props) => {
    const dispatch = useDispatch();
    const [startDate, setStartDate] = useState(props.initialStartDate)
    const [endDate, setEndDate] = useState(props.initialEndDate)

    const toValidator = (date) => {
        return isValidDate(startDate) && yyyyMMddFormat(date) >= yyyyMMddFormat(startDate)
            ? ''
            : `The End Date must be after the Start Date date'`;
    };

    console.log("start datek", startDate)
    console.log("end datek", endDate)

    const onStartDateChange = (_event, inputDate, newStartDate) => {
        if (isValidDate(startDate) && isValidDate(newStartDate) && inputDate === yyyyMMddFormat(newStartDate)) {
            newStartDate.setHours(startDate.getHours());
            newStartDate.setMinutes(startDate.getMinutes());
            newStartDate.setSeconds(startDate.getSeconds());
        }
        if (isValidDate(newStartDate) && inputDate === yyyyMMddFormat(newStartDate)) {
            setStartDate(new Date(newStartDate));
        }
    };

    const onStartTimeChange = (_event, time, hour, minute, second) => {
        if (isValidDate(startDate)) {
            const updateStartDate = new Date(startDate);
            updateStartDate.setHours(hour);
            updateStartDate.setMinutes(minute);
            updateStartDate.setSeconds(second);

            setStartDate(updateStartDate);
        }
    };

    const onEndDateChange = (_event, inputDate, newEndDate) => {
        if (isValidDate(endDate) && isValidDate(newEndDate) && inputDate === yyyyMMddFormat(newEndDate)) {
            newEndDate.setHours(endDate.getHours());
            newEndDate.setMinutes(endDate.getMinutes());
            newEndDate.setSeconds(endDate.getSeconds());
        }
        if (isValidDate(newEndDate) && inputDate === yyyyMMddFormat(newEndDate)) {
            setEndDate(newEndDate);
        }
    };

    const onEndTimeChange = (_event, time, hour, minute, second) => {
        if (isValidDate(endDate)) {
            const updateEndDate = new Date(endDate);
            updateEndDate.setHours(hour);
            updateEndDate.setMinutes(minute);
            updateEndDate.setSeconds(second)
            setEndDate(updateEndDate);
        }
    };


    const handleSubmit = () => {
        // setStartDate(startDate)
        // setEndDate(endDate)
        dispatch(StartEndDate(startDate, endDate, `${startDate}-${endDate}`));
        props.onDatesChange(startDate, endDate);
        props.onClose()
    }

    let CurrentDate = new Date()
    return (
        <>
            <Form>
                <FormGroup label='Start date' id='startdate'>
                    <InputGroup >
                        <InputGroupItem>
                            <DatePicker
                            value={isValidDate(startDate) ? yyyyMMddFormat(startDate) : ''}
                                onChange={onStartDateChange}
                                aria-label="Start date"
                                placeholder="YYYY-MM-DD"
                                validators={[date => date <= CurrentDate ? '' : 'Cannot select future dates']}
                            />
                        </InputGroupItem>
                        <InputGroupItem>
                            <TimePicker aria-label="Start time"
                                placeholder='hh:mm:ss' is24Hour
                                onChange={onStartTimeChange} 
                                time={isValidDate(startDate) ? `${startDate.getHours().toString().padStart(2, '0')}:${startDate.getMinutes().toString().padStart(2, '0')}:${startDate.getSeconds().toString().padStart(2, '0')}` : '00:00:00'}
                                includeSeconds stepMinutes={15} />
                        </InputGroupItem>
                    </InputGroup>
                </FormGroup>
                <FormGroup label='End date' id='enddate' >
                    <InputGroup >
                        <InputGroupItem>
                            <DatePicker
                                value={isValidDate(endDate) ? yyyyMMddFormat(endDate) : endDate}
                                onChange={onEndDateChange}
                                isDisabled={!isValidDate(startDate)}
                                rangeStart={startDate}
                                // validators={[toValidator]}
                                validators={[toValidator, date => date <= CurrentDate ? '' : 'Cannot select future dates']}
                                aria-label="End date"
                                placeholder="YYYY-MM-DD"
                            />
                        </InputGroupItem>
                        <InputGroupItem>
                            <TimePicker
                                // maxTime={date => date === CurrentDate ? `${currentHours}:${currentMinutes}` : null}
                                placeholder='hh:mm:ss' is24Hour
                                time={isValidDate(endDate) ? `${endDate.getHours().toString().padStart(2, '0')}:${endDate.getMinutes().toString().padStart(2, '0')}:${endDate.getSeconds().toString().padStart(2, '0')}` : '00:00:00'}
                                validators={[toValidator]}
                                onChange={onEndTimeChange} isDisabled={!isValidDate(startDate)} includeSeconds stepMinutes={15} />
                        </InputGroupItem>
                    </InputGroup>
                </FormGroup>
            </Form>
            <div className='pf-v5-u-mt-sm' style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <ActionGroup>
                    <Button
                        variant="secondary"
                        onClick={() => handleSubmit()}
                        isDisabled={startDate && endDate ? false : true}
                    >
                        Submit
                    </Button>
                </ActionGroup>
            </div>

        </>
    );
}
export default DateTimeRangePicker