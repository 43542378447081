import { Grid, GridItem, Spinner, Alert, Title, TextInput, Button, ToggleGroup, ToggleGroupItem, InputGroup, InputGroupItem, ActionList, ActionListItem, Card, CardBody, TextInputGroup, TextInputGroupMain, Icon, TextInputGroupUtilities, Badge, DescriptionList, DescriptionListGroup, DescriptionListTerm, DescriptionListDescription, InputGroupText, TextArea } from '@patternfly/react-core';
import { EditIcon, CheckIcon, TimesIcon, PlusCircleIcon } from '@patternfly/react-icons';
import React, { useState, useEffect } from 'react';
import dbApi from 'src/services/DbApi';
import { useParams } from 'react-router-dom';
import SingleDateTimePicker from 'src/Components/CommonEventPages/SingleDateTimePicker';
import CommonSelectBox from 'src/Components/CommonEventPages/CommonSelectBox';
import { ChipGroup, Chip } from '@patternfly/react-core';
import { Loader } from 'src/util/Loadingstate';
import { alertBoxData } from 'src/Redux/Action';
import { connect, useDispatch, useSelector } from 'react-redux';
import IndexInputGroup from 'src/Components/OnBoarding/IndexInputGroup';
import { formatTableTimestamp } from 'src/util/Timeutils';

const fieldMappings = {
    tlp_amber: 'tlpAmber',
    pap_amber: 'papAmber',
};

const IncidentDetail = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [title, setTitle] = useState('');
    const [severity, setSeverity] = useState('');
    const [tlpAmber, setTlpAmber] = useState('');
    const [papAmber, setPapAmber] = useState('');
    const [startDatetime, setStartDatetime] = useState('');
    const [dateTime, setDateTime] = useState();
    const [status, setStatus] = useState('');
    const [investigatorList, setInvestigatorList] = useState([]);
    const [selectedInvestigatorValue, setSelectedInvestigatorValue] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [editingField, setEditingField] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [description, setDescription] = useState('')
    const [inputDescription, setinputDescription] = useState('');
    const [originalValues, setOriginalValues] = useState({});
    const [date, setDate] = useState('');
    const [time, setTime] = useState('');
    // const [date, setDate] = useState('');
    // const [time, setTime] = useState('');
    const [tags, setTags] = useState([]);
    const [newTag, setNewTag] = useState('');
    const [inputTag, setInputTag] = useState('');
    const [showtags, setShowtags] = useState(true);
    const [isEditMode, setIsEditMode] = useState(false);
    const [createUserName, setCreateUserName] = useState('');
    const [readStatus, setReadStatus] = useState('');
    const [tempStartDatetime, setTempStartDatetime] = useState(null);
    // const [showClearButton, setshowClearButton] = useState();
    // const [showUtilities, setshowUtilities] = useState();


    const getIncidentDetail = async () => {
        try {
            setIsLoading(true);
            const res = await dbApi.postApi("incident_management/get_incident", { incidentId: id });
            if (res.data && res.data.length > 0) {
                const detail = res.data[0];
                setTitle(detail.title);
                setDescription(detail.description);
                setSeverity(detail.severity);
                setTlpAmber(detail.tlp_amber);
                setPapAmber(detail.pap_amber);
                setStartDatetime(detail.start_datetime);
                setDateTime(new Date(detail.start_datetime));
                setStatus(detail.status);
                setCreateUserName(detail.create_by_user);
                setReadStatus(detail.read_status);
                // setTags(detail.tags || []);
                const tags = detail.tags.map((obj) => ({
                    uniqueId: obj.unique_id,
                    tag: obj.tag,
                }
                ));
                setTags(tags)
                setSelectedInvestigatorValue(detail.user_id); // Adjust as per your data structure
            }
            setIsLoading(false);
        } catch (error) {
            setError(error);
            setIsLoading(false);
        }
    };

    const getInvestigatorList = async () => {
        try {
            const res = await dbApi.postApi("user/get_user_list", { status: '1', role: 'Investigator' });
            console.log('investigator', res);
            setInvestigatorList(res.data || []);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (id) {
            getIncidentDetail();
            getInvestigatorList();
        }
    }, [id]);

    // const selectedInvestigator = investigatorList.find(item => item.user_id === selectedInvestigatorValue);
    // const selectedInvestigatorName = selectedInvestigator ? selectedInvestigator.name : 'No investigator selected';

    // const handleEditClick = (fieldName) => {
    //     setEditingField(fieldName);
    //     if (fieldName === 'startDatetime') {
    //         // Assuming startDatetime is in ISO format or similar
    //         const [startDate, startTime] = startDatetime ? startDatetime.split('T') : ['', ''];
    //         const formattedTime = startTime ? startTime.split('.')[0] : '';
    //         setDate(startDate || '');
    //         setTime(formattedTime || '');
    //     } else {
    //         setInputValue(fieldMappings[fieldName] || '');
    //     }

    //     // Store the original values before editing starts
    //     setOriginalValues((prevValues) => ({
    //         ...prevValues,
    //         [fieldName]: {
    //             title, severity, tlpAmber, papAmber, startDatetime, status, description
    //         }[fieldName]
    //     }));

    //     switch (fieldName) {
    //         case 'title':
    //             setInputValue(title);
    //             break;
    //         case 'description':
    //             setInputValue(description);
    //             break;
    //         case 'severity':
    //             setInputValue(severity);
    //             break;
    //         case 'tlpAmber':
    //             setInputValue(tlpAmber);
    //             break;
    //         case 'papAmber':
    //             setInputValue(papAmber);
    //             break;
    //         // case 'startDatetime':
    //         //     const { formattedDate, formattedTime } = startDatetime
    //         //         ? formatTableTimestamp(startDatetime)
    //         //         : { formattedDate: '', formattedTime: '' };
    //         //     // Handle datetime values if needed
    //         //     break;
    //         case 'startDatetime':
    //              const [startDate, startTime] = startDatetime.split('T');
    //              const formattedTime = startTime ? formatTableTimestamp(startDatetime) : { formattedDate: '', formattedTime: '' };; // Remove milliseconds and timezone if present
    //             setDate(startDatetime || '');
    //             setTime(startDatetime || '');
    //             break;
    //         case 'status':
    //             setInputValue(status);
    //             break;
    //         default:
    //             setInputValue('');
    //     }
    // };

    const handleEditClick = (fieldName) => {
        setEditingField(fieldName);

        if (fieldName === 'startDatetime') {
            // Assuming startDatetime is in ISO format
            const [startDate, startTime] = startDatetime ? startDatetime.split('T') : ['', ''];
            // Ensure startTime is formatted correctly
            const formattedTime = startTime ? startTime.split('.')[0] : ''; // Remove milliseconds if present
            setDate(startDate || '');
            setTime(formattedTime || '');
        } else {
            setInputValue(fieldMappings[fieldName] || '');
        }

        // Store the original values before editing starts
        setOriginalValues((prevValues) => ({
            ...prevValues,
            [fieldName]: {
                title, severity, tlpAmber, papAmber, startDatetime, status, description
            }[fieldName]
        }));

        switch (fieldName) {
            case 'title':
                setInputValue(title);
                break;
            case 'description':
                setInputValue(description);
                break;
            case 'severity':
                setInputValue(severity);
                break;
            case 'tlpAmber':
                setInputValue(tlpAmber);
                break;
            case 'papAmber':
                setInputValue(papAmber);
                break;
            case 'startDatetime':
                const [startDate, startTime] = startDatetime;
                // const formattedTime = startTime ? startTime.split('.')[0] : ''; // Remove milliseconds if present
                setDate(startDate || '');
                setTime(startTime || '');
                break;
            case 'status':
                setInputValue(status);
                break;
            default:
                setInputValue('');
        }
    };

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleSaveClick = async (fieldName) => {
        try {
            const params = { incidentId: id };
            let updatedValue;

            if (fieldName === 'startDatetime') {
                // Combine date and time to form ISO 8601 string
                const formattedDatetime = `${date}T${time}`;  // Ensure proper formatting
                updatedValue = formattedDatetime;
                console.log('date and time updatedValue ', updatedValue)
            } else {
                updatedValue = inputValue;  // Use input value for other fields
            }

            // Update the field's actual value
            switch (fieldName) {
                case 'title':
                    setTitle(updatedValue);
                    break;
                case 'description':
                    setDescription(updatedValue);
                    break;
                case 'severity':
                    setSeverity(updatedValue);
                    break;
                case 'tlpAmber':
                    setTlpAmber(updatedValue);
                    break;
                case 'papAmber':
                    setPapAmber(updatedValue);
                    break;
                case 'startDatetime':
                    setStartDatetime(updatedValue);
                    break;
                case 'status':
                    setStatus(updatedValue);
                    break;
                default:
                    break;
            }

            params[fieldMappings[fieldName] || fieldName] = updatedValue;
            await dbApi.putApi("incident_management/update_incident", params);
            setEditingField(null);
            getIncidentDetail();
        } catch (error) {
            console.error('Failed to update incident:', error);
        }
    };
    const handleCancelClick = () => {
        if (editingField === 'startDatetime') {
            // Restore original date and time
            setDate(originalValues.startDatetime.date);
            setTime(originalValues.startDatetime.time);
        } else {
            setInputValue(originalValues[editingField]);
        }
        setEditingField(null);
    };

    // const handleSaveClick = async (fieldName) => {
    //     let updatedValue = inputValue;
    //     try {
    //         updatedValue = JSON.parse(inputValue);
    //     } catch (error) {
    //         console.log(error)
    //     }

    //     switch (fieldName) {
    //         case 'title':
    //             setTitle(updatedValue);
    //             break;
    //         case 'severity':
    //             setSeverity(updatedValue);
    //             break;
    //         case 'tlpAmber':
    //             setTlpAmber(updatedValue);
    //             break;
    //         case 'papAmber':
    //             setPapAmber(updatedValue);
    //             break;
    //         case 'startDatetime':
    //             setStartDatetime(updatedValue);
    //             break;
    //         case 'status':
    //             setStatus(updatedValue);
    //             break;
    //         default:
    //             break;
    //     }

    //     if (fieldName === 'startDatetime') {
    //         updatedValue = startDatetime;
    //         // console.log('date&time', date) // Adjust format based on your API's requirements
    //     }
    //     const params = { incidentId: id };
    //     if (fieldName === 'investigator') {
    //         params.investigatorId = selectedInvestigatorValue;
    //         console.log('investigatoe selelcted:', selectedInvestigatorValue)
    //     } else {
    //         params[fieldMappings[fieldName] || fieldName] = updatedValue;
    //     }

    //     try {
    //         await dbApi.putApi("incident_management/update_incident", {
    //             incidentId: id,
    //             [fieldMappings[fieldName] || fieldName]: updatedValue
    //         });
    //         setEditingField(null);
    //         getIncidentDetail()
    //     } catch (error) {
    //         console.error('Failed to update incident:', error);
    //     }
    // };
    // const handleCancelClick = () => {
    //     setEditingField(null);

    // };

    // const handleToggleChange = (fieldName, value) => {
    //     switch (fieldName) {
    //         case 'severity':
    //             setSeverity(value);
    //             break;
    //         case 'tlpAmber':
    //             setTlpAmber(value);
    //             break;
    //         case 'papAmber':
    //             setPapAmber(value);
    //             break;
    //         case 'status':
    //             setStatus(value);
    //             break;
    //         default:
    //             break;
    //     }
    //     setInputValue(value);

    //     console.log(`Toggled ${fieldName} to ${value}`);
    // };
    const handleToggleChange = (fieldName, value) => {
        // Temporarily set the input value to the toggle selection without updating the actual value
        setInputValue(value);

        console.log(`Toggled ${fieldName} to ${value}`);
    };


    const handleUpdateInvestigatorValue = (value) => {
        console.log('updated------inv', selectedInvestigatorValue)
        setSelectedInvestigatorValue(value);
    };

    const handleDateTimeChange = (newDate, newTime) => {
        setDate(newDate);  // Update date state
        setTime(newTime);  // Update time state
    };

    const fields = [
        { label: 'Title', name: 'title', value: title },
        { label: 'Description', name: 'description', value: description },
        { label: 'Severity', name: 'severity', value: severity, options: ['L', 'M', 'H', 'C'] },
        { label: 'TLP', name: 'tlpAmber', value: tlpAmber, options: ['WHITE', 'AMBER', 'RED', 'GREEN'] },
        { label: 'PAP', name: 'papAmber', value: papAmber, options: ['WHITE', 'AMBER', 'RED', 'GREEN'] },
        {
            label: 'Start Date',
            name: 'startDatetime',
            value: formatTableTimestamp(startDatetime).formattedTimeStamp  // Format date and time
        },
        { label: 'Status', name: 'status', value: status, options: ['Open', 'Close', 'Waiting'] },
    ];
    //Tags input add and remove code
    const handleInputTags = (event, value) => {
        setInputTag(value.trim());
    };
    const handleinvEditClick = () => {
        if (isEditMode) {
            handleInvestigatorUpdate(); // Save changes when exiting edit mode
        }
        setIsEditMode(!isEditMode); // Toggle edit mode
    };

    const deleteTag = tagToDelete => {
        console.log("1", tagToDelete)
        const newTags = tags.filter(tag => tag.uniqueId !== tagToDelete);
        console.log(newTags)
        setTags(newTags);
    };
    const clearTagsAndInput = () => {
        setTags([]);
        setInputTag('');
    };
    const addTag = newTagText => {
        const newTag = { uniqueId: '', tag: newTagText };
        setTags([...tags, newTag]);
        setInputValue('');
    };

    const handleEnter = () => {
        if (inputTag.length) {
            addTag(inputTag);
        }
    };
    const handleTextInputKeyDown = event => {
        if (event.key === 'Enter') {
            handleEnter();
        }
    };
    const showClearButton = !!inputTag || !!tags.length;
    const showUtilities = showClearButton;

    // const handleTagInputChange = (value) => {
    //     setNewTag(value.target.value);
    // };

    const handleAddTag = async () => {
        setShowtags(true)
        let params = {
            incidentId: id,
            tags: tags
        };
        try {
            const res = await dbApi.putApi('incident_management/update_tags', params);
            if (res.error) {
                dispatch(alertBoxData([{ title: res.message, variant: 'danger' }]));
            } else {
                await getIncidentDetail()
                dispatch(alertBoxData([{ title: res.message, variant: 'success' }]));
            }
        } catch (error) {
            console.error('Failed to update tags:', error);
            setIsLoading(false);
        }
    };

    const handleInvestigatorUpdate = async () => {
        setShowtags(true);
        const selectedInvestigator = investigatorList.find(item => item.user_id === selectedInvestigatorValue);

        if (!selectedInvestigator) {
            dispatch(alertBoxData([{ title: 'Please select a valid investigator.', variant: 'danger' }]));
            return;
        }
        const userId = selectedInvestigator.user_id;
        let params = {
            incidentId: id,
            investigatorId: userId
        };

        try {
            const res = await dbApi.putApi('incident_management/update_incident_investigator', params);
            console.log('Investigator update response:', res);

            if (res.error) {
                dispatch(alertBoxData([{ title: res.message, variant: 'danger' }]));
            } else {
                await getIncidentDetail();
                dispatch(alertBoxData([{ title: res.message, variant: 'success' }]));
            }
        } catch (error) {
            console.error('Failed to update investigator:', error);
            setIsLoading(false);
        }
    };

    const tlpColorMap = {
        WHITE: { backgroundColor: '#00c0ef', color: '#000' },
        AMBER: { backgroundColor: '#ffbf00', color: '#000' },
        GREEN: { backgroundColor: '#008000', color: '#fff' },
        RED: { backgroundColor: '#C9190B', color: '#fff' }
    };

    const extendedTlpColorMap = {
        ...tlpColorMap, // Existing color mappings
        L: { backgroundColor: '#00c0ef', color: '#fff' }, // Blue
        M: { backgroundColor: '#ffbf00', color: '#000' }, // Yellow
        H: { backgroundColor: '#C9190B', color: '#fff' }, // Red
        C: { backgroundColor: '#8B0000', color: '#fff' },// Dark Red
        Open: { backgroundColor: '#00c0ef', color: '#fff' },  // Blue
        Close: { backgroundColor: '#C9190B', color: '#fff' }, // Red
        Waiting: { backgroundColor: '#ffbf00', color: '#000' } // Yellow
    };

    const getBadgeProps = (status) => {
        if (status === 0) {
            return { text: 'Unread', color: '#C9190B' };
        } else if (status === 1) {
            return { text: 'Read', color: '#00c0ef' };
        }
        return { text: 'Unknown', color: 'grey' }; // fallback for unexpected status
    };

    const statusBadge = getBadgeProps(readStatus);
    // const formattedDateTime = formatTableTimestamp(`${date}T${time}`).formattedTimeStamp;
    //  const { formattedTimeStamp: createDatetime } = formatTableTimestamp(`${date}T${time}`);

    // console.log(`Formatted DateTime: ${formattedDateTime}`);

    return (
        <div>
            <Card >
                <CardBody >
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <>
                            <Grid hasGutter>
                                {/* Left Section */}
                                <GridItem span={6}>
                                    <DescriptionList isHorizontal>
                                        {fields.filter(field => !field.options).map((field, index) => (
                                            <DescriptionListGroup key={index}>
                                                <DescriptionListTerm className="pf-v5-u-font-weight-bold">
                                                    {field.label}:
                                                </DescriptionListTerm>
                                                <DescriptionListDescription>
                                                    {/* Check if the field is being edited */}
                                                    {editingField === field.name ? (
                                                        // If it's a datetime field, render the datetime picker
                                                        field.name === 'startDatetime' ? (
                                                            <InputGroup>
                                                                <SingleDateTimePicker
                                                                    date={startDatetime}  // Display the current date
                                                                    time={startDatetime}  // Display the current time
                                                                    onDateChange={(newDate) => handleDateTimeChange(newDate, startDatetime)}
                                                                    onTimeChange={(newTime) => handleDateTimeChange(startDatetime, newTime)}
                                                                />
                                                                <InputGroupItem>
                                                                    <Button variant="plain" onClick={() => handleSaveClick(field.name)}>
                                                                        <CheckIcon />
                                                                    </Button>
                                                                </InputGroupItem>
                                                                <InputGroupItem>
                                                                    <Button variant="plain" onClick={handleCancelClick}>
                                                                        <TimesIcon />
                                                                    </Button>
                                                                </InputGroupItem>
                                                            </InputGroup>
                                                        ) : field.name === 'description' ? (
                                                            // For description, render a TextArea instead of TextInput
                                                            <InputGroup>
                                                                <TextArea
                                                                    className="pf-v5-u-mb-md"
                                                                    value={inputValue}
                                                                    onChange={handleInputChange}
                                                                    aria-label={`Edit ${field.label}`}
                                                                    resizeOrientation="vertical" // Allows vertical resizing of the textarea
                                                                />
                                                                <InputGroupItem>
                                                                    <Button variant="plain" onClick={() => handleSaveClick(field.name)}>
                                                                        <CheckIcon />
                                                                    </Button>
                                                                </InputGroupItem>
                                                                <InputGroupItem>
                                                                    <Button variant="plain" onClick={handleCancelClick}>
                                                                        <TimesIcon />
                                                                    </Button>
                                                                </InputGroupItem>
                                                            </InputGroup>
                                                        ) : (
                                                            // For standard text input fields
                                                            <InputGroup>
                                                                <TextInput
                                                                    className="pf-v5-u-mb-md"
                                                                    value={inputValue}
                                                                    onChange={handleInputChange}
                                                                    aria-label={`Edit ${field.label}`}
                                                                />
                                                                <InputGroupItem>
                                                                    <Button variant="plain" onClick={() => handleSaveClick(field.name)}>
                                                                        <CheckIcon />
                                                                    </Button>
                                                                </InputGroupItem>
                                                                <InputGroupItem>
                                                                    <Button variant="plain" onClick={handleCancelClick}>
                                                                        <TimesIcon />
                                                                    </Button>
                                                                </InputGroupItem>
                                                            </InputGroup>
                                                        )
                                                    ) : (
                                                        // If not editing, show the field value
                                                        <div>
                                                            {field.value ? (
                                                                <>
                                                                    {field.options ? (
                                                                        <Badge
                                                                            style={{
                                                                                backgroundColor: extendedTlpColorMap[field.value]?.backgroundColor || '#fff',
                                                                                color: extendedTlpColorMap[field.value]?.color || '#000',
                                                                                padding: '2px 4px',
                                                                                borderRadius: '2px',
                                                                                fontSize: '13px',
                                                                            }}
                                                                        >
                                                                            {field.value}
                                                                        </Badge>
                                                                    ) : (
                                                                        <span>{field.value}</span>
                                                                    )}
                                                                    <Button variant="plain" onClick={() => handleEditClick(field.name)}>
                                                                        <EditIcon />
                                                                    </Button>
                                                                </>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </div>
                                                    )}

                                                </DescriptionListDescription>
                                            </DescriptionListGroup>
                                        ))}

                                    </DescriptionList>
                                </GridItem>

                                {/* Right Section */}
                                <GridItem span={6}>
                                    <DescriptionList isHorizontal>
                                        {/* Fields with Options */}
                                        {fields.filter(field => field.options).map((field, index) => (
                                            <DescriptionListGroup key={index}>
                                                <DescriptionListTerm className="pf-v5-u-font-weight-bold">
                                                    {field.label}:
                                                </DescriptionListTerm>
                                                <DescriptionListDescription>
                                                    {editingField === field.name ? (
                                                        <InputGroup>
                                                            <ToggleGroup
                                                                isCompact
                                                                style={{ maxWidth: '300px', flexWrap: 'wrap', overflowX: 'auto' }}
                                                                className="custom-toggle-group"
                                                            >
                                                                {field.options.map((option, idx) => (
                                                                    <ToggleGroupItem
                                                                        key={idx}
                                                                        className="pf-v5-u-mr-xs"
                                                                        text={
                                                                            <div
                                                                                style={{
                                                                                    backgroundColor: extendedTlpColorMap[option]?.backgroundColor || '#fff',
                                                                                    color: extendedTlpColorMap[option]?.color || '#000',
                                                                                    padding: '2px 20px',
                                                                                    borderRadius: '4px',
                                                                                }}
                                                                            >
                                                                                {option}
                                                                            </div>
                                                                        }
                                                                        isSelected={inputValue === option}
                                                                        onChange={() => handleToggleChange(field.name, option)}
                                                                    />
                                                                ))}
                                                                <InputGroupItem>
                                                                    <Button variant="plain" onClick={() => handleSaveClick(field.name)}>
                                                                        <CheckIcon />
                                                                    </Button>
                                                                </InputGroupItem>
                                                                <InputGroupItem>
                                                                    <Button variant="plain" onClick={handleCancelClick}>
                                                                        <TimesIcon />
                                                                    </Button>
                                                                </InputGroupItem>
                                                            </ToggleGroup>
                                                        </InputGroup>
                                                    ) : (
                                                        <Badge
                                                            style={{
                                                                backgroundColor: extendedTlpColorMap[field.value]?.backgroundColor || '#fff',
                                                                color: extendedTlpColorMap[field.value]?.color || '#000',
                                                                padding: '2px 20px',
                                                                borderRadius: '2px',
                                                                fontSize: '13px',
                                                            }}
                                                        >
                                                            {field.value}
                                                        </Badge>
                                                    )}
                                                    <Button
                                                        variant="plain"
                                                        onClick={() => handleEditClick(field.name)}
                                                        style={{ display: editingField === field.name ? 'none' : 'inline-flex' }}
                                                    >
                                                        <EditIcon />
                                                    </Button>
                                                </DescriptionListDescription>
                                            </DescriptionListGroup>
                                        ))}
                                        {/* Read Staus */}
                                        <DescriptionListGroup>
                                            <DescriptionListTerm className="pf-v5-u-font-weight-bold">Read Status:</DescriptionListTerm>
                                            <DescriptionListDescription>
                                                <Badge style={{
                                                    backgroundColor: statusBadge.color, padding: '2px 20px',
                                                    borderRadius: '2px',
                                                    fontSize: '13px',
                                                }}>{statusBadge.text}</Badge>
                                            </DescriptionListDescription>
                                        </DescriptionListGroup>

                                        {/* Investigator Field */}
                                        <DescriptionListGroup>
                                            <DescriptionListTerm className="pf-v5-u-font-weight-bold">
                                                <Title headingLevel="h6">Investigator:</Title>
                                            </DescriptionListTerm>
                                            <DescriptionListDescription>
                                                {isEditMode ? (
                                                    <CommonSelectBox
                                                        options={investigatorList.map((item) => ({
                                                            value: item.user_id,
                                                            children: item.name,
                                                        }))}
                                                        selectedOptions={selectedInvestigatorValue}
                                                        updateValue={handleUpdateInvestigatorValue}
                                                        placeholder='Select Investigator'
                                                    />
                                                ) : (
                                                    <span>{createUserName || 'No investigator selected'}</span>
                                                )}
                                                <Button variant="plain" onClick={handleinvEditClick}>
                                                    {isEditMode ? <CheckIcon /> : <EditIcon />}
                                                </Button>
                                            </DescriptionListDescription>
                                        </DescriptionListGroup>

                                        {/* Tags Field */}
                                        <DescriptionListGroup>
                                            <DescriptionListTerm className="pf-v5-u-font-weight-bold">
                                                <Title headingLevel="h6">Tags:</Title>
                                            </DescriptionListTerm>
                                            <DescriptionListDescription>
                                                {showtags ? (
                                                    <>
                                                        <ChipGroup numChips={3}>
                                                            {tags.map((tag) => (
                                                                <Chip key={tag.tag} isReadOnly>
                                                                    {tag.tag}
                                                                </Chip>
                                                            ))}
                                                        </ChipGroup>
                                                        <Button variant="plain" onClick={() => setShowtags(false)}>
                                                            <EditIcon />
                                                        </Button>
                                                    </>
                                                ) : (
                                                    <>
                                                        <TextInputGroup className="pf-v5-u-w-100">
                                                            <TextInputGroupMain
                                                                value={inputTag}
                                                                onChange={handleInputTags}
                                                                onKeyDown={handleTextInputKeyDown}
                                                                placeholder="Enter a new tag"
                                                                aria-label="tag-input"
                                                            >
                                                                <ChipGroup numChips={3}>
                                                                    {tags.map((tag) => (
                                                                        <Chip key={tag.uniqueId} onClick={() => deleteTag(tag.uniqueId)}>
                                                                            {tag.tag}
                                                                        </Chip>
                                                                    ))}
                                                                </ChipGroup>
                                                            </TextInputGroupMain>
                                                            {showUtilities && (
                                                                <TextInputGroupUtilities>
                                                                    {showClearButton && (
                                                                        <Button variant="plain" onClick={clearTagsAndInput} aria-label="Clear button for tags and input">
                                                                            <TimesIcon />
                                                                        </Button>
                                                                    )}
                                                                </TextInputGroupUtilities>
                                                            )}
                                                        </TextInputGroup>
                                                        <Button variant="plain" onClick={handleAddTag}>
                                                            <CheckIcon />
                                                        </Button>
                                                        <Button variant="plain" onClick={() => setShowtags(true)}>
                                                            <TimesIcon />
                                                        </Button>
                                                    </>
                                                )}
                                            </DescriptionListDescription>
                                        </DescriptionListGroup>

                                    </DescriptionList>
                                </GridItem>
                            </Grid>
                        </>
                    )}
                </CardBody>
            </Card >
        </div >
    );
};

export default IncidentDetail;
