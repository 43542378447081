import React, { useEffect } from 'react';
import * as echarts from 'echarts/core';
import { connect, useDispatch } from 'react-redux';
import { calculateTimeDifference, formatTime, formatTimestamp } from 'src/util/Timeutils';
import { Emptystatefornodata } from 'src/util/EmptyStateCard';
import { generateUniqueID } from 'src/util/UniqueIdGenerator';
import { formatValue } from 'src/util/ChartHelper';
import { FilterData } from 'src/Redux/Action';
import { UpdateFilterData } from 'src/util/FilterHelper';
const GpgScatterChart = (props) => {
    const dispatch = useDispatch();
    const id = generateUniqueID()

    const handleFilter = (fields, value) => {
        const updatedFields = UpdateFilterData(props.filterformdata, fields, 'is', value);
        console.log(updatedFields)
        dispatch(FilterData(updatedFields));
    }
    useEffect(() => {
        const chartDom = document.getElementById(id + 'gpgscatter');
        const textcolor = props.theme == 'dark-theme' ? '#fff' : '#000'
        const linecolor = props.theme == 'dark-theme' ? '#595757' : '#d9d9d9'

        const myChart = echarts.init(chartDom);
        const { resultInMinutes, resultInHours, resultInDays } = calculateTimeDifference(props.StartDateTime, props.EndDateTime);
        const xAxisData = []
        const Label = props.label;
        const uniqueDatesSet = new Set();
        for (let i = 0; i < props.seriesData.length; i++) {
            for (let j = 0; j < props.seriesData[i].date_histogram_agg.length; j++) {
                const xvalue = props.seriesData[i].date_histogram_agg[j].key_as_string;
                uniqueDatesSet.add(xvalue);
            }
        }
        const uniqueDatesArray = [...uniqueDatesSet];
        for (let i = 0; i < uniqueDatesArray.length; i++) {
            const { ConvertTimestamp, hours, minutes, seconds } = formatTimestamp(uniqueDatesArray[i]);
            const formattedTime = formatTime(resultInMinutes, resultInHours, resultInDays, hours, minutes, seconds, ConvertTimestamp);
            xAxisData.push({ x: formattedTime });
        }

        const minSize = 5;
        const maxSize = 50;
        const minCount = Math.min(...props.seriesData.flatMap(item => item.date_histogram_agg.map(bucket => bucket.doc_count)));
        const maxCount = Math.max(...props.seriesData.flatMap(item => item.date_histogram_agg.map(bucket => bucket.doc_count)));

        const calculateSymbolSize = (count) => {
            if (maxCount === minCount) {
                return minSize; // Avoid division by zero
            }
            return minSize + (maxSize - minSize) * (count - minCount) / (maxCount - minCount);
        };


        const SeriesData = props.seriesData.map((item) => ({
            name: item.key,
            type: 'scatter',
            nestedkey: item.field,
            emphasis: {
                focus: 'series',
            },
            data: item.date_histogram_agg.map((dataItem) => ({
                value: dataItem.doc_count,
                symbolSize: calculateSymbolSize(dataItem.doc_count) // Set the bubble size based on doc_count
            })),
        }));
        let interval = ''
        if (Label === '30 seconds') {
            interval = 4
        }
        else if (Label === 'hour') {
            interval = 3
        }
        else {
            interval = 3
        }


        const option = {
            tooltip: {
                trigger: 'axis',
                position: [50, 50],
                textStyle: {
                    color: "#000"
                },
                axisPointer: {
                    type: 'cross',
                    label: {
                        backgroundColor: '#6a7985'
                    }
                }
            },
            title: {
                text: `Timestamp per ${Label}`,
                top: 'bottom',
                left: 'center',
                textStyle: {
                    fontWeight: 450,
                    fontSize: 14,
                    color: textcolor,
                    fontfamily: 'RedHatText, helvetica, arial, sans-serif',
                },
            },
            grid: {
                left: '1%',
                right: '17%',
                bottom: '10%',
                top: '15%',
                containLabel: true
            },
            xAxis: [
                {
                    type: 'category',
                    boundaryGap: true,
                    data: xAxisData.map(item => item.x),
                    axisLabel: { interval: interval, rotate: -50, color: textcolor },
                    axisTick: {
                        alignWithLabel: true
                    }
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    axisLabel: {
                        formatter: formatValue,
                        color: textcolor
                    },
                    splitLine: {
                        lineStyle: {
                            color: linecolor
                        }
                    },
                    axisLine: {
                        show: true
                    }
                }
            ],
            legend: {
                type: 'scroll',
                orient: 'vertical',
                right: 'right',
                top: 5,
                // left:170,
                itemWidth: 9.5,
                itemHeight: 10,
                itemGap: 6.5,
                tooltip: {
                    trigger: 'item',
                    show: true,
                    position: [10, 10],
                    textStyle: {
                        color: "#000"
                    }
                },
                icon: "rect",
                pageIconSize: 12,
                textStyle: {
                    width: 150,
                    overflow: 'truncate',
                    fontSize: 12,
                    color: textcolor
                }
            },
            color: ['#0066cc', '#f4c145', '#4cb140', '#5752d1', '#ec7a08', '#40199A', '#C8EB79', '#C9190B', '#008BAD'],
            series: SeriesData
        };

        option && myChart.setOption(option);
        myChart.on('legendselectchanged', function (param) {
            const selectedSeries = SeriesData.find(series => series.name == param.name);
            if (selectedSeries) {
                handleFilter(selectedSeries.nestedkey, selectedSeries.name); // Pass the 'field' and 'name' to handleFilter
            }
        });
        const resizeChart = () => {
            if (myChart) {
                myChart.resize();
            }
        };

        window.addEventListener('resize', resizeChart);
        return () => {
            myChart.dispose();
            window.removeEventListener('resize', resizeChart);
        };
    }, [props.seriesData, props.colorMap,props.filterFields,, props.StartDateTime, props.EndDateTime, props.theme, props?.height]);

    return <> <div className="container">
        {/* {props.seriesData.length == 0 ?
    (<>
      <div id={id + 'gpgscatter'} style={{ width: '20px', height: '20px' }}> </div>
      < Emptystatefornodata />
    </>)
    : ( */}
        <div id={id + 'gpgscatter'} style={{ width: '100%', height: props?.height ? props?.height : '290px' }} > </div>
        {/* )
  } */}
    </div>
    </>
}
const mapStateToProps = (state) => ({
    dates: { startDate: state.startdate, endDate: state.enddate },
    filterformdata: state.FilterData,
    theme: state.theme,
    sidebarState: state.sidebarstate
    // mapStateToProps content remains unchanged if you have it
});

export default connect(
    mapStateToProps,
    null,
    null,
    { forwardRef: true }
)(GpgScatterChart)