import React, { useState, useRef, useEffect, forwardRef } from "react";
import { connect, useDispatch } from "react-redux";
import {
  Bullseye,
  Grid,
  GridItem,
  MenuToggle,
  Page,
  Sidebar,
  Select,
  SelectOption,
  SelectList,
  SidebarContent,
  Text,

} from "@patternfly/react-core";
import EventChart from "../CommonEventPages/EventChart";
import dbApi from '../../services/DbApi';
import EventLogTable from "../CommonEventPages/EventLogTable";
import { Emptystatefornodata } from "../../util/EmptyStateCard";
import Eventsidebar from "../CommonEventPages/EventSidebar";
import { formatTableTimestamp } from "../../util/Timeutils";
import { Loader } from "../../util/Loadingstate";

const VulnerabilitiesEvents = forwardRef(function VulnerabilitiesEvents(props, ref) {
  const dispatch = useDispatch();
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState("select");
  const [vulScrollId, setvulScrollId] = useState('');
  const [logTableData, setLogTableData] = useState([]);
  const [totalCount, setTotalCount] = useState("0");
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [chartlabel, setChartLabel] = useState('')
  const [chartseries, setChartSeries] = useState([])
  const [fieldKeys, setFieldKeys] = useState([])
  const [isLoading, setIsLoading] = useState(false);

  const childSecurityEventRef = useRef(null);
  const time1options = [
    <SelectOption key={0} value="seconds"> seconds</SelectOption>,
    <SelectOption key={1} value="minutes">minutes </SelectOption>,
    <SelectOption key={2} value="hours">hours</SelectOption>,
    <SelectOption key={3} value="days">days</SelectOption>,
    <SelectOption key={4} value="weeks">weeks</SelectOption>,
    <SelectOption key={5} value="months">months</SelectOption>,
    <SelectOption key={6} value="years">years</SelectOption>,
  ];
  const VulColumn = ["Time",
    "Agent Name",
    "data.vulnerability.package.name",
    "data.vulnerability.cve",
    "data.vulnerability.severity",
    "data.vulnerability.status",]
  const clearselection = () => {
    setSelected(null)
    setIsOpen(false)
  }
  const onToggle = (isOpen) => {
    setIsOpen(isOpen)
  }
  const onSelect = (event, selection, isplaceholder) => {
    if (isplaceholder) {
      clearselection()
    } else {
      setSelected(selection)
      setIsOpen(false)
    }
  }


  const getEventlog = async (data) => {
    try {
      setIsLoading(true)
      let all_eventlog = await dbApi.postApi("Opensearch/vulnerabilities_Event", data);
      console.log(all_eventlog);
      let ScrollID = ''
      let LogTableData = []
      let ChartLabel = ''
      let ChartSeries = []
      let TotalCount = '0'
      if (Object.keys(all_eventlog.vulnerabilities_Event).length !== 0) {
        let events = all_eventlog.vulnerabilities_Event.vulnerabilities.body.hits
        let label = all_eventlog.vulnerabilities_Event.interval
        let ChartData = all_eventlog.vulnerabilities_Event.vulnerabilities.body.aggregations[2]
        let scrollID = all_eventlog.vulnerabilities_Event.vulnerabilities.body._scroll_id
        let fieldkeys = all_eventlog.vulnerabilities_Event.keys

        ChartSeries = ChartData.buckets;
        ChartLabel = label

        ScrollID = scrollID
        LogTableData = events.hits;
        TotalCount = events.total.value;
        setLogTableData(LogTableData)
        setvulScrollId(ScrollID)
        setFieldKeys(fieldkeys)
        setTotalCount(TotalCount)
        setChartLabel(ChartLabel)
        setChartSeries(ChartSeries)
      }
      else {
        setLogTableData(LogTableData)
        setvulScrollId(ScrollID)
        setFieldKeys([])
        setTotalCount(TotalCount)
        setChartLabel(ChartLabel)
        setChartSeries(ChartSeries)
      }
      setIsLoading(false)

    }
    catch (error) {
      console.log(error)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (childSecurityEventRef.current) {
      childSecurityEventRef.current.setSecurityEventTable(logTableData, vulScrollId, VulColumn);
    }
  }, [logTableData, vulScrollId, VulColumn]);

  const eventsApi = async (startDate, endDate, allFields) => {
    const Fields = allFields
    const data = {
      startDate: startDate,
      endDate: endDate,
      filter: JSON.stringify(Fields),
    };
    try {
      setIsLoading(true)
      setStartDate(startDate)
      setEndDate(endDate)
      await getEventlog(data)
    }
    catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (props.dates.startDate != "") {
      let Dates = props.dates
      eventsApi(Dates.startDate, Dates.endDate, props.filterformdata,)
    }
  }, [props?.dates.startDate, props?.dates.endDate, props.filterformdata,]);

  console.log(startDate, endDate)

  const toggle = toggleRef => <MenuToggle
    ref={toggleRef}
    onClick={onToggle}
    isExpanded={isOpen}
    style={{ width: '150px' }}>
    {selected}
  </MenuToggle>

  const { formattedTimeStamp: StartDate } = formatTableTimestamp(startDate);
  const { formattedTimeStamp: EndDate } = formatTableTimestamp(endDate);
  return (
    <div>
      <Grid hasGutter>
        <GridItem>
          <Page isManagedSidebar>
            <Sidebar style={{ overflow: "auto" }}>
              <Eventsidebar
                Count={totalCount}
                Loading={isLoading}
                FieldKeys={fieldKeys}
                filterfield={[
                  {
                    'fields': 'data.vulnerability.status',
                    'condition': 'is_one_of',
                    'value': ['Active', 'Solved']
                  },
                ]}
              />
              <div style={{ width: "100%" }}>
                <SidebarContent className="Sidebar-content">
                  <Text align="center"><b>{`${totalCount.toLocaleString('hi-IN')} hits`}</b></Text>
                  <Text align='center'><small><b>{StartDate} - {EndDate}</b></small></Text>
                  {/* <div>
                    <Bullseye>
                      <div>
                        <Select
                          id="single-select"
                          isOpen={isOpen}
                          selected={selected}
                          onSelect={onSelect}
                          // onOpenChange={isOpen}
                          toggle={toggle}
                          shouldFocusToggleOnSelect
                        >
                          <SelectList>
                            {time1options}
                          </SelectList>
                        </Select>
                      </div>
                    </Bullseye>
                  </div> */}
                  {isLoading ? <Loader /> :
                    chartseries.length > 0 ?
                      <>
                        <EventChart EventSeries={chartseries} EventLabel={chartlabel} StartDateTime={startDate} EndDateTime={endDate} />
                        <EventLogTable ref={childSecurityEventRef} />
                      </>
                      :
                      <Emptystatefornodata />
                  }
                </SidebarContent>
              </div>
            </Sidebar>
          </Page>
        </GridItem>
      </Grid>
    </div>
  );
})

const mapStateToProps = (state) => ({
  dates: { startDate: state.startdate, endDate: state.enddate },
  filterformdata: state.FilterData,
  selectedGroup: state.SelectedGroup,
  // mapStateToProps content remains unchanged if you have it
});

const mapDispatchToProps = (dispatch) => { return {} }

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(VulnerabilitiesEvents)
