import React, { useRef, useState } from 'react';
import {
  Button,
  Card,
  DataList,
  DataListCheck,
  DataListItem,
  DataListItemRow,
  DataListCell,
  DataListItemCells,
  Label,
  Toolbar,
  ToolbarContent,
  ToolbarToggleGroup,
  ToolbarGroup,
  ToolbarItem,
  ToolbarFilter,
  MenuToggle,
  Modal,
  OverflowMenu,
  OverflowMenuGroup,
  OverflowMenuItem,
  OverflowMenuDropdownItem,
  Dropdown,
  DropdownList,
  Pagination,
  PaginationVariant,
  TextContent,
  Tooltip,
  Icon,
  Select, SelectOption, SearchInput, List, ListItem, Chip, ListVariant, Popover, TextVariants, Text, ChipGroup
} from '@patternfly/react-core';

import { Table, TableText, Thead, Tr, Th, Tbody, Td, ExpandableRowContent } from '@patternfly/react-table';
import FilterIcon from '@patternfly/react-icons/dist/esm/icons/filter-icon';
import ArrowCircleUpIcon from '@patternfly/react-icons/dist/esm/icons/arrow-circle-up-icon';
import ArrowCircleDownIcon from '@patternfly/react-icons/dist/esm/icons/arrow-circle-down-icon';
import EllipsisVIcon from '@patternfly/react-icons/dist/esm/icons/ellipsis-v-icon';

import dbApi from '../../services/DbApi';
import { useDispatch } from 'react-redux'
import { alertBoxData } from 'src/Redux/Action';
import ConfirmBox from '../ConfirmBox/ConfirmBox'
import { useLocation, useNavigate, Link } from 'react-router-dom/dist';
import { Emptystatefornodata } from 'src/util/EmptyStateCard';
import { Loader } from 'src/util/Loadingstate';

const UserList = (props) => {
  const dispatch = useDispatch()

  //Start DataTable State
  const defaultColumns = ['Status', 'Name', 'Email', 'Role', 'Group', 'Create Datetime'];
  const defaultRows = [];
  const excludeOrder = ['Create Datetime', 'Group']
  const excludeWrapCol = ['Create Datetime']
  const orderColumns = [
    {
      label: 'Name',
      col: 'first_name'
    },
    {
      label: 'Email',
      col: 'email'
    },
    {
      label: 'Role',
      col: 'role'
    },
    {
      label: 'Create Datetime',
      col: 'create_date'
    }
  ]

  const [dataFilters, setDataFilters] = useState({
    name: [],
    status: [],
    statusChip: [],
    role: [],
    roleChip: []
  })
  const [toast, setToast] = useState({
    title: '',
    variant: ''
  })
  const [expandedRowId, setExpandedRowId] = useState([]);
  const [isloading, setIsLoading] = useState(false)
  const [selectedRows, setSelectedRows] = useState([]);
  const [inputValue, setInputValue] = useState('')
  const [isCategoryDropdownOpen, setIsCategoryDropdownOpen] = useState(false)
  const [currentCategory, setCurrentCategory] = useState('Name')
  const [isFilterDropdownOpen, setIsFilterDropdownOpen] = useState(false)
  const [isKebabDropdownOpen, setIsKebabDropdownOpen] = useState(false);
  const [filters, setFilters] = useState([]);
  const [filteredColumns, setFilteredColumns] = useState(defaultColumns);
  const [filteredRows, setFilteredRows] = useState(defaultRows);
  const [managedColumns, setManagedColumns] = useState(defaultColumns);
  const [managedRows, setManagedRows] = useState(defaultRows);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [checkedState, setCheckedState] = useState(Array(defaultColumns.length).fill(true));
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [paginatedRows, setPaginatedRows] = useState([]);
  const [totalItem, setTotalItem] = useState(0)
  const [activeSortIndex, setActiveSortIndex] = useState(0);
  const [activeSortDirection, setActiveSortDirection] = useState('desc');
  const [roleList, setRoleList] = useState([]);

  //End DataTable State
  const [projectId, setProjectId] = useState(1)
  const [confirmModalData, setConfirmBox] = useState({
    show: false,
    msg: '',
    funCall: '',
    funParam: {}
  })

  //Start DataTable Code
  const matchCheckboxNameToColumn = (name) => {
    switch (name) {
      case 'check1':
        return 'Status';
      case 'check2':
        return 'Name';
      case 'check3':
        return 'Email';
      case 'check4':
        return 'Role';
      case 'check5':
        return 'Group';
      case 'check6':
        return 'Create Datetime';
    }
  };
  const matchSelectedColumnNameToAttr = (name) => {
    switch (name) {
      case 'Status':
        return 'status';
      case 'Name':
        return 'first_name';
      case 'Email':
        return 'email';
      case 'Role':
        return 'role';
      case 'Group':
        return 'group';
      case 'Create Datetime':
        return 'create_date';
    }
  };

  const onDelete = (type = '', id = '') => {
    if (type) {
      let temp = dataFilters
      temp[type].splice(0, 1);
      if (type == "status") {
        temp['statusChip'].splice(0, 1);
      } else if (type == "role") {
        temp['roleChip'].splice(0, 1);
      }
      setDataFilters(prevDataFilters => ({
        ...prevDataFilters,
        temp
      }));
    } else {
      setDataFilters({ name: [], status: [], statusChip: [], role: [], roleChip: [] })
    }
  };

  const onFilterToggle = () => {
    setIsFilterDropdownOpen(!isFilterDropdownOpen)
  };

  const onCategorySelect = event => {
    setCurrentCategory(event.target.innerText)
    setIsCategoryDropdownOpen(false)
    setIsFilterDropdownOpen(false)
  };

  const onCategoryToggle = () => {
    setIsCategoryDropdownOpen(!isCategoryDropdownOpen)
  };

  const onInputChange = newValue => {
    setInputValue(newValue)
  };

  const onNameInput = event => {
    if (event.key && event.key !== 'Enter') {
      return;
    }
    let temp = dataFilters
    temp.name[0] = inputValue
    setDataFilters(prevDataFilters => ({
      ...prevDataFilters,
      temp
    }));
  };

  const onFilterSelect = (event, selection, type, chipType) => {
    const checked = event.target.checked;
    let text = event.target.innerText
    setIsFilterDropdownOpen(false)
    let temp = dataFilters
    temp[type][0] = selection
    temp[chipType][0] = text
    setDataFilters(prevDataFilters => ({
      ...prevDataFilters,
      temp
    }));
  };


  const setRowSelected = (row, isSelecting = true) => {
    setSelectedRows((prevSelected) => {
      const otherSelectedRows = prevSelected.filter((r) => r !== row.id);
      return isSelecting ? [...otherSelectedRows, row.id] : otherSelectedRows;
    });
  }

  const isRowSelected = (row) => selectedRows.includes(row.id);

  //Call When click on column sort
  const onSort = (event, index, direction) => {
    setActiveSortIndex(index);
    setActiveSortDirection(direction);
  };

  // Pagination logic
  const handleSetPage = (_evt, newPage) => {
    setPage(newPage);
  };

  const handlePerPageSelect = (_evt, newPerPage, newPage) => {
    setPerPage(newPerPage);
    if (newPage !== page) { setPage(newPage); }
  };

  const renderPagination = () => (
    <Pagination
      itemCount={totalItem}
      page={page}
      perPage={perPage}
      onSetPage={handleSetPage}
      onPerPageSelect={handlePerPageSelect}
    />
  );

  // Removes attribute from each node object in Data.jsx
  const removePropFromObject = (object, keys) =>
    keys.reduce((obj, prop) => {
      const { [prop]: _, ...keep } = obj;
      return keep;
    }, object);

  // Filters columns out of table that are not selected in the column management modal
  const filterData = (checked, name) => {
    const selectedColumn = matchSelectedColumnNameToAttr(name);
    const filteredRows = [];
    if (checked) {
      const updatedFilters = filters.filter((item) => item !== selectedColumn);
      // Only show the names of columns that were selected in the modal
      const filteredColumns = defaultColumns.filter(
        (column) => !updatedFilters.includes(matchSelectedColumnNameToAttr(column))
      );
      // Remove the attributes (i.e. "columns") that were not selected
      managedRows.forEach((item) => filteredRows.push(removePropFromObject(item, updatedFilters)));

      setFilters(updatedFilters);
      setFilteredColumns(filteredColumns);
      setFilteredRows(filteredRows);
    } else {
      let updatedFilters = filters;
      if (typeof selectedColumn != 'undefined') {
        updatedFilters.push(selectedColumn);
      }

      // Only show the names of columns that were selected in the modal
      const filteredColumns = managedColumns.filter(
        (column) => !filters.includes(matchSelectedColumnNameToAttr(column))
      );

      // Remove the attributes (i.e. "columns") that were not selected
      managedRows.forEach((item) => filteredRows.push(removePropFromObject(item, updatedFilters)));

      setFilters(updatedFilters);
      setFilteredColumns(filteredColumns);
      setFilteredRows(filteredRows);
    }
  };
  const unfilterAllData = () => {
    setFilters([]);
    setFilteredColumns(defaultColumns);
    setFilteredRows(managedRows);
  };

  const handleChange = (event, checked) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    // Remove any columns from the table that aren't checked
    filterData(checked, matchCheckboxNameToColumn(target.name));
    const checkedIndex = defaultColumns.findIndex((element) => element === matchCheckboxNameToColumn(target.name));

    const updatedCheckedState = [...checkedState];
    updatedCheckedState[checkedIndex] = value;

    setCheckedState(updatedCheckedState);
  };

  const handleModalToggle = (_event) => {
    setIsModalOpen(!isModalOpen);
  };

  const onSave = () => {
    setManagedColumns(filteredColumns);
    console.log("filteredColumns")
    console.log(filteredColumns)
    setPaginatedRows(filteredRows);
    setIsModalOpen(!isModalOpen);
  };

  const selectAllColumns = () => {
    unfilterAllData();
    setCheckedState(Array(defaultColumns.length).fill(true));
  };

  async function fetchDataTable() {
    try {
      setIsLoading(true)
      let orderCol = 'id'
      if (activeSortIndex > 0) {
        orderCol = filteredColumns[activeSortIndex]
        let orderResult = orderColumns.find((obj, index) => {
          return obj.label == orderCol
        })
        orderCol = orderResult.col
      }

      let data = {
        id: '',
        status: dataFilters.status.length > 0 ? dataFilters.status[0] : '',
        role: dataFilters.role.length > 0 ? dataFilters.role[0] : '',
        search: dataFilters.name.length > 0 ? dataFilters.name[0] : '',
        sortBy: orderCol,
        sortOrder: activeSortDirection,
        limitStart: (perPage * page) - perPage,
        limitLength: perPage
      }

      const ret = await dbApi.postApi('user/user-list', data);
      console.log(ret)
      let result = ret.data
      setIsLoading(false)
      if (result?.data?.length > 0) {
        setTotalItem(result.totalRecord)
        setManagedRows(result.data)
        setPaginatedRows(result.data)
      } else {
        setTotalItem(0)
        setManagedRows([])
        setPaginatedRows([])
      }

    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }

  }

  const getUserRole = async (roleId = "") => {
    const res = await dbApi.getApi("user/user-roles");
    if (res?.data?.length > 0) {
      setRoleList(res.data)
    }
  }

  React.useEffect(() => {
    if (roleList.length == 0) {
      getUserRole();
    }
    fetchDataTable()
  }, [page, perPage, activeSortIndex, activeSortDirection, dataFilters]);
  //End DataTable Code


  const cmActions = async (id, type) => {
    // alert("id = "+id+" type = "+type)
  }

  const finalRemoveUser = async () => {
    try {
      setIsLoading(true)
      let ret = await dbApi.postApi('user/delete-user', { id: JSON.stringify(selectedRows) });
      setIsLoading(false)
      let type = 'danger'
      if (ret.error == false) {
        type = 'success'
        fetchDataTable()
      }
      dispatch(alertBoxData([{ title: ret.message, variant: type }]));
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }
  }

  const removeUser = () => {
    setConfirmBox({
      show: true,
      msg: 'Are you sure?',
      funCall: finalRemoveUser,
      funParam: {}
    })
  }

  const reloadData = () => {
    setSelectedRows([])
    fetchDataTable()
  }

  const renderModal = () => {
    return (
      <Modal
        title="Manage columns"
        isOpen={isModalOpen}
        variant="small"
        description={
          <TextContent>
            <Button isInline onClick={selectAllColumns} variant="link">
              Select all
            </Button>
          </TextContent>
        }
        onClose={handleModalToggle}
        actions={[
          <Button key="save" variant="primary" onClick={onSave}>
            Save
          </Button>,
          <Button key="cancel" variant="secondary" onClick={handleModalToggle}>
            Cancel
          </Button>
        ]}
      >
        <DataList aria-label="Table column management" id="table-column-management" isCompact>
          {
            defaultColumns.map((obj, index) => {
              return <DataListItem key={index} aria-labelledby={"col-mgmt-item-" + index}>
                <DataListItemRow>
                  <DataListCheck
                    aria-labelledby={"col-mgmt-item-" + index}
                    checked={checkedState[index]}
                    name={"check" + (index + 1)}
                    id={"check" + index}
                    onChange={handleChange}
                  />
                  <DataListItemCells
                    dataListCells={[
                      <DataListCell id={"col-mgmt-item-" + index} key={"col-mgmt-item-" + index}>
                        <label htmlFor={"check" + index}>{obj}</label>
                      </DataListCell>
                    ]}
                  />
                </DataListItemRow>
              </DataListItem>
            })
          }
        </DataList>
      </Modal>
    );
  };




  const tableActionItems = [
    <OverflowMenuDropdownItem key="1" onClick={handleModalToggle}  >  Manage Columns</OverflowMenuDropdownItem>,
    <OverflowMenuDropdownItem key="2" onClick={removeUser} isDisabled={selectedRows.length == 0}  >  Remove</OverflowMenuDropdownItem>,
  ];

  const tableAction =
    <Dropdown onSelect={() => setIsKebabDropdownOpen(!isKebabDropdownOpen)} onOpenChange={isKebabDropdownOpen => setIsKebabDropdownOpen(isKebabDropdownOpen)} toggle={toggleRef => <MenuToggle ref={toggleRef} aria-label="overflow menu" variant="plain" onClick={() => setIsKebabDropdownOpen(!isKebabDropdownOpen)} isExpanded={false}>
      <EllipsisVIcon />
    </MenuToggle>} isOpen={isKebabDropdownOpen}>
      <DropdownList >{tableActionItems}</DropdownList>
    </Dropdown>


  function buildFilterDropdown() {
    const statusMenuItems = [
      <SelectOption key="1" value="1" isSelected={dataFilters.status.includes(1)}>
        Active
      </SelectOption>,
      <SelectOption key="2" value="0" isSelected={dataFilters.status.includes(0)}>
        Inactive
      </SelectOption>,
    ];


    return <React.Fragment>
      <ToolbarFilter chips={dataFilters.name} deleteChip={onDelete} categoryName="name" showToolbarItem={currentCategory === 'Name'}>
        <SearchInput aria-label="name filter" placeholder="Filter by name..." onChange={(_event, value) => onInputChange(value)} value={inputValue} onClear={() => {
          onInputChange('');
        }} onSearch={onNameInput} />
      </ToolbarFilter>

      <ToolbarFilter chips={dataFilters.statusChip} deleteChip={onDelete} categoryName="status" showToolbarItem={currentCategory === 'Status'}>
        <Select aria-label="Status" isOpen={isFilterDropdownOpen} onSelect={(event, selection) => onFilterSelect(event, selection, 'status', 'statusChip')} selected={dataFilters.status} toggle={toggleRef => <MenuToggle ref={toggleRef} onClick={onFilterToggle} isExpanded={isFilterDropdownOpen} style={{
          width: '100%',
          verticalAlign: 'text-bottom'
        }}>
          Filter by status
        </MenuToggle>}>
          {statusMenuItems}
        </Select>
      </ToolbarFilter>

      <ToolbarFilter chips={dataFilters.roleChip} deleteChip={onDelete} categoryName="role" showToolbarItem={currentCategory === 'Role'}>
        <Select aria-label="Role" isOpen={isFilterDropdownOpen} onSelect={(event, selection) => onFilterSelect(event, selection, 'role', 'roleChip')} selected={dataFilters.status} toggle={toggleRef => <MenuToggle ref={toggleRef} onClick={onFilterToggle} isExpanded={isFilterDropdownOpen} style={{
          width: '100%',
          verticalAlign: 'text-bottom'
        }}>
          Filter by role
        </MenuToggle>}>
          {roleList.map((obj, index) => <SelectOption key={index} value={obj.id} isSelected={dataFilters.role.includes(obj.id)}>
            {obj.role}
          </SelectOption>)}
        </Select>
      </ToolbarFilter>


    </React.Fragment>;
  }

  function buildCategoryDropdown() {
    const categoryMenuItems = [
      <SelectOption key="1" value="Name">Name</SelectOption>,
      <SelectOption key="2" value="Status">Status</SelectOption>,
      <SelectOption key="3" value="Role">Role</SelectOption>
    ];
    return <ToolbarItem>
      <Select onSelect={onCategorySelect} selected={currentCategory} toggle={toggleRef => <MenuToggle ref={toggleRef} onClick={onCategoryToggle} isExpanded={isCategoryDropdownOpen} icon={<FilterIcon />} style={{
        width: '100%',
        verticalAlign: 'text-bottom'
      }}>
        {currentCategory}
      </MenuToggle>} isOpen={isCategoryDropdownOpen}>
        {categoryMenuItems}
      </Select>
    </ToolbarItem>;
  }

  function renderToolbar() {
    return <Toolbar id="toolbar-with-chip-groups" clearAllFilters={onDelete} collapseListedFiltersBreakpoint="xl">
      <ToolbarContent>
        <ToolbarToggleGroup toggleIcon={<FilterIcon />} breakpoint="xl">
          <ToolbarGroup variant="filter-group" style={{
            lineHeight: '22px',
            alignItems: 'center'
          }}>
            {buildCategoryDropdown()}
            {buildFilterDropdown()}
          </ToolbarGroup>
        </ToolbarToggleGroup>
        <ToolbarItem variant="overflow-menu">
          <OverflowMenu breakpoint="md">
            <OverflowMenuGroup groupType="button" isPersistent>
              <OverflowMenuItem>
                <Link to={'/user-profile'} className="route" ><Button variant="primary" size="sm">New</Button></Link>
              </OverflowMenuItem>
              <OverflowMenuItem isPersistent>
                {tableAction}
              </OverflowMenuItem>
            </OverflowMenuGroup>
          </OverflowMenu>
        </ToolbarItem>
        <ToolbarItem variant="pagination">{renderPagination()}</ToolbarItem>
      </ToolbarContent>
    </Toolbar>;
  }

  return (
    <div className="pf-v5-u-p-sm">

      <React.Fragment>
        <Card>
          {renderToolbar()}
          {isloading ? <Loader /> :
            <Table variant="compact" aria-label="Column Management Table">
              <Thead>
                <Tr>
                  <Th key={'chk'} screenReaderText='key' />
                  {managedColumns.map((column, columnIndex) => {
                    const sortParams = {
                      sort: {
                        sortBy: {
                          index: activeSortIndex,
                          direction: activeSortDirection
                        },
                        onSort,
                        columnIndex
                      }
                    };
                    return !excludeOrder.includes(column) ? <Th modifier={!excludeWrapCol.includes(column) && 'wrap'} key={columnIndex} {...sortParams} >{column}</Th> : <Th modifier={!excludeWrapCol.includes(column) && 'wrap'} key={columnIndex} >{column}</Th>;
                  })}
                </Tr>
              </Thead>
              <Tbody>
                {
                  paginatedRows.length != 0 ? <>
                    {paginatedRows.map((row, rowIndex) => (
                      <React.Fragment key={rowIndex} >
                        <Tr key={rowIndex}>
                          <Td
                            key={"chk-" + rowIndex}
                            select={{
                              rowIndex,
                              onSelect: (_event, isSelecting) => setRowSelected(row, isSelecting),
                              isSelected: isRowSelected(row),
                              isDisabled: row?.process_status == 1,
                            }}
                          />
                          {
                            typeof row?.status != 'undefined' && <Td key={rowIndex + "-status"} className="width-5-per" >
                              {
                                ((item) => {
                                  if (item.status == 1) {
                                    return <Tooltip content={<div>
                                      Active
                                    </div>}><Icon status="success">
                                        <ArrowCircleUpIcon />
                                      </Icon></Tooltip>
                                  }
                                  else if (item.status == 0) {
                                    return <Tooltip content={<div>
                                      Inactive
                                    </div>}><Icon status="danger">
                                        <ArrowCircleDownIcon />
                                      </Icon></Tooltip>
                                  }
                                })(row)
                              }
                            </Td>
                          }
                          {
                            typeof row?.first_name != 'undefined' && <Td key={rowIndex + "-name"} width={20} >
                              {
                                // row?.first_name + row?.last_name
                                <Link to={'/user-profile/' + row.id + '/' + `${row?.first_name + row?.last_name}`} className="route" >{row?.first_name} {row?.last_name != null ? " " + row?.last_name : ""}</Link>
                              }
                            </Td>
                          }
                          {
                            typeof row?.email != 'undefined' && <Td key={rowIndex + "-email"} width={10} >
                              {row.email}
                            </Td>
                          }
                          {
                            typeof row?.role != 'undefined' && <Td key={rowIndex + "-role"} width={10} >
                              {row.role}
                            </Td>
                          }
                          {
                            typeof row?.user_agent_groups != 'undefined' &&
                            <Td key={rowIndex + "-group"} width={20}>
                              {row?.user_agent_groups !== null ?
                                (<ChipGroup numChips={2}>
                                  {row?.user_agent_groups.split(",")?.map((item, index) => (
                                    <Chip key={"chip" + index} isReadOnly>{item}</Chip>
                                  ))}
                                </ChipGroup>
                                ) : null}
                            </Td>
                          }
                          {
                            typeof row?.create_date != 'undefined' && <Td key={rowIndex + "-count"} width={20} >
                              {row?.create_date}
                            </Td>
                          }
                        </Tr>
                      </React.Fragment>
                    ))}
                  </> : <Tr><Td colSpan={7}><Emptystatefornodata /></Td></Tr>
                }
              </Tbody>
            </Table>
          }
          <div className='pf-v5-u-mt-md pf-v5-u-mb-md '> {renderPagination()}</div>
          {renderModal()}
        </Card>
        <ConfirmBox confirmModal={confirmModalData} />
      </React.Fragment>

    </div>
  );
};
export default UserList
