import React from 'react';
import {
    Bullseye,
    EmptyState,
    EmptyStateHeader,
    EmptyStateIcon,
    Skeleton,
    Spinner
} from "@patternfly/react-core"

export const Loader = () => {
    return (
        <Bullseye>
            <EmptyState>
                <EmptyStateHeader
                    titleText="Loading..."
                    icon={<EmptyStateIcon icon={Spinner} />}
                    headingLevel="h2"
                />
            </EmptyState>
        </Bullseye>)
}


export const RecSkeleton = () => <React.Fragment>
    <Skeleton height="100%" shape="square" screenreaderText="Loading large rectangle contents" />
</React.Fragment>;

export const RoundSkeleton = ({ width }) => <React.Fragment>
    <Skeleton shape='circle' width={width ? width : '20%'}
        style={{
            marginBottom: '10px',
            marginTop: '-10px',
            '--pf-v5-c-skeleton--BackgroundColor': '#F2F9F9 ',
            '--pf-v5-c-skeleton--after--LinearGradientColorStop1': '#B8BBBE',
        }} />

</React.Fragment>

export const TextSkeleton = ({ width, height }) =>
    <Skeleton shape='rectangle' width={width ? width : '100%'} height={height ? height : '100%'}
        style={{
            // F2F9F9  cyan-A2D9D9 F0F0F0 D2D2D2
            '--pf-v5-c-skeleton--BackgroundColor': '#F2F9F9 ',
            '--pf-v5-c-skeleton--after--LinearGradientColorStop1': '#B8BBBE',
            // '--pf-v5-c-skeleton--after--AnimationDelay':'0s'
            // '	--pf-v5-c-skeleton--after--TranslateX':'100%'
        }} />



// export const CardSkeleton = () =>
//         <div style={{ height: '250px' }}>
//             <Skeleton shape="rectangle" height="100%"
//                 style={{
//                     '--pf-v5-c-skeleton--BackgroundColor': '#f0f0f0',
//                     '--pf-v5-c-skeleton--after--AnimationIterationCount': 'infinite',
//                     '--pf-v5-c-skeleton--after--LinearGradientColorStop1': '#d2d2d2',
//                     '--pf-v5-c-skeleton--after--AnimationTimingFunction':'linear'

//                 }} />
//         </div>