import * as React from 'react';
import {Avatar, Brand, Breadcrumb, BreadcrumbItem, Button, ButtonVariant, Card, Divider, Dropdown, DropdownGroup, DropdownItem,
   DropdownList, Icon, Label, Masthead, MastheadBrand, MastheadContent, MastheadMain, MastheadToggle, MenuToggle, Nav, NavItem, NavList, Page, PageBreadcrumb, PageSection, PageSidebar, PageSidebarBody, PageToggleButton, Pagination, PaginationVariant, Text, TextContent, TextVariants, Toolbar, ToolbarContent, ToolbarGroup, ToolbarItem, Truncate} from '@patternfly/react-core';
import {Table, Thead, Tr, Th, Tbody, Td} from '@patternfly/react-table';
// import translationsEn from './examples/translations.en.json';
// import translationsHe from './examples/translations.he.json';
import AlignRightIcon from '@patternfly/react-icons/dist/esm/icons/align-right-icon';
import ToolsIcon from '@patternfly/react-icons/dist/esm/icons/tools-icon';
import ClockIcon from '@patternfly/react-icons/dist/esm/icons/clock-icon';
import WalkingIcon from '@patternfly/react-icons/dist/esm/icons/walking-icon';
import pfLogo from '@patternfly/react-core/src/demos/assets/pf-logo.svg';
import BarsIcon from '@patternfly/react-icons/dist/esm/icons/bars-icon';
import CogIcon from '@patternfly/react-icons/dist/esm/icons/cog-icon';
import QuestionCircleIcon from '@patternfly/react-icons/dist/esm/icons/question-circle-icon';
import HelpIcon from '@patternfly/react-icons/dist/esm/icons/help-icon';
import EllipsisVIcon from '@patternfly/react-icons/dist/esm/icons/ellipsis-v-icon';
import HandPaperIcon from '@patternfly/react-icons/dist/esm/icons/hand-paper-icon';
import imgAvatar from '@patternfly/react-core/src/components/assets/avatarImg.svg';
import AppHomeContent from './AppHomeContent';
import logo from '../Assets/images/layout-header-img.svg'


export const AppHomeLayout = () => {
//   const [translation, setTranslation] = React.useState(translationsEn);
  
  const [isDirRTL, setIsDirRTL] = React.useState(false);
  const capitalize = input => input[0].toUpperCase() + input.substring(1);
  const [activeItem, setActiveItem] = React.useState(0);

  const onNavSelect = (_event, selectedItem) => {
    setActiveItem(selectedItem.itemId)
  };

  React.useEffect(() => {
    const html = document.querySelector('html');
    html.dir = isDirRTL ? 'rtl' : 'ltr';
  }, [isDirRTL]);

//   const handleSetPage = (_evt, newPage, _perPage, startIdx, endIdx) => {
//     setPaginatedRows(managedRows.slice(startIdx, endIdx));
//     setPage(newPage);
//   };
//   const handlePerPageSelect = (_evt, newPerPage, _newPage, startIdx, endIdx) => {
//     setPaginatedRows(managedRows.slice(startIdx, endIdx));
//     setPerPage(newPerPage);
//   };

const PageNav = <Nav onSelect={onNavSelect} aria-label="Nav" variant="horizontal">
<NavList>
  <NavItem preventDefault itemId={0} isActive={activeItem === 0} to="#home">
    Home
  </NavItem>
  <NavItem preventDefault itemId={1} isActive={activeItem === 1} to="#">
    Blog
  </NavItem>
  <NavItem preventDefault itemId={2} isActive={activeItem === 2} to="#">
    Service
  </NavItem>
  <NavItem preventDefault itemId={3} isActive={activeItem === 3} to="#">
    Login
  </NavItem>
  <NavItem preventDefault itemId={4} isActive={activeItem === 4} to="#">
   Contact
  </NavItem>
</NavList>
</Nav>;
  
  const toolbarItems = <React.Fragment>
      <Toolbar id="rtl-paginated-table">
        <ToolbarContent>
          <ToolbarItem>
            <Button variant="primary" icon={<Icon shouldMirrorRTL>
                  <AlignRightIcon />
                </Icon>} iconPosition="end" >
              {/* {translation.switchBtn} */}
            </Button>
          </ToolbarItem>
          {/* <ToolbarItem variant="pagination">{renderPagination(PaginationVariant.top)}</ToolbarItem> */}
        </ToolbarContent>
      </Toolbar>
    </React.Fragment>;

  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
  const [isKebabDropdownOpen, setIsKebabDropdownOpen] = React.useState(false);
  const [isFullKebabDropdownOpen, setIsFullKebabDropdownOpen] = React.useState(false);
//   const kebabDropdownItems = <>
//       <DropdownItem icon={<CogIcon />}>{translation.kebabDropdown.settings}</DropdownItem>
//       <DropdownItem icon={<HelpIcon />}>{translation.kebabDropdown.help}</DropdownItem>
//     </>;
//   const userDropdownItems = <>
//       <DropdownItem key="group 2 profile">{translation.userDropdown.myProfile}</DropdownItem>
//       <DropdownItem key="group 2 user">{translation.userDropdown.userManagement}</DropdownItem>
//       <DropdownItem key="group 2 logout">{translation.userDropdown.logout}</DropdownItem>
//     </>;
  const onDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const onDropdownSelect = () => {
    setIsDropdownOpen(false);
  };
  const onKebabDropdownToggle = () => {
    setIsKebabDropdownOpen(!isKebabDropdownOpen);
  };
  const onKebabDropdownSelect = () => {
    setIsKebabDropdownOpen(false);
  };
  const onFullKebabToggle = () => {
    setIsFullKebabDropdownOpen(!isFullKebabDropdownOpen);
  };
  const onFullKebabSelect = () => {
    setIsFullKebabDropdownOpen(false);
  };
  const masthead = <Masthead>
         <MastheadMain>
        <MastheadBrand dir="ltr">
        <Brand src={logo} alt="Skyvirt Logo" heights={{ default: '40px' }}></Brand>
        </MastheadBrand>
      </MastheadMain>
      <MastheadContent>
        <Toolbar id="toolbar" isFullHeight  isSticky={true}>
          <ToolbarContent>

            <ToolbarGroup variant="icon-button-group" align={{
    default: 'alignLeft'
  }} spacer={{
    default: 'spacerNone',
    md: 'spacerMd'
  }}>
          <ToolbarItem alignItems="center" isOverflowContainer>{PageNav}</ToolbarItem>

              
            </ToolbarGroup>
            <ToolbarItem visibility={{
    default: 'hidden',
    md: 'visible'
  }}>
              
            </ToolbarItem>
          </ToolbarContent>
        </Toolbar>
      </MastheadContent>
    </Masthead>;

  return <React.Fragment>
      <Page header={masthead} isManagedSidebar>
        <PageBreadcrumb>
          {/* <Breadcrumb >
            {Object.keys(breadcrumbItems).map((key, idx, arr) => <BreadcrumbItem key={idx} isActive={arr.length - 1 === idx} to={`${breadcrumbItems[key].url}`}>
                {translation.breadcrumbs[key]}
                {breadcrumbItemsLength}
              </BreadcrumbItem>)}
          </Breadcrumb> */}
        </PageBreadcrumb>
        <PageSection variant="light">
         
        </PageSection>
        <PageSection className="landing-container">
          <AppHomeContent/>
        </PageSection>
      </Page>
    </React.Fragment>;
};


export default AppHomeLayout