import React, { useEffect, useState, forwardRef, useMemo } from 'react'
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    CardFooter,
    Divider,
    Flex,
    Grid,
    GridItem,
    Icon,
    Modal,
    Progress,
    Text,
    TextContent,
    TextVariants,
    ModalVariant,
    Tabs,
    Tab,
    TabTitleText,
    Bullseye,
    FlexItem,
    Tooltip,
    CardHeader,
    DropdownList,
    DropdownItem,
    Dropdown,
    MenuToggle,
} from '@patternfly/react-core'
import { connect } from 'react-redux'
import NetInBarChart from '../NetworkInsight/ENIBarChart'
import EDonutChart from '../AllCharts/EDonutChart'
import Homebarchart from 'src/Layout/Homebarchart'
import GpgScatterChart from '../Gpg13/GpgScatterChart'
import Elinestackchart from '../AllCharts/Elinestackchart'
import { formatValue } from 'src/util/ChartHelper'
import VulnerableStatus from '../Vulnerabilities/VulnerableStatus'
import IncidentPieChart from './IncidentChart/IncidentPieChart'



const OverviewDashboard = forwardRef(function OverviewDashboard(props, ref) {
    const [filterFields, setFilterFields] = React.useState([]);
    const [theme, setTheme] = React.useState('light-theme');
    const [sidebarState, setSidebarState] = React.useState(false);
    const [isevolutiontimeOpen, setIsEvolutionTimeOpen] = React.useState(false);
    const [selectedterm, setSelectedterm] = React.useState('Last 24 hour');
    const staticTopData = [
        { key: 'Category A', doc_count: 120, type: 'Type1' },
        { key: 'Category B', doc_count: 90, type: 'Type2' },
        { key: 'Category C', doc_count: 150, type: 'Type3' },
        { key: 'Category D', doc_count: 200, type: 'Type4' },
    ];


    const staticLineseries = [
        {
            key: 'Pending',
            date_histogram_agg: {
                buckets: [
                    { key_as_string: '2024-08-01T00:00:00Z', doc_count: 20 },
                    { key_as_string: '2024-08-02T00:00:00Z', doc_count: 30 }
                ]
            }
        },
        {
            key: 'Progress',
            date_histogram_agg: {
                buckets: [
                    { key_as_string: '2024-08-01T00:00:00Z', doc_count: 15 },
                    { key_as_string: '2024-08-02T00:00:00Z', doc_count: 25 }
                ]
            }
        },
        {
            key: 'Resolve',
            date_histogram_agg: {
                buckets: [
                    { key_as_string: '2024-08-01T00:00:00Z', doc_count: 10 },
                    { key_as_string: '2024-08-02T00:00:00Z', doc_count: 25 }
                ]
            }
        }
    ];


    const staticFilterFields = 'customField';

    // Optional: Specify a static height for the chart container

    const [severityStatus, setSeverityStatus] = useState([]);
    const seriesData = [
        { key: 'Category A', doc_count: 10 },
        { key: 'Category B', doc_count: 20 },
        { key: 'Category C', doc_count: 30 },
        { key: 'Category D', doc_count: 50 },
    ];

    const color = ['#FF6B6B', '#FFD93D', '#6BCB77', '#3c763d'];
    const staticData = {
        EventSeries: [
            {
                key_as_string: '2024-09-01T08:00:00.000Z',
                doc_count: 50,
                rule_level_filter: { rule_level_counts: { buckets: [] } }
            },
            {
                key_as_string: '2024-09-01T09:00:00.000Z',
                doc_count: 30,
                rule_level_filter: { rule_level_counts: { buckets: [{ key: 'critical', doc_count: 5 }] } }
            },
        ],
        EventLabel: 'hour',
        StartDateTime: new Date('2024-09-01T00:00:00.000Z'),
        EndDateTime: new Date('2024-09-01T23:59:59.000Z'),
        TotalEvents: 100,
        CriticalCount: 5,
        TDCount: 10,
        theme: 'light-theme', // or 'dark-theme'
        sidebarState: true, // or false
    };

    const staticSeriesData = [
        {
            key: 'Series 1',
            field: 'field1',
            date_histogram_agg: [
                { key_as_string: '2024-09-01T00:00:00Z', doc_count: 10 },
                { key_as_string: '2024-09-02T00:00:00Z', doc_count: 20 },
                { key_as_string: '2024-09-03T00:00:00Z', doc_count: 30 },
            ],
        },
        {
            key: 'Series 2',
            field: 'field2',
            date_histogram_agg: [
                { key_as_string: '2024-09-01T00:00:00Z', doc_count: 15 },
                { key_as_string: '2024-09-02T00:00:00Z', doc_count: 25 },
                { key_as_string: '2024-09-03T00:00:00Z', doc_count: 35 },
            ],
        },
    ];

    const staticStartDateTime = new Date('2024-09-01T00:00:00Z');
    const staticEndDateTime = new Date('2024-09-03T23:59:59Z');
    const staticLabel = 'day'; // or 'hour', '30 seconds'
    //   const staticFilterFields = 'customField';
    const staticActionData = [
        { key: 'Pending', doc_count: 20 },
        { key: 'Progress', doc_count: 60 },
        { key: 'Resolve', doc_count: 20 },

    ];

    const colorScheme = ['#C9190B', '#ffbf00', '#4CB140'];

    const severityData = [
        { key: 'C', doc_count: 20 },
        { key: 'H', doc_count: 30 },
        { key: 'M', doc_count: 25 },
        { key: 'L', doc_count: 25 }
    ];

    // Colors corresponding to the severity levels
    const severityColors = ['#8B0000', '#C9190B', '#ffbf00', '#00c0ef'];

    const IncidentResolution = useMemo(() => {
        return (
            <IncidentPieChart
                ActionData={severityData}
                height='200px'
                color={severityColors}
                // filterFields={filterFields}
                // filterformdata={{}} 
                theme="light-theme" // or "dark-theme"
                sidebarState={false} // Example sidebar state
            />
        )
    }, [])
    const IncidentStackChart = useMemo(() => {
        return (
            <IncidentPieChart
                ActionData={staticActionData}
                height='200px'
                color={colorScheme}
                // filterFields={filterFields}
                // filterformdata={{}} 
                theme="light-theme" // or "dark-theme"
                sidebarState={false} // Example sidebar state
            />
        )
    }, [])
    const MTTR = useMemo(() => {
        return (
            <NetInBarChart
                TopData={staticTopData}
                filterFields={staticFilterFields}
                height={'200px'}
                theme="light-theme" // or 'dark-theme' depending on your preference
                sidebarState={false} // Set based on whether your sidebar is open or not
            />
        )
    }, [])
    const AssignedUser = useMemo(() => {
        return (
            <Homebarchart
                EventSeries={staticData.EventSeries}
                EventLabel={staticData.EventLabel}
                StartDateTime={staticData.StartDateTime}
                EndDateTime={staticData.EndDateTime}
                TotalEvents={staticData.TotalEvents}
                CrticalCount={staticData.CriticalCount}
                TDCount={staticData.TDCount}
                theme={staticData.theme}
                sidebarState={staticData.sidebarState}
            />
        )
    }, [])
    const IncidentLineChart = useMemo(() => {
        return (
            <> <Elinestackchart
                lineseries={staticLineseries}
                label="hour"
                StartDateTime={new Date("2024-08-01T00:00:00Z")}
                EndDateTime={new Date("2024-08-02T00:00:00Z")}
                filterFields={filterFields}
                theme={theme}
                legendwidth={200}
                height='200px'
                color={['#C9190B', '#ffbf00', '#4CB140']}
                sidebarState={sidebarState}
            /></>
        )
    }, [])
    const IncidentOverTime = useMemo(() => {
        return (
            <Homebarchart
                EventSeries={staticData.EventSeries}
                EventLabel={staticData.EventLabel}
                StartDateTime={staticData.StartDateTime}
                EndDateTime={staticData.EndDateTime}
                TotalEvents={staticData.TotalEvents}
                CrticalCount={staticData.CriticalCount}
                TDCount={staticData.TDCount}
                theme={staticData.theme}
                sidebarState={staticData.sidebarState}
            />
        )
    }, [])

    const IncidentClose = useMemo(() => {
        return (
            <GpgScatterChart
                seriesData={staticSeriesData}
                StartDateTime={staticStartDateTime}
                EndDateTime={staticEndDateTime}
                label={staticLabel}
                filterFields={staticFilterFields}
                height='200px' // Adjust the height as needed
                theme='light-theme' // or 'dark-theme'
            />
        )
    }, [])
    const onSelect = (_event, value) => {
        setSelectedterm(value);
        setIsEvolutionTimeOpen(false);
    };

    const onToggleClick = () => {
        setIsEvolutionTimeOpen(!isevolutiontimeOpen);
    };
    const toggle = toggleRef =>
        <MenuToggle ref={toggleRef} onClick={onToggleClick}
            isExpanded={isevolutiontimeOpen}> {selectedterm}</MenuToggle>
    const Timelist = [
        { term: "Today", key: "option1" },
        { term: "This week", key: "option2" },
        { term: "Last 15 minutes", key: "option3" },
        { term: "Last 30 minutes", key: "option4" },
        { term: "Last 1 hour", key: "option5" },
        { term: "Last 24 hours", key: "option6" },
        { term: "Last 7 days", key: "option7" },
        { term: "Last 30 days", key: "option8" },
        { term: "Last 90 days", key: "option9" },
        { term: "Last 1 year", key: "option10" },
    ]
    const timedropdown =
        <Dropdown
            isOpen={isevolutiontimeOpen}
            onSelect={onSelect}
            onOpenChange={isevolutiontimeOpen => setIsEvolutionTimeOpen(isevolutiontimeOpen)}
            toggle={toggle}
            ouiaId="BasicDropdown"
            shouldFocusToggleOnSelect isScrollable>
            {Timelist.map((item, index) => (
                <DropdownList key={index}>
                    <DropdownItem key={item.key}
                        value={item.term}

                    >{item.term}</DropdownItem>
                </DropdownList>
            ))}
        </Dropdown>


    return (<>
        <Grid hasGutter>
            <GridItem md={7} lg={5} sm={8}>
                <Card isFullHeight isRounded>
                    <CardTitle align="center" className='title-height'>Incident Overview</CardTitle>
                    <CardBody>
                        {/* Static data without loading state */}
                        <Flex className='pf-v5-u-justify-content-center' spaceItems={{ default: 'spaceItemsXs', lg: 'spaceItemsXl' }}>
                            <FlexItem>
                                <Text component={TextVariants.h1} className='pf-v5-u-text-align-center pf-v5-u-font-weight-bold'>
                                    <span className='pf-v5-u-font-size-4xl'>100</span>

                                </Text>
                                <Text component={TextVariants.small} className='pf-v5-u-color-100 grid-text-center'> Incident</Text>
                            </FlexItem>
                            <Divider orientation={{ default: 'vertical' }} style={{ '--pf-v5-c-divider--BackgroundColor': '#8A8D90' }} />
                            <FlexItem>
                                <Text component={TextVariants.h1} className='pf-v5-u-text-align-center pf-v5-u-font-weight-bold'>
                                    <a className='pf-v5-u-font-size-4xl pf-v5-u-danger-color-100'>20</a>
                                </Text>
                                <Text component={TextVariants.small} className='pf-v5-u-danger-color-100 grid-text-center'> Pending</Text>
                            </FlexItem>
                            <Divider orientation={{ default: 'vertical' }} style={{ '--pf-v5-c-divider--BackgroundColor': '#8A8D90' }} />
                            <FlexItem>
                                <Text component={TextVariants.h1} className='pf-v5-u-text-align-center pf-v5-u-font-weight-bold'>
                                    <a className='pf-v5-u-font-size-4xl pf-v5-u-warning-color-100'>60</a>
                                </Text>
                                <Text component={TextVariants.small} className='pf-v5-u-warning-color-100 grid-text-center'> Progress</Text>
                            </FlexItem>
                            <Divider orientation={{ default: 'vertical' }} style={{ '--pf-v5-c-divider--BackgroundColor': '#8A8D90' }} />
                            <FlexItem>
                                <Text component={TextVariants.h1} className='pf-v5-u-text-align-center pf-v5-u-font-weight-bold'>
                                    <a className='pf-v5-u-font-size-4xl pf-v5-u-success-color-100'>20</a>
                                </Text>
                                <Text component={TextVariants.small} className='pf-v5-u-success-color-100 grid-text-center'> Resolve</Text>
                            </FlexItem>
                        </Flex>
                    </CardBody>
                </Card>
            </GridItem>
            <GridItem md={3} sm={12} lg={5}>
                <Card isFullHeight isRounded>
                    <CardTitle align="center" className='title-height' >Task Overview</CardTitle>
                    <CardBody>

                        <Flex className='pf-v5-u-justify-content-center' spaceItems={{ default: 'spaceItemsXs', lg: 'spaceItemsXl' }}>

                            <FlexItem>
                                <Text component={TextVariants.h1} className='pf-v5-u-text-align-center pf-v5-u-font-weight-bold'>
                                    <span className='pf-v5-u-font-size-4xl'>100</span>
                                </Text>
                                <Text component={TextVariants.small} className='pf-v5-u-color-100 grid-text-center'> Task</Text>
                            </FlexItem>
                            <Divider orientation={{ default: 'vertical' }} style={{ '--pf-v5-c-divider--BackgroundColor': '#8A8D90' }} />
                            <FlexItem>
                                <Text component={TextVariants.h1} className='pf-v5-u-text-align-center pf-v5-u-font-weight-bold'>
                                    <a className='pf-v5-u-font-size-4xl pf-v5-u-danger-color-100'>20</a>
                                </Text>
                                <Text component={TextVariants.small} className='pf-v5-u-danger-color-100 grid-text-center'> Waiting</Text>
                            </FlexItem>
                            <Divider orientation={{ default: 'vertical' }} style={{ '--pf-v5-c-divider--BackgroundColor': '#8A8D90' }} />
                            <FlexItem>
                                <Text component={TextVariants.h1} className='pf-v5-u-text-align-center pf-v5-u-font-weight-bold'>
                                    <a className='pf-v5-u-font-size-4xl pf-v5-u-warning-color-100'>60</a>

                                </Text>
                                <Text component={TextVariants.small} className='pf-v5-u-warning-color-100 grid-text-center'> InProgress</Text>
                            </FlexItem>
                            <Divider orientation={{ default: 'vertical' }} style={{ '--pf-v5-c-divider--BackgroundColor': '#8A8D90' }} />
                            <FlexItem>
                                <Text component={TextVariants.h1} className='pf-v5-u-text-align-center pf-v5-u-font-weight-bold'>
                                    <a className='pf-v5-u-font-size-4xl pf-v5-u-success-color-100'>20</a>

                                </Text>
                                <Text component={TextVariants.small} className='pf-v5-u-success-color-100 grid-text-center'> Completed</Text>
                            </FlexItem>
                        </Flex>

                    </CardBody>
                </Card>
            </GridItem>
            <GridItem md={3} sm={12} lg={2}>
                <Card isFullHeight isRounded>
                    <CardTitle align="center" className='title-height'>Total Alert</CardTitle>
                    <CardBody>
                        <Text component={TextVariants.h1} className='pf-v5-u-text-align-center pf-v5-u-font-weight-bold'>
                            <span className='pf-v5-u-font-size-4xl'>40</span>

                        </Text>
                    </CardBody>
                </Card>
            </GridItem>
        </Grid>
        <div className="pf-v5-u-mt-md">
            <Grid hasGutter>
            <GridItem md={8} sm={12} lg={4}>
                    <Card isFullHeight isRounded>
                        <CardHeader actions={{ actions: timedropdown, hasNoOffset: false }}>
                            <CardTitle className='title-height'>Incident Trend</CardTitle>
                        </CardHeader>

                        <Divider />
                        <CardBody >{IncidentLineChart}</CardBody>
                        <CardFooter>
                            <TextContent>
                                <Text className="pf-v5-u-font-size-sm">Updated Interval 24hrs | Last Update: july 6th 2023 13:48:17  Update Now</Text>
                            </TextContent>
                        </CardFooter>
                    </Card>
                </GridItem>
                <GridItem md={8} sm={12} lg={4}>
                    <Card isRounded isFullHeight>
                        <CardTitle className='title-height'>Incidents Break Down</CardTitle>
                        <Divider />
                        <CardBody>
                            {IncidentStackChart}
                        </CardBody>
                        <CardFooter>
                            <TextContent>
                                <Text className="pf-v5-u-font-size-sm">Updated Interval 24hrs | Last Update: july 6th 2023 13:48:17  Update Now</Text>
                            </TextContent>
                        </CardFooter>
                    </Card>
                </GridItem>
                
                <GridItem md={8} sm={12} lg={4}>
                    <Card isFullHeight isRounded>
                        <CardTitle className='title-height'>Incident Resolution BreakDown</CardTitle>
                        <Divider />
                        <CardBody >{IncidentResolution}</CardBody>
                        <CardFooter>
                            <TextContent>
                                <Text className="pf-v5-u-font-size-sm">Updated Interval 24hrs | Last Update: july 6th 2023 13:48:17  Update Now</Text>
                            </TextContent>
                        </CardFooter>
                    </Card>
                </GridItem>
            </Grid>
            <div className="pf-v5-u-mt-md">
                <Grid hasGutter>
                    <GridItem md={8} sm={12} lg={6}>
                        <Card isFullHeight isRounded>
                            <CardTitle className='title-height'>MTTR By Open vs.Close</CardTitle>
                            <Divider />
                            <CardBody >{MTTR}</CardBody>
                            <CardFooter>
                                <TextContent>
                                    <Text className="pf-v5-u-font-size-sm">Updated Interval 24hrs | Last Update: july 6th 2023 13:48:17  Update Now</Text>
                                </TextContent>
                            </CardFooter>
                        </Card>
                    </GridItem>
                    <GridItem md={6} sm={12} lg={6}>
                        <Card isFullHeight isRounded>
                            <CardTitle className='title-height'>Incident Over Time
                            </CardTitle>
                            <Divider />
                            <CardBody >{IncidentOverTime}</CardBody>
                            <CardFooter>
                                <TextContent>
                                    <Text className="pf-v5-u-font-size-sm">Updated Interval 24hrs | Last Update: july 6th 2023 13:48:17  Update Now</Text>
                                </TextContent>
                            </CardFooter>
                        </Card>
                    </GridItem>

                </Grid>
            </div>
        </div>
        <div className="pf-v5-u-mt-md">
            <Grid hasGutter>
                <GridItem md={8} sm={12} lg={6}>
                    <Card isFullHeight isRounded>
                        <CardTitle >Assigned User</CardTitle>
                        <Divider />
                        <CardBody >{AssignedUser}</CardBody>
                        <CardFooter>
                            <TextContent>
                                <Text className="pf-v5-u-font-size-sm">Updated Interval 24hrs | Last Update: july 6th 2023 13:48:17  Update Now</Text>
                            </TextContent>
                        </CardFooter>
                    </Card>
                </GridItem>
                <GridItem md={6} sm={12} lg={6}>
                    <Card isFullHeight isRounded>
                        <CardTitle >Incident Closed by Severity and Analyst
                        </CardTitle>
                        <Divider />
                        <CardBody >{IncidentClose}</CardBody>
                        <CardFooter>
                            <TextContent>
                                <Text className="pf-v5-u-font-size-sm">Updated Interval 24hrs | Last Update: july 6th 2023 13:48:17  Update Now</Text>
                            </TextContent>
                        </CardFooter>
                    </Card>
                </GridItem>
            </Grid>
        </div>
    </>
    )
}
)
const mapStateToProps = (state) => ({
    dates: { startDate: state.startdate, endDate: state.enddate },
    filterformdata: state.FilterData,
    selectedGroup: state.SelectedGroup,
    // mapStateToProps content remains unchanged if you have it
});

const mapDispatchToProps = (dispatch) => { return {} }
export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(OverviewDashboard)

