//2nd which is perfectly run and usable
import React, { useEffect, useState } from "react";
import {
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Table,
} from "@patternfly/react-table";
import { Emptystatefornodata } from "src/util/EmptyStateCard"; 
import { Badge, Divider, Pagination, Toolbar, ToolbarContent, ToolbarItem } from "@patternfly/react-core";
import { UpdateFilterData } from "src/util/FilterHelper";
import { FilterData } from "src/Redux/Action";
import { connect, useDispatch } from "react-redux";
const TopSCARule = (props) => {
  // console.log("->",props.RuleDesc)
  const dispatch = useDispatch();
  const defaultColumns = [
    "Rules",
    "Count",
  ];
  const [columns, setColumns] = useState(defaultColumns);
  const [rows, setRows] = useState([]);
  const [defaultRows, setDefaultRows] = useState([]);
  const [selectedRowName, setSelectedRowName] = React.useState('');
  const [page, setPage] = React.useState(1);
  const [perPage, setPerPage] = React.useState(10);
  const [itemcount, setItemcount] = useState(0)

  const resetPagination = () => {
    setPage(page);
    setPerPage(perPage); // Set to your desired default perPage value
  };

  useEffect(() => {
    const newdefaultRows = props.RuleDesc.map((log) => {
      return {

        rules: log.rule_description,
        count: log.count,
      };
    });
    resetPagination();
    setItemcount(newdefaultRows.length)
    setDefaultRows(newdefaultRows)
    // if (defaultRows.length !== rows.length) {
    const startIndex = (page - 1) * perPage;
    const endIndex = startIndex + perPage;
    const slicedRows = defaultRows.slice(startIndex, endIndex);
    // console.log("slicerows",slicedRows)
    setRows(slicedRows);
    // }
  }, [props.RuleDesc, rows.length]);

  const onSetPage = (_event, newPage) => {
    setPage(newPage);
  };
  const onPerPageSelect = (_event, newPerPage, newPage) => {
    setPerPage(newPerPage);
    setPage(newPage);
  };
  useEffect(() => {
    const startIndex = (page - 1) * perPage;
    const endIndex = startIndex + perPage;
    const slicedRows = defaultRows.slice(startIndex, endIndex);
    // console.log("after slicing", "", startIndex, endIndex, slicedRows)
    setRows(slicedRows);
  }, [defaultRows, page, perPage]);
  const renderPagination = () => <Pagination
    itemCount={itemcount}
    perPage={perPage}
    page={page}
    onSetPage={onSetPage}
    onPerPageSelect={onPerPageSelect}
  />
  const handleFilter = (fields, value) => {
    const updatedFields = UpdateFilterData(props.filterformdata, fields, 'is', value);
    dispatch(FilterData(updatedFields));
  }

  return (

    <React.Fragment>
      {props.RuleDesc.length === 0 ? (
        <Emptystatefornodata />
      ) : (
        <>
          {props.inModal ?
            <Toolbar>
              <ToolbarContent>
                <ToolbarItem variant="pagination" >{renderPagination()}</ToolbarItem>
              </ToolbarContent>
            </Toolbar>
            : null
          }
          {/* <Divider/> */}
          <Table variant="compact" aria-label="signlog" id="signlog-table" isStickyHeader>
            <Thead>
              <Tr>
                {columns.map((column) => (
                  <Th key={column}>{column}</Th>
                ))}
              </Tr>
            </Thead>
            {rows.map((row, rowIndex) => (
              <Tbody key={rowIndex} >
                <Tr key={rowIndex}
                  onRowClick={() => setSelectedRowName(rowIndex)}
                  isSelectable
                  isClickable
                  isRowSelected={selectedRowName === rowIndex}  >
                  <Td width={90} dataLabel="Rules" modifier="truncate">{row?.rules}</Td>
                  <Td width={10} dataLabel="Count"><a onClick={() => { handleFilter('rule.description', row?.rules) }}>{row?.count}</a></Td>
                </Tr>
              </Tbody>
            ))}
          </Table>
          <div className="pf-v5-u-mt-sm">{renderPagination()}</div>
        </>
      )}
    </React.Fragment>
  );
};
const mapStateToProps = (state) => ({
  dates: { startDate: state.startdate, endDate: state.enddate },
  filterformdata: state.FilterData,
  // mapStateToProps content remains unchanged if you have it
})
export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(TopSCARule)