import React, { useEffect, useState, forwardRef, useMemo } from 'react'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Divider,
  Flex,
  FlexItem,
  Grid,
  GridItem,
  Modal,
  ModalVariant,
  Tab,
  TabContentBody,
  TabTitleText,
  Tabs,
} from '@patternfly/react-core';
import '@patternfly/react-core/dist/styles/base.css';
import dbApi from '../../services/DbApi';
import SystemAuditingCommands from './SACommands';
import { Emptystatefornodata } from '../../util/EmptyStateCard';
import { connect, useDispatch } from 'react-redux';
import SystemAuditingGroups from './SAGroups';
import EAreaChart from '../AllCharts/EAreaChart';
import TableViewForChart from '../AllCharts/TableViewForChart';
import { Loader } from '../../util/Loadingstate';


const SystemAuditingDashboard = forwardRef(function SystemAuditingDashboard(props, ref) {
  const dispatch = useDispatch();
  // const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [groupseries, setGroupseries] = useState([]);
  const [agentseries, setagentseries] = useState([]);
  const [filesseries, setfilesseries] = useState([]);
  const [alertovertime, setAlertovertime] = useState([]);
  const [alertlabel, setAlertLabel] = useState();
  const [commandsseries, setcommandsseries] = useState([]);
  const [activeTabKey, setActiveTabKey] = useState(0);
  //Loading states
  const [isloadGroup, setIsLoadGroup] = useState(false)
  const [isloadAgent, setIsLoadAgent] = useState(false)
  const [isloadCommand, setIsLoadCommand] = useState(false)
  const [isloadFiles, setIsLoadFiles] = useState(false)
  const [isloadAlerts, setIsLoadAlerts] = useState(false)
  //Modal states
  const [modalStates, setModalStates] = useState({
    modalGroup: false,
    modalAgents: false,
    modalFiles: false,
    modalCommands: false,
    modalAlerts: false,
  });
  const handleTabClick = (event, tabIndex) => {
    setActiveTabKey(tabIndex)
  }

  const getGroups = async (data) => {
    try {
      setIsLoadGroup(true)
      let all_groups = await dbApi.postApi("Opensearch/System_Dashboard_Auditing_Groups", data);
      console.log(all_groups)
      let GroupSeries = [];
      let groups = all_groups.System_Dashboard_Auditing_Groups
      if (Object.keys(groups).length !== 0) {
        GroupSeries = groups.body.aggregations.Groups.buckets

        setGroupseries(GroupSeries)
      }
      else {
        setGroupseries(GroupSeries)
      }
      setIsLoadGroup(false)
    } catch (error) {
      setIsLoadGroup(false)
      setGroupseries([])
      console.log(error)

    }
  }

  const getAgents = async (data) => {
    try {
      setIsLoadAgent(true)
      let all_agents = await dbApi.postApi("Opensearch/System_Dashboard_Auditing_Agents", data);
      console.log(all_agents)
      let AgentSeries = [];
      let agents = all_agents.System_Dashboard_Auditing_Agents;
      if (Object.keys(agents).length !== 0) {
        AgentSeries = agents.body.aggregations.Agents.buckets
        setagentseries(AgentSeries)
      }
      else {
        setagentseries(AgentSeries)
      }
      setIsLoadAgent(false)
    } catch (error) {
      setIsLoadAgent(false)
      setagentseries([])
      console.log(error)
    }
  }

  const getFiles = async (data) => {
    try {
      setIsLoadFiles(true)
      let all_files = await dbApi.postApi("Opensearch/System_Dashboard_Auditing_Files", data);
      console.log(all_files)
      let FileSeries = [];
      let files = all_files.System_Dashboard_Auditing_Files
      if (Object.keys(files).length !== 0) {
        FileSeries = files.body.aggregations.files.buckets;
        setfilesseries(FileSeries)
      }
      else {
        setfilesseries(FileSeries)
      }
      setIsLoadFiles(false)
    } catch (error) {
      setIsLoadFiles(false)
      setfilesseries([])
      console.log(error)
    }
  }
  //Opensearch/System_Dashboard_Auditing_Commands
  const getCommands = async (data) => {
    try {
      setIsLoadCommand(true)
      let all_commands = await dbApi.postApi("Opensearch/System_Dashboard_Auditing_Commands", data);
      console.log(all_commands)
      let CommandSeries = [];
      let commands = all_commands.System_Dashboard_Auditing_Commands
      if (Object.keys(commands).length !== 0) {
        CommandSeries = commands.body.aggregations.commands.buckets
        setcommandsseries(CommandSeries)
      } else {
        setcommandsseries(CommandSeries)
      }
      setIsLoadCommand(false)
    } catch (error) {
      setIsLoadCommand(false)
      setcommandsseries([])
      console.log(error)
    }
  }
  //Opensearch/System_Dashboard_Auditing_Alerts_Over_Time
  const getAlertsOverTime = async (data) => {
    try {
      setIsLoadAlerts(true)
      let all_alerts = await dbApi.postApi("Opensearch/System_Dashboard_Auditing_Alerts_Over_Time", data);
      console.log(all_alerts)
      let AlertOverTime = []
      let AlertLabel = ""
      let Alerts = all_alerts.System_Dashboard_Auditing_Alerts_Over_Time
      if (Object.keys(Alerts).length !== 0) {
        AlertOverTime = Alerts.data.body.aggregations.Alerts_over_time.buckets
        AlertLabel = Alerts.interval
        setAlertLabel(AlertLabel)
        setAlertovertime(AlertOverTime)
      }
      else {
        setAlertLabel(AlertLabel)
        setAlertovertime(AlertOverTime)
      }
      setIsLoadAlerts(false)
    } catch (error) {
      setIsLoadAlerts(false)
      setAlertLabel('')
      setAlertovertime([])
      console.log(error)
    }
  }

  const SystemAuditingDashApi = async (startDate, endDate, allFields) => {
    const Feilds = allFields
    // const searchcondition = [{}]
    // const searchregex = [{}]
    const data = {
      startDate: startDate,
      endDate: endDate,
      filter: JSON.stringify(Feilds),
    };
    try {
      // setLoading(false);
      setStartDate(startDate);
      setEndDate(endDate);
      getGroups(data);
      getAgents(data);
      getFiles(data);
      getAlertsOverTime(data);
      getCommands(data);
      console.log(data)

    }
    catch (error) {
      console.error(error);
      // setLoading(false);
    }
  }


  useEffect(() => {

    if (props.dates.startDate != "") {
      let Dates = props.dates
      SystemAuditingDashApi(Dates.startDate, Dates.endDate, props.filterformdata,)
      //childSecurityAlertsRef.current.setSecurityAlertsTable(securityAlertsLog, SAscrollId,);
    }
  }, [props?.dates.startDate, props?.dates.endDate, props?.filterformdata,]);


  const handleModalToggle = (modalName) => {
    setModalStates((prevStates) => ({
      ...prevStates,
      [modalName]: !prevStates[modalName],
    }));
  };
  const colorforevent = ['#8BC1F7', '#06C', '#002F5D', '#519DE9', '#004B95']

  const SAGroups = useMemo(() => {
    return (
      isloadGroup ? <Loader /> :
        groupseries.length > 0 ?
          <SystemAuditingGroups GroupData={groupseries.slice(0, 5)} height="220px" filterFields={'rule.groups'} />
          :
          <Emptystatefornodata />
    )
  }, [isloadGroup, groupseries])
  const SAAgents = useMemo(() => {
    return (
      isloadAgent ? <Loader /> : agentseries.length > 0 ?
        <SystemAuditingGroups GroupData={agentseries.slice(0, 5)} height="220px" filterFields={'agent.name'} />
        :
        <Emptystatefornodata />
    )
  }, [isloadAgent, agentseries])
  const SACommands = useMemo(() => {
    return (
      isloadCommand ? <Loader /> :
        commandsseries.length > 0 ?
          <SystemAuditingCommands CommandData={commandsseries.slice(0, 20)} height="210px" filterFields={'data.audit.command'} />
          :
          <Emptystatefornodata />
    )
  }, [isloadCommand, commandsseries])
  const SAFiles = useMemo(() => {
    return (
      isloadFiles ? <Loader /> :
        filesseries.length > 0 ?
          <SystemAuditingCommands CommandData={filesseries.slice(0, 20)} height="220px" filterFields={'data.audit.file.name'} />
          :
          <Emptystatefornodata />
    )
  }, [isloadFiles, filesseries])
  return (
    <React.Fragment>

      <Grid hasGutter>
        <GridItem md={props?.hideCard ? 4 : 6} sm={12} lg={props?.hideCard ? 4 : 3}>
          <Card isFullHeight>
            <CardTitle>Groups</CardTitle>
            <Divider />
            <CardBody> {SAGroups}</CardBody>
            {groupseries.length > 0 &&
              <CardFooter>
                <Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
                  <Button variant="link" onClick={() => { setActiveTabKey(0); handleModalToggle('modalGroup'); }}>
                    More
                  </Button>
                  <Modal
                    variant={ModalVariant.large}
                    title={`Groups (${groupseries.length})`}
                    isOpen={modalStates.modalGroup}
                    onClose={() => { handleModalToggle('modalGroup') }}
                  >
                    <Divider className='modal-Divider' />
                    <Card>
                      <Tabs activeKey={activeTabKey} onSelect={handleTabClick} aria-label="Tabs in the default example" role="region">
                        <Tab eventKey={0} title={<TabTitleText>Table View</TabTitleText>}>
                          {isloadGroup ? <Loader /> : <TableViewForChart Tabledata={groupseries} columnData={["Groups", "Count"]} filterFields={'rule.groups'} />}
                        </Tab>
                        <Tab eventKey={1} title={<TabTitleText>Graph View</TabTitleText>} aria-label="graph">
                        {activeTabKey == '1' && <TabContentBody className='pf-v5-u-p-xs'> {isloadGroup ? <Loader /> :
                            groupseries.length > 0 ? <SystemAuditingGroups GroupData={groupseries} height="300px" filterFields={'rule.groups'} />
                              :
                              <Emptystatefornodata />
                          }</TabContentBody>}
                        </Tab>
                      </Tabs>
                    </Card>
                  </Modal>
                </Flex>
              </CardFooter>
            }
          </Card>
        </GridItem>
        {props?.hideCard ? ' ' :
          <GridItem md={6} sm={12} lg={3}>
            <Card isFullHeight>
              <CardTitle>Agents</CardTitle>
              <Divider />
              <CardBody>{SAAgents} </CardBody>
              {agentseries.length > 0 &&
                <CardFooter>
                  <Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
                    <Button variant="link" onClick={() => { setActiveTabKey(0); handleModalToggle('modalAgents'); }}>
                      More
                    </Button>
                    <Modal
                      variant={ModalVariant.large}
                      title={`Agents(${agentseries.length})`}
                      isOpen={modalStates.modalAgents}
                      onClose={() => { handleModalToggle('modalAgents') }}
                    >
                      <Divider className='modal-Divider' />
                      <Card>
                        <Tabs activeKey={activeTabKey} onSelect={handleTabClick} aria-label="Tabs in the default example" role="region">
                          <Tab eventKey={0} title={<TabTitleText>Table View</TabTitleText>}>
                            {isloadAgent ? <Loader /> : <TableViewForChart Tabledata={agentseries} columnData={["Agents", "Count"]} filterFields={'agent.name'} />}
                          </Tab>
                          <Tab eventKey={1} title={<TabTitleText>Graph View</TabTitleText>} aria-label="graph">
                          {activeTabKey == '1' && <TabContentBody className='pf-v5-u-p-xs'>  {isloadAgent ? <Loader /> :
                              agentseries.length > 0 ?
                                <SystemAuditingGroups GroupData={agentseries} height="300px" filterFields={'agent.name'} />
                                :
                                <Emptystatefornodata />
                            }</TabContentBody>}
                          </Tab>
                        </Tabs>
                      </Card>
                    </Modal>
                  </Flex>
                </CardFooter>
              }
            </Card>
          </GridItem>
        }
        <GridItem md={props?.hideCard ? 4 : 6} sm={12} lg={props?.hideCard ? 4 : 3} >
          <Card isFullHeight>
            <CardTitle>Commands</CardTitle>
            <Divider />
            <CardBody>{SACommands}</CardBody>
            {commandsseries.length > 0 &&
              <CardFooter>
                <Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
                  <Button variant="link" onClick={() => { setActiveTabKey(0); handleModalToggle('modalCommands'); }}>
                    More
                  </Button>
                  <Modal
                    variant={ModalVariant.large}
                    title={`Commands(${commandsseries.length})`}
                    isOpen={modalStates.modalCommands}
                    onClose={() => { handleModalToggle('modalCommands') }}
                  >
                    <Divider className='modal-Divider' />
                    <Card>
                      <Tabs activeKey={activeTabKey} onSelect={handleTabClick} aria-label="Tabs in the default example" role="region">
                        <Tab eventKey={0} title={<TabTitleText>Table View</TabTitleText>}>
                          {isloadCommand ? <Loader /> : <TableViewForChart Tabledata={commandsseries} columnData={["Commands", "Count"]} filterFields={'data.audit.command'} />}
                        </Tab>
                        <Tab eventKey={1} title={<TabTitleText>Graph View</TabTitleText>} aria-label="graph">
                        {activeTabKey == '1' && <TabContentBody className='pf-v5-u-p-xs'>  {isloadCommand ? <Loader /> :
                            commandsseries.length > 0 ?
                              <SystemAuditingCommands CommandData={commandsseries.slice(0, 40)} height="300px" filterFields={'data.audit.command'} />
                              :
                              <Emptystatefornodata />
                          }</TabContentBody>}
                        </Tab>
                      </Tabs>
                    </Card>
                  </Modal>
                </Flex>
              </CardFooter>
            }
          </Card>
        </GridItem>
        <GridItem md={props?.hideCard ? 4 : 6} sm={12} lg={props?.hideCard ? 4 : 3} >
          <Card isFullHeight>
            <CardTitle>Files</CardTitle>
            <Divider />
            <CardBody>{SAFiles}</CardBody>
            {filesseries.length > 0 &&
              <CardFooter>
                <Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
                  <Button variant="link" onClick={() => { setActiveTabKey(0); handleModalToggle('modalFiles'); }}>
                    More
                  </Button>
                  <Modal
                    variant={ModalVariant.large}
                    title={`Files (${filesseries.length})`}
                    isOpen={modalStates.modalFiles}
                    onClose={() => { handleModalToggle('modalFiles') }}
                  >
                    <Divider className='modal-Divider' />
                    <Card>
                      <Tabs activeKey={activeTabKey} onSelect={handleTabClick} aria-label="Tabs in the default example" role="region">
                        <Tab eventKey={0} title={<TabTitleText>Table View</TabTitleText>}>
                          {isloadFiles ? <Loader /> : <TableViewForChart Tabledata={filesseries} columnData={["Files", "Count"]} filterFields={'data.audit.file.name'} />}
                        </Tab>
                        <Tab eventKey={1} title={<TabTitleText>Graph View</TabTitleText>} aria-label="graph">
                        {activeTabKey == '1' && <TabContentBody className='pf-v5-u-p-xs'>  {isloadFiles ? <Loader /> :
                            filesseries.length > 0 ?
                              <SystemAuditingCommands CommandData={filesseries.slice(0, 40)} height="300px" filterFields={'data.audit.file.name'} />
                              :
                              <Emptystatefornodata />
                          }</TabContentBody>}
                        </Tab>
                      </Tabs>
                    </Card>
                  </Modal>
                </Flex>
              </CardFooter>
            }
          </Card>
        </GridItem>
      </Grid>
      <div className="pf-v5-u-mt-md">
        <Grid hasGutter>
          <GridItem md={12} sm={12} lg={12} >
            <Card isFullHeight>
              <CardTitle>Alerts over time</CardTitle>
              <Divider />
              <CardBody>
                {
                  isloadAlerts ? <Loader /> :

                    <EAreaChart
                      alertlevelSeries={alertovertime}
                      label={alertlabel}
                      StartDateTime={startDate}
                      EndDateTime={endDate}
                      color={colorforevent}
                      filterFields={'rule.description'}
                    />
                }
              </CardBody>
            </Card>
          </GridItem>
        </Grid>
      </div>

    </React.Fragment>




  )

})
const mapStateToProps = (state) => ({
  dates: { startDate: state.startdate, endDate: state.enddate },
  filterformdata: state.FilterData,
  selectedGroup: state.SelectedGroup,
  // mapStateToProps content remains unchanged if you have it
});

const mapDispatchToProps = (dispatch) => { return {} };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(SystemAuditingDashboard)