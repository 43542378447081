import {
    Button,
    Card,
    CardBody,
    FileUpload,
    Flex,
    FlexItem,
    PageSection,
    MenuToggle,
    Select,
    SelectOption,
    SelectList,
    SelectGroup,
    Text,
    Toolbar,
    ToolbarContent,
    ToolbarItem,
    Icon,
    Pagination,
    SearchInput,
    Badge,
    Tooltip,
    ToolbarGroup, TextVariants
} from '@patternfly/react-core'
import { RedoIcon, TrashAltIcon, SortAmountDownIcon } from '@patternfly/react-icons'
import { Table, Tbody, Td, Th, Thead, Tr } from '@patternfly/react-table'
import React, { useMemo, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { Emptystatefornodata } from 'src/util/EmptyStateCard'
import dbApi from 'src/services/DbApi'
import { Loader } from 'src/util/Loadingstate'
import { alertBoxData } from 'src/Redux/Action'
import ConfirmBox from '../ConfirmBox/ConfirmBox'

const CdbLists = (props) => {
    const [filevalue, setFileValue] = useState('');
    const [filename, setFilename] = useState('');
    const [isRejected, setIsRejected] = useState(false);
    const [isSwitchOverwrite, setIsSwitchOverwrite] = useState(false);
    //table state
    const dispatch = useDispatch()
    const defaultColumns = {
        filename: "Name",
        relative_dirname: "Path",
        actions: "Action",
    };
    const [columns, setColumns] = useState(defaultColumns);
    const [rows, setRows] = useState([]);
    const [selectedRowName, setSelectedRowName] = useState('');
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [activeSortIndex, setActiveSortIndex] = useState(0);
    const [totalItem, setTotalItem] = useState(0)
    const [isSortDropdownOpen, setIsSortDropdownOpen] = useState(false);
    const [activeSortDirection, setActiveSortDirection] = useState('asc');
    const [inputValue, setInputValue] = useState('')
    const [searchValue, setSearchValue] = useState('');
    const [loadCDBList, setLoadCDBList] = useState(false)
    const [confirmModalData, setConfirmBox] = useState({
        show: false, msg: '',
        funCall: () => { }, funParam: {}, title: '',
        position: '',
        iconvariant: '',
        submitbtnvariant: '',
    })

    const excludeOrder = ['Action', 'Path']

    const getCDBList = async () => {
        let sort = ''
        if (activeSortDirection === 'asc') {
            sort = `+filename`
        }
        else {
            sort = `-filename`
        }
        let param = {
            offset: (perPage * page) - perPage,
            limit: perPage,
            search: searchValue,
            sort: sort,
        }
        try {
            setLoadCDBList(true)
            let res = await dbApi.postApi("Opensearch/CDB_Lists", param)
            console.log(res)
            let agents = []
            if (res.CDB_Lists.length > 0) {
                agents = res.CDB_Lists
                setRows(agents)
                setTotalItem(res.Total)
            } else {
                setRows([])
                setTotalItem(0)
            }
            setLoadCDBList(false)
        } catch (error) {
            console.log(error)
            setLoadCDBList(false)
        }
    }

    useEffect(() => {
        getCDBList()
    }, [page,
        perPage,
        activeSortIndex,
        activeSortDirection, searchValue])


    const onSort = (event, index, direction) => {
        setActiveSortIndex(index);
        setActiveSortDirection(direction);
    };
    const handleSetPage = (_evt, newPage) => {
        setPage(newPage);
    };
    const handlePerPageSelect = (_evt, newPerPage) => {
        setPerPage(newPerPage);
    };

    const onSearch = event => {
        if (event.key && event.key !== 'Enter') {
            return;
        }
        setSearchValue(inputValue)
        setPage(1)
    }
    const renderPagination = (variant) => (
        <Pagination
            itemCount={totalItem}
            page={page}
            perPage={perPage}
            onSetPage={handleSetPage}
            onPerPageSelect={handlePerPageSelect}
            variant={variant}
            titles={{
                paginationAriaLabel: `${variant} pagination`
            }}
        />
    );

    const finalDelete = async (deletedid) => {
        try {
            let param = {
                cdbName: deletedid
            }
            let res = await dbApi.postApi("Opensearch/CDB_Lists_Delete", param)
            console.log(res)
            if (!res.error) {
                dispatch(alertBoxData([{ title: res.message, variant: 'success' }]));
                getCDBList()
            }
            else {
                dispatch(alertBoxData([{ title: res.message, variant: 'danger' }]));
            }
        } catch (error) {
            console.log(error)
        }
        // Opensearch/Agent_Assigned_To_Group
    }

    const onDelete = (id, ) => {
        setConfirmBox({
            show: true,
            title: `Are you sure?`,
            msg: "",
            funCall: () => { finalDelete(id) },
            funParam: {},
            position: 'default',
            iconvariant: 'warning',
            submitbtnvariant: 'danger',
        })
    }
    const handleSwitchChangeOverwrite = (_event, checked) => {
        setIsSwitchOverwrite(checked);
    };
    const handleFileInputChange = (_event, file) => {
        setFilename(file.name);
    };
    const handleTextChange = (_event, value) => {
        setFileValue(value);
    };

    const handleDataChange = (_event, value) => {
        setFileValue(value);
    };
    const handleClear = (_event) => {
        setFilename('');
        setFileValue('');
        setIsRejected(false);
    };
    const handleFileRejected = () => {
        setIsRejected(true);
    };
    const ImportFilesUpload = useMemo(() => {
        return (
            <FileUpload
                id="text-file-with-restrictions-example"
                type='dataURL'
                // hideDefaultPreview
                value={filevalue}
                filename={filename}
                filenamePlaceholder="Drag and drop a file or upload one"
                onFileInputChange={handleFileInputChange}
                onDataChange={handleDataChange}
                onTextChange={handleTextChange}
                onClearClick={handleClear}
                // dropzoneProps={{
                //     accept: {
                //         'image/png': ['.png'],
                //         'image/jpeg': ['.jpg', '.jpeg'],
                //     },
                //     maxSize: 2097152,
                //     onDropRejected: handleFileRejected
                // }}
                // validated={isRejected ? 'error' : 'default'}
                browseButtonText="Upload"
            >
            </FileUpload>
        )
    }, [filename, filevalue, handleClear, handleTextChange, handleFileInputChange, handleDataChange])


    const tabletoolbar = <React.Fragment>
        <Toolbar>
            <ToolbarContent>
                <ToolbarItem visibility={{ md: 'hidden' }}>
                    <Select isOpen={isSortDropdownOpen} selected={[activeSortDirection, activeSortIndex]} onOpenChange={isOpen => setIsSortDropdownOpen(isOpen)} onSelect={(event, value) => {
                        if (value === 'asc' || value === 'desc') {
                            onSort(event, activeSortIndex, value);
                        } else {
                            onSort(event, value, activeSortDirection !== 'none' ? activeSortDirection : 'asc');
                        }
                    }} toggle={toggleRef => <MenuToggle ref={toggleRef} onClick={() => setIsSortDropdownOpen(!isSortDropdownOpen)} isExpanded={isSortDropdownOpen} variant="plain">
                        <SortAmountDownIcon />
                    </MenuToggle>}>
                        <SelectGroup label="Sort column">
                            <SelectList>
                                {Object.keys(columns).map((column, columnIndex) => <SelectOption key={column} value={columnIndex} isSelected={activeSortIndex === columnIndex}>
                                    {column}
                                </SelectOption>)}
                            </SelectList>
                        </SelectGroup>
                        <SelectGroup label="Sort direction">
                            <SelectList>
                                <SelectOption isSelected={activeSortDirection === 'asc'} value="asc" key="ascending">
                                    Ascending
                                </SelectOption>
                                <SelectOption isSelected={activeSortDirection === 'desc'} value="desc" key="descending">
                                    Descending
                                </SelectOption>
                            </SelectList>
                        </SelectGroup>
                    </Select>
                </ToolbarItem>
                <ToolbarItem style={{ width: '60%' }} variant="search-filter">
                    <SearchInput placeholder="Search..."
                        value={inputValue}
                        onChange={(_event, value) => setInputValue(value)}
                        onSearch={onSearch}
                        onClear={() => { setSearchValue(''); setInputValue('') }} />
                </ToolbarItem>
                <ToolbarItem align={{ default: 'alignRight' }}>
                    <ToolbarItem> {renderPagination('top')}</ToolbarItem>
                </ToolbarItem>
            </ToolbarContent>
        </Toolbar>
    </React.Fragment>
    return (
        <div>
            <PageSection className="pf-v5-u-p-sm">
                <ConfirmBox confirmModal={confirmModalData}/>
                <Card>
                    <CardBody>
                        <>
                            <Flex spaceItems={{ default: 'spaceItemsXs' }} >
                                <FlexItem> <Text component={TextVariants.h1} className="pf-v5-u-font-weight-bold">CDB Lists ({totalItem ?? 0})
                                    <br />
                                    <Text component={TextVariants.small}>From here you can manage your lists.</Text>
                                </Text>
                                </FlexItem>
                                {/* <FlexItem align={{ default: 'alignRight' }}> <Button variant='link' icon={<PlusCircleIcon />}>Add new lists file</Button></FlexItem>
                                <FlexItem >
                                    <Popover
                                        aria-label="Basic popover"
                                        headerContent={<div>Upload Lists</div>}
                                        bodyContent={<div>
                                            <Switch id="overwrite" aria-label="overwrite" isChecked={isSwitchOverwrite} onChange={handleSwitchChangeOverwrite} />
                                            <div className='pf-v5-u-mt-sm'>{ImportFilesUpload}</div>
                                        </div>}
                                        {...(filename !== '' && { footerContent: <div><Button icon={<UploadIcon />}>Upload</Button></div> })}
                                    >
                                        <Button variant='link' icon={<ImportIcon />}>Import files</Button>
                                    </Popover>
                                </FlexItem> */}
                                <FlexItem align={{ default: 'alignRight' }}> <Button variant='link' icon={<RedoIcon />} onClick={() => { getCDBList() }}>refresh</Button></FlexItem>
                                {/* <FlexItem > <Button variant='link' icon={<ExportIcon />} isDisabled={true}>Export formatted</Button></FlexItem> */}
                            </Flex>
                            {loadCDBList ? <Loader /> :
                                <>
                                    {tabletoolbar}
                                    <Table variant='compact'>
                                        <Thead>
                                            <Tr>
                                                {Object.values(columns).map((column, columnIndex) => {
                                                    const stickyColumnsIndex = [0, 1];
                                                    const isSticky = stickyColumnsIndex.includes(columnIndex);
                                                    const sortParams = {
                                                        sort: {
                                                            sortBy: {
                                                                index: activeSortIndex,
                                                                direction: activeSortDirection
                                                            },
                                                            onSort,
                                                            columnIndex
                                                        }
                                                    };
                                                    return !excludeOrder.includes(column) ? <Th key={columnIndex} {...sortParams} isStickyColumn={isSticky} >{column}</Th> : <Th key={columnIndex} isStickyColumn={isSticky}>{column}</Th>;
                                                })}
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {rows.length > 0 ?
                                                rows && rows.map((row, rowIndex) => {

                                                    return (
                                                        <>
                                                            <Tr key={rowIndex + 'agentid'}
                                                                onRowClick={() => setSelectedRowName(rowIndex)}
                                                                isSelectable
                                                                isClickable
                                                                isRowSelected={selectedRowName === rowIndex}
                                                            >

                                                                <Td dataLabel='Name' key={rowIndex + 'name'} width={20}>{row?.filename}</Td>
                                                                <Td dataLabel='Path' key={rowIndex + 'path'} width={20}>{row?.relative_dirname}</Td>
                                                                <Td dataLabel={columns.actions} key={rowIndex + 'action'} isActionCell width={20}>
                                                                    <div>
                                                                        <Flex spaceItems={{ default: 'spaceItemsMd' }}>

                                                                            {/* <FlexItem> <Link ><Tooltip content={`Edit ${row?.filename} content`} ><Icon isInline ><PencilAltIcon /></Icon></Tooltip> </Link></FlexItem> */}
                                                                            <FlexItem> <Tooltip content={`Delete ${row?.filename}`} ><Icon status="danger" onClick={() => { onDelete(row?.filename) }} isInline><TrashAltIcon /></Icon></Tooltip></FlexItem>
                                                                            {/* <FlexItem> <Link ><Tooltip content={`Export ${row?.filename} content`} ><Icon ><ExportIcon /></Icon></Tooltip> </Link></FlexItem> */}
                                                                        </Flex>
                                                                    </div>


                                                                </Td>

                                                            </Tr>
                                                        </>
                                                    )
                                                })
                                                :
                                                <Tr key='empty-row'>
                                                    <Td colSpan={10} key='empty-data'>
                                                        <Emptystatefornodata />
                                                    </Td>
                                                </Tr>
                                            }

                                        </Tbody>
                                    </Table>
                                    {renderPagination('bottom')}
                                </>
                            }
                        </>
                    </CardBody>
                </Card>
            </PageSection>
        </div>
    )
}

export default CdbLists