// import React from 'react'
// import {
//   Chart,
//   ChartLegend,
//   ChartThemeColor,
//   getInteractiveLegendEvents,
//   getInteractiveLegendItemStyles,
//   ChartDonut,
// } from '@patternfly/react-charts'
// import {
//   getResizeObserver
// } from '@patternfly/react-core';
// import { emptystatefornodata } from '../../util/emptystate';

// export default class SystemAuditingCommands extends React.Component {
//   constructor(props) {
//     super(props);
//     this.containerRef = React.createRef();
//     this.observer = () => { };
//     this.state = {
//       hiddenSeries: new Set(),
//       width: 10,
//       MitreData: []
//     };
//     this.series = []
//     this.handleResize = () => {
//       if (this.containerRef.current && this.containerRef.current.clientWidth) {
//         this.setState({ width: this.containerRef.current.clientWidth });
//       }
//     };
//     // Returns groups of chart names associated with each data series
//     this.getChartNames = () => {
//       const result = [];
//       this.series.map((_, index) => {
//         // Provide names for each series hidden / shown -- use the same name for a donut chart
//         result.push(['donut']);
//       });
//       return result;
//     };

//     // Returns onMouseOver, onMouseOut, and onClick events for the interactive legend
//     this.getEvents = () => getInteractiveLegendEvents({
//       chartNames: this.getChartNames(),
//       isHidden: this.isHidden,
//       legendName: 'CommandChart-ChartLegend',
//       onLegendClick: this.handleLegendClick
//     });

//     // Returns legend data styled per hiddenSeries
//     this.getLegendData = () => {
//       const { hiddenSeries } = this.state;
//       return this.series.map((s, index) => {
//         return {
//           ...s.legendItem, // name property
//           ...getInteractiveLegendItemStyles(hiddenSeries.has(index)) // hidden styles
//         };
//       });
//     };

//     // Hide each data series individually
//     this.handleLegendClick = (props) => {
//       if (!this.state.hiddenSeries.delete(props.index)) {
//         this.state.hiddenSeries.add(props.index);
//       }
//       this.setState({ hiddenSeries: new Set(this.state.hiddenSeries) });
//     };

//     // Returns true if data series is hidden
//     this.isHidden = (index) => {
//       const { hiddenSeries } = this.state; // Skip if already hidden                
//       return hiddenSeries.has(index);
//     };

//   }
//   componentDidMount() {
//     this.observer = getResizeObserver(this.containerRef.current, this.handleResize);
//     this.handleResize();
//   }
//   componentDidUpdate(prevProps) {
//     this.series = [];
//     // if (this.props.mitreseries !== prevProps.mitreseries) {
//     const { CommandData } = this.props;
//     if (CommandData && CommandData.length > 0) {
//       // const Top5Data = CommandData.slice(0, 5);
//       this.series = CommandData.map((bucket) => ({
//         datapoints: { x: bucket.key, y: parseFloat(bucket.percentages) },
//         legendItem: { name: `${bucket.key}(${bucket.doc_count})` }
//       }));
//     }
//     // this.setState({
//     //   MitreData:this.series
//     // })
//     // }
//   }
//   componentWillUnmount() {
//     this.observer();
//   }

//   render() {
//     const { width, hiddenSeries, MitreData } = this.state;
//     this.series = this.series || [];
//     const data = [];
//     this.series.map((commandsSeries, index) => {
//       data.push(!hiddenSeries.has(index) ? commandsSeries.datapoints : { y: null });
//     });

//     const { CommandData, height } = this.props;
//     return (<div style={{ height: height }} ref={this.containerRef}>
//       {CommandData.length === 0 ? (
//        emptystatefornodata()
//       ) :
//         (
//           <Chart
//             ariaDesc="commandschart"
//             ariaTitle="commandschart"
//             events={this.getEvents()}
//             legendComponent={<ChartLegend name={'CommandChart-ChartLegend'} data={this.getLegendData()} />}
//             name="CommandChart"
//             legendAllowWrap={true}
//             legendOrientation="vertical"
//             legendPosition="right"
//             padding={{
//               bottom: 10,
//               left: 0,
//               right: 150, // Adjusted to accommodate legend
//               top: 0
//             }}
//             showAxis={false}
//             themeColor={ChartThemeColor.blue}
//             width={width}
//           >
//             <ChartDonut
//               //   allowTooltip={false}
//               // labelPlacement={"parallel"}
//               // labelPosition={"centroid"}
//               // labelRadius={150}
//               title='Commands'
//               padAngle={1}
//               constrainToVisibleArea
//               data={data}
//               name="donut"
//               labels={({ datum }) => `${datum.x} : ${datum.y}%`}
//             />
//           </Chart>
//         )}
//     </div>
//     );
//   }
// }



import React, { useEffect } from 'react';
import * as echarts from 'echarts';
import 'echarts-wordcloud';
import { generateUniqueID } from '../../util/UniqueIdGenerator';
import { connect, useDispatch } from 'react-redux';
import { UpdateFilterData } from '../../util/FilterHelper';
import { FilterData } from '../../Redux/Action';

 const SystemAuditingCommands = (props) => {
    const id = generateUniqueID()
    const dispatch = useDispatch();
    const handleFilter = (fields, value) => {
        const updatedFields = UpdateFilterData(props.filterformdata, fields, 'is', value);
        dispatch(FilterData(updatedFields));
    }
    useEffect(() => {
        const chartDom = document.getElementById(id + 'command');
        const myChart = echarts.init(chartDom);

        const option = {
            tooltip: {
                trigger: 'item',
                // formatter: function (params) {
                //     return params.data.name + ' : ' + params.value ; // Display the full word and count in the tooltip
                // },
            },
            series: [
                {
                    textStyle: {
                        color: function () {
                            if (props?.theme == 'dark-theme') {
                                return 'rgb(' + [
                                    Math.round(100+Math.random() * 150),
                                    Math.round(100+Math.random() * 150),
                                    Math.round(100+Math.random() * 150)
                                ].join(',') + ')';
                            }
                            else {
                                return 'rgb(' + [
                                    Math.round(Math.random() * 160),
                                    Math.round(Math.random() * 160),
                                    Math.round(Math.random() * 160)
                                ].join(',') + ')';
                            }
                        }
                    },
                    emphasis: {
                        focus: 'self',
            
                        textStyle: {
                            textShadowBlur: 5,
                            textShadowColor: '#333'
                        }
                    },
                    type: 'wordCloud',
                    gridSize: 7,
                    sizeRange: [12, 60],
                    shape: 'rectangle',
                    rotationRange: [-90, 90],
                    rotationStep: 45,
                    left: 'center',
                    top: 'center',
                    width: '100%',
                    height: '100%',
                    right: null,
                    bottom: null,
                    drawOutOfBound: false,
                    shrinkToFit: true,
                    // roam: true,
                    layoutAnimation: true,
                    data: props.CommandData.map((bucket) => ({
                        value: bucket.doc_count,
                        name: bucket.key
                    })
                    ),
                },
            ],
        };

        // Set chart options
        option && myChart.setOption(option);
        myChart.on('click', 'series.wordCloud', function (param) {
            // const Fields = props.filterFields ? props.filterFields : param.data.nestedkey
            handleFilter(props.filterFields , param.data.name)
        });
        const resizeChart = () => {
            if (myChart) { // Check if chart exists before resizing
              myChart.resize();
            }
          };

        window.addEventListener('resize', resizeChart);
        return () => {
            myChart.dispose();
            window.removeEventListener('resize', resizeChart);
        };
    }, [props.CommandData,props.filterFields,props.height,props.theme,props.sidebarState ]);

    return <div className="container">
        <div id={id + 'command'} style={{ width: '100%', height: props.height }} ></div>
    </div>;
}
// SystemAuditingCommands
const mapStateToProps = (state) => ({
    dates: { startDate: state.startdate, endDate: state.enddate },
    filterformdata: state.FilterData,
    theme:state.theme,
    sidebarState:state.sidebarstate
    // mapStateToProps content remains unchanged if you have it
});

export default connect(
    mapStateToProps,
    null,
    null,
    { forwardRef: true }
)(SystemAuditingCommands)