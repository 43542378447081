import React, { useMemo, useRef, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  DataList,
  DataListCheck,
  DataListItem,
  DataListItemRow,
  DataListCell,
  DataListItemCells,
  Label,
  Toolbar,
  ToolbarContent,
  ToolbarToggleGroup,
  ToolbarGroup,
  ToolbarItem,
  ToolbarFilter,
  MenuToggle,
  Modal,
  OverflowMenu,
  OverflowMenuGroup,
  OverflowMenuItem,
  OverflowMenuDropdownItem,
  Dropdown,
  DropdownList,
  Pagination,
  PaginationVariant,
  TextContent,
  Tooltip,
  Icon,
  Select, SelectOption, SearchInput, List, ListItem, Chip, ListVariant, Popover, TextVariants, Text, ChipGroup, Badge,
  ModalVariant,
  Flex,
  FlexItem,
} from '@patternfly/react-core';

import { Table, TableText, Thead, Tr, Th, Tbody, Td, ExpandableRowContent, InnerScrollContainer } from '@patternfly/react-table';
import FilterIcon from '@patternfly/react-icons/dist/esm/icons/filter-icon';
import ArrowCircleUpIcon from '@patternfly/react-icons/dist/esm/icons/arrow-circle-up-icon';
import ArrowCircleDownIcon from '@patternfly/react-icons/dist/esm/icons/arrow-circle-down-icon';
import EllipsisVIcon from '@patternfly/react-icons/dist/esm/icons/ellipsis-v-icon';
import {
  PlusCircleIcon, SortAmountDownIcon, TrashIcon, PlayIcon, CloseIcon, RedoIcon, StopIcon, ClockIcon,
  CheckIcon, HourglassHalfIcon, TimesCircleIcon, PauseIcon, PausedIcon, UserIcon
} from '@patternfly/react-icons';

import dbApi from '../../services/DbApi';
import { useDispatch } from 'react-redux'
import { alertBoxData } from 'src/Redux/Action';
import ConfirmBox from '../ConfirmBox/ConfirmBox'
import { useLocation, useNavigate, Link } from 'react-router-dom/dist';
import { EmptystateforIncident, Emptystatefornodata } from 'src/util/EmptyStateCard';
import { Loader } from 'src/util/Loadingstate';
import { formatTableTimestamp } from 'src/util/Timeutils';
import ModalCommon from '../CommonEventPages/ModalCommon';
import IncidentModal from './IncidentModal';

const IncidentList = (props) => {

  const childIncidentListModalref = useRef(null);

  const dispatch = useDispatch()

  //Start DataTable State
  const defaultColumns = ['Incident ID', 'Title', 'Description', 'Status', 'Severity', 'TLP', 'PAP', 'Organization', 'Create By', 'Create Date and Time', 'Open Datetime', 'Close Datetime', 'Read Status'];
  const defaultRows = [];
  const excludeOrder = ['Description']
  const excludeWrapCol = []
  const orderColumns = [
    {
      label: 'Incident ID',
      col: 'incident_id'
    },
    {
      label: 'Title',
      col: 'title'
    },
    {
      label: 'Status',
      col: 'status'
    },
    {
      label: 'Severity',
      col: 'severity'
    },
    {
      label: 'TLP',
      col: 'tlp_amber'
    },
    {
      label: 'PAP',
      col: 'pap_amber'
    },
    {
      label: 'Read Status',
      col: 'read_status'
    },
    {
      label: 'Organization',
      col: 'organization_name'
    },
    {
      label: 'Create Date and Time',
      col: 'create_datetime'
    },
    {
      label: 'Open Datetime',
      col: 'open_datetime'
    },
    {
      label: 'Close Datetime',
      col: 'closing_datetime'
    },
    {
      label: 'Create By',
      col: 'create_by_user'
    }
  ]


  const columnNames = {
    incident_id: 'Incident ID',
    title: 'Title',
    short_description: 'Description',
    status: 'Status',
    severity: 'Severity',
    tlp_amber: 'TLP',
    pap_amber: 'PAP',
    organization_name: 'Organization',
    create_by_user: 'Create By',
    create_datetime: 'Create Date and Time',
    open_datetime: 'Open Datetime',
    closing_datetime: 'Close Datetime',
    read_status: 'Read Status'
  };



  const [dataFilters, setDataFilters] = useState({
    name: [],
    status: [],
    statusChip: [],
    severity: [],
    severityChip: [],
    tlp: [],
    tlpChip: [],
    pap: [],
    papChip: [],
    readStatus: [],
    readStatusChip: []
  })
  const [toast, setToast] = useState({
    title: '',
    variant: ''
  })
  const [expandedRowId, setExpandedRowId] = useState([]);
  const [isloading, setIsLoading] = useState(false)
  const [selectedRows, setSelectedRows] = useState([]);
  const [inputValue, setInputValue] = useState('')
  const [isCategoryDropdownOpen, setIsCategoryDropdownOpen] = useState(false)
  const [currentCategory, setCurrentCategory] = useState('Name')
  const [isFilterDropdownOpen, setIsFilterDropdownOpen] = useState(false)
  const [isKebabDropdownOpen, setIsKebabDropdownOpen] = useState(false);
  const [filters, setFilters] = useState([]);
  const [filteredColumns, setFilteredColumns] = useState(defaultColumns);
  const [filteredRows, setFilteredRows] = useState(defaultRows);
  const [managedColumns, setManagedColumns] = useState(defaultColumns);
  const [managedRows, setManagedRows] = useState(defaultRows);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [checkedState, setCheckedState] = useState(Array(defaultColumns.length).fill(true));
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [paginatedRows, setPaginatedRows] = useState([]);
  const [totalItem, setTotalItem] = useState(0)
  const [activeSortIndex, setActiveSortIndex] = useState(0);
  const [activeSortDirection, setActiveSortDirection] = useState('desc');


  //End DataTable State
  const [projectId, setProjectId] = useState(1)
  const [confirmModalData, setConfirmBox] = useState({
    show: false,
    msg: '',
    funCall: '',
    funParam: {}
  })

  const statusIcon = {
    'Waiting': <PauseIcon color='#337ab7' />,
    'InProgress': <HourglassHalfIcon color='#f39c12' />,
    'Completed': <CheckIcon color='#3c763d' />
  };

  //Start DataTable Code
  const matchCheckboxNameToColumn = (name) => {
    switch (name) {
      case 'check1':
        return 'Incident ID';
      case 'check2':
        return 'Title';
      case 'check3':
        return 'Description';
      case 'check4':
        return 'Status';
      case 'check5':
        return 'Severity';
      case 'check6':
        return 'TLP';
      case 'check7':
        return 'PAP';
      case 'check8':
        return 'Organization';
      case 'check9':
        return 'Create By';
      case 'check10':
        return 'Create Date and Time';
      case 'check11':
        return 'Open Datetime';
      case 'check12':
        return 'Close Datetime';
      case 'check13':
        return 'Read Status';
    }
  };
  const matchSelectedColumnNameToAttr = (name) => {
    switch (name) {
      case 'Incident ID':
        return 'incident_id';
      case 'Title':
        return 'title';
      case 'Description':
        return 'short_description';
      case 'Status':
        return 'status';
      case 'Severity':
        return 'severity';
      case 'TLP':
        return 'tlp_amber';
      case 'PAP':
        return 'pap_amber';
      case 'Organization':
        return 'organization_name';
      case 'Create By':
        return 'create_by_user';
      case 'Create Date and Time':
        return 'create_datetime';
      case 'Open Datetime':
        return 'open_datetime';
      case 'Close Datetime':
        return 'closing_datetime';
      case 'Read Status':
        return 'read_status';
    }
  };

  const onDelete = (type = '', id = '') => {
    if (type) {
      dataFilters[type].splice(0, 1);
      if (type == "status") {
        dataFilters['statusChip'].splice(0, 1);
      } else if (type == "severity") {
        dataFilters['severityChip'].splice(0, 1);
      } else if (type == "tlp") {
        dataFilters['tlpChip'].splice(0, 1);
      } else if (type == "pap") {
        dataFilters['papChip'].splice(0, 1);
      } else if (type == "readStatus") {
        dataFilters['readStatusChip'].splice(0, 1);
      }
      setDataFilters(prevDataFilters => ({
        ...prevDataFilters,
        dataFilters
      }));
    } else {
      setDataFilters({ name: [], status: [], statusChip: [], severity: [], severityChip: [], tlp: [], tlpChip: [], pap: [], papChip: [], readStatus: [], readStatusChip: [] })
    }
  };

  const onFilterToggle = () => {
    setIsFilterDropdownOpen(!isFilterDropdownOpen)
  };

  const onCategorySelect = event => {
    setCurrentCategory(event.target.innerText)
    setIsCategoryDropdownOpen(false)
    setIsFilterDropdownOpen(false)
  };

  const onCategoryToggle = () => {
    setIsCategoryDropdownOpen(!isCategoryDropdownOpen)
  };

  const onInputChange = newValue => {
    setInputValue(newValue)
  };

  const onNameInput = event => {
    if (event.key && event.key !== 'Enter') {
      return;
    }
    dataFilters.name[0] = inputValue
    setDataFilters(prevDataFilters => ({
      ...prevDataFilters,
      dataFilters
    }));
  };

  const onFilterSelect = (event, selection, type, chipType) => {
    setIsFilterDropdownOpen(false)
    dataFilters[type][0] = selection
    dataFilters[chipType][0] = event.target.innerText
    setDataFilters(prevDataFilters => ({
      ...prevDataFilters,
      dataFilters
    }));
  };


  const setRowSelected = (row, isSelecting = true) => {
    setSelectedRows((prevSelected) => {
      const otherSelectedRows = prevSelected.filter((r) => r !== row.incident_id);
      return isSelecting ? [...otherSelectedRows, row.incident_id] : otherSelectedRows;
    });
  }

  const isRowSelected = (row) => selectedRows.includes(row.incident_id);

  //Call When click on column sort
  const onSort = (event, index, direction) => {
    setActiveSortIndex(index);
    setActiveSortDirection(direction);
  };

  // Pagination logic
  const handleSetPage = (_evt, newPage) => {
    setPage(newPage);
  };

  const handlePerPageSelect = (_evt, newPerPage, newPage) => {
    setPerPage(newPerPage);
    if (newPage !== page) { setPage(newPage); }
  };

  const renderPagination = () => (
    <Pagination
      itemCount={totalItem}
      page={page}
      perPage={perPage}
      onSetPage={handleSetPage}
      onPerPageSelect={handlePerPageSelect}
    />
  );

  // Removes attribute from each node object in Data.jsx
  const removePropFromObject = (object, keys) =>
    keys.reduce((obj, prop) => {
      const { [prop]: _, ...keep } = obj;
      return keep;
    }, object);

  // Filters columns out of table that are not selected in the column management modal
  const filterData = (checked, name) => {
    const selectedColumn = matchSelectedColumnNameToAttr(name);
    const filteredRows = [];
    if (checked) {
      const updatedFilters = filters.filter((item) => item !== selectedColumn);
      // Only show the names of columns that were selected in the modal
      const filteredColumns = defaultColumns.filter(
        (column) => !updatedFilters.includes(matchSelectedColumnNameToAttr(column))
      );
      // Remove the attributes (i.e. "columns") that were not selected
      managedRows.forEach((item) => filteredRows.push(removePropFromObject(item, updatedFilters)));

      setFilters(updatedFilters);
      setFilteredColumns(filteredColumns);
      setFilteredRows(filteredRows);
    } else {
      let updatedFilters = filters;
      if (typeof selectedColumn != 'undefined') {
        updatedFilters.push(selectedColumn);
      }

      // Only show the names of columns that were selected in the modal
      const filteredColumns = managedColumns.filter(
        (column) => !filters.includes(matchSelectedColumnNameToAttr(column))
      );

      // Remove the attributes (i.e. "columns") that were not selected
      managedRows.forEach((item) => filteredRows.push(removePropFromObject(item, updatedFilters)));

      setFilters(updatedFilters);
      setFilteredColumns(filteredColumns);
      setFilteredRows(filteredRows);
    }
  };
  const unfilterAllData = () => {
    setFilters([]);
    setFilteredColumns(defaultColumns);
    setFilteredRows(managedRows);
  };

  const handleChange = (event, checked) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    // Remove any columns from the table that aren't checked
    filterData(checked, matchCheckboxNameToColumn(target.name));
    const checkedIndex = defaultColumns.findIndex((element) => element === matchCheckboxNameToColumn(target.name));

    const updatedCheckedState = [...checkedState];
    updatedCheckedState[checkedIndex] = value;

    setCheckedState(updatedCheckedState);
  };

  const handleModalToggle = (_event) => {
    setIsModalOpen(!isModalOpen);
  };

  const onSave = () => {
    setManagedColumns(filteredColumns);

    setPaginatedRows(filteredRows);
    setIsModalOpen(!isModalOpen);
  };

  const selectAllColumns = () => {
    unfilterAllData();
    setCheckedState(Array(defaultColumns.length).fill(true));
  };

  async function fetchDataTable() {
    try {
      setIsLoading(true);
      let orderCol = 'create_datetime';
      if (activeSortIndex > 0) {
        const orderResult = orderColumns.find((obj) => obj.label == filteredColumns[activeSortIndex])
        orderCol = orderResult.col;
      }

      const data = {
        limitStart: (perPage * page) - perPage,
        limitLength: perPage,
        sortByCol: orderCol,
        sortOrder: activeSortDirection,
        severity: dataFilters.severity.length > 0 ? dataFilters.severity[0] : '',
        search: dataFilters.name.length > 0 ? dataFilters.name[0] : '',
        status: dataFilters.status.length > 0 ? dataFilters.status[0] : '',
        tlpAmber: dataFilters.tlp.length > 0 ? dataFilters.tlp[0] : '',
        papAmber: dataFilters.pap.length > 0 ? dataFilters.pap[0] : '',
        readStatus: dataFilters.readStatus.length > 0 ? dataFilters.readStatus[0] : ''
      };

      const ret = await dbApi.postApi("incident_management/incident_list", data);
      const result = ret.data;
      console.log('incident_list_data', result)
      setIsLoading(false);
      if (result?.data?.length > 0) {
        setTotalItem(result.totalRecord);
        // props.setTaskCount(result.totalRecord);
        setManagedRows(result.data);
        setPaginatedRows(result.data);
      } else {
        setTotalItem(0);
        setManagedRows([]);
        setPaginatedRows([]);
      }

    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }

  }

  React.useEffect(() => {
    fetchDataTable()
  }, [page, perPage, activeSortIndex, activeSortDirection, dataFilters]);
  //End DataTable Code

  const handleModalClose = async (isSuccess) => {
    if (isSuccess) {
      await fetchDataTable();
    }
    setIsLoading(false);
  };

  const finalRemoveIncident = async () => {
    try {
      setIsLoading(true);
      const ret = await dbApi.deleteApi('incident_management/delete_incident', { incidentId: selectedRows });
      if (!ret.error) {
        fetchDataTable();
      }
      dispatch(alertBoxData([{ title: ret.message, variant: !ret.error ? "success" : "danger" }]));
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  const removeIncident = () => {
    setConfirmBox({
      show: true,
      msg: 'Are you sure?',
      funCall: finalRemoveIncident,
      funParam: {},
      position: 'default',
      iconvariant: 'warning',
      submitbtnvariant: 'danger',
    })
  }

  const reloadData = () => {
    setSelectedRows([])
    fetchDataTable()
  }

  const renderModal = () => {
    return (
      <Modal
        title="Manage columns"
        isOpen={isModalOpen}
        variant="small"
        description={
          <TextContent>
            <Button isInline onClick={selectAllColumns} variant="link">
              Select all
            </Button>
          </TextContent>
        }
        onClose={handleModalToggle}
        actions={[
          <Button key="save" variant="primary" onClick={onSave}>
            Save
          </Button>,
          <Button key="cancel" variant="secondary" onClick={handleModalToggle}>
            Cancel
          </Button>
        ]}
      >
        <DataList aria-label="Table column management" id="table-column-management" isCompact>
          {
            defaultColumns.map((obj, index) => {
              return <DataListItem key={index} aria-labelledby={"col-mgmt-item-" + index}>
                <DataListItemRow>
                  <DataListCheck
                    aria-labelledby={"col-mgmt-item-" + index}
                    checked={checkedState[index]}
                    name={"check" + (index + 1)}
                    id={"check" + index}
                    onChange={handleChange}
                  />
                  <DataListItemCells
                    dataListCells={[
                      <DataListCell id={"col-mgmt-item-" + index} key={"col-mgmt-item-" + index}>
                        <label htmlFor={"check" + index}>{obj}</label>
                      </DataListCell>
                    ]}
                  />
                </DataListItemRow>
              </DataListItem>
            })
          }
        </DataList>
      </Modal>
    );
  };


  const tableActionItems = [
    <OverflowMenuDropdownItem key="1" onClick={() => handleModalToggle()}  >Manage Columns</OverflowMenuDropdownItem>,
    <OverflowMenuDropdownItem key="2" onClick={() => removeIncident()} isDisabled={selectedRows.length == 0}  >  Remove</OverflowMenuDropdownItem>,
  ];

  const tableAction =
    <Dropdown onSelect={() => setIsKebabDropdownOpen(!isKebabDropdownOpen)} onOpenChange={isKebabDropdownOpen => setIsKebabDropdownOpen(isKebabDropdownOpen)} toggle={toggleRef => <MenuToggle ref={toggleRef} aria-label="overflow menu" variant="plain" onClick={() => setIsKebabDropdownOpen(!isKebabDropdownOpen)} isExpanded={false}>
      <EllipsisVIcon />
    </MenuToggle>} isOpen={isKebabDropdownOpen}>
      <DropdownList >{tableActionItems}</DropdownList>
    </Dropdown>

  function buildFilterDropdown() {
    const statusMenuItems = [
      <SelectOption key="1" value="Waiting" isSelected={dataFilters.status.includes("Waiting")}>Waiting</SelectOption>,
      <SelectOption key="2" value="InProgress" isSelected={dataFilters.status.includes("InProgress")}>InProgress</SelectOption>,
      <SelectOption key="3" value="Completed" isSelected={dataFilters.status.includes("Completed")}>Completed</SelectOption>
    ];

    const severityMenuItems = [
      <SelectOption key="1" value="L" isSelected={dataFilters.status.includes("L")}>Low</SelectOption>,
      <SelectOption key="2" value="M" isSelected={dataFilters.status.includes("M")}>Medium</SelectOption>,
      <SelectOption key="3" value="H" isSelected={dataFilters.status.includes("H")}>High</SelectOption>,
      <SelectOption key="4" value="C" isSelected={dataFilters.status.includes("C")}>Critical</SelectOption>
    ];

    const tlpMenuItems = [
      <SelectOption key="1" value="WHITE" isSelected={dataFilters.tlp.includes("WHITE")}>WHITE</SelectOption>,
      <SelectOption key="2" value="GREEN" isSelected={dataFilters.tlp.includes("GREEN")}>GREEN</SelectOption>,
      <SelectOption key="3" value="AMBER" isSelected={dataFilters.tlp.includes("AMBER")}>AMBER</SelectOption>,
      <SelectOption key="4" value="RED" isSelected={dataFilters.tlp.includes("RED")}>RED</SelectOption>
    ];

    const papMenuItems = [
      <SelectOption key="1" value="WHITE" isSelected={dataFilters.pap.includes("WHITE")}>WHITE</SelectOption>,
      <SelectOption key="2" value="GREEN" isSelected={dataFilters.pap.includes("GREEN")}>GREEN</SelectOption>,
      <SelectOption key="3" value="AMBER" isSelected={dataFilters.pap.includes("AMBER")}>AMBER</SelectOption>,
      <SelectOption key="4" value="RED" isSelected={dataFilters.pap.includes("RED")}>RED</SelectOption>
    ];

    const readStatusMenuItems = [
      <SelectOption key="1" value="1" isSelected={dataFilters.readStatus.includes("1")}>Read</SelectOption>,
      <SelectOption key="2" value="0" isSelected={dataFilters.readStatus.includes("0")}>Unread</SelectOption>
    ];

    return <React.Fragment>
      <ToolbarFilter chips={dataFilters.name} deleteChip={onDelete} categoryName="name" showToolbarItem={currentCategory === 'Name'}>
        <SearchInput aria-label="name filter" placeholder="Filter by name..." onChange={(_event, value) => onInputChange(value)} value={inputValue} onClear={() => {
          onInputChange('');
        }} onSearch={onNameInput} />
      </ToolbarFilter>

      <ToolbarFilter chips={dataFilters.statusChip} deleteChip={onDelete} categoryName="status" showToolbarItem={currentCategory === 'Status'}>
        <Select aria-label="Status" isOpen={isFilterDropdownOpen} onSelect={(event, selection) => onFilterSelect(event, selection, 'status', 'statusChip')} selected={dataFilters.status} toggle={toggleRef => <MenuToggle ref={toggleRef} onClick={onFilterToggle} isExpanded={isFilterDropdownOpen} style={{
          width: '100%',
          verticalAlign: 'text-bottom'
        }}>
          Filter by status
        </MenuToggle>}>
          {statusMenuItems}
        </Select>
      </ToolbarFilter>

      <ToolbarFilter chips={dataFilters.severityChip} deleteChip={onDelete} categoryName="severity" showToolbarItem={currentCategory === 'Severity'}>
        <Select aria-label="Severity" isOpen={isFilterDropdownOpen} onSelect={(event, selection) => onFilterSelect(event, selection, 'severity', 'severityChip')} selected={dataFilters.status} toggle={toggleRef => <MenuToggle ref={toggleRef} onClick={onFilterToggle} isExpanded={isFilterDropdownOpen} style={{
          width: '100%',
          verticalAlign: 'text-bottom'
        }}>
          Filter by severity
        </MenuToggle>}>
          {severityMenuItems}
        </Select>
      </ToolbarFilter>

      <ToolbarFilter chips={dataFilters.tlpChip} deleteChip={onDelete} categoryName="tlp" showToolbarItem={currentCategory === 'TLP'}>
        <Select aria-label="Tlp" isOpen={isFilterDropdownOpen} onSelect={(event, selection) => onFilterSelect(event, selection, 'tlp', 'tlpChip')} selected={dataFilters.tlp} toggle={toggleRef => <MenuToggle ref={toggleRef} onClick={onFilterToggle} isExpanded={isFilterDropdownOpen} style={{
          width: '100%',
          verticalAlign: 'text-bottom'
        }}>
          Filter by TLP
        </MenuToggle>}>
          {tlpMenuItems}
        </Select>
      </ToolbarFilter>

      <ToolbarFilter chips={dataFilters.papChip} deleteChip={onDelete} categoryName="pap" showToolbarItem={currentCategory === 'PAP'}>
        <Select aria-label="Pap" isOpen={isFilterDropdownOpen} onSelect={(event, selection) => onFilterSelect(event, selection, 'pap', 'papChip')} selected={dataFilters.pap} toggle={toggleRef => <MenuToggle ref={toggleRef} onClick={onFilterToggle} isExpanded={isFilterDropdownOpen} style={{
          width: '100%',
          verticalAlign: 'text-bottom'
        }}>
          Filter by PAP
        </MenuToggle>}>
          {papMenuItems}
        </Select>
      </ToolbarFilter>

      <ToolbarFilter chips={dataFilters.readStatusChip} deleteChip={onDelete} categoryName="readStatus" showToolbarItem={currentCategory === 'Read Status'}>
        <Select aria-label="ReadStatus" isOpen={isFilterDropdownOpen} onSelect={(event, selection) => onFilterSelect(event, selection, 'readStatus', 'readStatusChip')} selected={dataFilters.readStatus} toggle={toggleRef => <MenuToggle ref={toggleRef} onClick={onFilterToggle} isExpanded={isFilterDropdownOpen} style={{
          width: '100%',
          verticalAlign: 'text-bottom'
        }}>
          Filter by Read Status
        </MenuToggle>}>
          {readStatusMenuItems}
        </Select>
      </ToolbarFilter>

    </React.Fragment>;
  }

  function buildCategoryDropdown() {
    const categoryMenuItems = [
      <SelectOption key="1" value="Name">Name</SelectOption>,
      <SelectOption key="2" value="Status">Status</SelectOption>,
      <SelectOption key="3" value="Severity">Severity</SelectOption>,
      <SelectOption key="4" value="TLP">TLP</SelectOption>,
      <SelectOption key="5" value="PAP">PAP</SelectOption>,
      <SelectOption key="6" value="Read Status">Read Status</SelectOption>
    ];

    return <ToolbarItem>
      <Select onSelect={onCategorySelect} selected={currentCategory} toggle={toggleRef => <MenuToggle ref={toggleRef} onClick={onCategoryToggle} isExpanded={isCategoryDropdownOpen} icon={<FilterIcon />} style={{
        width: '100%',
        verticalAlign: 'text-bottom'
      }}>
        {currentCategory}
      </MenuToggle>} isOpen={isCategoryDropdownOpen}>
        {categoryMenuItems}
      </Select>
    </ToolbarItem>;
  }

  function renderToolbar() {
    return <Toolbar id="toolbar-with-chip-groups" clearAllFilters={onDelete} collapseListedFiltersBreakpoint="xl">
      <ToolbarContent>
        <ToolbarToggleGroup toggleIcon={<FilterIcon />} breakpoint="xl">
          <ToolbarGroup variant="filter-group" style={{
            lineHeight: '22px',
            alignItems: 'center'
          }}>
            {buildCategoryDropdown()}
            {buildFilterDropdown()}
          </ToolbarGroup>
        </ToolbarToggleGroup>
        <ToolbarItem variant="overflow-menu">
          <OverflowMenu breakpoint="md">
            <OverflowMenuGroup groupType="button" isPersistent>
              <OverflowMenuItem isPersistent>
                {tableAction}
              </OverflowMenuItem>
            </OverflowMenuGroup>
          </OverflowMenu>
        </ToolbarItem>
        <ToolbarItem variant="pagination">{renderPagination()}</ToolbarItem>
      </ToolbarContent>
    </Toolbar>;
  }


  const getBadgeStyle = (severity) => {
    let badgeContent = "";
    let badgeStyle = {};

    switch (severity) {
      case 'H':
        badgeContent = "H";
        badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#C9190B " };
        break;
      case 'C':
        badgeContent = "C";
        badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#A30000" };
        break;
      case 'M':
        badgeContent = "M";
        badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#F0AB00" };
        break;
      default:
        badgeContent = "L";
        badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#0066CC" };
        break;
    }
    return { badgeContent, badgeStyle };
  };

  const getTlpPapBadgeStyle = (value) => {
    let badgeStyle = {};
    let badgeContent = value.toUpperCase(); // Ensure the badge content is in uppercase

    switch (value.toUpperCase()) {
      case 'WHITE':
        badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#0066CC" }; // Blue
        break;
      case 'GREEN':
        badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#2E8B57" }; // Green
        break;
      case 'RED':
        badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#C9190B" }; // Red
        break;
      case 'AMBER':
        badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#F0AB00" }; // Yellow
        break;
      default:
        badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#d2d2d2" }; // Default grey for unexpected values
        break;
    }
    return { badgeContent, badgeStyle };
  };

  const tableHeader = useMemo(() => {
    return <Thead>
      <Tr>
        <Th key={'chk'} screenReaderText='key' />
        <Th key={'status'} />

        {managedColumns.map((column, columnIndex) => {
          const sortParams = {
            sort: {
              sortBy: {
                index: activeSortIndex,
                direction: activeSortDirection
              },
              onSort,
              columnIndex
            }
          };
          return !excludeOrder.includes(column) ? <Th modifier={!excludeWrapCol.includes(column) && 'wrap'} key={columnIndex} {...sortParams} >{column}</Th> : <Th modifier={!excludeWrapCol.includes(column) && 'wrap'} key={columnIndex} >{column}</Th>;
        })}
      </Tr>
    </Thead>

  }, [activeSortIndex, activeSortDirection, managedColumns])

  const tableBody = useMemo(() => {
    return <Tbody>
      {paginatedRows && paginatedRows.length > 0 ? (
        paginatedRows.map((row, rowIndex) => {
          return (
            <Tr key={"tr_" + rowIndex}>
              {
                typeof row?.incident_id != "undefined" ? <Td
                  key={"chk-" + rowIndex}
                  select={{
                    rowIndex,
                    onSelect: (_event, isSelecting) => setRowSelected(row, isSelecting),
                    isSelected: isRowSelected(row),
                    isDisabled: row?.process_status == 1,
                  }}
                /> : <Td />
              }
              {
                <Td key={rowIndex + "-status"} >
                  <Tooltip content={<div>Total Tasks: {row.total_task}</div>}>
                    <Badge>T : {row.total_task}</Badge>
                  </Tooltip>
                  <Tooltip content={<div>Total Observables: {row.total_observable}</div>}>
                    <Badge>O : {row.total_observable}</Badge>
                  </Tooltip>
                  <Tooltip content={<div>Total Alerts: {row.total_alert}</div>}>
                    <Badge>A : {row.total_alert}</Badge>
                  </Tooltip>
                </Td>
              }

              {
                typeof row?.incident_id != "undefined" && <Td key={"incident_id_" + rowIndex}>
                  <Link to={`/incident-detail/${row.incident_id}`}>{row.incident_id}</Link>
                </Td>
              }
              {typeof row?.title != "undefined" && <Td key={"title_" + rowIndex}>{row.title}</Td>}
              {typeof row?.short_description != "undefined" && <Td key={"short_" + rowIndex}>{row.short_description}</Td>}
              {typeof row?.status != "undefined" && <Td key={"status_" + rowIndex}>{row.status}</Td>}
              {
                typeof row?.severity != "undefined" && <Td key={"severity_" + rowIndex}>
                  {
                    ((severity) => {
                      const { badgeContent: severityBadgeContent, badgeStyle: severityBadgeStyle } = getBadgeStyle(severity);
                      return <Badge style={severityBadgeStyle}>{severityBadgeContent}</Badge>
                    })(row.severity)
                  }
                </Td>
              }
              {
                typeof row?.tlp_amber != "undefined" && <Td key={"tlp_" + rowIndex}>
                  {
                    ((tlp_amber) => {
                      const { badgeContent: tlpBadgeContent, badgeStyle: tlpBadgeStyle } = getTlpPapBadgeStyle(tlp_amber);
                      return <Badge style={tlpBadgeStyle}>{tlpBadgeContent}</Badge>
                    })(row.tlp_amber)
                  }
                </Td>
              }
              {
                typeof row?.pap_amber != "undefined" && <Td key={"pap_" + rowIndex}>
                  {
                    ((pap_amber) => {
                      const { badgeContent: papBadgeContent, badgeStyle: papBadgeStyle } = getTlpPapBadgeStyle(pap_amber);
                      return <Badge style={papBadgeStyle}>{papBadgeContent}</Badge>
                    })(row.pap_amber)
                  }
                </Td>
              }

              {typeof row?.organization_name != "undefined" && <Td key={"org_" + rowIndex}>{row.organization_name}</Td>}
              {typeof row?.create_by_user != "undefined" && <Td key={"create_" + rowIndex}>{row.create_by_user}</Td>}
              {typeof row?.create_datetime != "undefined" && <Td key={"create_date_" + rowIndex}>
                {
                  ((rowCreateDatetime) => {
                    const { formattedTimeStamp: createDatetime } = formatTableTimestamp(rowCreateDatetime);
                    return createDatetime;
                  })(row.create_datetime)
                }
              </Td>
              }

              {typeof row?.open_datetime != "undefined" && <Td key={"open_date_" + rowIndex}>
                {
                  ((openDatetime) => {
                    if (openDatetime != null) {
                      var { formattedTimeStamp: openDatetime } = formatTableTimestamp(openDatetime);
                      return openDatetime;
                    }
                  })(row.open_datetime)
                }
              </Td>
              }

              {typeof row?.closing_datetime != "undefined" && <Td key={"close_date_" + rowIndex}>
                {
                  ((closeDatetime) => {
                    if (closeDatetime != null) {
                      var { formattedTimeStamp: closeDatetime } = formatTableTimestamp(row.closing_datetime);
                      return closeDatetime;
                    }
                  })(row.closing_datetime)
                }
              </Td>
              }
              {
                typeof row?.read_status != "undefined" && <Td key={"read_status_" + rowIndex}>
                  <Badge
                    isRead={row.read_status === 1}
                    style={{ backgroundColor: row.read_status === 1 ? '#38812F' : '#C9190B', color: 'white' }}
                  >
                    {row.read_status === 1 ? 'Read' : 'Unread'}
                  </Badge>
                </Td>
              }
            </Tr>
         
          );
          
        })
      ) : (
        <Tr><Td colSpan={14}><EmptystateforIncident /></Td></Tr>
      )}
    </Tbody>
  }, [paginatedRows, selectedRows])

  return (
    <div className="pf-v5-u-p-sm">
      <React.Fragment>
        <Card>
          <CardBody>
            <Flex className='pf-v5-u-mt-sm' justifyContent={{ default: 'justifyContentSpaceBetween' }}>
              <Flex spaceItems={{ default: 'spaceItemsXs' }}>
                <FlexItem>
                  <Button variant="primary" icon={<PlusCircleIcon />} onClick={() => childIncidentListModalref.current.setModalBox(true)}> Add Incident</Button>
                </FlexItem>
              </Flex>
            </Flex>
          </CardBody>
        </Card>
        {renderToolbar()}
        {isloading ? <Loader /> :
          <InnerScrollContainer key="scroller">
            <Table variant="compact" aria-label="Column Management Table">
              {tableHeader}
              {tableBody}
            </Table>
            <div className='pf-v5-u-mt-md pf-v5-u-mb-md '> {renderPagination()}</div>
          </InnerScrollContainer>
        }
        {renderModal()}

        <ConfirmBox confirmModal={confirmModalData} />
        <ModalCommon
          title="Create a New Incident"
          onclose={() => childIncidentListModalref.current.setModalBox(false)}
          variant={ModalVariant.large}
          ref={childIncidentListModalref}
        >
          <IncidentModal onclose={() => childIncidentListModalref.current.setModalBox(false)} onModalClose={handleModalClose} />
        </ModalCommon>
      </React.Fragment>
    </div>
  );
};
export default IncidentList;

