import React, { useEffect, useRef, useState } from 'react'
import OnBoardingForm from './OnBoardingForm'
import { BackgroundImage, Button, Card, CardBody, CardFooter, EmptyState, EmptyStateBody, EmptyStateHeader, EmptyStateIcon, Flex, ModalVariant, PageSection, PageSectionVariants } from '@patternfly/react-core'
import bgImage from '../../Assets/images/black.jpeg'
import { useNavigate, useParams } from 'react-router-dom'
import { ArrowAltCircleLeftIcon, CheckCircleIcon, ExclamationCircleIcon } from '@patternfly/react-icons'
import { alertBoxData } from 'src/Redux/Action'
import { useDispatch } from 'react-redux'
import dbApi from '../../services/DbApi';
import { Loader } from 'src/util/Loadingstate'
import global_danger_color_100 from '@patternfly/react-tokens/dist/esm/global_danger_color_100';
import global_success_color_100 from '@patternfly/react-tokens/dist/esm/global_success_color_100';


function OnboardLandingPage() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const token = localStorage.getItem('token')
    const [isloading, setisloading] = useState(false)
    const [showform, setShowForm] = useState(false)
    const [modalmsg, setModalmsg] = useState('')
    const [msgtype, setMsgtype] = useState('')

    const uniqueId = useParams()
    const verifyLink = async () => {
        try {
            setisloading(true)
            let res = await dbApi.postApi("Opensearch/Verify_OnBoard_Link", { unique_id: uniqueId.uniqueId })
            console.log(res)
            if (res.Verify_OnBoard_Link.error) {
                setShowForm(false)
                setModalmsg(res.Verify_OnBoard_Link.message)
                setMsgtype('error')
            } else {
                dispatch(alertBoxData([{ title: res.Verify_OnBoard_Link.message, variant: "success" }]));
                setShowForm(true)
            }
            setisloading(false)
        } catch (error) {
            console.log(error)
            setisloading(false)
        }
    }
    useEffect(() => {
        verifyLink()
    }, [])

    const updateStatusofLink = async () => {
        try {
            let res = await dbApi.postApi("Opensearch/Update_OnBoard_Link", { unique_id: uniqueId.uniqueId })
            console.log(res)
        } catch (error) {
            console.log(error)
        }
    }
    const showSuccessonboardModal = (msg) => {
        setShowForm(false)
        setModalmsg(msg)
        setMsgtype('success')
    }
    return (
        <div className="pf-v5-u-p-sm" style={{ alignContent: 'center', marginLeft: '25%', marginRight: '25%' }} >
            <BackgroundImage src={bgImage} alt='bg' />
            {isloading ? <Loader /> :
                showform ?
                    <Card isFlat>
                        <CardBody>
                            <OnBoardingForm openModal={false} actiontype={'Add'} UpdateStatus={() => updateStatusofLink()} showSuccessModal={showSuccessonboardModal} />
                        </CardBody>
                        <CardFooter>
                            <Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
                                {token == null || token == 'undefined' || token == '' ?
                                    <Button isInline variant='link' icon={<ArrowAltCircleLeftIcon />} onClick={() => { navigate('/') }}> Back</Button>
                                    :
                                    <Button isInline variant='link' icon={<ArrowAltCircleLeftIcon />} onClick={() => { navigate('/home') }}> Back</Button>
                                }
                            </Flex>
                        </CardFooter>
                    </Card>
                    :
                    <Card style={{ margin: '20%' }}>
                        <EmptyState>
                            <EmptyStateHeader headingLevel="h2"
                                titleText={modalmsg}
                                icon={<EmptyStateIcon icon={msgtype == 'error' ? ExclamationCircleIcon : CheckCircleIcon} color={msgtype == 'error' ? global_danger_color_100.var : global_success_color_100.var} />} />
                        </EmptyState>
                    </Card>
            }
        </div>
    )
}

export default OnboardLandingPage
