
//2nd which is perfectly run and usable
import React, { useCallback, useEffect, useState } from "react";
import {
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    ExpandableRowContent,
    Table,
    InnerScrollContainer,
} from "@patternfly/react-table";
import {
    Badge,
    DescriptionList,
    DescriptionListDescription,
    DescriptionListGroup,
    DescriptionListTerm,
    Tab,
    Tabs,
    TabTitleText,
    TabContentBody,
    Tooltip,
    ListItem,
    List,
    Icon,
    ListVariant,
    BackToTop,
    Truncate,
} from "@patternfly/react-core";
import dbApi from '../../services/DbApi'
import { SearchIcon, SecurityIcon } from "@patternfly/react-icons";
import { formatTableTimestamp } from "../../util/Timeutils";
import { Emptystatefornodata } from '../../util/EmptyStateCard';
import { Link } from "react-router-dom";
import SearchInputItem from "../CommonEventPages/SearchInputItem";
import { connect, useDispatch } from "react-redux";
import { UpdateFilterData } from "../../util/FilterHelper";
import { FilterData } from "../../Redux/Action";

const { forwardRef, useRef, useImperativeHandle } = React;

const RecentAlertsRealTime = forwardRef((props, ref) => {
    const [SecurityAlertsLog, setSecurityAlertsLog] = React.useState([])

    useImperativeHandle(ref, () => ({
        setSecurityAlertsTable(data = [],) {
            setSecurityAlertsLog(data)
        }
    }));


    const defaultColumns = [
        "Time",
        "level",
        "Rule Description & key objects",
        "Rule Group(s)"
    ];
    // const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const [columns, setColumns] = useState(defaultColumns);
    const [rows, setRows] = useState([]);
    const [activeTabKey, setActiveTabKey] = useState(0);
    // const initialExpandedRowNames = [];
    // const [areAllExpanded, setAreAllExpanded] = useState(false);
    // const [collapseAllAriaLabel, setCollapseAllAriaLabel] = useState("Expand all");
    const [expandedRowNames, setExpandedRowNames] = useState([]);
    const [expandedRowData, setExpandedRowData] = useState({});
    const [selectedRowName, setSelectedRowName] = useState('');

    const handleTabClick = (event, tabIndex) => {
        setActiveTabKey(tabIndex);
    };


    //useEffect for main data 
    useEffect(() => {
        const newdefaultRows = SecurityAlertsLog.map((log) => {
            const { formattedTimeStamp } = formatTableTimestamp(log._source.timestamp);
            return {
                id: log._id,
                timestamp: formattedTimeStamp,
                ruleLevel: log._source.rule.level,
                agentId: log._source.agent.id,
                agentName: log._source.agent.name,
                techniques: log._source.rule?.mitre?.id,
                tactics: log._source.rule?.mitre?.tactic,
                ruleDescription: log._source.rule.description,
                rulegroup: log._source.rule?.groups,
                ruleId: log._source.rule.id,
                dstuser: log._source?.data?.dstuser,
                targetuser: log._source?.data?.win?.eventdata?.targetUserName,
                ipaddress: log._source?.data?.win?.eventdata?.ipAddress,
                processname: log._source?.data?.win?.eventdata?.logonProcessName,
                url: log._source?.data?.url,
                srcip: log._source?.data?.srcip,
                param4: log._source?.data?.win?.eventdata?.param4,
                param2: log._source?.data?.win?.eventdata?.param2,
                param3: log._source?.data?.win?.eventdata?.param3,
                cvss3: log._source?.data?.vulnerability?.cvss?.cvss3?.base_score,
                vectorattack: log._source.data?.vulnerability?.cvss?.cvss3?.vector?.attack_vector,
                query: log._source.data?.query,
                details: log,
            };
        });

        // setDefaultRows(newdefaultRows)
        // setItemcount(newdefaultRows.length)
        // const startIndex = (page - 1) * perPage;
        // const endIndex = startIndex + perPage;
        // // const filteredRows = newdefaultRows.filter(row => onFilter(row, searchValue));
        // const slicedRows = newdefaultRows.slice(startIndex, endIndex);
        // console.log("after initial slicing", "", startIndex, endIndex, slicedRows)
        setRows(newdefaultRows);

        // setDefaultRows(slicedRows)
        // console.log("rows with all data", newdefaultRows)

        // const allExpanded = expandedRowNames.length === rows.length;
        // setAreAllExpanded(allExpanded);
        // setCollapseAllAriaLabel(allExpanded ? "Collapse all" : "Expand all");
        // setIsLoading(true);
    }, [SecurityAlertsLog]);

    const setRowExpanded = (row, isExpanding) => {
        const otherExpandedRowNames = expandedRowNames.filter((r) => r !== row.id);
        setExpandedRowNames(
            // isExpanding ? [...otherExpandedRowNames, row.id] : otherExpandedRowNames
            isExpanding ? [row.id] : []
        );
        if (!expandedRowData[row.id]) {
            const keyifiedData = keyify(row?.details);
            // setExpandedRowData((prevData) => ({
            //     ...prevData,
            //     [row.id]: keyifiedData,
            // }));
            setExpandedRowData({ [row.id]: keyifiedData, });
        }
        // setRowExpanded(row, !isRowExpanded(row));
        // const updatedExpandedRowNames = isRowExpanded(row)
        //     ? expandedRowNames.filter((r) => r !== row.id)
        //     : [...expandedRowNames, row.id];
        // setExpandedRowNames(updatedExpandedRowNames);
        const updatedExpandedRowNames = isRowExpanded(row)
            ? otherExpandedRowNames : [row.id];
        setExpandedRowNames(updatedExpandedRowNames);

    };
    const isRowExpanded = (row) => {
        return expandedRowNames.includes(row.id);
    };

    // const onCollapseAll = (_event, _rowIndex, isOpen) => {
    //     setExpandedRowNames(isOpen ? [...rows.map((row) => row.id)] : []);
    // };
    const handleFilter = (fields, value) => {
        const updatedFields = UpdateFilterData(props.filterformdata, fields, 'is', value);
        dispatch(FilterData(updatedFields));
    }

    const renderSeverityColumn = (severity, ruleLevel) => {
        let badgeStyle = {};
        let tooltipContent = " ";
        if (ruleLevel >= 12) {
            tooltipContent = "Severity: Critical";
            badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#A30000" };
            return (
                <Tooltip content={tooltipContent}><Badge style={badgeStyle} onClick={() => { handleFilter('rule.level', ruleLevel) }}><a className='pf-v5-u-color-light-100' href="#/Security-Events" ><Icon isInline iconSize="sm"><SecurityIcon /></Icon> C</a></Badge></Tooltip>
            )
        }
        else if (ruleLevel >= 9) {
            tooltipContent = "Severity: High";
            badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#C9190B" };
            return (
                <Tooltip content={tooltipContent}><Badge style={badgeStyle} onClick={() => { handleFilter('rule.level', ruleLevel) }}><a className='pf-v5-u-color-light-100' href="#/Security-Events"><Icon isInline iconSize="sm"><SecurityIcon /></Icon> H</a></Badge></Tooltip>
            )
        }
        else {
            return null;
        }
    }

    const hyperlinkConditions = {
        // 'agent.id': (value) => value !== '000',
        'rule.info': (value) => value !== null && value !== undefined && value !== '',
        // 'agent.name': (value, obj) => obj['agent.id'] && obj['agent.id'] !== '000',
    };

    const keyify = (obj, prefix = "") => {
        const skipKeys = ["_score", "_version", "_index", "sort", "@timestamp"];
        const result = [];
        Object.keys(obj).forEach((el) => {
            const fullkey = prefix + el;
            if (skipKeys.includes(el)) {
                return;
            }
            else if (Array.isArray(obj[el])) {
                const value = obj[el].join(", ");
                result.push({ key: fullkey, value });
            } else if (typeof obj[el] === "object" && obj[el] !== null) {
                if (el === "_source") {
                    result.push(...keyify(obj[el], prefix));
                }
                else {
                    result.push(...keyify(obj[el], fullkey + "."));
                }
            }
            else {
                if (hyperlinkConditions[fullkey] && hyperlinkConditions[fullkey](obj[el])) {
                    result.push({ key: fullkey, value: <a href={obj[el]}>{obj[el]}</a> });
                }
                else if (typeof obj[el] === 'boolean') {
                    result.push({ key: fullkey, value: obj[el] ? 'true' : 'false' });
                }
                else {
                    // Check if the current field needs to be highlighted
                    const fieldData = props.filterformdata.find(item => item.fields === fullkey);
                    if (fieldData && obj[el] === fieldData.value) {
                        result.push({ key: fullkey, value: <span style={{ backgroundColor: 'yellow' }}>{obj[el]}</span> });
                    }
                    else {
                        result.push({ key: fullkey, value: obj[el] });
                    }
                }

                // else { result.push({ key: fullkey, value: obj[el] }); }
            }
        });
        const sortedResult = result.sort((a, b) => {
            if (a.key < b.key) return -1;
            if (a.key > b.key) return 1;
            return 0
        });

        return sortedResult;
    };
    const preStyle = {
        wordBreak: "break-all",
        whiteSpace: "pre-wrap",
    };
    const getRowStyle = (rowData) => {
        // console.log('Condition met for row:',rowData);
        if (rowData.details?._source.agent.id == '023') {
            // console.log('Condition met for row:', rowData);
            return { color: '#C9190B' };
        }
        return;
    };

    const renderBadgeWithTooltip = (data, tooltipContent, style, field) => {

        if (!data || data === "::1" || data === "127.0.0.1" || data.includes('$') || data == undefined && data == null && data == '') {
            return null;
        }
        return (
            <ListItem key={tooltipContent}>
                <Tooltip content={tooltipContent}>
                    <Badge style={style} onClick={() => { handleFilter(field, data) }}><a className='pf-v5-u-color-light-100' href="#/Security-Events">{data}</a></Badge>
                </Tooltip>
            </ListItem>
        );
    };
    const renderRuleLevelcolumn = (ruleLevel) => {
        let tooltipContent = "";
        let badgeStyle = {};
        if (ruleLevel >= 6) {
            tooltipContent = "Medium";
            badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#F0AB00" };
            if (ruleLevel >= 12) {
                tooltipContent = "Critical";
                badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#A30000 " };
            }
            return (
                <Tooltip content={tooltipContent}>
                    <Badge style={badgeStyle} >{ruleLevel}</Badge>
                </Tooltip>
            );
        }
        else {
            return ruleLevel;
        }
    };
    const descbadgestyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#F0AB00" };
    //   const paramstyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#C9190B" }
    // console.log("12345",SecurityAlertsLog)
    return (
        <React.Fragment>
            <div style={{ height: '1200px' }}>
                <InnerScrollContainer>
                    <Table variant="compact"
                        aria-label="securityalertsLogTable"
                        id="securityalerts-log-table"
                        isStickyHeader
                        gridBreakPoint="grid-md"
                    >
                        <Thead>
                            <Tr>
                                <Th key={'expand'} aria-label="expand-event" />
                                {columns.map((column, index) => (
                                    <Th key={index} >{column}</Th>
                                ))}
                            </Tr>
                        </Thead>
                        {rows.length > 0 && rows.map((row, rowIndex) => (

                            <Tbody isExpanded={isRowExpanded(row)} key={rowIndex}>
                                <Tr
                                    onRowClick={() => setSelectedRowName(rowIndex)}
                                    isSelectable
                                    isClickable
                                    isRowSelected={selectedRowName === rowIndex}
                                    className={row?.ruleLevel >= 14 ? "pf-v5-u-background-color-danger" : ""}
                                >
                                    <Td
                                        expand={
                                            row.details
                                                ? {
                                                    rowIndex: rowIndex,
                                                    isExpanded: isRowExpanded(row),
                                                    onToggle: () =>
                                                        setRowExpanded(row, !isRowExpanded(row)),
                                                }
                                                : undefined
                                        }
                                    >
                                        <ExpandableRowContent key={rowIndex}>
                                            <pre> {row.details ? (
                                                <pre>{JSON.stringify(row.details, null, 2)}</pre>
                                            ) : (
                                                <p>No details available.</p>
                                            )}</pre>
                                        </ExpandableRowContent>
                                    </Td>
                                    <Td dataLabel={'Time'}> {row?.timestamp} </Td>
                                    <Td dataLabel={'level'}><a onClick={() => { handleFilter('rule.level', row?.ruleLevel) }} href="#/Security-Events">{renderRuleLevelcolumn(row?.ruleLevel)}</a></Td>
                                    {/* <Td width={10} dataLabel={'Agent Name'}>
                                <Link to={"/Individual-Agent-Dashboard/" + row?.agentName + '/' + row?.agentId}>{row?.agentId}: {row?.agentName}</Link></Td> */}
                                    <Td dataLabel={'Rule Description & key objects'} style={getRowStyle(row?.ruleDescription)}>
                                        {/* {row?.ruleDescription} */}
                                        {row?.ruleDescription && (
                                            <>
                                                {row?.ruleLevel !== undefined && row?.ruleLevel !== null ? renderSeverityColumn(row?.severity, row?.ruleLevel) : " "}
                                                <a className='pf-v5-u-color-100' href="#/Security-Events" onClick={() => handleFilter('rule.description', row?.ruleDescription)}>
                                                    <Truncate content={row?.ruleDescription} />  </a>
                                                <List isPlain variant={ListVariant.inline}>
                                                    <ListItem>
                                                        <Tooltip content='Agent name'>
                                                            <Link to={"/Individual-Agent-Dashboard/" + row?.agentName + '/' + row?.agentId} className='pf-v5-u-color-light-100'><Badge> {row?.agentId}: {row?.agentName}</Badge></Link>
                                                        </Tooltip>
                                                    </ListItem>
                                                    {renderBadgeWithTooltip(row?.targetuser, 'target user name', descbadgestyle, 'data.win.eventdata.targetUserName')}
                                                    {renderBadgeWithTooltip(row?.ipaddress, 'IP Address', descbadgestyle, 'data.win.eventdata.ipAddress')}
                                                    {renderBadgeWithTooltip(row?.processname, 'Process name', descbadgestyle, 'data.win.eventdata.logonProcessName')}
                                                    {renderBadgeWithTooltip(row?.url, 'URL', descbadgestyle, 'data.url')}
                                                    {renderBadgeWithTooltip(row?.srcip, 'Source IP', descbadgestyle, 'data.srcip')}
                                                    {renderBadgeWithTooltip(row?.dstuser, 'dst user', descbadgestyle, 'data.dstuser')}
                                                    {renderBadgeWithTooltip(row?.param4, 'Parameters', descbadgestyle, 'data.win.eventdata.param4')}
                                                    {renderBadgeWithTooltip(row?.query, 'Query', descbadgestyle, 'data.query')}
                                                </List>
                                                {
                                                    (row?.cvss3 > 7 && row?.vectorattack) ?
                                                        <Badge style={{ "--pf-v5-c-badge--m-unread--BackgroundColor": "#C9190B" }}>exploitable : {row?.vectorattack}</Badge>
                                                        :
                                                        " "
                                                }
                                            </>
                                        )}

                                    </Td>
                                    <Td dataLabel={'Rule Group(s)'} >
                                        {row?.rulegroup !== undefined && row?.rulegroup !== null ?
                                            (<List>
                                                {row?.rulegroup.slice(1)?.map((item, index) =>
                                                (<Badge key={index}><ListItem key={"li" + index}><a className='pf-v5-u-color-light-100' onClick={() => { handleFilter('rule.groups', item) }} href="#/Security-Events">{item}</a></ListItem></Badge>
                                                ))} </List>)
                                            : (" ")}
                                    </Td>
                                </Tr>
                                <Tr isExpanded={isRowExpanded(row)} >
                                    {/* <Td></Td> */}
                                    <Td colSpan={11} noPadding>
                                        <ExpandableRowContent key={rowIndex}>
                                            <Tabs
                                                activeKey={activeTabKey}
                                                onSelect={handleTabClick}
                                                style={{ marginTop: "0px" }}
                                                aria-label="Tabs in the Security Alerts"
                                                role="region"
                                            >
                                                <Tab
                                                    eventKey={0}
                                                    title={<TabTitleText>Table</TabTitleText>}
                                                    aria-label="Table data"
                                                >
                                                    <TabContentBody hasPadding  >
                                                        {/* {console.log ("logdata:", keyify(row?.details))} */}
                                                        <DescriptionList isHorizontal horizontalTermWidthModifier={{ default: '30ch' }} >
                                                            {expandedRowData[row.id]?.map((item, index) => (
                                                                <React.Fragment key={index}>
                                                                    <DescriptionListGroup >
                                                                        <DescriptionListTerm key={"item-" + index} > {item.key}</DescriptionListTerm>
                                                                        <DescriptionListDescription key={"desc-" + index}  >{item.value}</DescriptionListDescription>
                                                                    </DescriptionListGroup>
                                                                </React.Fragment>
                                                            ))}
                                                        </DescriptionList>
                                                    </TabContentBody>
                                                </Tab>
                                                <Tab eventKey={1} title={<TabTitleText>JSON</TabTitleText>}>
                                                    <TabContentBody hasPadding >
                                                        <pre style={preStyle} >
                                                            {row.details ? (
                                                                <pre>{JSON.stringify(row.details, null, 2)}</pre>
                                                            ) : (
                                                                <p>No details available.</p>
                                                            )}
                                                        </pre>
                                                    </TabContentBody>
                                                </Tab>
                                            </Tabs>
                                        </ExpandableRowContent>
                                    </Td>
                                </Tr>
                            </Tbody>
                        ))}
                    </Table>
                </InnerScrollContainer>
            </div>
        </React.Fragment>
    )
})
const mapStateToProps = (state) => ({
    filterformdata: state.FilterData,
});

export default connect(
    mapStateToProps,
    null,
    null,
    { forwardRef: true }
)(RecentAlertsRealTime)
