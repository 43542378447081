import * as echarts from 'echarts';
import React, { useEffect } from 'react';
import { generateUniqueID } from '../../util/UniqueIdGenerator';
import { formatValue } from '../../util/ChartHelper';
import { UpdateFilterData } from '../../util/FilterHelper';
import { FilterData } from '../../Redux/Action';
import { connect, useDispatch } from 'react-redux';

const GpgReqByAgentChart = (props) => {
    const id = generateUniqueID();
    const dispatch = useDispatch();

    const handleFilter = (fields, value) => {
        const updatedFields = UpdateFilterData(props.filterformdata, fields, 'is', value);
        dispatch(FilterData(updatedFields));
    };

    useEffect(() => {
        if (props.seriesData && props.seriesData.length > 0) {
            const chartDom = document.getElementById(id + 'gpgreqchart');
            const myChart = echarts.init(chartDom);
            const textcolor = props.theme === 'dark-theme' ? '#fff' : '#000';
            const linecolor = props.theme === 'dark-theme' ? '#595757' : '#d9d9d9';

            const xAxisData = props.seriesData.map(item => item.key);

            const allAgents = props.seriesData.flatMap(requirement => requirement.Agent_Name);

            // Step 2: Calculate the total doc_count for each agent
            const agentTotals = allAgents.reduce((acc, agent) => {
                acc[agent.key] = (acc[agent.key] || 0) + agent.doc_count;
                return acc;
            }, {});

            // Step 3: Sort the agents by their total doc_count and take the top 10
            const topAgents = Object.entries(agentTotals)
                .sort((a, b) => b[1] - a[1])
                .slice(0, 10)
                .map(([key]) => key);

            // Step 4: Filter the SeriesData to include only the top 10 agents
            const agentsData = {};
            props.seriesData.forEach(requirement => {
                requirement.Agent_Name.forEach(agent => {
                    if (topAgents.includes(agent.key)) {
                        if (!agentsData[agent.key]) {
                            agentsData[agent.key] = Array(xAxisData.length).fill(0);
                        }
                        const index = xAxisData.indexOf(requirement.key);
                        agentsData[agent.key][index] = agent.doc_count;
                    }
                });
            });
            const SeriesData = Object.keys(agentsData).map(agent => ({
                name: agent,
                type: 'bar',
                stack: 'total',
                barWidth: '40',
                emphasis: {
                    focus: 'series',
                },
                data: agentsData[agent],
            }));

            const legendData = Object.keys(agentsData);

            const option = {
                tooltip: {
                    trigger: 'axis',
                    textStyle: {
                        color: "#000"
                    }
                },
                title: {
                    text: "Requirements",
                    top: 'bottom',
                    left: 'center',
                    textStyle: {
                        fontWeight: 450,
                        fontSize: 14,
                        color: textcolor,
                        fontfamily: 'RedHatText, helvetica, arial, sans-serif',
                    },
                },
                legend: {
                    type: 'scroll',
                    orient: 'vertical',
                    right: 'right',
                    top: 10,
                    data: legendData,
                    itemWidth: 9.5,
                    itemHeight: 10,
                    itemGap: 6.5,
                    tooltip: {
                        trigger: 'item',
                        show: true,
                        position: [60, 10],
                        textStyle: {
                            color: "#000"
                        }
                    },
                    icon: "rect",
                    pageIconSize: 12,
                    textStyle: {
                        width: 180,
                        overflow: 'truncate',
                        fontSize: 12,
                        color: textcolor
                    }
                },
                grid: {
                    left: '2%',
                    right: '15%',
                    bottom: '13%',
                    top: '8%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: true,
                        axisLabel: {
                            interval: 0,
                            rotate: -50,
                            width: 70,
                            overflow: 'truncate',
                            fontSize: 12,
                            color: textcolor
                        },
                        data: xAxisData,
                        axisTick: {
                            alignWithLabel: true
                        }
                    }
                ],
                yAxis: [
                    {
                        axisLine: {
                            show: true
                        },
                        splitLine: {
                            lineStyle: {
                                color: linecolor
                            }
                        },
                        axisLabel: {
                            formatter: formatValue,
                            color: textcolor
                        }
                    }
                ],
                color: ['#0066cc', '#f4c145', '#4cb140', '#5752d1', '#ec7a08', '#40199A', '#C8EB79', '#C9190B', '#008BAD'],
                series: SeriesData
            };

            option && myChart.setOption(option);
            myChart.on('legendselectchanged', function (param) {

                handleFilter(props.filterFields, param.name);
            });

            const resizeChart = () => {
                if (myChart) {
                    myChart.resize();
                }
            };

            window.addEventListener('resize', resizeChart);
            return () => {
                myChart.dispose();
                window.removeEventListener('resize', resizeChart);
            };
        }
    }, [props.seriesData, props.theme, props.sidebarState, props.height]);

    return (
        <div className="container">
            <div id={id + 'gpgreqchart'} style={{ width: '100%', height: props.height }}></div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    dates: { startDate: state.startdate, endDate: state.enddate },
    filterformdata: state.FilterData,
    theme: state.theme,
    sidebarState: state.sidebarstate
});

export default connect(
    mapStateToProps,
    null,
    null,
    { forwardRef: true }
)(GpgReqByAgentChart);
