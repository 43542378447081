import { Button, Card, CardBody, CardHeader, CardTitle, DualListSelector, Icon, Label, LabelGroup, PageSection } from '@patternfly/react-core'
import { AngleLeftIcon, RedoIcon, SortAlphaDownIcon, SortAmountDownIcon } from '@patternfly/react-icons'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { alertBoxData } from 'src/Redux/Action'
import dbApi from 'src/services/DbApi'
const ManageGroupAgent = (props) => {
    const { groupname } = useParams()
    const [allagentList, setAllAgentList] = useState([]);
    const [groupagents, setGroupAgents] = useState([]);
    const [chosenAgents, setChosenAgents] = useState([]);
    const [availableAgents, setAvailableAgents] = useState([]);
    const [isloadagents, setIsloadAgents] = useState(false)
    const [addedAgents, setAddedAgents] = useState('')
    const [removedAgents, setRemovedAgents] = useState('')
    const [isDisableapply, setIsDisableApply] = useState(true)
    const [isloadapply, setIsloadApply] = useState(false)
    const onListChange = (event, newAvailableAgents, newChosenAgents) => {
        setAvailableAgents(newAvailableAgents.sort())
        setChosenAgents(newChosenAgents.sort());
    };
    const dispatch = useDispatch()

    const getAllAgents = async () => {
        let param = {
            offset: '',
            limit: '',
            search: '',
            sort: '',
            name: 'default',
            select: 'id,name'
        }
        try {
            setIsloadAgents(true)
            let res = await dbApi.postApi("Opensearch/Agent_Group", param)
            console.log(res)
            let agents = []
            if (res.Agent_List.length > 0) {
                agents = res.Agent_List.map((item) => ({
                    id: item.id,
                    text: `${item.id}-${item.name}`
                }));
                setAllAgentList(agents)

            } else {
                setAllAgentList([])
            }
            setIsloadAgents(false)
        } catch (error) {
            console.log(error)
            setIsloadAgents(false)
        }
    };
    const getGroupAgents = async () => {
        let param = {
            offset: '',
            limit: '',
            search: '',
            sort: '',
            name: groupname,
            select: 'id,name'
        }
        try {
            setIsloadAgents(true)
            let res = await dbApi.postApi("Opensearch/Agent_Group", param)
            console.log(res)
            let agents = []
            if (res.Agent_List.length > 0) {
                agents = res.Agent_List.map((item) => ({
                    id: item.id,
                    text: `${item.id}-${item.name}`
                }));
                setGroupAgents(agents);
                setChosenAgents(agents)

            } else {
                setGroupAgents([]);
                setChosenAgents([])
            }
            setIsloadAgents(false)
        } catch (error) {
            console.log(error)
            setIsloadAgents(false)
        }
    };
    useEffect(() => {
        getAllAgents();
        getGroupAgents();
    }, [])


    useEffect(() => {
        const chosenIds = chosenAgents.map(agent => agent.id);
        const updatedAvailableAgents = allagentList.filter(agent => !chosenIds.includes(agent.id));
        setAvailableAgents(updatedAvailableAgents);
    }, [allagentList, chosenAgents]);



    const availableOptionsActions = [<Button
        variant='link'
        onClick={() => { getAllAgents() }}
        aria-label="refresh"
        key="redo"
    >
        <RedoIcon />
    </Button>,];
    const chosenOptionsActions = [<LabelGroup isCompact>
        <Label color='green' isCompact >Added ({addedAgents.length ?? 0})</Label>
        <Label color="red" isCompact>Removed ({removedAgents.length ?? 0})</Label>
    </LabelGroup>];

    const getNewlyChosenAgents = () => {
        const initialChosenIds = groupagents.map(agent => agent.id);
        return chosenAgents.filter(agent => !initialChosenIds.includes(agent.id));
    };

    const getNewlyAvailableAgents = () => {
        const initialChosenIds = groupagents.map(agent => agent.id);
        return groupagents.filter(agent => !chosenAgents.map(a => a.id).includes(agent.id));
    };

    useEffect(() => {
        const newlyChosenAgents = getNewlyChosenAgents();
        const newlyAvailableAgents = getNewlyAvailableAgents();
        setAddedAgents(newlyChosenAgents)
        setRemovedAgents(newlyAvailableAgents)
        if (newlyChosenAgents.length > 0) {
            setIsDisableApply(false)
        }
        else if (newlyAvailableAgents.length > 0) {
            setIsDisableApply(false)
        }
        console.log('Newly chosen agents:', newlyChosenAgents);
        console.log('Newly available agents:', newlyAvailableAgents);
    }, [chosenAgents, groupagents]);


    const getAssignAgent = async () => {
        try {
            let agents = addedAgents.map((item) => item.id)
            let param = {
                Agent_ID: agents.join(','),
                Group_Name: groupname
            }
            setIsloadApply(true)
            setIsDisableApply(true)
            let res = await dbApi.postApi("Opensearch/Agent_Assigned_To_Group", param)
            console.log(res)
            let data = res.Agent_Assigned_To_Group
            if (!data.error) {
                dispatch(alertBoxData([{ title: data.message, variant: 'success' }]));
                getGroupAgents()
                getAllAgents()
            }
            else {
                dispatch(alertBoxData([{ title: data.message, variant: 'danger' }]));
            }
            setIsloadApply(false)
        } catch (error) {
            console.log(error)
            setIsloadApply(false)
        }
    }

    const getRemoveAgent = async () => {
        try {
            let agents = removedAgents.map((item) => item.id)
            let param = {
                Agent_ID: agents.join(','),
                Group_Name: groupname
            }
            setIsloadApply(true)
            setIsDisableApply(true)
            let res = await dbApi.postApi("Opensearch/Remove_Agent_Assigned_Group ", param)
            console.log(res)
            if (!res.error) {
                dispatch(alertBoxData([{ title: res.message, variant: 'danger' }]));
                getGroupAgents()
                getAllAgents()
            }
            else {
                dispatch(alertBoxData([{ title: res.message, variant: 'success' }]));
            }
            setIsloadApply(false)
        } catch (error) {
            console.log(error)
            setIsloadApply(false)
        }
        // Opensearch/Agent_Assigned_To_Group
    }
    const onApplychange = () => {
        if (addedAgents.length > 0) {
            getAssignAgent();
        }
        if (removedAgents.length > 0) {
            getRemoveAgent()
        }

    }
    const headerActions = <>
        <Button variant='primary' onClick={onApplychange}
            isDisabled={isDisableapply}
            isLoading={isloadapply}>
            Apply Changes
        </Button>
    </>
    return (
        <div>
            <PageSection className="pf-v5-u-p-sm">
                <Card>
                    <CardHeader actions={{ actions: (headerActions) }}>
                        <CardTitle component='h1'>
                            <Link to={"/management/groups-agent/" + groupname} className='pf-v5-u-color-100'>
                                <Icon size='md' isInline><AngleLeftIcon /></Icon>
                                Manage agents of group {groupname}
                            </Link></CardTitle>
                    </CardHeader>
                    <CardBody>
                        <DualListSelector
                            isSearchable
                            availableOptionsTitle='Available Agents'
                            availableOptions={availableAgents}
                            availableOptionsActions={availableOptionsActions}
                            chosenOptionsTitle={chosenAgents.length > 0 ? `Current agents in the group (${chosenAgents.length})` : 'Current agents in the group'}
                            chosenOptions={chosenAgents}
                            chosenOptionsActions={chosenOptionsActions}
                            // chosenOptionsStatus={}
                            isTree
                            addAllTooltip="Add all agents"
                            addAllTooltipProps={{ position: 'top' }}
                            addSelectedTooltip="Add selected agents"
                            addSelectedTooltipProps={{ position: 'right' }}
                            removeSelectedTooltip="Remove selected agents"
                            removeSelectedTooltipProps={{ position: 'left' }}
                            removeAllTooltip="Remove all agents"
                            removeAllTooltipProps={{ position: 'bottom' }}
                            onListChange={onListChange}
                            id="dual-list-selector-basic-search" />
                    </CardBody>
                </Card>
            </PageSection>
        </div>
    )
}

export default ManageGroupAgent