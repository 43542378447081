// const timeZoneList=
// [
//     {children: "Arabic (Saudi Arabia)", value: "ar-SA"},
//     {children: "Bangla (Bangladesh)", value: "bn-BD"},
//     {children: "Bangla (India)", value: "bn-IN"},
//     {children: "Czech (Czech Republic)", value: "cs-CZ"},
//     {children: "Danish (Denmark)", value: "da-DK"},
//     {children: "Austrian German", value: "de-AT"},
//     {children: "\"Swiss\" German", value: "de-CH"},
//     {children: "Standard German (as spoken in Germany)", value: "de-DE"},
//     {children: "Modern Greek", value: "el-GR"},
//     {children: "Australian English", value: "en-AU"},
//     {children: "Canadian English", value: "en-CA"},
//     {children: "British English", value: "en-GB"},
//     {children: "Irish English", value: "en-IE"},
//     {children: "Indian English", value: "en-IN"},
//     {children: "New Zealand English", value: "en-NZ"},
//     {children: "US English", value: "en-US"},
//     {children: "English (South Africa)", value: "en-ZA"},
//     {children: "Argentine Spanish", value: "es-AR"},
//     {children: "Chilean Spanish", value: "es-CL"},
//     {children: "Colombian Spanish", value: "es-CO"},
//     {children: "Castilian Spanish (as spoken in Central-Northern Spain)", value: "es-ES"},
//     {children: "Mexican Spanish", value: "es-MX"},
//     {children: "American Spanish", value: "es-US"},
//     {children: "Iranian (Iran)", value: "fa-IR"},
//     {children: "Finnish (Finland)", value: "fi-FI"},
//     {children: "Belgian French", value: "fr-BE"},
//     {children: "Canadian French", value: "fr-CA"},
//     {children: "\"Swiss\" French", value: "fr-CH"},
//     {children: "Standard French (especially in France)", value: "fr-FR"},
//     {children: "Hebrew (Israel)", value: "he-IL"},
//     {children: "Hindi (India)", value: "hi-IN"},
//     {children: "Hungarian (Hungary)", value: "hu-HU"},
//     {children: "Indonesian (Indonesia)", value: "id-ID"},
//     {children: "\"Swiss\" Italian", value: "it-CH"},
//     {children: "Standard Italian (as spoken in Italy)", value: "it-IT"},
//     {children: "Japanese (Japan)", value: "ja-JP"},
//     {children: "Korean (Republic of Korea)", value: "ko-KR"},
//     {children: "Belgian Dutch", value: "nl-BE"},
//     {children: "Standard Dutch (as spoken in The Netherlands)", value: "nl-NL"},
//     {children: "Norwegian (Norway)", value: "no-NO"},
//     {children: "Polish (Poland)", value: "pl-PL"},
//     {children: "Brazilian Portuguese", value: "pt-BR"},
//     {children: "European Portuguese (as written and spoken in Portugal)", value: "pt-PT"},
//     {children: "Romanian (Romania)", value: "ro-RO"},
//     {children: "Russian (Russian Federation)", value: "ru-RU"},
//     {children: "Slovak (Slovakia)", value: "sk-SK"},
//     {children: "Swedish (Sweden)", value: "sv-SE"},
//     {children: "Indian Tamil", value: "ta-IN"},
//     {children: "Sri Lankan Tamil", value: "ta-LK"},
//     {children: "Thai (Thailand)", value: "th-TH"},
//     {children: "Turkish (Turkey)", value: "tr-TR"},
//     {children: "Mainland China, simplified characters", value: "zh-CN"},
//     {children: "Hong Kong, traditional characters", value: "zh-HK"},
//     {children: "Taiwan, traditional characters", value: "zh-TW"}
// ]
const timeZoneList = [
    { value: "Pacific/Niue", children: "(GMT-11:00) Pacific, Niue" },
    { value: "Pacific/Pago_Pago", children: "(GMT-11:00) Pacific, Pago Pago" },
    { value: "Pacific/Midway", children: "(GMT-11:00) Pacific, Midway" },
    { value: "Pacific/Honolulu", children: "(GMT-10:00) Pacific, Honolulu" },
    { value: "Pacific/Rarotonga", children: "(GMT-10:00) Pacific, Rarotonga" },
    { value: "Pacific/Tahiti", children: "(GMT-10:00) Pacific, Tahiti" },
    { value: "Pacific/Marquesas", children: "(GMT-09:30) Pacific, Marquesas" },
    { value: "America/Adak", children: "(GMT-09:00) America, Adak" },
    { value: "Pacific/Gambier", children: "(GMT-09:00) Pacific, Gambier" },
    { value: "America/Anchorage", children: "(GMT-08:00) America, Anchorage" },
    { value: "America/Sitka", children: "(GMT-08:00) America, Sitka" },
    { value: "America/Yakutat", children: "(GMT-08:00) America, Yakutat" },
    { value: "America/Nome", children: "(GMT-08:00) America, Nome" },
    { value: "America/Juneau", children: "(GMT-08:00) America, Juneau" },
    { value: "America/Metlakatla", children: "(GMT-08:00) America, Metlakatla" },
    { value: "America/Adak", children: "(GMT-08:00) America, Adak" },
    { value: "Pacific/Pitcairn", children: "(GMT-08:00) Pacific, Pitcairn" },
    { value: "America/Los_Angeles", children: "(GMT-07:00) America, Los Angeles" },
    { value: "America/Vancouver", children: "(GMT-07:00) America, Vancouver" },
    { value: "America/Tijuana", children: "(GMT-07:00) America, Tijuana" },
    { value: "America/Whitehorse", children: "(GMT-07:00) America, Whitehorse" },
    { value: "America/Dawson", children: "(GMT-07:00) America, Dawson" },
    { value: "America/Phoenix", children: "(GMT-07:00) America, Phoenix" },
    { value: "America/Denver", children: "(GMT-06:00) America, Denver" },
    { value: "America/Edmonton", children: "(GMT-06:00) America, Edmonton" },
    { value: "America/Hermosillo", children: "(GMT-06:00) America, Hermosillo" },
    { value: "America/Yellowknife", children: "(GMT-06:00) America, Yellowknife" },
    { value: "America/Belize", children: "(GMT-06:00) America, Belize" },
    { value: "America/Ojinaga", children: "(GMT-06:00) America, Ojinaga" },
    { value: "America/Guatemala", children: "(GMT-06:00) America, Guatemala" },
    { value: "America/El_Salvador", children: "(GMT-06:00) America, El Salvador" },
    { value: "America/Regina", children: "(GMT-06:00) America, Regina" },
    { value: "America/Tegucigalpa", children: "(GMT-06:00) America, Tegucigalpa" },
    { value: "America/Chicago", children: "(GMT-05:00) America, Chicago" },
    { value: "America/Winnipeg", children: "(GMT-05:00) America, Winnipeg" },
    { value: "America/Mexico_City", children: "(GMT-05:00) America, Mexico City" },
    { value: "America/Bahia_Banderas", children: "(GMT-05:00) America, Bahia Banderas" },
    { value: "America/Bogota", children: "(GMT-05:00) America, Bogota" },
    { value: "America/Lima", children: "(GMT-05:00) America, Lima" },
    { value: "America/Rio_Branco", children: "(GMT-05:00) America, Rio Branco" },
    { value: "America/Indiana/Knox", children: "(GMT-05:00) America, Indiana, Knox" },
    { value: "America/Indiana/Tell_City", children: "(GMT-05:00) America, Indiana, Tell City" },
    { value: "America/Indiana/Vevay", children: "(GMT-05:00) America, Indiana, Vevay" },
    { value: "America/Indiana/Vincennes", children: "(GMT-05:00) America, Indiana, Vincennes" },
    { value: "America/Indiana/Winamac", children: "(GMT-05:00) America, Indiana, Winamac" },
    { value: "America/Indiana/Petersburg", children: "(GMT-05:00) America, Indiana, Petersburg" },
    { value: "America/Indiana/Marengo", children: "(GMT-05:00) America, Indiana, Marengo" },
    { value: "America/Indiana/Indianapolis", children: "(GMT-05:00) America, Indiana, Indianapolis" },
    { value: "America/Guayaquil", children: "(GMT-05:00) America, Guayaquil" },
    { value: "America/Jamaica", children: "(GMT-05:00) America, Jamaica" },
    { value: "America/Port-au-Prince", children: "(GMT-05:00) America, Port-au-Prince" },
    { value: "America/Panama", children: "(GMT-05:00) America, Panama" },
    { value: "America/New_York", children: "(GMT-04:00) America, New York" },
    { value: "America/Toronto", children: "(GMT-04:00) America, Toronto" },
    { value: "America/Detroit", children: "(GMT-04:00) America, Detroit" },
    { value: "America/Thunder_Bay", children: "(GMT-04:00) America, Thunder Bay" },
    { value: "America/Iqaluit", children: "(GMT-04:00) America, Iqaluit" },
    { value: "America/Nipigon", children: "(GMT-04:00) America, Nipigon" },
    { value: "America/Pangnirtung", children: "(GMT-04:00) America, Pangnirtung" },
    { value: "America/Kentucky/Monticello", children: "(GMT-04:00) America, Kentucky, Monticello" },
    { value: "America/Kentucky/Louisville", children: "(GMT-04:00) America, Kentucky, Louisville" },
    { value: "America/Indiana/Vevay", children: "(GMT-04:00) America, Indiana, Vevay" },
    { value: "America/Indiana/Petersburg", children: "(GMT-04:00) America, Indiana, Petersburg" },
    { value: "America/Indiana/Marengo", children: "(GMT-04:00) America, Indiana, Marengo" },
    { value: "America/Havana", children: "(GMT-04:00) America, Havana" },
    { value: "America/Antigua", children: "(GMT-04:00) America, Antigua" },
    { value: "America/Barbados", children: "(GMT-04:00) America, Barbados" },
    { value: "America/Blanc-Sablon", children: "(GMT-04:00) America, Blanc-Sablon" },
    { value: "America/Boa_Vista", children: "(GMT-04:00) America, Boa Vista" },
    { value: "America/Campo_Grande", children: "(GMT-04:00) America, Campo Grande" },
    { value: "America/Caracas", children: "(GMT-04:00) America, Caracas" },
    { value: "America/Cuiaba", children: "(GMT-04:00) America, Cuiaba" },
    { value: "America/Curacao", children: "(GMT-04:00) America, Curacao" },
    { value: "America/Dominica", children: "(GMT-04:00) America, Dominica" },
    { value: "America/Grenada", children: "(GMT-04:00) America, Grenada" },
    { value: "America/Guadeloupe", children: "(GMT-04:00) America, Guadeloupe" },
    { value: "America/Guyana", children: "(GMT-04:00) America, Guyana" },
    { value: "America/Kralendijk", children: "(GMT-04:00) America, Kralendijk" },
    { value: "America/La_Paz", children: "(GMT-04:00) America, La Paz" },
    { value: "America/Lower_Princes", children: "(GMT-04:00) America, Lower Princes" },
    { value: "America/Manaus", children: "(GMT-04:00) America, Manaus" },
    { value: "America/Marigot", children: "(GMT-04:00) America, Marigot" },
    { value: "America/Martinique", children: "(GMT-04:00) America, Martinique" },
    { value: "America/Moncton", children: "(GMT-03:00) America, Moncton" },
    { value: "America/Montserrat", children: "(GMT-04:00) America, Montserrat" },
    { value: "America/Port_of_Spain", children: "(GMT-04:00) America, Port of Spain" },
    { value: "America/Porto_Velho", children: "(GMT-04:00) America, Porto Velho" },
    { value: "America/Puerto_Rico", children: "(GMT-04:00) America, Puerto Rico" },
    { value: "America/Rio_Branco", children: "(GMT-04:00) America, Rio Branco" },
    { value: "America/Santiago", children: "(GMT-03:00) America, Santiago" },
    { value: "America/Santo_Domingo", children: "(GMT-04:00) America, Santo Domingo" },
    { value: "America/St_Barthelemy", children: "(GMT-04:00) America, St. Barthelemy" },
    { value: "America/St_Kitts", children: "(GMT-04:00) America, St. Kitts" },
    { value: "America/St_Lucia", children: "(GMT-04:00) America, St. Lucia" },
    { value: "America/St_Thomas", children: "(GMT-04:00) America, St. Thomas" },
    { value: "America/St_Vincent", children: "(GMT-04:00) America, St. Vincent" },
    { value: "America/Tortola", children: "(GMT-04:00) America, Tortola" },
    { value: "America/Anguilla", children: "(GMT-04:00) America, Anguilla" },
    { value: "America/Argentina/Buenos_Aires", children: "(GMT-03:00) America, Argentina, Buenos Aires" },
    { value: "America/Argentina/Catamarca", children: "(GMT-03:00) America, Argentina, Catamarca" },
    { value: "America/Argentina/Cordoba", children: "(GMT-03:00) America, Argentina, Cordoba" },
    { value: "America/Argentina/Jujuy", children: "(GMT-03:00) America, Argentina, Jujuy" },
    { value: "America/Argentina/La_Rioja", children: "(GMT-03:00) America, Argentina, La Rioja" },
    { value: "America/Argentina/Mendoza", children: "(GMT-03:00) America, Argentina, Mendoza" },
    { value: "America/Argentina/Rio_Gallegos", children: "(GMT-03:00) America, Argentina, Rio Gallegos" },
    { value: "America/Argentina/Salta", children: "(GMT-03:00) America, Argentina, Salta" },
    { value: "America/Argentina/San_Juan", children: "(GMT-03:00) America, Argentina, San Juan" },
    { value: "America/Argentina/San_Luis", children: "(GMT-03:00) America, Argentina, San Luis" },
    { value: "America/Argentina/Tucuman", children: "(GMT-03:00) America, Argentina, Tucuman" },
    { value: "America/Argentina/Ushuaia", children: "(GMT-03:00) America, Argentina, Ushuaia" },
    { value: "America/Bahia", children: "(GMT-03:00) America, Bahia" },
    { value: "America/Belem", children: "(GMT-03:00) America, Belem" },
    { value: "America/Cayenne", children: "(GMT-03:00) America, Cayenne" },
    { value: "America/Fortaleza", children: "(GMT-03:00) America, Fortaleza" },
    { value: "America/Maceio", children: "(GMT-03:00) America, Maceio" },
    { value: "America/Miquelon", children: "(GMT-02:00) America, Miquelon" },
    { value: "America/Montevideo", children: "(GMT-03:00) America, Montevideo" },
    { value: "America/Noronha", children: "(GMT-02:00) America, Noronha" },
    { value: "America/Paramaribo", children: "(GMT-03:00) America, Paramaribo" },
    { value: "America/Punta_Arenas", children: "(GMT-03:00) America, Punta Arenas" },
    { value: "America/Recife", children: "(GMT-03:00) America, Recife" },
    { value: "America/Thule", children: "(GMT-03:00) America, Thule" },
    { value: "America/Sao_Paulo", children: "(GMT-03:00) America, Sao Paulo" },
    { value: "America/Scoresbysund", children: "(GMT-01:00) America, Scoresbysund" },
    { value: "Atlantic/Azores", children: "(GMT-01:00) Atlantic, Azores" },
    { value: "Atlantic/Cape_Verde", children: "(GMT-01:00) Atlantic, Cape Verde" },
    { value: "Africa/Abidjan", children: "(GMT) Africa, Abidjan" },
    { value: "Africa/Accra", children: "(GMT) Africa, Accra" },
    { value: "Africa/Bamako", children: "(GMT) Africa, Bamako" },
    { value: "Africa/Banjul", children: "(GMT) Africa, Banjul" },
    { value: "Africa/Bissau", children: "(GMT) Africa, Bissau" },
    { value: "Africa/Conakry", children: "(GMT) Africa, Conakry" },
    { value: "Africa/Dakar", children: "(GMT) Africa, Dakar" },
    { value: "Africa/Freetown", children: "(GMT) Africa, Freetown" },
    { value: "Africa/Lome", children: "(GMT) Africa, Lome" },
    { value: "Africa/Monrovia", children: "(GMT) Africa, Monrovia" },
    { value: "Africa/Nouakchott", children: "(GMT) Africa, Nouakchott" },
    { value: "Africa/Ouagadougou", children: "(GMT) Africa, Ouagadougou" },
    { value: "Africa/Sao_Tome", children: "(GMT) Africa, Sao Tome" },
    { value: "Atlantic/Reykjavik", children: "(GMT) Atlantic, Reykjavik" },
    { value: "Africa/Accra", children: "(GMT) Africa, Accra" },
    { value: "Atlantic/St_Helena", children: "(GMT) Atlantic, St. Helena" },
    { value: "Africa/Douala", children: "(GMT+01:00) Africa, Douala" },
    { value: "Africa/Algiers", children: "(GMT+01:00) Africa, Algiers" },
    { value: "Africa/Bangui", children: "(GMT+01:00) Africa, Bangui" },
    { value: "Africa/Brazzaville", children: "(GMT+01:00) Africa, Brazzaville" },
    { value: "Africa/Casablanca", children: "(GMT+01:00) Africa, Casablanca" },
    { value: "Africa/Douala", children: "(GMT+01:00) Africa, Douala" },
    { value: "Africa/El_Aaiun", children: "(GMT+01:00) Africa, El Aaiun" },
    { value: "Africa/Kinshasa", children: "(GMT+01:00) Africa, Kinshasa" },
    { value: "Africa/Lagos", children: "(GMT+01:00) Africa, Lagos" },
    { value: "Africa/Libreville", children: "(GMT+01:00) Africa, Libreville" },
    { value: "Africa/Luanda", children: "(GMT+01:00) Africa, Luanda" },
    { value: "Africa/Malabo", children: "(GMT+01:00) Africa, Malabo" },
    { value: "Africa/Ndjamena", children: "(GMT+01:00) Africa, Ndjamena" },
    { value: "Africa/Niamey", children: "(GMT+01:00) Africa, Niamey" },
    { value: "Africa/Porto-Novo", children: "(GMT+01:00) Africa, Porto-Novo" },
    { value: "Africa/Tunis", children: "(GMT+01:00) Africa, Tunis" },
    { value: "Africa/Windhoek", children: "(GMT+01:00) Africa, Windhoek" },
    { value: "America/Danmarkshavn", children: "(GMT) America, Danmarkshavn" },
    { value: "Atlantic/Canary", children: "(GMT) Atlantic, Canary" },
    { value: "Atlantic/Faroe", children: "(GMT) Atlantic, Faroe" },
    { value: "Atlantic/Madeira", children: "(GMT) Atlantic, Madeira" },
    { value: "Atlantic/Reykjavik", children: "(GMT) Atlantic, Reykjavik" },
    { value: "Atlantic/Azores", children: "(GMT) Atlantic, Azores" },
    { value: "Atlantic/Bermuda", children: "(GMT-03:00) Atlantic, Bermuda" },
    { value: "Atlantic/Canary", children: "(GMT) Atlantic, Canary" },
    { value: "Atlantic/Cape_Verde", children: "(GMT-01:00) Atlantic, Cape Verde" },
    { value: "Atlantic/Faroe", children: "(GMT) Atlantic, Faroe" },
    { value: "Atlantic/Madeira", children: "(GMT) Atlantic, Madeira" },
    { value: "Atlantic/Reykjavik", children: "(GMT) Atlantic, Reykjavik" },
    { value: "Atlantic/South_Georgia", children: "(GMT) Atlantic, South Georgia" },
    { value: "Atlantic/Stanley", children: "(GMT-03:00) Atlantic, Stanley" },
    { value: "Australia/Adelaide", children: "(GMT+09:30) Australia, Adelaide" },
    { value: "Australia/Brisbane", children: "(GMT+10:00) Australia, Brisbane" },
    { value: "Australia/Broken_Hill", children: "(GMT+09:30) Australia, Broken Hill" },
    { value: "Australia/Currie", children: "(GMT+10:00) Australia, Currie" },
    { value: "Australia/Darwin", children: "(GMT+09:30) Australia, Darwin" },
    { value: "Australia/Eucla", children: "(GMT+08:45) Australia, Eucla" },
    { value: "Australia/Hobart", children: "(GMT+10:00) Australia, Hobart" },
    { value: "Australia/Lindeman", children: "(GMT+10:00) Australia, Lindeman" },
    { value: "Australia/Lord_Howe", children: "(GMT+10:30) Australia, Lord Howe" },
    { value: "Australia/Melbourne", children: "(GMT+10:00) Australia, Melbourne" },
    { value: "Australia/Perth", children: "(GMT+08:00) Australia, Perth" },
    { value: "Australia/Sydney", children: "(GMT+10:00) Australia, Sydney" },
    { value: "Australia/Yancowinna", children: "(GMT+09:30) Australia, Yancowinna" },
    { value: "Europe/Amsterdam", children: "(GMT+02:00) Europe, Amsterdam" },
    { value: "Europe/Andorra", children: "(GMT+02:00) Europe, Andorra" },
    { value: "Europe/Astrakhan", children: "(GMT+04:00) Europe, Astrakhan" },
    { value: "Europe/Athens", children: "(GMT+03:00) Europe, Athens" },
    { value: "Europe/Belgrade", children: "(GMT+02:00) Europe, Belgrade" },
    { value: "Europe/Berlin", children: "(GMT+02:00) Europe, Berlin" },
    { value: "Europe/Bratislava", children: "(GMT+02:00) Europe, Bratislava" },
    { value: "Europe/Brussels", children: "(GMT+02:00) Europe, Brussels" },
    { value: "Europe/Bucharest", children: "(GMT+03:00) Europe, Bucharest" },
    { value: "Europe/Budapest", children: "(GMT+02:00) Europe, Budapest" },
    { value: "Europe/Busingen", children: "(GMT+02:00) Europe, Busingen" },
    { value: "Europe/Chisinau", children: "(GMT+03:00) Europe, Chisinau" },
    { value: "Europe/Copenhagen", children: "(GMT+02:00) Europe, Copenhagen" },
    { value: "Europe/Dublin", children: "(GMT+01:00) Europe, Dublin" },
    { value: "Europe/Gibraltar", children: "(GMT+02:00) Europe, Gibraltar" },
    { value: "Europe/Guernsey", children: "(GMT+01:00) Europe, Guernsey" },
    { value: "Europe/Helsinki", children: "(GMT+03:00) Europe, Helsinki" },
    { value: "Europe/Isle_of_Man", children: "(GMT+01:00) Europe, Isle of Man" },
    { value: "Europe/Istanbul", children: "(GMT+03:00) Europe, Istanbul" },
    { value: "Europe/Jersey", children: "(GMT+01:00) Europe, Jersey" },
    { value: "Europe/Kaliningrad", children: "(GMT+02:00) Europe, Kaliningrad" },
    { value: "Europe/Kiev", children: "(GMT+03:00) Europe, Kiev" },
    { value: "Europe/Kirov", children: "(GMT+03:00) Europe, Kirov" },
    { value: "Europe/Lisbon", children: "(GMT+01:00) Europe, Lisbon" },
    { value: "Europe/Ljubljana", children: "(GMT+02:00) Europe, Ljubljana" },
    { value: "Europe/London", children: "(GMT+01:00) Europe, London" },
    { value: "Europe/Luxembourg", children: "(GMT+02:00) Europe, Luxembourg" },
    { value: "Europe/Madrid", children: "(GMT+02:00) Europe, Madrid" },
    { value: "Europe/Malta", children: "(GMT+02:00) Europe, Malta" },
    { value: "Europe/Mariehamn", children: "(GMT+03:00) Europe, Mariehamn" },
    { value: "Europe/Minsk", children: "(GMT+03:00) Europe, Minsk" },
    { value: "Europe/Monaco", children: "(GMT+02:00) Europe, Monaco" },
    { value: "Europe/Moscow", children: "(GMT+03:00) Europe, Moscow" },
    { value: "Europe/Oslo", children: "(GMT+02:00) Europe, Oslo" },
    { value: "Europe/Paris", children: "(GMT+02:00) Europe, Paris" },
    { value: "Europe/Podgorica", children: "(GMT+02:00) Europe, Podgorica" },
    { value: "Europe/Prague", children: "(GMT+02:00) Europe, Prague" },
    { value: "Europe/Riga", children: "(GMT+03:00) Europe, Riga" },
    { value: "Europe/Rome", children: "(GMT+02:00) Europe, Rome" },
    { value: "Europe/Samara", children: "(GMT+04:00) Europe, Samara" },
    { value: "Europe/San_Marino", children: "(GMT+02:00) Europe, San Marino" },
    { value: "Europe/Sarajevo", children: "(GMT+02:00) Europe, Sarajevo" },
    { value: "Europe/Saratov", children: "(GMT+04:00) Europe, Saratov" },
    { value: "Europe/Simferopol", children: "(GMT+03:00) Europe, Simferopol" },
    { value: "Europe/Skopje", children: "(GMT+02:00) Europe, Skopje" },
    { value: "Europe/Sofia", children: "(GMT+03:00) Europe, Sofia" },
    { value: "Europe/Stockholm", children: "(GMT+02:00) Europe, Stockholm" },
    { value: "Europe/Tallinn", children: "(GMT+03:00) Europe, Tallinn" },
    { value: "Europe/Tirane", children: "(GMT+02:00) Europe, Tirane" },
    { value: "Europe/Ulyanovsk", children: "(GMT+04:00) Europe, Ulyanovsk" },
    { value: "Europe/Uzhgorod", children: "(GMT+03:00) Europe, Uzhgorod" },
    { value: "Europe/Vaduz", children: "(GMT+02:00) Europe, Vaduz" },
    { value: "Europe/Vatican", children: "(GMT+02:00) Europe, Vatican" },
    { value: "Europe/Vienna", children: "(GMT+02:00) Europe, Vienna" },
    { value: "Europe/Vilnius", children: "(GMT+03:00) Europe, Vilnius" },
    { value: "Europe/Volgograd", children: "(GMT+04:00) Europe, Volgograd" },
    { value: "Europe/Warsaw", children: "(GMT+02:00) Europe, Warsaw" },
    { value: "Europe/Zagreb", children: "(GMT+02:00) Europe, Zagreb" },
    { value: "Europe/Zaporozhye", children: "(GMT+03:00) Europe, Zaporozhye" },
    { value: "Europe/Zurich", children: "(GMT+02:00) Europe, Zurich" },
    { value: "Asia/Aden", children: "(GMT+03:00) Asia, Aden" },
    { value: "Asia/Almaty", children: "(GMT+06:00) Asia, Almaty" },
    { value: "Asia/Amman", children: "(GMT+03:00) Asia, Amman" },
    { value: "Asia/Anadyr", children: "(GMT+12:00) Asia, Anadyr" },
    { value: "Asia/Aqtau", children: "(GMT+05:00) Asia, Aqtau" },
    { value: "Asia/Aqtobe", children: "(GMT+05:00) Asia, Aqtobe" },
    { value: "Asia/Ashgabat", children: "(GMT+05:00) Asia, Ashgabat" },
    { value: "Asia/Atyrau", children: "(GMT+05:00) Asia, Atyrau" },
    { value: "Asia/Baghdad", children: "(GMT+03:00) Asia, Baghdad" },
    { value: "Asia/Bahrain", children: "(GMT+03:00) Asia, Bahrain" },
    { value: "Asia/Baku", children: "(GMT+04:00) Asia, Baku" },
    { value: "Asia/Bangkok", children: "(GMT+07:00) Asia, Bangkok" },
    { value: "Asia/Barnaul", children: "(GMT+07:00) Asia, Barnaul" },
    { value: "Asia/Beirut", children: "(GMT+03:00) Asia, Beirut" },
    { value: "Asia/Bishkek", children: "(GMT+06:00) Asia, Bishkek" },
    { value: "Asia/Brunei", children: "(GMT+08:00) Asia, Brunei" },
    { value: "Asia/Chita", children: "(GMT+09:00) Asia, Chita" },
    { value: "Asia/Choibalsan", children: "(GMT+08:00) Asia, Choibalsan" },
    { value: "Asia/Colombo", children: "(GMT+05:30) Asia, Colombo" },
    { value: "Asia/Damascus", children: "(GMT+03:00) Asia, Damascus" },
    { value: "Asia/Dhaka", children: "(GMT+06:00) Asia, Dhaka" },
    { value: "Asia/Dili", children: "(GMT+09:00) Asia, Dili" },
    { value: "Asia/Dubai", children: "(GMT+04:00) Asia, Dubai" },
    { value: "Asia/Dushanbe", children: "(GMT+05:00) Asia, Dushanbe" },
    { value: "Asia/Famagusta", children: "(GMT+03:00) Asia, Famagusta" },
    { value: "Asia/Gaza", children: "(GMT+03:00) Asia, Gaza" },
    { value: "Asia/Hebron", children: "(GMT+03:00) Asia, Hebron" },
    { value: "Asia/Ho_Chi_Minh", children: "(GMT+07:00) Asia, Ho Chi Minh" },
    { value: "Asia/Hong_Kong", children: "(GMT+08:00) Asia, Hong Kong" },
    { value: "Asia/Hovd", children: "(GMT+07:00) Asia, Hovd" },
    { value: "Asia/Irkutsk", children: "(GMT+08:00) Asia, Irkutsk" },
    { value: "Asia/Jakarta", children: "(GMT+07:00) Asia, Jakarta" },
    { value: "Asia/Jayapura", children: "(GMT+09:00) Asia, Jayapura" },
    { value: "Asia/Jerusalem", children: "(GMT+03:00) Asia, Jerusalem" },
    { value: "Asia/Kabul", children: "(GMT+04:30) Asia, Kabul" },
    { value: "Asia/Kamchatka", children: "(GMT+12:00) Asia, Kamchatka" },
    { value: "Asia/Karachi", children: "(GMT+05:00) Asia, Karachi" },
    { value: "Asia/Kathmandu", children: "(GMT+05:45) Asia, Kathmandu" },
    { value: "Asia/Khandyga", children: "(GMT+09:00) Asia, Khandyga" },
    { value: "Asia/Kolkata", children: "(GMT+05:30) Asia, Kolkata" },
    { value: "Asia/Krasnoyarsk", children: "(GMT+07:00) Asia, Krasnoyarsk" },
    { value: "Asia/Kuala_Lumpur", children: "(GMT+08:00) Asia, Kuala Lumpur" },
    { value: "Asia/Kuching", children: "(GMT+08:00) Asia, Kuching" },
    { value: "Asia/Kuwait", children: "(GMT+03:00) Asia, Kuwait" },
    { value: "Asia/Macau", children: "(GMT+08:00) Asia, Macau" },
    { value: "Asia/Magadan", children: "(GMT+11:00) Asia, Magadan" },
    { value: "Asia/Makassar", children: "(GMT+08:00) Asia, Makassar" },
    { value: "Asia/Manila", children: "(GMT+08:00) Asia, Manila" },
    { value: "Asia/Muscat", children: "(GMT+04:00) Asia, Muscat" },
    { value: "Asia/Nicosia", children: "(GMT+03:00) Asia, Nicosia" },
    { value: "Asia/Novokuznetsk", children: "(GMT+07:00) Asia, Novokuznetsk" },
    { value: "Asia/Novosibirsk", children: "(GMT+07:00) Asia, Novosibirsk" },
    { value: "Asia/Omsk", children: "(GMT+06:00) Asia, Omsk" },
    { value: "Asia/Oral", children: "(GMT+05:00) Asia, Oral" },
    { value: "Asia/Phnom_Penh", children: "(GMT+07:00) Asia, Phnom Penh" },
    { value: "Asia/Pontianak", children: "(GMT+07:00) Asia, Pontianak" },
    { value: "Asia/Pyongyang", children: "(GMT+08:30) Asia, Pyongyang" },
    { value: "Asia/Qatar", children: "(GMT+03:00) Asia, Qatar" },
    { value: "Asia/Qyzylorda", children: "(GMT+06:00) Asia, Qyzylorda" },
    { value: "Asia/Riyadh", children: "(GMT+03:00) Asia, Riyadh" },
    { value: "Asia/Sakhalin", children: "(GMT+11:00) Asia, Sakhalin" },
    { value: "Asia/Samarkand", children: "(GMT+05:00) Asia, Samarkand" },
    { value: "Asia/Seoul", children: "(GMT+09:00) Asia, Seoul" },
    { value: "Asia/Shanghai", children: "(GMT+08:00) Asia, Shanghai" },
    { value: "Asia/Singapore", children: "(GMT+08:00) Asia, Singapore" },
    { value: "Asia/Srednekolymsk", children: "(GMT+11:00) Asia, Srednekolymsk" },
    { value: "Asia/Taipei", children: "(GMT+08:00) Asia, Taipei" },
    { value: "Asia/Tashkent", children: "(GMT+05:00) Asia, Tashkent" },
    { value: "Asia/Tbilisi", children: "(GMT+04:00) Asia, Tbilisi" },
    { value: "Asia/Tehran", children: "(GMT+04:30) Asia, Tehran" },
    { value: "Asia/Thimphu", children: "(GMT+06:00) Asia, Thimphu" },
    { value: "Asia/Tokyo", children: "(GMT+09:00) Asia, Tokyo" },
    { value: "Asia/Tomsk", children: "(GMT+07:00) Asia, Tomsk" },
    { value: "Asia/Ulaanbaatar", children: "(GMT+08:00) Asia, Ulaanbaatar" },
    { value: "Asia/Urumqi", children: "(GMT+06:00) Asia, Urumqi" },
    { value: "Asia/Ust-Nera", children: "(GMT+10:00) Asia, Ust-Nera" },
    { value: "Asia/Vientiane", children: "(GMT+07:00) Asia, Vientiane" },
    { value: "Asia/Vladivostok", children: "(GMT+10:00) Asia, Vladivostok" },
    { value: "Asia/Yakutsk", children: "(GMT+09:00) Asia, Yakutsk" },
    { value: "Asia/Yangon", children: "(GMT+06:30) Asia, Yangon" },
    { value: "Asia/Yekaterinburg", children: "(GMT+05:00) Asia, Yekaterinburg" },
    { value: "Asia/Yerevan", children: "(GMT+04:00) Asia, Yerevan" },
    { value: "Indian/Chagos", children: "(GMT+06:00) Indian, Chagos" },
    { value: "Indian/Christmas", children: "(GMT+07:00) Indian, Christmas" },
    { value: "Indian/Cocos", children: "(GMT+06:30) Indian, Cocos" },
    { value: "Indian/Comoro", children: "(GMT+03:00) Indian, Comoro" },
    { value: "Indian/Kerguelen", children: "(GMT+05:00) Indian, Kerguelen" },
    { value: "Indian/Mahe", children: "(GMT+04:00) Indian, Mahe" },
    { value: "Indian/Maldives", children: "(GMT+05:00) Indian, Maldives" },
    { value: "Indian/Mauritius", children: "(GMT+04:00) Indian, Mauritius" },
    { value: "Indian/Mayotte", children: "(GMT+03:00) Indian, Mayotte" },
    { value: "Indian/Reunion", children: "(GMT+04:00) Indian, Reunion" },
    { value: "Pacific/Apia", children: "(GMT+13:00) Pacific, Apia" },
    { value: "Pacific/Auckland", children: "(GMT+12:00) Pacific, Auckland" },
    { value: "Pacific/Bougainville", children: "(GMT+11:00) Pacific, Bougainville" },
    { value: "Pacific/Chatham", children: "(GMT+12:45) Pacific, Chatham" },
    { value: "Pacific/Chuuk", children: "(GMT+10:00) Pacific, Chuuk" },
    { value: "Pacific/Easter", children: "(GMT-06:00) Pacific, Easter" },
    { value: "Pacific/Efate", children: "(GMT+11:00) Pacific, Efate" },
    { value: "Pacific/Enderbury", children: "(GMT+13:00) Pacific, Enderbury" },
    { value: "Pacific/Fakaofo", children: "(GMT+13:00) Pacific, Fakaofo" },
    { value: "Pacific/Fiji", children: "(GMT+12:00) Pacific, Fiji" },
    { value: "Pacific/Funafuti", children: "(GMT+12:00) Pacific, Funafuti" },
    { value: "Pacific/Galapagos", children: "(GMT-06:00) Pacific, Galapagos" },
    { value: "Pacific/Gambier", children: "(GMT-09:00) Pacific, Gambier" },
    { value: "Pacific/Guadalcanal", children: "(GMT+11:00) Pacific, Guadalcanal" },
    { value: "Pacific/Guam", children: "(GMT+10:00) Pacific, Guam" },
    { value: "Pacific/Honolulu", children: "(GMT-10:00) Pacific, Honolulu" },
    { value: "Pacific/Kiritimati", children: "(GMT+14:00) Pacific, Kiritimati" },
    { value: "Pacific/Kosrae", children: "(GMT+11:00) Pacific, Kosrae" },
    { value: "Pacific/Kwajalein", children: "(GMT+12:00) Pacific, Kwajalein" },
    { value: "Pacific/Majuro", children: "(GMT+12:00) Pacific, Majuro" },
    { value: "Pacific/Marquesas", children: "(GMT-09:30) Pacific, Marquesas" },
    { value: "Pacific/Midway", children: "(GMT-11:00) Pacific, Midway" },
    { value: "Pacific/Nauru", children: "(GMT+12:00) Pacific, Nauru" },
    { value: "Pacific/Niue", children: "(GMT-11:00) Pacific, Niue" },
    { value: "Pacific/Norfolk", children: "(GMT+11:00) Pacific, Norfolk" },
    { value: "Pacific/Noumea", children: "(GMT+11:00) Pacific, Noumea" },
    { value: "Pacific/Pago_Pago", children: "(GMT-11:00) Pacific, Pago Pago" },
    { value: "Pacific/Palau", children: "(GMT+09:00) Pacific, Palau" },
    { value: "Pacific/Pitcairn", children: "(GMT-08:00) Pacific, Pitcairn" },
    { value: "Pacific/Pohnpei", children: "(GMT+11:00) Pacific, Pohnpei" },
    { value: "Pacific/Port_Moresby", children: "(GMT+10:00) Pacific, Port Moresby" },
    { value: "Pacific/Rarotonga", children: "(GMT-10:00) Pacific, Rarotonga" },
    { value: "Pacific/Saipan", children: "(GMT+10:00) Pacific, Saipan" },
    { value: "Pacific/Tahiti", children: "(GMT-10:00) Pacific, Tahiti" },
    { value: "Pacific/Tarawa", children: "(GMT+12:00) Pacific, Tarawa" },
    { value: "Pacific/Tongatapu", children: "(GMT+13:00) Pacific, Tongatapu" },
    { value: "Pacific/Wake", children: "(GMT+12:00) Pacific, Wake" },
    { value: "Pacific/Wallis", children: "(GMT+12:00) Pacific, Wallis" },
    { value: "Pacific/Yap", children: "(GMT+10:00) Pacific, Yap" },
    { value: "UTC", children: "(GMT) UTC" },
    { value: "Etc/UTC+12", children: "(GMT+12:00) Etc, UTC+12" },
    { value: "Etc/UTC+2", children: "(GMT+02:00) Etc, UTC+2" },
    { value: "Etc/UTC+11", children: "(GMT+11:00) Etc, UTC+11" },
    { value: "Etc/UTC+3", children: "(GMT+03:00) Etc, UTC+3" },
    { value: "Etc/UTC+4", children: "(GMT+04:00) Etc, UTC+4" },
    { value: "Etc/UTC+5", children: "(GMT+05:00) Etc, UTC+5" },
    { value: "Etc/UTC+6", children: "(GMT+06:00) Etc, UTC+6" },
    { value: "Etc/UTC+7", children: "(GMT+07:00) Etc, UTC+7" },
    { value: "Etc/UTC+8", children: "(GMT+08:00) Etc, UTC+8" },
    { value: "Etc/UTC+9", children: "(GMT+09:00) Etc, UTC+9" },
    { value: "Etc/UTC+10", children: "(GMT+10:00) Etc, UTC+10" },
    { value: "Etc/UTC+1", children: "(GMT+01:00) Etc, UTC+1" },
    { value: "Etc/UTC+0", children: "(GMT) Etc, UTC+0" },
    { value: "Etc/UTC-0", children: "(GMT) Etc, UTC-0" },
    { value: "Etc/UTC-1", children: "(GMT-01:00) Etc, UTC-1" },
    { value: "Etc/UTC-11", children: "(GMT-11:00) Etc, UTC-11" },
    { value: "Etc/UTC-12", children: "(GMT-12:00) Etc, UTC-12" },
    { value: "Etc/UTC-2", children: "(GMT-02:00) Etc, UTC-2" },
    { value: "Etc/UTC-3", children: "(GMT-03:00) Etc, UTC-3" },
    { value: "Etc/UTC-4", children: "(GMT-04:00) Etc, UTC-4" },
    { value: "Etc/UTC-5", children: "(GMT-05:00) Etc, UTC-5" },
    { value: "Etc/UTC-6", children: "(GMT-06:00) Etc, UTC-6" },
    { value: "Etc/UTC-7", children: "(GMT-07:00) Etc, UTC-7" },
    { value: "Etc/UTC-8", children: "(GMT-08:00) Etc, UTC-8" },
    { value: "Etc/UTC-9", children: "(GMT-09:00) Etc, UTC-9" },
    { value: "Etc/UTC-10", children: "(GMT-10:00) Etc, UTC-10" },
    { value: "Etc/UTC-13", children: "(GMT-13:00) Etc, UTC-13" },
    { value: "Etc/UTC-14", children: "(GMT-14:00) Etc, UTC-14" },
    { value: "Etc/UTC-15", children: "(GMT-15:00) Etc, UTC-15" },
    { value: "Etc/UTC-16", children: "(GMT-16:00) Etc, UTC-16" },
    { value: "Etc/UTC-17", children: "(GMT-17:00) Etc, UTC-17" },
    { value: "Etc/UTC-18", children: "(GMT-18:00) Etc, UTC-18" },
    { value: "Etc/UTC-19", children: "(GMT-19:00) Etc, UTC-19" },
    { value: "Etc/UTC-20", children: "(GMT-20:00) Etc, UTC-20" },
    { value: "Etc/UTC-21", children: "(GMT-21:00) Etc, UTC-21" },
    { value: "Etc/UTC-22", children: "(GMT-22:00) Etc, UTC-22" },
    { value: "Etc/UTC-23", children: "(GMT-23:00) Etc, UTC-23" },
    { value: "Etc/UTC-24", children: "(GMT-24:00) Etc, UTC-24" },
    { value: "Etc/UTC-25", children: "(GMT-25:00) Etc, UTC-25" },
    { value: "Etc/UTC-26", children: "(GMT-26:00) Etc, UTC-26" },
    { value: "Etc/UTC-27", children: "(GMT-27:00) Etc, UTC-27" },
    { value: "Etc/UTC-28", children: "(GMT-28:00) Etc, UTC-28" },
    { value: "Etc/UTC-29", children: "(GMT-29:00) Etc, UTC-29" },
    { value: "Etc/UTC-30", children: "(GMT-30:00) Etc, UTC-30" },
    { value: "Etc/UTC-31", children: "(GMT-31:00) Etc, UTC-31" },
    { value: "Etc/UTC-32", children: "(GMT-32:00) Etc, UTC-32" },
    { value: "Etc/UTC-33", children: "(GMT-33:00) Etc, UTC-33" },
    { value: "Etc/UTC-34", children: "(GMT-34:00) Etc, UTC-34" },
    { value: "Etc/UTC-35", children: "(GMT-35:00) Etc, UTC-35" },
    { value: "Etc/UTC-36", children: "(GMT-36:00) Etc, UTC-36" },
    { value: "Etc/UTC-37", children: "(GMT-37:00) Etc, UTC-37" },
    { value: "Etc/UTC-38", children: "(GMT-38:00) Etc, UTC-38" },
    { value: "Etc/UTC-39", children: "(GMT-39:00) Etc, UTC-39" },
    { value: "Etc/UTC-40", children: "(GMT-40:00) Etc, UTC-40" },
    { value: "Etc/UTC-41", children: "(GMT-41:00) Etc, UTC-41" },
    { value: "Etc/UTC-42", children: "(GMT-42:00) Etc, UTC-42" },
    { value: "Etc/UTC-43", children: "(GMT-43:00) Etc, UTC-43" },
]

module.exports=timeZoneList;