import React, { useState } from 'react';
import { Modal, ModalVariant, Button, Form, FormGroup, TextInput } from '@patternfly/react-core';
import HelpIcon from '@patternfly/react-icons/dist/esm/icons/help-icon';
import formStyles from '@patternfly/react-styles/css/components/Form/form';
import { emptyCheck, selectmenuoption } from 'src/util/Validate';
import CommonSelectBox from '../CommonEventPages/CommonSelectBox';
import ConfirmBox from '../ConfirmBox/ConfirmBox';
const MigrateDataForm = () => {
    const [isModalOpen, setModalOpen] = useState(false);
    const [patternname, setPatternName] = useState('');
    const [validPatternName, setValidPatternName] = useState('');
    const [selectedSrcIndex, setSelectedSrcIndex] = useState([]);
    const [validSrcIndex, setValidSrcIndex] = useState('')
    const [selectedDstIndex, setSelectedDstIndex] = useState([]);
    const [validDstIndex, setValidDstIndex] = useState('')
    const [confirmModalData, setConfirmBox] = useState({
        show: false,
        msg: '',
        funCall: '',
        funParam: {},
        title: '',
        position: '',
        iconvariant: '',
        submitbtnvariant: '',
    })
    const handleModalToggle = _event => {
        setModalOpen(!isModalOpen);
        setPatternName('')
        setValidPatternName('')
        setSelectedSrcIndex('')
        setValidSrcIndex('')
        setSelectedDstIndex('')
        setValidDstIndex('')
    };
    const fieldChange = (value, type) => {
        switch (type) {
            case "patternname":
                setPatternName(value)
                setValidPatternName(emptyCheck(value))
                break;
        }
    };
    const updateSourceIndBox = (value, type = 'srcindex', index = 'Value') => {
        setSelectedSrcIndex(value)
        setValidSrcIndex(selectmenuoption(value))
    }
    const updateDestIndBox = (value, type = 'dstindex', index = 'Value') => {
        setSelectedDstIndex(value)
        setValidDstIndex(selectmenuoption(value))
    }
    function formValid() {
        let isValid = true;
        let status = emptyCheck(patternname)
        setValidPatternName(status)
        if (status == 'error') {
            isValid = false
        }

        let menuStatus = selectmenuoption(selectedSrcIndex)
        setValidSrcIndex(menuStatus)
        if (menuStatus == 'danger') {
            isValid = false
        }
        menuStatus = selectmenuoption(selectedDstIndex)
        setValidDstIndex(menuStatus)
        if (menuStatus == 'danger') {
            isValid = false
        }
        return isValid
    }
    const finalSubmit = async () => {
        handleModalToggle()
        console.log('api call')
    }

    const submitForm = () => {
        if (formValid()) {
            handleModalToggle()
            setConfirmBox({
                show: true,
                msg: 'Add Migrate Data Form.',
                funCall: finalSubmit,
                title: 'Are you sure? ',
                position: 'default',
                iconvariant: 'info',
                submitbtnvariant: 'primary',
            })
        }

    }
    // const onCancelForm = () => {
    //     setPatternName('')
    //     setValidPatternName('')
    //     setSelectedSrcIndex('')
    //     setValidSrcIndex('')
    //     setSelectedDstIndex('')
    //     setValidDstIndex('')
    //     handleModalToggle()
    // }

    return <React.Fragment>
        <ConfirmBox confirmModal={confirmModalData} />
        <Button variant="primary" onClick={handleModalToggle}>
            Migrate Data
        </Button>
        <Modal variant={ModalVariant.small} title=" Migrate Data" isOpen={isModalOpen} onClose={handleModalToggle}
            actions={[<Button key="create" variant="primary" form="modal-with-form-form" onClick={() => { submitForm() }}>
                Submit
            </Button>, <Button key="cancel" variant="link" onClick={handleModalToggle}>
                Cancel
            </Button>]}>
            <Form id="modal-with-form-form">
                <FormGroup label="Pattern" isRequired fieldId="patternName">
                    <TextInput isRequired type="text" id="patternName" name="patternName" value={patternname} validated={validPatternName} onChange={(event, value) => fieldChange(value, 'patternname')} />
                </FormGroup>
                <FormGroup label="Source Index" isRequired fieldId="modal-with-form-srcind">
                    <CommonSelectBox
                        options={[{ value: 1, children: 'src1' }, { value: 2, children: 'src2' }, { value: 3, children: 'src3' }]}
                        isLoading={false}
                        placeholder="Select Source Index"
                        isDisabled={false}
                        type="srcindex"
                        indexVal={''}
                        updateValue={updateSourceIndBox}
                        selectedOptions={selectedSrcIndex}
                        validStatus={validSrcIndex}
                    />
                </FormGroup>
                <FormGroup label="Destination Index" isRequired fieldId="modal-with-form-dstind">
                    <CommonSelectBox
                        options={[{ value: 1, children: 'dst1' }, { value: 2, children: 'dst2' }, { value: 3, children: 'dst3' }]}
                        isLoading={false}
                        placeholder="Select Destination Index"
                        isDisabled={false}
                        type="dstindex"
                        indexVal={''}
                        updateValue={updateDestIndBox}
                        selectedOptions={selectedDstIndex}
                        validStatus={validDstIndex}
                    />
                </FormGroup>
            </Form>
        </Modal>
    </React.Fragment>;
};
export default MigrateDataForm 