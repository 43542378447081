import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
    Brand,
    Divider,
    Masthead,
    MastheadBrand,
    MastheadContent,
    MastheadMain,
    Menu,
    MenuContent,
    MenuContainer,
    MenuItem,
    MenuList,
    MenuToggle,
    Page,
    Toolbar,
    ToolbarContent,
    ToolbarGroup,
    ToolbarItem,
    Dropdown,
    DropdownList,
    Avatar,
    TextVariants,
    DropdownItem,
    Text,
    Badge,
    MenuSearch,
    MenuSearchInput,
    InputGroup,
    InputGroupItem,
    SearchInput,
    Switch,
    ToggleGroup,
    ToggleGroupItem,
    AboutModal,
    TextContent,
    TextList,
    TextListItem,
    Icon,
    StackItem,
    Truncate,
    Stack,
    FlexItem,
    Flex,
    MastheadToggle,
    PageToggleButton,
    NavExpandable,
    Nav,
    NavList,
    NavItem,
    PageSidebar,
    PageSidebarBody,
    WizardNavItemStatus,
    DrilldownMenu,
    Grid,
    GridItem,
    TextInputGroup,
    TextInputGroupMain,
    TextInputGroupUtilities,
    Button,
    List,

} from '@patternfly/react-core'
import logo from '../Assets/images/layout-header-img.svg'
import brandImg from '../Assets/images/soc-icon.svg'
import AppContent from './AppContent';
import imgAvatar from '../Assets/images/avatar.svg'
import { useLocation, useNavigate, Link } from 'react-router-dom/dist';
import dbApi from 'src/services/DbApi';
import { connect, useDispatch } from 'react-redux';
import { alertBoxData, FilterData, AllGroups, selectedGroup, IncludeNetworkeve, allMappingKeys, usedTheme, SidebarOpenclose } from 'src/Redux/Action';
import ConfirmBox from 'src/Components/ConfirmBox/ConfirmBox';
import TokenService from 'src/services/TokenService';
import { TextSkeleton } from 'src/util/Loadingstate';
import { BarsIcon, EllipsisVIcon, InfoCircleIcon, MoonIcon, SunIcon, TimesIcon } from '@patternfly/react-icons';
import { UpdateFilterData } from 'src/util/FilterHelper';

const navItems = [
    {
        id: "Home",
        to: "#/home",
        itemId: "Home",
        label: "Home"
    },
    {
        title: "Threat Intelligence",
        groupId: "Thint_group",
        items: [
            { id: "security_events", to: "#/security-events", itemId: "Thint_group_item-1", label: "Security Events" },
            { id: "vulnerabilities_event", to: "#/vulnerabilities", itemId: "Thint_group_item-2", label: "Vulnerability Detection" },
            { id: "mitre_event", to: "#/mitre-attack", itemId: "Thint_group_item-3", label: "MITRE ATT&CK" },
            { id: "osquery_event", to: "#/osquery", itemId: "Thint_group_item-4", label: "OsQuery" },
            { id: "tip_event", to: "#/threat-intel-platform-engine", itemId: "Thint_group_item-5", label: 'TIP' },//`TIP${<sup>+</sup>}
        ]
    },
    {
        title: "Endpoint Security",
        groupId: "endsec_group",
        items: [
            { id: "security_config", to: "#/security-configuration-assessment", itemId: "endsec_group_item-6", label: "Security Configuration Assessment (CIS)" },
            { id: "policy_monitoring", to: "#/policy-monitoring", itemId: "endsec_group_item-7", label: "Policy Monitoring & Malware Detection" },
            { id: "integrity_monitoring", to: "#/integrity-monitoring", itemId: "endsec_group_item-8", label: "File Integrity Monitoring" },
            { id: "system_auditing", to: "#/system-auditing", itemId: "endsec_group_item-9", label: "System Auditing" }
        ]
    },
    {
        title: "Security Compliances",
        groupId: "sec_compliances",
        items: [
            { id: "pcidss_event", to: "#/pci-dss", itemId: "sec_compliances_item-7", label: "PCI DSS" },
            { id: "gdpr_event", to: "#/gdpr", itemId: "sec_compliances_item-8", label: "GDPR" },
            { id: "hipaa_event", to: "#/hipaa", itemId: "sec_compliances_item-9", label: "HIPAA" },
            { id: "nist_event", to: "#/nist_800_53", itemId: "sec_compliances_item-10", label: "NIST 800-53" },
            { id: "tsc_event", to: "#/tsc", itemId: "sec_compliances_item-11", label: "TSC" },
            { id: "gpg_event", to: "#/gpg_13", itemId: "sec_compliances_item-12", label: "GPG13" },
        ]
    },

    {
        id: "Network insights",
        to: "#/network-insights",
        itemId: "Network Insights",
        label: "Network Insights"
    },

    {
        id: "User Behaviour Monitoring",
        to: "#/user-behaviour-monitoring",
        itemId: "User Behaviour Monitor",
        label: "User Behaviour Monitor"
    },

    {
        id: "Agents",
        to: "#/agents",
        itemId: "Agents",
        label: "Agents"
    },
    {
        id: "WatchList",
        to: "#/watchList",
        itemId: "WatchList",
        label: "WatchList"
    },
    {
        title: "Server Management",
        groupId: "management_section",
        items: [
            { id: "rule_list", to: "#/rules-list", itemId: "management_section_item-13", label: "Rules" },
            { id: "cdb_list", to: "#/cdb-list", itemId: "management_section_item-14", label: "CDB LISTS" },
            { id: "group_managemet", to: "#/management/groups", itemId: "management_section_item-15", label: "Groups" },
            { id: "index_managemet", to: "#/index-management", itemId: "management_section_item-16", label: "Index Management" },
        ]
    },
    {
        id: "Real Time Dashboard",
        to: "#/real-time-dashboard",
        itemId: "Real Time Dashboard",
        label: "Real Time Dashboard"
    },
    {
        title: "Incident Management",
        groupId: "incident_management_section",
        items: [
            { id: "incidents", to: "#/incident-management", it3emId: "incident_management_item-13", label: "Incidents" },
            { id: "alerts", to: "#/alerts", itemId: "incident_management_item-14", label: "Alerts" },
            { id: "tasks", to: "#/tasks", itemId: "incident_management_item-15", label: "Tasks" },

        ]
    },
];

const AppLayout = (props) => {
    const pathName = useLocation().pathname;
    const segments = pathName.split('/').filter(segment => segment !== '')
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [sidebaropen, setSidebaropen] = useState(true)
    const [mobileView, setMobileView] = useState(window.innerWidth >= 1450 ? false : true);
    const [activeItem, setActiveItem] = useState('Home');
    const [activeGroup, setActiveGroup] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [searchAgent, setSearchAgent] = useState('')
    const [isOpenagent, setIsOpenAgent] = useState(false);
    const [searchGroup, setSearchGroup] = useState('')
    const [isOpengroup, setIsOpenGroup] = useState(false);
    const [keys, setKeys] = useState([])
    const [selectedagent, setSelectedAgent] = useState('Agents')
    const [selectedgroup, setSelectedGroup] = useState('')
    const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
    const [agent, setAgent] = useState([]);
    const [Groups, setGroups] = useState([]);
    const [orgwisegroup, setOrgwisegroup] = useState([])
    const [isloadgroup, setIsloadGroup] = useState(false);
    const [isSelected, setIsSelected] = useState('light-theme');
    const [isaboutModalOpen, setIsAboutModalOpen] = React.useState(false);
    const [isFullKebabDropdownOpen, setIsFullKebabDropdownOpen] = useState(false);
    const [logoutload, setLogoutLoad] = useState(false)
    const [loadagent, setloadagent] = useState(false)
    const [confirmModalData, setConfirmBox] = React.useState({
        show: false,
        msg: '',
        funCall: '',
        funParam: {},
        title: '',
        position: '',
        iconvariant: '',
        submitbtnvariant: ''
    })
    const [isIncludeNw, setIsIncludeNw] = useState(false);

    //Drilldown Menu
    const [selectedOrg, setSelectedOrg] = useState('All')
    const [selected, setSelected] = useState('');
    const searchgroupRef = React.createRef();
    const searchorgRef = React.createRef();
    const [searchgroupInput, setSearchGroupInput] = useState('');
    const [searchOrgInput, setSearchOrgInput] = useState('');
    const [showorgsearch, setShoworgsearch] = useState(true)
    const [isOpenorg, setIsOpenorg] = useState(false);
    const [activeMenu, setActiveMenu] = useState('rootMenu');
    const [menuDrilledIn, setMenuDrilledIn] = useState([]);
    const [drilldownPath, setDrilldownPath] = useState([]);
    const [menuHeights, setMenuHeights] = useState({});
    const [defaultgroupid, setDefaultgroupId] = useState('')
    const toggleRef = useRef(null);
    const menuRef = useRef(null);
    const handleNetworkEvents = (_event, checked) => {
        setIsIncludeNw(checked);
        dispatch(IncludeNetworkeve(checked))
    };
    const handleItemClick = (event, _isSelected) => {
        const id = event.currentTarget.id;
        setIsSelected(id);
    };
    useEffect(() => {
        const htmlElement = document.getElementsByTagName('html')[0];
        if (isSelected === 'dark-theme') {
            htmlElement.classList.add('pf-v5-theme-dark');
            dispatch(usedTheme('dark-theme'))
        } else {
            htmlElement.classList.remove('pf-v5-theme-dark');
            dispatch(usedTheme('light-theme'))
        }
    }, [isSelected]);

    const isLocationValid = segments.includes('individual-agent-dashboard');
    //get agents
    const getAgents = async (groupName) => {
        try {
            setloadagent(true)
            let result = filteredGroups.find(obj => obj.groupName == groupName)
            let groupId = typeof result != "undefined" ? result.groupId : defaultgroupid;
            let param = { groupId: groupId }
            // let allAgents = await dbApi.getApi('Opensearch/Health_Agent_List_ID');
            let allAgents = await dbApi.postApi('user/get_user_agents', param)
            // console.log('agent', allAgents);
            let agent = [];
            if (allAgents.data.length > 0) {
                agent = allAgents.data;
            }
            setAgent(agent);
            setloadagent(false)
        } catch (error) {
            console.log(error);
            setloadagent(false)
        }
    };
    // user/get_user_groups
    const getAllGroups = async () => {
        try {
            setIsloadGroup(true)
            let res = await dbApi.getApi('user/get_user_groups');
            console.log('groups', res);
            let groups = []
            let OrganizationGroup = []

            if (res.data.length > 0) {
                groups = res.data
                OrganizationGroup = res.orgWiseGroup
            }
            let filteredGroups = res.data.filter(group => group.group_status == 1);
            setGroups(filteredGroups)
            if (localStorage.getItem("role") == 'Admin') {
                setDefaultgroupId(res.defaultGroupId)
            }
            else {
                setDefaultgroupId('')
            }
            setOrgwisegroup(OrganizationGroup)
            dispatch(AllGroups(groups))
            setIsloadGroup(false)
        } catch (error) {
            console.log(error);
            setIsloadGroup(false)
        }
    };
    // post selectedgroupname
    const setGroupName = async (groupName) => {
        try {
            let result = filteredGroups.find(obj => obj.groupName == groupName)
            // if (typeof result == "undefined") {
            //     return true
            // }
            let groupId = typeof result != "undefined" ? result.groupId : defaultgroupid;
            //setIsloadGroup(true)
            // let res = await dbApi.postApi('user/update_selected_group', { groupId: result.groupId });
            let res = await dbApi.postApi('user/update_selected_group', { groupId: groupId });
            console.log(res);

        } catch (error) {
            console.log(error);
        }
    };

    const getEventfieldKeys = async () => {
        try {
            let res = await dbApi.getApi('elasticsearch/Get_Key')
            console.log(res)
            let getkeys = res.Get_Key
            let allkeys = []
            if (getkeys.length > 0) {
                allkeys = getkeys
            }
            setKeys(allkeys)
            dispatch(allMappingKeys(allkeys));
        } catch (error) {
            console.log(error)
        }
    }

    // useEffect(() => {
    //     if (isLocationValid) {
    //         getAgents();
    //     } else {
    //         getAllGroups();
    //     }
    // }, [isLocationValid]);
    useEffect(() => {
        getEventfieldKeys()
        getAgents('');
        getAllGroups();
    }, [props?.callHeaderAgentgroup])


    //for header toolbar pagename
    const staticpathToLabelMap = {
        '/home': 'Home',
        '/security-events': 'Security Events',
        '/vulnerabilities': 'Vulnerability Detection',
        '/mitre-attack': 'MITRE ATT&CK',
        '/osquery': 'OsQuery',
        '/threat-intel-platform-engine': 'Threat Intelligence Platform engine',
        '/security-configuration-assessment': 'Security Configuration Assessment (CIS)',
        '/integrity-monitoring': 'File Integrity Monitoring',
        '/policy-monitoring': 'Policy Monitoring & Malware Detection',
        '/system-auditing': 'System Auditing',
        '/pci-dss': 'PCI DSS',
        '/gdpr': 'GDPR',
        '/hipaa': 'HIPAA',
        '/nist_800_53': 'NIST 800-53',
        '/tsc': 'TSC',
        '/gpg_13': 'GPG13',
        '/network-insights': 'Network insights',
        '/user-behaviour-monitoring': '"User Behaviour Monitoring',
        '/agents': 'Agents',
        '/watchList': 'WatchList',
        '/rules-list': 'Rules',
        '/cdb-list': 'CDB LISTS',
        '/index-management': 'Index Management',
        '/management/groups': 'Groups',
        '/real-time-dashboard': 'Real Time Dashboard',
        '/user-profile': 'User Profile',
        '/organizations-management': 'Organizations Management',
        '/onboardingform': 'OnBoarding',
        '/user-management': 'User Management',
        '/incident-management': 'Incident Management'


    };
    const dynamicPathToLabelMap = {
        '/individual-agent-dashboard/:agentname/:agentId': (params) => `Agent: ${params.agentname}`,
        '/management/groups-agent/:groupname': (params) => `Groups`,
        '/management/groups-config/:groupname/:filename/:id': (params) => `Groups`,
        '/management/manage-group/:groupname': (params) => `Groups`,
        '/user-profile/:userid/:username': (params) => `User Profile: ${params.username}`
    };
    const getLabelForPath = (path, staticMap, dynamicMap) => {
        // Check static paths first
        if (staticMap[path]) {
            return staticMap[path];
        }
        // Check dynamic paths
        for (const [pattern, getLabel] of Object.entries(dynamicMap)) {
            const regexPattern = new RegExp(`^${pattern.replace(/:\w+/g, '([^/]+)')}$`);
            const match = path.match(regexPattern);
            if (match) {
                const paramNames = pattern.match(/:(\w+)/g).map(name => name.substring(1));
                const params = paramNames.reduce((acc, name, index) => {
                    acc[name] = match[index + 1];
                    return acc;
                }, {});
                return getLabel(params);
            }
        }

        return 'Home'; // Default label if no match found
    };

    // const currentLabel = pathToLabelMap[location.pathname] || 'Home';
    const currentLabel = getLabelForPath(pathName, staticpathToLabelMap, dynamicPathToLabelMap);


    const onNavSelect = (_event, result) => {
        const parentGroup = navItems.find(group => group.items && group.items.some(item => item.itemId === result.itemId));
        if (parentGroup) {
            setActiveGroup(parentGroup.groupId);
        }
        setActiveItem(result.itemId);
    };
    const onNavExpand = (event, result, id) => {
        if (id == activeGroup) {
            setActiveGroup('');
        }
        else {
            setActiveGroup(id);
        }
    }
    const onDropdownToggle = useCallback(() => {
        setIsDropdownOpen(prevState => !prevState);
    }, []);

    const onDropdownSelect = useCallback(() => {
        setIsDropdownOpen(false);
    }, []);

    const onFullKebabToggle = () => {
        setIsFullKebabDropdownOpen(!isFullKebabDropdownOpen);
    };

    const onFullKebabSelect = () => {
        setIsFullKebabDropdownOpen(false);
    };
    const onToggleClick = () => {
        setIsOpen(!isOpen);
        setIsOpenAgent(!isOpenagent);

    }
    const onToogleGroup = () => {
        setIsOpenGroup(!isOpengroup)
    }

    // console.log(props?.filterformdata)
    const onSelectAgent = (_event, value) => {
        setSelectedAgent(value)
        setIsOpenAgent(false)
        dispatch(FilterData(UpdateFilterData(props.filterformdata, 'agent.name', 'is', value)))
    };
    const onSelectGroup = async (_event, value, id) => {
        let groupId = ''
        let result = []
        if (value !== 'default') {
            result = filteredGroups.find(obj => obj.groupName == value)
            groupId = result.groupId
        }
        // let groupId = typeof result.groupId != "undefined" ? result.groupId : '4';
        setSelectedGroup(value)
        setIsOpenGroup(false)
        await setGroupName(value)
        await getAgents(value)
        setTimeout(() => {
            dispatch(FilterData([]));
            dispatch(selectedGroup(groupId));//remove this after db 
        }, 500);
    };


    const finalLogout = async () => {
        setLogoutLoad(true)
        let res = await dbApi.getApi('login/user-logout')
        if (!res.error) {
            if (res.message != "Unauthorized") {
                dispatch(alertBoxData([{ title: res.message, variant: "success" }]));
                TokenService.removeUser()
                navigate('/')
                return true
            }
            dispatch(alertBoxData([{ title: res.message, variant: "danger" }]));
        }
        setLogoutLoad(false)
        dispatch(alertBoxData([{ title: 'Logout failed. Please check your network connection and try again.', variant: "danger" }]));
    }

    const logout = () => {
        setConfirmBox({
            show: true,
            msg: `${TokenService.getUserName()}, are you sure you want to logout?`,
            title: 'Logout',
            funCall: finalLogout,
            funParam: {},
            position: 'top',
            iconvariant: 'warning',
            submitbtnvariant: 'primary'
        })
    }

    const onPageResize = (_event, windowSize) => {
        if (windowSize >= 1450) {
            setMobileView(false);
        } else {
            setMobileView(true);
        }
    };

    const renderNavItems = (items) => {
        return items.map((item) => {

            if (localStorage.getItem("role") == 'User' && (item.groupId === 'management_section' || item.groupId === 'incident_management_section'||item.itemId=='Agents')) {
                return null; // Do not render these sections for 'User' role
            }
            if (item.groupId) {
                return (
                    <NavExpandable
                        key={item.groupId}
                        title={item.title}
                        groupId={item.groupId}
                        isExpanded={activeGroup == item.groupId}
                        isActive={activeGroup == item.groupId}
                        onExpand={(event, val) => onNavExpand(event, val, item.groupId)}
                    >
                        {renderNavItems(item.items)}
                    </NavExpandable>
                );
            }
            return (
                <NavItem
                    key={item.itemId}
                    id={item.id}
                    to={item.to}
                    itemId={item.itemId}
                    isActive={activeItem === item.itemId}
                >
                    {item.label}
                </NavItem>
            );
        });
    };

    const PageNav = <Nav onSelect={onNavSelect} aria-label="Expandable third level global">
        <NavList>
            {renderNavItems(navItems)}
        </NavList>
    </Nav>

    const sidebar = (
        <PageSidebar isSidebarOpen={!mobileView || true}>
            <PageSidebarBody>{PageNav}</PageSidebarBody>
        </PageSidebar>
    );

    const locationname = <React.Fragment>
        <ToolbarItem align={{ default: 'alignRight' }}>
            {/* {segments.map((segment, index) => (
                <Text component={TextVariants.h2} key={segment}>
                    {segment.includes("individual-agent-dashboard") ? "Agent : " : `${index > 1 ? " | " : "  "} ${segment}`}
                    {index === segments.length - 1 ? '' : ' '}
                </Text>
            ))} */}
            {/* {selectedLabel} */}
            {currentLabel}
        </ToolbarItem>
    </React.Fragment>
    const toggleAboutModal = (_event) => {
        setIsAboutModalOpen(!isaboutModalOpen);
    };

    const userDropdownItems = (
        <>
            <DropdownItem key="group 2 profile"><Link to={'/user-profile/' + TokenService.getUserId() + '/' + TokenService.getUserName()}  >My profile</Link></DropdownItem>
            {localStorage.getItem("role") == 'Admin' && <DropdownItem key="group 2 orgList"><Link to={'/organizations-management'} >Organization management</Link></DropdownItem>}
            {localStorage.getItem("role") == 'Admin' && <DropdownItem key="group 2 onboarding"><Link to={'/onboardingform'} >On-Boarding</Link></DropdownItem>}
            {localStorage.getItem("role") == 'Admin' && <DropdownItem key="group 2 user"><Link to='/user-management'>User management</Link></DropdownItem>}
            <DropdownItem key="group 2 logout" onClick={() => logout()} isDanger>Logout</DropdownItem>
            <Divider />
            <DropdownItem key="about" onClick={toggleAboutModal}>About</DropdownItem>
        </>
    );
    const AboutModalBox = <React.Fragment>
        <AboutModal
            isOpen={isaboutModalOpen}
            onClose={(e) => toggleAboutModal(e)}
            //   trademark="Copyright © 2024 CyberRange. All Rights Reserved."
            brandImageSrc={brandImg}
            brandImageAlt="SecSphere-SOC"
            // backgroundImageSrc="/assets/images/pfbg-icon.svg"
            productName="SecSphere-SOC"
        >
            <TextContent>
                <TextList component="dl">
                    <TextListItem component="dt">SOC Version</TextListItem>
                    <TextListItem component="dd">1.4.3</TextListItem>
                    <TextListItem component="dt">User Name</TextListItem>
                    <TextListItem component="dd">{TokenService.getUserName()}</TextListItem>
                    <TextListItem component="dt">User Role</TextListItem>
                    <TextListItem component="dd">{TokenService.getUserRole()}</TextListItem>
                    <br />
                    <TextListItem component="dt"><Icon><InfoCircleIcon /></Icon> Build info</TextListItem>
                    <TextListItem component="dt">Build no.</TextListItem>
                    <TextListItem component="dd">240909-23</TextListItem>
                    <TextListItem component="dt">Last Build created on</TextListItem>
                    <TextListItem component="dd">Friday, September 09, 2024 at 06:00:00 PM GMT+5:30 </TextListItem>
                </TextList>
            </TextContent>
        </AboutModal>
    </React.Fragment>

    //change the cards of severity in all pages and poc add

    //on Agent Search
    const onSearchAgent = (value) => {
        setSearchAgent(value);
    };

    const filteredAgents = agent.filter(item =>
        item.agent_name.toLowerCase().includes(searchAgent.toLowerCase())
    );
    const onSearchGroup = (value) => {
        setSearchGroup(value)
    }


    const filteredGroups = Groups.filter(item =>
        item.groupName.toLowerCase().includes(searchGroup.toLowerCase())
    );
    //badge style 
    const renderStatuscolumn = (status) => {
        let badgeStyle = {};
        if (status === 1) {
            badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#3E8635" };
            return <Badge style={badgeStyle} >Active</Badge>
        }
        else if (status === 0) {
            badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#C9190B" };
            return <Badge style={badgeStyle} >Disconnected</Badge>
        }
        else if (status === 2) {
            badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#F0AB00" };
            return <Badge style={badgeStyle} >Pending</Badge>
        }
        else if (status === 3) {
            badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#6A6E73" };
            return <Badge style={badgeStyle} >Never connected</Badge>
        }
    };


    const OrganizationgroupMenu = useMemo(() => {
        const onDrillToggleClick = () => {
            setIsOpenorg(!isOpenorg);
            setMenuDrilledIn([]);
            setDrilldownPath([]);
            setActiveMenu('');
        };
        const drillIn = (_event, fromMenuId, toMenuId, pathId) => {
            setMenuDrilledIn([...menuDrilledIn, fromMenuId]);
            setDrilldownPath([...drilldownPath, pathId]);
            setActiveMenu(toMenuId);
        };
        const drillOut = (_event, toMenuId) => {
            setMenuDrilledIn(menuDrilledIn.slice(0, menuDrilledIn.length - 1));
            setDrilldownPath(drilldownPath.slice(0, drilldownPath.length - 1));
            setActiveMenu(toMenuId);
        };
        const setHeight = (menuId, height) => {
            if (!menuHeights[menuId] || menuId !== 'rootMenu' && menuHeights[menuId] !== height) {
                setMenuHeights({
                    ...menuHeights,
                    [menuId]: height
                });
            }
        };
        const handleSearchInput = (value, type) => {
            switch (type) {
                case 'group':
                    setSearchGroupInput(value);
                    searchgroupRef?.current?.focus();
                    break;
                case 'organization':
                    setSearchOrgInput(value);
                    searchorgRef?.current?.focus();
                    break;
            }

        };
        const toggleStyle = selectedOrg == 'All' ? { width: '100px' } : {};
        const toggle = <MenuToggle ref={toggleRef} onClick={onDrillToggleClick} isExpanded={isOpenorg} style={toggleStyle}   >
            {selectedgroup !== 'default' ?
                selectedOrg !== '' && selectedgroup !== '' ? `${selectedOrg}: ${selectedgroup}` : selectedOrg
                :
                'All'
            }
        </MenuToggle>;
        const menu = <Menu id="rootMenu" containsDrilldown drilldownItemPath={drilldownPath} style={{ width: '330px' }}
            drilledInMenus={menuDrilledIn} activeMenu={activeMenu} onDrillIn={drillIn}
            onDrillOut={drillOut} onGetMenuHeight={setHeight} ref={menuRef}>
            <MenuContent menuHeight={`${menuHeights[activeMenu]}px`}>
                <MenuList>
                    {showorgsearch && <MenuSearch>
                        <MenuSearchInput>
                            <SearchInput ref={searchorgRef} value={searchOrgInput}
                                aria-label="Filter menu items" onChange={(_event, value) => handleSearchInput(value, 'organization')} />
                        </MenuSearchInput>
                    </MenuSearch>}
                    {isloadgroup ?
                        <List isPlain className='pf-v5-u-m-md'>
                            <div>
                                <TextSkeleton /><br />
                                <TextSkeleton /><br />
                                <TextSkeleton /><br />
                                <TextSkeleton /><br />
                            </div>
                        </List> :
                        (<>
                            <MenuItem key={'allgroups'} itemId={`allgroups`} onClick={(_event) => { onSelectGroup(_event, 'default'); setIsOpenorg(!isOpenorg); setSelectedOrg('All') }}>All</MenuItem>
                            {orgwisegroup.filter(opt => !searchOrgInput || opt.orgName.toLowerCase()
                                .includes(searchOrgInput.toString()
                                    .toLowerCase())).map((org, orgIndex) => {
                                        const mappedgrpopt =
                                            org.groups.filter(opt => !searchgroupInput || opt.groupName.toLowerCase()
                                                .includes(searchgroupInput.toString()
                                                    .toLowerCase())).map((opt, index) => <MenuItem key={opt.groupName} itemId={opt.groupId}
                                                        onClick={(_event) => { onSelectGroup(_event, opt.groupName); setIsOpenorg(!isOpenorg) }}>
                                                        {opt.groupName} ({opt.agents.length})
                                                    </MenuItem>);
                                        if (searchgroupInput && mappedgrpopt.length === 0) {
                                            mappedgrpopt.push(<MenuItem isDisabled key="no result">
                                                No results found
                                            </MenuItem>);
                                        }
                                        return (<>
                                            <MenuItem
                                                key={org.orgName}
                                                itemId={`group:${org.orgName}`}
                                                direction="down"
                                                onClick={() => { setSelectedOrg(org.orgName); setSelectedGroup(''); setShoworgsearch(false) }}
                                                drilldownMenu={
                                                    <DrilldownMenu id={`drilldownMenu${org.org_id}`}>
                                                        <MenuItem
                                                            itemId={`group:${org.orgName}_breadcrumb`}
                                                            onClick={() => setShoworgsearch(true)}
                                                            direction="up"
                                                        >
                                                            {org.orgName}
                                                        </MenuItem>
                                                        <Divider component="li" />
                                                        <MenuSearch>
                                                            <MenuSearchInput>
                                                                <SearchInput ref={searchgroupRef} value={searchgroupInput}
                                                                    aria-label="Filter menu items" onChange={(_event, value) => handleSearchInput(value, 'group')} />
                                                            </MenuSearchInput>
                                                        </MenuSearch>
                                                        <Divider component="li" />
                                                        {mappedgrpopt}
                                                    </DrilldownMenu>
                                                }
                                            >
                                                {org.orgName}
                                            </MenuItem>
                                        </>)
                                    })}
                        </>)
                    }
                </MenuList>
            </MenuContent>
        </Menu>
        return (
            <MenuContainer isOpen={isOpenorg}
                onOpenChange={isOpen => setIsOpenorg(isOpen)}
                menu={menu} menuRef={menuRef} toggle={toggle} toggleRef={toggleRef} />
        )
    }, [isOpenorg, activeMenu, drilldownPath, menuDrilledIn, menuHeights,
        toggleRef, menuRef, searchgroupRef, searchorgRef, searchgroupInput, orgwisegroup])

    const handleClearAgentFilter = () => {
        const updatedFilters = props.filterformdata.filter(filter => filter.fields !== 'agent.name');
        setSelectedAgent('Agents');
        dispatch(FilterData(updatedFilters));
    };

    const Agentdropdown = <React.Fragment>
        <Dropdown isOpen={isOpenagent}
            maxMenuHeight='600px'
            onSelect={onSelectAgent}
            popperProps={{ position: 'end' }}
            onOpenChange={isOpenagent => setIsOpenAgent(isOpenagent)}
            toggle={toggleRef => <MenuToggle ref={toggleRef}

                onClick={onToggleClick} isExpanded={!isOpenagent}>
                {/* {selectedagent} */}
                <TextInputGroup isPlain style={{ marginTop: '-10px', marginBottom: '-9.5px' }}>
                    <TextInputGroupMain value={selectedagent} onClick={onToggleClick}
                        id="create-typeahead-select-input" autoComplete="off"
                        placeholder="select" role="combobox" isExpanded={isOpenagent} aria-controls="select-create-typeahead-listbox" />

                    {selectedagent !== 'Agents' && <TextInputGroupUtilities>
                        {!isLocationValid ?
                            !!selectedagent && <Button variant="plain"
                                onClick={handleClearAgentFilter}
                                aria-label="Clear input value">
                                <TimesIcon aria-hidden />
                            </Button>
                            : ''
                        }
                    </TextInputGroupUtilities>}
                </TextInputGroup>
            </MenuToggle>} ouiaId="BasicDropdown" shouldFocusToggleOnSelect>
            <MenuSearch>
                <MenuSearchInput>
                    <InputGroup>
                        <InputGroupItem isFill>
                            <SearchInput placeholder="Search..."
                                value={searchAgent}
                                onChange={(_event, value) => onSearchAgent(value)}
                            />
                        </InputGroupItem>
                    </InputGroup>
                </MenuSearchInput>
            </MenuSearch>
            <DropdownList>
                {loadagent ?
                    <List isPlain className='pf-v5-u-m-md'>
                        <div>
                            <TextSkeleton /><br />
                            <TextSkeleton /><br />
                            <TextSkeleton /><br />
                            <TextSkeleton /><br />
                        </div>
                    </List> :
                    filteredAgents.map((item, index) => (
                        <DropdownItem value={item.agent_name} key={index} >
                            {isLocationValid ?
                                <Link className='pf-v5-u-color-100' to={`/individual-agent-dashboard/${item.agent_name}/${item.agent_id}`} >
                                    {item.agent_name} {renderStatuscolumn(item.agent_status)}</Link>
                                :
                                <> {item.agent_name} {renderStatuscolumn(item.agent_status)}</>
                            }
                        </DropdownItem>
                    ))
                }
            </DropdownList>
        </Dropdown>
    </React.Fragment>

    const headerToolbar = <React.Fragment>
        <Toolbar id="fill-toolbar" isFullHeight isStatic>
            <ToolbarContent>
                <ToolbarGroup variant="icon-button-group">{locationname}</ToolbarGroup>
                {/* Toolbar for agent and group filters  */}
                <ToolbarGroup align={{ default: 'alignRight' }} spacer={{ default: 'spacerSm' }}>
                    <ToolbarItem > {OrganizationgroupMenu} </ToolbarItem>
                    <ToolbarItem > {Agentdropdown} </ToolbarItem>
                </ToolbarGroup>
                {/* Group of theme and profile dropdown  */}
                <ToolbarGroup spacer={{ default: 'spacerSm' }} align={{ md: 'alignLeft' }}>
                    <ToolbarItem >
                        <ToggleGroup aria-label="Dark theme toggle group" >
                            <ToggleGroupItem
                                icon={<SunIcon />}
                                aria-label='light'
                                buttonId="light-theme"
                                isSelected={isSelected === 'light-theme'}
                                onChange={handleItemClick}
                            />
                            <ToggleGroupItem
                                icon={<MoonIcon />}
                                aria-label='dark'
                                buttonId="dark-theme"
                                isSelected={isSelected === 'dark-theme'}
                                onChange={handleItemClick}
                            />
                        </ToggleGroup>
                    </ToolbarItem>
                    {/* userdropdown on mobile screen */}
                    <ToolbarItem visibility={{ md: 'hidden' }}>
                        <Dropdown
                            isOpen={isFullKebabDropdownOpen}
                            onSelect={onFullKebabSelect}
                            popperProps={{ position: 'right' }}
                            onOpenChange={isOpen => setIsFullKebabDropdownOpen(isOpen)}
                            toggle={(toggleRef) => (
                                <MenuToggle
                                    ref={toggleRef}
                                    isExpanded={isFullKebabDropdownOpen}
                                    onClick={onFullKebabToggle}
                                    icon={<Avatar src={imgAvatar} alt="avatar" />}
                                    variant="plain"
                                    aria-label="Toolbar menu"
                                >
                                    <EllipsisVIcon aria-hidden="true" />
                                </MenuToggle>
                            )}
                        >
                            <DropdownList>
                                <DropdownItem key='image' description={TokenService.getUserRole()}>
                                    <Flex spaceItems={{ default: 'spaceItemsXs' }} alignItems={{ default: 'alignItemsCenter' }}>
                                        <FlexItem> <Avatar src={imgAvatar} alt="user" border='light' /></FlexItem>
                                        <FlexItem>{TokenService.getUserName()}</FlexItem>
                                    </Flex>
                                </DropdownItem>
                                <Divider />
                                {userDropdownItems}</DropdownList>
                        </Dropdown>
                    </ToolbarItem>
                    {/* userdropdown on desktop screen */}
                    <ToolbarItem visibility={{ default: 'hidden', md: 'visible' }}>
                        <Dropdown
                            isOpen={isDropdownOpen}
                            onSelect={onDropdownSelect}
                            popperProps={{ position: 'right' }}
                            onOpenChange={isOpen => setIsDropdownOpen(isOpen)}
                            toggle={(toggleRef) => (
                                <MenuToggle
                                    ref={toggleRef}
                                    isExpanded={isDropdownOpen}
                                    onClick={onDropdownToggle}
                                    icon={<Avatar src={imgAvatar} alt="avatar" />}
                                    isFullHeight
                                >
                                    <Stack>
                                        <StackItem><Truncate content={TokenService.getUserName()} /></StackItem>
                                        <StackItem> <small>({TokenService.getUserRole()})</small> </StackItem>
                                    </Stack>
                                </MenuToggle>
                            )}
                        >
                            <DropdownList>{userDropdownItems}</DropdownList>
                        </Dropdown>
                    </ToolbarItem>
                </ToolbarGroup>
            </ToolbarContent>
        </Toolbar>
    </React.Fragment>;

    const masthead = <React.Fragment>
        <Masthead id="stack-inline-masthead" display={{ lg: 'inline', '2xl': 'inline', md: 'inline', sm: 'stack' }} className='soc-masthead'>
            <MastheadToggle>
                <PageToggleButton variant="plain" aria-label="Global navigation">
                    <Icon onClick={() => { setSidebaropen(!sidebaropen); dispatch(SidebarOpenclose(sidebaropen)) }}> <BarsIcon /></Icon>
                </PageToggleButton>
            </MastheadToggle>
            <MastheadMain>
                <MastheadBrand component="a">
                    <Brand src={logo} alt="Skyvirt Logo" heights={{ default: '40px' }}></Brand>
                </MastheadBrand>
            </MastheadMain>
            <MastheadContent> {headerToolbar}</MastheadContent>
        </Masthead>
    </React.Fragment>;

    return <React.Fragment>
        <ConfirmBox confirmModal={confirmModalData} />
        {AboutModalBox}
        <Page
            header={masthead}
            sidebar={sidebar}
            isManagedSidebar
            onPageResize={(event, resizeObject) => onPageResize(event, resizeObject)}
        >
            <AppContent></AppContent>
        </Page>
    </React.Fragment>
}
const mapStateToProps = (state) => ({
    filterformdata: state.FilterData,
    callHeaderAgentgroup: state.Headergroupagent
});

export default connect(
    mapStateToProps,
    null,
    null,
    { forwardRef: true }
)(memo(AppLayout))

//Dropdown previous
{/* <Dropdown isOpen={isOpengroup}
                                onSelect={(_event, value) => onSelectGroup(_event, value)}
                                isScrollable
                                popperProps={{ position: 'end' }}
                                onOpenChange={isOpengroup => setIsOpenGroup(isOpengroup)}
                                toggle={toggleRef => <MenuToggle ref={toggleRef}
                                    onClick={onToogleGroup} isExpanded={!isOpengroup}>
                                    {selectedgroup}
                                </MenuToggle>} ouiaId="groupdropdown" shouldFocusToggleOnSelect>
                                <MenuSearch>
                                    <MenuSearchInput>
                                        <InputGroup>
                                            <InputGroupItem isFill>
                                                <SearchInput placeholder="Search..."
                                                    value={searchGroup}
                                                    onChange={(_event, value) => onSearchGroup(value)}
                                              
                                                />
                                            </InputGroupItem>
                                        </InputGroup>
                                    </MenuSearchInput>
                                </MenuSearch>
                                <DropdownList>
                                    {filteredGroups.map((item, index) => (
                                        <DropdownItem value={item.groupName} key={index}>
                                            {isloadgroup ? <>
                                                <TextSkeleton /><br />
                                                <TextSkeleton /><br />
                                                <TextSkeleton /><br />
                                            </>
                                                :
                                                `${item.groupName} (${item.agents.length})`
                                            }

                                        </DropdownItem>
                                    ))}

                                </DropdownList>
                            </Dropdown> */}