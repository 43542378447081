import React, { useState, useEffect, useRef } from 'react';
import { CodeEditor } from '@patternfly/react-code-editor';
import {
    Button,
    Flex,
    FlexItem,
    Card,
    CardBody,
    CardHeader,
    Text,
    TextContent,
    TextVariants,
    Icon,
    Tooltip,
    Spinner,
    PageSection,
    PageSectionVariants,
    TextArea
} from '@patternfly/react-core';
import {
    CommentAltIcon,
    EyeIcon,
    SyncAltIcon,
    UserCircleIcon,
    EditIcon,
    TrashIcon,
    TimesIcon
} from '@patternfly/react-icons';
import dbApi from 'src/services/DbApi';
import { useParams } from 'react-router-dom';
import { alertBoxData } from 'src/Redux/Action';
import { useDispatch } from 'react-redux';
import ConfirmBox from 'src/Components/ConfirmBox/ConfirmBox';


const IncidentCommentModal = () => {
    const { id } = useParams();
    const editorContainerRef = useRef(null);
    const messagesEndRef = useRef(null);

    const dispatch = useDispatch();
    const [editorContent, setEditorContent] = useState('');
    const [comments, setComments] = useState([]);
    const [editIndex, setEditIndex] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isPrimaryLoading, setIsPrimaryLoading] = useState(false);
    const [startLimit, setStartLimit] = useState(0); // Pagination state
    const [hasMoreComments, setHasMoreComments] = useState(true);
    const [scrollComment, setScrollComment] = useState(null);
    const [confirmModalData, setConfirmBox] = useState({
        show: false,
        msg: '',
        funCall: '',
        funParam: {},
        title: '',
        position: '',
        iconvariant: '',
        submitbtnvariant: '',
    });

    useEffect(() => {
        fetchComments();
    }, []);

    const scrollToBottom = () => {
        const scrollHeight = messagesEndRef.current.scrollHeight;
        const height = messagesEndRef.current.clientHeight;
        const maxScrollTop = scrollHeight - height;
        messagesEndRef.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }

    useEffect(() => {
        if (scrollComment != null) {
            scrollToBottom();
        }
    }, [scrollComment])

    const fetchComments = async () => {
        const params = {
            sourceId: id,
            sourceType: 1,
            startLimit: 0
        };
        try {
            setIsLoading(true);
            const res = await dbApi.postApi('incident_management/get_comment', params);
            console.log('Fetched Comments Response:', res);
            let comments = []
            if (res && res.data) {
                comments = res.data
                setComments(comments);
                setScrollComment(Date.now());
                console.warn('No data returned from the API');
            }
        } catch (error) {
            console.error('Error fetching comments:', error);
        }
        setIsLoading(false);
    };


    const onEditorDidMount = (editor, monaco) => {
        editor.layout();
        editor.focus();
        monaco.editor.getModels()[0].updateOptions({ tabSize: 5 });
    };

    const onChange = value => {
        setEditorContent(value);
    };


    const updateComment = async () => {
        if (editIndex === null || comments.length === 0) {
            console.error('Edit index is null or comments array is empty');
            return;
        }

        const commentUniqueId = comments[editIndex]?.unique_id;
        if (!commentUniqueId) {
            console.error('Comment unique ID is missing');
            return;
        }

        const updateData = {
            commentUniqueId,
            comment: editorContent
        };

        try {
            setIsPrimaryLoading(true);
            const res = await dbApi.putApi('incident_management/update_comment', updateData);
            console.log('update_comment', res);

            if (!res.error) {
                const updatedComments = comments.map(comment =>
                    comment.unique_id === commentUniqueId ? { ...comment, comment: editorContent } : comment
                );
                console.log('--->updated value', updatedComments)
                setComments(updatedComments);
                dispatch(alertBoxData([{ title: res.message, variant: 'success' }]));
                await fetchComments();
            } else {
                dispatch(alertBoxData([{ title: res.message, variant: 'danger' }]));
            }
        } catch (error) {
            console.error('Error updating comment:', error);
            dispatch(alertBoxData([{ title: 'Error updating comment', variant: 'danger' }]));
        }
        setEditorContent('');
        setEditIndex(null);
        setIsPrimaryLoading(false);
    };

    const handleCommentClick = async () => {
        if (editIndex !== null) {
            // Update existing comment
            const commentUniqueId = comments[editIndex].unique_id;
            console.log('comment with ID:', commentUniqueId);
            await updateComment(commentUniqueId);

        } else {
            // Add new comment
            const commentData = {
                sourceId: id,
                sourceType: '1',
                comment: editorContent
            };

            try {
                setIsLoading(true);
                const res = await dbApi.postApi('incident_management/add_comments', commentData);
                console.log('Add Comment Response:', res);

                if (res && res.data) {
                    const newComment = {
                        comment: editorContent, // Use 'comment' to match the property used in rendering
                        last_datetime: new Date().toLocaleString(),
                        unique_id: res.data.unique_id // Use the ID returned by the API
                    };
                    setComments(prevComments => [...prevComments, newComment]);
                    fetchComments();
                    console.log('------------>>> editorContent', editorContent)
                } else {
                    console.warn('No data returned from the API');
                }
            } catch (error) {
                console.error('Error adding comment:', error);
            }
            setEditorContent('');
            setEditIndex(null);
            setIsLoading(false);

        }
    };


    // const handleEditClick = index => {
    //     setEditorContent(comments[index].comment);
    //     setEditIndex(index);
    //     // Scroll to the CodeEditor section
    //     if (editorContainerRef.current) {
    //         editorContainerRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    //     }
    // };

    const handleEditClick = index => {
        console.log('Editing comment at index:', index); // Add this log
        setEditorContent(comments[index].comment);
        setEditIndex(index);
        if (editorContainerRef.current) {
            editorContainerRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
    };


    const deleteComment = async (commentUniqueId) => {
        console.log('commentunique-----', commentUniqueId)
        try {
            setIsPrimaryLoading(true);
            console.log('Deleting comment with ID:', commentUniqueId);
            const res = await dbApi.deleteApi('incident_management/delete_comment', { commentUniqueId: commentUniqueId });
            console.log('Delete Comment Response:', res);

            if (!res.error) {
                dispatch(alertBoxData([{ title: res.message, variant: "success" }]));
                const updatedComments = comments.filter(comment => comment.unique_id !== commentUniqueId);
                setComments(updatedComments);
                fetchComments();
                console.log('Remaining comments:', updatedComments);

            } else {
                dispatch(alertBoxData([{ title: res.message, variant: 'danger' }]));
            }
        } catch (error) {
            console.error('Error deleting comment:', error);

        }
        setIsPrimaryLoading(false);
    };

    const handleDeleteClick = index => {
        const commentUniqueId = comments[index].unique_id;
        console.log('delete_unique_id', commentUniqueId)// This id should hold the unique_id
        console.log('Index:', index, 'commentUniqueId:', commentUniqueId);
        deleteComment(commentUniqueId);
    };


    const onDeleteGroup = (commentUniqueId) => {
        setConfirmBox({
            show: true,
            msg: `Permanently delete task?`,
            funCall: () => handleDeleteClick(commentUniqueId),
            funParam: {},
            position: 'default',
            iconvariant: 'warning',
            submitbtnvariant: 'danger',
        });
    };
    const handleCancelClick = () => {
        setEditorContent('');
        setEditIndex(null);
    };

    const isCommentDisabled = editorContent.trim().length === 0;

    return (
        <PageSection variant={PageSectionVariants.light} isFilled>
            <div ref={messagesEndRef} style={{ maxHeight: '280px', overflowY: 'auto' }}>
                {comments.map((comment, index) => (
                    <Card key={index} isHoverable style={{ marginBottom: '10px' }} className='pf-v5-global-BackgroundColor-light-300'>
                        <CardHeader>
                            <Flex alignItems={{ default: 'alignItemsCenter' }}>
                                <FlexItem>
                                    <Icon size="lg">
                                        <UserCircleIcon />
                                    </Icon>
                                </FlexItem>
                                <FlexItem>
                                    <Text component={TextVariants.h6}>{comment.user_name}</Text>
                                </FlexItem>
                                <FlexItem spacer={{ default: 'spacerNone' }} align={{ default: 'alignRight' }}>
                                    <Text component={TextVariants.small}>
                                        {new Date(comment.last_datetime).toLocaleString()}
                                    </Text>
                                    <Tooltip content="Edit comment">
                                        <Button
                                            variant="plain"
                                            onClick={() => handleEditClick(index)}
                                            aria-label="Edit comment"
                                            style={{ marginLeft: '5px' }}
                                        >
                                            <Icon><EditIcon /></Icon>
                                        </Button>
                                    </Tooltip>
                                    <Tooltip content="Delete comment">
                                        <Button
                                            variant="plain"
                                            onClick={() => onDeleteGroup(index)}
                                            aria-label="Delete comment"
                                            style={{ marginLeft: '5px' }}
                                        >
                                            <Icon status="danger"><TrashIcon /></Icon>
                                        </Button>
                                    </Tooltip>
                                </FlexItem>
                            </Flex>
                        </CardHeader>
                        <CardBody>
                            <TextContent>
                                <Text component={TextVariants.p}>{comment.comment}</Text>
                            </TextContent>
                        </CardBody>
                    </Card>
                ))}
                {/* <CodeEditor
                ref={editorContainerRef}
                code={editorContent}
                onChange={onChange}
                onEditorDidMount={onEditorDidMount}
                height="60px"
            /> */}
                <div style={{ marginTop: '10px'}} ref={editorContainerRef}>
                    {/* <Card isRounded isFullHeight>
                        <CardBody> */}
                    <TextArea
                        id="editor"
                        aria-label="Comment Editor"
                        value={editorContent}
                        onChange={(e) => setEditorContent(e.target.value)}
                        resizeOrientation="vertical"
                        placeholder="Type your comment here..."
                        rows={2}  // Adjust the height of the textarea
                    // className='pf-v5-u-w-100'
                    />
                    {/* </CardBody>
                    </Card> */}

                </div>
                <ConfirmBox confirmModal={confirmModalData} />
            </div>
            <br />
            <Flex>
                <FlexItem>
                    <Button key="primary" variant="primary" onClick={() => fetchComments()} icon={<SyncAltIcon />}>
                        Refresh
                    </Button>
                </FlexItem>
                {/* <FlexItem align={{ default: 'alignRight' }}>
                        <Button key="confirm" variant="primary" icon={<EyeIcon />}>
                            Preview
                        </Button>
                    </FlexItem> */}
                <FlexItem>
                    <Button
                        key="primary"
                        variant="primary"
                        icon={isPrimaryLoading ? <Spinner size="md" /> : <CommentAltIcon />}
                        onClick={handleCommentClick}
                        isDisabled={isCommentDisabled}
                    >
                        {isPrimaryLoading ? 'Loading...' : (editIndex !== null ? 'Update' : 'Comment')}
                    </Button>
                </FlexItem>
                {editIndex !== null && (
                    <FlexItem>
                        <Button
                            key="cancel"
                            variant="danger"
                            onClick={handleCancelClick}
                            icon={<TimesIcon />}
                        >
                            Cancel
                        </Button>
                    </FlexItem>
                )}
            </Flex>
        </PageSection>
    );
};

export default IncidentCommentModal;
