import React, { useEffect } from 'react';
import * as echarts from 'echarts';

const Todo = () => {
    useEffect(() => {
        // Initialize ECharts instance
        const chartDom = document.getElementById('main');
        const myChart = echarts.init(chartDom);

        // Define chart options
        const option = {
            legend: {
                top: 'bottom',
            },
            toolbox: {
                show: true,
                feature: {
                    mark: { show: true },
                    dataView: { show: true, readOnly: false },
                    restore: { show: true },
                    saveAsImage: { show: true },
                },
            },
            series: [
                {
                    name: 'Nightingale Chart',
                    type: 'pie',
                    radius: [40, 100],
                    center: ['40%', '30%'],
                    roseType: 'area',
                    itemStyle: {
                        borderRadius: 7,
                    },
                    data: [
                        { value: 40, name: 'rose 1' },
                        { value: 38, name: 'rose 2' },
                        { value: 32, name: 'rose 3' },
                        { value: 30, name: 'rose 4' },
                        { value: 28, name: 'rose 5' },
                        { value: 26, name: 'rose 6' },
                        { value: 22, name: 'rose 7' },
                        { value: 18, name: 'rose 8' },
                        { value: 20, name: 'rose 9' },
                        { value: 10, name: 'rose 10' },
                        { value: 5, name: 'rose 11' },
                        { value: 1, name: 'rose 12' },
                        { value: 7, name: 'rose 13' },
                        { value: 2, name: 'rose 14' },
                    ],
                },
            ],
        };

        // Set chart options
        option && myChart.setOption(option);

        // Clean up the chart when the component unmounts
        return () => {
            myChart.dispose();
        };
    }, []);

    return <div className="container">
        <div id="main" style={{ width: '100%', height: '500px' }} ></div>
    </div>;
}

export default Todo;


// import * as echarts from 'echarts';

// var chartDom = document.getElementById('main');
// var myChart = echarts.init(chartDom);
// var option;

// option = {
//   tooltip: {
//     trigger: 'item'
//   },
//   series: [
//     {
//       name: 'Access From',
//       type: 'pie',
//       radius: ['40%', '70%'],
//       center: ['50%', '70%'],
//       // adjust the start angle
//       startAngle: 180,
//       label: {
//         show: true,
//         formatter(param) {
//           // correct the percentage
//           return param.name + ' (' + param.percent * 2 + '%)';
//         }
//       },
//       data: [
//         { value: 1048, name: 'Search Engine' },
//         { value: 735, name: 'Direct' },
//         { value: 580, name: 'Email' },

//         {
//           // make an record to fill the bottom 50%
//           value: 1048 + 735 + 580,
//           itemStyle: {
//             // stop the chart from rendering this piece
//             color: 'none',
//             decal: {
//               symbol: 'none'
//             }
//           },
//           label: {
//             show: false
//           }
//         }
//       ]
//     }
//   ]
// };

// option && myChart.setOption(option);