import React, { forwardRef, useState, useRef, useEffect, useImperativeHandle } from 'react';

import { MenuToggle, Popper, Toolbar, ToolbarContent, ToolbarItem, Pagination, Flex, 
    FlexItem, Button, Select, SelectList, SelectOption, SelectGroup, SearchInput, Tooltip,
     Card, CardBody, Icon, Grid, GridItem, Title, Badge, ModalVariant } from '@patternfly/react-core';
import { Table, Thead, Tr, Th, Tbody, Td, InnerScrollContainer } from '@patternfly/react-table';
import { PlusCircleIcon, SortAmountDownIcon, TrashIcon, PlayIcon, CloseIcon, RedoIcon, StopIcon, ClockIcon, CheckIcon, HourglassHalfIcon, TimesCircleIcon, PauseIcon, PausedIcon } from '@patternfly/react-icons';
import ModalCommon from 'src/Components/CommonEventPages/ModalCommon';
import { Link, useParams } from 'react-router-dom';
import dbApi from 'src/services/DbApi';
import { Loader } from 'src/util/Loadingstate';
import { useDispatch } from 'react-redux';
import ConfirmBox from 'src/Components/ConfirmBox/ConfirmBox';
import { formatTableTimestamp } from 'src/util/Timeutils';

const AlertLogModal = forwardRef((props,ref) => {
    
    const childIncidentCommentModalRef = useRef(null);
    const [alertData, setAlertData] = useState({});
    const [isLoading, setIsLoading] = useState(false)

    const getAlertLog = async(uniqueId)=>{
        try {
            setIsLoading(true);
            const res = await dbApi.postApi('incident_management/get_alert_detail', {uniqueId:uniqueId});
            if(!res.error) {
               setAlertData(res.data);
            } else {
               setAlertData([])
            }
            setIsLoading(false);
            childIncidentCommentModalRef.current.setModalBox(true);
        } catch (error) {
            console.error('Error fetching incidents:', error);
            setIsLoading(false);
        }
    }

    useImperativeHandle(ref, () => ({
        setOpenModalBox() {
            childIncidentCommentModalRef.current.setModalBox(true);
        },
        getAlertDetail(alertUniqueId)
        {
            getAlertLog(alertUniqueId)
        }
    }));
    return (<><ModalCommon
                title="Alert"
                onClose={() => childIncidentCommentModalRef.current.setModalBox(false)}
                variant={ModalVariant.medium}
                ref={childIncidentCommentModalRef}
                >
                { isLoading ? <Loader /> : <pre>{ JSON.stringify(alertData, null, 2) }</pre> }
                    </ModalCommon>
                    </>
    );

})


export default AlertLogModal;