
  export const formatValue = (value) => {
    if (value >= 1000000000) { // 1 billion
      return `${(value / 1000000000).toFixed(2)} B`;
    } else if (value >= 1000000) { // 1 million
      return `${(value / 1000000).toFixed(2)} M`;
    } else if (value >= 1000) { // 1 thousand
      return `${(value / 1000).toFixed(1)} K`;
    }
    return parseFloat(value);
  };