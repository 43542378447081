import React from 'react';
import { EmptyState, EmptyStateHeader, EmptyStateIcon, EmptyStateBody, EmptyStateFooter, EmptyStateActions, Button } from '@patternfly/react-core';

const EmptystateModal = ({
    titleText,
    bodyText,
    showFooter,
    onFooterFun,
    icon,
    buttonVariant,
    buttonText
}) => {
    return (
        <EmptyState>
            <EmptyStateHeader
                titleText={titleText}
                headingLevel="h4"
                icon={icon}
            />
            <EmptyStateBody>{bodyText}</EmptyStateBody>
            {showFooter && (
                <EmptyStateFooter>
                    <EmptyStateActions>
                        <Button variant={buttonVariant || "primary"} onClick={onFooterFun}>{buttonText || "OK"}</Button>
                    </EmptyStateActions>
                </EmptyStateFooter>
            )}
        </EmptyState>
    );
}

export default EmptystateModal;
