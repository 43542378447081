import * as echarts from 'echarts';
import React, { useEffect } from 'react';
import { generateUniqueID } from '../../util/UniqueIdGenerator';
import { calculateTimeDifference, formatTime, formatTimestamp } from '../../util/Timeutils';
import { formatValue } from '../../util/ChartHelper';
import { Emptystatefornodata } from '../../util/EmptyStateCard';
import { connect, useDispatch } from 'react-redux';
import { UpdateFilterData } from '../../util/FilterHelper';
import { FilterData } from '../../Redux/Action';
const EAreaChart = (props) => {
  const id = generateUniqueID()
  const dispatch = useDispatch();
  const handleFilter = (fields, value) => {
    const updatedFields = UpdateFilterData(props.filterformdata, fields, 'is', value);
    dispatch(FilterData(updatedFields));
  }
  useEffect(() => {
    if (props.alertlevelSeries && props.alertlevelSeries.length > 0) {
      const chartDom = document.getElementById(id + 'eac');
      const textcolor = props.theme == 'dark-theme' ? '#fff' : '#000'
      const linecolor = props.theme == 'dark-theme' ? '#595757' : '#d9d9d9'
      const myChart = echarts.init(chartDom);
      const { resultInMinutes, resultInHours, resultInDays } = calculateTimeDifference(props.StartDateTime, props.EndDateTime);

      const Label = props.label;
      const uniqueDatesSet = new Set();
      for (let i = 0; i < props.alertlevelSeries.length; i++) {
        for (let j = 0; j < props.alertlevelSeries[i].date_histogram_agg.buckets.length; j++) {
          const xvalue = props.alertlevelSeries[i].date_histogram_agg.buckets[j].key_as_string;
          uniqueDatesSet.add(xvalue);
        }
      }
      const xAxisData = []
      const uniqueDatesArray = [...uniqueDatesSet];
      for (let i = 0; i < uniqueDatesArray.length; i++) {
        const { ConvertTimestamp, hours, minutes, seconds } = formatTimestamp(uniqueDatesArray[i]);
        const formattedTime = formatTime(resultInMinutes, resultInHours, resultInDays, hours, minutes, seconds, ConvertTimestamp);
        xAxisData.push({ x: formattedTime });
      }

      const seriesData = props.alertlevelSeries.map((item) => ({
        name: item.key,
        type: 'line',
        smooth: true,
        areaStyle: {},
        showSymbol: false,
        emphasis: {
          focus: 'series',
        },
        data: item.date_histogram_agg.buckets.map((dataItem) => dataItem.doc_count),
      }));

      const legendData = props.alertlevelSeries.map((item) => item.key.toString());
      let interval = ''
      if (Label === '30 seconds') {
        interval = 4
      }
      else if (Label === 'hour') {
        interval = 3
      }
      else {
        interval = 3
      }

      const option = {
        animation: false,
        tooltip: {
          trigger: 'axis',
          position: [50, 50],
          textStyle: {
            color: "#000"
          },
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        title: {
          text: `Timestamp per ${Label}`,
          top: 'bottom',
          left: 'center',
          textStyle: {
            fontWeight: 450,
            fontSize: 14,
            color: textcolor,
            fontfamily: 'RedHatText, helvetica, arial, sans-serif',
          },
        },
        legend: {
          type: 'scroll',
          orient: 'vertical',
          right: 'right',
          top: 10,
          // bottom: 20,
          data: legendData,
          itemWidth: 9.5,
          itemHeight: 10,
          itemGap: 6.5,
          tooltip: {
            trigger: 'item',
            show: true,
            position: [10, 10],
            textStyle: {
              color: "#000"
            }
          },
          icon: "rect",
          pageIconSize: 12,
          textStyle: {
            width: props.legendwidth ? props.legendwidth : 180,
            overflow: 'truncate',
            fontSize: 12,
            color: textcolor
          }
        },

        grid: {
          left: '2%',
          right: '15%',
          bottom: '15%',
          top: '5%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: [0, '100%'],
            axisLabel: { interval: interval, rotate: -50, color: textcolor },
            data: xAxisData.map(item => item.x),
            axisTick: {
              alignWithLabel: true
            },
          }
        ],
        yAxis: [
          {
            axisLabel: {
              formatter: formatValue,
              color: textcolor
            },
            splitLine: {
              lineStyle: {
                color: linecolor
              }
            },
            axisLine: {
              show: true
            },
          }
        ],
        dataZoom: [
          {
            type: 'inside',
            start: 0,
            end: 100
          },
          {
            show: false,
            start: 0,
            end: 100
          }
        ],
        color: props.color,
        series: seriesData
      };
      option && myChart.setOption(option);
      myChart.on('legendselectchanged', function (param) {
        // console.log("Param",param)
        const Fields = props.filterFields
        handleFilter(Fields, param.name)
      });
      const resizeChart = () => {
        if (myChart) { // Check if chart exists before resizing
          myChart.resize();
        }
      };

      window.addEventListener('resize', resizeChart);
      return () => {
        myChart.dispose();
        window.removeEventListener('resize', resizeChart);
      };
    }
  }, [props.alertlevelSeries, props.label, props.StartDateTime,props.legendwidth,
  props.EndDateTime, props.filterFields, props.theme, props.sidebarState,props.height])

  return <> <div className="container">
    {props.alertlevelSeries.length == 0 ?
      (<>
        <div id={id + 'eac'} style={{ width: '1px', height: '1px' }}> </div>
        < Emptystatefornodata />
      </>)
      : (
        <div id={id + 'eac'} style={{ width: '100%', height: props?.height?props?.height:'290px' }} > </div>
      )
    }
  </div>
  </>
}
const mapStateToProps = (state) => ({
  dates: { startDate: state.startdate, endDate: state.enddate },
  filterformdata: state.FilterData,
  theme: state.theme,
  sidebarState: state.sidebarstate
  // mapStateToProps content remains unchanged if you have it
});

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(EAreaChart)