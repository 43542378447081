import React, { useEffect } from 'react';
import * as echarts from 'echarts';
import 'echarts-wordcloud';
import { generateUniqueID } from '../../util/UniqueIdGenerator';
import { Emptystatefornodata } from '../../util/EmptyStateCard';
import { connect, useDispatch } from 'react-redux';
import { FilterData } from '../../Redux/Action';
import { UpdateFilterData } from '../../util/FilterHelper';

const TopIOCs = (props) => {
    const id = generateUniqueID()
    const dispatch = useDispatch();
    const handleFilter = (fields, value) => {
        const updatedFields = UpdateFilterData(props.filterformdata, fields, 'is', value);
        dispatch(FilterData(updatedFields));
    }

    useEffect(() => {
        const chartDom = document.getElementById(id + '-ioc');
        const myChart = echarts.init(chartDom);

        const option = {
            tooltip: {
                trigger: 'item',
                formatter: function (params) {
                    if(params.data.nestedkey){
                        return params.data.name + '(' + params.data.nestedkey + ')' + ' : ' + params.data.value;
                    }else{
                        return params.data.name + ' : ' + params.data.value; 
                    }
                },
            },
            series: [
                {
                    textStyle: {
                        color: function () {
                            if (props?.theme == 'dark-theme') {
                                return 'rgb(' + [
                                    Math.round(100 + Math.random() * 150),
                                    Math.round(100 + Math.random() * 150),
                                    Math.round(100 + Math.random() * 150)
                                ].join(',') + ')';
                            }
                            else {
                                return 'rgb(' + [
                                    Math.round(Math.random() * 160),
                                    Math.round(Math.random() * 160),
                                    Math.round(Math.random() * 160)
                                ].join(',') + ')';
                            }
                        }
                    },
                    emphasis: {
                        focus: 'self',

                        textStyle: {
                            textShadowBlur: 5,
                            textShadowColor: '#333'
                        }
                    },
                    type: 'wordCloud',
                    gridSize: 10,
                    sizeRange: [12, 70],
                    shape: 'rectangle',
                    rotationRange: [-90, 90],
                    // rotationStep: 45,
                    left: 'center',
                    top: 'center',
                    width: '100%',
                    height: '100%',
                    right: null,
                    bottom: null,
                    drawOutOfBound: false,
                    shrinkToFit: true,
                    // roam: true,
                    data: props.ThreatIntelData.map((bucket) => ({
                        value: bucket.doc_count,
                        name: bucket.key,
                        nestedkey: bucket.nestedkey,
                    }))
                },
            ],
        };

        // Set chart options
        option && myChart.setOption(option);
        myChart.on('click', 'series.wordCloud', function (param) {
            if (param.data.nestedkey) { handleFilter(props.filterFields, param.data.name) }
        });
        // Clean up the chart when the component unmounts
        const resizeChart = () => {
            if (myChart) { // Check if chart exists before resizing
                myChart.resize();
            }
        };

        window.addEventListener('resize', resizeChart);
        return () => {
            myChart.dispose();
            window.removeEventListener('resize', resizeChart);
        };
    }, [props.ThreatIntelData, props.filterFields, props.theme, props.sidebarState]);

    return <> <div className="container">
        {props.ThreatIntelData.length == 0 ?
            (<>
                <div id={id + '-ioc'} style={{ width: '1px', height: '1px' }}> </div>
                < Emptystatefornodata />
            </>)
            : (
                <div id={id + '-ioc'} style={{ width: '100%', height: '200px' }} > </div>
            )
        }
    </div>
    </>
}
const mapStateToProps = (state) => ({
    dates: { startDate: state.startdate, endDate: state.enddate },
    filterformdata: state.FilterData,
    theme: state.theme,
    sidebarState: state.sidebarstate
    // mapStateToProps content remains unchanged if you have it
});

export default connect(
    mapStateToProps,
    null,
    null,
    { forwardRef: true }
)(TopIOCs)