import React, { useEffect, useState, forwardRef, useMemo } from 'react'
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    CardFooter,
    Divider,
    Flex,
    Grid,
    GridItem,
    Modal,
    Text,
    TextVariants,
    ModalVariant,
    Tabs,
    Tab,
    TabTitleText,
    Bullseye,
    TabContentBody,
    FlexItem,
    Tooltip,
} from '@patternfly/react-core'
import dbApi from '../../services/DbApi'
import { connect, useDispatch } from 'react-redux';
import SystemAuditingGroups from '../SystemAuditing/SAGroups'
import AlertsVolumeHeat from './AlertsVolumeHeat'
import { Loader,TextSkeleton } from 'src/util/Loadingstate';
import EWordCloud from '../AllCharts/EWordCloud';
import { Emptystatefornodata } from 'src/util/EmptyStateCard';
import TableViewForChart from '../AllCharts/TableViewForChart';
import { formatValue } from 'src/util/ChartHelper';
import EDonutChart from '../AllCharts/EDonutChart';
import EBarStackChart from '../AllCharts/EBarStackChart';
import RequirementDistributionchart from './RequirementDistributionchart';
import ReqDistTable from './ReqDistTable';
const HippaDashboard = forwardRef(function HippaDashboard(props, ref) {
    const dispatch = useDispatch()
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [activeTabKey, setActiveTabKey] = useState(0);
    const [alertsvolume, setAlertsVolume] = useState([])
    const [commonalerts, setCommonAlerts] = useState([]);
    const [activeagent, setActiveAgent] = useState([]);
    const [totalalerts, setTotalalerts] = useState(0)
    const [maxrule, setMaxrule] = useState(0)
    const [reqevolution, setReqEvolution] = useState([])
    const [evolutionlabel, setEvolutionlabel] = useState('')
    const [reqdist, setReqDist] = useState([])

    //Loading states
    const [loadvolume, setLoadVolume] = useState(false)
    const [loadcommonalert, setLoadCommonAlert] = useState(false)
    const [isloadagent, setIsLoadAgent] = useState(false)
    const [loadstats, setLoadStats] = useState(false)
    const [loadreqevolution, setLoadreqEvolution] = useState(false)
    const [loadreqdist, setLoadreqDist] = useState(false)
    // modal states
    const [modalStates, setModalStates] = useState({
        modalcommonalert: false,
        modaltopreq: false,
        modalactiveagent: false,
        modalAgents: false,
        modalreqdist:false
    });
    const handleModalToggle = (modalName) => {
        setModalStates((prevStates) => ({
            ...prevStates,
            [modalName]: !prevStates[modalName],
        }));
    };

    const handleTabClick = (event, tabIndex) => {
        setActiveTabKey(tabIndex)
    }

    const getAlertsvolumebyagent = async (data) => {
        try {
            setLoadVolume(true)
            let res = await dbApi.postApi("SecurityCompliances/Hipaa_Alerts_Volume_By_Agent", data)
            console.log(res)
            let volume = []
            if (Object.keys(res.Hipaa_Alerts_Volume_By_Agent).length !== 0) {
                volume = res.Hipaa_Alerts_Volume_By_Agent
                setAlertsVolume(volume)
            }
            else {
                setAlertsVolume(volume)
            }
            setLoadVolume(false)
        } catch (error) {
            console.log(error)
            setAlertsVolume([])
            setLoadVolume(false)
        }
    }
    // CommonAlerts and top requirements
    const getCommonAlerts = async (data) => {
        try {
            setLoadCommonAlert(true)
            let res = await dbApi.postApi("SecurityCompliances/Hipaa_Most_Common_Alerts", data)
            console.log(res)
            let alerts = []
            if (Object.keys(res.Hipaa_Most_Common_Alerts).length !== 0) {
                alerts = res.Hipaa_Most_Common_Alerts
                setCommonAlerts(alerts)
            }
            else {
                setCommonAlerts(alerts)
            }
            setLoadCommonAlert(false)
        } catch (error) {
            console.log(error)
            setCommonAlerts([])
            setLoadCommonAlert(false)
        }
    }
    // SecurityCompliances/Hipaa_Most_Active_Agents
    const getActiveAgents = async (data) => {
        try {
            setIsLoadAgent(true)
            let res = await dbApi.postApi("SecurityCompliances/Hipaa_Most_Active_Agents", data)
            console.log(res)
            let agents = []
            if (Object.keys(res.Hipaa_Most_Active_Agents).length !== 0) {
                agents = res.Hipaa_Most_Active_Agents
                setActiveAgent(agents)
            }
            else {
                setActiveAgent(agents)
            }
            setIsLoadAgent(false)
        } catch (error) {
            console.log(error)
            setActiveAgent([])
            setIsLoadAgent(false)
        }
    }
    const getStats = async (data) => {
        try {
            setLoadStats(true)
            let res = await dbApi.postApi("SecurityCompliances/Hipaa_Status", data)
            console.log(res)
            let total = ''
            let maxrule = ''
            if (Object.keys(res.Hipaa_Status).length !== 0) {
                total = res.Hipaa_Status.Total_count
                maxrule = res.Hipaa_Status.status
                setTotalalerts(total)
                setMaxrule(maxrule)
            }
            else {
                setTotalalerts(total)
                setMaxrule(maxrule)
            }
            setLoadStats(false)
        } catch (error) {
            console.log(error)
            setTotalalerts('')
            setMaxrule('')
            setLoadStats(false)
        }
    }

    const getRequirementsevolution = async (data) => {
        try {
            setLoadreqEvolution(true)
            let res = await dbApi.postApi("SecurityCompliances/Hipaa_Requirements_Evolution", data)
            console.log(res)
            let chartseries = ''
            let label = ''
            if (Object.keys(res.Hipaa_Requirements_Evolution).length !== 0) {
                chartseries = res.Hipaa_Requirements_Evolution.data.body.aggregations.Hippa.buckets
                label = res.Hipaa_Requirements_Evolution.interval
                setReqEvolution(chartseries)
                setEvolutionlabel(label)

            }
            else {
                setReqEvolution(chartseries)
                setEvolutionlabel(label)
            }
            setLoadreqEvolution(false)
        } catch (error) {
            console.log(error)
            setReqEvolution([])
            setEvolutionlabel('')
            setLoadreqEvolution(false)
        }
    }

    const getReqDistribution = async (data) => {
        try {
            setLoadreqDist(true)
            let res = await dbApi.postApi("SecurityCompliances/Hipaa_Requirements_Distribution_By_Agent", data)
            console.log(res)
            let chartseries = ''
            if (Object.keys(res.Hipaa_Requirements_Distribution_By_Agent).length !== 0) {
                chartseries = res.Hipaa_Requirements_Distribution_By_Agent
                setReqDist(chartseries)
            }
            else {
                setReqDist(chartseries)
            }
            setLoadreqDist(false)
        } catch (error) {
            console.log(error)
            setReqDist([])
            setLoadreqDist(false)
        }
    }
    const HipaaDashboardApi = async (startDate, endDate, allFields) => {
        const Fields = allFields
        const data = {
            startDate: startDate,
            endDate: endDate,
            filter: JSON.stringify(Fields),
        };
        try {
            setStartDate(startDate);
            setEndDate(endDate);
            console.log(data)
            getAlertsvolumebyagent(data);
            getCommonAlerts(data)
            getActiveAgents(data)
            getStats(data)
            getRequirementsevolution(data)
            getReqDistribution(data)


        }
        catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {

        if (props.dates.startDate != "") {
            let Dates = props.dates
            HipaaDashboardApi(Dates.startDate, Dates.endDate, props.filterformdata)
        }
    }, [props?.dates.startDate, props?.dates.endDate, props?.filterformdata,]);


    const AlertsVolumeByAgent = useMemo(() => {
        return (loadvolume ? <Loader /> :
            alertsvolume.length > 0 ?
                <AlertsVolumeHeat SeriesData={alertsvolume.slice(0, 10)} filterFields={'agent.id'} height={'750px'} />
                : <Emptystatefornodata />
        )
    }, [loadvolume, alertsvolume])

    const CommonAlert = useMemo(() => {
        return (
            loadcommonalert ?
                <Loader /> :
                commonalerts.length > 0 ?
                    <EWordCloud cloudseries={commonalerts.slice(0, 20)} height={'250px'} filterFields={'rule.hipaa'} />
                    : <Emptystatefornodata />
        )
    }, [loadcommonalert, commonalerts])
    const TopRequirements = useMemo(() => {
        return (
            loadcommonalert ?
                <Loader /> :
                commonalerts.length > 0 ?
                     <SystemAuditingGroups GroupData={commonalerts.slice(0, 5)} height={300} filterFields={'rule.hipaa'} />
                    // <EDonutChart seriesData={commonalerts.slice(0, 5)} height={'150px'} filterFields={'rule.hipaa'} color={['#519DE9', '#004B95', '#8BC1F7', '#06C', '#002F5D']} />
                    : <Emptystatefornodata />
        )
    }, [loadcommonalert, commonalerts])

    const ActiveAgent = useMemo(() => {
        return (
            isloadagent ?
                <Loader /> :
                activeagent.length > 0 ?
                    <EDonutChart seriesData={activeagent.slice(0, 5)} height={'240px'} filterFields={'agent.name'} color={['#519DE9', '#004B95', '#8BC1F7', '#06C', '#002F5D']} />
                    : <Emptystatefornodata />
        )
    }, [isloadagent, activeagent])

    const RequirementEvolution = useMemo(() => {
        return (
            loadreqevolution ?
                <Loader /> :
                reqevolution.length > 0 ?
                    <EBarStackChart
                        label={evolutionlabel}
                        Stackseries={reqevolution.slice(0, 5)}
                        StartDateTime={startDate}
                        EndDateTime={endDate}
                        filterFields={'rule.hipaa'}
                    />
                    : <Emptystatefornodata />
        )
    }, [loadreqevolution, reqevolution])
    const RequirementDistribution = useMemo(() => {
        return (
            loadreqdist ?
                <Loader /> :
                reqdist.length > 0 ?
                    <RequirementDistributionchart seriesData={reqdist.slice(0, 5)} filterFields={'rule.hipaa'} height={'250px'} />
                    : <Emptystatefornodata />
        )
    }, [loadreqdist, reqdist])
    
    return (<>
        <Grid hasGutter className="pf-v5-u-mt-md">
            <GridItem md={5} sm={12} lg={5} mdRowSpan={2}>
                <Card isFullHeight>
                    <CardTitle >Alerts volume by agent</CardTitle>
                    <Divider />
                    <CardBody > {AlertsVolumeByAgent} </CardBody>

                </Card>
            </GridItem>

            <GridItem md={4} sm={12} lg={4}>
                <Card isFullHeight>
                    <CardTitle>Top 5 requirements</CardTitle>
                    <Divider />
                    <CardBody>{TopRequirements}</CardBody>
                    {commonalerts.length > 0 && <CardFooter>
                        <Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
                            <Button variant="link" onClick={() => { setActiveTabKey(0); handleModalToggle('modaltopreq') }}> More </Button>
                            <Modal
                                tabIndex={0}
                                variant={ModalVariant.large}
                                title={`Top requirements (${commonalerts.length})`}
                                isOpen={modalStates.modaltopreq}
                                onClose={() => handleModalToggle('modaltopreq')}
                            >
                                <Divider className='modal-Divider' />
                                <Card>
                                    <Tabs activeKey={activeTabKey} onSelect={handleTabClick} aria-label="Tabs in the default example" role="region">
                                        <Tab eventKey={0} title={<TabTitleText>Table View</TabTitleText>}>
                                            {loadcommonalert ?
                                                <Loader /> : <TableViewForChart Tabledata={commonalerts} columnData={["Requirement", "Count"]} filterFields={'rule.hipaa'} />
                                            }
                                        </Tab>
                                        <Tab eventKey={1} title={<TabTitleText>Graph View</TabTitleText>} aria-label="graph">
                                            {activeTabKey == '1' && <TabContentBody>   {loadcommonalert ? <Loader /> :
                                                commonalerts.length > 0 ?
                                                    (<SystemAuditingGroups GroupData={commonalerts.slice(0, 15)} height={300} filterFields={'rule.hipaa'} />) : (<Emptystatefornodata />)
                                            }
                                            </TabContentBody>}
                                        </Tab>
                                    </Tabs>
                                </Card>
                            </Modal>
                        </Flex>
                    </CardFooter>}
                </Card>
            </GridItem>
            <GridItem md={3} sm={12} lg={3}>
                <Card isFullHeight>
                    <CardTitle>Most common alerts</CardTitle>
                    <Divider />
                    <CardBody>{CommonAlert}</CardBody>
                    {commonalerts.length > 0 && <CardFooter>
                        <Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
                            <Button variant="link" onClick={() => { setActiveTabKey(0); handleModalToggle('modalcommonalert') }}> More </Button>
                            <Modal
                                tabIndex={0}
                                variant={ModalVariant.large}
                                title={`Most common alerts (${commonalerts.length})`}
                                isOpen={modalStates.modalcommonalert}
                                onClose={() => handleModalToggle('modalcommonalert')}
                            >
                                <Divider className='modal-Divider' />
                                <Card>
                                    <Tabs activeKey={activeTabKey} onSelect={handleTabClick} aria-label="Tabs in the default example" role="region">
                                        <Tab eventKey={0} title={<TabTitleText>Table View</TabTitleText>}>
                                            {loadcommonalert ?
                                                <Loader /> : <TableViewForChart Tabledata={commonalerts} columnData={["Requirement", "Count"]} filterFields={'rule.hipaa'} />
                                            }
                                        </Tab>
                                        <Tab eventKey={1} title={<TabTitleText>Graph View</TabTitleText>} aria-label="graph">
                                            {activeTabKey == '1' && <TabContentBody>   {loadcommonalert ? <Loader /> :
                                                commonalerts.length > 0 ?
                                                    (<EWordCloud cloudseries={commonalerts.slice(0, 100)} height={500} filterFields={'rule.hipaa'} />) : (<Emptystatefornodata />)
                                            }</TabContentBody>}
                                        </Tab>
                                    </Tabs>
                                </Card>
                            </Modal>
                        </Flex>
                    </CardFooter>}
                </Card>
            </GridItem>
            <GridItem md={4} sm={12} lg={4}>
                <Card isFullHeight hasGutter>
                    <CardTitle>Most active agents</CardTitle>
                    <Divider />
                    <CardBody>{ActiveAgent}</CardBody>
                    {activeagent.length > 0 && <CardFooter>
                        <Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
                            <Button variant="link" onClick={() => { setActiveTabKey(0); handleModalToggle('modalactiveagent') }}> More </Button>
                            <Modal
                                tabIndex={0}
                                variant={ModalVariant.large}
                                title={`Most active agents (${activeagent.length})`}
                                isOpen={modalStates.modalactiveagent}
                                onClose={() => handleModalToggle('modalactiveagent')}
                            >
                                <Divider className='modal-Divider' />
                                <Card>
                                    <Tabs activeKey={activeTabKey} onSelect={handleTabClick} aria-label="Tabs in the default example" role="region">
                                        <Tab eventKey={0} title={<TabTitleText>Table View</TabTitleText>}>
                                            {loadcommonalert ?
                                                <Loader /> : <TableViewForChart Tabledata={activeagent} columnData={["Agent", "Count"]} filterFields={'agent.name'} />
                                            }
                                        </Tab>
                                        <Tab eventKey={1} title={<TabTitleText>Graph View</TabTitleText>} aria-label="graph">
                                            {activeTabKey == '1' && <TabContentBody>
                                                {loadcommonalert ? <Loader /> :
                                                    activeagent.length > 0 ?
                                                        (<SystemAuditingGroups GroupData={activeagent.slice(0, 15)} height={300} filterFields={'agent.name'} />) : (<Emptystatefornodata />)
                                                }
                                            </TabContentBody>}
                                        </Tab>
                                    </Tabs>
                                </Card>
                            </Modal>
                        </Flex>
                    </CardFooter>}
                </Card>
            </GridItem>
            <GridItem md={3} sm={12} lg={3}>
                <Card isFullHeight>
                    <CardTitle>Stats</CardTitle>
                    <Divider />
                    <CardBody>
                        <Bullseye>
                            <Flex direction={{ default: 'column' }} spaceItems={{ default: 'spaceItemsLg' }} alignItems={{ default: 'alignItemsCenter' }}>
                                <FlexItem>
                                    <Tooltip content={`Alerts : ${totalalerts}`}>
                                        <Text className=' pf-v5-u-font-family-heading pf-v5-u-font-size-4xl pf-v5-u-text-align-center'>
                                            {loadstats ? <TextSkeleton /> : formatValue(totalalerts) ?? 0}
                                        </Text>
                                    </Tooltip>
                                    <Text component={TextVariants.h2} className='pf-v5-u-color-100 grid-text-center'>Total alerts</Text>
                                </FlexItem>
                                <FlexItem>
                                    <Text className=' pf-v5-u-font-family-heading pf-v5-u-font-size-4xl pf-v5-u-text-align-center'>
                                        {loadstats ? <TextSkeleton /> : maxrule ?? 0}
                                    </Text>
                                    <Text component={TextVariants.h2} className='pf-v5-u-color-100 grid-text-center'>Max rule level detected</Text>
                                </FlexItem>
                            </Flex>
                        </Bullseye>
                    </CardBody>
                </Card>
            </GridItem>
        </Grid>


        <div className="pf-v5-u-mt-md">
            <Grid hasGutter className="pf-v5-u-mt-md">
                <GridItem md={6} sm={12} lg={6}>
                    <Card isFullHeight>
                        <CardTitle >Requirements evolution over time</CardTitle>
                        <Divider />
                        <CardBody > {RequirementEvolution} </CardBody>

                    </Card>
                </GridItem>
                <GridItem md={6} sm={12} lg={6}>
                    <Card isFullHeight>
                        <CardTitle > Requirements distribution by agent</CardTitle>
                        <Divider />
                        <CardBody > {RequirementDistribution}</CardBody>
                        {reqdist.length > 0 && <CardFooter>
                <Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
                  <Button variant="link" onClick={() => { setActiveTabKey(0); handleModalToggle('modalreqdist'); }}>
                    More
                  </Button>
                  <Modal
                    variant={ModalVariant.large}
                    title={`Requirements distribution by agent (${reqdist.length})`}
                    isOpen={modalStates.modalreqdist}
                    onClose={() => handleModalToggle('modalreqdist')}
                  >
                    <Divider className='modal-Divider' />
                    <Card>
                      <Tabs activeKey={activeTabKey} onSelect={handleTabClick} aria-label="Tabs in the default example" role="region">
                        <Tab eventKey={0} title={<TabTitleText>Table View</TabTitleText>}>
                          <TabContentBody className='pf-v5-u-p-xs'>
                            {loadreqdist ? <Loader /> : <ReqDistTable Tabledata={reqdist} columnData={["Agent", "Requirements", "Count"]} filterFields={'agent.name'} />}
                          </TabContentBody>
                        </Tab>
                        <Tab eventKey={1} title={<TabTitleText>Graph View</TabTitleText>} aria-label="graph">
                          {activeTabKey == '1' && <TabContentBody className='pf-v5-u-p-xs'>
                            {loadreqdist ? <Loader /> :
                              reqdist.length > 0 ?
                              <RequirementDistributionchart seriesData={reqdist.slice(0, 10)} filterFields={'rule.hipaa'} height={'350px'} /> :
                                <Emptystatefornodata />}
                          </TabContentBody>}
                        </Tab>
                      </Tabs>
                    </Card>
                  </Modal>
                </Flex>

              </CardFooter>}
                    </Card>
                </GridItem>
            </Grid>
        </div>

    </>
    )
}
)
const mapStateToProps = (state) => ({
    dates: { startDate: state.startdate, endDate: state.enddate },
    filterformdata: state.FilterData,
    selectedGroup: state.SelectedGroup,
    // mapStateToProps content remains unchanged if you have it
});

const mapDispatchToProps = (dispatch) => { return {} }
export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(HippaDashboard)

