import React, { useState, useRef, useEffect, useMemo } from 'react';
import { MenuToggle, Popper, Toolbar, ToolbarContent, ToolbarItem, Pagination, Flex, FlexItem, Button, Select, SelectList, SelectOption, SelectGroup, SearchInput, Tooltip, Card, CardBody, Icon, OverflowMenu, OverflowMenuGroup, OverflowMenuItem, OverflowMenuDropdownItem, Dropdown, DropdownList, Modal, TextContent, DataList, DataListItem, DataListItemRow, DataListCheck, DataListItemCells, DataListCell, ToolbarFilter, ToolbarToggleGroup, ToolbarGroup } from '@patternfly/react-core';
import { Table, Thead, Tr, Th, Tbody, Td, InnerScrollContainer } from '@patternfly/react-table';
import { PlusCircleIcon, SortAmountDownIcon, TrashIcon, PlayIcon, CloseIcon, RedoIcon, StopIcon, ClockIcon, CheckIcon, HourglassHalfIcon, TimesCircleIcon, PauseIcon, PausedIcon, EllipsisVIcon, FilterIcon } from '@patternfly/react-icons';
import ModalCommon from 'src/Components/CommonEventPages/ModalCommon';
import IncidentTaskModal from './IncidentTaskModal';
import { Link, useParams } from 'react-router-dom';
import dbApi from 'src/services/DbApi';
// import { Emptystatefornodata } from 'src/util/EmptyStateCard';
import { Loader } from 'src/util/Loadingstate';
import { useDispatch } from 'react-redux';
import { alertBoxData } from 'src/Redux/Action';
import ConfirmBox from 'src/Components/ConfirmBox/ConfirmBox';
import { formatTableTimestamp } from 'src/util/Timeutils';
import { EmptystateforIncident } from 'src/util/EmptyStateCard';

const columnNames = {
    title: 'Title',
    status: 'Status',
    create_datetime: 'Create Date and Time',
    assignee_name: 'Assignee',
    action: 'Action',
};
const statusIconMapping = {
    'Waiting': {
        statusIcon: <PauseIcon color='#337ab7' />,
        statusTooltip: 'Waiting',
        actionIcon: <PlayIcon color='#337ab7' />,
        actionTooltip: 'Start',
        nextStatus: 'InProgress'
    },
    'InProgress': {
        statusIcon: <HourglassHalfIcon color='#f39c12' />,
        statusTooltip: 'InProgress',
        actionIcon: <TimesCircleIcon color='#3c763d' />,
        actionTooltip: 'Close',
        nextStatus: 'Completed'
    },
    'Completed': {
        statusIcon: <CheckIcon color='#3c763d' />,
        statusTooltip: 'Completed',
        actionIcon: <PlayIcon color='#3c763d' />,
        actionTooltip: 'Reopen',
        nextStatus: 'Waiting'
    }
};
const defaultColumns = ['Title', 'Status', 'Create Date and Time', 'Assignee', 'Action'];
const defaultRows = [];
const excludeOrder = ['Action']
const excludeWrapCol = ['Create Date and Time']

const IncidentDetailTask = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const childTaskDetailModalref = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [columns] = useState(columnNames);
    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [activeSortIndex, setActiveSortIndex] = useState(2);
    const [activeSortDirection, setActiveSortDirection] = useState('desc');
    const [isSortDropdownOpen, setIsSortDropdownOpen] = useState(false);
    const [totalItem, setTotalItem] = useState(0);
    const [searchValue, setSearchValue] = useState('');
    const [loadDelete, setLoadDelete] = useState(false);
    const [isKebabDropdownOpen, setIsKebabDropdownOpen] = useState(false);
    const [inputValue, setInputValue] = useState('')
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [managedColumns, setManagedColumns] = useState(defaultColumns);
    const [filteredColumns, setFilteredColumns] = useState(defaultColumns);
    const [isFilterDropdownOpen, setIsFilterDropdownOpen] = useState(false)
    const [filteredRows, setFilteredRows] = useState(defaultRows);
    const [isCategoryDropdownOpen, setIsCategoryDropdownOpen] = useState(false)
    const [paginatedRows, setPaginatedRows] = useState([]);
    const [filters, setFilters] = useState([]);
    const [managedRows, setManagedRows] = useState(defaultRows);
    const [checkedState, setCheckedState] = useState(Array(defaultColumns.length).fill(true));
    const [currentCategory, setCurrentCategory] = useState('Name')
    const [dataFilters, setDataFilters] = useState({
        name: [],
        status: [],
        statusChip: [],
        // severity: [],
        // severityChip: []
    })
    const [confirmModalData, setConfirmBox] = useState({
        show: false,
        msg: '',
        funCall: '',
        funParam: {},
        // title: '',
        // position: '',
        // iconvariant: '',
        // submitbtnvariant: '',
    });
    const [selectedRows, setSelectedRows] = useState([]);
    const isRowSelected = row => selectedRows.includes(row.unique_id);
    const setRowSelected = (row, isSelecting) => {
        setSelectedRows(prevSelected => {
            const otherSelectedRows = prevSelected.filter(r => r !== row.unique_id);
            const newSelectedRows = isSelecting ? [...otherSelectedRows, row.unique_id] : otherSelectedRows;
            console.log('Selected Row IDs:', newSelectedRows);
            return newSelectedRows;
        });
    };

    const onFilterToggle = () => {
        setIsFilterDropdownOpen(!isFilterDropdownOpen)
    };

    const onInputChange = newValue => {
        setInputValue(newValue)
    };

    const onNameInput = event => {
        if (event.key && event.key !== 'Enter') {
            return;
        }
        let temp = dataFilters
        temp.name[0] = inputValue
        setDataFilters(prevDataFilters => ({
            ...prevDataFilters,
            temp
        }));
    };

    const filterData = (checked, name) => {
        const selectedColumn = matchSelectedColumnNameToAttr(name);
        const filteredRows = [];
        if (checked) {
            const updatedFilters = filters.filter((item) => item !== selectedColumn);
            // Only show the names of columns that were selected in the modal
            const filteredColumns = defaultColumns.filter(
                (column) => !updatedFilters.includes(matchSelectedColumnNameToAttr(column))
            );
            // Remove the attributes (i.e. "columns") that were not selected
            managedRows.forEach((item) => filteredRows.push(removePropFromObject(item, updatedFilters)));

            setFilters(updatedFilters);
            setFilteredColumns(filteredColumns);
            setFilteredRows(filteredRows);
        } else {
            let updatedFilters = filters;
            if (typeof selectedColumn != 'undefined') {
                updatedFilters.push(selectedColumn);
            }

            // Only show the names of columns that were selected in the modal
            const filteredColumns = managedColumns.filter(
                (column) => !filters.includes(matchSelectedColumnNameToAttr(column))
            );

            // Remove the attributes (i.e. "columns") that were not selected
            managedRows.forEach((item) => filteredRows.push(removePropFromObject(item, updatedFilters)));
            setFilters(updatedFilters);
            setFilteredColumns(filteredColumns);
            setFilteredRows(filteredRows);
        }
    };

    const onCategoryToggle = () => {
        setIsCategoryDropdownOpen(!isCategoryDropdownOpen)
    };

    const onFilterSelect = (event, selection, type, chipType) => {
        const checked = event.target.checked;
        let text = event.target.innerText
        setIsFilterDropdownOpen(false)
        let temp = dataFilters
        temp[type][0] = selection
        temp[chipType][0] = text
        setDataFilters(prevDataFilters => ({
            ...prevDataFilters,
            temp
        }));
    };

    const onCategorySelect = event => {
        setCurrentCategory(event.target.innerText)
        setIsCategoryDropdownOpen(false)
        setIsFilterDropdownOpen(false)
    };
    
    const unfilterAllData = () => {
        setFilters([]);
        setFilteredColumns(defaultColumns);
        setFilteredRows(managedRows);
    };

    const matchCheckboxNameToColumn = (name) => {
        switch (name) {
            case 'check1':
                return 'Title';
            case 'check2':
                return 'Status';
            case 'check3':
                return 'Create Date and Time';
            case 'check4':
                return 'Assignee';
            case 'check5':
                return 'Action';
        }
    };

    const handleChange = (event, checked) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        // Remove any columns from the table that aren't checked
        filterData(checked, matchCheckboxNameToColumn(target.name));
        const checkedIndex = defaultColumns.findIndex((element) => element === matchCheckboxNameToColumn(target.name));

        const updatedCheckedState = [...checkedState];
        updatedCheckedState[checkedIndex] = value;

        setCheckedState(updatedCheckedState);
    };

    const selectAllColumns = () => {
        unfilterAllData();
        setCheckedState(Array(defaultColumns.length).fill(true));
    };

    const handleModalToggle = (_event) => {
        setIsModalOpen(!isModalOpen);
    };

    const onSave = () => {
        setManagedColumns(filteredColumns);
        console.log("filteredColumns")
        console.log(filteredColumns)
        setPaginatedRows(filteredRows);
        setIsModalOpen(!isModalOpen);
    };

    const getTaskList = async () => {
        const data = {
            limitStart: (perPage * page) - perPage,
            limitLength: perPage,
            sortByCol: Object.keys(columns)[activeSortIndex],
            sortOrder: activeSortDirection,
            search: dataFilters.name.length > 0 ? dataFilters.name[0] : '',
            status: dataFilters.status.length > 0 ? dataFilters.status[0] : '',
            incidentId: id,
        };

        try {
            setIsLoading(true);
            const res = await dbApi.postApi("incident_management/task_list", data);
            console.log('task list data', res)
            if (res && res.data) {
                setRows(res.data.data);
                setTotalItem(res.data.totalRecord);
                setManagedRows(res.data.data);
                setPaginatedRows(res.data.data);
            } else {
                setRows([]);
                setTotalItem(0);
                setManagedRows([]);
                setPaginatedRows([])
            }
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching incidents:', error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getTaskList();
    }, [page, perPage, activeSortIndex, activeSortDirection, searchValue, dataFilters]);


    const finalRemoveTask = async (tasks) => {
        let param = { taskUniqueId: tasks };
        console.log('delete_id_task', param)

        try {
            setIsLoading(true);
            console.log({ taskUniqueId: selectedRows });
            const ret = await dbApi.deleteApi('incident_management/delete_task', param);
            let type = 'danger';
            if (!ret.error) {
                type = 'success';
                getTaskList();
            }
            dispatch(alertBoxData([{ title: ret.message, variant: type }]));
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    const matchSelectedColumnNameToAttr = (name) => {
        switch (name) {
            case 'Title':
                return 'title';
            case 'Status':
                return 'status';
            case 'Create Date and Time':
                return 'create_datetime';
            case 'Assignee':
                return 'assignee_name';
            case 'Action':
                return 'action';

        }
    };

    const removePropFromObject = (object, keys) =>
        keys.reduce((obj, prop) => {
            const { [prop]: _, ...keep } = obj;
            return keep;
        }, object);



    const removeTask = (taskIds) => {
        setConfirmBox({
            show: true,
            title: 'Permanently delete task?',
            msg: "Are you sure you want to delete this task?",
            funCall: () => finalRemoveTask(taskIds),
            funParam: {},
            position: 'default',
            iconvariant: 'warning',
            submitbtnvariant: 'danger',
        })
    }

    const onDelete = (type = '', id = '') => {
        if (type) {
            let temp = dataFilters
            temp[type].splice(0, 1);
            if (type == "status") {
                temp['statusChip'].splice(0, 1);
            } else if (type == "severity") {
                temp['severityChip'].splice(0, 1);
            }
            setDataFilters(prevDataFilters => ({
                ...prevDataFilters,
                temp
            }));
        } else {
            setDataFilters({ name: [], status: [], statusChip: [] })
        }
    };

    const renderModal = () => {
        return (
            <Modal
                title="Manage columns"
                isOpen={isModalOpen}
                variant="small"
                description={
                    <TextContent>
                        <Button isInline onClick={selectAllColumns} variant="link">
                            Select all
                        </Button>
                    </TextContent>
                }
                onClose={handleModalToggle}
                actions={[
                    <Button key="save" variant="primary" onClick={onSave}>
                        Save
                    </Button>,
                    <Button key="cancel" variant="secondary" onClick={handleModalToggle}>
                        Cancel
                    </Button>
                ]}
            >
                <DataList aria-label="Table column management" id="table-column-management" isCompact>
                    {
                        defaultColumns.map((obj, index) => {
                            return <DataListItem key={index} aria-labelledby={"col-mgmt-item-" + index}>
                                <DataListItemRow>
                                    <DataListCheck
                                        aria-labelledby={"col-mgmt-item-" + index}
                                        checked={checkedState[index]}
                                        name={"check" + (index + 1)}
                                        id={"check" + index}
                                        onChange={handleChange}
                                    />
                                    <DataListItemCells
                                        dataListCells={[
                                            <DataListCell id={"col-mgmt-item-" + index} key={"col-mgmt-item-" + index}>
                                                <label htmlFor={"check" + index}>{obj}</label>
                                            </DataListCell>
                                        ]}
                                    />
                                </DataListItemRow>
                            </DataListItem>
                        })
                    }
                </DataList>
            </Modal>
        );
    };

    const tableActionItems = [
        <OverflowMenuDropdownItem key="1" onClick={() => handleModalToggle()}> Manage Columns</OverflowMenuDropdownItem>,
        <OverflowMenuDropdownItem key="2" onClick={() => removeTask(selectedRows)} isDisabled={selectedRows.length == 0}> Remove</OverflowMenuDropdownItem>,
    ];

    const tableAction =
        <Dropdown onSelect={() => setIsKebabDropdownOpen(!isKebabDropdownOpen)} onOpenChange={isKebabDropdownOpen => setIsKebabDropdownOpen(isKebabDropdownOpen)} toggle={toggleRef => <MenuToggle ref={toggleRef} aria-label="overflow menu" variant="plain" onClick={() => setIsKebabDropdownOpen(!isKebabDropdownOpen)} isExpanded={false}>
            <EllipsisVIcon />
        </MenuToggle>} isOpen={isKebabDropdownOpen}>
            <DropdownList >{tableActionItems}</DropdownList>
        </Dropdown>

    const excludedSortColumns = ['action'];
    const onSort = (event, index, direction) => {
        const columnName = Object.keys(columns)[index];
        if (!excludedSortColumns.includes(columnName)) {
            setActiveSortIndex(index);
            setActiveSortDirection(direction);
        }
    };

    const handleSetPage = (_evt, newPage) => {
        setPage(newPage);
    };

    const handlePerPageSelect = (_evt, newPerPage, newPage) => {
        setPerPage(newPerPage);
        setPage(newPage);
    };

    const renderPagination = () => (
        <Pagination
            itemCount={totalItem}
            page={page}
            perPage={perPage}
            onSetPage={handleSetPage}
            onPerPageSelect={handlePerPageSelect}
        />
    );

    function buildFilterDropdown() {
        const statusMenuItems = [
            <SelectOption key="1" value="Waiting" isSelected={dataFilters.status.includes("Waiting")}>Waiting</SelectOption>,
            <SelectOption key="2" value="InProgress" isSelected={dataFilters.status.includes("InProgress")}>InProgress</SelectOption>,
            <SelectOption key="3" value="Completed" isSelected={dataFilters.status.includes("Completed")}>Completed</SelectOption>
        ];

        return <React.Fragment>
            <ToolbarFilter chips={dataFilters.name} deleteChip={onDelete} categoryName="name" showToolbarItem={currentCategory === 'Name'}>
                <SearchInput aria-label="name filter" placeholder="Filter by name..." onChange={(_event, value) => onInputChange(value)} value={inputValue} onClear={() => {
                    onInputChange('');
                }} onSearch={onNameInput} />
            </ToolbarFilter>
            <ToolbarFilter chips={dataFilters.statusChip} deleteChip={onDelete} categoryName="status" showToolbarItem={currentCategory === 'Status'}>
                <Select aria-label="Status" isOpen={isFilterDropdownOpen} onSelect={(event, selection) => onFilterSelect(event, selection, 'status', 'statusChip')} selected={dataFilters.status} toggle={toggleRef => <MenuToggle ref={toggleRef} onClick={onFilterToggle} isExpanded={isFilterDropdownOpen} style={{
                    width: '100%',
                    verticalAlign: 'text-bottom'
                }}>
                    Filter by status
                </MenuToggle>}>
                    {statusMenuItems}
                </Select>
            </ToolbarFilter>
        </React.Fragment>;
    }

    function buildCategoryDropdown() {
        const categoryMenuItems = [
            <SelectOption key="1" value="Name">Name</SelectOption>,
            <SelectOption key="2" value="Status">Status</SelectOption>
            // <SelectOption key="3" value="Severity">Severity</SelectOption>,

        ];
        return <ToolbarItem>
            <Select onSelect={onCategorySelect} selected={currentCategory} toggle={toggleRef => <MenuToggle ref={toggleRef} onClick={onCategoryToggle} isExpanded={isCategoryDropdownOpen} icon={<FilterIcon />} style={{
                width: '100%',
                verticalAlign: 'text-bottom'
            }}>
                {currentCategory}
            </MenuToggle>} isOpen={isCategoryDropdownOpen}>
                {categoryMenuItems}
            </Select>
        </ToolbarItem>;
    }

    function renderToolbar() {
        return <Toolbar id="toolbar-with-chip-groups" clearAllFilters={onDelete} collapseListedFiltersBreakpoint="xl">
            <ToolbarContent>
                <ToolbarToggleGroup toggleIcon={<FilterIcon />} breakpoint="xl">
                    <ToolbarGroup variant="filter-group" style={{
                        lineHeight: '22px',
                        alignItems: 'center'
                    }}>
                        {buildCategoryDropdown()}
                        {buildFilterDropdown()}
                    </ToolbarGroup>
                </ToolbarToggleGroup>
                <ToolbarItem variant="overflow-menu">
                    <OverflowMenu breakpoint="md">
                        <OverflowMenuGroup groupType="button" isPersistent>
                            {/* <OverflowMenuItem>
                    <Link to={'/user-profile'} className="route" ><Button variant="primary" size="sm">New</Button></Link>
                  </OverflowMenuItem> */}
                            <OverflowMenuItem isPersistent>
                                {tableAction}
                            </OverflowMenuItem>
                        </OverflowMenuGroup>
                    </OverflowMenu>
                </ToolbarItem>
                <ToolbarItem variant="pagination">{renderPagination()}</ToolbarItem>
            </ToolbarContent>
        </Toolbar>
    }

    const handleStatusChange = async (taskUniqueId, currentStatus) => {
        // Define status transitions
        const statusTransitions = {
            'Waiting': 'InProgress',
            'InProgress': 'Completed',
            'Completed': 'InProgress'
        };

        // Determine the new status
        const newStatus = statusTransitions[currentStatus] || 'Waiting';

        // Define request parameters
        const params = {
            taskUniqueId: taskUniqueId,
            status: newStatus,
            description: '', // Assuming description and title are not used in status change
            title: ''
        };

        try {
            // Send API request
            const res = await dbApi.putApi('incident_management/update_task', params);
            if (!res.error) {
                dispatch(alertBoxData([{ title: res.message, variant: 'success' }]));
                getTaskList(); // Refresh the task list to reflect the status change
            } else {
                dispatch(alertBoxData([{ title: res.message, variant: 'danger' }]));
            }
        } catch (error) {
            console.error('Error updating task status:', error);
        }
    };

    // console.log('statusIconMapping keys:', Object.keys(statusIconMapping));

    const handleModalClose = async (isSuccess) => {
        if (isSuccess) {
            await getTaskList();
        }
        setIsLoading(false);
        // childTaskDetailModalref.current.onModalClose();
    };


    const tableHeader = useMemo(() => {
        return <Thead>
            <Tr>
                <Th key={'chk'} screenReaderText='key' />
                {/* <Th key={'status'} /> */}
                {managedColumns.map((column, columnIndex) => {
                    const sortParams = {
                        sort: {
                            sortBy: {
                                index: activeSortIndex,
                                direction: activeSortDirection
                            },
                            onSort,
                            columnIndex
                        }
                    };
                    return !excludeOrder.includes(column) ? <Th modifier={!excludeWrapCol.includes(column) && 'wrap'} key={columnIndex} {...sortParams} >{column}</Th> : <Th modifier={!excludeWrapCol.includes(column) && 'wrap'} key={columnIndex} >{column}</Th>;
                })}
            </Tr>
        </Thead>

    }, [activeSortIndex, activeSortDirection, managedColumns])

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <Flex justifyContent={{ default: 'justifyContentSpaceBetween' }}>
                        <Flex spaceItems={{ default: 'spaceItemsXs' }}>
                            <FlexItem>
                                <Button variant="primary" icon={<PlusCircleIcon />} onClick={() => childTaskDetailModalref.current.setModalBox(true)}> Add Task</Button>
                            </FlexItem>
                        </Flex>
                    </Flex>
                </CardBody>
            </Card>
            {/* {tableToolbar} */}
            {renderToolbar()}
            {isLoading ? (
                <Loader />
            ) : (
                <InnerScrollContainer key="scroller">
                    <Table variant="compact" aria-label="Column Management Table">
                        {tableHeader}
                        <Tbody>
                            {rows && rows.length > 0 ? (
                                paginatedRows.map((row, rowIndex) => {
                                    const { formattedTimeStamp: createDatetime } = row.create_datetime ? formatTableTimestamp(row.create_datetime) : { formattedTimeStamp: ' ' };
                                    return (
                                        <Tr key={rowIndex + row.incident_id}>
                                            <Td
                                                key={"chk-" + rowIndex}
                                                select={{
                                                    rowIndex,
                                                    onSelect: (_event, isSelecting) => setRowSelected(row, isSelecting),
                                                    isSelected: isRowSelected(row),
                                                    isDisabled: row?.process_status == 1,
                                                }}
                                            />
                                            {
                                                typeof row?.title != 'undefined' && (
                                                    <Td dataLabel={columnNames.title}>
                                                        <Link to={`/incident-detail/${row.incident_id}/task/${row.unique_id}`}>{row.title}</Link>
                                                    </Td>
                                                )}

                                            {typeof row?.status != 'undefined' && (
                                                <Td dataLabel={columnNames.status}>
                                                    {row.status} {' '}
                                                    <Tooltip content={statusIconMapping[row.status]?.statusTooltip}>
                                                        {statusIconMapping[row.status]?.statusIcon}
                                                    </Tooltip>
                                                </Td>
                                            )}
                                            {
                                                typeof row?.create_datetime != 'undefined' && (
                                                    <Td dataLabel={columnNames.create_datetime}>{createDatetime}</Td>
                                                )}
                                            {
                                                typeof row?.assignee_name != 'undefined' && (
                                                    <Td dataLabel={columnNames.assignee_name}>{row.assignee_name}</Td>
                                                )}

                                            <Td dataLabel={columnNames.action}>
                                                <Flex>
                                                    <Tooltip content={statusIconMapping[row.status]?.actionTooltip}>
                                                        <Button
                                                            variant="plain"
                                                            onClick={() => handleStatusChange(row.unique_id, row.status)}>

                                                            {statusIconMapping[row.status]?.actionIcon}
                                                        </Button>
                                                    </Tooltip>
                                                    <Tooltip content="Delete">
                                                        <Button variant="plain" onClick={() => removeTask([row.unique_id])} aria-label="Delete task">
                                                            <Icon status="danger"> <TrashIcon /></Icon>
                                                        </Button>
                                                    </Tooltip>
                                                </Flex>
                                            </Td>

                                        </Tr>
                                    );
                                })
                            ) : (
                                <Tr>
                                    <Td colSpan={Object.keys(columns).length} key="empty-data">
                                        <EmptystateforIncident />
                                    </Td>
                                </Tr>
                            )}
                        </Tbody>

                    </Table>
                </InnerScrollContainer>
            )}
            {/* <div className='pf-v5-u-mt-md pf-v5-u-mb-md '> {renderPagination()}</div> */}
            {renderModal()}
            <ModalCommon
                title="Create a New Task"
                ref={childTaskDetailModalref}
                onclose={() => childTaskDetailModalref.current.setModalBox(false)}
            >
                <IncidentTaskModal
                    onTaskAdded={getTaskList}
                    IncidentId={id}
                    onclose={() => childTaskDetailModalref.current.setModalBox(false)}
                    onModalClose={handleModalClose}
                />
            </ModalCommon>
            <ConfirmBox confirmModal={confirmModalData} />
        </React.Fragment>
    );
};

export default IncidentDetailTask;
