import { NOT_RESPOND, BAD_REQUEST } from '../Constant/constants';
import { AxiosRequestConfig } from 'axios';

import Api from "./Api";

const header = {
  headers: {
    "Content-Type": "application/json",
  }
}

class dbApi {
  async postApi(url: any, data: any, loader: any = true) {
    let response: any = [];
    try {
      response = await Api.post(url, data, header);
    } catch (error: any) {
      if (error.response) {
        /*
        * The request was made and the server responded with a
        * status code that falls out of the range of 2xx
        */
        //console.log(error.response.data);
        return error.response.data;
        // console.log(error.response.status);
        // console.log(error.response.headers);
      } else if (error.request) {
        /*
        * The request was made but no response was received, `error.request`
        * is an instance of XMLHttpRequest in the browser and an instance 
        * of http.ClientRequest in Node.js
        */
        console.log(error.request);
        response = { 'error': true, 'message': NOT_RESPOND };
        return response;
      } else {
        // Something happened in setting up the request and triggered an Error
        //console.log('3');
        console.log('Error', error.message);
        response = { 'error': true, 'message': BAD_REQUEST };
        return response;
      }
    }
    if (response.status === 200) {
      return response.data;
    }
  }

  async postFormDataApi(url: any, data: any, loader: any = true) {
    let response: any = [];
    try {
      response = await Api.post(url, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        // Optional: Progress bar callback
        onUploadProgress: (progressEvent: any) => {
          const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          console.log(`Upload Progress: ${percentage}%`);
        },
      }
      );
    } catch (error: any) {
      if (error.response) {
        /*
        * The request was made and the server responded with a
        * status code that falls out of the range of 2xx
        */
        //console.log(error.response.data);
        return error.response.data;
        // console.log(error.response.status);
        // console.log(error.response.headers);
      } else if (error.request) {
        /*
        * The request was made but no response was received, `error.request`
        * is an instance of XMLHttpRequest in the browser and an instance 
        * of http.ClientRequest in Node.js
        */
        console.log(error.request);
        response = { 'error': true, 'message': NOT_RESPOND };
        return response;
      } else {
        // Something happened in setting up the request and triggered an Error
        //console.log('3');
        console.log('Error', error.message);
        response = { 'error': true, 'message': BAD_REQUEST };
        return response;
      }
    }
    if (response.status === 200) {
      return response.data;
    }
  }

  async getApi(url: any, fullResponse: boolean = false, responseType:any = "") {
    let response: any = [];
    try {
       const config: AxiosRequestConfig = {
        headers: {
          'Content-Type': 'application/json',  // Example header
        }
      };
      if(responseType!="")
      {
        config.responseType = responseType;
      }  
      response = await Api.get(url, config);
    } catch (error: any) {
      if (error.response) {
        /*
        * The request was made and the server responded with a
        * status code that falls out of the range of 2xx
        */
        //console.log(error.response.data);
        // console.log('1');
        return error.response.data;
        // console.log(error.response.status); 
        // console.log(error.response.headers);
      } else if (error.request) {
        /*
        * The request was made but no response was received, `error.request`
        * is an instance of XMLHttpRequest in the browser and an instance 
        * of http.ClientRequest in Node.js
        */
        // console.log('2');
        console.log(error.request);
        response = { 'error': true, 'message': NOT_RESPOND };
        return response;
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log('3');
        console.log('Error', error.message);
        response = { 'error': true, 'message': BAD_REQUEST };
        return response;
      }

    }
    if (response.status === 200) {
      return !fullResponse ? response.data : response;
    }
  }

  

  async deleteApi(url: any, data: any, loader: any = true) {
    // const queryString = new URLSearchParams(data).toString();
    // console.log(queryString)
    let response: any = [];
    try {
      // response = await Api.delete(url + '?' + queryString);
      response = await Api.delete(url, { data: data });
    } catch (error: any) {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        response = { 'error': true, 'message': NOT_RESPOND };
        return response;
      } else {
        response = { 'error': true, 'message': BAD_REQUEST };
        return response;
      }
    }
    if (response.status === 200) {
      return response.data;
    }
  }


  async putApi(url: any, data: any, loader: any = true) {
    let response: any = [];
    try {
      response = await Api.put(url, data, header);
    } catch (error: any) {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        response = { 'error': true, 'message': NOT_RESPOND };
        return response;
      } else {
        response = { 'error': true, 'message': BAD_REQUEST };
        return response;
      }
    }
    if (response.status === 200) {
      return response.data;
    }
  }

}
export default new dbApi()