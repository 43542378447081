import React, { useEffect, useState } from "react";
import {
    List,
    ListItem,
    PageSidebar,
    PageToggleButton,
    Popover,
    SearchInput,
    Truncate,
    PageSidebarBody,
    TextVariants,
    Text,
    Progress,
    ProgressSize,
    Divider,
    Title,
    Icon,
    Tooltip,
    FlexItem,
    Flex,
} from "@patternfly/react-core";
import { ExchangeAltIcon } from "@patternfly/react-icons";
import { TextSkeleton } from "../../util/Loadingstate";
import { connect, useDispatch } from "react-redux";
import dbApi from '../../services/DbApi';
import { FilterData, selectedGroup } from "../../Redux/Action";
import { UpdateFilterData } from "../../util/FilterHelper";
import { formatTableTimestamp } from "src/util/Timeutils";

const Eventsidebar = (props) => {
    const dispatch = useDispatch()
    const [isSidebarExpanded, setIsSidebarExpanded] = useState(true);
    const [isFilterPopoverOpen, setIsFilterPopoverOpen] = useState(false);

    const [isloading, setIsloading] = useState(false)
    const [topvalues, setTopValues] = useState([])
    const [searchValue, setSearchValue] = useState('')
    const [selectedKey, setSelectedKey] = useState('')
    const [filteredFieldData, setFilteredFieldData] = useState([]);
    const toggleSidebar = () => {
        setIsSidebarExpanded((prevState) => !prevState);
    };
console.log("",props.filterfield)
    const getKeysData = async (keyfield) => {
        try {
            setIsloading(true)
            const updatedFilter = props.filterfield;
            const mergedFilter = [...updatedFilter, ...props.filterformdata];
            const data = {
                startDate: props?.dates?.startDate,
                endDate: props?.dates?.endDate,
                filter: JSON.stringify(mergedFilter),
                field: keyfield,
            }
            console.log(data)
            let key_data = await dbApi.postApi("elasticsearch/Top_5", data);
            console.log(key_data)
            if (Object.keys(key_data.Top_Five).length !== 0) {
                const TopValues = key_data.Top_Five.body.aggregations.Top_5
                let topvalues = []
                if (TopValues.buckets.length > 0) {
                    topvalues = TopValues.buckets
                }
                setTopValues(topvalues)
            }
            else {
                setTopValues([])
            }
            setIsloading(false)
        }
        catch (e) {
            console.log(e)
            setIsloading(false)
        }
    }

    const onSearchChange = (value) => {
        setSearchValue(value);
    };

    // Filtering function
    useEffect(() => {
        if (searchValue === '') {
            setFilteredFieldData(props.FieldKeys);
        } else {
            let filteredData = []
            filteredData = props.FieldKeys.filter((item) =>
                item.field.toLowerCase().includes(searchValue.toLowerCase())
            );

            // const filteredData = Object.fromEntries(
            //     Object.entries(props.FieldKeys).filter(([key, value]) =>
            //         value.toLowerCase().includes(searchValue.toLowerCase())
            //     )
            // );
            // const filteredData = {};
            // Object.keys(props.FieldKeys).forEach(key => {
            //     const value = props.FieldKeys[key];
            //     if (value.toLowerCase().includes(searchValue.toLowerCase())) {
            //         filteredData[key] = value;
            //     }
            // });
            setFilteredFieldData(filteredData);
        }
    }, [searchValue, props.FieldKeys]);

    const handleUpdates = (value) => {
        const updatedFields = UpdateFilterData(props.filterformdata, selectedKey, 'is', value);
        dispatch(FilterData(updatedFields));
    }
    const displayIcon = (type) => {
        if (type === 'date') {
            return (
                <Icon size="sm" isInline className="sidebar-icon">
                    <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" className="euiIcon euiIcon--medium" focusable="false" role="img" aria-label="Date field">
                        <title>Date field</title>
                        <path fillRule="evenodd" d="M13 11.567C13 12.36 12.36 13 11.567 13H4.433C3.64 13 3 12.36 3 11.567V4.433C3 3.64 3.64 3 4.433 3H6v-.495a.51.51 0 01.412-.497L6.5 2c.276 0 .5.214.5.505V3h2v-.495a.51.51 0 01.412-.497L9.5 2c.276 0 .5.214.5.505V3h1.567C12.36 3 13 3.64 13 4.433v7.134zM4 6v5.33c0 .37.3.67.67.67h6.66c.37 0 .67-.3.67-.67V6H4zm1.5 4c.245 0 .45.183.492.412L6 10.5c0 .245-.183.45-.412.492L5.5 11a.505.505 0 01-.5-.5c0-.245.183-.45.412-.492L5.5 10zM8 10c.245 0 .45.183.492.412l.008.088c0 .245-.183.45-.412.492L8 11a.505.505 0 01-.5-.5c0-.245.183-.45.412-.492L8 10zm2.5 0c.245 0 .45.183.492.412L11 10.5c0 .245-.183.45-.412.492L10.5 11a.505.505 0 01-.5-.5c0-.245.183-.45.412-.492L10.5 10zm-5-1.5c.245 0 .45.183.492.412L6 9c0 .245-.183.45-.412.492L5.5 9.5A.505.505 0 015 9c0-.245.183-.45.412-.492L5.5 8.5zm2.5 0c.245 0 .45.183.492.412L8.5 9c0 .245-.183.45-.412.492L8 9.5a.505.505 0 01-.5-.5c0-.245.183-.45.412-.492L8 8.5zm2.5 0c.245 0 .45.183.492.412L11 9c0 .245-.183.45-.412.492L10.5 9.5A.505.505 0 0110 9c0-.245.183-.45.412-.492L10.5 8.5zM5.5 7c.245 0 .45.183.492.412L6 7.5c0 .245-.183.45-.412.492L5.5 8a.505.505 0 01-.5-.5c0-.245.183-.45.412-.492L5.5 7zM8 7c.245 0 .45.183.492.412L8.5 7.5c0 .245-.183.45-.412.492L8 8a.505.505 0 01-.5-.5c0-.245.183-.45.412-.492L8 7zm2.5 0c.245 0 .45.183.492.412L11 7.5c0 .245-.183.45-.412.492L10.5 8a.505.505 0 01-.5-.5c0-.245.183-.45.412-.492L10.5 7zM4 5h8v-.33c0-.37-.3-.67-.67-.67H4.67C4.3 4 4 4.3 4 4.67V5z"></path></svg>
                </Icon>

            );
        }
        else if (type === 'geo_point') {
            return (
                <Tooltip content={"geo_point field"}>
                    <Icon size="sm" isInline className="sidebar-icon">
                        <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" className="euiIcon euiIcon--medium" focusable="false" role="img" aria-label="Geo point field">
                            <title>Geo point field</title>
                            <path fillRule="evenodd" d="M8 3c1.636 0 3.088.785 4 2 .628.836 1 1.875 1 3a4.978 4.978 0 01-.999 3H12a4.992 4.992 0 01-4 2 4.992 4.992 0 01-4-1.999V11a5 5 0 014-8zm.948 8H7.052c.277.626.623 1 .948 1 .325 0 .67-.374.948-1zM6 11l-.645.001c.274.242.581.446.914.606A5.445 5.445 0 016 11.001zm4.645.001H10a5.51 5.51 0 01-.269.606c.333-.16.64-.364.914-.606zm-5.133-2.5H4.031c.068.54.243 1.048.505 1.5h1.172a9.186 9.186 0 01-.196-1.5zm3.975 0H6.513c.03.544.104 1.05.21 1.5h2.553c.107-.45.182-.956.21-1.5zm2.482 0h-1.481a9.186 9.186 0 01-.196 1.5h1.172c.262-.452.437-.96.505-1.5zM5.708 6L4.535 6c-.261.452-.437.96-.504 1.5h1.481A9.187 9.187 0 015.708 6zm3.568 0H6.724c-.107.449-.182.955-.21 1.499h2.973a8.479 8.479 0 00-.21-1.5zM11.465 6h-1.173c.102.467.17.972.196 1.5h1.481a3.974 3.974 0 00-.504-1.5zM6.269 4.393l-.124.062c-.286.15-.551.333-.79.545H6a5.51 5.51 0 01.269-.607zM8 4c-.326 0-.671.375-.948 1h1.896C8.671 4.376 8.326 4 8 4zm1.73.393l.038.071c.083.168.161.347.232.536h.646a4.006 4.006 0 00-.915-.607z"></path></svg>

                    </Icon>
                </Tooltip>)
        }
        else if (type === 'integer' || type === 'long') {
            return (
                <Icon size="sm" isInline className="sidebar-icon">
                    <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" className="euiIcon euiIcon--medium euiIcon-isLoaded" focusable="false" role="img" aria-label="Number field">
                        <title>Number field</title>
                        <path d="M7.8 9.812h-.842l-.78 2.335H5.031l.78-2.335H4.6l.182-1.043h1.373l.507-1.504H5.454l.188-1.042h1.367l.792-2.37H8.94l-.792 2.37h.849l.792-2.37h1.145l-.792 2.37H11.4l-.182 1.042H9.8L9.293 8.77h1.248l-.183 1.043H8.946l-.775 2.335H7.026L7.8 9.812zm-.5-1.043h.842l.513-1.504h-.849L7.3 8.77z"></path></svg>
                </Icon>
            )
        }
        else if (type === 'boolean') {
            return (
                <Icon size="sm" isInline className="sidebar-icon">
                    <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" className="euiIcon euiIcon--medium" focusable="false" role="img" aria-label="Boolean field">
                        <title>Boolean field</title>
                        <path fillRule="evenodd" d="M8 13A5 5 0 118 3a5 5 0 010 10zm-2.828-2.172a4 4 0 015.656-5.656c.004.013-5.645 5.674-5.656 5.656z"></path></svg>
                </Icon>
            )
        }
        else {
            return (
                <Icon isInline size="sm" className="sidebar-icon">
                    <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" role="img" aria-label="String field">
                        <title>String field</title>
                        <path d="M9.147 4.297l-.255 1.455h.936l-.222 1.266h-.935l-.455 2.688c-.025.184-.013.323.036.417.048.093.17.144.365.151.075.004.23-.005.465-.027l-.13 1.32c-.3.097-.618.142-.957.135-.552-.007-.965-.17-1.239-.487-.274-.317-.386-.748-.335-1.293l.476-2.904h-.725l.216-1.266h.725l.254-1.455h1.78z"></path>
                    </svg>
                </Icon>
            )
        }
    }
    const sidebarin = (
        <div className="pf-v5-u-p-md">
            <div className="pf-v5-u-mt-sm">
                <SearchInput placeholder="Search field names"
                    value={searchValue}
                    onChange={(_event, value) => onSearchChange(value)}
                    onClear={() => onSearchChange('')}
                />
            </div>
            
            {/* <Popover
                showClose={false}
                hasAutoWidth
                headerContent={<div>FILTER BY TYPE</div>}
                bodyContent={
                    <div>
                        <Form isHorizontal>
                            <FormGroup label="Aggregatable">
                                <ToggleGroup aria-label="Default with single selectable">
                                    <ToggleGroupItem text="Any" buttonId="toggle-group-single-1" />
                                    <ToggleGroupItem text="Yes" buttonId="toggle-group-single-2" />
                                    <ToggleGroupItem text="No" buttonId="toggle-group-single-3" />
                                </ToggleGroup>
                            </FormGroup>
                            <FormGroup label="Searchable">
                                <ToggleGroup aria-label="Default with single selectable">
                                    <ToggleGroupItem text="Any" buttonId="toggle-group-single-4" />
                                    <ToggleGroupItem text="Yes" buttonId="toggle-group-single-5" />
                                    <ToggleGroupItem text="No" buttonId="toggle-group-single-6" />
                                </ToggleGroup>
                            </FormGroup>
                            <FormGroup label="Type">
                                <ToggleGroup aria-label="Default with single selectable">
                                    <ToggleGroupItem text="Any" buttonId="toggle-group-single-7" />
                                    <ToggleGroupItem text="Yes" buttonId="toggle-group-single-8" />
                                    <ToggleGroupItem text="No" buttonId="toggle-group-single-9" />
                                </ToggleGroup>
                            </FormGroup>
                        </Form>
                    </div>
                }
                footerContent={
                    <Switch id="simple-switch" label="Hide missing fields" hasCheckIcon />
                }
            >
                <Button variant="link" icon={<FilterAltIcon />} size="sm">
                    Filter by type
                </Button>
            </Popover> */}
            {/* <List isPlain>
                <b>Selected fields</b>
                <ListItem>agent.name</ListItem>
                <ListItem>rule.description</ListItem>
                <ListItem>rule.level</ListItem>
                <ListItem>rule.id</ListItem>
            </List> */}

            {props.Loading ?
                <List isPlain>
                    <div>
                        <div><b> available fields</b></div>
                        <TextSkeleton /><br />
                        <TextSkeleton /><br />
                        <TextSkeleton /><br />
                        <TextSkeleton /><br />
                        <TextSkeleton /><br />
                        <TextSkeleton /><br />
                    </div>
                </List>
                : props.Count > 0 ?
                    props.FieldKeys && (
                        <List isPlain>
                            {" "}
                            <b key="availablefield"> available fields</b>
                            {filteredFieldData.toSorted().map((key, index) => (
                                <Popover
                                    aria-label="Basic popover"
                                    showClose={false}
                                    minWidth="300px"
                                    key={"Popover +" + index}
                                    position="right"
                                    hideOnOutsideClick={true}
                                    headerContent={
                                        <div>
                                            <Text component={TextVariants.h1}>TOP 5 VALUES ({selectedKey})</Text>
                                        </div>
                                    }
                                    bodyContent={
                                        <React.Fragment>
                                            {isloading ?
                                                <div>
                                                    <Divider />
                                                    <TextSkeleton /><br />
                                                    <TextSkeleton /><br />
                                                    <TextSkeleton /><br />
                                                    <TextSkeleton /><br />
                                                    <TextSkeleton /><br />
                                                </div> : topvalues.length == 0 ? <> <Divider />No Values</> :

                                                    topvalues.map((bucket, index) => {
                                                        let title = ''
                                                        if (bucket.key_as_string) {
                                                            const { formattedTimeStamp } = formatTableTimestamp(bucket.key_as_string)
                                                            title = formattedTimeStamp
                                                        }
                                                        else {
                                                            title = bucket.key
                                                        }
                                                        return (
                                                            <div key={`progress-container-${index}`} style={{ marginBottom: '13px' }}>
                                                                <a className='pf-v5-u-color-100'>
                                                                    <Progress value={bucket.percentages} key={'progress' + index} label={`${bucket.percentages}%`} aria-label={`${bucket.key}`}
                                                                        style={{ "--pf-v5-c-progress--GridGap": "0" }}
                                                                        title={title}
                                                                        onClick={() => { handleUpdates(bucket.key) }}
                                                                        isTitleTruncated
                                                                        size={ProgressSize.sm} />
                                                                </a>
                                                            </div>
                                                        )
                                                    })}
                                        </React.Fragment>
                                    }
                                >
                                    <ListItem key={key + 'field'}>
                                        <Flex spaceItems={{ default: 'spaceItemsXs' }} display={{ default: 'inlineFlex' }} alignItems={{ default: 'alignItemsCenter' }} className='pf-v5-u-display-flex pf-v5-u-flex-nowrap'>
                                            <FlexItem>{displayIcon(key.type)}</FlexItem>
                                            <FlexItem><a> <Truncate
                                                content={key.field}
                                                key={key.field + 'truncate'}
                                                onClick={() => {
                                                    getKeysData(key.field);
                                                    setIsFilterPopoverOpen(true);
                                                    setSelectedKey(key.field)
                                                }} /> </a></FlexItem>
                                        </Flex>
                                    </ListItem>
                                </Popover>

                            ))}
                        </List>
                    )
                    :
                    <b>No available Fields</b>
            }
        </div >
    );
    return (<>
        {/* {sidebarin} */}
        <PageSidebar theme="light" className="PageSidebar">
            <PageSidebarBody>{sidebarin}</PageSidebarBody>
        </PageSidebar>
        <PageToggleButton variant="plain" id="nav-toggle">
            <ExchangeAltIcon onClick={toggleSidebar} />
        </PageToggleButton>
    </>
    )
}
const mapStateToProps = (state) => ({
    dates: { startDate: state.startdate, endDate: state.enddate },
    filterformdata: state.FilterData,
    groupindex: state.groupIndexName,
    selectedGroup: state.SelectedGroup,
    FieldData: state.FieldData
    // mapStateToProps content remains unchanged if you have it
});

export default connect(
    mapStateToProps,
    null,
    null,
    { forwardRef: true }
)(Eventsidebar)