import * as echarts from 'echarts';
import React, { useEffect } from 'react';
import { generateUniqueID } from '../../util/UniqueIdGenerator';
import { connect } from 'react-redux';
 const ThreatRatio = ({ RatioData, height, legend,theme,sidebarState }) => {
    const id = generateUniqueID()
    useEffect(() => {
        const chartDom = document.getElementById(id + 'trr');
        const myChart = echarts.init(chartDom);
        const legendData = RatioData.map((item) => item.type);
        const textcolor = theme == 'dark-theme' ? '#fff' : '#000'
        //  console.log(legendData)
        const option = {
            tooltip: {
                trigger: 'item',
                position: [30, 30],
                textStyle: {
                    color: "#000"
                },
                formatter: function (params) {
                    return params.data.name + ' : ' + params.data.value + '%'; // Display the full word and count in the tooltip
                },
            },
            legend: {
                show: legend,
                orient: 'horizontal',
                top: "bottom",
                
                itemWidth: 9.5,
                itemHeight: 10,
                itemGap: 6.5,
                icon: "rect",
                textStyle: {
                    fontSize: 12,
                    color:textcolor
                },
                data: legendData
            },
            color: ['#3e8635', '#C9190B'],
            series: [
                {
                    type: 'pie',
                    radius: '80%',
                    center: ['45%', '45%'],
                    label: {
                        show: false,
                    },
                    data: RatioData.map(item => ({
                        value: parseFloat(item.percentages)?parseFloat(item.percentages):0,
                        name: item.type,
                    })
                    )
                }
            ]
        };

        option && myChart.setOption(option);
        const resizeChart = () => {
            if (myChart) { // Check if chart exists before resizing
              myChart.resize();
            }
          };

        window.addEventListener('resize', resizeChart);
        return () => {
            myChart.dispose();
            window.removeEventListener('resize', resizeChart);
        };

    }, [RatioData, height, legend,theme,sidebarState])

    return <div className="container">
        <div id={id + 'trr'} style={{ width: '100%', height: height }} ></div>
    </div>;
}
const mapStateToProps = (state) => ({
    dates: { startDate: state.startdate, endDate: state.enddate },
    filterformdata: state.FilterData,
    theme: state.theme,
    sidebarState:state.sidebarstate
    // mapStateToProps content remains unchanged if you have it
});

export default connect(
    mapStateToProps,
    null,
    null,
    { forwardRef: true }
)(ThreatRatio)