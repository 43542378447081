// import React from 'react';
// import { Chart, ChartAxis, ChartBar, ChartStack, ChartVoronoiContainer, } from '@patternfly/react-charts';
// import { getResizeObserver } from '@patternfly/react-core';
// import { emptystatefornodata } from '../../util/emptystate';

// export default class TopSCAAsset extends React.Component {

//   constructor() {
//     super();
//     this.containerRef = React.createRef();
//     this.state = {
//       width: 0,
//       passeddata: [],
//       faileddata: [],
//       notappdata:[],
//       xTickValues: [],
//     };
//     this.handleResize = () => {
//       if (this.containerRef.current && this.containerRef.current.clientWidth) {
//         this.setState({ width: this.containerRef.current.clientWidth });
//       }
//     };
//     this.legendData = [
//       { name: 'Passed', symbol: { fill: '#3e8635' } },
//       { name: 'Failed', symbol: { fill: '#A30000' } },
//       { name: 'Not Applicable', symbol: { fill: '#0066CC' } },
//     ];

//   }
//   componentDidMount() {
//     this.observer = getResizeObserver(this.containerRef.current, this.handleResize);
//     this.handleResize();
//   }
//   componentDidUpdate(prevProps) {
//     const passeddata = []
//     const faileddata = []
//     const notappdata = []
//     const xTickValues = []

//     if (this.props.AssetsData !== prevProps.AssetsData) {
//       const { AssetsData } = this.props;
//       // console.log("data-->>",AssetsData)
//       if (AssetsData && AssetsData.length > 0) {
//         AssetsData.sort((a, b) => {
//           const aMaxCount = Math.max(
//            a.value = a.totalpassed+a.totalfailed+a.totalnot_applicable,
//           );
//           const bMaxCount = Math.max(
//            b.value = b.totalpassed+b.totalfailed1+b.totalnot_applicable, 
//           );
//           return aMaxCount - bMaxCount;
//         });
//         // console.log("34567",AssetsData)

//         for (let i = 0; i < AssetsData.length; i++) {
//           xTickValues.push({
//             xlabel: AssetsData[i].key
//           })
//           passeddata.push({
//             name: 'passed',
//             x: AssetsData[i].key,
//             y: AssetsData[i].totalpassed
//           })
//           faileddata.push({
//             name: 'failed',
//             x: AssetsData[i].key,
//             y: AssetsData[i].totalfailed

//           })
//           notappdata.push({
//             name: 'not applicable',
//             x: AssetsData[i].key,
//             y: AssetsData[i].totalnot_applicable
//           })
//         }
//       }
//       this.setState({
//         xTickValues: xTickValues,
//         passeddata: passeddata,
//         faileddata: faileddata,
//         notappdata:notappdata

//       })
//     }
//   }
//   componentWillUnmount() {
//     this.observer();
//   }

//   render() {
//     const { width, xTickValues, passeddata,faileddata,notappdata } = this.state;
//     const { AssetsData,height } = this.props;
//     return (<div ref={this.containerRef}>
//       {AssetsData.length === 0 ? (
//        emptystatefornodata()
//       ) :
//         (
//           <Chart
//             ariaDesc="Top Assets Vulnerable"
//             ariaTitle="Top Assets Vulnerable"
//             containerComponent={<ChartVoronoiContainer  labels={({ datum }) => `${datum.name}: ${datum.y}`} constrainToVisibleArea />}
//             domainPadding={{ x: [30, 25] }}
//             legendData={this.legendData}
//             legendOrientation="vertical"
//             legendPosition="right"
//             height={height}
//             name="Assets Vulnerable"
//             padding={{
//               bottom: 55,
//               left: 160,
//               right: 140, // Adjusted to accommodate legend
//               top: 10,
//             }}
//             width={width}
//           >
//             <ChartAxis  tickValues={xTickValues} invertAxis />
//             <ChartAxis dependentAxis showGrid  label='count'  />
//             <ChartStack horizontal>
//               <ChartBar  data={passeddata} colorScale={['#3e8635']}/>
//               <ChartBar data={faileddata} colorScale={['#A30000']}/>
//               <ChartBar data={notappdata} colorScale={['#0066CC']}/>
//             </ChartStack>
//           </Chart>
//         )}
//     </div>)
//   }
// }


import * as echarts from 'echarts';
import React, { useEffect } from 'react';
import { generateUniqueID } from 'src/util/UniqueIdGenerator';
import { formatValue } from 'src/util/ChartHelper';
import { UpdateFilterData } from 'src/util/FilterHelper';
import { FilterData } from 'src/Redux/Action';
import { connect, useDispatch } from 'react-redux';
const TopSCAAsset = (props) => {
  const id = generateUniqueID()
  const dispatch = useDispatch();
  const handleFilter = (fields, value) => {
    const updatedFields = UpdateFilterData(props.filterformdata, fields, 'is', value);
    dispatch(FilterData(updatedFields));
  }
  useEffect(() => {
    if (props.AssetsData && props.AssetsData.length > 0) {
      const chartDom = document.getElementById(id + 'scagent');
      const myChart = echarts.init(chartDom);
      const textcolor = props.theme == 'dark-theme' ? '#fff' : '#000'
  
 const linecolor = props.theme == 'dark-theme' ? '#595757' : '#d9d9d9'
      const YAxisData = props.AssetsData.map(entry => ({ y: entry.agent_name }));
      const option = {
        tooltip: {
          trigger: 'axis',
          textStyle: {
            color: "#000"
          }
        },
        title: {
          text: 'Count',
          top: 'bottom',
          left: 'center',
          textStyle: {
            fontWeight: 450,
            fontSize: 14,
            color: textcolor,
            fontfamily: 'RedHatText, helvetica, arial, sans-serif',
          },
        },
        legend: {
          type: 'scroll',
          orient: 'vertical',
          right: 0,
          top: 0,
          itemWidth: 9.5,
          itemHeight: 10,
          itemGap: 6.5,
          textStyle: {
            width: 90
          },
          icon: "rect",
          textStyle: {
            color:textcolor
          },
          data: ['Passed', 'Failed', 'Not Applicable']
        },
        grid: {
          left: '1%',
          right: '18%',
          bottom: '10%',
          top: '8%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          formatter: formatValue,
          axisLine: {
            show: true
          }, splitLine: {
            lineStyle: {
              color: linecolor
            }
          },
          axisLabel: {color:textcolor },
        },
        yAxis: {
          type: 'category',
          inverse: true,
          axisLabel: {
            interval: 0,
            rotate: 20,
            width: 70,
            overflow: 'truncate',
            fontSize: 12,
            color:textcolor
          },
          boundaryGap: true,
          data: YAxisData.map(item => item.y),
          axisTick: {
            alignWithLabel: true
          },

        },
        series: [
          {
            name: 'Passed',
            type: 'bar',
            stack: 'total',
            barWidth: 10,
            emphasis: {
              focus: 'series',
            },
            itemStyle: {
              color: '#3e8635',
            },
            data: props.AssetsData.map(item => item.passed_count)
          },
          {
            name: 'Failed',
            type: 'bar',
            stack: 'total',
            barWidth: 10,
            emphasis: {
              focus: 'series',
            },
            itemStyle: {
              color: '#A30000', // Red for active, Green for solved
            },
            data: props.AssetsData.map(item => item.failed_count)
          },
          {
            name: 'Not Applicable',
            type: 'bar',
            stack: 'total',
            barWidth: 10,
            emphasis: {
              focus: 'series',
            },
            itemStyle: {
              color: '#0066CC', // Red for active, Green for solved
            },
            data: props.AssetsData.map(item => item.not_applicable)
          },
        ]
      };
      option && myChart.setOption(option);
      myChart.on('legendselectchanged', function (param) {
        handleFilter('data.sca.check.result', param.name.toLowerCase())
      });
      const resizeChart = () => {
        if (myChart) { // Check if chart exists before resizing
          myChart.resize();
        }
      };

    window.addEventListener('resize', resizeChart);
    return () => {
        myChart.dispose();
        window.removeEventListener('resize', resizeChart);
    };

    }

  }, [props.AssetsData, props.height, props.theme,props.sidebarState])
  return <div className="container">
    <div id={id + 'scagent'} style={{ width: '100%', height: props.height }} ></div>
  </div>;
}
const mapStateToProps = (state) => ({
  dates: { startDate: state.startdate, endDate: state.enddate },
  filterformdata: state.FilterData,
  theme: state.theme,
  sidebarState:state.sidebarstate
  // mapStateToProps content remains unchanged if you have it
});

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(TopSCAAsset)
