import {
    Toolbar,
    ToolbarContent,
    ToolbarItem,
    Icon,
    Pagination,
    SearchInput,
    Flex,
    FlexItem,
    Tooltip,
    Text,
    Button,
    Select,
    MenuToggle,
    SelectGroup,
    SelectList,
    SelectOption,
    TextVariants,
    ToolbarGroup,
} from '@patternfly/react-core'
import { ExportIcon, EyeIcon, PencilAltIcon, RedoIcon, SortAmountDownIcon } from '@patternfly/react-icons'
import { Table, Tbody, Td, Th, Thead, Tr } from '@patternfly/react-table'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import dbApi from 'src/services/DbApi'
import { Emptystatefornodata } from 'src/util/EmptyStateCard'
import { Loader } from 'src/util/Loadingstate'

const GroupFileTable = (props) => {
    const defaultColumns = {
        filename: 'File',
        hash: "Checksum",
        actions: "Action",
    };
    const [columns, setColumns] = useState(defaultColumns);
    const [rows, setRows] = useState([]);
    const [selectedRowName, setSelectedRowName] = useState('');
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [isSortDropdownOpen, setIsSortDropdownOpen] = useState(false);
    const [activeSortIndex, setActiveSortIndex] = useState(0);
    const [totalItem, setTotalItem] = useState(0)
    const [activeSortDirection, setActiveSortDirection] = useState('asc');
    const [inputValue, setInputValue] = useState('')
    const [searchValue, setSearchValue] = useState('');
    const [isloadfiles, setIsLoadFiles] = useState(false)


    const excludeOrder = ['Action']
    const getGroupFile = async () => {
        let sort = ''
        if (activeSortDirection === 'asc') {
            sort = `+${Object.keys(columns)[activeSortIndex]}`
        }
        else {
            sort = `-${Object.keys(columns)[activeSortIndex]}`
        }
        let param = {
            offset: (perPage * page) - perPage,
            limit: perPage,
            search: searchValue,
            sort: sort,
            name: props.GroupName
        }
        try {
            setIsLoadFiles(true)
            let res = await dbApi.postApi("Opensearch/Files_Group", param)
            console.log(res)
            let files = []
            if (res.File_List.length > 0) {
                files = res.File_List
                setRows(files)
                setTotalItem(res.total)
            } else {
                setRows([])
                setTotalItem(0)
            }
            setIsLoadFiles(false)
        } catch (error) {
            console.log(error)
            setIsLoadFiles(false)
        }
    }

    useEffect(() => {
        getGroupFile()
    }, [page,
        perPage,
        activeSortIndex,
        activeSortDirection, searchValue, props.GroupName])


    const onSort = (event, index, direction) => {
        setActiveSortIndex(index);
        setActiveSortDirection(direction);
    };
    const handleSetPage = (_evt, newPage) => {
        setPage(newPage);
    };
    const handlePerPageSelect = (_evt, newPerPage) => {
        setPerPage(newPerPage);
    };

    const onSearch = event => {
        if (event.key && event.key !== 'Enter') {
            return;
        }
        setSearchValue(inputValue)
        setPage(1)
    }
    const renderPagination = (variant) => (
        <Pagination
            itemCount={totalItem}
            page={page}
            perPage={perPage}
            onSetPage={handleSetPage}
            onPerPageSelect={handlePerPageSelect}
            variant={variant}
            titles={{
                paginationAriaLabel: `${variant} pagination`
            }}
        />
    );
    const tabletoolbar = <React.Fragment>
        <Toolbar>
            <ToolbarContent>
                <ToolbarItem >
                    <Text component={TextVariants.h1} className="pf-v5-u-font-weight-bold">Files ({totalItem})
                        <br />
                        <Text component={TextVariants.small}>From here you can list and see your group files, also, you can edit the group configuration</Text>
                    </Text>
                </ToolbarItem>
                <ToolbarGroup align={{ default: 'alignRight' }}>
                    <ToolbarItem spacer={{ default: 'spacerSm' }}>
                        <Button variant="link" icon={<RedoIcon />} onClick={() => { getGroupFile() }}>Refresh</Button>
                    </ToolbarItem>
                    <ToolbarItem >
                        <Button variant="link" icon={<ExportIcon />} onClick={() => { }}>Export Formatted</Button>
                    </ToolbarItem>
                </ToolbarGroup>

            </ToolbarContent>
        </Toolbar>
        <Toolbar>
            <ToolbarContent >
            <ToolbarItem visibility={{ md: 'hidden' }}>
                    <Select isOpen={isSortDropdownOpen} selected={[activeSortDirection, activeSortIndex]} onOpenChange={isOpen => setIsSortDropdownOpen(isOpen)} onSelect={(event, value) => {
                        if (value === 'asc' || value === 'desc') {
                            onSort(event, activeSortIndex, value);
                        } else {
                            onSort(event, value, activeSortDirection !== 'none' ? activeSortDirection : 'asc');
                        }
                    }} toggle={toggleRef => <MenuToggle ref={toggleRef} onClick={() => setIsSortDropdownOpen(!isSortDropdownOpen)} isExpanded={isSortDropdownOpen} variant="plain">
                        <SortAmountDownIcon />
                    </MenuToggle>}>
                        <SelectGroup label="Sort column">
                            <SelectList>
                                {Object.keys(columns).map((column, columnIndex) => <SelectOption key={column} value={columnIndex} isSelected={activeSortIndex === columnIndex}>
                                    {column}
                                </SelectOption>)}
                            </SelectList>
                        </SelectGroup>
                        <SelectGroup label="Sort direction">
                            <SelectList>
                                <SelectOption isSelected={activeSortDirection === 'asc'} value="asc" key="ascending">
                                    Ascending
                                </SelectOption>
                                <SelectOption isSelected={activeSortDirection === 'desc'} value="desc" key="descending">
                                    Descending
                                </SelectOption>
                            </SelectList>
                        </SelectGroup>
                    </Select>
                </ToolbarItem>
                <ToolbarItem style={{ width: '60%' }} variant="search-filter">
                    <SearchInput placeholder="Search..."
                        value={inputValue}
                        onChange={(_event, value) => setInputValue(value)}
                        onSearch={onSearch}
                        onClear={() => { setSearchValue(''); setInputValue('') }} />
                </ToolbarItem>
                <ToolbarItem align={{ default: 'alignRight' }}>
                    <ToolbarItem> {renderPagination('top')}</ToolbarItem>
                </ToolbarItem>
            </ToolbarContent>
        </Toolbar>
    </React.Fragment>




    return (<React.Fragment>
        {isloadfiles ? <Loader /> :
            <>
                {tabletoolbar}
                <Table variant='compact'>
                    <Thead>
                        <Tr>
                            {Object.values(columns).map((column, columnIndex) => {
                                const stickyColumnsIndex = [0, 1];
                                const isSticky = stickyColumnsIndex.includes(columnIndex);
                                const sortParams = {
                                    sort: {
                                        sortBy: {
                                            index: activeSortIndex,
                                            direction: activeSortDirection
                                        },
                                        onSort,
                                        columnIndex
                                    }
                                };
                                return !excludeOrder.includes(column) ? <Th key={columnIndex} {...sortParams} isStickyColumn={isSticky} >{column}</Th> : <Th key={columnIndex} isStickyColumn={isSticky}>{column}</Th>;
                            })}
                        </Tr>
                    </Thead>
                    <Tbody>
                        {rows.length > 0 ?
                            rows && rows.map((row, rowIndex) => {

                                return (
                                    <>
                                        <Tr key={rowIndex + 'agentid'}
                                            onRowClick={() => setSelectedRowName(rowIndex)}
                                            isSelectable
                                            isClickable
                                            isRowSelected={selectedRowName === rowIndex}
                                        >
                                            <Td width={20} dataLabel={columns.filename} key={rowIndex + 'name'}>{row?.filename}</Td>
                                            <Td width={60} dataLabel={columns.hash} key={rowIndex + 'hash'}>{row?.hash}</Td>
                                            <Td width={20} dataLabel={columns.actions} key={rowIndex + 'action'} isActionCell>
                                                <div>
                                                    <Flex spaceItems={{ default: 'spaceItemsSm' }}>
                                                        <FlexItem> <Link to={"/management/groups-config/" + props.GroupName +  '/' +row?.filename + '/' + '2'}><Tooltip content={'view'} ><Icon status="info"><EyeIcon /></Icon></Tooltip></Link></FlexItem>
                                                        {row?.filename == 'agent.conf' && <FlexItem> <Link to={"/management/groups-config/" + props.GroupName+ '/' + row?.filename+ '/' + '1'}><Tooltip content={`Edit ${row?.filename}`} ><Icon status="info" ><PencilAltIcon /></Icon></Tooltip> </Link></FlexItem>}
                                                    </Flex>
                                                </div>


                                            </Td>

                                        </Tr>
                                    </>
                                )
                            })
                            :
                            <Tr key='empty-row'>
                                <Td colSpan={10} key='empty-data'>
                                    <Emptystatefornodata />
                                </Td>
                            </Tr>
                        }

                    </Tbody>
                </Table>
                {renderPagination('bottom')}
            </>
        }
    </React.Fragment>

    )
}

export default GroupFileTable