import React, { useState, useRef, useEffect } from 'react';
import {
    PageSection,
    PageSectionVariants,
    Tooltip,
    Tabs,
    Tab,
    TabTitleText,
    TabContentBody,
} from '@patternfly/react-core';
import EventBar from '../CommonEventPages/EventBar';
import MitreAttacksDashboard from './MitreAttacksDashboard';
import MitreAttacksEvents from './MitreAttacksEvents';
import AddFilterForm from '../CommonEventPages/AddFilterForm';
import MitreIntelligence from './MitreIntelligence';
import MitreFramework from './MitreFramework';

const MitreAttack = () => {

    const [activeTabKey, setActiveTabKey] = useState(0);
    const handleTabClick = (_event, tabIndex) => {
        setActiveTabKey(tabIndex);
    };
    const filterfield = [{
        'fields': 'rule.mitre.id',
        'condition': 'exists',
    }]

    return (
        <div>
            <PageSection variant={PageSectionVariants.light} padding={{ default: 'noPadding' }}>
                <Tabs variant='light300' activeKey={activeTabKey} onSelect={handleTabClick} aria-label="Tabs-individual" role="region" isOverflowHorizontal isBox width={40}>
                    <Tab eventKey={0} title={<TabTitleText className='pf-v5-u-font-weight-bold'>Dashboard</TabTitleText>} aria-label="Horizontal overflow content users">
                        {activeTabKey == 0 &&
                            <TabContentBody className='pf-v5-u-p-xs'>
                                <EventBar />
                                <AddFilterForm filter={filterfield} />
                                <MitreAttacksDashboard hideCard={false} />
                            </TabContentBody>}
                    </Tab>
                    <Tab eventKey={1} title={<TabTitleText className='pf-v5-u-font-weight-bold'>Intelligence</TabTitleText>} aria-label="Horizontal overflow content users">
                        {activeTabKey == 1 &&
                            <TabContentBody className='pf-v5-u-p-xs'>
                                <MitreIntelligence />
                            </TabContentBody>}
                    </Tab>
                    <Tab eventKey={2} title={<TabTitleText className='pf-v5-u-font-weight-bold'>Framework</TabTitleText>} aria-label="Horizontal overflow content users">
                        {activeTabKey == 2 &&
                            <TabContentBody className='pf-v5-u-p-xs'>
                                <EventBar />
                                <AddFilterForm filter={filterfield} />
                                <MitreFramework onIconClick={handleTabClick} />
                            </TabContentBody>}
                    </Tab>
                    <Tab eventKey={3} title={<TabTitleText className='pf-v5-u-font-weight-bold'>Events</TabTitleText>} aria-label="Horizontal overflow content users">
                        {activeTabKey == 3 &&
                            <TabContentBody className='pf-v5-u-p-xs'>
                                <EventBar />
                                <AddFilterForm filter={filterfield} />
                                <MitreAttacksEvents />
                            </TabContentBody>}
                    </Tab>
                </Tabs>
            </PageSection>
        </div>
    );
};

export default MitreAttack;
