import * as echarts from 'echarts';
import React, { useEffect } from 'react';
import { generateUniqueID } from '../../util/UniqueIdGenerator';
import { connect } from 'react-redux';
const VulnerabilityStatus = ({ StatusData, TotalVulnerability, theme }) => {
    const id = generateUniqueID()
    useEffect(() => {
        const chartDom = document.getElementById(id + 'vulstat');
        const myChart = echarts.init(chartDom);
        const textcolor = theme == 'dark-theme' ? '#fff' : '#000'
        const colors = {
            Critical: '#A30000',
            High: '#C9190B',
            Medium: '#f0ab00',
            Low: '#2B9AF3'
        };
        let seriesData = []
        if (TotalVulnerability === 0) {
            seriesData = [{
                name: 'healthy',
                value: TotalVulnerability,
            }]

        }
        else {
            seriesData = Object.keys(StatusData).map(key => ({
                name: key,
                value: StatusData[key],
            }));
        }

        const option = {
            tooltip: {
                trigger: 'item',
                textStyle: {
                    color: "#000"
                }
            },

            color: TotalVulnerability === 0 ? '#3e8635' : seriesData.map(item => colors[item.name]),
            series: [
                {
                    type: 'pie',
                    radius: ['45%', '35%'],
                    center: ['52%', '30%'],
                    avoidLabelOverlap: false,
                    labelLine: {
                        length: 1,
                        length2: 0,
                      },
                    label: {
                        fontSize:9.5,
                        minMargin: 1.3,
                        edgeDistance: 1.5,
                        lineHeight: 1,
                        show: true,
                        color:textcolor,
                        
                        formatter(param) {
                            return param.name + ' (' + param.value + ')';
                        }
                    },
                    data: seriesData
                }
            ]
        };

        option && myChart.setOption(option);
        return () => {
            myChart.dispose();
        };

    }, [StatusData, theme])

    return <div className="container">
        <div id={id + 'vulstat'} style={{ width: '100%', height: '170px' }} ></div>
    </div>;
}
const mapStateToProps = (state) => ({
    dates: { startDate: state.startdate, endDate: state.enddate },
    filterformdata: state.FilterData,
    theme: state.theme
    // mapStateToProps content remains unchanged if you have it
});

export default connect(
    mapStateToProps,
    null,
    null,
    { forwardRef: true }
)(VulnerabilityStatus)