import React, { useState, useRef, useEffect } from 'react';
import {
    PageSection,
    PageSectionVariants,
    Tooltip,
    Tabs,
    Tab,
    TabTitleText,
    TabContentBody,
} from '@patternfly/react-core';
import PolicyDashboard from './PolicyDashboard';
import PolicyEvents from './PolicyEvents';
import EventBar from '../CommonEventPages/EventBar';
import AddFilterForm from '../CommonEventPages/AddFilterForm';

const PolicyMonitoring = () => {
    const [activeTabKey, setActiveTabKey] = useState(0);
    const handleTabClick = (_event, tabIndex) => {
        setActiveTabKey(tabIndex);
    };
    const filterfield = [{
        'fields': 'rule.groups',
        'condition': 'is',
        'value': 'rootcheck'
    }]
    return (
        <div>
            <PageSection variant={PageSectionVariants.light} padding={{ default: 'noPadding' }}>
                <Tabs variant='light300' activeKey={activeTabKey} onSelect={handleTabClick} aria-label="Tabs-individual" role="region" isOverflowHorizontal isBox width={40}>
                    <Tab eventKey={0} title={<TabTitleText className='pf-v5-u-font-weight-bold'>Dashboard</TabTitleText>} aria-label="Horizontal overflow content users">
                        {activeTabKey == 0 &&
                            <TabContentBody className='pf-v5-u-p-xs'>
                                <EventBar />
                                <AddFilterForm filter={filterfield} />
                                <PolicyDashboard hideCard={false} />
                            </TabContentBody>}
                    </Tab>
                    <Tab eventKey={1} title={<TabTitleText className='pf-v5-u-font-weight-bold'>Events</TabTitleText>} aria-label="Horizontal overflow content users">
                        {activeTabKey == 1 &&
                            <TabContentBody className='pf-v5-u-p-xs'>
                                <EventBar />
                                <AddFilterForm filter={filterfield} />
                                <PolicyEvents />
                            </TabContentBody>}
                    </Tab>
                </Tabs>
            </PageSection>

        </div>
    );
};

export default PolicyMonitoring;