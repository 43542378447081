import { Flex, FlexItem, Radio, FormGroup, Button, Icon, InputGroup, InputGroupItem, DatePicker, isValidDate, TimePicker, yyyyMMddFormat, updateDateTime, DescriptionList, DescriptionListGroup, DescriptionListTerm, DescriptionListDescription } from '@patternfly/react-core';
import React, { useState, useEffect, useMemo } from 'react';
import CommonSelectBox from '../CommonEventPages/CommonSelectBox'
import CommonMultiSelectBox from '../CommonEventPages/CommonMultiSelectBox'
import { connect, useDispatch, useSelector } from 'react-redux';

const UserPermission = (props) => {
    const dispatch = useDispatch()
    const [groups, setGroups] = useState([])
    const [mainGroupsList, setMainGroupsList] = useState([])
    const [selectedGroup, setSelectedGroup] = useState(props.group)
    const [agentList, setAgentList] = useState([])
    const [selectedAgents, setSelectedAgents] = useState(props.agents)
    const [disableAgent, setDisableAgent] = useState(false)
    const [permission, setPermission] = useState(props.permission)
    const [currentIndex, setCurrentIndex] = useState(null)
    const [validAgent, setValidAgent] = useState('')

    useEffect(() => {
        if (props?.groupDataList != "undefined") {
            setGroups(props?.groupDataList)
        }
    }, [props?.groupDataList])


    useEffect(() => {
        if (props?.data != "undefined") {
            let data = props.data
            setSelectedGroup(data.group)
            setSelectedAgents(data.agents)
            setPermission(data.permission)
            data.permission == 1 ? setDisableAgent(true) : setDisableAgent(false)
            setCurrentIndex(data.index)
            if (data.group != "" && agentList.length == 0) {
                let index = groups.findIndex(obj => obj.group_id == data.group)
                if (index > -1) {
                    setAgentList(groups[index]['agents']);
                }
            }else if(data.group == "" && agentList.length != 0)
            {
                console.log("set empty")
                setAgentList([])
            }
            //agentList
        }
    }, [props?.data,groups])

    const updateFieldSelectBox = (value, type = 'fieldselect', index = 'field') => {
        setCurrentIndex(index)
        switch (type) {
            case "groups":
                {
                    setSelectedGroup(value)
                    let agents = groups.find(obj => obj.group_id == value)
                    if(typeof agents != "undefined") {
                        setAgentList(agents.agents)
                    }
                    setSelectedAgents([])
                    break;
                }
            case "agents":
                {
                    setSelectedAgents(value)
                    break;
                }
        }
    }

    //Here set user permission data for parent component
    useEffect(() => {
        if (currentIndex != null && currentIndex!=="") {
            if (permission == 2 && selectedGroup != "" && selectedAgents.length == 0) {
                setValidAgent('error')
            } else if (selectedGroup != "") {
                setValidAgent('')
            }
            props.setParentData({ group: selectedGroup, agents: selectedAgents, permission: permission }, currentIndex)
        }
    }, [selectedGroup, selectedAgents, permission, currentIndex])

    const handleMultiSelectGroup = () => {

    }

    const handleChange = (event, checked) => {
        setPermission(parseInt(event.target.value));
        if (event.target.value == 1) {
            setDisableAgent(true)
            setSelectedAgents([])
        } else {
            setDisableAgent(false)
        }
    };

    const groupBox = useMemo(() => {
        return <CommonSelectBox key="1"
            options={groups.map(key => ({
                value: key['group_id'], children: key['group_name']
            }))}
            isLoading={false}
            placeholder="Select group"
            isDisabled={false}
            type="groups"
            indexVal={currentIndex}
            updateValue={updateFieldSelectBox}
            selectedOptions={selectedGroup}
        />
    }, [groups, selectedGroup, currentIndex])


    const agentBox = useMemo(() => {
        // console.log("agent option");
        // console.log(agentList)
        // console.log(selectedAgents)
        return <CommonMultiSelectBox key="3"
            initialOptions={agentList.map((item, index) => ({
                value: item,
                children: item,
            }))}
            onSelectChange={handleMultiSelectGroup}
            isDisabled={disableAgent}
            type="agents"
            updateValue={updateFieldSelectBox}
            placeholderText="Select agents"
            ariaLabel="agent-agents"
            clearButtonAriaLabel="Clear value"
            selectedOptions={selectedAgents}
            indexVal={currentIndex}
            validStatus={validAgent}
        />
    }, [agentList, disableAgent, selectedAgents, currentIndex, validAgent])

    const permissionRadio = useMemo(() => {
        return <div key='2'>
            <Radio
                isChecked={permission == 1}
                value={1}
                onChange={(event, checked) => handleChange(event, checked)}
                name={"permission_" + currentIndex}
                key="1"
                label="All Agents"
                id={"permission-1-" + currentIndex} />
            <Radio
                isChecked={permission == 2}
                value={2}
                onChange={(event, checked) => handleChange(event, checked)}
                name={"permission_" + currentIndex}
                key="2"
                label="Selected"
                id={"permission-2-" + currentIndex} />
        </div>
    }, [permission, currentIndex])

    return (
        <div key={currentIndex} >
            <DescriptionList isCompact>
                <DescriptionListGroup>
                    <DescriptionListTerm >Groups</DescriptionListTerm>
                    <DescriptionListDescription id={'groups-'+currentIndex}>{groupBox}</DescriptionListDescription>
                </DescriptionListGroup>
                <DescriptionListGroup>
                    <DescriptionListTerm>Agent</DescriptionListTerm>
                    <DescriptionListDescription id={'agents-'+currentIndex}>{agentBox}</DescriptionListDescription>
                </DescriptionListGroup>
                <DescriptionListGroup>
                    <DescriptionListTerm>Permission</DescriptionListTerm>
                    <DescriptionListDescription id={'permission-' + currentIndex}>{permissionRadio}</DescriptionListDescription>
                </DescriptionListGroup>

            </DescriptionList>
        </div>);
}
export default React.memo(UserPermission)