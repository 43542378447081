import React, { useState, useRef, useEffect, forwardRef } from "react";
import { connect, useDispatch } from "react-redux";
import {
  Grid,
  GridItem,
  MenuToggle,
  Page,
  Sidebar,
  SelectOption,
  SidebarContent,
  Text,

} from "@patternfly/react-core";
import dbApi from '../../services/DbApi';
import { Emptystatefornodata, } from "../../util/EmptyStateCard";
import Eventsidebar from "../CommonEventPages/EventSidebar";
import EventChart from "../CommonEventPages/EventChart";
import { formatTableTimestamp } from "../../util/Timeutils";
import DashboardSecurityAlerts from "../SecurityEvents/SecurityAlerts";
import { Loader } from "../../util/Loadingstate";

const NetworkInsightEvents = forwardRef(function NetworkInsightEvents(props, ref) {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState("select");
  const [scrollId, setScrollId] = useState('');
  const [logtableData, setLogTableData] = useState([]);
  const [totalcount, setTotalCount] = useState("0");
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [chartlabel, setChartLabel] = useState('')
  const [fieldKeys, setFieldKeys] = useState([])
  const [chartseries, setChartSeries] = useState([])

  const [isLoading, setIsLoading] = useState(false);

  const childSecurityEventRef = useRef(null);
  const time1options = [
    <SelectOption key={0} value="seconds"> seconds</SelectOption>,
    <SelectOption key={1} value="minutes">minutes </SelectOption>,
    <SelectOption key={2} value="hours">hours</SelectOption>,
    <SelectOption key={3} value="days">days</SelectOption>,
    <SelectOption key={4} value="weeks">weeks</SelectOption>,
    <SelectOption key={5} value="months">months</SelectOption>,
    <SelectOption key={6} value="years">years</SelectOption>,
  ];
// Network_Anamoly_Alerts.body.hits.total.value
 
  const Networkinsightcolumn = [
    "Time",
    "Rule Level",
    "Agent Name",
    'Protocol/Category',
    "Rule Description & key objects",
    "Rule Group(s)",
    "Rule ID",
  ];
  // "Syscheck Path", "Syscheck event",
  const clearselection = () => {
    setSelected(null)
    setIsOpen(false)
  }
  const onToggle = (isOpen) => {
    setIsOpen(isOpen)
  }
  const onSelect = (event, selection, isplaceholder) => {
    if (isplaceholder) {
      clearselection()
    } else {
      setSelected(selection)
      setIsOpen(false)
    }
  }

  // ... rest of your existing code

  const getEventlog = async (data) => {
    try {
      setIsLoading(true)
      let all_eventlog = await dbApi.postApi("Opensearch/Network_Event", data);
      console.log(all_eventlog);
      let ScrollID = ''
      let LogTableData = []
      let ChartLabel = ''
      let ChartSeries = []
      let TotalCount = '0'
      if (Object.keys(all_eventlog.Network_Event).length !== 0) {
        let events = all_eventlog.Network_Event.Network.body.hits
        let label = all_eventlog.Network_Event.interval
        let ChartData = all_eventlog.Network_Event.Network.body.aggregations[2]
        let scrollID = all_eventlog.Network_Event.Network.body._scroll_id
        let fieldkeys = all_eventlog.Network_Event.keys

        ChartSeries = ChartData.buckets;
        ChartLabel = label
        ScrollID = scrollID
        LogTableData = events.hits;
        TotalCount = events.total.value;

        setLogTableData(LogTableData)
        setScrollId(ScrollID)
        setFieldKeys(fieldkeys)
        setTotalCount(TotalCount)
        setChartLabel(ChartLabel)
        setChartSeries(ChartSeries)
      }
      else {
        setLogTableData(LogTableData)
        setScrollId(ScrollID)
        setFieldKeys([])
        setTotalCount(TotalCount)
        setChartLabel(ChartLabel)
        setChartSeries(ChartSeries)
      }
      setIsLoading(false)
    }
    catch (error) {
      console.log(error)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (childSecurityEventRef.current) {
      childSecurityEventRef.current.setSecurityAlertsTable(logtableData, scrollId,Networkinsightcolumn);
    }
    // [logtableData, scrollId, Networkinsightcolumn]
  }, [logtableData, scrollId,Networkinsightcolumn]);

  const eventsApi = async (startDate, endDate, allFields) => {
    const Feilds = allFields
    const data = {
      startDate: startDate,
      endDate: endDate,
      filter: JSON.stringify(Feilds),
    };
    try {
      setIsLoading(true)
      setStartDate(startDate)
      setEndDate(endDate)
      await getEventlog(data)
    }
    catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (props.dates.startDate != "") {
      let Dates = props.dates
      eventsApi(Dates.startDate, Dates.endDate, props.filterformdata)
    }
  }, [props?.dates.startDate, props?.dates.endDate, props.filterformdata,]);



  const toggle = toggleRef => <MenuToggle
    ref={toggleRef}
    onClick={onToggle}
    isExpanded={isOpen}
    style={{ width: '150px' }}>
    {selected}
  </MenuToggle>
  const { formattedTimeStamp: StartDate } = formatTableTimestamp(startDate);
  const { formattedTimeStamp: EndDate } = formatTableTimestamp(endDate);
  return (
    <div>
      <Grid hasGutter>
        <GridItem>
          <Page isManagedSidebar>
            <Sidebar style={{ overflow: "auto" }} >
              <Eventsidebar Count={totalcount}
                FieldKeys={fieldKeys}
                filterfield={[{
                  "fields": 'rule.groups',
                  'condition': 'is_one_of',
                  'value': ['zeek', 'suricata']
                }]}
                Loading={isLoading} />
              <div style={{ width: "100%" }}>
                <SidebarContent className="Sidebar-content">
                  <Text align="center"><b>{`${totalcount.toLocaleString('hi-IN')} hits`}</b></Text>
                  <Text align='center'><small><b>{StartDate} - {EndDate}</b></small></Text>
                  {/* <div>
                    <Bullseye>
                      <div>
                        <Select
                          id="single-select"
                          isOpen={isOpen}
                          selected={selected}
                          onSelect={onSelect}
                          // onOpenChange={isOpen}
                          toggle={toggle}
                          shouldFocusToggleOnSelect
                        >
                          <SelectList>
                            {time1options}
                          </SelectList>
                        </Select>
                      </div>
                    </Bullseye>
                  </div> */}
                  {isLoading ? <Loader /> :
                    chartseries.length === 0 ? <Emptystatefornodata /> :
                      <>
                        <EventChart EventSeries={chartseries} EventLabel={chartlabel} StartDateTime={startDate} EndDateTime={endDate} />

                        <DashboardSecurityAlerts ref={childSecurityEventRef} searchbar={false} />
                      </>
                  }
                </SidebarContent>
              </div>
            </Sidebar>
          </Page>
        </GridItem>
      </Grid>
    </div>
  );
})
const mapStateToProps = (state) => ({
  dates: { startDate: state.startdate, endDate: state.enddate },
  filterformdata: state.FilterData,
  selectedGroup: state.SelectedGroup,
  // mapStateToProps content remains unchanged if you have it
});

const mapDispatchToProps = (dispatch) => { return {} };

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(React.memo(NetworkInsightEvents))