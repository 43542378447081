import React, { useState, useRef, useEffect, createContext } from 'react';
import {
    PageSection,
    PageSectionVariants,
    Tooltip,
    Tabs,
    Tab,
    TabTitleText,
    TabContentBody,
    TabAction,

} from '@patternfly/react-core';
import { LinkIcon } from '@patternfly/react-icons';
import EventBar from '../CommonEventPages/EventBar';
import HealthAnalysisdash from './HealthAnalysisdash';
import SecurityDashboard from '../SecurityEvents/SecurityDashboard';
import AddFilterForm from '../CommonEventPages/AddFilterForm';
import IntegrityDashboard from '../IntegrityMonitoring/IntegrityDashboard';
import PolicyDashboard from '../PolicyMonitoring/PolicyDashboard';
import SystemAuditingDashboard from '../SystemAuditing/SystemAuditingDashboard';
import SCADashboard from '../SecurityConfiguration/SCADashboard';
import VulnerabilitiesDashboard from '../Vulnerabilities/VulnerabilitiesDashboard';
import OsqueryDashboard from '../OSQuery/OsqueryDashboard';
import MitreAttacksDashboard from '../MitreAttacks/MitreAttacksDashboard';
import ThreatIntelDashboard from '../ThreatIntelPlatform/ThreatIntelDashboard';
import SecurityEvents from '../SecurityEvents/SecurityEvents';
import { connect } from 'react-redux';
import SCAEvents from '../SecurityConfiguration/SCAEvents';
import IntegrityEvents from '../IntegrityMonitoring/IntegrityEvents';
import PolicyEvents from '../PolicyMonitoring/PolicyEvents';
import OsqueryEvents from '../OSQuery/OsqueryEvents';
import VulnerabilitiesEvents from '../Vulnerabilities/VulnerabilitiesEvents';
import ThreatIntelEvent from '../ThreatIntelPlatform/ThreatIntelEvent';
import SystemAuditingEvents from '../SystemAuditing/SystemAuditingEvents';
import MitreAttacksEvents from '../MitreAttacks/MitreAttacksEvents';
import { useParams } from 'react-router-dom';
import Inventorydata from './Inventorydata';
// import AgentContext from './bkAgentContext';

export const AgentContext = createContext();
const AgentHome = (props) => {
    const [activeTabKey, setActiveTabKey] = useState(0);
    const [tabName, setTabName] = useState('');
    const handleTabClick = (_event, tabIndex) => {
        setActiveTabKey(tabIndex);
    };
    const { agentname } = useParams()
    const { agentId } = useParams()

    // useEffect(() => {
    //     const isAgentPresent = props.Allgroups.some(group => group.agents.includes(agentname));
    //     if (!isAgentPresent) {
    //         window.location.href = '#/security-events';
    //     }
    // }, [props.Allgroups, agentname]);

    const TabActions = (EventName) =>
        <TabAction> <Tooltip content={'go to events'}><LinkIcon onClick={() => { setActiveTabKey(2); setTabName(EventName) }} /></Tooltip> </TabAction>

    const getTabPages = (event) => {
        switch (tabName) {
            case 'SCA':
                return <SCAEvents />
            case 'Securityevents':
                return <SecurityEvents />;
            case 'IntegrityEvents':
                return <IntegrityEvents />;
            case 'PolicyEvents':
                return <PolicyEvents />;
            case 'OsQueryEvents':
                return <OsqueryEvents />;
            case 'VulnerableEvents':
                return <VulnerabilitiesEvents />;
            case 'TIPEvents':
                return <ThreatIntelEvent />;
            case 'SystemAuditingEvents':
                return <SystemAuditingEvents />;
            case 'MitreEvents':
                return <MitreAttacksEvents />;
            default:
                return <SecurityEvents />
                break;
        }
    };

    return (
        <div>
            <PageSection variant={PageSectionVariants.light} padding={{ default: 'noPadding' }}>

                <Tabs variant='light300' activeKey={activeTabKey} onSelect={handleTabClick} aria-label="Tabs-individual" role="region" isBox width={40}>
                    <Tab eventKey={0} title={<TabTitleText>Health Analysis</TabTitleText>} aria-label="Horizontal overflow content users">
                        {activeTabKey == 0 &&
                            <TabContentBody className='pf-v5-u-p-xs'>
                                <EventBar />
                                <HealthAnalysisdash />
                            </TabContentBody>}
                    </Tab>
                    <Tab eventKey={1} title={<TabTitleText>Inventory data</TabTitleText>} aria-label="Horizontal overflow content users">
                        {activeTabKey == 1 &&
                            <TabContentBody className='pf-v5-u-p-xs'>
                                <AgentContext.Provider value={agentId}>
                                    <Inventorydata />
                                </AgentContext.Provider>
                            </TabContentBody>}
                    </Tab>
                    <Tab eventKey={2} title={<TabTitleText>Events</TabTitleText>} aria-label="Horizontal overflow content users">
                        {activeTabKey == 2 &&
                            <TabContentBody className='pf-v5-u-p-xs'>
                                <EventBar />
                                <AddFilterForm filter={[{}]} />
                                {getTabPages()}
                            </TabContentBody>}
                    </Tab>

                    <Tab
                        eventKey={3}
                        title={<TabTitleText>Security Events </TabTitleText>}
                        actions={TabActions('Securityevents')}
                    >
                        {activeTabKey == 3 &&
                            <TabContentBody className='pf-v5-u-p-xs'>
                                <EventBar />
                                <AddFilterForm filter={[{
                                    "fields": 'rule.groups',
                                    'condition': 'is_not',
                                    'value': 'zeek'
                                }]} />
                                <SecurityDashboard />
                            </TabContentBody>
                        }
                    </Tab>
                    <Tab eventKey={4} title={<TabTitleText>Integrity Monitoring</TabTitleText>} actions={TabActions('IntegrityEvents')}>
                        {/* Integrity Monitoring */}
                        {activeTabKey == 4 && <TabContentBody className='pf-v5-u-p-xs'>
                            <EventBar />
                            <AddFilterForm filter={[{
                                "fields": 'rule.groups',
                                'condition': 'is',
                                'value': 'syscheck'
                            }]} />
                            <IntegrityDashboard hideCard={true} />
                        </TabContentBody>}
                    </Tab>
                    <Tab eventKey={5} title={<TabTitleText>Policy Monitoring</TabTitleText>} actions={TabActions('PolicyEvents')}>
                        {/* Policy Monitoring */}
                        {activeTabKey == 5 && <TabContentBody className='pf-v5-u-p-xs'>
                            <EventBar />
                            <AddFilterForm filter={[{
                                "fields": 'rule.groups',
                                'condition': 'is',
                                'value': 'rootcheck'
                            }]} />
                            <PolicyDashboard hideCard={true} />
                        </TabContentBody>}
                    </Tab>
                    <Tab eventKey={6} title={<TabTitleText>System Audting</TabTitleText>} actions={TabActions('SystemAuditingEvents')}>
                        {/* System Audting */}
                        {activeTabKey == 6 && <TabContentBody className='pf-v5-u-p-xs'>
                            <EventBar />
                            <AddFilterForm filter={[{
                                "fields": 'rule.groups',
                                'condition': 'is',
                                'value': 'audit'
                            }]} />
                            <SystemAuditingDashboard hideCard={true} />
                        </TabContentBody>}
                    </Tab>
                    <Tab eventKey={7} title={<TabTitleText>SCA</TabTitleText>}
                        actions={TabActions('SCA')}
                        tooltip={<Tooltip content='Security Assessment Configuration' />}>
                        {/* SCA */}

                        {activeTabKey == 7 && <TabContentBody className='pf-v5-u-p-xs'>
                            <EventBar AllTimeDisplay={true} />
                            <AddFilterForm filter={[{
                                "fields": 'rule.groups',
                                'condition': 'is',
                                'value': 'sca'
                            }]} />
                            <SCADashboard hideCard={true} />
                        </TabContentBody>}
                    </Tab>
                    <Tab eventKey={8} title={<TabTitleText>Vulnerabilities</TabTitleText>} actions={TabActions('VulnerableEvents')}>
                        {/* Vulnerabilities */}
                        {activeTabKey == 8 && <TabContentBody className='pf-v5-u-p-xs'>
                            <EventBar AllTimeDisplay={true} />
                            <AddFilterForm filter={[{
                                'fields': 'data.vulnerability.status',
                                'condition': 'is_one_of',
                                'value': ['Active', 'Solved']
                            }]} />
                            {/* <VulnerabilitiesDashboard hideCard={true} /> */}

                            <VulnerabilitiesDashboard hideCard={true} agentID={agentId} />

                        </TabContentBody>}
                    </Tab>
                    <Tab eventKey={9} title={<TabTitleText>OS Query</TabTitleText>} actions={TabActions('OsQueryEvents')}>
                        {/* OS Query */}
                        {activeTabKey == 9 && <TabContentBody className='pf-v5-u-p-xs'>
                            <EventBar />
                            <AddFilterForm filter={[{
                                "fields": 'rule.groups',
                                'condition': 'is',
                                'value': 'osquery'
                            }]} />
                            <OsqueryDashboard hideCard={true} />
                        </TabContentBody>}
                    </Tab>
                    <Tab eventKey={10} title={<TabTitleText>MITRE ATT&CK</TabTitleText>} actions={TabActions('MitreEvents')}>
                        {/* MITRE ATT&CK */}
                        {activeTabKey == 10 && <TabContentBody className='pf-v5-u-p-xs'>
                            <EventBar />
                            <AddFilterForm filter={[{
                                'fields': 'rule.mitre.id',
                                'condition': 'exists',
                            }]} />
                            <MitreAttacksDashboard hideCard={true} />
                        </TabContentBody>}
                    </Tab>
                    <Tab eventKey={11} title={<TabTitleText>TIP<sup>+</sup></TabTitleText>} actions={TabActions('TIPEvents')}>
                        {/* TIP */}
                        {activeTabKey == 11 && <TabContentBody className='pf-v5-u-p-xs'>
                            <EventBar />
                            <AddFilterForm filter={[{
                                'fields': 'rule.groups',
                                'condition': 'is_one_of',
                                'value': ['active_response', 'misp']
                            }, {
                                'fields': 'rule.groups',
                                'condition': 'is_not',
                                'value': 'misp_error'

                            }]} />
                            <ThreatIntelDashboard hideCard={true} />
                        </TabContentBody>}
                    </Tab>
                </Tabs>
            </PageSection>
        </div>
    );
};

// export default AgentHome
const mapStateToProps = (state) => ({
    dates: { selectedTime: state.Term },
    selectedGroup: state.SelectedGroup,
    Allgroups: state.Allgroups
});


export default connect(
    mapStateToProps,
    null,
    null,
    { forwardRef: true }
)(AgentHome)


{/* <PageSection variant={PageSectionVariants.light} padding={{ default: 'noPadding' }}>
                <Flex flexWrap={{ default: 'nowrap' }}>
                    <Button
                        variant={selectedButton === 'HealthAnalysis' ? 'default' : 'plain'}
                        onClick={() => { setSelectedButton('HealthAnalysis'); }}
                    >
                        <Text className="pf-v5-u-font-weight-bold" component={TextVariants.h1}>
                            Health Analysis
                        </Text>
                    </Button>
                     <Button
                        variant={selectedButton === 'Security Events' ? 'default' : 'plain'}
                        onClick={() => { setSelectedButton('Security Events'); }}
                    >
                        <Text className="pf-v5-u-font-weight-bold" component={TextVariants.h1}>
                        Security Events
                        </Text>
                    </Button> <Button
                        variant={selectedButton === 'Integrity Monitoring' ? 'default' : 'plain'}
                        onClick={() => { setSelectedButton('Integrity Monitoring'); }}
                    >
                        <Text className="pf-v5-u-font-weight-bold" component={TextVariants.h1}>
                        Integrity Monitoring
                        </Text>
                    </Button> <Button
                        variant={selectedButton === ' Policy Monitoring' ? 'default' : 'plain'}
                        onClick={() => { setSelectedButton(' Policy Monitoring'); }}
                    >
                        <Text className="pf-v5-u-font-weight-bold" component={TextVariants.h1}>
                        Policy Monitoring
                        </Text>
                    </Button>
                    <Button
                        variant={selectedButton === '   System Audting' ? 'default' : 'plain'}
                        onClick={() => { setSelectedButton('   System Audting'); }}
                    >
                        <Text className="pf-v5-u-font-weight-bold" component={TextVariants.h1}>
                          System Audting
                        </Text>
                    </Button>
                    <Button
                        variant={selectedButton === ' SCA' ? 'default' : 'plain'}
                        onClick={() => { setSelectedButton(' SCA'); }}
                    >
                        <Tooltip content={'Security Configuration Assessment'}>
                        <Text className="pf-v5-u-font-weight-bold" component={TextVariants.h1}>
                        SCA
                        </Text>
                        </Tooltip>
                    </Button>
            
                    <Button
                        variant={selectedButton === 'Events' ? 'default' : 'plain'}
                        onClick={() => { setSelectedButton('Events'); }}
                    >
                        <Text className="pf-v5-u-font-weight-bold " component={TextVariants.h1}>
                            Events
                        </Text>
                    </Button>
                </Flex>
            </PageSection>

            <div className="pf-v5-u-p-sm">
                <EventBar />
                {selectedButton === 'HealthAnalysis' && (
                    <div>
                        <HealthAnalysisdash />
                    </div>
                )}
                {selectedButton === 'Security Events' && (
                    <div>
                          Security Events
                    </div>
                )} {selectedButton === 'Integrity Monitoring' && (
                    <div>
                        Integrity Monitoring
                    </div>
                )} {selectedButton === ' Policy Monitoring' && (
                    <div>
                         Policy Monitoring
                    </div>
                )} {selectedButton === 'System Audting' && (
                    <div>
                        System Audting
                    </div>
                )} {selectedButton === 'Vulnerabilities' && (
                    <div>
                        Vulnerabilities
                    </div>
                )} {selectedButton === 'OS Query' && (
                    <div>
                        OS Query
                    </div>
                )} {selectedButton === 'MITRE ATT&CK' && (
                    <div>
                        MITRE ATT&CK
                    </div>
                )} {selectedButton === 'TIP' && (
                    <div>
                        TIP
                    </div>
                )} {selectedButton === 'Network Insight' && (
                    <div>
                        Network Insight
                    </div>
                )} {selectedButton === 'Events' && (
                    <div>
                        Events
                    </div>
                )} {selectedButton === 'UBM' && (
                    <div>
                        UBM
                    </div>
                )} {selectedButton === 'Events' && (
                    <div>
                        Events
                    </div>
                )} 
            </div> */}