import React, { useState, useEffect } from 'react';
import {
  ActionGroup,
  Button,
  Card,
  CardBody,
  CardHeader,
  DataList,
  DataListCell,
  DataListItem,
  DataListItemCells,
  DataListItemRow,
  Drawer,
  DrawerActions,
  DrawerCloseButton,
  DrawerContent,
  DrawerContentBody,
  DrawerHead,
  DrawerPanelBody,
  DrawerPanelContent,
  Flex,
  FlexItem,
  Form,
  FormGroup,
  FormHelperText,
  Gallery,
  Grid,
  HelperText,
  HelperTextItem,
  Icon,
  InputGroup,
  InputGroupItem,
  MenuToggle,
  PageSection,
  Select,
  SelectList,
  SelectOption,
  Switch,
  TextInput,
  Title,

} from '@patternfly/react-core';
import { emptyCheck, emailCheck } from '../../util/Validate.js';
import dbApi from 'src/services/DbApi';
import { useParams, useNavigate, Link } from "react-router-dom";
import ConfirmBox from '../ConfirmBox/ConfirmBox.js';
import { connect, useDispatch } from 'react-redux';
import { alertBoxData } from 'src/Redux/Action.js';
import UserPermission from './UserPermission.js';
import PlusCircleIcon from '@patternfly/react-icons/dist/esm/icons/plus-circle-icon';
import TrashIcon from '@patternfly/react-icons/dist/esm/icons/trash-icon';
import CommonSelectBox from '../CommonEventPages/CommonSelectBox.js';
import { EyeIcon, EyeSlashIcon, KeyIcon, UserIcon } from '@patternfly/react-icons';
import { Loader } from 'src/util/Loadingstate.js';
import { PASS_CHAR_RULE, PASS_LENGTH } from 'src/Constant/constants.js';

const UserProfile = (props) => {
  const TimeZoneList = require('../../util/TimeZoneList.js');
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const [currentRole, setCurrentRole] = React.useState('');

  const [activeTabKey, setActiveTabKey] = React.useState(0);
  const [confirmModalData, setConfirmBox] = React.useState({
    show: false,
    msg: '',
    funCall: '',
    funParam: {},
    title: '',
    position: '',
    iconvariant: '',
    submitbtnvariant: ''
  })

  const statusList = [
    {
      id: 1, status: "Active"
    },
    {
      id: 0, status: "Inactive"
    }
  ]
  const [isloading, setIsLoading] = useState(false)
  const [actionType, setActionType] = useState('Add')
  const [disable, setDisable] = useState('')
  const [disableRole, setDisableRole] = useState(false)
  const [roleList, setRoleList] = useState([]);
  const [isOrgOpen, setIsOrgOpen] = useState(false);
  const [org, setOrg] = useState({ id: '', text: 'Organization' });
  const [orgList, setOrgList] = useState([]);
  const [userStatus, setUserStatus] = useState({ id: 1, text: 'Active' });
  const [isUserStatusOpen, setIsUserStatusOpen] = useState(false)
  const [role, setRole] = useState({ id: '', text: 'Role' });
  const [isRoleOpen, setIsRoleOpen] = useState(false);
  const [istimezoneOpen, setIsTimezoneOpen] = useState(false)
  const [timezone, setTimeZone] = useState("Asia/Kolkata")
  const [firstName, setFirstName] = useState('');
  const [validFirstName, setValidFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [validLastName, setValidLastName] = useState('');
  const [email, setEmail] = useState('');
  const [validEmail, setValidEmail] = useState('');
  const [userName, setUserName] = useState('');
  const [validUserName, setValidUserName] = useState('');
  const [password, setPassword] = useState('');
  const [ruleLength, setRuleLength] = useState('indeterminate');
  const [ruleCharacters, setRuleCharacters] = useState('indeterminate');
  const [passwordHidden, setPasswordHidden] = useState(true);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [validConfirmPassword, setValidConfirmPassword] = useState('default');
  const [check2fatype, setCheck2FAtype] = useState('')
  const [isSwitchChecked2FA, setIsSwitchChecked2FA] = useState(false);
  const [isSwitchChange, setIsSwitchChange] = useState(false)
  // const [currentUserID,setCurrentUserId]=useState()
  const [multiselectedGroup, setmultiSelectedGroup] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState([]);
  const [fromOrgDateTime, setFromOrgDateTime] = useState('');
  const [toOrgDateTime, setToOrgDateTime] = useState('');
  const [groupList, setGroupList] = useState([]);
  const [currentOrgId, setCurrentOrgId] = useState('');
  //Drawer states
  const [selectedDataListItemId, setSelectedDataListItemId] = useState('userdetail');
  const [isExpanded, setIsExpanded] = useState(false);
  const initPer = {
    group: '',
    agents: [],
    permission: 1
  }

  const [userPermission, setUserPermission] = useState([initPer]);
  const { userid } = useParams()
  // const { username } = useParams()

  const handleTabClick = (event, tabIndex) => {
    setActiveTabKey(tabIndex);
  };

  const onSelectDataListItem = (_event, id) => {
    setSelectedDataListItemId(id);
    setIsExpanded(true);
  };
  const onClose = () => {
    setIsExpanded(false);
  };
  const handleMultiSelectGroup = (selected) => {
    setmultiSelectedGroup(selected)
  };
  const updateGroupselectBox = (value, type = 'valueselect', index = 'Value') => {
    setSelectedGroup(value)
  }

  const validateMainPassword = (password) => {
    if (password === '') {
      setRuleLength('indeterminate');
      setRuleCharacters('indeterminate');
      return 'indeterminate';
    }

    if (password.length < 8) {
      setRuleLength('error');
    } else {
      setRuleLength('success');
    }

    let rulesCount = 0;
    if (/[a-z]/g.test(password)) rulesCount++;
    if (/[A-Z]/g.test(password)) rulesCount++;
    if (/\d/g.test(password)) rulesCount++;
    if (/\W/g.test(password)) rulesCount++;

    if (rulesCount < 3) {
      setRuleCharacters('error');
      return 'error';
    } else {
      setRuleCharacters('success');
      return 'success';
    }
  };

  const validateConfirmPassword = (value) => {
    if (password == "") {
      return "";
    }
    let status = emptyCheck(value)
    if (status == 'error') {
      setValidConfirmPassword(status)
    } else {
      if (password == value) {
        status = 'success'
      } else {
        status = 'error'
      }
      setValidConfirmPassword(status)
    }
    return status
  }


  const fieldChange = (value, type) => {
    switch (type) {
      case "firstName":
        setFirstName(value)
        setValidFirstName(emptyCheck(value))
        break;
      case "lastName":
        setLastName(value)
        setValidLastName(emptyCheck(value))
        break;
      case "email":
        setEmail(value)
        setValidEmail(emailCheck(value))
        break;
      case "userName":
        setUserName(value)
        setValidUserName(emptyCheck(value))
        break;
      case "password":
        setPassword(value)
        validateMainPassword(value);
        // if (actionType == "Add") {
        //   validateMainPassword(value);
        // }
        break;

      case "confirmPassword":
        setConfirmPassword(value)
        validateConfirmPassword(value)
        break;
    }
  };

  function formValid() {
    let ret = true;
    const passstatus = validateMainPassword(password);
    let status = emptyCheck(firstName)
    setValidFirstName(status)
    if (status == 'error') {
      ret = false
    }

    status = emptyCheck(lastName)
    setValidLastName(status)
    if (status == 'error') {
      ret = false
    }

    status = emailCheck(email)
    setValidEmail(status)
    if (status == 'error') {
      ret = false
    }

    status = emptyCheck(userName)
    setValidUserName(status)
    if (status == 'error') {
      ret = false
    }

    status = validateMainPassword(password)
    if ((actionType == "Add") || (actionType == "Edit" && password != "")) {
      if (status == 'error') {
        ret = false
      }
    }

    status = validateConfirmPassword(confirmPassword)
    if (actionType == "Add" && status == "success") {
      if (status == 'error') {
        ret = false
      }
    }
    if (role.id == 2) {
      let valid = true
      for (let index in userPermission) {
        let item = { ...userPermission[index] }
        if (item.permission == 2 && item.agents.length == 0 && valid) {
          ret = false
          valid = true
          setActiveTabKey(1)
        }
      }
    }

    status = emptyCheck(password)
    if (actionType == "Edit" && status == "success") {
      status = validateConfirmPassword(confirmPassword)
      if (status == 'error') {
        ret = false
      }
    }
    return ret
  }


  const onSelectBoxToggle = (type) => {
    switch (type) {
      case 'role':
        setIsRoleOpen(!isRoleOpen);
        break;
      case 'org':
        setIsOrgOpen(!isOrgOpen);
        break;
      case 'userStatus':
        setIsUserStatusOpen(!isUserStatusOpen);
        // alert(!isUserStatusOpen)
        break;
      case 'timezone':
        setIsTimezoneOpen(!istimezoneOpen)
        break;
    }
  };
  const onSelectBox = (_event, value, type) => {
    let text = _event.target.innerText;
    switch (type) {
      case 'role':
        setIsRoleOpen(!isRoleOpen);
        setRole({ id: value, text: text });
        if (text == "User") {
          getOrganizationList('');
        }
        break;
      case 'org':
        setIsOrgOpen(!isOrgOpen);
        setOrg({ id: value, text: text });
        if (currentOrgId != "" && currentOrgId == value) {
          getUserDetails();
        }
        break;
      case 'userStatus':
        setUserStatus({ id: value, text: text });
        setIsUserStatusOpen(!isUserStatusOpen);
        break;
    }
  };



  //get_organization_group_list

  //get all on board organization list
  const getOrganizationGroupList = async (orgId = "") => {
    const res = await dbApi.postApi("opensearch/get_organization_group_with_agent_list", { id: orgId });
    !res.error ? setGroupList(res?.data) : setGroupList([])
    if (res?.data.length == 0) {
      setUserPermission([initPer]);
    }
  }

  useEffect(() => {
    if (org.id != "") {
      let dates = orgList.find(obj => obj.id == org.id);
      if (typeof dates != "undefined") {
        setFromOrgDateTime(dates.start_datetime);
        setToOrgDateTime(dates.end_datetime);
        getOrganizationGroupList(org.id)
      }
    }
  }, [org])



  const finalSubmit = async () => {
    let url = "add-user"
    let data = {
      roleId: role.id,
      status: userStatus.id,
      firstName: firstName,
      lastName: lastName,
      email: email,
      // group: selectedGroup,
      userName: userName,
      passWord: confirmPassword,
      timezone: timezone,
    }

    if (actionType == "Edit") {
      // console.log('Edit')
      url = "edit-user"
      data.id = userid
      data.two_factor_auth_method = isSwitchChecked2FA == true ? 1 : 0
      data.two_factor_auth = isSwitchChecked2FA == true ? 1 : 0
    }
    if (currentRole == "Admin") {
      data.userPermission = JSON.stringify(userPermission)
      data.orgId = org.id
    }

    const res = await dbApi.postApi('user/' + url, data)
    let status = 'danger'
    if (!res.error) {
      status = 'success'
      // if (actionType == "Add") {
      // setTimeout((() => { navigate('/user-profile/' + res.data) }), 1000)
      setTimeout((() => { navigate('/User-Management') }), 500)
      // }
    }
    dispatch(alertBoxData([{ title: res.message, variant: status }]));
  }


  const submitForm = () => {
    if (formValid()) {
      setConfirmBox({
        show: true,
        msg: 'Are you sure you want to Update the profile details?',
        funCall: finalSubmit,
        funParam: {},
        title: 'Update profile Details',
        position: 'default',
        iconvariant: 'info',
        submitbtnvariant: 'primary'
      })
    }
  }


  const getUserDetails = async () => {
    try {
      setIsLoading(true)
      const res = await dbApi.postApi("user/get_user_details", { id: userid });
      console.log(res)
      if (!res.error) {
        let user = res.data
        setActionType("Edit")
        setDisable(true)
        let result = statusList.find((obj) => obj.id == user.status)
        let timezone = TimeZoneList.find((obj) => obj.value == user.timezone)
        setUserStatus({ id: result.id, text: result.status })
        setTimeZone(timezone.value)
        getUserRole(user.role_id)
        setFirstName(user.first_name)
        setLastName(user.last_name)
        setEmail(user.email)
        setSelectedGroup(user.group)
        setUserName(user.username)
        setIsSwitchChecked2FA(user.two_factor_method == 1 ? true : false)
        if (user.group.length > 0) {
          // await getOrganizationList()
          setUserPermission(user.group)
        }
        let orgId = ''
        if (user?.organization?.length > 0) {
          let org = user.organization[0];
          orgId = org.org_detail_id;
          setCurrentOrgId(orgId);
          setFromOrgDateTime(org.start_datetime);
          setToOrgDateTime(org.end_datetime);
        }
        getOrganizationList(orgId)
      } else {
        setActionType("")
        setDisable(true)
        setUserStatus({ id: '', text: 'Role' })
        getUserRole('')
        getOrganizationList('')
        setFirstName('')
        setLastName('')
        setEmail('')
        setSelectedGroup([])
        setUserName('')
      }
      setIsLoading(false)
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }
  }

  const getUserRole = async (roleId = "") => {
    const res = await dbApi.getApi("user/user-roles");
    if (res?.data?.length > 0) {
      setRoleList(res.data)
      let defaultRole = {}
      if (roleId == "") {
        defaultRole = res.data[0]
      } else {
        defaultRole = res.data.find((obj) => obj.id == roleId)
      }
      setRole({ id: defaultRole.id, text: defaultRole.role })
    }
  }

  //get all on board organization list
  const getOrganizationList = async (orgId = "") => {
    const res = await dbApi.postApi("opensearch/get_organization_list", { id: orgId });
    if (res?.data?.length > 0) {
      setOrgList(res?.data)
      let result = res?.data.find(obj => obj.id == orgId)
      if (typeof result != "undefined") {
        setOrg({ id: result.id, text: result.name });
      }
    }
  }

  const addNewRow = () => {
    setUserPermission([...userPermission, initPer]);
  }

  const removeRow = (index) => {
    let newPer = userPermission
    newPer.splice(index, 1)
    if (index == 0 && newPer.length == 0) {
      newPer.push(initPer)
    }
    setUserPermission([...newPer]);
  }

  const getUserId = () => {
    return localStorage.getItem("userId")
  }

  // const getUserTimezone = () => {
  //   return localStorage.getItem("timezone")
  // }
  const currentUserID = getUserId()

  useEffect(() => {
    (async () => {
      setCurrentRole(localStorage.getItem("role"))
      if (localStorage.getItem("role") !== 'Admin') {
        setDisableRole(true)
      }

      if (typeof userid != 'undefined' && userid != "") {
        if (currentUserID !== userid && localStorage.getItem("role") !== 'Admin') {
          navigate('/Security-Events');
        } else {
          getUserId();
          getUserDetails();
        }
      } else {
        getUserRole();
      }
    })()
  }, [userid])

  const setUserPermissionData = (data, index) => {
    let newData = userPermission
    if (typeof newData[index] != "undefined") {
      newData[index] = data
    } else {
      newData.push(data)
    }
    setUserPermission([...newData]);
  }

  const handleSwitchChange2FA = (_event, checked) => {
    setIsSwitchChange(true)
    setIsSwitchChecked2FA(checked);
  };
  // useEffect(() => {
  //   if (isSwitchChange) { finalSubmit() }
  // }, [isSwitchChange])


  const userPerList = React.useMemo(() => {
    return userPermission.map((obj, index) => {
      let addBtn = ''
      let removeBtn = ''
      // formData.length - 1 == rowIndex
      if (userPermission.length - 1 == index) {
        addBtn = <><FlexItem key={"add-" + index}>
          <Button variant="plain" onClick={() => addNewRow()} icon={<Icon status="info"><PlusCircleIcon /></Icon>} />
        </FlexItem></>
      }
      // if(userPermission.length>1)
      // formData.length !== 1
      if (userPermission.length !== 1) {
        removeBtn = <><FlexItem key={"remove-" + index}>
          <Button variant="plain" onClick={() => removeRow(index)} icon={<Icon status="danger"><TrashIcon /></Icon>}
          />
        </FlexItem></>
      }

      return <React.Fragment key={index}>{index > 0}
        {/* <GalleryItem style={{width:'300px'}} key={"com-" + index}> */}
        <Card key={"com-" + index} isFlat isFullHeight >
          <CardHeader
            actions={{
              actions: <Flex spaceItems={{ default: 'spaceItemsXs' }} display={{ default: 'inlineFlex' }}>
                {addBtn}{removeBtn}
              </Flex>
            }}>
          </CardHeader>
          <CardBody>
            <UserPermission setParentData={setUserPermissionData} groupDataList={groupList} data={{ ...obj, index: index }} />
          </CardBody>
        </Card>
        {/* </GalleryItem> */}
      </React.Fragment>
    })
  }, [userPermission, groupList])

  const statusToggle = toggleRef => <MenuToggle ref={toggleRef} onClick={() => onSelectBoxToggle('userStatus')} isExpanded={isUserStatusOpen} isFullWidth={true} >
    {userStatus?.text}
  </MenuToggle>;
  const statusBox = <Select id="status-sel" isOpen={isUserStatusOpen} selected={userStatus?.id} onSelect={(e, value) => onSelectBox(e, value, 'userStatus')} onOpenChange={isOpen => onSelectBoxToggle('userStatus')} toggle={statusToggle} shouldFocusToggleOnSelect>
    <SelectList>
      {statusList.map((option, index) => <SelectOption key={index} value={option.id} >{option.status}</SelectOption>)}
    </SelectList>
  </Select>

  const roleToggle = toggleRef => <MenuToggle ref={toggleRef} onClick={() => onSelectBoxToggle('role')} isExpanded={isRoleOpen} isDisabled={disableRole} isFullWidth={true} >
    {role?.text}
  </MenuToggle>;
  const roleBox = <Select id="role-sel" isOpen={isRoleOpen} selected={role?.id} onSelect={(e, value) => onSelectBox(e, value, 'role')} onOpenChange={isOpen => onSelectBoxToggle('role')} toggle={roleToggle} shouldFocusToggleOnSelect>
    <SelectList>
      {roleList.map((option, index) => <SelectOption key={index} value={option.id}  >{option.role}</SelectOption>)}
    </SelectList>
  </Select>

  const orgToggle = toggleRef => <MenuToggle style={{ width: '30%' }} ref={toggleRef} onClick={() => onSelectBoxToggle('org')} isExpanded={isOrgOpen} isFullWidth={true} >
    {org?.text}
  </MenuToggle>;
  const orgBox = <Select id="org-sel" isOpen={isOrgOpen} selected={org?.id} onSelect={(e, value) => onSelectBox(e, value, 'org')} onOpenChange={isOpen => onSelectBoxToggle('org')} toggle={orgToggle} shouldFocusToggleOnSelect>
    <SelectList>
      {orgList.map((option, index) => <SelectOption key={index} value={option.id}  >{option.name}</SelectOption>)}
    </SelectList>
  </Select>


  const updateFieldSelectBox = (value, type = 'fieldselect', index = 'field') => {
    setTimeZone(value)
  }
  const timeZoneBox = <CommonSelectBox
    key={'timezonebox'}
    options={TimeZoneList
      .sort().map(key => ({
        value: key.value,
        children: key.children
      }))}
    // options={[{ value: 'en-Us', children: 'English' }]}
    isLoading={false}
    placeholder="Select Timezone"
    isDisabled={false}
    type="timezone"
    updateValue={updateFieldSelectBox}
    selectedOptions={timezone}
  />


  const userpermissionBlock = <React.Fragment>
    <Form isHorizontal>
      <Grid hasGutter>
        {/* md={4} */}
        <FormGroup label="Organization" isRequired fieldId="organization">
          {orgBox}
        </FormGroup>
      </Grid>
      {
        org.id != "" && <FormGroup label="Subscribe From" isRequired fieldId="dates">
          <Flex direction={{ default: 'column', lg: 'row' }}>
            <FlexItem>
              {fromOrgDateTime}
            </FlexItem>
            <FlexItem>To</FlexItem>
            <FlexItem>
              {toOrgDateTime}
            </FlexItem>
          </Flex>
        </FormGroup>
      }

      <Gallery hasGutter >
        {userPerList}
      </Gallery>
    </Form>
    <br />
    <ActionGroup>
      <Button variant="primary" onClick={() => submitForm()} > {actionType == 'Add' ? 'Submit' : 'Update'}</Button>
      <Button variant="link"><Link to='/User-Management'>Cancel</Link></Button>
    </ActionGroup>
  </React.Fragment>

  const UserDetailBlock = <React.Fragment>
    <Form>
      <FormGroup label="Username" isRequired fieldId="username">
        <TextInput isRequired isDisabled={disable} type="text" id="username" name="username" aria-describedby="username-helper" value={userName} validated={validUserName} onChange={(event, value) => fieldChange(value, 'userName')} />
      </FormGroup>
      <Grid hasGutter md={6}>
        <FormGroup label="First Name" isRequired fieldId="firstName">
          <TextInput isRequired type="text" id="firstName" name="firstName" aria-describedby="firstName-helper" value={firstName} validated={validFirstName} onChange={(event, value) => fieldChange(value, 'firstName')} />
        </FormGroup>
        <FormGroup label="Last Name" isRequired fieldId="lastName">
          <TextInput isRequired type="text" id="lastName" name="lastName" aria-describedby="lastName-helper" value={lastName} validated={validLastName} onChange={(event, value) => fieldChange(value, 'lastName')} />
        </FormGroup>
        <FormGroup label="Email" isRequired fieldId="email">
          <TextInput isRequired type="text" id="email" name="email" aria-describedby="email-helper" value={email} validated={validEmail} onChange={(event, value) => fieldChange(value, 'email')} />
        </FormGroup>
        <FormGroup label='Timezone' isRequired fieldId="timezone">
          {timeZoneBox}
        </FormGroup>
        <FormGroup label="User Role" isRequired fieldId="role">
          {roleBox}
        </FormGroup>
        <FormGroup label="Status" isRequired fieldId="status">
          {statusBox}
        </FormGroup>
        <FormGroup label="New Password" isRequired={typeof id == 'undefined' ? false : true} fieldId="password">
          {/* <TextInput isRequired type="password" id="password" name="password" aria-describedby="password-helper" value={password}
            validated={validPassword} onChange={(event, value) => fieldChange(value, 'password')} /> */}

          <InputGroup>
            <InputGroupItem isFill>
              <TextInput
                isRequired
                name="password"
                type={passwordHidden ? 'password' : 'text'}
                aria-label="Password input"
                value={password}
                onChange={(event, value) => fieldChange(value, 'password')}
              />
            </InputGroupItem>
            <InputGroupItem>
              <Button
                variant="control"
                onClick={() => setPasswordHidden(!passwordHidden)}
                aria-label={passwordHidden ? 'Show password' : 'Hide password'}
              >
                {passwordHidden ? <EyeIcon /> : <EyeSlashIcon />}
              </Button>
            </InputGroupItem>
          </InputGroup>
          <FormHelperText>
            <HelperText component="ul" aria-live="polite" id="password-field-helper">
              <HelperTextItem isDynamic variant={ruleLength} component="li">
                {PASS_LENGTH}
              </HelperTextItem>
              <HelperTextItem isDynamic variant={ruleCharacters} component="li">
                {PASS_CHAR_RULE}
              </HelperTextItem>
            </HelperText>
          </FormHelperText>
        </FormGroup>
        {
          typeof id == 'undefined' && <FormGroup label="Confirm Password" isRequired={password != '' ? true : false} fieldId="confirmPassword">
            <TextInput isRequired type="password" id="confirmPassword" name="confirmPassword" aria-describedby="confirmPassword-helper" value={confirmPassword} validated={validConfirmPassword} onChange={(event, value) => fieldChange(value, 'confirmPassword')} />
          </FormGroup>
        }
        <Form isHorizontal >
          <FormGroup label="Enable 2FA" fieldId='2fa' isInline>
            <Switch id="2fa" aria-label="2fa" isChecked={isSwitchChecked2FA} onChange={handleSwitchChange2FA} />
          </FormGroup>
        </Form>

      </Grid>
    </Form>
    <br />
    <ActionGroup>
      <Button variant="primary" onClick={() => submitForm()} > {actionType == 'Add' ? 'Submit' : 'Update'}</Button>
      <Button variant="link"><Link to='/User-Management'>Cancel</Link></Button>
    </ActionGroup>
  </React.Fragment>
  const panelContent = <DrawerPanelContent defaultSize={'80%'}>
    <DrawerHead>
      <Title size="lg" headingLevel="h2">
        {(() => {
          switch (selectedDataListItemId) {
            case 'userdetail':
              return (<><Icon isInline><UserIcon /></Icon> User Details </>);
            case 'userpermission':
              return (<><Icon isInline><KeyIcon /></Icon> User Permission</>)

            default:
              break;
          }
        })()}
      </Title>
      <DrawerActions> <DrawerCloseButton onClick={onClose} /> </DrawerActions>
    </DrawerHead>
    <DrawerPanelBody>
      {(() => {
        switch (selectedDataListItemId) {
          case 'userdetail':
            return (<>{isloading ? <Loader /> : UserDetailBlock}</>);
          // {isloading&&<Bullseye><Spinner/> Please be wait...</Bullseye>}
          case 'userpermission':
            return (<>{userpermissionBlock}</>)
          default:
            break;
        }
      })()}
    </DrawerPanelBody>
  </DrawerPanelContent>

  const drawerContent = (
    <React.Fragment>
      <DataList
        aria-label="selectable data list example"
        selectedDataListItemId={selectedDataListItemId}
        onSelectDataListItem={onSelectDataListItem}
      >
        <DataListItem aria-label="data-list-item1-in-card" id="userdetail">
          <DataListItemRow>
            <DataListItemCells dataListCells={[<DataListCell key="userdetail"><b>User Details</b></DataListCell>]} />
          </DataListItemRow>
        </DataListItem>
        {currentRole == "Admin" && role.id == 2 && <DataListItem aria-label="data-list-item1-in-card" id="userpermission">
          <DataListItemRow>
            <DataListItemCells dataListCells={[<DataListCell key="userpermission"><b>User Permission</b></DataListCell>]} />
          </DataListItemRow>
        </DataListItem>}
      </DataList>
    </React.Fragment>
  );

  return <div className="pf-v5-u-p-sm">
    <PageSection padding={{ default: 'noPadding' }} >
      <Card>
        <Drawer isStatic isExpanded={isExpanded}>
          <DrawerContent panelContent={panelContent}>
            <DrawerContentBody>{drawerContent}</DrawerContentBody>
          </DrawerContent>
        </Drawer>
      </Card>
    </PageSection>
    <ConfirmBox confirmModal={confirmModalData} />
  </div>
}

const mapStateToProps = (state) => ({
  groups: state.Allgroups
})
// export default UserProfile
export default connect(mapStateToProps)(UserProfile) 