import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Modal, Button, ModalVariant } from '@patternfly/react-core';

const ModalCommon = forwardRef(
    ({ title, description, onClose = () => { }, children, actions, variant = ModalVariant.medium }, ref) => {
        const [isModalOpen, setIsModalOpen] = useState(false);
        const handleModalToggle = (_event) => {
            setIsModalOpen(!isModalOpen);
        };

        useImperativeHandle(ref, () => ({
            setModalBox(modalopen) {
                setIsModalOpen(modalopen)
            }
        }));

        return (
            <Modal
                title={title}
                isOpen={isModalOpen}
                onClose={(e) => {
                    handleModalToggle(e);
                    if (typeof onClose === 'function') {
                        onClose();
                    }
                }}
                actions={actions}
                variant={variant}
                description={description}
                ouiaId="CommonModal"
            >
                {children}
            </Modal>
        );
    }
);

export default ModalCommon;
