export const LOGOUT_MSG = 'Logout Successfully';
export const NOT_RESPOND = 'Response not found';
export const BAD_REQUEST = 'Bad request error';
export const FieldData_EventBar='Event bar field data';
export const Empty_State='Display Empty State';
export const ALERT_BOX='ALERT_BOX';
export const REMOVE_ALERT='REMOVE_ALERT';
export const INVALID_EMAIL='Please enter valid email id';
export const RESET_PASSWORD='Check your email for reset password';
export const REGISTRATION_SUCCESS='Registration completed succesfully.'
export const VERIFY_EMAIL='Please check your registered email for email verification'
export const VERIFIED_EMAIL='Your Email address was successfully verified. You can Login Now.'
export const PASS_LENGTH='Must be at least 8 characters'
export const PASS_CHAR_RULE='Must include at least 3 of the following: lowercase letter, uppercase letters, numbers, symbols'
export const TEMPLATE_MESSAGE='Template creation will require Admin rights, due to envolvement of Virtulalization Infrastruture administrative steps and it could be affect the Organisation policy.'
export const ON_PREM_MSG='In the case of On-Prem you may need to consult with your administrator to activate this account.'
export const TEAM_TYPE_MSG='Ownership can be assigned only to expert and corporate.'
export const TWO_FA_MSG='Two-factor authentication (2FA) makes your account more secure by adding an extra verification step when you log in.'

