import React, { useState, useEffect, useRef } from 'react';
import {
    Form,
    Grid,
    GridItem,
    TextInput,
    InputGroup,
    InputGroupItem,
    DatePicker,
    TimePicker,
    FormGroup,
    ToggleGroup,
    ToggleGroupItem,
    TextArea,
    ActionGroup,
    Button,
    Popover,
    yyyyMMddFormat
} from '@patternfly/react-core';
import axios from 'axios';
import SaveIcon from '@patternfly/react-icons/dist/js/icons/save-icon';
import IndexInputGroup from '../OnBoarding/IndexInputGroup';
import CommonSelectBox from '../CommonEventPages/CommonSelectBox';
import dbApi from 'src/services/DbApi';
import ConfirmBox from '../ConfirmBox/ConfirmBox';
import { alertBoxData } from 'src/Redux/Action';
import { useDispatch } from 'react-redux';
import SingleDateTimePicker from '../CommonEventPages/SingleDateTimePicker';
import { HelpIcon } from '@patternfly/react-icons';
import { emptyCheck } from 'src/util/Validate';
import { Loader } from 'src/util/Loadingstate';

const IncidentModal = ({ onModalClose, onclose }) => {
    const dispatch = useDispatch()
    const initialFormset = {
        title: '',
        startDatetime: '',
        severity: '',
        tlpAmber: '',
        papAmber: '',
        tags: '',
        docId: '1',
        description: '',
        organizationUniqueId: '',
    }
    const [isLoader, setIsLoader] = useState(false);
    const [caseName, setCaseName] = useState('');
    const [caseValidName, setCaseValidName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState([initialFormset]);
    const [severity, setSeverity] = useState('L');
    const [tlp, setTlp] = useState('AMBER');
    const [pap, setPap] = useState('AMBER');
    const [startDateTime, setStartDateTime] = useState();
    const [date, setDateTime] = useState();
    const [description, setDescription] = useState('');
    const [docId, setDocId] = useState('');
    const [validdescription, setValidDescription] = useState('');
    const [investigator, setInvestigator] = useState([]);
    const [investigatorValid, setInvestigatorValid] = useState('');
    const [selectedInvestigatorValue, setSelectedInvestigatorValue] = useState('');
    const [isTimePickerOpen, setIsTimePickerOpen] = useState(false);
    const [chips, setChips] = useState([]);
    const [indexNameValid, setIndexNameValid] = useState('');
    const [organization, setOrganization] = useState([]);
    const [organizationValid, setOrganizationValid] = useState('');
    const [selectedOrgValue, setSelectedOrgValue] = useState('');
    const [orgList, setOrgList] = useState([]);
    const [isModalOpen, setModalOpen] = useState(false);
    const [confirmModalData, setConfirmBox] = useState({
        show: false,
        msg: '',
        funCall: '',
        funParam: {},
        title: '',
        position: '',
        iconvariant: '',
        submitbtnvariant: '',
    });
    const [options, setOptions] = useState([
        { value: 'option1', children: 'Option 1' },
        { value: 'option2', children: 'Option 2' },
        // Add more options as needed
    ]);

    useEffect(() => {
        const today = new Date();
        setDateTime(today);
    }, []);

    const handleFieldChange = (value, field) => {
        // const value = typeof valueOrEvent === 'string' ? valueOrEvent : valueOrEvent.target.value;
        switch (field) {
            case 'caseName':
                setCaseName(value);
                setCaseValidName(emptyCheck(value));
                break;
            case 'description':
                setDescription(value);
                setValidDescription(emptyCheck(value));
                break;
            case 'docId':
                setDocId(value);
                break;
            default:
                break;
        }
    };

    const formValidation = () => {
        let isValid = true;
        const caseNameStatus = emptyCheck(caseName);
        setCaseValidName(caseNameStatus);
        if (caseNameStatus === 'error') {
            isValid = false;
        }
        const descriptionStatus = emptyCheck(description);
        setValidDescription(descriptionStatus);
        if (descriptionStatus === 'error') {
            isValid = false;
        }
        // const investigatorStatus = emptyCheck(selectedInvestigatorValue);
        // setInvestigatorValid(investigatorStatus);
        // if (investigatorStatus === 'error') {
        //     isValid = false;
        // }
        const organizationStatus = emptyCheck(selectedOrgValue);
        setOrganizationValid(organizationStatus);
        if (organizationStatus === 'error') {
            isValid = false;
        }
        // const indexNameStatus = chips.length > 0 && chips.every(chip => chip.length > 2) ? 'success' : 'error';
        // setIndexNameValid(indexNameStatus);
        // if (indexNameStatus === 'error') {
        //     isValid = false;
        // }
        // console.log({
        //     caseNameStatus,
        //     descriptionStatus,
        //     investigatorStatus,
        //     organizationStatus,
        //     // indexNameStatus
        // });
        return isValid;
    };

    const handleUpdateOrgValue = (value) => {
        console.log(value)
        setSelectedOrgValue(value);
        setOrganizationValid(emptyCheck(value));
    };
    // const handleUpdateInvestigatorValue = (value) => {
    //     setSelectedInvestigatorValue(value);
    //     setInvestigatorValid(emptyCheck(value));
    // };

    const handleSetChips = (newChips) => {
        setChips(newChips);
    };

    const handleSeverityToggle = (event, _isSelected) => {
        const id = event.currentTarget.id;
        setSeverity(id);
    };

    const handleTlpToggle = (event, _isSelected) => {
        const id = event.currentTarget.id;
        setTlp(id);
    };

    const handlePapToggle = (event, _isSelected) => {
        const id = event.currentTarget.id;
        setPap(id);
    };
    const handleModalToggle = () => {
        setModalOpen(!isModalOpen);
    };
    const handleDateTimeChange = (newDate) => {
        setStartDateTime(newDate)
    };
    useEffect(() => {
        getOrganizationList();
    }, []);

    const getOrganizationList = async () => {
        let data = { status: '1' };
        try {
            let res = await dbApi.postApi("opensearch/get_organization_list", data);
            console.log(res);
            let allOrg = [];
            if (res.data.length > 0) {
                allOrg = res.data;
                setOrgList(allOrg);
                console.log('orglist', orgList);
            } else {
                setOrgList([]);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const resetForm = () => {
        setCaseName('');
        setCaseValidName('');
        setSeverity('L');
        setTlp('AMBER');
        setPap('AMBER');
        setDescription('');
        setValidDescription('');
        setSelectedInvestigatorValue('');
        setInvestigatorValid('');
        setChips([]);
        setOrganizationValid('');
        setSelectedOrgValue('');
    };

    const finalSubmit = async () => {
        if (!startDateTime) {
            console.error('Date or Time is missing');
            return;
        }
        setIsLoader(true)
        const params = {
            title: caseName,
            description: description,
            severity: severity,
            tlpAmber: tlp,
            papAmber: pap,
            startDatetime: startDateTime,
            tags: chips,
            docId: docId,
            organizationUniqueId: selectedOrgValue,
        };

        try {
            const res = await dbApi.postApi("incident_management/add_incident", params);
            if (!res.error) {
                resetForm();
                onclose();
                onModalClose(true);
            }
            dispatch(alertBoxData([{ title: res.message, variant: !res.error ? 'success' : 'danger' }]));
        } catch (error) {
            console.error('API call failed:', error.response || error.message || error);
            onclose(false);
            onModalClose(false);
        }
        setIsLoader(false)
    };

    const submitForm = () => {
        if (formValidation(formData)) {
            setConfirmBox({
                show: true,
                msg: 'Are you sure?',
                funCall: finalSubmit,
                title: 'Add Incident.',
                position: 'default',
                iconvariant: 'info',
                submitbtnvariant: 'primary',
            });
            // handleModalToggle();
        }
    };

    return (<>
        {isLoader ? <Loader /> :
            <Form>
                <Grid hasGutter>
                    <GridItem lg={6}>
                        <FormGroup
                            isRequired
                            fieldId="case-name"
                            helperTextInvalid="Case name is required"
                            validated={caseValidName}
                        >
                            <TextInput
                                value={caseName}
                                isRequired
                                placeholder='Title'
                                type="text"
                                id="horizontal-form-name"
                                aria-describedby="horizontal-form-name-helper"
                                name="horizontal-form-name"
                                onChange={(e, value) => handleFieldChange(value, 'caseName')}
                                validated={caseValidName}
                            />
                        </FormGroup>
                    </GridItem>
                    <GridItem lg={6}>
                        <InputGroup>
                            <InputGroupItem>
                                <SingleDateTimePicker
                                    dateTime={date}
                                    //  time={time}
                                    onDateChange={handleDateTimeChange}
                                // onTimeChange={handleDateTimeChange}
                                />
                            </InputGroupItem>
                        </InputGroup>
                    </GridItem>
                </Grid>
                <Grid>
                    <GridItem lg={4}>
                        <FormGroup
                            label="Severity"
                            isRequired
                            fieldId="severity"
                            helperTextInvalid="Severity is required"
                            labelIcon={
                                <Popover
                                    headerContent={
                                        <div>
                                            Severity
                                        </div>
                                    }
                                    bodyContent={
                                        <div>
                                            Severity Info
                                        </div>
                                    }
                                >
                                    <HelpIcon />
                                </Popover>
                            }
                        >
                            <ToggleGroup aria-label="Severity">
                                <ToggleGroupItem
                                    className='pf-v5-u-mr-sm'
                                    text="L"
                                    buttonId="L"
                                    isSelected={severity === 'L'}
                                    onChange={handleSeverityToggle}
                                    style={{
                                        '--pf-v5-c-toggle-group__button--BackgroundColor': severity === 'L' ? '#FFFF00' : 'transparent' // Yellow
                                    }}
                                />
                                <ToggleGroupItem
                                    className='pf-v5-u-mr-sm'
                                    text="M"
                                    buttonId="M"
                                    isSelected={severity === 'M'}
                                    onChange={handleSeverityToggle}
                                    style={{
                                        '--pf-v5-c-toggle-group__button--BackgroundColor': severity === 'M' ? '#ADD8E6' : 'transparent' // Light Blue
                                    }}
                                />
                                <ToggleGroupItem
                                    className='pf-v5-u-mr-sm'
                                    text="H"
                                    buttonId="H"
                                    isSelected={severity === 'H'}
                                    onChange={handleSeverityToggle}
                                    style={{
                                        '--pf-v5-c-toggle-group__button--BackgroundColor': severity === 'H' ? '#FF6347' : 'transparent' // Red
                                    }}
                                />
                                <ToggleGroupItem
                                    className='pf-v5-u-mr-sm'
                                    text="C"
                                    buttonId="C"
                                    isSelected={severity === 'C'}
                                    onChange={handleSeverityToggle}
                                    style={{
                                        '--pf-v5-c-toggle-group__button--BackgroundColor': severity === 'C' ? '#8B0000' : 'transparent' // Dark Red
                                    }}
                                />
                            </ToggleGroup>

                        </FormGroup>
                    </GridItem>
                    <GridItem lg={4}>
                        <FormGroup
                            label="TLP"
                            isRequired
                            fieldId="simple-form-group-05"
                            helperTextInvalid="TLP is required"
                            labelIcon={
                                <Popover
                                    headerContent={
                                        <div>
                                            Pap
                                        </div>
                                    }
                                    bodyContent={
                                        <div>
                                            Pap Info
                                        </div>
                                    }
                                >
                                    <HelpIcon />
                                </Popover>
                            }

                        >
                            <ToggleGroup aria-label="TLP">
                                <ToggleGroupItem
                                    className='pf-v5-u-mr-sm'
                                    text="WHITE"
                                    buttonId="WHITE"
                                    isSelected={tlp == 'WHITE'}
                                    onChange={handleTlpToggle}
                                />
                                <ToggleGroupItem
                                    className='pf-v5-u-mr-sm'
                                    text="GREEN"
                                    buttonId="GREEN"
                                    isSelected={tlp == 'GREEN'}
                                    onChange={handleTlpToggle}
                                />
                                <ToggleGroupItem
                                    className='pf-v5-u-mr-sm'
                                    text="AMBER"
                                    buttonId="AMBER"
                                    isSelected={tlp == 'AMBER'}
                                    onChange={handleTlpToggle}
                                />
                                <ToggleGroupItem
                                    className='pf-v5-u-mr-sm'
                                    text="RED"
                                    buttonId="RED"
                                    isSelected={tlp == 'RED'}
                                    onChange={handleTlpToggle}
                                />
                            </ToggleGroup>
                        </FormGroup>
                    </GridItem>
                    <GridItem lg={4}>
                        <FormGroup
                            label="PAP"
                            isRequired
                            fieldId="simple-form-group-09"
                            helperTextInvalid="PAP is required"
                            labelIcon={
                                <Popover
                                    headerContent={
                                        <div>
                                            TLP
                                        </div>
                                    }
                                    bodyContent={
                                        <div>
                                            TLP Info
                                        </div>
                                    }
                                >

                                    <HelpIcon />

                                </Popover>
                            }
                        >
                            <ToggleGroup aria-label="PAP">
                                <ToggleGroupItem
                                    className='pf-v5-u-mr-sm'
                                    text="WHITE"
                                    buttonId="WHITE"
                                    isSelected={pap == 'WHITE'}
                                    onChange={handlePapToggle}
                                />
                                <ToggleGroupItem
                                    className='pf-v5-u-mr-sm'
                                    text="GREEN"
                                    buttonId="GREEN"
                                    isSelected={pap == 'GREEN'}
                                    onChange={handlePapToggle}
                                />
                                <ToggleGroupItem
                                    className='pf-v5-u-mr-sm'
                                    text="AMBER"
                                    buttonId="AMBER"
                                    isSelected={pap == 'AMBER'}
                                    onChange={handlePapToggle}
                                />
                                <ToggleGroupItem
                                    className='pf-v5-u-mr-sm'
                                    text="RED"
                                    buttonId="RED"
                                    isSelected={pap == 'RED'}
                                    onChange={handlePapToggle}
                                />
                            </ToggleGroup>
                        </FormGroup>
                    </GridItem>
                </Grid>
                <IndexInputGroup
                    placeholder='Enter Tags Name'
                    currentChips={chips}
                    setCurrentChips={handleSetChips}
                    indexNameValid={indexNameValid}
                />
                <FormGroup fieldId='description'>
                    <TextArea
                        isRequired
                        value={description}
                        placeholder='Description'
                        onChange={(e, value) => handleFieldChange(value, 'description')}
                        id="description"
                        name="description"
                        validated={validdescription}
                    />
                </FormGroup>
                {/* <CommonSelectBox
                options={options}
                isDisabled={true}
                createOption={true}
                selectedOptions={selectedInvestigatorValue}
                placeholder="Investigator"
                // updateValue={handleUpdateInvestigatorValue}
                validStatus={investigatorValid}
            /> */}
                <CommonSelectBox
                    options={orgList.map((item) => ({
                        value: item.unique_id,
                        children: item.name
                    }))}
                    isDisabled={false}
                    createOption={false}
                    selectedOptions={selectedOrgValue}
                    placeholder="Organization"
                    updateValue={handleUpdateOrgValue}
                    validStatus={organizationValid}
                />

                <FormGroup
                    isRequired
                    fieldId="doc-id"
                >
                    <TextInput
                        value={docId}
                        isRequired
                        placeholder='Document ID'
                        type="text"
                        id="doc_id"
                        aria-describedby="horizontal-form-name-helper"
                        name="doc_id"
                        onChange={(e, value) => handleFieldChange(value, 'docId')}
                    />
                </FormGroup>

                <ActionGroup>
                    <Button variant="primary" icon={<SaveIcon />} onClick={() => submitForm()} >Create</Button>
                    <Button variant="secondary" onClick={onclose}>Cancel</Button>
                </ActionGroup>
            </Form>
        }

        <ConfirmBox confirmModal={confirmModalData} />
    </>
    );
};

export default IncidentModal;
