import React from 'react'
import SecurityEvents from '../SecurityEvents/SecurityEvents'

const HuntingEvents = () => {
    // alert("landed")
    return (
        <div className='pf-v5-u-p-sm'>
            <SecurityEvents />

        </div>
    )
}
export default HuntingEvents
