import React from 'react';
import {
    Button,
    Flex,
    PageSection,
    PageSectionVariants,
    Text,
    TextVariants,
} from '@patternfly/react-core';
import EventBar from '../CommonEventPages/BkEventBar';
import { RiskFactor } from './RiskFactor';
import { AssetWiseRiskFactor } from './AWriskFactor';

export default class RiskAnalysis extends React.Component {
    constructor(props) {
        super(props);
        this.dashboardRef = React.createRef()
        this.eventsRef = React.createRef()
        this.state = {
            showRiskfactor: true,
            showAWRF: false,
            selectedButton: 'Risk Factor',
            allFields: {},
        };
    }
    apiCall = (StartDate, EndDate, selectedButton) => {
        if (selectedButton == 'Risk Factor'&& this.dashboardRef.current) {
            // this.dashboardRef.current.NetworkInsightDashboardApi(StartDate, EndDate);
            console.log("message")
        } 
        else if(selectedButton == 'AWRF' && this.eventsRef.current){
            console.log(selectedButton)
        }
    }
    handleFields = (Fields) => {
        this.setState({
            allFields: Fields,
        })
    }

    render() {
        const { showRiskfactor, showAWRF, selectedButton, allFields } = this.state;

        return (
            <div>
                <PageSection variant={PageSectionVariants.light} padding={{ default: 'noPadding' }}>
                    <Flex flexWrap={{ default: 'nowrap' }}>

                        <Button
                            variant={selectedButton === 'Risk Factor' ? 'default' : 'plain'}
                            onClick={() => this.setState({ showRiskfactor: true, showAWRF: false, selectedButton: 'Risk Factor' })} >
                            <Text className="pf-v5-u-font-weight-bold" component={TextVariants.h1}> Risk Factor </Text>
                        </Button>

                        <Button
                            variant={selectedButton === 'AWRF' ? 'default' : 'plain'}
                            onClick={() => this.setState({ showRiskfactor: false, showAWRF: true, selectedButton: 'AWRF' })}>

                            <Text className="pf-v5-u-font-weight-bold " component={TextVariants.h1}>  Asset Wise Risk Factor </Text>
                        </Button>
                    </Flex>
                </PageSection>
              
                <div className="pf-v5-u-p-sm">
                    <EventBar selectedButton={selectedButton} onClick={this.apiCall} Field={allFields}  />
                    {showRiskfactor && selectedButton === 'Risk Factor' &&
                        (<div> <RiskFactor
                            ref={this.dashboardRef}
                            sendFields={this.handleFields} /></div>)}

                    {showAWRF && selectedButton === 'AWRF' &&
                        (<div><AssetWiseRiskFactor ref={this.eventsRef}
                            sendFields={this.handleFields} /> </div>)}
                </div>
            </div>
        );
    }
}



