import * as echarts from 'echarts';
import React, { useEffect } from 'react';
import { generateUniqueID } from '../../../util/UniqueIdGenerator';
import { UpdateFilterData } from '../../../util/FilterHelper';
import { FilterData } from '../../../Redux/Action';
import { connect, useDispatch } from 'react-redux';
const IncidentPieChart = ({ ActionData, height, color, filterFields, filterformdata, theme ,sidebarState}) => {
    const id = generateUniqueID()
    const dispatch = useDispatch();
    const handleFilter = (fields, value) => {
        const updatedFields = UpdateFilterData(filterformdata, fields, 'is', value);
        dispatch(FilterData(updatedFields));
    }
    useEffect(() => {
        const chartDom = document.getElementById(id + 'edt');
        const myChart = echarts.init(chartDom);
        const textcolor = theme == 'dark-theme' ? '#fff' : '#000'
        const legendData = ActionData.map((item) => item.key.toString());
        //  console.log(legendData)
        const option = {
            tooltip: {
                trigger: 'item',
                textStyle: {
                    color: "#000"
                }
            },

            legend: {
                // orient: 'vertical',
                top: 'bottom',
                // right: 5,
                itemWidth: 9.5,
                itemHeight: 10,
                itemGap: 6.5,
                icon: "rect",
                textStyle: {
                    fontSize: 12,
                    color: textcolor
                },
                data: legendData
            },
            color: color,
            series: [
                {
                    type: 'pie',
                    // left: 0,
                    // right: 0,
                    // top: 20,
                    bottom: 30,
                    radius: '90%',
                    center: ['50%', '50%'],
                    label: {
                        // minMargin: 6,
                        // edgeDistance: 2,
                        // lineHeight: 12,
                        show: true,
                        color: textcolor,
                        formatter(param) {
                            // correct the percentage
                            return param.name + ' (' + param.percent * 1 + '%)';
                        }
                    },
                    data: ActionData.map((bucket) => {
                        const mappedData = { 
                            value: bucket.doc_count, // Use log scale for word size
                            name: bucket.key,
                        }; // console.log("Mapped data:", mappedData); // Check mapped data
                        return mappedData;
                    }
                    )
                }
            ]
        };

        option && myChart.setOption(option);
        myChart.on('legendselectchanged', function (param) {
            handleFilter(filterFields, param.name)
        });
        const resizeChart = () => {
            if (myChart) { // Check if chart exists before resizing
              myChart.resize();
            }
          };

        window.addEventListener('resize', resizeChart);
        return () => {
            myChart.dispose();
            window.removeEventListener('resize', resizeChart);
        };

    }, [height, ActionData, filterFields, filterformdata, theme,sidebarState])

    return <div className="container">
        <div id={id + 'edt'} style={{ width: '100%', height: height }} ></div>
    </div>;
}
const mapStateToProps = (state) => ({
    dates: { startDate: state.startdate, endDate: state.enddate },
    filterformdata: state.FilterData,
    theme: state.theme,
    sidebarState:state.sidebarstate
    // mapStateToProps content remains unchanged if you have it
});

export default connect(
    mapStateToProps,
    null,
    null,
    { forwardRef: true }
)(IncidentPieChart)


