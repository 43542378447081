import {
  Button,
  Card, CardBody,
  Chip, Divider, Drawer,
  DrawerActions, DrawerCloseButton,
  DrawerContent, DrawerContentBody,
  DrawerHead, DrawerPanelBody, DrawerPanelContent,
  ExpandableSection, Flex, FlexItem,
  Gallery, Icon, Label, Level, LevelItem, List, ListItem,
  Menu,
  MenuContent,
  MenuItem,
  MenuList,
  Popover,
  SearchInput, Switch, Title, Toolbar,
  ToolbarContent, ToolbarItem,
  Tooltip,
  Truncate
} from '@patternfly/react-core'
import React, { useEffect, useRef, useState } from 'react'
import dbApi from '../../services/DbApi'
import { connect, useDispatch } from 'react-redux';
import { ChartBarIcon, CheckIcon, ClockIcon, CogIcon, TimesIcon } from '@patternfly/react-icons';
import { Loader,TextSkeleton } from 'src/util/Loadingstate';
import { EmptystateforIncident } from 'src/util/EmptyStateCard';
import EventLogTable from '../CommonEventPages/EventLogTable';
import EventBar from '../CommonEventPages/EventBar';
import AddFilterForm from '../CommonEventPages/AddFilterForm';
import { StaticTactics } from 'src/util/CommonData';
import { Chart } from '@patternfly/react-charts';
import { FilterData } from 'src/Redux/Action';
import { UpdateFilterData } from 'src/util/FilterHelper';

const MitreFramework = (props) => {
  const dispatch = useDispatch();
  const childSecurityEventRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isExpanded, setIsExpanded] = React.useState(false);
  const [isExpandparent, setIsExpandparent] = React.useState(false);
  const [expandedSections, setExpandedSections] = useState(Array(5).fill(true));
  const [searchValue, setSearchValue] = useState('')
  const [inputValue, setInputValue] = useState('')
  const [expandheadername, setExpandHeadername] = useState('')
  const [expandheaderId, setExpandHeaderId] = useState('')
  const [alltechniques, setAlltechniques] = useState([])
  const [alltactics, setAllTactics] = useState([])
  const [loadTactics, setLoadTactics] = useState(false)
  const [loadTechniques, setLoadTechniques] = useState(false)
  const [isChecked, setIsChecked] = React.useState(false);
  const [selectedTactics, setSelectedTactics] = useState([]);
  //selectedTactics
  const [activeItem, setActiveItem] = React.useState(0);
  const [selectedItem, setSelectedItem] = React.useState(0);
  //drawer Api state for table 
  const [TechniqueDetails, setTechniqueDetails] = useState('')
  const [loaddetails, setLoadDetails] = useState(false);
  const [loaddetailstactic, setLoadDetailsTactic] = useState(false);
  const [detailTactics, setDetailTactics] = useState([])
  const [scrollId, setScrollID] = useState('');
  const [logtableData, setLogTableData] = useState([]);
  const [totalCount, setTotalCount] = useState("0");

  const onSelect = (_event, itemId) => {
    setActiveItem(itemId);
    setSelectedItem(itemId);
  };

  const handleChange = (_event, checked) => {
    setIsChecked(checked);
  };

  const toggleTacticSelection = (tactic) => {
    setSelectedTactics((prevSelectedTactics) => {
      if (prevSelectedTactics.includes(tactic)) {
        return prevSelectedTactics.filter(item => item !== tactic);
      } else {
        return [...prevSelectedTactics, tactic];
      }
    });
  };


  const onparentClose = () => {
    setIsExpandparent(false)
  }
  const onClose = () => {
    setIsExpanded(false);
  };
  const onSearch = event => {
    if (event.key && event.key !== 'Enter') {
      return;
    }
    setSearchValue(inputValue)
  }
  const MitreEventColumn = ["Time", "Agent Name", "Rule mitre id", "Rule mitre tactic", "Rule Description", "Rule level", "Rule ID"]
  const onToggle = (index) => {
    const newExpandedSections = [...expandedSections];
    newExpandedSections[index] = !newExpandedSections[index]; // Toggle the expanded state of the clicked section
    setExpandedSections(newExpandedSections);
  };

  const onTechselect = (value, techId) => {
    setIsExpandparent(true)
    setExpandHeadername(value)
    setExpandHeaderId(techId)
  }

  // Opensearch/Mitre_Framework_Tactics Home Tactics
  const getTactics = async (data) => {
    try {
      setLoadTactics(true)
      let res = await dbApi.postApi("Opensearch/Mitre_Framework_Tactics", data);
      console.log(res)
      let Tacticsdata = [];
      if (Object.keys(res.Mitre_Framework_Tactics).length !== 0) {
        Tacticsdata = res.Mitre_Framework_Tactics.body.aggregations.Tactic.buckets
        setAllTactics(Tacticsdata)
      }
      else {
        setAllTactics(Tacticsdata)
      }
      setLoadTactics(false)
    }
    catch (error) {
      console.log(error)
      setLoadTactics(false)
    }
  }
  useEffect(() => {
    if (props.dates.startDate != "") {
      let Dates = props.dates
      const Fields = props.filterformdata
      const data = {
        startDate: Dates.startDate,
        endDate: Dates.endDate,
        filter: JSON.stringify(Fields),
      };
      getTactics(data);
    }
  }, [props?.dates.startDate, props?.dates.endDate, props?.filterformdata,]);

  //Home Techniques according to start date and enddate and selectedtactics
  const getTechnique = async (data) => {
    try {
      setLoadTechniques(true)
      let res = await dbApi.postApi("Opensearch/Mitre_Framework_Techniques", data);
      console.log(res)
      let Techdata = [];
      if (Object.keys(res.Mitre_ID).length !== 0) {
        Techdata = res.Mitre_ID
        setAlltechniques(Techdata)
      }
      else {
        setAlltechniques(Techdata)
      }
      setLoadTechniques(false)
    } catch (error) {
      console.log(error)
      setLoadTechniques(false)
    }
  }

  useEffect(() => {
    if (props?.dates.startDate != "") {
      let staticfield = []
      if (selectedTactics.length > 0) {
        staticfield = [{
          "fields": 'rule.mitre.tactic',
          'condition': 'is_not_one_of',
          'value': selectedTactics
        }];
      }
      const Fields = [...props?.filterformdata, ...staticfield]
      const data = {
        startDate: props?.dates.startDate,
        endDate: props?.dates.endDate,
        filter: JSON.stringify(Fields),
      };
      getTechnique(data)
    }
  }, [props?.dates.startDate, props?.dates.endDate, props?.filterformdata, selectedTactics])

  //For Drawer content when slide the content 
  const getEventlog = async (data) => {
    let MAscrollId = ''
    let LogTableData = []
    let TotalCount = '0'
    try {
      setIsLoading(true)
      let all_eventlog = await dbApi.postApi("Opensearch/Mitre_Attack_Secuirty_Event ", data);
      console.log(all_eventlog);
      if (Object.keys(all_eventlog.Mitre_Attack_Secuirty_Event).length !== 0) {
        let events = all_eventlog.Mitre_Attack_Secuirty_Event.Mitre_Attack_Secuirty.body.hits
        let scrollID = all_eventlog.Mitre_Attack_Secuirty_Event.Mitre_Attack_Secuirty.body._scroll_id
        MAscrollId = scrollID
        LogTableData = events.hits;
        TotalCount = events.total.value;

        setLogTableData(LogTableData)
        setScrollID(MAscrollId)
        setTotalCount(TotalCount)
      }

      else {
        setLogTableData(LogTableData)
        setScrollID(MAscrollId)
        setTotalCount(TotalCount)
      }
      setIsLoading(false)
    }
    catch (error) {
      console.log("Api Failed", error)
    }
  }

  useEffect(() => {
    if (childSecurityEventRef.current) {
      childSecurityEventRef.current.setSecurityEventTable(logtableData, scrollId, MitreEventColumn);
    }
  }, [logtableData, scrollId, MitreEventColumn]);


  useEffect(() => {
    if (props.dates.startDate != "" && expandheaderId != "") {
      let Dates = props.dates
      let staticfield = [{
        "fields": 'rule.mitre.id',
        'condition': 'is',
        'value': expandheaderId
      }]
      const Fields = [...props.filterformdata, ...staticfield]

      const data = {
        startDate: Dates.startDate,
        endDate: Dates.endDate,
        filter: JSON.stringify(Fields),
      };
      getEventlog(data)
    }
  }, [expandheaderId]);//props?.dates.startDate, props?.dates.endDate, props.filterformdata,

  const getDetailedTactics = async (data) => {
    try {
      let param = {
        Mitre_Tactics_ID: data.map(item => item)
      }
      setLoadDetailsTactic(true)
      let res = await dbApi.postApi("Opensearch/Mitre_Tactics_ID", param);
      console.log(res)
      let TacticsDetails = [];
      if (Object.keys(res.Mitre_Tactics_ID).length !== 0) {
        TacticsDetails = res.Mitre_Tactics_ID.data.affected_items
        setDetailTactics(TacticsDetails)
      }
      else {
        setDetailTactics(TacticsDetails)
      }
      setLoadDetailsTactic(false)
    }
    catch (error) {
      console.log(error)
      setLoadDetailsTactic(false)
    }
  }
  const getTechniqueDetails = async (techId) => {
    try {
      let param = {
        External_Id: techId
      }
      setLoadDetails(true)
      let res = await dbApi.postApi("Opensearch/Mitre_Techniques_External_ID", param)
      console.log(res)
      let details = []
      if (Object.keys(res.Mitre_Techniques_External_ID).length !== 0) {
        details = res.Mitre_Techniques_External_ID[0]
        setTechniqueDetails(details)
        await getDetailedTactics(details.tactics)
      }
      else {
        setTechniqueDetails([])
      }
      setLoadDetails(false)
    } catch (error) {
      console.log(error)
      setLoadDetails(false)
    }
  }
  useEffect(() => {
    if (expandheaderId != "") { getTechniqueDetails(expandheaderId) }
  }, [expandheaderId])

  const ChildPanelContent = <DrawerPanelContent defaultSize={'80%'}>
    <DrawerHead>
      <Title headingLevel="h2">{expandheadername}</Title>
      <DrawerActions> <DrawerCloseButton onClick={onparentClose} /> </DrawerActions>
    </DrawerHead>
    <Divider />
    <DrawerPanelBody>
      <ExpandableSection toggleContent={
        <Flex spaceItems={{ default: 'spaceItemsLg' }}>
          <FlexItem>
            <Title size="md" headingLevel="h5"> Technique details</Title>
          </FlexItem>
        </Flex>} onToggle={() => onToggle(0)} isExpanded={expandedSections[0]}>
        <Flex spaceItems={{ default: 'spaceItemsXl' }}>
          <FlexItem>
            <Title headingLevel='h5'>ID</Title>
            {loaddetails ? <TextSkeleton /> : <p>{TechniqueDetails.external_id}</p>}
          </FlexItem>
          <FlexItem>
            <Title headingLevel='h5'>Version</Title>
            {loaddetails ? <TextSkeleton /> : <p>{TechniqueDetails.mitre_version}</p>}
          </FlexItem>
          <FlexItem>
            <Title headingLevel='h5'>Tactics</Title>
            {loaddetailstactic ? <TextSkeleton /> :
              <List isPlain>
                {detailTactics.map((tactic, index) =>
                  <ListItem key={index}>{tactic.name}</ListItem>
                )}
              </List>
            }
          </FlexItem>
        </Flex>{' '}
        <Flex>
          {' '}
          <FlexItem>
            <Title headingLevel='h5'>Description</Title>
            {loaddetails ? <TextSkeleton /> :
              <p>{TechniqueDetails.description}</p>
            }
          </FlexItem>
        </Flex>
      </ExpandableSection>
      <br />
      {/* <ExpandableSection toggleContent={
        <Flex spaceItems={{ default: 'spaceItemsLg' }}>
          <FlexItem>
            <Title size="md" headingLevel="h5"> Recent events</Title>
          </FlexItem>
          <FlexItem align={{ default: 'alignRight' }}><Label color='blue'>{totalCount}</Label></FlexItem>
        </Flex>} onToggle={() => onToggle(1)} isExpanded={expandedSections[1]}> */}
      <Flex spaceItems={{ default: 'spaceItemsLg' }}>
        <FlexItem>
          <Title size="md" headingLevel="h5"> Recent events</Title>
        </FlexItem>
        <FlexItem align={{ default: 'alignRight' }}><Label color='blue'>{totalCount}</Label> hits</FlexItem>
      </Flex>
      <br />
      <EventBar />
      <AddFilterForm filter={[{
        'fields': 'rule.mitre.id',
        'condition': 'exists',
      }]} />
      {isLoading ? <Loader /> :
        <EventLogTable ref={childSecurityEventRef} />
      }
      {/* </ExpandableSection> */}
    </DrawerPanelBody>
  </DrawerPanelContent >

  const panelContent = <DrawerPanelContent defaultSize={'80%'}>
    <DrawerHead style={{ marginTop: '-10px' }}>
      <Flex>
        <FlexItem><Title size="lg" headingLevel="h2"> Techniques</Title></FlexItem>
      </Flex>
      <SearchInput placeholder="Filter techniques of selected tactic(s)" className='pf-v5-u-mt-sm'
        value={inputValue}
        onChange={(_event, value) => setInputValue(value)}
        onSearch={onSearch}
        onClear={() => { setInputValue(''); setSearchValue('') }} />
      <DrawerActions> <DrawerCloseButton onClick={onClose} /> </DrawerActions>
    </DrawerHead>
    <DrawerPanelBody>
      {loadTechniques ? <Loader />
        :
        alltechniques.length > 0 ?
          <Gallery hasGutter aria-label="Selectable card container" minWidths={{ default: '250px' }}>
            {alltechniques
              .filter(item =>
                item.key.toLowerCase().includes(inputValue.toLowerCase()) ||
                item.Mitre_Techniques[0].tech.toLowerCase().includes(inputValue.toLowerCase())
              )
              .map((item, index) => {
                return (
                  <Card isCompact isRounded isFlat key={item.key + index} className="card-hover-icons">
                    <CardBody style={{ margin: '-10px' }}><Flex justifyContent={{ default: 'justifyContentSpaceBetween' }} alignItems={{ default: 'alignItemsCenter' }}>
                      <FlexItem><a className='pf-v5-u-color-100' onClick={() => onTechselect(item.Mitre_Techniques[0].tech, item.key)}><Truncate content={`${item.key} -${item.Mitre_Techniques[0].tech}`} /></a></FlexItem>
                      <FlexItem align={{ default: 'alignRight' }}>
                        <div className="icon-container">
                          <Tooltip content={`Show ${item.key} in Dashboard`}>
                            <a onClick={() => { props.onIconClick(null, 0); dispatch(FilterData(UpdateFilterData(props.filterformdata, 'rule.mitre.id', 'is', item.key))) }}><Icon><ClockIcon /></Icon></a>
                          </Tooltip>{' '}
                          <Tooltip content={`Inspect ${item.key} in Events`}>
                            <a onClick={() => { props.onIconClick(null, 3); dispatch(FilterData(UpdateFilterData(props.filterformdata, 'rule.mitre.id', 'is', item.key))) }}><Icon><ChartBarIcon /></Icon></a>
                          </Tooltip>
                        </div>{' '} <Label isCompact color='purple'>{item.doc_count}</Label>
                      </FlexItem>
                    </Flex>
                    </CardBody>
                  </Card>
                )
              })}
          </Gallery>
          : <EmptystateforIncident />
      }
    </DrawerPanelBody>
  </DrawerPanelContent>

  const enhancedTactics = StaticTactics.map((tactic) => {
    // Find corresponding tactic from API response
    const apiTactic = alltactics.find(obj => obj.key === tactic.name);
    return {
      ...tactic,
      doc_count: apiTactic ? apiTactic.doc_count || 0 : 0
    };
  }).sort((a, b) => b.doc_count - a.doc_count);

  const drawerContent = <React.Fragment>
    <Toolbar>
      <ToolbarContent>
        <ToolbarItem>
          <Title size="lg" headingLevel="h2"> Tactics</Title>
        </ToolbarItem>
        <ToolbarItem align={{ default: 'alignRight' }}>
          <Popover
            aria-label="Basic popover"
            headerContent={<div>OPTIONS</div>}
            position='bottom'
            minWidth='1.75 rem'
            bodyContent={hide =>
              <div>
                <Divider />
                <Menu style={{ margin: '-10px' }} isPlain role="listbox" onSelect={(_, itemId) => { onSelect(itemId); hide() }} activeItemId={activeItem} selected={selectedItem}>
                  <MenuContent>
                    <MenuList aria-label="Menu single select example">
                      <MenuItem itemId={0} icon={<CheckIcon aria-hidden />} onClick={() => { setSelectedTactics([]) }}>Select all</MenuItem>
                      <MenuItem itemId={1} icon={<TimesIcon aria-hidden />} onClick={() => { setSelectedTactics(enhancedTactics.map(tactic => tactic.name)) }}>Unselect all</MenuItem>
                    </MenuList>
                  </MenuContent>
                </Menu></div>
            }
          >
            <Button isInline variant='link' icon={<CogIcon />} />
          </Popover>
        </ToolbarItem>
      </ToolbarContent>
    </Toolbar>
    {loadTactics ?
      <List isPlain className='pf-v5-u-mx-sm'>
        <TextSkeleton /><br />
        <TextSkeleton /><br />
        <TextSkeleton /><br />
        <TextSkeleton /><br />
        <TextSkeleton /><br />
        <TextSkeleton /><br />
      </List>
      :
      alltactics.length > 0 ?
        <List isPlain className='pf-v5-u-mx-sm'>
          {enhancedTactics.map((tactic, index) =>
            <ListItem key={index} onClick={() => toggleTacticSelection(tactic.name)}>
              <Flex key={'Flex' + index} spaceItems={{ default: 'spaceItemsXs' }}>
                <b style={{ fontWeight: selectedTactics.includes(tactic.name) ? 'normal' : 'bold' }}> <FlexItem > <a className='pf-v5-u-color-100'>{tactic.name}</a></FlexItem></b>
                <FlexItem align={{ default: 'alignRight' }}><Label color={selectedTactics.includes(tactic.name) ? 'grey' : 'purple'} isCompact> <b style={{ fontWeight: selectedTactics.includes(tactic.name) ? 'normal' : 'bold' }}>{tactic.doc_count}</b></Label></FlexItem>
              </Flex>

            </ListItem>
          )}
        </List>
        : <b className='pf-v5-u-mx-sm'>No available Tactics</b>
    }
  </React.Fragment>;
  return (
    <Card isRounded isFlat >
      <Drawer isExpanded={isExpandparent}>
        <DrawerContent panelContent={ChildPanelContent}>
          <DrawerContentBody>
            <Drawer isStatic isExpanded={isExpanded} >
              <DrawerContent panelContent={panelContent}>
                <DrawerContentBody >{drawerContent}</DrawerContentBody>
              </DrawerContent>
            </Drawer>
          </DrawerContentBody>
        </DrawerContent>
      </Drawer>
    </Card>
  )
}
const mapStateToProps = (state) => ({
  dates: { startDate: state.startdate, endDate: state.enddate },
  filterformdata: state.FilterData,
  selectedGroup: state.SelectedGroup,
  // mapStateToProps content remains unchanged if you have it
});

const mapDispatchToProps = (dispatch) => { return {} };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(MitreFramework)
