export function calculateTimeDifference(StartDateTime, EndDateTime) {
  const timeDifferenceMs = EndDateTime.getTime() - StartDateTime.getTime();
  const resultInMinutes = Math.round(timeDifferenceMs / 60000);
  const resultInHours = Math.round(timeDifferenceMs / (1000 * 60 * 60));
  const resultInDays = Math.round(resultInHours / 24);
  return { resultInMinutes, resultInHours, resultInDays };
}

var getUserTimezone = () => {
  // return localStorage.getItem("timezone")
  const timezone = localStorage.getItem("timezone");
  // Check if timezone is null or invalid
  if (!timezone || !Intl.DateTimeFormat().resolvedOptions().timeZone) {
    return "UTC"; // Use a default time zone, e.g., UTC
  }
  return timezone;
}
// var UserTimeZone = getUserTimezone()
// console.log("UserTimeZone", UserTimeZone)

//Formate time stamp according to values
export function formatTimestamp(xvalue) {
  const UserTimeZone = getUserTimezone()
  // console.log("UserTimeZone = ", UserTimeZone)
  const Timestamp = new Date(xvalue);
  let ConvertTimestamp = Timestamp.toLocaleDateString("en-US", { dateStyle: 'medium', timeZone: UserTimeZone, hour12: false });
  //timeconvert
  const hours = Timestamp.toLocaleString("en-US", { hour: '2-digit', hour12: false, timeZone: UserTimeZone });
  const minutes = Timestamp.toLocaleString("en-US", { minute: '2-digit', timeZone: UserTimeZone });
  const seconds = Timestamp.toLocaleString("en-US", { second: '2-digit', timeZone: UserTimeZone });

  return { ConvertTimestamp, hours, minutes, seconds };
}
// timeFormatting for x label or tickvalues
export function formatTime(resultInMinutes, resultInHours, resultInDays, hours, minutes, seconds, ConvertTimestamp) {
  let formattedTime = " ";
  if (resultInMinutes >= 1 && resultInMinutes <= 60) {
    formattedTime = `${hours}:${minutes}:${seconds}`;
  } else if (resultInMinutes > 60 && resultInHours <= 12) {
    formattedTime = `${hours}:${minutes}:${seconds}`;
  } else if (resultInHours > 12 && resultInHours <= 72) {
    formattedTime = `${hours}:${minutes}:${seconds}`;
  } else if (resultInHours > 72 && resultInDays <= 30) {
    formattedTime = `${ConvertTimestamp},${hours}:${minutes}`;
  } else if (resultInDays > 30 && resultInDays <= 180) {
    formattedTime = `${ConvertTimestamp},${hours}:${minutes}`;
  } else if (resultInDays > 180 && resultInDays <= 500) {
    formattedTime = `${ConvertTimestamp},${hours}:${minutes}`;
  } else if (resultInDays > 500) {
    formattedTime = `${ConvertTimestamp},${hours}:${minutes}`;
  }

  return formattedTime;
}

//Time formatting for tables data time 
export function formatTableTimestamp(value) {
  const UserTimeZone = getUserTimezone()
  const Timestamp = new Date(value);
  const ConvertTimestamp = Timestamp.toLocaleDateString("en-US", { dateStyle: 'medium', timeZone: UserTimeZone, hour12: false });
  // console.log("ConvertTimestamp",ConvertTimestamp)
  //timeconvert
  const hours = Timestamp.toLocaleString("en-US", { hour: '2-digit', hour12: false, timeZone: UserTimeZone });
  const minutes = Timestamp.toLocaleString("en-US", { minute: '2-digit', timeZone: UserTimeZone });
  const seconds = Timestamp.toLocaleString("en-US", { second: '2-digit', timeZone: UserTimeZone });
  const milliseconds = Timestamp.getMilliseconds().toString().padStart(3, '0');
  const formattedTime = `${hours}:${minutes}:${seconds}.${milliseconds}`;
  const formattedTimeStamp = `${ConvertTimestamp} @ ${formattedTime}`;
  // const formatTimeforchart=`${ConvertTimestamp}  ${formattedTime}`;
  return { formattedTimeStamp };
}

//Date Conversion code 
// convertDates(startDate, endDate) {
//   const parsedStartDate = new Date(startDate);
//   const parsedEndDate = new Date(endDate);

//   const year = parsedStartDate.getUTCFullYear();
//   const month = ("0" + (parsedStartDate.getUTCMonth() + 1)).slice(-2);
//   const day = ("0" + parsedStartDate.getUTCDate()).slice(-2);
//   const hours = ("0" + parsedStartDate.getUTCHours()).slice(-2);
//   const minutes = ("0" + parsedStartDate.getUTCMinutes()).slice(-2);
//   const seconds = ("0" + parsedStartDate.getUTCSeconds()).slice(-2);
//   const milliseconds = parsedStartDate
//     .getUTCMilliseconds()
//     .toString()
//     .padStart(3, "0");

//   const convertedStartDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}+0000`;

//   const year2 = parsedEndDate.getUTCFullYear();
//   const month2 = ("0" + (parsedEndDate.getUTCMonth() + 1)).slice(-2);
//   const day2 = ("0" + parsedEndDate.getUTCDate()).slice(-2);
//   const hours2 = ("0" + parsedEndDate.getUTCHours()).slice(-2);
//   const minutes2 = ("0" + parsedEndDate.getUTCMinutes()).slice(-2);
//   const seconds2 = ("0" + parsedEndDate.getUTCSeconds()).slice(-2);
//   const milliseconds2 = parsedEndDate
//     .getUTCMilliseconds()
//     .toString()
//     .padStart(3, "0");

//   const convertedEndDate = `${year2}-${month2}-${day2}T${hours2}:${minutes2}:${seconds2}.${milliseconds2}+0000`;

//   return [convertedStartDate, convertedEndDate];
// }

// API FOR END DATE AND START DATE
// export const TimeApi = async () => {
//   try {
//     let currentDateTime = await dbApi.getApi("elasticsearch/getCurrentUtcDateTime");
//     const CurrentTime = new Date(currentDateTime);
//     // this.setState({
//     //   currentTime: CurrentTime,
//     // })
//     return CurrentTime;
//   }
//   catch (error) {
//     console.log(error);
//   }
// }
//for default time case
const parseTime = (timeString) => {
  const timeRegex = /(\d+)\s+(\w+)/g; // Regular expression to match number + unit

  let match;
  const timeUnits = {
    seconds: 1000,
    minutes: 60 * 1000,
    hours: 60 * 60 * 1000,
    days: 24 * 60 * 60 * 1000,
    weeks: 7 * 24 * 60 * 60 * 1000,
    months: 30 * 24 * 60 * 60 * 1000,
    years: 365 * 24 * 60 * 60 * 1000,
  };

  let totalMilliseconds = 0;

  while ((match = timeRegex.exec(timeString)) !== null) {
    const [, value, unit] = match;
    const milliseconds = parseInt(value, 10) * timeUnits[unit.toLowerCase()];
    totalMilliseconds += milliseconds;
  }

  return totalMilliseconds;
};
//calculator or Timehandler for multiple switch cases

export const TimeHandler = async (term, TimeApi, entireLog) => {
  try {
    const currentDateTime = await TimeApi();
    let startDate, endDate;
    let selectedTime, selectedUnit;

    switch (term) {
      case "All Time": ;
        const entire_StartDate = await entireLog();
        endDate = currentDateTime;
        startDate = new Date(entire_StartDate);
        selectedTime = 15;
        selectedUnit = "minutes"
        // interval="30d"
        // this.setState({ selectedTime: 15, selectedUnit: "minutes" });
        break;
      case "Last 15 minutes": ;
        endDate = currentDateTime;
        startDate = new Date(currentDateTime - 15 * 60 * 1000);
        // console.log("15min", startDate, endDate);
        selectedTime = 15; selectedUnit = "minutes";
        // interval = "30s"
        // this.setState({ selectedTime: 15; selectedUnit: "minutes" });

        break;
      case "Last 30 minutes":
        endDate = currentDateTime;
        startDate = new Date(currentDateTime - 30 * 60 * 1000);
        selectedTime = 30; selectedUnit = "minutes"
        // interval="1m"
        // var [convertedStart; convertedEnd] = this.convertDates(startDate;endDate);
        // this.setState({ selectedTime: 30; selectedUnit: "minutes" });
        break;
      case "Last 1 hour":
        endDate = currentDateTime;
        startDate = new Date(currentDateTime - 60 * 60 * 1000);
        selectedTime = 1; selectedUnit = "hours"
        // interval="5m"
        // this.setState({ selectedTime: 1; selectedUnit: "hours" });
        break;
      case "Today":
        endDate = currentDateTime;
        startDate = new Date(currentDateTime);
        startDate = new Date(startDate.setHours(0, 0, 0, 0));
        selectedTime = 12; selectedUnit = "hours"
        // interval="1h"
        // this.setState({ selectedTime: 12, selectedUnit: "hours" });
        break;
      case "Last 7 days":
        endDate = currentDateTime;
        startDate = new Date(currentDateTime - 7 * 24 * 60 * 60 * 1000)
        selectedTime = 7; selectedUnit = "days"
        // interval="1d"
        // this.setState({ selectedTime: 7, selectedUnit: "days" });
        break;
      case "Last 24 hours":
        // endDate = currentDateTime;
        endDate = currentDateTime;
        startDate = new Date(currentDateTime - 24 * 60 * 60 * 1000);
        selectedTime = 24; selectedUnit = "hours"
        // interval="1h"
        // this.setState({ selectedTime: 24, selectedUnit: "hours" });
        break;
      case "This week":
        endDate = new Date(currentDateTime);
        startDate = new Date(currentDateTime);
        const dayOfWeek = endDate.getDay();
        startDate.setHours(0, 0, 0, 0);
        startDate.setDate(startDate.getDate() - dayOfWeek);
        const { resultInMinutes: min, resultInHours: hr, resultInDays: days } = calculateTimeDifference(startDate, endDate)
        selectedTime = days; selectedUnit = "days"
        // interval="1d"
        // startDate= new Date(endDate.setDate(endDate.getDate()-((endDate.getDay()+1)%7)));
        // this.setState({ selectedTime: 4, selectedUnit: "days" });
        break;
      case "This month":
        endDate = new Date(currentDateTime);
        // startDate = new Date(currentDateTime);
        startDate = new Date(currentDateTime.getFullYear(), currentDateTime.getMonth(), 1);
        const { resultInMinutes, resultInHours, resultInDays } = calculateTimeDifference(startDate, endDate)
        selectedTime = resultInDays; selectedUnit = "days"
        // console.log("startdate :", startDate)
        // console.log("endDate :", endDate)
        break;
      case "Last 30 days":
        endDate = currentDateTime;
        startDate = new Date(currentDateTime - 30 * 24 * 60 * 60 * 1000);
        selectedTime = 30; selectedUnit = "days"
        // interval="1d"
        // this.setState({ selectedTime: 30, selectedUnit: "days" });
        break;
      case "Last 90 days":
        endDate = currentDateTime;
        startDate = new Date(currentDateTime - 90 * 24 * 60 * 60 * 1000);
        selectedTime = 90; selectedUnit = "days"
        // interval="1d"
        // this.setState({ selectedTime: 30, selectedUnit: "days" });
        break;
      case "Last 1 year":
        endDate = currentDateTime;
        startDate = new Date(currentDateTime - 365 * 24 * 60 * 60 * 1000);
        selectedTime = 1; selectedUnit = "year"
        // interval="1M"
        break;
      default:
        const dateRangeMatch = term.match(/(\S+)\s*-\s*(\S+)/);
        if (dateRangeMatch) {
          const [startString, endString] = term.split('-');
          startDate = new Date(startString)
          endDate = new Date(endString)
        }
        else {
          const milliseconds = parseTime(term);
          const [lastTerm, timeValue, unit] = term.split(' ');
          const time = parseInt(timeValue, 10);
          if (milliseconds > 0) {
            endDate = currentDateTime;
            startDate = new Date(currentDateTime - milliseconds);
            selectedTime = time; selectedUnit = unit
          } else {
            throw new Error('Invalid term provided');
          }
        }
        break;
        return;
    }
    // console.log(startDate)
    // console.log(endDate)
    return { startDate, endDate, selectedTime, selectedUnit };
  } catch (error) {
    console.error('Error in TimeHandler:', error);
    return null; // You might want to handle this differently based on your use case
  }
};


export const splitDateTime = (dateStr = "") => {
  const dateVal = dateStr.substring(0, dateStr.indexOf(' '));
  const timeVal = dateStr.substring(dateStr.indexOf(' ') + 1);
  return { date: dateVal, time: timeVal }
}



// For Adding time interval to start date to get end date for barchart click event 
export const addInterval = (startDate, interval, unit) => {
  let EndDate = new Date(startDate); // Copying startDate to avoid mutating it
  // console.log("asdfgh", startDate, interval, unit)
  switch (unit) {
    case 'seconds':
      EndDate.setSeconds(EndDate.getSeconds() + interval);
      break;
    case 'minutes':
      EndDate.setMinutes(EndDate.getMinutes() + interval);
      break;
    case 'hour':
      EndDate.setHours(EndDate.getHours() + interval);
      break;
    case 'day':
      EndDate.setHours(EndDate.getHours() + interval);
      break;
    case 'week':
      EndDate.setHours(EndDate.getHours() + interval);
      break;
    case 'year':
      EndDate.setHours(EndDate.getHours() + interval);
      break;
    // You can add more cases for other units if needed
    default:
      console.error('Invalid unit:', unit);
  }
  // console.log("ENDDATE HELPER", EndDate)
  return EndDate;
}


export const vulformatDate = (dateString) => {
  if (!dateString) return 'N/A';
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, '0');
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
}