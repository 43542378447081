import React, { useEffect, useMemo, useState } from 'react';
import { connect, useDispatch } from 'react-redux'
import {
    Banner,
    Button,
    Card,
    Flex,
    FlexItem,
    HelperText,
    HelperTextItem,
    Icon,
    MenuToggle,
    Modal,
    ModalVariant,
    Radio,
    Select,
    SelectList,
    SelectOption,
} from '@patternfly/react-core';
import { emptyArrayCheck, selectmenuoption, } from '../../util/Validate.js';
import { alertBoxData, callHeaderAG } from 'src/Redux/Action.js';
import dbApi from '../../services/DbApi'
import ConfirmBox from '../ConfirmBox/ConfirmBox.js';
import { InfoCircleIcon, PlusCircleIcon, TrashIcon } from '@patternfly/react-icons';
import CommonSelectBox from '../CommonEventPages/CommonSelectBox.js';
import IndexInputGroup from './IndexInputGroup.js';
import { Loader } from 'src/util/Loadingstate.js';
const { forwardRef, useImperativeHandle } = React;
const OrganizationGroupForm = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    // {"orgGroupId":"1","groupName":"2","index":["wazuh-alerts-*"],"status":"1","isParent":true}

    const initialFormset = {
        orgGroupId: '',
        groupName: '',
        indexName: [],
        status: { id: 1, Status: "Active" },
        isparent: false,
        groupNameValid: '',
        indexNameValid: '',
    }
    const [formData, setFormData] = useState([initialFormset]);
    const [isStatusOpen, setIsStatusOpen] = useState([]);
    const [isloadgetGroup, setIsLoadgetGroup] = useState(false)
    const [Groups, setGroups] = useState([]);
    const [validparent, setValidParent] = useState(true);
    const [selectedParentIndex, setSelectedParentIndex] = useState(null);
    const [listRowId, setListRowId] = useState('')
    const [organizationName, setOrganizationName] = useState('')
    const [isModalOpen, setModalOpen] = useState(false);
    const [confirmModalData, setConfirmBox] = useState({
        show: false,
        msg: '',
        funCall: '',
        funParam: {},
        title: '',
        position: '',
        iconvariant: '',
        submitbtnvariant: '',
    })

    useImperativeHandle(ref, () => ({
        setOrganizationForm(rowId = '', Orgname = '') {
            setListRowId(rowId)
            setOrganizationName(Orgname)
            setFormData([initialFormset])
            setValidParent(true)
            handleModalToggle()
        }
    }));
    //Api call for default Group added in Rows
    const getGroupRows = async (OrgId) => {
        let data = {
            id: OrgId
        }
        try {
            setIsLoadgetGroup(true)
            let res = await dbApi.postApi("opensearch/get_organization_group", data)
            console.log("organization group", res)
            let rowDetail = res.data
            let updatedFormData = []
            if (rowDetail.length > 0) {
                updatedFormData = rowDetail.map((obj) => {
                    const rowStatus = StatusList.find(sObj => sObj.id == obj.group_status);
                    return {
                        orgGroupId: obj.org_gp_id,
                        groupName: obj.group_id,
                        indexName: obj.indexs,
                        status: { id: parseInt(obj.group_status), Status: rowStatus.Status },
                        isparent: obj.parent_group_id !== null ? false : true
                    }
                })
                setFormData(updatedFormData);
            }
            setIsLoadgetGroup(false)
            // else{
            //     setFormData(updatedFormData);
            // }
        } catch (error) {
            console.log(error)
            setIsLoadgetGroup(false)
        }
    }
    //Api call for Groups
    const getAllGroups = async (OrgId) => {
        try {
            let param = {
                orgId: OrgId
            }
            setIsLoadgetGroup(true)
            let allGroups = await dbApi.postApi('Opensearch/get_organization_group_list', param);
            console.log('groups', allGroups);
            let groups = []
            if (allGroups.data.length > 0) {
                groups = allGroups.data
            }
            setGroups(groups)
            setIsLoadgetGroup(false)
        } catch (error) {
            console.log(error);
            setIsLoadgetGroup(false)
        }
    };


    useEffect(() => {
        const fetchData = async () => {
            if (listRowId !== '') {
                await getAllGroups(listRowId);
                await getGroupRows(listRowId);
            }
        };

        fetchData();
    }, [listRowId]);

    // opensearch/delete_organization_group for delete the particular group for particular organization 
    const finaldelete = async (data) => {
        let param = {
            id: data
        }
        try {
            let res = await dbApi.postApi("opensearch/delete_organization_group", param)
            console.log("delete", res)
            let status = 'danger'
            if (!res.error) {
                status = 'success'
                dispatch(alertBoxData([{ title: res.message, variant: status }]));
                getGroupRows(listRowId)
                dispatch(callHeaderAG(Date.now()))
            }
            else {
                dispatch(alertBoxData([{ title: res.message, variant: status }]));
            }
        } catch (error) {
            console.log(error)
        }
    }

    const deleteGroups = (id) => {
        setConfirmBox({
            show: true,
            msg: 'Are you sure?',
            funCall: () => finaldelete(id),
            title: 'Delete group from organization.',
            position: 'default',
            iconvariant: 'warning',
            submitbtnvariant: 'danger',
        })
    }


    //usestate for Dropdown open for status
    useEffect(() => {
        setIsStatusOpen(Array(formData.length).fill(false));
    }, [formData]);

    const handleModalToggle = () => {
        setModalOpen(!isModalOpen);
    };

    const handleRadioChange = (_event, rowIndex) => {
        const updateFormData = [...formData];
        updateFormData.forEach((row, index) => {
            if (index === rowIndex) {
                row.isparent = false;
            } else {
                row.isparent = true;
            }
        });
        setFormData(updateFormData);
        setSelectedParentIndex(rowIndex);
        setValidParent(true)
    };


    const StatusList = [
        { id: 1, Status: "Active" },
        { id: 0, Status: "Inactive" }
    ]

    //Add Row
    const addNewRow = (rowIndex) => {
        setFormData([...formData, initialFormset])
    }

    //Remove Row
    const removeRow = (index) => {
        let newrow = [...formData]
        newrow.splice(index, 1)
        setFormData(newrow);
    }


    const formValidation = (formData) => {
        let isValid = true;
        const newFormData = formData.map((item) => {
            const groupNameValid = selectmenuoption(item.groupName);
            const indexNameValid = emptyArrayCheck(item.indexName)
            let validparent = false;
            if (groupNameValid == 'danger') isValid = false;
            if (indexNameValid == 'error') isValid = false;
            if (!validparent && item.isparent == true) { validparent = true }
            return {
                ...item,
                groupNameValid,
                indexNameValid,
            };
        });
        if (!validparent) {
            isValid = false;
            setValidParent(false)
        } else {
            setValidParent(true)
        }
        setFormData(newFormData);
        return  isValid
    }

     // opensearch/add_organization_group for add the groups to organization
     const finalSubmit = async () => {
        setListRowId('')
        setOrganizationName('')
        let AllGroups = formData.map((item) => ({
            orgGroupId: item.orgGroupId,
            groupId: item.groupName,
            indexName: item.indexName,
            status: item.status.id,
            isParent: item.isparent,
        }))
        let data = {
            orgId: listRowId,
            groups: JSON.stringify(AllGroups)
        }
        console.log(data)
        try {
            let res = await dbApi.postApi("opensearch/add_organization_group", data)
            console.log(res)
            let status = 'danger'
            if (!res.error) {
                status = 'success'
                dispatch(alertBoxData([{ title: res.message, variant: status }]));
                dispatch(callHeaderAG(new Date()))//current time
            }
            else {
                dispatch(alertBoxData([{ title: res.message, variant: status }]));
            }
        } catch (error) {
            console.log(error)
        }
    }

    const submitForm = () => {
        if (formValidation(formData)) {
            setConfirmBox({
                show: true,
                msg: 'Are you sure?',
                funCall: finalSubmit,
                title: 'Add groups to organization.',
                position: 'default',
                iconvariant: 'info',
                submitbtnvariant: 'primary',
            })
            handleModalToggle();
            // props.closeonSubmit();
        }
    }

   console.log(confirmModalData)
    //Group select box
    const groupBox = useMemo(() => {
        return (GroupName, rowIndex, validgroup) => {
            // console.log("GroupName",GroupName)
            const updateGroupSelectBox = (value, type = 'fieldselect', index = 'field') => {
                const selectedGroup = Groups.find(group => group.group_id == value);
                const updatedFormData = [...formData];
                updatedFormData[rowIndex].groupName = value;
                updatedFormData[rowIndex].groupNameValid = selectmenuoption(value);
                if (selectedGroup) {
                    updatedFormData[rowIndex].indexName = selectedGroup.indexs;
                }
                setFormData(updatedFormData);
            }
            const selectedGroupNames = formData.filter((obj, index) => index != rowIndex ? true : false).map((obj) => obj.groupName);
            // console.log("selectedGroupNames",selectedGroupNames)
            return (<CommonSelectBox

                key={rowIndex + 'groupbox'}
                options={Groups && Object.keys(Groups)
                    .sort()
                    .filter(key => !selectedGroupNames.includes(Groups[key]['group_id']))
                    .map(key => ({
                        value: Groups[key]['group_id'],
                        children: Groups[key]['group_name']
                    }))}
                // options={[{value:3,children:'ghj'}]}
                isLoading={false}
                placeholder="Select group"
                isDisabled={false}
                type="groups"
                indexVal={rowIndex}
                updateValue={updateGroupSelectBox}
                selectedOptions={GroupName}
                validStatus={validgroup}
            // validStatus={groupNameValid[rowIndex]}
            />)
        }
    }, [Groups, formData, setFormData])

    //Status Dropdown box
    const renderStatusBox = useMemo(() => {
        return (status, rowIndex) => {
            const isOpen = isStatusOpen[rowIndex]; // Assuming isStatusOpen is an array
            const onSelectBoxToggle = () => {
                setIsStatusOpen(prevState => {
                    const newState = [...prevState];
                    newState[rowIndex] = !prevState[rowIndex];
                    return newState;
                });
            };

            const onSelectBox = (_event, value) => {
                let text = _event.target.innerText;
                const updatedFormData = [...formData];
                updatedFormData[rowIndex].status = { id: value, Status: text };
                setFormData(updatedFormData);
                onSelectBoxToggle();
            };

            const statusToggle = toggleRef => (

                <MenuToggle
                    placeholder='Select Status'
                    ref={toggleRef}
                    onClick={onSelectBoxToggle}
                    isExpanded={isOpen}
                    isFullWidth={true}
                >
                    {status.Status}
                </MenuToggle>
            );

            return (
                <Select
                    id={`status-sel-${rowIndex}`}
                    isOpen={isOpen}
                    selected={status.id}
                    onSelect={(e, value) => onSelectBox(e, value)}
                    onOpenChange={() => onSelectBoxToggle()}
                    toggle={statusToggle}
                    shouldFocusToggleOnSelect
                >
                    <SelectList>
                        {StatusList.map((option, index) => (
                            <SelectOption key={index} value={option.id}>
                                {option.Status}
                            </SelectOption>
                        ))}
                    </SelectList>
                </Select>
            );
        }
    }, [isStatusOpen, formData, setFormData])

    return <React.Fragment>
        <Modal variant={ModalVariant.large} title={`Add Groups For Organization (${organizationName})`}
            style={{ height: '500px' }}
            isOpen={isModalOpen}
            onClose={() => {
                handleModalToggle();
                setListRowId('');
                setOrganizationName('')
            }}
            actions={[<Button variant="primary" onClick={() => { submitForm(); }} >Submit</Button>]}
        >
            <Card>
                {isloadgetGroup ? <Loader /> :
                    <>
                        <Banner screenReaderText="Info banner" variant="blue">
                            <Flex spaceItems={{ default: 'spaceItemsSm' }}>
                                <FlexItem>
                                    <InfoCircleIcon />
                                </FlexItem>
                                <FlexItem>Press enter after typing complete index name to create tags.</FlexItem>
                            </Flex>
                        </Banner>
                        {formData.map((row, rowIndex) => {
                            return (
                                <div className="pf-v5-u-p-sm" key={rowIndex}>
                                    <Flex spaceItems={{ default: 'spaceItemsXs' }} display={{ default: 'inlineFlex' }} className='pf-v5-u-display-flex pf-v5-u-flex-nowrap' key={rowIndex + row.orgGroupId}>
                                        <FlexItem key={1}>
                                            {groupBox(row.groupName, rowIndex, row.groupNameValid)}
                                        </FlexItem>
                                        <FlexItem key={2}>
                                            <IndexInputGroup
                                                placeholder="enter indexes name"
                                                currentChips={row.indexName}
                                                setCurrentChips={newValue => {
                                                    const updatedFormData = [...formData];
                                                    updatedFormData[rowIndex].indexName = newValue;
                                                    updatedFormData[rowIndex].indexNameValid = emptyArrayCheck(newValue);
                                                    setFormData(updatedFormData);
                                                }}
                                                indexNameValid={row.indexNameValid}
                                            />
                                        </FlexItem>
                                        <FlexItem key={3}>
                                            {renderStatusBox(row.status, rowIndex)}
                                        </FlexItem>
                                        <FlexItem key={4}>
                                            <Radio name="basic-inline-radio" isChecked={row.isparent} key={rowIndex} onChange={() => handleRadioChange(rowIndex)} label="Is Parent" id="basic-inline-radio-01" />
                                            {/* <Radio name="basic-inline-radio" label="Central" id="basic-inline-radio-02" /> */}
                                        </FlexItem>
                                        {formData.length - 1 == rowIndex && <FlexItem key={5}><a><Icon status="info" onClick={() => { addNewRow(rowIndex); }}><PlusCircleIcon /></Icon></a></FlexItem>}
                                        {formData.length !== 1 && <FlexItem key={6}><a><Icon status="danger"
                                            onClick={() => {
                                                { row.orgGroupId == '' && removeRow(rowIndex); }

                                                if (row.orgGroupId !== '') { deleteGroups(row.orgGroupId) }
                                            }}><TrashIcon /></Icon></a></FlexItem>}
                                    </Flex>
                                </div>
                            )
                        })}
                        {!validparent && <HelperText className='pf-v5-u-ml-md'>
                            <HelperTextItem variant="error" hasIcon>Please select one parent group.</HelperTextItem>
                        </HelperText>}

                    </>
                }
            </Card>
        </Modal>
        <ConfirmBox confirmModal={confirmModalData} />
    </React.Fragment >
});


const mapStateToProps = (state) => ({})

export default connect(
    mapStateToProps,
    null,
    null,
    { forwardRef: true }
)(OrganizationGroupForm)