import * as echarts from 'echarts';
import React, { useEffect } from 'react';
import { generateUniqueID } from '../../util/UniqueIdGenerator';
import { calculateTimeDifference, formatTime, formatTimestamp } from '../../util/Timeutils';
import { formatValue } from '../../util/ChartHelper';
import { connect } from 'react-redux';
const DetectionResponseTrend = ({ theme, ResponseData, DetectionData, TdArLabel, StartDateTime, EndDateTime,sidebarState }) => {
  const id = generateUniqueID()
  useEffect(() => {
    // alert("hello!")
    // if (ResponseData && DetectionData && DetectionData.length > 0 && ResponseData.length > 0)
    {
      const chartDom = document.getElementById(id + 'tdar');
      const myChart = echarts.init(chartDom);
      const textcolor = theme == 'dark-theme' ? '#fff' : '#000'
      const linecolor = theme == 'dark-theme' ? '#595757' : '#d9d9d9'
      const { resultInMinutes, resultInHours, resultInDays } = calculateTimeDifference(StartDateTime, EndDateTime);
      const Label = TdArLabel;
      const uniqueDatesSet = new Set();
      const xAxisData = []
      let Tddate = []
      let Ardate = []
      // console.log("alldata", ResponseData)
      for (let i = 0; i < ResponseData.length; i++) {
        Ardate = ResponseData[i].key_as_string;
        uniqueDatesSet.add(Ardate);
      }
      for (let i = 0; i < DetectionData.length; i++) {
        Tddate = DetectionData[i].key_as_string;
        uniqueDatesSet.add(Tddate);
      }

      const uniqueDatesArray = [...uniqueDatesSet];
      // console.log(uniqueDatesArray)
      for (let k = 0; k < uniqueDatesArray.length; k++) {
        const { ConvertTimestamp, hours, minutes, seconds } = formatTimestamp(uniqueDatesArray[k]);
        const formattedTime = formatTime(resultInMinutes, resultInHours, resultInDays, hours, minutes, seconds, ConvertTimestamp);
        xAxisData.push({ x: formattedTime });
        // console.log("dates1", xAxisData)
      }



      const seriesTDData = { ThreatDetection: [] };
      const seriesArData = { ActiveResponse: [] };

      ResponseData.forEach(entry => { seriesArData.ActiveResponse.push(entry?.doc_count); });

      DetectionData.forEach(entry => { seriesTDData.ThreatDetection.push(entry?.doc_count); });


      let interval = ''
      if (Label === '30 seconds') {
        interval = 4
      }
      else if (Label === 'hour') {
        interval = 3
      }
      else {
        interval = 2
      }

      const option = {
        tooltip: {
          trigger: 'axis',
          textStyle: {
            color: "#000"
          }
        },
        title: {
          text: `Timestamp per ${Label}`,
          top: 'bottom',
          left: 'center',
          textStyle: {
            fontWeight: 450,
            fontSize: 14,
            color: textcolor,
          },

        },
        legend: {
          orient: 'vertical',
          right: '3',
          top: 10,
          itemWidth: 9.5,
          itemHeight: 10,
          itemGap: 6.5,
          icon: "rect",
          textStyle: {
            color: textcolor
          },
          data: ['Threat Detection', 'Active Response']
        },
        grid: {
          left: 0,
          right: '14%',
          bottom: '12%',
          top: '6%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          axisLabel: { interval: interval, rotate: -50, color: textcolor },
          boundaryGap: true,
          data: xAxisData.map(item => item.x),
          axisTick: {
            alignWithLabel: true
          },

        },
        yAxis: {
          type: 'value',
          splitLine: {
            lineStyle: {
              color: linecolor
            }
          },
          axisLabel: {
            color: textcolor,
            formatter: formatValue
          },
          axisLine: {
            show: true
          },
        },
        series: [
          {
            name: 'Threat Detection',
            type: 'line',
            data: seriesTDData.ThreatDetection,
            emphasis: {
              focus: 'series',
            },
            itemStyle: {
              color: '#C9190B', // Red for active, Green for solved
            },
          },
          {
            name: 'Active Response',
            type: 'line',
            data: seriesArData.ActiveResponse,
            emphasis: {
              focus: 'series',
            },
            itemStyle: {
              color: '#3e8635', // Red for active, Green for solved
            },
          },
        ]
      };
      option && myChart.setOption(option);
      const resizeChart = () => {
        if (myChart) { // Check if chart exists before resizing
          myChart.resize();
        }
      };

    window.addEventListener('resize', resizeChart);
    return () => {
        myChart.dispose();
        window.removeEventListener('resize', resizeChart);
    };
    }

  }, [theme, ResponseData, DetectionData, TdArLabel, StartDateTime, EndDateTime,sidebarState])


  return <> <div className="container">
    <div id={id + 'tdar'} style={{ width: '100%', height: '300px' }} > </div>
  </div>
  </>
}

const mapStateToProps = (state) => ({
  dates: { startDate: state.startdate, endDate: state.enddate },
  filterformdata: state.FilterData,
  theme: state.theme,
  sidebarState:state.sidebarstate
  // mapStateToProps content remains unchanged if you have it
});

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(DetectionResponseTrend)