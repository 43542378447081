import * as echarts from 'echarts';
import React, { useEffect } from 'react';
import { generateUniqueID } from '../../util/UniqueIdGenerator';
import { Emptystatefornodata } from '../../util/EmptyStateCard';
import { connect, useDispatch } from 'react-redux';
import { FilterData } from '../../Redux/Action';
import { UpdateFilterData } from '../../util/FilterHelper';
import { formatValue } from 'src/util/ChartHelper';
const RealTimeTopAgents = (props) => {
    const id = generateUniqueID()
    const dispatch = useDispatch();
    const handleFilter = (fields, value) => {
        const updatedFields = UpdateFilterData(props.filterformdata, fields, 'is', value);
        dispatch(FilterData(updatedFields));
    }
    useEffect(() => {
        if (props.TopData && props.TopData.length > 0) {
            const chartDom = document.getElementById(id + 'enib');
            const myChart = echarts.init(chartDom);
            const textcolor = props.theme == 'dark-theme' ? '#fff' : '#000'
            const linecolor = props.theme == 'dark-theme' ? '#595757' : '#d9d9d9'
            const seriesData = [{
                type: 'bar',
                barWidth: 10,
                data: props.TopData.map(item => ({
                    name: item.key,
                    value: item.doc_count,
                    nestedkey: item.type
                }))
            }];

            const option = {
                tooltip: {
                    trigger: 'axis',
                    textStyle: {
                        color: "#000"
                    }
                },
                title:
                {
                    text: 'Top Agents',
                    textStyle: {
                        color: textcolor
                    }
                },
                animation: false,
                grid: {
                    left: '1%',
                    right: '8%',
                    bottom: '10%',
                    top: '20%',
                    containLabel: true
                },
                xAxis: {
                    name: `Count`,
                    nameLocation: "middle",
                    nameTextStyle: {
                        align: "center",
                        lineHeight: 80,
                        fontSize: 14,
                        color: textcolor,
                    },
                    axisLine: {
                        show: true
                    },
                    axisLabel: { rotate: -60, color: textcolor, formatter: formatValue },
                    splitLine: {
                        lineStyle: {
                            color: linecolor
                        }
                    },
                },
                yAxis: {
                    type: 'category',
                    inverse: true,
                    axisLabel: {
                        interval: 0,
                        rotate: 20,
                        width: 70,
                        overflow: 'truncate',
                        fontSize: 12,
                        color: textcolor
                    },
                    boundaryGap: true,
                    data: props.TopData.map(item => item.key),
                    axisTick: {
                        alignWithLabel: true
                    },

                },
                color: ['#0066CC'],
                series: seriesData
            };

            option && myChart.setOption(option);
            myChart.on('click', 'series.bar', function (param) {
                const Fields = props.filterFields ? props.filterFields : param.data.nestedkey
                handleFilter(Fields, param.data.name)
            })
            const resizeChart = () => {
                if (myChart) { // Check if chart exists before resizing
                    myChart.resize();
                }
            };

            window.addEventListener('resize', resizeChart);
            return () => {
                myChart.dispose();
                window.removeEventListener('resize', resizeChart);
            };
        }

    }, [props.TopData, props.theme, props.sidebarState])

    return <> <div className="container">
        {props.TopData.length == 0 ?
            (<>
                <div id={id + 'enib'} style={{ width: '1px', height: '1px' }}> </div>
                < Emptystatefornodata />
            </>)
            : (
                <div id={id + 'enib'} style={{ width: '100%', height: '290px' }} > </div>
            )
        }
    </div>
    </>
}

const mapStateToProps = (state) => ({
    dates: { startDate: state.startdate, endDate: state.enddate },
    filterformdata: state.FilterData,
    theme: state.theme,
    sidebarState: state.sidebarstate
    // mapStateToProps content remains unchanged if you have it
});

export default connect(
    mapStateToProps,
    null,
    null,
    { forwardRef: true }
)(RealTimeTopAgents)