import React, { useEffect, useState } from 'react';
import {
  Card,
  DataList,
  DataListCell,
  DataListItem,
  DataListItemCells,
  DataListItemRow,
  Toolbar,
  ToolbarContent,
  ToolbarItem,
  Divider,
  Drawer,
  DrawerActions,
  DrawerCloseButton,
  DrawerContent,
  DrawerContentBody,
  DrawerHead,
  DrawerPanelBody,
  DrawerPanelContent,
  Flex,
  FlexItem,
  Title,
  SearchInput,
  ExpandableSection
} from '@patternfly/react-core';

import dbApi from '../../services/DbApi'
import MiteIntelligenceTable from './MitreIntelligenceTable';
import { Loader } from '../../util/Loadingstate';
import { formatTableTimestamp } from '../../util/Timeutils';
import MarkdownIt from 'markdown-it';

const MitreIntelligence = () => {
  const md = new MarkdownIt({
    html: true,
    linkify: true,
    typographer: true,
    highlight: true,
    xhtmlOut: true,
    breaks: true
  });
  const [selectedDataListItemId, setSelectedDataListItemId] = React.useState('Groups');
  const [isExpanded, setIsExpanded] = React.useState(false);
  const [isExpandparent, setIsExpandparent] = React.useState(false);
  const [apiid, setApiId] = useState('')
  const [groupData, setGroupData] = useState([])
  const [groupCount, setGroupCount] = useState(0)
  const [loadgroup, setLoadGroup] = useState(true)
  const [mitigationData, setMitigationData] = useState([])
  const [mitigationCount, setMitigationCount] = useState(0)
  const [loadmitigation, setLoadMitigation] = useState(true)
  const [softwareData, setSoftwareData] = useState([])
  const [softwareCount, setSoftwareCount] = useState(0)
  const [loadsoftware, setloadSoftware] = useState(true)
  const [tacticsData, setTacticsData] = useState([])
  const [tacticsCount, setTacticsCount] = useState(0)
  const [loadtactics, setLoadTactics] = useState(true)
  const [techniquesData, setTechniquesData] = useState([])
  const [techniquesCount, setTechniqueCount] = useState(0)
  const [detailrow, setDetailrow] = useState([])
  const [loadtechniques, setLoadTechniques] = useState(true)
  const [searchValue, setSearchValue] = useState('')
  const [inputValue, setInputValue] = useState('')
  //Childpanelcontent drawer state
  const [loadsoftwareid, setLoadSoftwareId] = useState(false)
  const [softwarechildData, setsoftwarechildData] = useState([])
  const [loadgroupid, setLoadGroupId] = useState(false)
  const [groupschildData, setgroupschildData] = useState([])
  const [loadtacticsid, setLoadTacticsId] = useState(false)
  const [tacticschildData, settacticschildData] = useState([])
  const [loadtechniquesid, setLoadTechniquesId] = useState(false)
  const [techniqueschildData, settechniqueschildData] = useState([])
  const [loadmitigationid, setLoadMitigationId] = useState(false)
  const [mitigationchildData, setmitigationchildData] = useState([])

  const [expandedSections, setExpandedSections] = useState(Array(5).fill(true));
  const onToggle = (index) => {
    const newExpandedSections = [...expandedSections];
    newExpandedSections[index] = !newExpandedSections[index]; // Toggle the expanded state of the clicked section
    setExpandedSections(newExpandedSections);
  };

  const getMitreGroup = async () => {
    let data = {
      search: searchValue
    }
    try {
      setLoadGroup(true)
      let Mitregroups = await dbApi.postApi("Opensearch/Mitre_Groups", data);
      console.log(Mitregroups)
      if (Object.keys(Mitregroups.Mitre_Groups).length !== 0) {
        let Mitre_groups = Mitregroups.Mitre_Groups
        let groupData = [];
        let groupCount = 0
        if (Mitre_groups.length > 0) {
          groupData = Mitre_groups
          groupCount = Mitre_groups.length
        }
        setGroupData(groupData)
        setGroupCount(groupCount)
      }
      else {
        setGroupData([])
        setGroupCount(0)
      }
      setLoadGroup(false)
    }
    catch (error) {
      console.log(error)
      setLoadGroup(false)
    }
  }
  const getMitigations = async () => {
    let data = {
      search: searchValue
    }
    try {
      setLoadMitigation(true)
      let Mitigation = await dbApi.postApi("Opensearch/Mitre_Mitigation", data);
      console.log(Mitigation)
      if (Object.keys(Mitigation.Mitre_Mitigation).length !== 0) {
        let Mitre_mitigation = Mitigation.Mitre_Mitigation
        let mitigationData = [];
        let mitigationCount = 0
        if (Mitre_mitigation.length > 0) {
          mitigationData = Mitre_mitigation
          mitigationCount = Mitre_mitigation.length
        }
        setMitigationData(mitigationData)
        setMitigationCount(mitigationCount)
      }
      else {
        setMitigationData([])
        setMitigationCount(0)
      }
      setLoadMitigation(false)
    }
    catch (error) {
      console.log(error)
      setLoadMitigation(false)
    }
  }
  const getMitreSoftware = async () => {
    let data = {
      search: searchValue
    }
    try {
      setloadSoftware(true)
      let Software = await dbApi.postApi("Opensearch/Mitre_Software", data);
      console.log(Software)
      if (Object.keys(Software.Mitre_Software).length !== 0) {
        let Mitre_Software = Software.Mitre_Software
        let softwareData = [];
        let softwareCount = 0
        if (Mitre_Software.length > 0) {
          softwareData = Mitre_Software
          softwareCount = Mitre_Software.length
        }
        setSoftwareData(softwareData)
        setSoftwareCount(softwareCount)
      }
      else {
        setSoftwareData([])
        setSoftwareCount(0)
      }
      setloadSoftware(false)
    }
    catch (error) {
      console.log(error)
      setloadSoftware(false)
    }
  }
  const getMitreTactics = async () => {
    let data = {
      search: searchValue
    }
    try {
      setLoadTactics(true)
      let Tactics = await dbApi.postApi("Opensearch/Mitre_Tactics", data);
      console.log(Tactics)
      if (Object.keys(Tactics.Mitre_Tactics).length !== 0) {
        let Mitre_Tactics = Tactics.Mitre_Tactics
        let tacticsData = [];
        let tacticsCount = 0
        if (Mitre_Tactics.length > 0) {
          tacticsData = Mitre_Tactics
          tacticsCount = Mitre_Tactics.length
        }
        setTacticsData(tacticsData)
        setTacticsCount(tacticsCount)
      }
      else {
        setTacticsData([])
        setTacticsCount(0)
      }
      setLoadTactics(false)
    }
    catch (error) {
      console.log(error)
      setLoadTactics(false)
    }
  }
  const getMitreTechnique = async () => {
    let data = {
      search: searchValue
    }
    try {
      setLoadTechniques(true)
      let Techniques = await dbApi.postApi("Opensearch/Mitre_Techniques", data);
      console.log(Techniques)
      if (Object.keys(Techniques.Mitre_Techniques).length !== 0) {
        let Mitre_Techniques = Techniques.Mitre_Techniques
        let techniquesData = [];
        let techniquesCount = 0
        if (Mitre_Techniques.length > 0) {
          techniquesData = Mitre_Techniques
          techniquesCount = Mitre_Techniques.length
        }
        setTechniquesData(techniquesData)
        setTechniqueCount(techniquesCount)
      }
      else {
        setTechniquesData([])
        setTechniqueCount(0)
      }
      setLoadTechniques(false)
    }
    catch (error) {
      console.log(error)
      setLoadTechniques(false)
    }
  }
  useEffect(() => {
    if (selectedDataListItemId === 'Groups') {
      getMitreGroup()
    }
    else if (selectedDataListItemId === 'Mitigations') {
      getMitigations()
    }
    else if (selectedDataListItemId === 'Software') {
      getMitreSoftware()
    }
    else if (selectedDataListItemId === 'Tactics') {
      getMitreTactics()
    }
    else if (selectedDataListItemId === 'Techniques') {
      getMitreTechnique()
    }
    // getMitreGroup()
  }, [selectedDataListItemId,searchValue])


  const getSoftwareID = async (data) => {
    try {
      setLoadSoftwareId(true)
      let res = await dbApi.postApi("Opensearch/Mitre_Software_ID", data);
      console.log(res)
      if (Object.keys(res.Mitre_Software_ID.data.affected_items).length !== 0) {
        let Mitre_child_soft = res.Mitre_Software_ID.data.affected_items
        let softwarechildData = [];
        if (res.Mitre_Software_ID.data.affected_items.length > 0) {
          softwarechildData = Mitre_child_soft
        }
        setsoftwarechildData(softwarechildData)
      }
      else {
        setsoftwarechildData([])
      }
      setLoadSoftwareId(false)
    } catch (error) {
      console.log(error)
      setLoadSoftwareId(false)
    }

  }
  const getTechniquesID = async (data) => {
    try {
      setLoadTechniquesId(true)
      let res = await dbApi.postApi("Opensearch/Mitre_Techniques_ID", data);
      console.log(res)
      if (Object.keys(res.Mitre_Techniques_ID.data.affected_items).length !== 0) {
        let Mitre_child_Tech = res.Mitre_Techniques_ID.data.affected_items
        let techniqueschildData = [];
        if (res.Mitre_Techniques_ID.data.affected_items.length > 0) {
          techniqueschildData = Mitre_child_Tech
        }
        settechniqueschildData(techniqueschildData)
      }
      else {
        settechniqueschildData([])
      }
      setLoadTechniquesId(false)
    } catch (error) {
      console.log(error)
      setLoadTechniquesId(false)
    }

  }
  const getGroupsID = async (data) => {
    try {
      setLoadGroupId(true)
      let res = await dbApi.postApi("Opensearch/Mitre_Groups_ID", data);
      console.log(res)
      if (Object.keys(res.Mitre_Groups_ID.data.affected_items).length !== 0) {
        let Mitre_child_groups = res.Mitre_Groups_ID.data.affected_items
        let groupschildData = [];
        if (res.Mitre_Groups_ID.data.affected_items.length > 0) {
          groupschildData = Mitre_child_groups
        }
        setgroupschildData(groupschildData)
      }
      else {
        setgroupschildData([])
      }
      setLoadGroupId(false)
    } catch (error) {
      console.log(error)
      setLoadGroupId(false)
    }

  }
  const getTacticsID = async (data) => {
    try {
      setLoadTacticsId(false)
      let res = await dbApi.postApi("Opensearch/Mitre_Tactics_ID", data);
      console.log(res)
      if (Object.keys(res.Mitre_Tactics_ID.data.affected_items).length !== 0) {
        let mitre_childtactics = res.Mitre_Tactics_ID.data.affected_items
        let tacticschildData = [];
        if (res.Mitre_Tactics_ID.data.affected_items.length > 0) {
          tacticschildData = mitre_childtactics
        }
        settacticschildData(tacticschildData)
      }
      else {
        settacticschildData([])
      }
      setLoadTacticsId(false)
    } catch (error) {
      console.log(error)
      setLoadTacticsId(false)
    }

  }
  const getMitigationsID = async (data) => {
    try {
      setLoadMitigationId(true)
      let res = await dbApi.postApi("Opensearch/Mitre_Mitigations_ID", data);
      console.log(res)
      if (Object.keys(res.Mitre_Mitigation_ID.data.affected_items).length !== 0) {
        let Mitre_child_mitigation = res.Mitre_Mitigation_ID.data.affected_items
        let mitigationchildData = [];
        if (res.Mitre_Mitigation_ID.data.affected_items.length > 0) {
          mitigationchildData = Mitre_child_mitigation
        }
        setmitigationchildData(mitigationchildData)
      }
      else {
        setmitigationchildData([])
      }
      setLoadMitigationId(false)
    } catch (error) {
      console.log(error)
      setLoadMitigationId(false)
    }

  }
  const onSelectDataListItem = (_event, id) => {
    setSelectedDataListItemId(id);
    setIsExpanded(true);
    setSearchValue('');
    setInputValue('');
  };
  const onClose = () => {
    setIsExpanded(false);
  };
  const onIDselect = (row) => {
    setIsExpandparent(true)
    if (row.details.software != null) { getSoftwareID({ Mitre_Software_ID: row.details.software }) }
    if (row.details.mitigations != null) { getMitigationsID({ Mitre_Mitigations_ID: row.details.mitigations }) }
    if (row.details.tactics != null) { getTacticsID({ Mitre_Tactics_ID: row.details.tactics }) }
    if (row.details.techniques != null) { getTechniquesID({ Mitre_Techniques_ID: row.details.techniques }) }
    if (row.details.groups != null) { getGroupsID({ Mitre_Groups_ID: row.details.groups }) }
    setDetailrow(row)
  }

  const onparentClose = () => {
    setIsExpandparent(false)
  }
  const onSearch = event => {
    if (event.key && event.key !== 'Enter') {
      return;
    }
    setSearchValue(inputValue)
  }

  const panelContent = <DrawerPanelContent defaultSize={'84%'}>
    <DrawerHead>
      <Title size="lg" headingLevel="h2">
        {(() => {
          switch (selectedDataListItemId) {
            case 'Groups':
              return (<> {selectedDataListItemId} ({groupCount}) </>);
            case 'Mitigations':
              return (<>{selectedDataListItemId}({mitigationCount}) </>)
            case 'Software':
              return (<>{selectedDataListItemId}({softwareCount}) </>)
            case 'Tactics':
              return (<>{selectedDataListItemId}({tacticsCount}) </>)
            case 'Techniques':
              return (<>{selectedDataListItemId}({techniquesCount}) </>)
            default:
              break;
          }
        })()}
      </Title>
      <SearchInput placeholder="Search..." className='pf-v5-u-mt-md'
          value={inputValue}
          onChange={(_event, value) => setInputValue(value)}
          onSearch={onSearch}
          onClear={() => {setInputValue('');setSearchValue('')}} />
      <DrawerActions> <DrawerCloseButton onClick={onClose} /> </DrawerActions>
    </DrawerHead>
    <DrawerPanelBody>
      {(() => {
        switch (selectedDataListItemId) {
          case 'Groups':
            return (loadgroup ? <Loader /> : <MiteIntelligenceTable onIDselect={onIDselect} Tabledata={groupData} />);
          case 'Mitigations':
            return (loadmitigation ? <Loader /> : <MiteIntelligenceTable onIDselect={onIDselect} Tabledata={mitigationData} />)
          case 'Software':
            return (loadsoftware ? <Loader /> : <MiteIntelligenceTable onIDselect={onIDselect} Tabledata={softwareData} />)
          case 'Tactics':
            return (loadtactics ? <Loader /> : <MiteIntelligenceTable onIDselect={onIDselect} Tabledata={tacticsData} />)
          case 'Techniques':
            return (loadtechniques ? <Loader /> : <MiteIntelligenceTable onIDselect={onIDselect} Tabledata={techniquesData} />)
          default:
            break;
        }
      })()}
    </DrawerPanelBody>
  </DrawerPanelContent>

  let formattedcreatedtime, formattedmodifiedtime;

  if (detailrow.details) {
    formattedcreatedtime = formatTableTimestamp(detailrow.details.created_time).formattedTimeStamp;
    formattedmodifiedtime = formatTableTimestamp(detailrow.details.modified_time).formattedTimeStamp;
  }

  const renderHtmlContent = (content) => {
    return { __html: md.render(content) };
  };

  const ChildPanelContent = <DrawerPanelContent defaultSize={'70%'}>
    <DrawerHead>
      <Title headingLevel="h2">Details</Title>
      <DrawerActions> <DrawerCloseButton onClick={onparentClose} /> </DrawerActions>
    </DrawerHead>
    <Divider />
    <DrawerPanelBody>
      {detailrow.details &&
        (<>
          <Flex spaceItems={{ default: 'spaceItemsXl' }}>
            <FlexItem>
              <Title headingLevel='h5'>ID</Title>
              <p>{detailrow.details.external_id}</p>
            </FlexItem>
            <FlexItem>
              <Title headingLevel='h5'>Name</Title>
              <p>{detailrow.details.name}</p>
            </FlexItem>
            <FlexItem>
              <Title headingLevel='h5'>Created time</Title>
              <p>{formattedcreatedtime}</p>
            </FlexItem>
            <FlexItem>
              <Title headingLevel='h5'>ModifiedTime</Title>
              <p>{formattedmodifiedtime} </p>
            </FlexItem>
            <FlexItem>
              <Title headingLevel='h5'>Version</Title>
              <p>{detailrow.details.mitre_version}</p>
            </FlexItem>
            <FlexItem>
              <Title headingLevel='h5'>Description</Title>
              {/* <p>{formatText(detailrow.details.description)}</p> */}

              <p dangerouslySetInnerHTML={renderHtmlContent(detailrow.details.description)}></p>
            </FlexItem>
          </Flex>
        </>
        )}
      <br />

      {loadgroupid ? <Loader /> :
        groupschildData.length !== 0 &&
        <ExpandableSection toggleText={'Groups'} onToggle={() => onToggle(0)} isExpanded={expandedSections[0]}>
          <MiteIntelligenceTable onIDselect={onIDselect} Tabledata={groupschildData} />
        </ExpandableSection>
      }
      {loadmitigationid ? <Loader /> : mitigationchildData.length !== 0 &&
        <ExpandableSection toggleText={'Mitigation '} onToggle={() => onToggle(1)} isExpanded={expandedSections[1]}>
          <MiteIntelligenceTable onIDselect={onIDselect} Tabledata={mitigationchildData} />
        </ExpandableSection>
      }
      {loadsoftwareid ? <Loader /> : softwarechildData.length !== 0 &&
        <ExpandableSection toggleText={'Software'} onToggle={() => onToggle(2)} isExpanded={expandedSections[2]}>
          <MiteIntelligenceTable onIDselect={onIDselect} Tabledata={softwarechildData} />
        </ExpandableSection>
      }
      {loadtacticsid ? <Loader /> : tacticschildData.length !== 0 &&
        <ExpandableSection toggleText={'Tactics'} onToggle={() => onToggle(3)} isExpanded={expandedSections[3]}>
          <MiteIntelligenceTable onIDselect={onIDselect} Tabledata={tacticschildData} />
        </ExpandableSection>
      }
      {loadtechniquesid ? <Loader /> : techniqueschildData.length !== 0 &&
        <ExpandableSection toggleText={'Techniques'} onToggle={() => onToggle(4)} isExpanded={expandedSections[4]}>
          <MiteIntelligenceTable onIDselect={onIDselect} Tabledata={techniqueschildData} />
          {/* expandedSections[4] ? 'Techniques ' : 'Techniques' */}
        </ExpandableSection>
      }
    </DrawerPanelBody>
  </DrawerPanelContent >


  const drawerContent = <React.Fragment>
    {/* <Toolbar id="data-list-data-toolbar">
      <ToolbarContent>
        <ToolbarItem >
          <SearchInput placeholder='Search in all resources' />
        </ToolbarItem>
      </ToolbarContent>
    </Toolbar> */}
    <DataList aria-label="selectable data list example" selectedDataListItemId={selectedDataListItemId} onSelectDataListItem={onSelectDataListItem} >
      <DataListItem aria-label="data-list-Groups-in-card" id="Groups">
        <DataListItemRow>
          <DataListItemCells dataListCells={[<DataListCell key="Groups">Groups</DataListCell>]} />
        </DataListItemRow>
      </DataListItem>
      <DataListItem aria-label="data-list-Mitigations-in-card" id="Mitigations">
        <DataListItemRow>
          <DataListItemCells dataListCells={[<DataListCell key="Mitigations">Mitigations </DataListCell>]} />
        </DataListItemRow>
      </DataListItem>
      <DataListItem aria-label="data-list-Software-in-card" id="Software">
        <DataListItemRow>
          <DataListItemCells dataListCells={[<DataListCell key="Software">Software</DataListCell>]} />
        </DataListItemRow>
      </DataListItem>
      <DataListItem aria-label="data-list-Tactics-in-card" id="Tactics">
        <DataListItemRow>
          <DataListItemCells dataListCells={[<DataListCell key="Tactics">Tactics </DataListCell>]} />
        </DataListItemRow>
      </DataListItem>
      <DataListItem aria-label="data-list-Techniques-in-card" id="Techniques">
        <DataListItemRow>
          <DataListItemCells dataListCells={[<DataListCell key="Techniques">Techniques </DataListCell>]} />
        </DataListItemRow>
      </DataListItem>
    </DataList>
  </React.Fragment>;
  return (
    <Card isRounded isFlat >
      <Drawer isExpanded={isExpandparent}>
        <DrawerContent panelContent={ChildPanelContent}>
          <DrawerContentBody>
            <Drawer isStatic isExpanded={isExpanded} isInline>
              <DrawerContent panelContent={panelContent}>
                <DrawerContentBody >{drawerContent}</DrawerContentBody>
              </DrawerContent>
            </Drawer>
          </DrawerContentBody>
        </DrawerContent>
      </Drawer>
    </Card>
  )
};
export default MitreIntelligence