import {
    Button,
    Card,
    CardTitle,
    PageSection,
    Tabs,
    Tab,
    TabTitleText,
    Icon,
    CardHeader,
    Flex,
    FlexItem,
    TabContentBody
} from '@patternfly/react-core'
import { AngleLeftIcon, FolderOpenAltIcon } from '@patternfly/react-icons'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import GroupAgentTable from './GroupAgentTable'
import GroupFileTable from './GroupFileTable'

const GroupAgent = (props) => {
    const { groupname } = useParams()
    const [activeTabKey, setActiveTabKey] = useState(0);
    const handleTabClick = (event, tabIndex) => {
        setActiveTabKey(tabIndex)
    }
    const headerActions = <>
        <Flex spaceItems={{ default: 'spaceItemsXs' }} justifyContent={{ default: 'justifyContentFlexEnd' }}>
            <FlexItem>
                <Link to={"/management/manage-group/"+groupname} className='pf-v5-u-color-100'>
                    <Button  variant='link' icon={<FolderOpenAltIcon />} onClick={() => { }}>Manage agents</Button>
                </Link>
            </FlexItem>
        </Flex>
    </>
    return (
        <>
            <PageSection className="pf-v5-u-p-sm">
                <Card>
                    <CardHeader actions={{ actions: (headerActions) }}>
                        <CardTitle component='h2'>
                            <Link to={"/management/groups"} className='pf-v5-u-color-100'>
                                <Icon size='md' isInline><AngleLeftIcon /></Icon>{groupname}
                            </Link></CardTitle>
                    </CardHeader>
                    <Tabs activeKey={activeTabKey} onSelect={handleTabClick} aria-label="Tabs for rule level alerts" role="region">
                        <Tab eventKey={0} title={<TabTitleText>Agents</TabTitleText>} aria-label="rule-log-table">
                            {activeTabKey == 0 &&
                                <TabContentBody className='pf-v5-u-p-xs'>
                                <GroupAgentTable GroupName={groupname} />
                            </TabContentBody>}
                        </Tab>
                        <Tab eventKey={1} title={<TabTitleText>Files</TabTitleText>}>
                            {activeTabKey == 1 &&
                                <TabContentBody className='pf-v5-u-p-xs'>
                                    <GroupFileTable GroupName={groupname} />
                                </TabContentBody>}

                        </Tab>
                    </Tabs>

                </Card>
            </PageSection>
        </>
    )
}
export default GroupAgent