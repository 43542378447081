import React, { useEffect } from 'react';
import { generateUniqueID } from '../../util/UniqueIdGenerator';
import * as echarts from 'echarts';
import worldJson from '../../util/world.json'
import { Emptystatefornodata } from '../../util/EmptyStateCard';
import { connect } from 'react-redux';

 const TopOrigins = ({OriginData,sidebarState,theme}) => {
    const id = generateUniqueID()
    useEffect(() => {

        const chartDom = document.getElementById(id + 'threatorigin');
        const myChart = echarts.init(chartDom);
        let option;
        // const maxcount = TopLocation.length > 0
        //     ? Math.max(...TopLocation.map(bucket => parseFloat(bucket.doc_count)))
        //     : 380000;

        // const mincount = TopLocation.length > 0
        //     ? Math.min(...TopLocation.map(bucket => parseFloat(bucket.doc_count)))
        //     : 380000;
        myChart.showLoading();
        myChart.hideLoading();
        echarts.registerMap('world', worldJson);
        option = {


            tooltip: {
                trigger: 'item',
                showDelay: 0,
                transitionDuration: 0.2
            },
            visualMap: {
                right: 'right',
                min: 0,
                max: 38000,
                // max: TopLocation.map((bucket) => {parseFloat(Math.max(bucket.doc_count)) == undefined ? 380000 : parseFloat(Math.max(bucket.doc_count))}),
                inRange: {
                    color: [
                        '#313695',
                        '#4575b4',
                        '#74add1',
                        '#abd9e9',
                        '#e0f3f8',
                        '#ffffbf',
                        '#fee090',
                        '#fdae61',
                        '#f46d43',
                        '#d73027',
                        '#a50026'
                    ]
                },
                text: ['High', 'Low'],
                calculable: true
            },
            toolbox: {
                show: true,
                left: 'right',
                top: 'top',
            },
            series: [
                {
                    name: 'Top Countries',
                    roam: true,
                    type: 'map',
                    map: 'world',
                    emphasis: {
                        label: {
                            show: true
                        }
                    },
                    data: OriginData.map((bucket) => ({
                        value: bucket.doc_count,
                        name: bucket.key,
                    })
                    ),
                }
            ]
        };
        option && myChart.setOption(option);
        const resizeChart = () => {
            if (myChart) { // Check if chart exists before resizing
              myChart.resize();
            }
          };

        window.addEventListener('resize', resizeChart);
        return () => {
            myChart.dispose();
            window.removeEventListener('resize', resizeChart);
        };
    }, [OriginData,sidebarState,theme]);

return <> <div className="container">
{OriginData.length == 0 ?
    (<>
        <div id={id + 'threatorigin'} style={{ width: '1px', height: '1px' }}> </div>
        < Emptystatefornodata />
    </>)
    : (
        <div id={id + 'threatorigin'} style={{ width: '100%', height: '300px' }} > </div>
    )
}
</div>
</>
}

const mapStateToProps = (state) => ({
    dates: { startDate: state.startdate, endDate: state.enddate },
    filterformdata: state.FilterData,
    theme: state.theme,
    sidebarState:state.sidebarstate
    // mapStateToProps content remains unchanged if you have it
  });
  
  export default connect(
    mapStateToProps,
    null,
    null,
    { forwardRef: true }
  )(TopOrigins)