import React, { useEffect, useState } from 'react'
import dbApi from '../../services/DbApi';
import * as echarts from 'echarts';

import { generateUniqueID } from '../../util/UniqueIdGenerator';
import { connect } from "react-redux";
import { Loader } from '../../util/Loadingstate';
import { Emptystatefornodata } from '../../util/EmptyStateCard';
import { Card, Grid } from '@patternfly/react-core';
import SecurityHeatMap from './SecurityHeatMap';
const AgentHeatMap = (props) => {
    const [severity, setSeverity] = useState([]);
    const [isLoad, setIsLoad] = useState(true)
    const id = generateUniqueID()
    const getAgentSeverity = async (data) => {
        try {
            setIsLoad(true)
            let agentseverity = await dbApi.postApi('elasticsearch/Agent_Name_By_Severity', data)
            console.log(agentseverity);
            if (Object.keys(agentseverity.Agent_Name_By_Severity).length !== 0) {
                let agent_Severity = agentseverity.Agent_Name_By_Severity.Agent_Name;
                let severity = [];

                if (agent_Severity.length > 0) {
                    severity = agent_Severity
                }
                setSeverity(severity)
            }
            else {
                setSeverity([])
            }
            setIsLoad(false)
        }
        catch (error) {
            console.log(error)
            setIsLoad(false)
        }
    }
    const heatMapApi = async (startDate, endDate, allFields, IndexName) => {
        let staticfield = [{
            "fields": 'rule.groups',
            'condition': 'is_not',
            'value': 'zeek'
        }]
        const Fields = [...allFields, ...staticfield]
        const data = {
            startDate: startDate,
            endDate: endDate,
            filter: JSON.stringify(Fields),
            Index_Name: IndexName
        };
        try {
            await getAgentSeverity(data)
        }
        catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        if (props.dates.startDate != "") {
            let Dates = props.dates
            heatMapApi(Dates.startDate, Dates.endDate, props.filterformdata, props?.groupindex)
        }
    }, [props?.dates.startDate, props?.dates.endDate, props.filterformdata, props?.groupindex]);
    return (
        <Grid >
            <Card isRounded>
            {isLoad ? <Loader /> :
                severity.length !== 0 ?
                    <SecurityHeatMap severity={severity} />
                    : <Emptystatefornodata />}
                    </Card>
        </Grid>
    )
}
const mapStateToProps = (state) => ({
    dates: { startDate: state.startdate, endDate: state.enddate },
    filterformdata: state.FilterData,
    groupindex: state.groupIndexName
    // mapStateToProps content remains unchanged if you have it
});
export default connect(
    mapStateToProps,
    null,
    null,
    { forwardRef: true }
)(AgentHeatMap)
