import React, { useMemo, useState } from 'react'
import {
  ActionGroup,
  Button,
  Card,
  CardBody,
  ExpandableSection,
  Flex,
  FlexItem,
  Form,
  FormGroup,
  Grid,
  GridItem,
  Icon,
  TextInput
} from '@patternfly/react-core'
import { emptyArrayCheck, emptyCheck, selectmenuoption, selectmultipleoption } from 'src/util/Validate';
import CommonSelectBox from '../CommonEventPages/CommonSelectBox';
import CommonMultiSelectBox from '../CommonEventPages/CommonMultiSelectBox';
import ConfirmBox from '../ConfirmBox/ConfirmBox';
import { PlusCircleIcon, TrashIcon } from '@patternfly/react-icons';
import IndexInputGroup from './IndexInputGroup';

const AlertingRule = () => {
  const [titlename, setTitleName] = useState('');
  const [validtitlename, setValidtitlename] = useState('');
  const [selectedAgents, setSelectedAgents] = useState([]);
  const [multiselectedAgents, setmultiselectedAgents] = useState([])
  const [validAgents, setValidAgents] = useState('')
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [multiselectedEmails, setmultiselectedEmails] = useState([])
  const [validEmails, setValidEmails] = useState('')
  const [selectedPriority, setSelectedPriority] = useState([]);
  const [validPriority, setValidPriority] = useState('')
  const [expandedSections, setExpandedSections] = useState(Array(5).fill(true));
  const [actionType, setActionType] = useState('Add')
  const [confirmModalData, setConfirmBox] = useState({
    show: false,
    msg: '',
    funCall: '',
    funParam: {},
    title: '',
    position: '',
    iconvariant: '',
    submitbtnvariant: '',
  })
  //search rule list multiple rows states 
  const initialFormset = {
    fieldName: '',
    conditionName: '',
    valueInput: [],
    value: '',
    fieldNameValid: '',
    conditionNameValid: '',
    valueInputValid: '',
  }
  const [formData, setFormData] = useState([initialFormset]);
  const [Fields, setFields] = useState(
    [{ fieldid: 1, keyname: "Name" },
    { fieldid: 2, keyname: "Email" },
    { fieldid: 3, keyname: "Phone" },
    { fieldid: 4, keyname: "Address" }]);
  // const [fieldNameValid, setFieldNameValid] = useState([]);
  const [Conditions, setConditions] = useState([{ fieldid: 1, keyname: "is" },
  { fieldid: 2, keyname: "isnot" },
  { fieldid: 3, keyname: "isoneof" },
  { fieldid: 4, keyname: "isnotoneof" }]);
  // const [conditionNameValid, setConditionNameValid] = useState([]);

  const onToggle = (index) => {
    const newExpandedSections = [...expandedSections];
    newExpandedSections[index] = !newExpandedSections[index]; // Toggle the expanded state of the clicked section
    setExpandedSections(newExpandedSections);
  };
  const fieldChange = (value, type) => {
    switch (type) {
      case "titlename":
        setTitleName(value)
        setValidtitlename(emptyCheck(value))
        break;
    }
  };

  const handleMultiSelectAgents = (selected, type) => {
    console.log(selected)
    switch (type) {
      case "agents":
        setmultiselectedAgents(selected)
        setValidAgents(selectmultipleoption(selected))
        break;
      case "emails":
        setmultiselectedEmails(selected)
        setValidEmails(selectmultipleoption(selected))
        break;
    }
  };
  const updateAgentsBox = (value, type = 'valueselect', index = 'Value') => {
    setSelectedAgents(value)
    setValidAgents(selectmultipleoption(value))
  }
  const updateEmailBox = (value, type = 'valueselect', index = 'Value') => {
    setSelectedEmails(value)
    setValidEmails(selectmultipleoption(value))
  }

  const updatePriorityBox = (value, type = 'valueselect', index = 'Value') => {
    setSelectedPriority(value)
    setValidPriority(selectmenuoption(value))
  }

  function formValid(formData) {
    let  isValid = true;
    let status = emptyCheck(titlename)
    setValidtitlename(status)
    if (status == 'error') {
       isValid = false
    }
    let menuStatus = selectmultipleoption(selectedAgents)
    setValidAgents(menuStatus)
    if (menuStatus == 'danger') {
       isValid = false
    }
    menuStatus = selectmultipleoption(selectedEmails)
    setValidEmails(menuStatus)
    if (menuStatus == 'danger') {
       isValid = false
    }
    menuStatus = selectmenuoption(selectedPriority)
    setValidPriority(menuStatus)
    if (menuStatus == 'danger') {
       isValid = false
    }

    const newFormData = formData.map((item) => {

      const fieldNameValid = selectmenuoption(item.fieldName);
      const conditionNameValid = selectmenuoption(item.conditionName);
      const valueInputValid = emptyArrayCheck(item.valueInput)
      if (fieldNameValid == 'danger')  isValid = false;
      if (conditionNameValid == 'danger')  isValid = false;
      if (valueInputValid == 'error')  isValid = false;
      return {
        ...item,
        fieldNameValid,
        conditionNameValid,
        valueInputValid
      };
    });
    setFormData(newFormData);
    // return isValid;
    return  isValid
  }



  //Add Row
  const addNewRow = (rowIndex) => {
    setFormData([...formData, initialFormset])
  }

  //Remove Row
  const removeRow = (index) => {
    let newrow = [...formData]
    newrow.splice(index, 1)
    setFormData(newrow);
  }
  //Field select box
  const fieldBox = useMemo(() => {
    return (FieldName, rowIndex, validfield) => {
      // console.log("FieldName",FieldName)
      const updateFieldSelectBox = (value, type = 'fieldselect', index = 'field') => {
        const selectedField = Fields.find(group => group.fieldid == value);
        const updatedFormData = [...formData];
        updatedFormData[rowIndex].fieldName = value;
        updatedFormData[rowIndex].fieldNameValid = selectmenuoption(value);
        // if (selectedField) {
        //   updatedFormData[rowIndex].valueInput = selectedField.indexs;
        // }
        setFormData(updatedFormData);
      }
      const selectedGroupNames = formData.filter((obj, index) => index != rowIndex ? true : false).map((obj) => obj.fieldName);
      // console.log("selectedGroupNames",selectedGroupNames)
      return (<CommonSelectBox

        key={rowIndex + 'groupbox'}
        // options={Fields && Object.keys(Fields)
        //   .sort()
        //   .map(key => ({
        //     value: Fields[key]['fieldid'],
        //     children: Fields[key]['key']
        //   }))}
        options={Fields.map(key => ({
          value: key.fieldid,
          children: key.keyname
        }))}
        isLoading={false}
        placeholder="Select field"
        isDisabled={false}
        type="fields"
        indexVal={rowIndex}
        updateValue={updateFieldSelectBox}
        selectedOptions={FieldName}
        validStatus={validfield}
      />)
    }
  }, [Fields, formData, setFormData,])


  //Field select box
  const conditionBox = useMemo(() => {
    return (ConditionName, rowIndex, validcondition) => {
      const updateConditionBox = (value, type = 'fieldselect', index = 'field') => {
        const selectedcondition = Fields.find(group => group.fieldid == value);
        const updatedFormData = [...formData];
        updatedFormData[rowIndex].conditionName = value;
        updatedFormData[rowIndex].conditionNameValid = selectmenuoption(value);

        // if (selectedcondition) {
        //   updatedFormData[rowIndex].valueInput = selectedcondition.indexs;
        // }
        setFormData(updatedFormData);
      }
      // const selectedGroupNames = formData.filter((obj, index) => index != rowIndex ? true : false).map((obj) => obj.fieldName);
      // console.log("selectedGroupNames",selectedGroupNames)
      return (<CommonSelectBox

        key={rowIndex + 'conditionbox'}
        options={Conditions.map(key => ({
          value: key.fieldid,
          children: key.keyname
        }))}
        // options={Conditions && Object.keys(Conditions)
        //   .sort()
        //   .map(key => ({
        //     value: Conditions[key]['fieldid'],
        //     children: Conditions[key]['key']
        //   }))}
        // options={[{value:3,children:'ghj'}]}
        isLoading={false}
        placeholder="Select condition"
        isDisabled={false}
        type="conditions"
        indexVal={rowIndex}
        updateValue={updateConditionBox}
        selectedOptions={ConditionName}
        validStatus={validcondition}
      // validStatus={conditionNameValid[rowIndex]}
      />)
    }
  }, [Conditions, formData, setFormData])

  const finalSubmit = async () => {
    console.log('api call')
    // let data = {}
    // if (actionType == 'Add') {
    //   data = {
    //     name: organizationName,
    //     detail: orgDetail,
    //   }
    // }
    // else {
    //   props.updatemodalstate(false);
    //   data = {
    //     id: listRowId,
    //     name: organizationName,
    //   }
    // }
    // // console.log(data)
    // let apiurl = ''
    // if (actionType == 'Add') {
    //   apiurl = 'opensearch/add_customer_on_board'
    // }
    // else {
    //   apiurl = 'opensearch/update_customer_on_board'
    // }
    // console.log("Testing");
    // console.log(props);

    // const res = await dbApi.postApi(apiurl, data)
    // props.UpdateStatus()
    // let status = 'danger'
    // console.log(res)
    // if (!res.error) {
    //   status = 'success'
    //   if (actionType == "Add") {
    //     props.showSuccessModal(res.message)
    //     setActionType('Add')
    //   }
    //   else {
    //     dispatch(alertBoxData([{ title: res.message, variant: status }]));
    //     props.callUpdateList();
    //     setActionType('Add');
    //   }
    // }
    // else {
    //   dispatch(alertBoxData([{ title: res.message, variant: status }]));
    //   setActionType('Add')
    // }
  }
  const submitForm = () => {
    if (actionType == 'Add') {
      if (formValid(formData) ) {
        setConfirmBox({
          show: true,
          msg: 'Add Detail Form.',
          funCall: finalSubmit,
          title: 'Are you sure? ',
          position: 'default',
          iconvariant: 'info',
          submitbtnvariant: 'primary',
        })
      }
    }
    else {
      setConfirmBox({
        show: true,
        msg: `This edit cant't be reversed.`,
        funCall: finalSubmit,
        title: 'Are you sure? ',
        position: 'default',
        iconvariant: 'info',
        submitbtnvariant: 'primary',
      })
    }
  }
  const onCancelForm = () => {
    console.log('cancel')
  }

  return (
    <div>
      <ConfirmBox confirmModal={confirmModalData} />
      <Card>
        <CardBody>
          <ExpandableSection displaySize="lg" toggleText={'Detail Form'} onToggle={() => onToggle(0)} isExpanded={expandedSections[0]}>
            <Form >
              <Grid hasGutter md={4}>
                <GridItem span={12}>
                  <FormGroup fieldId="simple-form-name-01" label='Rule Name' isRequired>
                    <TextInput type="text" id="simple-form-name-01" name="simple-form-name-01" aria-describedby="simple-form-name-01-helper"
                      value={titlename} validated={validtitlename} onChange={(event, value) => fieldChange(value, 'titlename')} />
                  </FormGroup>
                </GridItem>
                <GridItem>
                  <FormGroup label="Agents" fieldId="simple-form-email-01" isRequired>
                    <CommonMultiSelectBox
                      initialOptions={[{ value: 'agent1', children: 'agent1' }, { value: 'agent2', children: 'agent2' }, { value: 'agent3', children: 'agent3' }]}
                      createOption={false}
                      onSelectChange={(selected) => handleMultiSelectAgents(selected, 'agents')}
                      isDisabled={false}
                      updateValue={updateAgentsBox}
                      placeholderText="Select Agents"
                      ariaLabel="Your aria label"
                      clearButtonAriaLabel="Clear input value"
                      selectedOptions={selectedAgents}
                      validStatus={validAgents}
                    />
                  </FormGroup>
                </GridItem>
                <GridItem>
                  <FormGroup label="Members Email" fieldId="basic-form-checkbox-group" isRequired>
                    <CommonMultiSelectBox
                      initialOptions={[{ value: 'email1', children: 'email1' }, { value: 'email2', children: 'email2' }, { value: 'email3', children: 'email3' }]}
                      createOption={false}
                      onSelectChange={(value) => handleMultiSelectAgents(value, 'emails')}
                      isDisabled={false}
                      updateValue={updateEmailBox}
                      placeholderText="Select Member Emails"
                      ariaLabel="Your aria label"
                      clearButtonAriaLabel="Clear input value"
                      selectedOptions={multiselectedEmails}
                      validStatus={validEmails}
                    />
                  </FormGroup>
                </GridItem>
                <GridItem>
                  <FormGroup fieldId="basic-form-radio-group" label="Priority" isRequired>
                    <CommonSelectBox
                      options={[{ value: 1, children: 'critical' }, { value: 2, children: 'high' }, { value: 3, children: 'medium' }]}
                      isLoading={false}
                      placeholder="Select priority"
                      isDisabled={false}
                      type="priority"
                      indexVal={''}
                      updateValue={updatePriorityBox}
                      selectedOptions={selectedPriority}
                      validStatus={validPriority}
                    />
                  </FormGroup>
                </GridItem>
                <GridItem span={12}>
                  <FormGroup label="Filter Criteria" fieldId="simple-form-phone-01" isRequired>
                    {/* <AlertingRuleList /> */}
                    {formData.map((row, rowIndex) => {
                      return (
                        <div className="pf-v5-u-p-sm" key={rowIndex}>
                          <Flex spaceItems={{ default: 'spaceItemsXs' }} display={{ default: 'inlineFlex' }} className='pf-v5-u-display-flex pf-v5-u-flex-nowrap' key={rowIndex + row.orgGroupId}>
                            <FlexItem key={1}>
                              {fieldBox(row.fieldName, rowIndex, row.fieldNameValid)}
                            </FlexItem>
                            <FlexItem key={2}>
                              {conditionBox(row.conditionName, rowIndex, row.conditionNameValid)}
                            </FlexItem>
                            <FlexItem key={3}>
                              <IndexInputGroup
                                placeholder="enter values"
                                currentChips={row.valueInput}
                                setCurrentChips={newValue => {
                                  const updatedFormData = [...formData];
                                  updatedFormData[rowIndex].valueInput = newValue;
                                  updatedFormData[rowIndex].valueInputValid = emptyArrayCheck(newValue);
                                  setFormData(updatedFormData);
                                }}
                                indexNameValid={row.valueInputValid}
                              />
                            </FlexItem>
                            {/* <FlexItem key={4}>
                              <TextInput isRequired placeholder="value" type="text" id="fieldvalue" name="fieldvalue" aria-describedby="fieldvalue-helper" value={row.fieldvalue ?? ''} validated={row.validFieldvalue} onChange={(event) => fieldChange(event, rowIndex)} />
                            </FlexItem> */}
                            {formData.length - 1 == rowIndex && <FlexItem key={5}><a><Icon status="info" onClick={() => { addNewRow(rowIndex); }}><PlusCircleIcon /></Icon></a></FlexItem>}
                            {formData.length !== 1 && <FlexItem key={6}><a>
                              <Icon status="danger" onClick={() => { removeRow(rowIndex) }}><TrashIcon /></Icon></a></FlexItem>}
                          </Flex>
                        </div>
                      )
                    })}
                  </FormGroup>
                </GridItem>
              </Grid>
              <ActionGroup>
                <Button variant="primary" onClick={() => submitForm()}>{actionType == 'Add' ? 'Submit' : 'Update'}</Button>
                <Button variant="link" onClick={() => onCancelForm()}>Cancel</Button>
              </ActionGroup>
            </Form>
          </ExpandableSection>
          <br />
          <ExpandableSection displaySize="lg" toggleText={'Rule List'} onToggle={() => onToggle(1)} isExpanded={expandedSections[1]}>
            Rule list table
          </ExpandableSection>
        </CardBody>
      </Card>


    </div>
  )
}

export default AlertingRule