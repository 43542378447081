import React, { useEffect } from 'react'
import * as echarts from 'echarts';
import { generateUniqueID } from '../../util/UniqueIdGenerator';
import { connect } from 'react-redux';
const RealTimeHeatMap = (props) => {
    const id = generateUniqueID()
    useEffect(() => {
        const chartDom = document.getElementById(id + 'hme');
        const myChart = echarts.init(chartDom);
        const option = {
            
            tooltip: {
                trigger: 'item',
                formatter: function (params) {
                    return params.data.name + `(${params.data.nestedkey})` + ' : ' + params.value;
                },

            },
            series: [
                {
                    textStyle: {
                        color: "#fff"
                    },
                    type: 'treemap',
                    roam: false,
                    squareRatio: 1.618033988749895,
                    visibleMin: 1,
                    top:'2%',
                    // bottom:'2%',
                    // right:'2%',
                    // left:'2%',
                    width: "100%",
                    height: "100%",
                    nodeClick: false,
                    sort: "true",
                    clipWindow: "origin",
                    animation: false,
                    itemStyle: {
                        borderWidth: 0,
                        gapWidth: 1
                    },
                    breadcrumb:
                        { show: false },
                    data: props.severity.map(bucket => {
                        let color;
                        let value = 0;
                        let nestedkey = '';
                        let itemStyleColor = '';
                        const docCount12AndAbove = bucket.Agent_ID[0].level_ranges.buckets.find(range => range.key === '12-and-above');
                        if (docCount12AndAbove && docCount12AndAbove.doc_count > 0) {
                            value = docCount12AndAbove.doc_count;
                            nestedkey = 'Critical';
                            itemStyleColor = '#A30000';
                        } else {
                            switch (bucket.Agent_ID[0].level_ranges.highest_doc_count_bucket.Status) {
                                case 'Critical':
                                    color = '#A30000';
                                    break;
                                case 'High':
                                    color = '#f0ab00';
                                    break;
                                case 'Low':
                                    color = '#3E8635';
                                    break;
                            }
                            value = bucket.Agent_ID[0].level_ranges.highest_doc_count_bucket.doc_count;
                            nestedkey = bucket.Agent_ID[0].level_ranges.highest_doc_count_bucket.Status;
                            itemStyleColor = color;
                        }
                        return {
                            value: value,
                            name: bucket.key,
                            nestedkey: nestedkey,
                            nestedkey1: bucket.Agent_ID[0].key,
                            itemStyle: {
                                color: itemStyleColor
                            }
                        };
                    })
                },
            ],
        };

        // Set chart options
        option && myChart.setOption(option);
        myChart.on('click', 'series.treemap', function (param) {
            const { name, nestedkey1 } = param.data;
            console.log('agent.name', name)
            console.log('agent.name', nestedkey1)

            window.location.href = "#/individual-agent-dashboard/" + param.data.name + '/' + param.data.nestedkey1;
        });
        const resizeChart = () => {
            if (myChart) { // Check if chart exists before resizing
              myChart.resize();
            }
          };

        window.addEventListener('resize', resizeChart);
        return () => {
            myChart.dispose();
            window.removeEventListener('resize', resizeChart);
        };
    }, [props.severity,props.sidebarState,props.theme]);
    return (
        <div  id={id + 'hme'} style={{ width: '100%', height: '100%' }}> </div>
        // </div>

    )
}
const mapStateToProps = (state) => ({
    filterformdata: state.FilterData,
    sidebarState:state.sidebarstate,
    theme: state.theme,
});
export default connect(
    mapStateToProps,
    null,
    null,
    { forwardRef: true }
)(RealTimeHeatMap)