import React, { useEffect, useState } from "react";
import {
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    Table,
    Caption,
} from "@patternfly/react-table";
import { Emptystatefornodata } from "../../util/EmptyStateCard";
import { Badge, Pagination, Text, Toolbar, ToolbarContent, ToolbarItem, Tooltip, Truncate } from "@patternfly/react-core";
import { connect, useDispatch } from "react-redux";
import { FilterData } from "../../Redux/Action";
import { UpdateFilterData } from "../../util/FilterHelper";

const PoCTableData = (props) => {
    const dispatch = useDispatch();
    const columns = props.columnData
    const [defaultRows, setDefaultRows] = useState([]);
    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);//props.vulPerPage??20
    const [selectedRowName, setSelectedRowName] = useState('');
    const [topics, setTopics] = useState([]);
    const handleSetPage = (_evt, newPage, perPage, startIdx, endIdx) => {
        // setPaginatedRows(rows.slice(startIdx, endIdx));
        setPage(newPage);
    };
    const handlePerPageSelect = (_evt, newPerPage, newPage) => {
        setPage(newPage);
        setPerPage(newPerPage);
    };
    const handleFilter = (fields, value) => {
        const updatedFields = UpdateFilterData(props.filterformdata, fields, 'is', value);
        dispatch(FilterData(updatedFields));
    }
    // .sort((a, b) => b.doc_count - a.doc_count)
    useEffect(() => {
        const newdefaultRows = props.Tabledata.map((log) => {
            return {
                pocurl: log?.html_url,
                pocdescription: log?.description,
                poctopics: log?.topics,
                // ProtoType:log.type,
                // nestedkey1: bucket.score
            };
        });
        setDefaultRows(newdefaultRows)
        const startIndex = (page - 1) * perPage;
        const endIndex = startIndex + perPage;
        const slicedRows = newdefaultRows.slice(startIndex, endIndex);
        // if (defaultRows.length !== rows.length) {
        //   const slicedRows = defaultRows;
        setRows(slicedRows);
        // }
    }, [props.Tabledata]);

    useEffect(() => {
        const startIndex = (page - 1) * perPage;
        const endIndex = startIndex + perPage;
        const slicedRows = defaultRows.slice(startIndex, endIndex);
        // console.log("after slicing", "", startIndex, endIndex, slicedRows)
        setRows(slicedRows);
    }, [defaultRows, page, perPage]);


    const renderPagination = () =>
        <Pagination
            itemCount={defaultRows.length}
            page={page}
            perPage={perPage}
            onSetPage={handleSetPage}
            onPerPageSelect={handlePerPageSelect}
        />;

    return (
        <React.Fragment>
            {props.Tabledata.length === 0 ? (
                <Emptystatefornodata />
            ) : (
                <>
                    <Table variant="compact" aria-label="IPlog" id="IPlog-table" isStickyHeader >
                        <Thead >
                            <Tr key={'cahrt-table-header'}>
                                {columns.map((column, index) => (
                                    <Th key={column + index}>{column}</Th>
                                ))}
                            </Tr>
                        </Thead>
                        {rows.length > 0 && (rows.map((row, rowIndex) => {

                            return (
                                <Tbody key={rowIndex + 'tablebody'}>
                                    <Tr key={rowIndex + 'row'}
                                        onRowClick={() => setSelectedRowName(rowIndex)}
                                        isSelectable
                                        isClickable
                                        isRowSelected={selectedRowName === rowIndex} >

                                        <Td dataLabel={'PoC URL'} width={10} key={'pocurl' + rowIndex}><a href={row?.pocurl} target="_blank" rel="noopener noreferrer">{row?.pocurl}</a></Td>
                                        <Td dataLabel={'Description'} width={10} key={'pocdesc' + rowIndex}>
                                            <div> {row?.pocdescription}</div>
                                            <div>{row?.poctopics.map((topic) => (
                                                <Badge className="pf-m-purple">{topic}</Badge>
                                            ))}
                                            </div>
                                        </Td>
                                    </Tr>
                                </Tbody>
                            )
                        }
                        ))}
                    </Table>
                    {props.Pagination !== false && <div className="pf-v5-u-mt-md">{renderPagination()}</div>}

                    <Text className="pf-v5-u-danger-color-100">*WARNING: These exploitable PoC is for R&D purposes only. Do not use it for malicious purposes.</Text>
                </>
            )}
        </React.Fragment>
    );
}
const mapStateToProps = (state) => ({
    dates: { startDate: state.startdate, endDate: state.enddate },
    filterformdata: state.FilterData,
    // mapStateToProps content remains unchanged if you have it
});

export default connect(
    mapStateToProps,
    null,
    null,
    { forwardRef: true }
)(PoCTableData)
