import * as echarts from 'echarts';
import React, { useEffect } from 'react';
import { generateUniqueID } from '../../util/UniqueIdGenerator';
import { formatValue } from '../../util/ChartHelper';
import { connect } from 'react-redux';
const TopVulnerableBranches = ({ GroupData, height, theme, sidebarState,ismodal }) => {
  const id = generateUniqueID()
  useEffect(() => {

    if (GroupData && GroupData.length > 0) {
      // console.log("GroupData",GroupData)
      const chartDom = document.getElementById(id + 'summary');
      const myChart = echarts.init(chartDom);
      const textcolor = theme == 'dark-theme' ? '#fff' : '#000'
      const linecolor = theme == 'dark-theme' ? '#595757' : '#d9d9d9'
      
      const yAxisData = GroupData.map(entry => ({ y: entry.group_name }));

      // const seriesData = {
      //   Critical: [],
      //   High: [],
      //   Low: [],
      //   Medium: []
      // };


      // GroupData.forEach(entry => {
      //   seriesData.Critical.push(entry.total_critical);
      //   seriesData.High.push(entry.total_high);
      //   seriesData.Low.push(entry.total_low);
      //   seriesData.Medium.push(entry.total_medium);
      // });

      const option = {
        tooltip: {
          trigger: 'axis',
          textStyle: {
            color: "#000"
          }
        },
        title: {
          text: 'Count',
          top: 'bottom',
          left: 'center',
          textStyle: {
            fontWeight: 450,
            fontSize: 14,
            color: textcolor,
            fontfamily: 'RedHatText, helvetica, arial, sans-serif',
          },
        },
        legend: {
          type: 'scroll',
          orient: 'vertical',
          right: 'right',
          top: 0,
          itemWidth: 9.5,
          itemHeight: 10,
          itemGap: 6,
          icon: "rect",
          textStyle: {
            // width: 50,
            overflow: 'truncate',
            color: textcolor
          },
          data: ['Critical', 'High', 'Medium', 'Low']
        },
        grid: {
          left: 0,
          right: '24%',
          bottom: '15%',
          top: 10,
          containLabel: true
        },
        xAxis: {
          type: 'value',
          splitLine: {
            lineStyle: {
              color: linecolor
            }
          },
          axisLabel: {
            formatter: formatValue,
            color: textcolor,
            rotate: -50
          },
          axisLine: {
            show: true
          },
        },
        yAxis: {
          type: 'category',
          inverse: true,
          axisLabel: {
            interval: 0,
            rotate: 20,
            width: 70,
            overflow: 'truncate',
            fontSize: 12,
            color: textcolor
          },
          boundaryGap: true,
          data: yAxisData.map(item => item.y),
          axisTick: {
            alignWithLabel: true
          },
        },
        series: [
          {
            name: 'Critical',
            type: 'bar',
            label: {
              show: ismodal?true:false
            },
            stack: 'total',
            barWidth:ismodal?30:10,
            emphasis: {
              focus: 'series',
            },
            itemStyle: {
              color: '#A30000', // Red for active, Green for solved
            },
            data: GroupData.map(item => item.critical_total)
          },
          {
            name: 'High',
            type: 'bar',
            label: {
              show: ismodal?true:false
            },
            stack: 'total',
            barWidth: ismodal?30:10,
            emphasis: {
              focus: 'series',
            },
            itemStyle: {
              color: '#C9190B', // Red for active, Green for solved
            },
            data: GroupData.map(item => item.high_total)
          },
          {
            name: 'Medium',
            type: 'bar',
            label: {
              show: ismodal?true:false
            },
            stack: 'total',
            barWidth: ismodal?30:10,
            emphasis: {
              focus: 'series',
            },
            itemStyle: {
              color: '#f0ab00', // Red for active, Green for solved
            },
            data: GroupData.map(item => item.medium_total)
          }, {
            name: 'Low',
            type: 'bar',
            label: {
              show: ismodal?true:false
            },
            stack: 'total',
            barWidth: ismodal?30:10,
            emphasis: {
              focus: 'series',
            },
            itemStyle: {
              color: '#2B9AF3', // Red for active, Green for solved
            },
            data: GroupData.map(item => item.low_total)
          },
        ]
      };
      option && myChart.setOption(option);
      const resizeChart = () => {
        if (myChart) { // Check if chart exists before resizing
          myChart.resize();
        }
      };

      window.addEventListener('resize', resizeChart);
      return () => {
        myChart.dispose();
        window.removeEventListener('resize', resizeChart);
      };

    }
  }, [GroupData, height, theme, sidebarState,ismodal])
  return <div className="container">
    <div id={id + 'summary'} style={{ width: '100%', height: height }} ></div>
  </div>;
}

const mapStateToProps = (state) => ({
  dates: { startDate: state.startdate, endDate: state.enddate },
  filterformdata: state.FilterData,
  theme: state.theme,
  sidebarState: state.sidebarstate
  // mapStateToProps content remains unchanged if you have it
});

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(TopVulnerableBranches)