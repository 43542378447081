import { Icon, List, ListItem, ListVariant, Modal, ModalVariant, Pagination, TextArea, TextInput, Toolbar, ToolbarContent, ToolbarItem } from '@patternfly/react-core'
import { ExclamationCircleIcon, PlusCircleIcon, TrendDownIcon, TrendUpIcon } from '@patternfly/react-icons'
import { ActionsColumn, Table, Tbody, Td, Th, Thead, Tr } from '@patternfly/react-table'
import React, { useEffect, useState } from 'react'
import dbApi from '../../services/DbApi'
import { formatTableTimestamp } from '../../util/Timeutils'
import { connect, useDispatch } from 'react-redux'

const RankListTable = (props) => {
    const dispatch = useDispatch()
    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(1);
    const [totalItem, setTotalItem] = useState(0)
    const [selectedRowName, setSelectedRowName] = useState('');
    const [perPage, setPerPage] = useState(10);
    const ranklistcolumn = [
        "Group Name",
        "Agent Name",
        "Rank",
        "Trend",
    ];
    const getRiskRank = async () => {
        let data = {
            limitStart:(perPage * page) - perPage,
            limitLength:perPage,
            search:'',
            sortBy:'id',
            sortOrder:'desc',
            watchListId:''
        }
        try {
            let res = await dbApi.postApi("Opensearch/agent_risk_list", data)
            console.log(res)
            let rankdata = []
            if (!res.error) {
                rankdata = res.data.data
                setRows(rankdata)
                setTotalItem(res.data.totalRecord)
            }
            else {
                setRows(rankdata)
                setTotalItem(0)
            }
        } catch (error) {
            console.log(error)
        }
    }
    // Pagination logic
    const handleSetPage = (_evt, newPage) => {
        setPage(newPage);
    };
    const handlePerPageSelect = (_evt, newPerPage) => {
        setPerPage(newPerPage);
    };

    const renderPagination = (variant, isCompact) => (
        <Pagination
            isCompact={isCompact}
            itemCount={totalItem}
            page={page}
            perPage={perPage}
            onSetPage={handleSetPage}
            onPerPageSelect={handlePerPageSelect}
            variant={variant}
            titles={{
                paginationAriaLabel: `${variant} pagination`
            }}
        />
    );

    useEffect(() => {
        getRiskRank()
    }, [page, perPage])
    return (
        <>
            <Toolbar>
                <ToolbarContent >
                    <ToolbarItem align={{ default: 'alignRight' }}>
                        <ToolbarItem> {renderPagination('top', false)}</ToolbarItem>
                    </ToolbarItem>
                </ToolbarContent>
            </Toolbar>
            <Table aria-label="Sortable Table"
                variant='compact'
                isStickyHeader
                gridBreakPoint="grid-md">
                <Thead>
                    <Tr>
                        {ranklistcolumn.map((column, index) => (
                            <Th key={column + index}>{column}</Th>
                        ))}
                    </Tr>
                </Thead>
                {rows.length > 0 && (rows.map((row, rowIndex) => {

                    return (
                        <Tbody key={rowIndex}>

                            <Tr key={rowIndex}
                                onRowClick={() => setSelectedRowName(rowIndex)}
                                isSelectable
                                isClickable
                                isRowSelected={selectedRowName === rowIndex}
                                className={row?.agent_name === props.AgentName ? "pf-v5-u-background-color-danger" : ""}
                            >
                                <Td width={10} dataLabel={ranklistcolumn[0]}>{row?.group_name} </Td>
                                <Td width={40} dataLabel={ranklistcolumn[1]}>{row?.agent_name} </Td>
                                <Td width={30} dataLabel={ranklistcolumn[2]}>{row?.rank}</Td>
                                <Td width={20} dataLabel={ranklistcolumn[3]}>{row?.difference > 0 ? <Icon status="danger"><TrendUpIcon /></Icon> : <Icon status="success"><TrendDownIcon /></Icon>}</Td>

                            </Tr>
                        </Tbody>
                    )
                }
                ))}
            </Table>
            {renderPagination('bottom', false)}
        </>
    )
}

const mapStateToProps = (state) => ({
    groups: state.Allgroups
})
export default connect(mapStateToProps)(RankListTable) 