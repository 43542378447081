import { Card, CardBody, CardFooter, CardTitle, Grid, GridItem, PageSection } from '@patternfly/react-core'
import React, { useState } from 'react';
import { UtilizationCard3 } from './cardheadereg'
import InteractiveLegendChart from '../SecurityEvents/ExampleAlertEvolution'
import Todo from './Echart'
import EventBar from '../CommonEventPages/EventBar'
import CommonSelectBox from '../CommonEventPages/CommonSelectBox'
import AddFilterForm from '../CommonEventPages/AddFilterForm';
import CommonMultiSelectBox from '../CommonEventPages/CommonMultiSelectBox';
import MainLogin from '../Login/MainLogin';
import UserProfile from '../UserManagement/UserProfile';
import { LineChart } from 'echarts/charts';



const Testing = () => {
    // const [selectedOption, setSelectedOption] = useState('');

    const initialOptions = [
        { value: 'Option 1', children: 'Option 1' },
        { value: 'Option 2', children: 'Option 2' },
        { value: 'option3', children: 'Option 3' },
        { value: 'option4', children: 'Option 4' },
        { value: 'option5', children: 'Option 5' },
        { value: 'option6', children: 'Option 6' },
        { value: 'option7', children: 'Option 7' },
        { value: 'option8', children: 'Option 8' },
        { value: 'option9', children: 'Option 9' },
        { value: 'option10', children: 'Option 10' },
        { value: 'option11', children: 'Option 11' },
        { value: 'option12', children: 'Option 12' },
        { value: 'option13', children: 'Option 13' },

    ];

    const [selectedOptions, setSelectedOptions] = useState([]);

    const handleSelectChange = (selected) => {
        setSelectedOptions(selected); // Handle changes in selected options
    };
    // const handleSelect = value => {
    //     setSelectedOption(value);
    // };
    return (<>
        <PageSection>
            {/* <div className='pf-v5-u-m-sm'>
                <EventBar />
            </div>
            <div className='pf-v5-u-m-sm'>
                <Grid hasGutter>
                    <GridItem md={12} sm={12} lg={4}>
                        <Card isFlat isFullHeight>
                            <UtilizationCard3 />
                        </Card>
                    </GridItem>
                    <GridItem md={12} sm={12} lg={4} >
                        <Card isFlat isFullHeight>
                            <InteractiveLegendChart />
                        </Card>
                    </GridItem>
                </Grid>
            </div>
            <div className='pf-v5-u-m-sm'>
                <Grid>
                    <GridItem md={12} sm={12} lg={4} >
                        <Card isFlat>
                            <CardTitle> e chart</CardTitle>
                            <CardBody> <Todo /></CardBody>
                        </Card>
                    </GridItem>
                    <GridItem md={12} sm={12} lg={4} >
                        <Card isFlat>
                            <CardTitle>seelect box</CardTitle>
                            <CardBody>
                                <CommonSelectBox
                                    options={[
                                        { value: 'option1', children: 'Option 1' },
                                        { value: 'option2', children: 'Option 2' },
                                        { value: 'option3', children: 'Option 3' },
                                        { value: 'option4', children: 'Option 4' },
                                        { value: 'option5', children: 'Option 5' },
                                        { value: 'option6', children: 'Option 6' },
                                        { value: 'option7', children: 'Option 7' },
                                        { value: 'option8', children: 'Option 8' },
                                        { value: 'option9', children: 'Option 9' },
                                        { value: 'option10', children: 'Option 10' },
                                        // ... other options
                                    ]}
                                    // onSelect={handleSelect} // Pass your select handler function
                                    // selectedValue={selectedOption} // Pass the selected value
                                    placeholder="Select an option"

                                />
                            </CardBody>
                            <CardFooter>
                                <AddFilterForm />
                            </CardFooter>
                        </Card>
                    </GridItem>
                    <GridItem md={12} sm={12} lg={4} >
                        <Card isFlat>
                            <CardTitle>seelect box</CardTitle>
                            <CardBody>
                                <CommonMultiSelectBox
                                    initialOptions={initialOptions}
                                    onSelectChange={handleSelectChange}
                                    placeholderText="Select an option"
                                    ariaLabel="Your aria label"
                                    clearButtonAriaLabel="Clear input value"
                                />

                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem>
                        <Dummy />
                    </GridItem>
                    <br/>
                    <br/>
                    <GridItem>
                      <Card>  <MainLogin/></Card>
                    </GridItem>
                </Grid>
            </div> */}
            <div>
                <UserProfile/>
                {/* <LineChart/> */}
            </div>

        </PageSection>
    </>
    )
}

export default Testing