import {
    Card,
    CardBody,
    CardTitle,
    Divider,
    Grid,
    GridItem,
    SearchInput
} from '@patternfly/react-core'
import React, { memo, useContext, useEffect, useMemo, useState } from 'react'
import dbApi from 'src/services/DbApi'
import { Table, Tbody, Td, Th, Thead, Tr } from '@patternfly/react-table'
import { TextSkeleton } from 'src/util/Loadingstate'
import { formatTableTimestamp } from 'src/util/Timeutils'
import { AgentContext } from './AgentHome'
import NetworkInterface from './InventoryTables/NetworkInterfaceTable'
import NetworkPort from './InventoryTables/NetworkPortTable'
import NetworkSettings from './InventoryTables/NetworkSettingTable'
import WindowsUpdate from './InventoryTables/WindowUpdateTable'
import Packages from './InventoryTables/PackagesTable'
import Processes from './InventoryTables/ProcessesTable'

const Inventorydata = (props) => {

    const AgentID = useContext(AgentContext)
    const [inventorydetail, setInventoryDetail] = useState([])

    //Loading State
    const [inventoryload, setInventorLoad] = useState(false)


    const getInventoryDetails = async (data) => {
        try {
            setInventorLoad(true)
            let res = await dbApi.postApi("Opensearch/Hardware_OS", data)
            console.log(res)
            let InventoryDetails = []
            if (res.Hardware_OS.length > 0) {
                InventoryDetails = res.Hardware_OS
                setInventoryDetail(InventoryDetails)
            }
            else {
                setInventoryDetail(InventoryDetails)
            }
            setInventorLoad(false)

        } catch (error) {
            console.log(error)
            setInventorLoad(false)
        }
    }
    useEffect(() => {
        let data = {
            agentId: AgentID
        }
        getInventoryDetails(data)
    }, [AgentID])







    const InventoryDetails = useMemo(() => {
        const Inventorycolumn = ['Cores', 'Memory', 'Architecture', 'Operating System', 'CPU', 'Host Name', 'Board Serial', 'Last Scan']
        const memory = inventorydetail[0]?.ram.total / 1024
        const { formattedTimeStamp: formattedLastScan } = inventorydetail[1]?.scan.time ? formatTableTimestamp(inventorydetail[1]?.scan.time) : { formattedTimeStamp: null }
        return (
            inventoryload ? <TextSkeleton /> :
                <Table id="detail-log" variant='compact'>
                    <Thead>
                        <Tr key={'header-row'}>
                            {Inventorycolumn.map((column, index) => (
                                <Th key={column + index}>{column}</Th>
                            ))}
                        </Tr>
                    </Thead>
                    <Tbody key={'invdetail'}>
                        <Tr>
                            <Td key={'cores'} width={10} dataLabel={Inventorycolumn[0]}> {inventorydetail[0]?.cpu.cores}</Td>
                            <Td key={'memory'} width={10} dataLabel={Inventorycolumn[1]}>{memory.toFixed(2)} MB</Td>
                            <Td key={'arch'} width={10} dataLabel={Inventorycolumn[2]}>{inventorydetail[1]?.architecture}</Td>
                            <Td key={'osname'} width={20} dataLabel={Inventorycolumn[3]}>{inventorydetail[1]?.os.name} {inventorydetail[1]?.os.version}</Td>
                            <Td key={'cpu'} width={20} dataLabel={Inventorycolumn[4]}>{inventorydetail[0]?.cpu.name} </Td>
                            <Td key={'hname'} width={10} dataLabel={Inventorycolumn[5]} modifier='truncate'>{inventorydetail[1]?.hostname}</Td>
                            <Td key={'boardser'} width={10} dataLabel={Inventorycolumn[6]}>{inventorydetail[0]?.board_serial !== undefined && inventorydetail[0]?.board_serial !== null ? inventorydetail[0]?.board_serial : ''}</Td>
                            <Td key={'lastscan'} width={10} dataLabel={Inventorycolumn[7]}> {formattedLastScan}</Td>
                        </Tr>
                    </Tbody>

                </Table>
        )
    }, [inventoryload, inventorydetail])

    return (
        <>
            <Card className="pf-v5-u-mt-sm" isFlat>
                {InventoryDetails}
            </Card>
            <Grid hasGutter className="pf-v5-u-mt-sm">
                <GridItem md={12} lg={6} sm={12}>
                    <Card isFullHeight isFlat >
                        <NetworkInterface agentId={AgentID} />
                    </Card>
                </GridItem>
                <GridItem md={12} lg={6} sm={12}>
                    <Card isFullHeight isFlat >
                        <NetworkPort agentId={AgentID} />
                    </Card>
                </GridItem>
            </Grid>
            <Grid hasGutter className="pf-v5-u-mt-sm">
                <GridItem md={12} lg={9} sm={12}>
                    <Card isFullHeight isFlat >
                        <NetworkSettings agentId={AgentID} />
                    </Card>
                </GridItem>
                <GridItem md={12} lg={3} sm={12}>
                    <Card isFullHeight isFlat >
                        <WindowsUpdate agentId={AgentID} />
                    </Card>
                </GridItem>
            </Grid>
            <Grid hasGutter className="pf-v5-u-mt-sm">
                <GridItem md={12} lg={12} sm={12}>
                    <Card isFullHeight isFlat >
                        <Packages agentId={AgentID} />
                    </Card>
                </GridItem>
            </Grid>
            <Grid hasGutter className="pf-v5-u-mt-sm">
                <GridItem md={12} lg={12} sm={12}>
                    <Card isFullHeight isFlat >
                        <Processes agentId={AgentID} />
                    </Card>
                </GridItem>
            </Grid>
        </>
    )
}

export default Inventorydata