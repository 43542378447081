import { FieldData_EventBar, ALERT_BOX, REMOVE_ALERT } from '../Constant/constants';
const initialState = {
  FieldData: [],
  loading: false,
  startdate: '',
  enddate: '',
  CurrentDateTime: '',
  Term: '',
  SearchText: '',
  alert: [],
  FilterData: [],
  IndFilter: [],
  Allgroups: [],
  MappingKeys: [],
  SelectedGroup: '',
  Headergroupagent:'',
  globalIntervalDuration: '',
  ZeekAlerts: '',
  theme:'',
  sidebarstate:'',
  filterrulelevel:''
};

export const changeState = (state = initialState, action) => {
  switch (action.type) {
    case FieldData_EventBar:
      return {
        ...state,
        FieldData: action.data
      }
      break;
    case 'Mapping_keys':
      return {
        ...state,
        MappingKeys: action.data
      }
      break;
    case ALERT_BOX:
      action.data[0].key = new Date().getTime()
      return {
        ...state,
        alert: [...state.alert, ...action.data]
      };
      break;
    case REMOVE_ALERT:
      const key = state.alert.findIndex(obj => obj.key == action.data)
      if (key > -1) {
        return {
          ...state,
          alert: [...state.alert.slice(0, key), ...state.alert.slice(key + 1)]
        };
      } else {
        return state;
      }
      break;
    case 'All_Groups_List':
      return {
        ...state,
        Allgroups: action.data
      };
      break;
    case 'START_LOADING':
      return {
        ...state,
        loading: action.data.show
      };
      break;
      case 'Filter_rule_level':
      return {
        ...state,
        filterrulelevel: action.data
      };
      break;
    case 'Start_End_Date':
      return {
        ...state,
        startdate: action.data.StartDate,
        enddate: action.data.EndDate,
        Term: action.data.Term,
        // CurrentDateTime:action.data.currentDateTime
      };
      break;
    case 'Form_Filter':
      return {
        ...state,
        FilterData: action.data,
      };
      break;
    case 'Search_Text':
      return {
        ...state,
        SearchText: action.data,
      }; break;
      case 'Sidebar_state':
      return {
        ...state,
        sidebarstate: action.data,
      }; break;
    case 'Inc_NW_Eve':
      return {
        ...state,
        ZeekAlerts: action.data,
      }; break;
    case 'Global_Interval':
      return {
        ...state,
        globalIntervalDuration: action.data
      };
      break;
    case 'Selected_Group':
      return {
        ...state,
        SelectedGroup: action.data,
      };
      break;
      case 'Header_Group_Agent':
        return {
          ...state,
          Headergroupagent: action.data,
        };
        break;
    case 'Used_Theme':
      return {
        ...state,
        theme: action.data
      };
      break;
    default:
      return state;
      break;
  }
};
