import * as echarts from 'echarts';
import React, { useEffect } from 'react';
import { generateUniqueID } from 'src/util/UniqueIdGenerator';
import { connect } from 'react-redux';
const BenchmarkScore = ({ score, height,theme,sidebarState }) => {
  const id = generateUniqueID()
  useEffect(() => {
    const chartDom = document.getElementById(id + 'gauge');
    const myChart = echarts.init(chartDom);
    const textcolor = theme == 'dark-theme' ? '#fff' : '#000'
    const gaugeColor = score > 75 ? '#3E8635' : '#F0AB00';
    const option = {
      series: [
        {
          type: 'gauge',
          startAngle: 180,
          endAngle: 0,
          min: 0,
          max: 100,
          splitNumber: 2,
          progress: {
            show: true,
            width: 8
          },
          itemStyle: {
            color: gaugeColor,
          },
          axisLine: {
            lineStyle: {
              width: 3,
            }
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            length: 3,
            lineStyle: {
              color: "#999",
              width: 2
            }
          },
          axisLabel: {
            distance: 7,
            color: "#999",
            fontSize: 8
          },
          anchor: {
            show: true,
            // showAbove: true,
            size: 2,
            itemStyle: {
              borderWidth: 10
            }
          },
          title: {
            show: false
          },
          detail: {
            valueAnimation: true,
            fontSize: 16,
            color:textcolor,
            offsetCenter: [-1, '70%'],
            formatter: '{value} %',
          },
          data: [
            {
              value: score
            }

          ]
        }
      ]
    };

    option && myChart.setOption(option);
    const resizeChart = () => {
      if (myChart) { // Check if chart exists before resizing
        myChart.resize();
      }
    };

  window.addEventListener('resize', resizeChart);
  return () => {
      myChart.dispose();
      window.removeEventListener('resize', resizeChart);
  };
  }, [score, height,theme,sidebarState])
  return <div className="container">
    <div id={id + 'gauge'} style={{ width: '100%', height: height, marginTop: '-20px' }} >
    </div>
  </div>;
}
const mapStateToProps = (state) => ({
  dates: { startDate: state.startdate, endDate: state.enddate },
  filterformdata: state.FilterData,
  theme: state.theme,
  sidebarState:state.sidebarstate
  // mapStateToProps content remains unchanged if you have it
});

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(BenchmarkScore)
