import React, { useEffect, useState } from "react";
import {
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Table,
} from "@patternfly/react-table";
import { Emptystatefornodata } from "../../util/EmptyStateCard";
import {Pagination } from "@patternfly/react-core";
import { connect, useDispatch } from "react-redux";
import { FilterData } from "../../Redux/Action";
import { UpdateFilterData } from "../../util/FilterHelper";
import MarkdownIt from 'markdown-it'

const MiteIntelligenceTable = (props) => {
  const dispatch = useDispatch();
  // const markdown = new MarkdownIt({ html:true,linkify: true,typographer: true,highlight:true});
  const md = new MarkdownIt({ html:true,linkify: true,typographer: true,highlight:true})
  const columns=['ID', 'Name', 'Description']
  const [defaultRows, setDefaultRows] = useState([]);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [selectedRowName, setSelectedRowName] = useState('');

  const handleSetPage = (_evt, newPage) => {
    setPage(newPage);
  };
  const handlePerPageSelect = (_evt, newPerPage, newPage) => {
    setPage(newPage);
    setPerPage(newPerPage);
  };
  const handleFilter = (fields, value) => {
    const updatedFields = UpdateFilterData(props.filterformdata, fields, 'is', value);
    dispatch(FilterData(updatedFields));
  }

  useEffect(() => {
    const newdefaultRows = props.Tabledata.map((log) => {
      return {

        details: log,
      };
    });
    setDefaultRows(newdefaultRows)
    const startIndex = (page - 1) * perPage;
    const endIndex = startIndex + perPage;
    const slicedRows = newdefaultRows.slice(startIndex, endIndex);
    setRows(slicedRows);
  }, [props.Tabledata]);

  useEffect(() => {
    const startIndex = (page - 1) * perPage;
    const endIndex = startIndex + perPage;
    const slicedRows = defaultRows.slice(startIndex, endIndex);
    setRows(slicedRows);
  }, [defaultRows, page, perPage]);

  const renderPagination = () =>
    <Pagination
      itemCount={defaultRows.length}
      page={page}
      perPage={perPage}
      onSetPage={handleSetPage}
      onPerPageSelect={handlePerPageSelect}
  
    />;

  return (
    <React.Fragment>
      {props.Tabledata.length === 0 ? (
        <Emptystatefornodata />
      ) : (
        <>
          {/* <Toolbar>
            <ToolbarContent>
              <ToolbarItem variant="pagination">{renderPagination()}</ToolbarItem>
            </ToolbarContent>
          </Toolbar> */}
          <Table variant="compact" aria-label="IPlog" id="IPlog-table" isStickyHeader>
            <Thead >
              <Tr>
                {columns.map((column) => (
                  <Th key={column}>{column}</Th>
                ))}
              </Tr>
            </Thead>
            {rows.length > 0 && (rows.map((row, rowIndex) => {
              return (
                <Tbody key={rowIndex}>
                  <Tr key={rowIndex}
                    onRowClick={() => { setSelectedRowName(rowIndex); props.onIDselect(row) }}
                    isSelectable
                    isClickable
                    isRowSelected={selectedRowName === rowIndex}>
                    <Td width={10} dataLabel="ID"><a onClick={() => props.onIDselect(row)}>{row?.details?.external_id}</a></Td>
                    <Td width={10} dataLabel="Name"><a onClick={() => props.onIDselect(row)}>{row?.details?.name}</a></Td>
                    <Td width={80} dataLabel="Description"  dangerouslySetInnerHTML={{__html: md.render(String(row?.details?.description || ''))}}></Td>
                    {/* <Td width={80} dataLabel="Description">{row?.details?.description} </Td> */}
                  </Tr>
                </Tbody>
              )
            }
            ))}
          </Table>
          <div className="pf-v5-u-mt-sm">{renderPagination()}</div>
        </>
      )}
    </React.Fragment >
  );
}
const mapStateToProps = (state) => ({
  dates: { startDate: state.startdate, endDate: state.enddate },
  filterformdata: state.FilterData,
  // mapStateToProps content remains unchanged if you have it
});

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(MiteIntelligenceTable)