class TokenService {
  getLocalRefreshToken() {
    return localStorage.getItem("refreshToken")
  }
  updateLocalToken(token) {
    localStorage.setItem('token', token);
  }
  getUserRole() {
    return localStorage.getItem("role")!=null ? localStorage.getItem("role") : ""
  }
  getUserName() {
    return localStorage.getItem("name")!=null ? localStorage.getItem("name") : ""
  }
  getLocalToken() {
    return localStorage.getItem("token")
  }
  getUserId() {
    return localStorage.getItem("userId")
  }
  getUserTimezone() {
    return localStorage.getItem("timezone")
  }
  setToken(data) {
    localStorage.setItem('token','');
  }  
  setUser(data) {
    console.log("data",data)
    localStorage.setItem('userId',data.userId);
    localStorage.setItem('name',data.name);
    localStorage.setItem('role',data.role);
    localStorage.setItem('timezone',data.timezone);
    localStorage.setItem('token', data.access_token);
    localStorage.setItem('refreshToken', data.refresh_token);
  }
  removeUser() {
    localStorage.clear();
  }
}
export default new TokenService();
