import { Bullseye, EmptyState, EmptyStateBody, EmptyStateHeader, EmptyStateIcon } from "@patternfly/react-core"
import { SearchIcon } from "@patternfly/react-icons"

export const Emptystatefornodata=()=>{
  
   return(<Bullseye>
    <EmptyState>
      <EmptyStateHeader
        titleText="No Data"
        icon={<EmptyStateIcon icon={SearchIcon} />}
        headingLevel="h2"
      />
      <EmptyStateBody>
        Please select another time range Or clear filters.
      </EmptyStateBody>
    </EmptyState>
  </Bullseye>)
  }

  export const EmptystateforIncident=()=>{
  
    return(<Bullseye>
     <EmptyState>
       <EmptyStateHeader
         titleText="No Data"
         icon={<EmptyStateIcon icon={SearchIcon} />}
         headingLevel="h2"
       />
       <EmptyStateBody>
       No results match the filter criteria. Clear all filters and try again.
       </EmptyStateBody>
     </EmptyState>
   </Bullseye>)
   }

//   import { Bullseye, EmptyState, EmptyStateBody, EmptyStateHeader, EmptyStateIcon } from "@patternfly/react-core"
// import { SearchIcon } from "@patternfly/react-icons"
// import { useSelector } from "react-redux"

// export function emptystatefornodata() {
//   const isEmpty = useSelector((state) => state.emptystate)
//   return isEmpty ?
//     <Bullseye>
//       <EmptyState>
//         <EmptyStateHeader
//           titleText="No Data"
//           icon={<EmptyStateIcon icon={SearchIcon} />}
//           headingLevel="h2"
//         />
//         <EmptyStateBody>
//           Please select another time range.
//         </EmptyStateBody>
//       </EmptyState>

//     </Bullseye> : null;
// }