import * as React from 'react';
import {Grid, GridItem,Split, SplitItem  } from '@patternfly/react-core';



export const Landing = () => {

  return <React.Fragment>
     {/* <Grid sm={6} md={4} lg={3} xl2={1}>
    <GridItem span={3} rowSpan={2}>
      span = 3 rowSpan= 2
    </GridItem>
    <GridItem>Grid Item</GridItem>
  </Grid> */}
<Split hasGutter>
    <SplitItem>content</SplitItem>
    <SplitItem isFilled>pf-m-fill</SplitItem>
    <SplitItem>content</SplitItem>
  </Split>
    </React.Fragment>;
};


export default Landing