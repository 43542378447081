import React, { useEffect, useState } from "react";
import {
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    Table,
    InnerScrollContainer,
} from "@patternfly/react-table";
import {
    Badge,
    Button,
    Divider,
    Icon,
    Pagination,
    SearchInput,
    Text,
    TextVariants,
    Toolbar,
    ToolbarContent,
    ToolbarItem,
    Tooltip,
    Truncate
} from "@patternfly/react-core";
import { connect, } from "react-redux";
import { RedoIcon, } from '@patternfly/react-icons';
import dbApi from 'src/services/DbApi'
import { Loader } from "src/util/Loadingstate";
import { Emptystatefornodata } from "src/util/EmptyStateCard";

const WindowsUpdate = (props) => {
    const columns = {
        hotfix: 'Update Code'
    }
    const [windowload, setWindowLoad] = useState(false)
    const [rows, setRows] = useState([]);
    const [activeSortIndex, setActiveSortIndex] = useState(0);
    const [activeSortDirection, setActiveSortDirection] = useState('asc');
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10)
    const [selectedRowName, setSelectedRowName] = useState('');
    const [searchValue, setSearchValue] = useState('')
    const [inputValue, setInputValue] = useState('')
    const [totalRecord, setTotalRecord] = useState(0)

    const getWindowUpdates = async () => {
        let sort = ''
        if (activeSortDirection === 'asc') {
            sort = `+${Object.keys(columns)[activeSortIndex]}`
        }
        else {
            sort = `-${Object.keys(columns)[activeSortIndex]}`
        }
        let data = {
            agentId: props.agentId,
            offset: (perPage * page) - perPage,
            limit: perPage,
            search: searchValue,
            sort: sort,
        }
        try {
            setWindowLoad(true)
            let res = await dbApi.postApi("Opensearch/Window_Update", data)
            console.log(res)
            let WindowUpdate = []
            let totalcount = 0
            if (Object.keys(res.Window_Update).length > 0) {
                let log = res.Window_Update.data
                WindowUpdate = log.affected_items
                totalcount = log.total_affected_items
                setRows(WindowUpdate)
                setTotalRecord(totalcount)
            }
            else {
                setRows(WindowUpdate)
                setTotalRecord(totalcount)
            }
            setWindowLoad(false)
        } catch (error) {
            console.log(error)
            setWindowLoad(false)
        }
    }

    const onSearch = event => {
        if (event.key && event.key !== 'Enter') {
            return;
        }
        setSearchValue(inputValue)
    }

    useEffect(() => {
        getWindowUpdates()
    }, [props.agentId, page, perPage, activeSortDirection, activeSortIndex, searchValue])


    //sorting
    const onSort = (event, index, direction) => {
        setActiveSortIndex(index);
        setActiveSortDirection(direction);
    };

    //Pagination
    const handleSetPage = (_evt, newPage) => {
        setPage(newPage);
    };
    const handlePerPageSelect = (_evt, newPerPage,newPage) => {
        setPerPage(newPerPage);
        setPage(newPage);
    };
    const renderPagination = (variant) =>
        <Pagination
            itemCount={totalRecord}
            page={page}
            perPage={perPage}
            onSetPage={handleSetPage}
            onPerPageSelect={handlePerPageSelect}
            variant={variant}
        />;
    const toolbarItems = <Toolbar id="sortable-toolbar">
        <ToolbarContent>
            <ToolbarItem>
                <Text component={TextVariants.h1} className='pf-v5-u-font-weight-bold'>
                    Windows Updates ({totalRecord})
                </Text>
            </ToolbarItem>
            <ToolbarItem align={{ default: 'alignRight' }}>
                <Button variant="secondary" size="sm" icon={<RedoIcon />} onClick={() => {getWindowUpdates()}}/>
            </ToolbarItem>
        </ToolbarContent>
    </Toolbar>
    return (
        <React.Fragment>
            <>
                {toolbarItems}
                <Divider />
                <SearchInput
                    className="pf-v5-u-p-sm"
                    placeholder="Search..."
                    value={inputValue}
                    onChange={(_event, value) => setInputValue(value)}
                    onSearch={onSearch}
                    onClear={() => { setInputValue(''); setSearchValue('') }} />

                {windowload ? <Loader /> :
                    <>
                        <InnerScrollContainer>
                            <Table variant="compact" aria-label="inventory" id="inventory-table" isStickyHeader gridBreakPoint="grid-md">
                                <Thead >
                                    <Tr key={'NS-table'}>
                                        {Object.values(columns).map((column, columnIndex) => {
                                            const stickyColumnsIndex = [0, 1];
                                            const isSticky = stickyColumnsIndex.includes(columnIndex);
                                            const sortParams = {
                                                sort: {
                                                    sortBy: {
                                                        index: activeSortIndex,
                                                        direction: activeSortDirection
                                                    },
                                                    onSort,
                                                    columnIndex
                                                }
                                            };
                                            return <Th key={columnIndex} isStickyColumn={isSticky} {...sortParams}>{column}</Th>
                                        }
                                        )}
                                    </Tr>
                                </Thead>
                                <Tbody key={'tablebody'}>
                                    {rows.length > 0 ?
                                        rows && rows.map((row, rowIndex) => {
                                            return (
                                                <Tr key={rowIndex + 'row'}
                                                    onRowClick={() => setSelectedRowName(rowIndex)}
                                                    isSelectable
                                                    isClickable
                                                    isRowSelected={selectedRowName === rowIndex}
                                                >
                                                    <Td width={50} dataLabel={'Update Code'} key={'upcode' + rowIndex}>{row?.hotfix}</Td>
                                                </Tr>

                                            )
                                        }) :
                                        <Tr key='empty-row'>
                                            <Td colSpan={10} key='empty-data'>
                                                <Emptystatefornodata />
                                            </Td>
                                        </Tr>
                                    }
                                </Tbody>
                            </Table>

                        </InnerScrollContainer>
                        {renderPagination('bottom', true)}
                    </>
                }
            </>
        </React.Fragment>
    );
}
const mapStateToProps = (state) => ({
    dates: { startDate: state.startdate, endDate: state.enddate },
    filterformdata: state.FilterData,
    // mapStateToProps content remains unchanged if you have it
});

export default connect(
    mapStateToProps,
    null,
    null,
    { forwardRef: true }
)(WindowsUpdate)
