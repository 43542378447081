import * as echarts from 'echarts';
import React, { useEffect, useState } from 'react';
import { generateUniqueID } from '../../util/UniqueIdGenerator';
import dbApi from '../../services/DbApi'
export const RankbarGraph = () => {
    const [agentRankdata, setAgentRankdata] = useState([])
    const getRankListbar = async () => {
        try {
            let res = await dbApi.getApi("Opensearch/get_agent_risk_score_graph_data")
            console.log(res)
            let agentRankdata = []
            if (res.agentRiskData.length > 0) {
                agentRankdata = res.agentRiskData
                setAgentRankdata(agentRankdata)
            }
            else{
                setAgentRankdata(agentRankdata)
            }
            
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        getRankListbar()
    }, [])
    const id = generateUniqueID()
    useEffect(() => {
        if (agentRankdata && agentRankdata.length > 0) {
            const chartDom = document.getElementById(id + 'rankbar');
            const myChart = echarts.init(chartDom);
            const option = {
                tooltip: {
                    trigger: 'axis',
                    textStyle: {
                        color: "#000"
                    }
                },
                grid: {
                    left: '1%',
                    right: '13%',
                    bottom: '12%',
                    top: '6%',
                    containLabel: true
                },
                xAxis: {
                    type: 'value',

                },
                yAxis: {
                    type: 'category',
                    inverse: true,
                    axisTick: {
                        alignWithLabel: true
                    },
                    data: agentRankdata.slice(0, 15).map((item => item.agent_name)),
                    axisLabel: {
                        interval: 0,
                        rotate: 20,
                        width: 70,
                        overflow: 'truncate',
                        fontSize: 12,
                    },
                },
                color: ['#A30000', '#C9190B', '#f0ab00', '#2B9AF3'],
                series: [
                    {
                        name: '',
                        type: 'bar',
                        barWidth: 12,
                        emphasis: {
                            focus: 'series',
                        },
                        //data: percentage
                        data: agentRankdata.slice(0, 15).map((bucket) => ({
                            value: bucket.percentage, // Use log scale for word size
                            name: bucket.agent_name,
                        })
                        )

                    },

                ]
            };
            option && myChart.setOption(option);
            return () => {
                myChart.dispose();
            };

        }

    }, [agentRankdata])
    return <div className="container">
        <div id={id + 'rankbar'} style={{ width: '100%', height: '450px' }} ></div>
    </div>;
}
