import { ActionGroup, Button, FormGroup, Grid, GridItem, Popover, Switch, TextArea, TextInput, ToggleGroup, ToggleGroupItem } from '@patternfly/react-core';
import { HelpIcon, LinkIcon, SaveIcon, StarHalfIcon, StarIcon } from '@patternfly/react-icons';
import React, { useState, useEffect } from 'react';
import dbApi from 'src/services/DbApi';
import { useDispatch } from 'react-redux';
import ConfirmBox from 'src/Components/ConfirmBox/ConfirmBox';
import { useParams } from 'react-router-dom';
import CommonSelectBox from 'src/Components/CommonEventPages/CommonSelectBox';
import { alertBoxData } from 'src/Redux/Action';
import IndexInputGroup from 'src/Components/OnBoarding/IndexInputGroup';
import { Loader } from 'src/util/Loadingstate';

const IncidentObservableModal = ({ onclose, IncidentId, onModalClose }) => {
    const dispatch = useDispatch();
    const initialFormset = {
        // title: '',
        // type: '',

        // incidentId: IncidentId,
        // description: '',

    };
    const [formData, setFormData] = useState([initialFormset]);
    const [tlp, setTlp] = useState('AMBER');
    const [isLoading, setIsLoading] = useState(false);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [validDescription, setValidDescription] = useState('');
    const [value, setValue] = useState('');
    const [validValue, setValidValue] = useState('');
    const [assignee, setAssignee] = useState('');
    const [validTitle, setValidTitle] = useState('');
    const [validAssignee, setValidAssignee] = useState('');
    const [typeList, setTypeList] = useState([]);
    const [selectedInvestigatorValue, setSelectedInvestigatorValue] = useState('');
    const [type, setType] = useState('');
    const [validType, setValidType] = useState('');
    const [investigatorValid, setInvestigatorValid] = useState('');
    const [chips, setChips] = useState([]);
    const [indexNameValid, setIndexNameValid] = useState('');
    const [isLoader, setIsLoader] = useState(false);
    const [confirmModalData, setConfirmBox] = useState({
        show: false,
        msg: '',
        funCall: '',
        funParam: {},
        title: '',
        position: '',
        iconvariant: '',
        submitbtnvariant: '',
    });
    const [isChecked, setIsChecked] = React.useState(false);
    const [isIoc, setIsIoc] = useState(false);
    const [hasBeenSighted, setHasBeenSighted] = useState(false);
    const [ignoreSimilarity, setIgnoreSimilarity] = useState(false);

    const handleIsIcoToggle = () => {
        setIsIoc(!isIoc);
    };
    const handleIgnoreSimilarityToggle = () => {
        setIgnoreSimilarity(!ignoreSimilarity);
    };

    const handleChange = (_event, checked) => {
        setIsChecked(checked);
        setHasBeenSighted(checked);
    };
    const handleUpdateTypeValue = (value) => {
        setType(value);
        setValidType(emptyCheck(value));
    };

    const handleSetChips = (newChips) => {
        setChips(newChips);
    };

    const handleTlpToggle = (event, _isSelected) => {
        const id = event.currentTarget.id;
        setTlp(id);
    }

    const handleFieldChange = (value, field) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [field]: value,
        }));

        switch (field) {
            case 'type':
                setType(value);
                setValidType(emptyCheck(value));
                break;
            case 'description':
                setDescription(value);
                setValidDescription(emptyCheck(value));
                break;
            case 'value':
                setValue(value);
                setValidValue(emptyCheck(value));
                break;
            case 'assignee':
                setAssignee(value);
                setValidAssignee(emptyCheck(value));
                break;
            default:
                break;
        }
    };

    const emptyCheck = (value) => {
        return value ? 'success' : 'error';
    };

    const resetForm = () => {
        setType('');
        setDescription('');
        setSelectedInvestigatorValue('');
        setInvestigatorValid('');
    };

    const finalSubmit = async () => {
        setIsLoader(true);
        const params = {
            incidentId: IncidentId,
            type: type,
            description: description,
            tlp: tlp,
            value: value,
            // assigneeId: selectedInvestigatorValue,
            // isChecked: isChecked,
            isIoc: isIoc,
            hasBeenSighted: hasBeenSighted,
            ignoreSimilarity: ignoreSimilarity,
            // tags: chips.map(chip => ({ uniqueId: '', tag: chip }))
        };

        try {
            let res = await dbApi.postApi('incident_management/add_observable', params);

            if (!res.error) {
                dispatch(alertBoxData([{ title: res.message, variant: 'success' }]));
                resetForm();
                onclose();
                onModalClose(true);
            } else {
                dispatch(alertBoxData([{ title: res.message, variant: 'danger' }]));
            }
        } catch (error) {
            console.error('API call failed:', error);
            onModalClose(false);
        }
        setIsLoader(false);
    };

    const formValidation = () => {
        let isValid = true;

        // const organizationStatus = emptyCheck(selectedOrgValue);
        // setOrganizationValid(organizationStatus);
        // if (organizationStatus === 'error') {
        //     isValid = false;
        // }
        const typeStatus = emptyCheck(type);
        setValidType(typeStatus);
        if (typeStatus === 'error') {
            isValid = false;
        }
        const descriptionStatus = emptyCheck(description);
        setValidDescription(descriptionStatus);
        if (descriptionStatus === 'error') {
            isValid = false;
        }
        const valueStatus = emptyCheck(value);
        setValidValue(valueStatus);
        if (valueStatus === 'error') {
            isValid = false;
        }
        const chipsStatus = emptyCheck(chips.length > 0 ? 'valid' : '');
        setIndexNameValid(chipsStatus);
        if (chipsStatus === 'error') {
            isValid = false;
        }
        return isValid;
    };

    const submitForm = () => {
        if (formValidation(formData)) {
            setConfirmBox({
                show: true,
                msg: 'Are you sure?',
                funCall: finalSubmit,
                title: 'Add Observable.',
                position: 'default',
                iconvariant: 'info',
                submitbtnvariant: 'primary',
            });
            // handleModalToggle();
        }
    };

    const getTypeList = async () => {
        try {
            const res = await dbApi.getApi("incident_management/get_observable_type");
            console.log('observable_type', res)
            if (res?.length > 0) {
                setTypeList(res);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getTypeList();
    }, []);


    const getBackgroundColor = (id) => {
        switch (id) {
            case 'WHITE':
                return '#f0f0f0'; // Light gray or white
            case 'GREEN':
                return '#4caf50'; // Green
            case 'AMBER':
                return '#ffbf00'; // Amber
            case 'RED':
                return '#f44336'; // Red
            default:
                return 'transparent';
        }
    };
    return (
        <>
            {isLoader ? <Loader /> : (
                <div>
                    <div className="pf-v5-u-mt-md">
                        <Grid>
                            <GridItem lg={6}>
                                <CommonSelectBox
                                    options={typeList.map(item => ({
                                        value: item.id,
                                        children: item.title
                                    }))}
                                    isDisabled={false}
                                    createOption={false}
                                    selectedOptions={type}
                                    placeholder="Type"
                                    updateValue={handleUpdateTypeValue}
                                    validStatus={validType} // Add this line to show validation status
                                // helperTextInvalid="Type is required" // Add this line to show the validation message
                                />

                            </GridItem>
                        </Grid>
                    </div>
                    <Grid className='pf-v5-u-mt-lg'>
                        <GridItem lg={2}>
                            <FormGroup
                                label="TLP"
                                isRequired
                                className='pf-v5-u-font-weight-bold'
                                fieldId="simple-form-group-05"
                                helperTextInvalid="TLP is required"
                                labelIcon={
                                    <Popover
                                        headerContent={
                                            <div>
                                                Pap
                                            </div>
                                        }
                                        bodyContent={
                                            <div>
                                                Pap Info
                                            </div>
                                        }
                                    >
                                        <HelpIcon />
                                    </Popover>
                                }
                            />
                        </GridItem>
                        <GridItem lg={5}>
                            <ToggleGroup aria-label="TLP">
                                <ToggleGroupItem
                                    className='pf-v5-u-mr-sm toggle-white'
                                    text="WHITE"
                                    buttonId="WHITE"
                                    isSelected={tlp === 'WHITE'}
                                    onChange={handleTlpToggle}
                                    style={{
                                        backgroundColor: tlp === 'WHITE' ? '#00c0ef' : 'transparent',
                                        '--pf-v5-c-toggle-group__button--hover--BackgroundColor': tlp === 'WHITE' ? '#00c0ef' : 'transparent'
                                    }}
                                />
                                <ToggleGroupItem
                                    className='pf-v5-u-mr-sm'
                                    text="GREEN"
                                    buttonId="GREEN"
                                    isSelected={tlp === 'GREEN'}
                                    onChange={handleTlpToggle}
                                    style={{
                                        backgroundColor: tlp === 'GREEN' ? '#2E8B57' : 'transparent',
                                        '--pf-v5-c-toggle-group__button--hover--BackgroundColor': tlp === 'GREEN' ? '#2E8B57' : 'transparent'
                                    }}
                                />
                                <ToggleGroupItem
                                    className='pf-v5-u-mr-sm'
                                    text="AMBER"
                                    buttonId="AMBER"
                                    isSelected={tlp === 'AMBER'}
                                    onChange={handleTlpToggle}
                                    style={{
                                        backgroundColor: tlp === 'AMBER' ? '#F0AB00' : 'transparent',
                                        '--pf-v5-c-toggle-group__button--hover--BackgroundColor': tlp === 'AMBER' ? '#F0AB00' : 'transparent'
                                    }}
                                />
                                <ToggleGroupItem
                                    className='pf-v5-u-mr-sm'
                                    text="RED"
                                    buttonId="RED"
                                    isSelected={tlp === 'RED'}
                                    onChange={handleTlpToggle}
                                    style={{
                                        backgroundColor: tlp === 'RED' ? '#C9190B' : 'transparent',
                                        '--pf-v5-c-toggle-group__button--hover--BackgroundColor': tlp === 'RED' ? '#C9190B' : 'transparent'
                                    }}
                                />
                            </ToggleGroup>
                        </GridItem>

                    </Grid>
                    <FormGroup fieldId="value" className="pf-v5-u-mt-md">
                        <TextArea
                            isRequired
                            value={value}
                            placeholder="Value"
                            onChange={(e) => handleFieldChange(e.target.value, 'value')}
                            id="value"
                            name="value"
                            validated={validValue}
                        />
                    </FormGroup>
                    <Grid className='pf-v5-u-mt-lg'>
                        <GridItem lg={2}>
                            <FormGroup
                                label="Has been sighted"
                                className='pf-v5-u-font-weight-bold'
                                fieldId="simple-form-group-05"
                            />
                        </GridItem>
                        <GridItem lg={2}>
                            <Switch id="no-label-switch-on" aria-label="Message when on" isChecked={isChecked} onChange={handleChange} />
                        </GridItem>
                        <GridItem lg={2}>
                            <FormGroup
                                label="Is IOC"
                                className='pf-v5-u-font-weight-bold'
                                fieldId="simple-form-group-05"
                            />
                        </GridItem>
                        <GridItem lg={2}>
                            <Button
                                variant="plain"
                                aria-label="Toggle star"
                                onClick={handleIsIcoToggle}
                                className='pf-v5-u-font-size-xl'
                            >
                                {isIoc ? <StarIcon color='#f39c12' /> : <StarIcon />}
                            </Button>
                        </GridItem>
                        <GridItem lg={2}>
                            <FormGroup
                                label="Ignore for similarity"
                                className='pf-v5-u-font-weight-bold'
                                fieldId="simple-form-group-05"
                            />
                        </GridItem>
                        <GridItem lg={2}>
                            <Button
                                variant="plain"
                                aria-label="Toggle star"
                                onClick={handleIgnoreSimilarityToggle}
                                className='pf-v5-u-font-size-xl'
                            >
                                {ignoreSimilarity ? <LinkIcon color='#337ab7' /> : <LinkIcon />}
                            </Button>
                        </GridItem>
                    </Grid>
                    <div className='pf-v5-u-mt-sm'>
                        <IndexInputGroup
                            placeholder='Enter Tags name'
                            currentChips={chips}
                            setCurrentChips={handleSetChips}
                            indexNameValid={indexNameValid}

                        />
                    </div>
                    <FormGroup fieldId="description" className="pf-v5-u-mt-md">
                        <TextArea
                            isRequired
                            value={description}
                            placeholder="Description"
                            onChange={(e) => handleFieldChange(e.target.value, 'description')}
                            id="description"
                            name="description"
                            validated={validDescription}
                        />
                    </FormGroup>
                    {/* <FormGroup
        isRequired
        fieldId="assignee"
        helperTextInvalid="assignee is required"
        validated={validAssignee}
        className="pf-v5-u-mt-md"
      >
        <TextInput
          value={assignee}
          isRequired
          placeholder="Assignee"
          type="text"
          id="assignee"
          aria-describedby="Assignee-helper"
          name="assignee"
          onChange={(e) => handleFieldChange(e.target.value, 'assignee')}
          validated={validAssignee}
        />
      </FormGroup> */}

                    <ActionGroup className="pf-v5-u-mt-md">
                        <Button variant="primary" className="pf-v5-u-mr-md" icon={<SaveIcon />} onClick={submitForm}>
                            Create
                        </Button>
                        <Button variant="secondary" onClick={onclose}>Cancel</Button>
                    </ActionGroup>
                    <ConfirmBox confirmModal={confirmModalData} />
                </div>
            )}
        </>
    );
};

export default IncidentObservableModal;
