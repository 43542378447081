import React, { useState, useImperativeHandle, forwardRef } from 'react';
import { Modal, Card } from '@patternfly/react-core';

const CustomModalBox = forwardRef(({
    title,
    variant,
    onclose,
    icon,
    description,
    children
}, ref) => {

    const [isModalOpen, setModalOpen] = useState(false);
    const handleModalToggle = (_event) => {
        setModalOpen(!isModalOpen);
    };

    useImperativeHandle(ref, () => ({
        setModalBox(modalopen) {
            setModalOpen(modalopen);
        }
    }));

    return (
        <React.Fragment>
            <Modal
                aria-label='custom-modal'
                variant={variant}
                title={title}
                titleIconVariant={icon}
                isOpen={isModalOpen}
                onClose={(e) => { handleModalToggle(e); onclose(); }}
                description={description}
            >
                {children}
            </Modal>
        </React.Fragment>
    );
});

export default CustomModalBox;
