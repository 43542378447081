import React, { useEffect, useState } from "react";
import {
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    Table,
} from "@patternfly/react-table";
import { Badge, Tooltip, } from "@patternfly/react-core";
import { connect, useDispatch } from "react-redux";
import { FilterData } from "../../Redux/Action";
import { UpdateFilterData } from "../../util/FilterHelper";

const RuleClassificationtable = (props) => {
    const dispatch=useDispatch()
    const columns = ["Rule level", "Title", "Description"]
    const rows = [
        { rulelevel: '5', title: 'User generated error', description: 'These include missed passwords, denied actions, etc. By themselves, these have no security relevance.' },
        { rulelevel: '6', title: 'Low relevance attack', description: 'These indicate a worm or a virus that have no affect to the system (like code red for apache servers, etc). These also include frequently IDS events and frequently errors.' },
        { rulelevel: '7', title: '"Bad word" matching', description: 'These include words like "bad", "error", etc.These events are most of the time unclassified and may have some security relevance.' },
        { rulelevel: '8', title: 'First time seen', description: 'Include first time seen events. First time an IDS event is fired or the first time a user logged in. It also includes security relevant actions (like the starting of a sniffer or something like that).' },
        { rulelevel: '9', title: 'Error from invalid source', description: 'Include attempts to login as an unknown user or from an invalid source.May have security relevance (specially if repeated).These also include errors regarding the "admin" (root) account.' },
        { rulelevel: '10', title: 'Multiple user generated errors', description: 'These include multiple bad passwords, multiple failed logins, etc.These may indicate an attack or may just be that a user just forgot his credentials.' },
        { rulelevel: '11', title: 'Integrity checking warning', description: 'These include messages regarding the modification of binaries or the presence of rootkits (by Rootcheck).These may indicate a successful attack. Also included IDS events that will be ignored (high number of repetitions).' },
        { rulelevel: '12', title: 'High importance event', description: 'These include error or warning messages from the system, kernel, etc. These may indicate an attack against a specific application.' },
        { rulelevel: '13', title: 'Unusual error (high importance)', description: 'Most of the times it matches a common attack pattern.' },
        { rulelevel: '14', title: 'High importance security event', description: 'Most of the times done with correlation and it indicates an attack.' },
        { rulelevel: '15', title: 'Severe attack', description: 'No chances of false positives. Immediate attention is necessary' },
    ]
    const [selectedRowName, setSelectedRowName] = useState('');

    const handleFilter = (fields, value) => {
        const updatedFields = UpdateFilterData(props.filterformdata, fields, 'is', value);
        dispatch(FilterData(updatedFields));
      }


    const renderRuleLevelcolumn = (ruleLevel) => {
        let tooltipContent = "";
        let badgeStyle = {};
        if (ruleLevel > 6 && ruleLevel < 12) {
            tooltipContent = "Medium";
            badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#F0AB00" };
        }
        else if (ruleLevel >= 12) {
            tooltipContent = "Critical";
            badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#A30000 " };
        }
        else {
            tooltipContent = "low";
            badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#0066CC " };
        }
        return (
            <Tooltip content={tooltipContent}>
               <div> <Badge style={badgeStyle} onClick={() => { handleFilter('rule.level', ruleLevel) }} >{ruleLevel}</Badge></div>
            </Tooltip>
        );
    };

    return (
        <React.Fragment>
            <Table variant="compact" aria-label="IPlog" id="IPlog-table" isStickyHeader>
                <Thead >
                    <Tr key={'cahrt-table-header'}>
                        {columns.map((column, index) => (
                            <Th key={column + index}>{column}</Th>
                        ))}
                    </Tr>
                </Thead>
                {rows.length > 0 && (rows.map((row, rowIndex) => {
                    return (
                        <Tbody key={rowIndex + 'tablebody'}>
                            <Tr key={rowIndex + 'row'}
                                onRowClick={() => setSelectedRowName(rowIndex)}
                                isSelectable
                                isClickable
                                isRowSelected={selectedRowName === rowIndex} >
                                <Td width={10} key={rowIndex + 'level'} dataLabel="Rule level">{renderRuleLevelcolumn(row?.rulelevel)}</Td>
                                <Td width={20} key={rowIndex + 'title'} dataLabel="Title">{row?.title}</Td>
                                <Td width={70} key={rowIndex + 'desc'} dataLabel="Description">{row?.description}</Td>
                            </Tr>
                        </Tbody>
                    )
                }
                ))}
            </Table>
        </React.Fragment>
    );
}
const mapStateToProps = (state) => ({
    dates: { startDate: state.startdate, endDate: state.enddate },
    filterformdata: state.FilterData,
    // mapStateToProps content remains unchanged if you have it
});

export default connect(
    mapStateToProps,
    null,
    null,
    { forwardRef: true }
)(RuleClassificationtable)
