import React, { useState, useRef, useEffect, forwardRef } from "react";
import { connect, useDispatch } from "react-redux";
import {
  Bullseye,
  Grid,
  GridItem,
  MenuToggle,
  Page,
  Sidebar,
  Select,
  SelectOption,
  SelectList,
  SidebarContent,
  Text,
  Drawer,
  DrawerContent,
  DrawerPanelContent,
  DrawerContentBody,
  Card,
  DrawerHead,
  DrawerActions,
  DrawerCloseButton,

} from "@patternfly/react-core";
import dbApi from '../../services/DbApi';
import EventLogTable from "../CommonEventPages/EventLogTable";
import Eventsidebar from "../CommonEventPages/EventSidebar";
import EventChart from "../CommonEventPages/EventChart";
import { formatTableTimestamp } from "../../util/Timeutils";
import { Loader } from "../../util/Loadingstate";
import { Emptystatefornodata } from "../../util/EmptyStateCard";
import { eventfieldData } from "src/Redux/Action";

const IntegrityEvents = forwardRef(function IntegrityEvents(props, ref) {
  const dispatch = useDispatch();
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState("select");
  const [IMscrollid, setIMScrollid] = useState('');
  const [logtabledata, setLogTableData] = useState([]);
  const [totalcount, setTotalCount] = useState("0");
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [chartlabel, setChartLabel] = useState('')
  const [chartseries, setChartSeries] = useState([])
  const [fieldKeys, setFieldKeys] = useState([])
  const [isLoading, setIsLoading] = useState(false);

  const childSecurityEventRef = useRef(null);
  const time1options = [
    <SelectOption key={0} value="seconds"> seconds</SelectOption>,
    <SelectOption key={1} value="minutes">minutes </SelectOption>,
    <SelectOption key={2} value="hours">hours</SelectOption>,
    <SelectOption key={3} value="days">days</SelectOption>,
    <SelectOption key={4} value="weeks">weeks</SelectOption>,
    <SelectOption key={5} value="months">months</SelectOption>,
    <SelectOption key={6} value="years">years</SelectOption>,
  ];
  const IntegrityColumn = ["Time", "Agent Name", "Syscheck Path", "Syscheck event", "Rule Description", "Rule level", "Rule ID"]

  // "Syscheck Path", "Syscheck event",
  const clearselection = () => {
    setSelected(null)
    setIsOpen(false)
  }
  const onToggle = (isOpen) => {
    setIsOpen(isOpen)
  }
  const onSelect = (event, selection, isplaceholder) => {
    if (isplaceholder) {
      clearselection()
    } else {
      setSelected(selection)
      setIsOpen(false)
    }
  }

  const getEventlog = async (data) => {
    try {
      setIsLoading(true)
      let all_eventlog = await dbApi.postApi("Opensearch/Integrity_Monitoring_Events", data);
      console.log(all_eventlog);
      // Integrity_Monitoring_Events
      let IMScrollId = ''
      let LogTableData = []
      let ChartLabel = ''
      let ChartSeries = []
      let TotalCount = '0'
      if (Object.keys(all_eventlog.Integrity_Monitoring_Events).length !== 0) {
        let events = all_eventlog.Integrity_Monitoring_Events.Integrity_Monitoring.body.hits
        let label = all_eventlog.Integrity_Monitoring_Events.interval
        let ChartData = all_eventlog.Integrity_Monitoring_Events.Integrity_Monitoring.body.aggregations[2]
        let scrollID = all_eventlog.Integrity_Monitoring_Events.Integrity_Monitoring.body._scroll_id
        let fieldkeys = all_eventlog.Integrity_Monitoring_Events.keys

        ChartSeries = ChartData.buckets;
        ChartLabel = label
        IMScrollId = scrollID
        LogTableData = events.hits;
        TotalCount = events.total.value;

        setLogTableData(LogTableData)
        setFieldKeys(fieldkeys)
        setIMScrollid(IMScrollId)
        setTotalCount(TotalCount)
        setChartLabel(ChartLabel)
        setChartSeries(ChartSeries)
      }
      else {
        setLogTableData(LogTableData)
        setFieldKeys([])
        setIMScrollid(IMScrollId)
        setTotalCount(TotalCount)
        setChartLabel(ChartLabel)
        setChartSeries(ChartSeries)
      }
      setIsLoading(false)
    }
    catch (error) {
      console.log(error)
      setLogTableData([])
      setFieldKeys([])
      setIMScrollid('')
      setTotalCount(0)
      setChartLabel('')
      setChartSeries([])
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (childSecurityEventRef.current) {
      childSecurityEventRef.current.setSecurityEventTable(logtabledata, IMscrollid, IntegrityColumn);
    }
  }, [logtabledata, IMscrollid, IntegrityColumn]);

  const eventsApi = async (startDate, endDate, allFields) => {
    const Fields = allFields
    const data = {
      startDate: startDate,
      endDate: endDate,
      filter: JSON.stringify(Fields),
    };
    try {
      setIsLoading(true)
      setStartDate(startDate)
      setEndDate(endDate)
      await getEventlog(data)
    }
    catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {

    if (props.dates.startDate != "") {
      let Dates = props.dates
      eventsApi(Dates.startDate, Dates.endDate, props.filterformdata,)
    }
  }, [props?.dates.startDate, props?.dates.endDate, props?.filterformdata]);

  const toggle = toggleRef => <MenuToggle
    ref={toggleRef}
    onClick={onToggle}
    isExpanded={isOpen}
    style={{ width: '150px' }}>
    {selected}
  </MenuToggle>

  // console.log("sd",startDate)
  // console.log("ed",endDate)
  const { formattedTimeStamp: StartDate } = formatTableTimestamp(startDate);
  const { formattedTimeStamp: EndDate } = formatTableTimestamp(endDate);
  return (
    <div>
      {/* <Card isFlat >
        <Drawer isStatic isExpanded={isExpanded} onExpand={onExpand} isInline> 
          <DrawerContent panelContent={
            <DrawerPanelContent isResizable defaultSize={'82%'} minSize={'850px'}>
              <Text align="center" className="pf-v5-u-mt-sm"><b>{`${totalcount.toLocaleString('hi-IN')} hits`}</b></Text>
              <Text align='center'><small><b>{StartDate} - {EndDate}</b></small></Text>
              {isLoading ? <Loader /> :
                chartseries.length === 0 ? <Emptystatefornodata /> : <>
                  <EventChart EventSeries={chartseries} EventLabel={chartlabel} StartDateTime={startDate} EndDateTime={endDate} />

                  <EventLogTable ref={childSecurityEventRef} />
                </>}
              <DrawerHead>
                <DrawerActions>
                  <DrawerCloseButton onClick={alert('close')} />
                </DrawerActions>
              </DrawerHead>
            </DrawerPanelContent>
          }>
            <DrawerContentBody>

              <Eventsidebar
                Count={totalcount}
                Loading={isLoading}
                FieldKeys={fieldKeys}
                filterfield={[{
                  'fields': 'rule.groups',
                  'condition': 'is',
                  'value': 'syscheck'
                }]} />

            </DrawerContentBody>
          </DrawerContent>
        </Drawer>
      </Card> */}

      <Grid hasGutter>
        <GridItem>
          <Page isManagedSidebar>
            <Sidebar style={{ overflow: "auto" }} >
              <Eventsidebar
                Count={totalcount}
                Loading={isLoading}
                FieldKeys={fieldKeys}
                filterfield={[{
                  'fields': 'rule.groups',
                  'condition': 'is',
                  'value': 'syscheck'
                }]} />
              <div style={{ width: "100%" }}>
                <SidebarContent className="Sidebar-content">
                  <Text align="center"><b>{`${totalcount.toLocaleString('hi-IN')} hits`}</b></Text>
                  <Text align='center'><small><b>{StartDate} - {EndDate}</b></small></Text>
                  {isLoading ? <Loader /> :
                    chartseries.length === 0 ? <Emptystatefornodata /> :
                      <>
                        <EventChart EventSeries={chartseries} EventLabel={chartlabel} StartDateTime={startDate} EndDateTime={endDate} />

                        <EventLogTable ref={childSecurityEventRef} />
                      </>
                  }
                </SidebarContent>
              </div>
            </Sidebar>
          </Page>
        </GridItem>
      </Grid>
    </div>
  );
})

const mapStateToProps = (state) => ({
  dates: { startDate: state.startdate, endDate: state.enddate },
  filterformdata: state.FilterData,
  selectedGroup: state.SelectedGroup,
  // mapStateToProps content remains unchanged if you have it
});

const mapDispatchToProps = (dispatch) => { return {} };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(IntegrityEvents)
