import { Card, CardBody, CardTitle, Divider, Grid, GridItem } from '@patternfly/react-core'
import React from 'react'
import EventBar from '../CommonEventPages/BkEventBar'

export const RiskFactor = () => {
  return (<>
    <Grid hasGutter>
      <GridItem md={7} lg={7} sm={3}>
        <Card>
          <CardTitle>Risk Score</CardTitle>
          <Divider />
          <CardBody>
            <Grid>
              <GridItem md={6} lg={6} sm={3}>
                <Card isPlain isFullHeight >
                  {/* <CardTitle align="center">Risk Score Trend</CardTitle> */}
                  <CardBody></CardBody>
                  <CardBody >
                    Trend Chart Data
                  </CardBody>
                  <CardBody></CardBody>
                </Card>
              </GridItem>
              <GridItem md={6} lg={6} sm={3}>
                <Card isPlain isFullHeight >
                  {/* <CardTitle align="center">Overall Risk Score</CardTitle> */}
                  <CardBody></CardBody>
                  <CardBody >
                    Overall score Data
                  </CardBody>
                  <CardBody></CardBody>
                </Card>
              </GridItem>
            </Grid>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem md={5} lg={5} sm={3}>
        <Card isFlat isFullHeight >
          <CardTitle align="center"> Key Factors</CardTitle>
          <Divider />
          <CardBody></CardBody>
          <CardBody >
            Data
          </CardBody>
          <CardBody></CardBody>
        </Card>
      </GridItem>
    </Grid>
    <div className="pf-v5-u-mt-sm">
      <Grid>
        <GridItem md={5} lg={5} sm={3}>
          <Card isFlat isFullHeight >
            <CardTitle >Probable Attacks</CardTitle>
            <Divider />
            <CardBody></CardBody>
            <CardBody >
              Data
            </CardBody>
            <CardBody></CardBody>
          </Card>
        </GridItem>
      </Grid>
    </div>
  </>
  )
}
