import * as echarts from 'echarts';
import React, { useEffect } from 'react';
import { generateUniqueID } from '../../util/UniqueIdGenerator';
import { Emptystatefornodata } from '../../util/EmptyStateCard';
import { UpdateFilterData } from '../../util/FilterHelper';
import { FilterData } from '../../Redux/Action';
import { connect, useDispatch } from 'react-redux';
const EDonutChart = (props) => {
    const id = generateUniqueID()
    const dispatch = useDispatch();
    const handleFilter = (fields, value) => {
        const updatedFields = UpdateFilterData(props.filterformdata, fields, 'is', value);
        dispatch(FilterData(updatedFields));
    }
    useEffect(() => {
        const chartDom = document.getElementById(id + 'edt');
        const myChart = echarts.init(chartDom);
        const textcolor = props.theme == 'dark-theme' ? '#fff' : '#000'
        const option = {
            tooltip: {
                trigger: 'item',
                textStyle: {
                    color: "#000"
                }
            },

            legend: {
                top: 'bottom',
                itemWidth: 9.5,
                itemHeight: 10,
                itemGap: 6.5,
                icon: "rect",
                tooltip: {
                    trigger: 'item',
                    show: true,
                    position: [60, 40],
                    textStyle: {
                        color: "#000",
                        fontSize: 12,
                    }
                },
                textStyle: {
                    width: 200,
                    overflow: 'truncate',
                    fontSize: 12,
                    color: textcolor
                }
            },
            color: props.color,
            series: [
                {
                    type: 'pie',
                    left: 0,
                    right: 0,
                    top: -10,
                    bottom: 100,
                    radius: '70%',
                    center: ['50%', '50%'],
                    roseType: 'area',
                    itemStyle: {
                        borderRadius: 10,
                    },
                    label: {
                        alignTo: 'edge',
                        minMargin: 1,
                        edgeDistance: 1,
                        lineHeight: 1,
                        color: textcolor
                    },
                    labelLine: {
                        length: 1,
                        length2: 1,
                        smooth: true
                    },
                    // itemStyle: {
                    //     borderRadius: 5,
                    // },
                    data: props.seriesData.map((bucket) => {
                        const mappedData = {
                            value: bucket.doc_count ? bucket.doc_count : bucket.count, // Use log scale for word size
                            name: bucket.key ? bucket.key : bucket.topuser,
                        }; // console.log("Mapped data:", mappedData); // Check mapped data
                        return mappedData;
                    }
                    )
                }
            ]
        };

        option && myChart.setOption(option);
        if (props.filterFields !== 'health') {
            myChart.on('legendselectchanged', function (param) {
                const Fields = props.filterFields
                handleFilter(Fields, param.name)
            });
        }
        const resizeChart = () => {
            if (myChart) { // Check if chart exists before resizing
                myChart.resize();
            }
        };

        window.addEventListener('resize', resizeChart);
        return () => {
            myChart.dispose();
            window.removeEventListener('resize', resizeChart);
        };

    }, [props.height, props.seriesData, props.filterFields, props.theme, props.sidebarState, props.height])

    return <> <div className="container">
        {props.seriesData.length == 0 ?
            (<>
                <div id={id + 'edt'} style={{ width: '1px', height: '1px' }}> </div>
                < Emptystatefornodata />
            </>)
            : (
                <div id={id + 'edt'} style={{ width: '100%', height: props.height ? props.height : '290px' }} > </div>
            )
        }
    </div>
    </>

}
const mapStateToProps = (state) => ({
    dates: { startDate: state.startdate, endDate: state.enddate },
    filterformdata: state.FilterData,
    theme: state.theme,
    sidebarState: state.sidebarstate
    // mapStateToProps content remains unchanged if you have it
});

export default connect(
    mapStateToProps,
    null,
    null,
    { forwardRef: true }
)(EDonutChart)
