import React, { useEffect, useState } from 'react'
import {
    TextVariants,
    Text,
    WizardStep,
    Wizard,
    WizardHeader,
    Title,
    Gallery,
    GalleryItem,
    CardTitle,
    Card,
    Divider,
    CardBody,
    Radio,
    Form,
    FormGroup,
    Popover,
    TextInput,
    Alert,
    FormHelperText,
    HelperText,
    HelperTextItem,
    useWizardContext,
    WizardFooter,
    WizardFooterWrapper,
    Button,
    List,
    ListItem
} from '@patternfly/react-core';
import dbApi from '../../services/DbApi'
import { HelpIcon, LinuxIcon, WindowsIcon } from '@patternfly/react-icons';
import CommonMultiSelectBox from '../CommonEventPages/CommonMultiSelectBox';
import { connect } from 'react-redux';


const CustomStepThirdFooter = ({ getCommandsParent }) => {
    const { goToNextStep, goToPrevStep, close } = useWizardContext();
    const [isLoading, setIsLoading] = useState(false);
    async function onNext() {
        setIsLoading(true);
        await getCommandsParent();
        setIsLoading(false);
        goToNextStep();
    }
    return <WizardFooterWrapper>
        <Button variant="secondary" onClick={goToPrevStep} isDisabled={isLoading}>  Back </Button>
        <Button variant="primary" onClick={onNext} isLoading={isLoading} isDisabled={isLoading}>Next</Button>
        <Button variant="link" onClick={close} isDisabled={isLoading}> Cancel  </Button>
    </WizardFooterWrapper>;
};

const DeployAgent = (props) => {
    const [selectedvalue, setSelectedValue] = useState([]);
    const [multiselectedOptions, setmultiSelectedOptions] = useState(['default'])
    const [agentname, setAgentName] = useState('')
    const [serveradd, setserveradd] = useState('agent.secsphere.com')
    const [isPackageselected, setIsPackageselected] = useState(false);
    const [showHelperText, setShowHelperText] = useState('');
    const [showHelperTextgroup, setShowHelperTextgroup] = useState([]);
    const [checked, setChecked] = useState(false);
    const [installcommand, setInstallCommand] = useState('')
    const [runCommand, setRunCommand] = useState('')
    const handleradioChange = (value) => {
        setChecked(value);
        setIsPackageselected(true)
    };

    const handleMultiSelectChange = (selected) => {
        setmultiSelectedOptions(selected)
    };
    const updateValueselectBox = (value, type = 'valueselect', index = 'Value') => {
        setSelectedValue(value)
        setShowHelperTextgroup(value)
    }
    const handleAgentName = (_event, value) => {
        setAgentName(value)
        setShowHelperText(value);

    }
    const getCommands = async () => {
        try {
            const data = {
                package: checked,
                serverAddress: serveradd,
                agentName: agentname,
                groupsName: multiselectedOptions
            };
            let res = await dbApi.postApi("opensearch/get_agent_deploy_command", data);
            console.log(res)
            if (Object.keys(res.data).length !== 0) {
                let data = res.data
                setInstallCommand(data.installCommand)
                setRunCommand(data.runAgentCommand)
            }
            else {
                setInstallCommand('')
                setRunCommand('')
            }
        }
        catch (error) {
            console.log(error)
        }
    }
    const RunCommand = runCommand.split('\r\n')
    // console.log("RunCommand", RunCommand)
    return (
        <Wizard
            isVisitRequired
            header={
                <WizardHeader
                    title="Generate Agent S/W Link"
                    titleId="Generate Agent S/W Link"
                    closeButtonAriaLabel="Close wizard"
                    onClose={props.CloseWizard}
                />
            }
            onClose={props.CloseWizard}
            height={400}
        >
            <WizardStep name="Step 1" id="wizard-step-1" footer={{ isNextDisabled: !isPackageselected }} >
                <div>
                    <Title headingLevel="h1">Select the package to download and install on your system:</Title>
                    <div className="pf-v5-u-mt-sm">
                        <Gallery hasGutter>
                            <GalleryItem>
                                <Card isFullHeight>
                                    <CardTitle align='center'><LinuxIcon /> LINUX </CardTitle>
                                    <Divider inset={{ default: 'insetMd' }} />
                                    <CardBody>
                                        <Radio isChecked={checked === 'RPM amd64'} onChange={() => handleradioChange('RPM amd64')} id="RPM amd64" label="RPM amd64" name="select-Package" />
                                        <Radio isChecked={checked === 'RPM aarch64'} onChange={() => handleradioChange('RPM aarch64')} id="RPM aarch64" label="RPM aarch64" name="select-Package" />
                                        <Radio isChecked={checked === 'DEB amd64'} onChange={() => handleradioChange('DEB amd64')} id="DEB amd64" label="DEB amd64" name="select-Package" />
                                        <Radio isChecked={checked === 'DEB aarch64'} onChange={() => handleradioChange('DEB aarch64')} id="DEB aarch64" label="DEB aarch64" name="select-Package" />
                                    </CardBody>
                                </Card>
                            </GalleryItem>
                            <GalleryItem>
                                <Card isFullHeight>
                                    <CardTitle align='center'><WindowsIcon /> WINDOWS</CardTitle>
                                    <Divider inset={{ default: 'insetMd' }} />
                                    <CardBody>
                                        <Radio isChecked={checked === 'MSI 32/64 bits'} onChange={() => handleradioChange("MSI 32/64 bits")} id="MSI 32/64 bits" label="MSI 32/64 bits" name="select-Package" />
                                    </CardBody>
                                </Card>
                            </GalleryItem>
                            <GalleryItem>
                                <Card isFullHeight>
                                    <CardTitle align='center'> MAC OS</CardTitle>
                                    <Divider inset={{ default: 'insetMd' }} />
                                    <CardBody>
                                        <Radio isChecked={checked === 'Intel'} onChange={() => handleradioChange('Intel')} id="Intel" label="Intel" name="select-Package" />
                                        <Radio isChecked={checked === 'Apple Silicon'} onChange={() => handleradioChange('Apple Silicon')} id="Apple Silicon" label="Apple Silicon" name="select-Package" />
                                    </CardBody>
                                </Card>
                            </GalleryItem>
                        </Gallery>
                    </div>
                </div>
            </WizardStep>
            <WizardStep name="Step 2" id="wizard-step-2"  >
                <div>
                    <Title headingLevel="h1" >Server address</Title>
                    <Text className="pf-v5-u-mt-sm" component={TextVariants.h6}>This is the address the agent uses to communicate with the Wazuh server. Enter an IP address or a fully qualified domain name (FDQN).</Text>
                </div>
                <div className="pf-v5-u-mt-xl">
                    <Form>
                        <FormGroup style={{ width: '300px' }} label="Assign a server address" type="text" fieldId="ipaddress"
                            labelIcon={
                                <Popover
                                    showClose={false}
                                    position='right'
                                    headerContent={<div>Learn about <a>Server address</a>.</div>}>

                                    <button type="button" aria-label="More info for name field" onClick={e => e.preventDefault()} aria-describedby="simple-form-name-02" className="pf-v5-c-form__group-label-help">
                                        <HelpIcon />
                                    </button>

                                </Popover>}>
                            <TextInput
                                placeholder='Server address'
                                // validated={validated}
                                value={serveradd}
                                isDisabled
                                id="serveradd"
                                aria-describedby="serveradd-helper"
                            // onChange={handleserveraddchange}
                            />
                        </FormGroup>
                    </Form>
                </div>
            </WizardStep>
            {/* isNextDisabled: (showHelperText == '' || showHelperTextgroup == ''), */}
            <WizardStep name="Step 3" id="wizard-step-3" footer={<CustomStepThirdFooter getCommandsParent={getCommands} />} >
                <div>

                    <Title headingLevel='h1'>Optional settings</Title>
                    <Text className="pf-v5-u-mt-sm" component={TextVariants.h6}>The deployment sets the endpoint hostname as the agent name by default. Optionally, you can set your own name in the field below.</Text>
                </div>
                <div className="pf-v5-u-mt-xl">
                    <Form>
                        <FormGroup style={{ width: '300px' }} label="Assign an agent name" type="text" fieldId="agentname"
                            labelIcon={
                                <Popover
                                    showClose={false}
                                    position='right'
                                    headerContent={<div>Learn about <a>Server address</a>.</div>}>

                                    <button type="button" aria-label="More info for name field" onClick={e => e.preventDefault()} aria-describedby="simple-form-name-02" className="pf-v5-c-form__group-label-help">
                                        <HelpIcon />
                                    </button>

                                </Popover>}>
                            <TextInput
                                placeholder='Agent Name'
                                value={agentname}
                                id="agentname"
                                aria-describedby="agentname-helper"
                                onChange={handleAgentName}
                            />
                            {/* {showHelperText === '' && <FormHelperText>
                                <HelperText>
                                    <HelperTextItem variant={'error'}>
                                        Agent Name must be entered
                                    </HelperTextItem>
                                </HelperText>
                            </FormHelperText>} */}
                        </FormGroup>
                    </Form>
                </div>
                <div className="pf-v5-u-mt-sm">
                    <Alert variant="warning" isInline title="The agent name must be unique. It can’t be changed once the agent has been enrolled." />
                </div>
                <div className="pf-v5-u-mt-xl">
                    <Form>
                        <FormGroup style={{ width: '300px' }}
                            label="Select one or more existing groups" type="text" fieldId="agentgroup"
                            labelIcon={
                                <Popover
                                    showClose={false}
                                    position='right'
                                    headerContent={<div>Learn about <a>Select a group</a>.</div>}>
                                    <button type="button" aria-label="More info for name field" onClick={e => e.preventDefault()} aria-describedby="simple-form-name-02" className="pf-v5-c-form__group-label-help">
                                        <HelpIcon />
                                    </button>

                                </Popover>}>
                            <CommonMultiSelectBox
                                initialOptions={props?.groupsoption.map((item, index) => ({
                                    value: item.groupName,
                                    children: item.groupName,
                                }))
                                }
                                createOption={false}
                                onSelectChange={handleMultiSelectChange}
                                isDisabled={false}
                                updateValue={updateValueselectBox}
                                placeholderText="Select a group"
                                ariaLabel="Your aria label"
                                selectedOptions={multiselectedOptions}
                                clearButtonAriaLabel="Clear input value"

                            />
                            {/* {showHelperTextgroup.length === 0 && <FormHelperText>
                                <HelperText>
                                    <HelperTextItem variant={'error'}>
                                        select atleast one group
                                    </HelperTextItem>
                                </HelperText>
                            </FormHelperText>} */}
                        </FormGroup>
                    </Form>
                </div>
            </WizardStep>
            <WizardStep name="Step 4" id="wizard-step-4"  >
                <div>
                    <Title headingLevel="h1"> Run the following commands to download and install the Wazuh agent:</Title>
                    <div className="pf-v5-u-mt-xl">
                        {/* <Alert variant="warning" isInline title="Please select the server address." /> */}

                        <div class="pf-v5-u-background-color-200"> <Text component={TextVariants.p}>
                            {installcommand}
                        </Text>
                        </div>
                    </div>
                </div>
            </WizardStep>
            <WizardStep name="Step 5" id="wizard-step-5" footer={{ nextButtonText: 'Finish' }} >
                <div>
                    <Title headingLevel="h1">Start the Wazuh agent:</Title>
                    <div className="pf-v5-u-mt-xl">
                        <List >
                            {RunCommand.map((item, index) => (
                                <ListItem key={index}>{item}</ListItem>
                            ))}
                        </List>
                    </div>
                </div>
            </WizardStep>
            {/* <WizardStep name="Review" id="wizard-step-4" footer={{ nextButtonText: 'Finish' }}> <p>Review step content</p> </WizardStep> */}
        </Wizard>
    )
}
// export default DeployAgent
const mapStateToProps = (state) => ({
    dates: { startDate: state.startdate, endDate: state.enddate },
    filterformdata: state.FilterData,
    groupsoption: state.Allgroups
    // mapStateToProps content remains unchanged if you have it
});

export default connect(
    mapStateToProps,
    null,
    null,
    { forwardRef: true }
)(DeployAgent)





// <Gallery hasGutter>
// <GalleryItem>
//     <Card isFullHeight>
//         <CardTitle align='center'><LinuxIcon /> LINUX </CardTitle>
//         <Divider inset={{ default: 'insetMd' }} />
//         <CardBody>
//             <Radio isChecked={checked === 'RPM amd64'} onChange={handleradioChange('RPM amd64')} id="RPM amd64" label="RPM amd64" name="radio-controlled" />
//             <Radio isChecked={checked === 'RPM aarch64'} onChange={handleradioChange('RPM aarch64')} id="RPM aarch64" label="RPM aarch64" name="radio-controlled" />
//             <Radio isChecked={checked === 'DEB amd64'} onChange={handleradioChange('DEB amd64')} id="DEB amd64" label="DEB amd64" name="radio-controlled" />
//             <Radio isChecked={checked === 'DEB aarch64'} onChange={handleradioChange('DEB aarch64')} id="DEB aarch64" label="DEB aarch64" name="radio-controlled" />
//         </CardBody>
//     </Card>
// </GalleryItem>
// <GalleryItem>
//     <Card isFullHeight>
//         <CardTitle align='center'><WindowsIcon /> WINDOWS</CardTitle>
//         <Divider inset={{ default: 'insetMd' }} />
//         <CardBody>
//             <Radio isChecked={checked === 'MSI 32/64 bits'} onChange={handleradioChange("MSI 32/64 bits")} id="MSI 32/64 bits" label="MSI 32/64 bits" name="radio-controlled" />
//         </CardBody>
//     </Card>
// </GalleryItem>
// <GalleryItem>
//     <Card isFullHeight>
//         <CardTitle align='center'> MAC OS</CardTitle>
//         <Divider inset={{ default: 'insetMd' }} />
//         <CardBody>
//             <Radio isChecked={checked === 'Intel'} onChange={handleradioChange('Intel')} id="Intel" label="Intel" name="radio-controlled" />
//             <Radio isChecked={checked === 'Apple Silicon'} onChange={handleradioChange('Apple Silicon')} id="Apple Silicon" label="Apple Silicon" name="radio-controlled" />
//         </CardBody>
//     </Card>
// </GalleryItem>

// </Gallery>