import React from 'react'
import { Table, Tbody, Th, Thead, Tr, Td } from '@patternfly/react-table';
import { formatTableTimestamp } from '../../util/Timeutils';
export const LatestScanProfile = ({ ProfileData }) => {

    const scancolumn = [
        "Policy",
        "End Scan",
        "Passed",
        "Failed",
        "Not applicable",
        "Score",
    ]
    return (
        <Table id="profile-log" variant='compact' >
            <Thead>
                <Tr key={'row-header-sca'}>
                    {scancolumn.map((column, index) => (<Th key={column + index + 'column'}>{column}</Th>))}
                </Tr>
            </Thead>
            <Tbody>
                {ProfileData.length > 0 && ProfileData.map((row, index) => {
                    const { formattedTimeStamp: formattedEndScan } = row?.end_scan ? formatTableTimestamp(row?.end_scan) : { formattedTimeStamp: null }
                    return (<Tr key={index + 'row'}>
                        <Td key={index + 'sca_name'} dataLabel={scancolumn[0]}><b>{row?.name}</b></Td>
                        <Td key={index + 'sca_esd'} dataLabel={scancolumn[1]}>{formattedEndScan}</Td>
                        <Td key={index + 'sca_pass'} dataLabel={scancolumn[2]}>{row?.pass}</Td>
                        <Td key={index + 'sca_fail'} dataLabel={scancolumn[3]}>{row?.fail}</Td>
                        <Td key={index + 'sca_inv'} dataLabel={scancolumn[4]}>{row?.invalid}</Td>
                        <Td key={index + 'sca_score'} dataLabel={scancolumn[5]}>{row?.score} %</Td>

                    </Tr>)
                }
                )}
            </Tbody>
        </Table>
    )
}
