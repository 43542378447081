import * as echarts from 'echarts';
import React, { useEffect } from 'react';
import { generateUniqueID } from '../../util/UniqueIdGenerator';
import { Emptystatefornodata } from '../../util/EmptyStateCard';
import { UpdateFilterData } from '../../util/FilterHelper';
import { FilterData } from '../../Redux/Action';
import { connect, useDispatch } from 'react-redux';
const TopCVE = (props) => {
  const id = generateUniqueID()
  const dispatch = useDispatch();
  const handleFilter = (fields, value) => {
    const updatedFields = UpdateFilterData(props.filterformdata, fields, 'is', value);
    dispatch(FilterData(updatedFields));
  }
  useEffect(() => {
    const chartDom = document.getElementById(id + 'edt');
    const myChart = echarts.init(chartDom);
    const textcolor = props.theme == 'dark-theme' ? '#fff' : '#000'
        // const bordercolor = props.theme == 'dark-theme' ? '' : '#fff'
    const option = {
      tooltip: {
        trigger: 'item',
        textStyle: {
          color: "#000"
        }
      },
      legend: {
        top: 'bottom',
        itemWidth: 9.5,
        itemHeight: 10,
        itemGap: 6.5,
        icon: "rect",
        tooltip: {
          trigger: 'item',
          show: true,
          position: [50, 30],
          textStyle: {
            color: "#000",
            fontSize: 12,
          }
        },
        textStyle: {
          width: 200,
          overflow: 'truncate',
          fontSize: 12,
          color:textcolor
        }
      },
      // color: props.color,
      color: ['#b30000', '#cc0000', '#e60000', '#ff1a1a', '#ff4d4d'],
      series: [
        {
          type: 'pie',
          left: 0,
          right: 0,
          top: 0,
          bottom: 80,
          radius: '60%',
          center: ['50%', '50%'],
          roseType: 'area',
          label: {
            color:textcolor
        },
          itemStyle: {
            borderRadius: 10,
          },
          data: props?.cveData.map((bucket) => ({
            value: bucket.total, // Use log scale for word size
            name: bucket.cve_title,
          })
          )
        }
      ]
    };

    option && myChart.setOption(option);
      myChart.on('legendselectchanged', function (param) {
        const Fields = props.filterFields
        handleFilter(Fields, param.name)
      });
      const resizeChart = () => {
        if (myChart) { // Check if chart exists before resizing
          myChart.resize();
        }
      };

    window.addEventListener('resize', resizeChart);
    return () => {
        myChart.dispose();
        window.removeEventListener('resize', resizeChart);
    };

  }, [props.height, props.cveData, props.filterFields,props?.SelectedItem,props.theme,props.sidebarState])

  return <> <div className="container">
    {props.cveData.length == 0 ?
      (<>
        <div id={id + 'edt'} style={{ width: '5px', height: '5px' }}> </div>
        < Emptystatefornodata />
      </>)
      : (
        <div id={id + 'edt'} style={{ width: '100%', height: props.height }} > </div>
      )
    }
  </div>
  </>

}
const mapStateToProps = (state) => ({
  dates: { startDate: state.startdate, endDate: state.enddate },
  filterformdata: state.FilterData,
  theme:state.theme,
  sidebarState:state.sidebarstate
  // mapStateToProps content remains unchanged if you have it
});

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(TopCVE)
