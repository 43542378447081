import { connect, useDispatch } from 'react-redux';
import { Emptystatefornodata } from '../../util/EmptyStateCard';
import { generateUniqueID } from '../../util/UniqueIdGenerator';
import * as echarts from 'echarts';
import React, { useEffect, useState } from 'react';
import { UpdateFilterData } from 'src/util/FilterHelper';
import { FilterData } from 'src/Redux/Action';
const TopConnection = (props) => {
  const id = generateUniqueID();
  const dispatch = useDispatch();
  const handleFilter = (fields, value) => {
    const updatedFields = UpdateFilterData(props.filterformdata, fields, 'is', value);
    dispatch(FilterData(updatedFields));
}
  useEffect(() => {
    const chartDom = document.getElementById(id + 'sankey-topconnection');
    const myChart = echarts.init(chartDom);
    const textcolor = props.theme == 'dark-theme' ? '#fff' : '#000'
    const option = {
      tooltip: {
        trigger: 'item',
        triggerOn: 'mousemove'
      },
      series: {
        type: 'sankey',
        left: "2%",
        top: props.topheight,
        right: "25%",
        bottom: "10%",
        lineStyle: {
          normal: {
            color: 'textcolor', // Color based on source node
            curveness: 0.5
          }

        },
        // lineStyle: {
        //   color: textcolor
        // },
        emphasis: {
          focus: 'adjacency',
        },

        data: props.ConnectionName.map(item => ({
          name: item,
          label: {
            color: textcolor
          }
        })),
        links: props.ConnectionlinkData.map(item => ({
          source: item.source,
          target: item.target,
          value: item.value,
          textstyle: {
            color: textcolor
          }

        })),

      }
    };

    option && myChart.setOption(option);
    myChart.on('click', function (params) {
      if (params.dataType === 'node') {
        const fullName = params.data.name;
        console.log('Node clicked:', params.data.name);
        const regex = /^([a-zA-Z_]+):-\s*(.+)$/;
        const match = fullName.match(regex);
        console.log(match[1])
        let Fields = ''
        if (match[1] == 'Srcip') {
          Fields = 'data.srcip'
        } else if (match[1] == 'Dst_IPS') {
          Fields = 'data.dstip'
        }
        else if (match[1] == 'Application') {
          Fields = 'data.service'
        }
        else if (match[1] == 'Proto') {
          Fields = 'data.proto'
        }
        handleFilter(Fields, match[2])
      }
    });
    const resizeChart = () => {
      if (myChart) { // Check if chart exists before resizing
        myChart.resize();
      }
    };

    window.addEventListener('resize', resizeChart);
    return () => {
      myChart.dispose();
      window.removeEventListener('resize', resizeChart);
    };
  }, [props.ConnectionlinkData, props.ConnectionName, props.theme, props.height, props.sidebarState]);


  return <> <div className="container">
    {props.ConnectionlinkData.length == 0 ?
      (<>
        <div id={id + 'sankey-topconnection'} style={{ width: '1px', height: '1px' }}> </div>
        < Emptystatefornodata />
      </>)
      : (
        <div id={id + 'sankey-topconnection'} style={{ width: '100%', height: props.height }} > </div>
      )
    }
  </div>
  </>
}

const mapStateToProps = (state) => ({
  dates: { startDate: state.startdate, endDate: state.enddate },
  filterformdata: state.FilterData,
  theme: state.theme,
  sidebarState: state.sidebarstate
  // mapStateToProps content remains unchanged if you have it
});

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(TopConnection)

