
import React, { useEffect } from 'react';
import * as echarts from 'echarts';
import 'echarts-wordcloud';
import { generateUniqueID } from '../../util/UniqueIdGenerator';
import { Emptystatefornodata } from '../../util/EmptyStateCard';
import { connect, useDispatch } from 'react-redux';
import { FilterData } from '../../Redux/Action';
import { UpdateFilterData } from '../../util/FilterHelper';

//  const NetworkUserIP = ({props,ref,cloudseries, height, filterFields }) => {
const NetworkUserIP = (props) => {
    const id = generateUniqueID()
    const dispatch = useDispatch();
    const handleFilter = (fields, value) => {
        const updatedFields = UpdateFilterData(props.filterformdata, fields, 'is', value);
        dispatch(FilterData(updatedFields));
    }
    useEffect(() => {
        const chartDom = document.getElementById(id + 'nuip');
        const myChart = echarts.init(chartDom);
        const textcolor = props?.theme == 'dark-theme' ? '#fff' : '#000'
        const option = {
            layoutAnimation: false,
            title: [
                {
                    text: props?.title ? props?.title : null,
                    textStyle: { color: textcolor }
                }
            ],
            tooltip: {
                trigger: 'item',
                position: [20, 20],
                formatter: function (params) {
                    return params.data.name + `(${params.data.nestedkey})` + ' : ' + params.value
                },
            },
            series: [
                {
                    textStyle: {
                        color: function (params) {
                            const color = params.data.isTip ? '#7D1007' : (
                            props?.theme == 'dark-theme'?
                                `rgb(${[Math.round(100 + Math.random() * 150), Math.round(100 + Math.random() * 150), Math.round(100 + Math.random() * 150)].join(',')})` :
                                 `rgb(${[Math.round(Math.random() * 160), Math.round(Math.random() * 160), Math.round(Math.random() * 160)].join(',')})`
                            )
                            return color;
                        }

                    },
                    emphasis: {
                        focus: 'self',

                        textStyle: {
                            textShadowBlur: 5,
                            textShadowColor: '#333'
                        }
                    },
                    type: 'wordCloud',
                    gridSize: 2,
                    keepAspect: false,
                    sizeRange: [12, 60],
                    shape: 'Star',
                    rotationRange: [-90, 90],
                    // rotationStep: 45,

                    left: 'center',
                    top: 'center',
                    width: '100%',
                    // height: '100%',
                    right: null,
                    bottom: null,
                    drawOutOfBound: false,
                    shrinkToFit: true,
                    // roam: true,
                    data: props.cloudseries.map((bucket) => {
                        const isTip = bucket.category.some(cat => cat.key == 'tip');
                        const mappedData = {
                            value: bucket.doc_count, // Use log scale for word size
                            name: bucket.key,
                            nestedkey: bucket.type,
                            nestedkey1: bucket.field,
                            isTip
                        }; 
                        return mappedData;
                    }
                    ),
                },
            ],
        };


        // Set chart options
        option.animationDuration = 0;
        option && myChart.setOption(option);
        myChart.on('click', 'series.wordCloud', function (param) {
            const Fields = param.data.nestedkey1
            handleFilter(Fields, param.data.name)

        });
        const resizeChart = () => {
            if (myChart) { // Check if chart exists before resizing
              myChart.resize();
            }
          };

        window.addEventListener('resize', resizeChart);
        return () => {
            myChart.dispose();
            window.removeEventListener('resize', resizeChart);
        };
    }, [props.cloudseries, props.theme,props.sidebarState]);


    return <div className="container">
        {/* <div id={id + 'nuip'} style={{ width: '100%', height: props.height }} > </div> */}
        {props.cloudseries.length == 0 ?
            (<>
                <div id={id + 'nuip'} style={{ width: '1px', height: '1px' }}> </div>
                < Emptystatefornodata />
            </>)
            : (
                <div id={id + 'nuip'} style={{ width: '100%', height: props.height }} > </div>
            )
        }
    </div>
}
const mapStateToProps = (state) => ({
    dates: { startDate: state.startdate, endDate: state.enddate },
    filterformdata: state.FilterData,
    theme: state.theme,
    sidebarState:state.sidebarstate
    // mapStateToProps content remains unchanged if you have it
});

export default connect(
    mapStateToProps,
    null,
    null,
    { forwardRef: true }
)(NetworkUserIP)


