//2nd which is perfectly run and usable
import React, { useEffect, useState } from "react";
import {
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Table,
} from "@patternfly/react-table";
import {
  Badge,
  MenuToggle,
  Pagination,
  Select,
  SelectOption,
  SelectList,
  SelectGroup,
  Toolbar,
  ToolbarContent,
  ToolbarItem,
  LabelGroup,
  Label,
  Modal,
  ModalVariant,
  Flex,
  FlexItem,
  Banner,
  Alert,
  Bullseye,
  EmptyState,
  EmptyStateHeader,
  EmptyStateIcon,
  EmptyStateBody
} from "@patternfly/react-core";
import dbApi from '../../services/DbApi'
import { connect, useDispatch } from "react-redux";
import SortAmountDownIcon from '@patternfly/react-icons/dist/esm/icons/sort-amount-down-icon';
import { Loader } from "src/util/Loadingstate";
import { getBorderColor, UpdateFilterData } from "src/util/FilterHelper";
import PocTableData from "./PocTableData";
import { SearchIcon } from "@patternfly/react-icons";
import { FilterData } from "src/Redux/Action";
const ExploitableCve = (props) => {

  const defaultColumns = {
    data_vulnerability_cve: "CVE Name",
    rule_description: "Description",
    agent_name: "Affected Agents",
    poc: "PoC Link Count",
  };

  const dispatch=useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [columns, setColumns] = useState(defaultColumns);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(props.InModal ? 20 : 5);
  const [selectedRowName, setSelectedRowName] = useState('');
  const [activeSortIndex, setActiveSortIndex] = useState(0);
  const [activeSortDirection, setActiveSortDirection] = useState('asc');
  const [isSortDropdownOpen, setIsSortDropdownOpen] = useState(false);
  const [totalItem, setTotalItem] = useState(0)


  const [pocModalopen, setPocModalOpen] = useState(false);
  const [selectedCve, setSelectedCve] = useState('')
  const [cveDesc, setCveDesc] = useState('')
  const [selectedCveseverity, setSelectedCveseverity] = useState('')
  const [selectedCvePoC, setSelectedCvePoc] = useState([])

  const handleModalToggle = (_event, cve, severity, poc, desc) => {
    setPocModalOpen(!pocModalopen);
    setSelectedCve(cve)
    setSelectedCveseverity(severity)
    setCveDesc(desc)
    setSelectedCvePoc(poc)

  };
  const getExploitablePoc = async (data) => {
    let param = {
      ...data,
      limitStart: (perPage * page) - perPage,
      limitLength: perPage,
      sortByCol: Object.keys(columns)[activeSortIndex],
      orderType: activeSortDirection,
    }
    try {
      setIsLoading(true)
      let res = await dbApi.postApi("Opensearch/get_poc_list", param)
      console.log(res)
      let allpoc = []
      if (res.data.data.length > 0) {
        allpoc = res.data.data
        setRows(allpoc)
        setTotalItem(res.data.totalRecord)
        props.TotalPoC(res.data.totalRecord)
      }
      else {
        setRows([])
        setTotalItem(0)
        props.TotalPoC(0)
      }
      setIsLoading(false)
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }
  }

  const handleSetPage = (_evt, newPage) => {
    setPage(newPage);
  };
  const handlePerPageSelect = (_evt, newPerPage, newPage) => {
    setPage(newPage);
    setPerPage(newPerPage);
  };

  useEffect(() => {
    const Fields = props.filterformdata
    const data = {
      startDate: props.dates.startDate,
      endDate: props.dates.endDate,
      filter: Fields.length > 0 ? JSON.stringify(Fields) : "[]",
    };
    if (props.dates.startDate != "") {
      getExploitablePoc(data)
    }
  }, [props?.dates.startDate, props?.dates.endDate, props?.filterformdata,
    page, perPage, activeSortDirection, activeSortIndex,]);

  const onSort = (event, index, direction) => {
    setActiveSortIndex(index);
    setActiveSortDirection(direction);
  };
  const renderPagination = (variant) =>
    <Pagination
      itemCount={totalItem}
      page={page}
      perPage={perPage}
      onSetPage={handleSetPage}
      onPerPageSelect={handlePerPageSelect}
      variant={variant}
    />
    const handleFilter = (fields, value) => {
      const updatedFields = UpdateFilterData(props.filterformdata, fields, 'is', value);
      dispatch(FilterData(updatedFields));
  }
  const tableToolbar = <>
    <Toolbar id="sortable-toolbar">
      <ToolbarContent>
        <ToolbarItem key={'sorting'} visibility={{ md: 'hidden' }}>
          <Select isOpen={isSortDropdownOpen} selected={[activeSortDirection, activeSortIndex]} onOpenChange={isOpen => setIsSortDropdownOpen(isOpen)} onSelect={(event, value) => {
            if (value === 'asc' || value === 'desc') {
              onSort(event, activeSortIndex, value);
            } else {
              onSort(event, value, activeSortDirection !== 'none' ? activeSortDirection : 'asc');
            }
          }} toggle={toggleRef => <MenuToggle ref={toggleRef} onClick={() => setIsSortDropdownOpen(!isSortDropdownOpen)} isExpanded={isSortDropdownOpen} variant="plain">
            <SortAmountDownIcon />
          </MenuToggle>}>
            <SelectGroup label="Sort column">
              <SelectList>
                {Object.keys(columns).map((column, columnIndex) => <SelectOption key={column} value={columnIndex} isSelected={activeSortIndex === columnIndex}>
                  {column}
                </SelectOption>)}
              </SelectList>
            </SelectGroup>
            <SelectGroup label="Sort direction">
              <SelectList>
                <SelectOption isSelected={activeSortDirection === 'asc'} value="asc" key="ascending">
                  Ascending
                </SelectOption>
                <SelectOption isSelected={activeSortDirection === 'desc'} value="desc" key="descending">
                  Descending
                </SelectOption>
              </SelectList>
            </SelectGroup>
          </Select>
        </ToolbarItem>
        <ToolbarItem align={{ default: 'alignRight' }} key={'pagination'}>
          {renderPagination()}
        </ToolbarItem>
      </ToolbarContent>
    </Toolbar>
  </>

  const renderSeveritycolumn = (severity) => {
    let badgeStyle = {};
    if (severity == "High") {
      badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#C9190B" };
    }
    else if (severity == "Critical") {
      badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#A30000 " };
    }
    else if (severity == "Medium") {
      badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#F0AB00 " };
    }
    else if (severity == "Low") {
      badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#0066CC " };
    }
    return (
      <div> <Badge style={badgeStyle} className='pf-v5-u-color-light-100'>{severity}</Badge></div>
    );
  };
  const PoCModal = <React.Fragment>
    <Modal
      title={<>

        <Flex spaceItems={{ default: 'spaceItemsSm' }} display={{ default: 'inlineFlex' }} alignItems={{ default: 'alignItemsCenter' }}>
          <FlexItem> {selectedCve}</FlexItem>
          <FlexItem>{renderSeveritycolumn(selectedCveseverity)}</FlexItem>
        </Flex></>}
      isOpen={pocModalopen}
      onClose={handleModalToggle}
      variant={ModalVariant.large}
      description={cveDesc}
    >

      <PocTableData Tabledata={selectedCvePoC} columnData={["PoC URL", "Description"]} />
    </Modal>
  </React.Fragment>
  return (
    <React.Fragment>
      {PoCModal}
      <>
        {isLoading ? <Loader /> :
          <>
            {props.InModal ? tableToolbar : null}
            <Table variant="compact" aria-label="userlog" id="user-log-table" isStickyHeader>
              <Thead>
                <Tr>
                  {Object.values(columns).map((column) => (
                    <Th key={column}>{column}</Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                {rows.length > 0 ?
                  rows && rows.map((row, rowIndex) => {
                    const PoCData = row?.poc ? JSON.parse(row?.poc) : null;
                    return (
                      <Tr key={rowIndex}
                        onRowClick={() => setSelectedRowName(rowIndex)}
                        isSelectable
                        isClickable
                        style={{ borderLeft: getBorderColor(row?.data_vulnerability_severity) }}
                        isRowSelected={selectedRowName === rowIndex}  >
                        <Td width={20} dataLabel={'CVE Name'}><a className='pf-v5-u-color-100' onClick={() => { handleFilter('data.vulnerability.cve', row?.data_vulnerability_cve) }}>{row?.data_vulnerability_cve}</a></Td>
                        <Td width={40} dataLabel={'Description'}>  {row?.rule_description}</Td>
                        <Td width={30} dataLabel={'Affected assets'}>
                          {row?.agent_names !== undefined && row?.agent_names !== null ?
                            (<LabelGroup numLabels={2} isCompact>
                              {row?.agent_names.split(',').map((item, index) => (
                                <Label key={"label" + index} color='blue' isCompact>{item}</Label>
                              ))}
                            </LabelGroup>) : null}
                        </Td>
                        <Td width={10} dataLabel={'PoC Link Count'}>
                          <a onClick={(event) => handleModalToggle(event, row?.data_vulnerability_cve, row?.data_vulnerability_severity, PoCData, row?.rule_description)}>
                            {PoCData == null ? '' : PoCData.length}</a></Td>
                      </Tr>
                    )
                  })
                  :
                  <Tr key='empty-row'>
                    <Td colSpan={10} key='empty-data'>
                      <Bullseye>
                        <EmptyState>
                          <EmptyStateHeader
                            titleText="No Data"
                            icon={<EmptyStateIcon icon={SearchIcon} />}
                            headingLevel="h2"
                          />
                          <EmptyStateBody>
                           PoC is not available for this time frame.
                          </EmptyStateBody>
                        </EmptyState>
                      </Bullseye>
                    </Td>
                  </Tr>
                }
              </Tbody>

            </Table>
            {props.InModal && renderPagination('bottom', true)}
          </>
        }
      </>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  dates: { startDate: state.startdate, endDate: state.enddate, selectedtime: state.Term },
  filterformdata: state.FilterData,
  selectedGroup: state.SelectedGroup,

  // mapStateToProps content remains unchanged if you have it
});

const mapDispatchToProps = (dispatch) => { return {} }

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(ExploitableCve)