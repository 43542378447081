import React, { useEffect, useState, forwardRef, useRef, useMemo } from 'react'
import {
    Card,
    CardBody,
    CardTitle,
    Divider,
    Grid,
    GridItem,
    CardFooter,
    Flex,
    Button,
    Modal,
    ModalVariant,
    CardHeader,
    Bullseye,
    EmptyState,
    EmptyStateHeader,
    EmptyStateBody,
    EmptyStateIcon,
    Tabs,
    Tab,
    TabTitleText,
    SelectList,
    Select,
    SelectOption,
    MenuToggle,
    TabContentBody,
} from '@patternfly/react-core'
import GeoMap from './GeoMap'
import dbApi from '../../services/DbApi'
import { ExternalIP } from './ExternalIp'
import TopConnection from './TopConnectionData'
import ConnectionSummary from './ConnectionSummary'
import TopProtoandApp from './TopProtoandApp'
import AlertsAnamolyLog from './AlertsAnomalyLog'
import TopSignatures from './Topsignatures'
import TableViewForChart from '../AllCharts/TableViewForChart'
import EWordCloud from '../AllCharts/EWordCloud'
import NetInBarChart from './ENIBarChart'
import { Emptystatefornodata } from '../../util/EmptyStateCard'
import { connect, useDispatch } from 'react-redux'
import { Loader } from '../../util/Loadingstate'


const NetworkInsightDashboard = forwardRef(function NetworkInsightDashboard(props, ref) {
    const childAnomalyalertsRef = useRef(null);
    const dispatch = useDispatch()
    // const [startDate, setStartDate] = useState()
    // const [endDate, setEndDate] = useState()
    const [isOpen, setIsOpen] = React.useState(false);
    const [selected, setSelected] = React.useState('10');
    const [domainurldata, setDomainUrldata] = useState([]);
    const [countryseries, setCountrySeries] = useState([]);
    const [cityseries, setCitySeries] = useState([]);
    const [protoseries, setProtoSeries] = useState([]);
    const [sourceseries, setSourceseries] = useState([]);
    const [destinationseries, setDestinationseries] = useState([]);
    const [appseries, setAppseries] = useState([]);
    const [connectionseries, setConnectionSeries] = useState([]);
    const [moreconnectionseries, setMoreConnectionSeries] = useState([]);
    const [connectionname, setConnectionName] = useState([]);
    const [moreconnectionname, setMoreConnectionName] = useState([]);
    const [connectionsummary, setConnectionSummary] = useState([]);
    const [moreconnectionsummary, setMoreConnectionSummary] = useState([]);
    const [externalIP, setExternalIP] = useState([]);
    const [signatures, setSignatures] = useState([]);
    const [anamolyalertslog, setAnamolyAlertslog] = useState([])
    const [anamolyscrollID, setAnamolyScrollID] = useState('')

    //Loading States
    const [isloadMap, setLoadMap] = useState(false)
    const [isloadDomain, setIsLoadDomain] = useState(false)
    const [isloadcity, setIsLoadCity] = useState(false)
    const [isloadexternalIp, setIsloadexternalIp] = useState(false)
    const [isloadConnection, setIsloadConnection] = useState(false)
    const [isloadSource, setIsLoadSource] = useState(true)
    const [isloadDest, setIsloadDest] = useState(true)
    const [isloadProto, setIsLoadProto] = useState(false)
    const [isLoadApp, setIsLoadApp] = useState(false)
    const [isLoadSignature, setIsLoadSignature] = useState(false)
    const [isLoadAnamoly, setIsLoadAnamoly] = useState(false)
    const [modalStates, setModalStates] = useState({
        modalCountries: false,
        modalCities: false,
        modaldomainurl: false,
        modalSourceIP: false,
        modalDestinationIP: false,
        modalProto: false,
        modalApp: false,
        modalExternalIP: false,
        modalConnSummary: false,
        modalTopConnection: false,
        modalexternalIP: false,
        modalsignatures: false,
        modalTableChart: false,
        modalAnomalyalertss: false,

    });
    const [activeTabKey, setActiveTabKey] = useState(0);
    const handleModalToggle = (modalName) => {
        setModalStates((prevStates) => ({
            ...prevStates,
            [modalName]: !prevStates[modalName],
        }));
    };
    //Tab function
    const handleTabClick = (event, tabIndex) => {
        setActiveTabKey(tabIndex);
    };
    //Api Call for domain and url
    const getDomainandUrl = async (data) => {
        try {
            setIsLoadDomain(true)
            let alldata = await dbApi.postApi("Opensearch/Network_Top_url", data)
            console.log(alldata)
            let DomainUrl = []
            if (Object.keys(alldata.Network_Top_url).length !== 0) {
                let all_data = alldata.Network_Top_url.body.aggregations.Top_Url
                DomainUrl = all_data.buckets
                setDomainUrldata(DomainUrl)
            }
            else {
                setDomainUrldata(DomainUrl)
            }
            setIsLoadDomain(false)
        } catch (error) {
            console.log(error)
            setDomainUrldata([])
            setIsLoadDomain(false)
        }
    }
    //API Call for TOP COUNTRIES
    const getTopCountry = async (data) => {
        try {
            setLoadMap(true)
            let AllCountry = await dbApi.postApi("Opensearch/Network_Top_country", data);
            console.log(AllCountry);
            let CountrySeries = []
            if (Object.keys(AllCountry.Network_Top_country).length !== 0) {
                let All_Country = AllCountry.Network_Top_country.body.aggregations.Top_Country;
                // if (All_Country.buckets.length > 0) {
                CountrySeries = All_Country.buckets
                setCountrySeries(CountrySeries)
                // }
            }
            else {
                setCountrySeries(CountrySeries)
            }
            setLoadMap(false)
        }
        catch (error) {
            console.log(error)
            setCountrySeries([])
            setLoadMap(false)
        }
    }
    //API Call for TOP CITIES
    const getTopCity = async (data) => {
        try {
            setIsLoadCity(true)
            let AllCity = await dbApi.postApi("Opensearch/Network_Top_City", data);
            console.log(AllCity);
            let CitySeries = []
            if (Object.keys(AllCity.Network_Top_City).length !== 0) {
                let All_City = AllCity.Network_Top_City.body.aggregations.Top_City;
                CitySeries = All_City.buckets
                setCitySeries(CitySeries)
            }
            else {
                setCitySeries(CitySeries)
            }
            setIsLoadCity(false)
        }
        catch (error) {
            console.log(error)
            setIsLoadCity(false)
            setCitySeries([])
        }
    }
    //API Call for TOP PROTO
    const getTopProto = async (data) => {
        try {
            setIsLoadProto(true)
            let AllProto = await dbApi.postApi("Opensearch/Network_Proto", data);
            console.log(AllProto);
            let ProtoSeries = []
            if (AllProto.Network_Proto.length > 0) {
                ProtoSeries = AllProto.Network_Proto
                setProtoSeries(ProtoSeries)
            }
            else {
                setProtoSeries(ProtoSeries)
            }
            setIsLoadProto(false)
        }
        catch (error) {
            console.log(error)
            setIsLoadProto(false)
            setProtoSeries([])
        }
    }
    //API Call for TOP Source IP
    const getTopSrc = async (data) => {
        try {
            setIsLoadSource(true)
            let AllSrc = await dbApi.postApi("Opensearch/Network_SrcIP", data);
            console.log(AllSrc);
            let All_Src_IP = AllSrc.Network_SrcIP;
            let SourceSeries = []
            if (All_Src_IP.length > 0) {
                SourceSeries = All_Src_IP
                setSourceseries(SourceSeries)
            }
            else {
                setSourceseries(SourceSeries)
            }
            setIsLoadSource(false)
        }
        catch (error) {
            console.log(error)
            setIsLoadSource(false)
            setSourceseries([])
        }
    }
    //API Call for TOP Destination IP
    const getTopDestination = async (data) => {
        try {
            setIsloadDest(true)
            let AllDst = await dbApi.postApi("Opensearch/Network_Destination_IP", data);
            console.log(AllDst);
            let All_Dst = AllDst.Network_Destination_IP;
            let DestinationSeries = []
            if (All_Dst.length > 0) {
                DestinationSeries = All_Dst
                setDestinationseries(DestinationSeries)
            }
            else {
                setDestinationseries(DestinationSeries)
            }
            setIsloadDest(false)
        }
        catch (error) {
            console.log(error)
            setIsloadDest(false)
            setDestinationseries([])
        }
    }
    //API Call for TOP Application
    const getTopApplication = async (data) => {
        try {
            setIsLoadApp(true)
            let AllApp = await dbApi.postApi("Opensearch/Network_Top_Application", data);
            console.log(AllApp);
            let AppSeries = []
            if (Object.keys(AllApp.Network_Top_Application).length !== 0) {
                let All_App = AllApp.Network_Top_Application.body.aggregations.Top_Application;
                AppSeries = All_App.buckets
                setAppseries(AppSeries)
            }
            else {
                setAppseries(AppSeries)
            }
            setIsLoadApp(false)
        }
        catch (error) {
            console.log(error)
            setAppseries([])
            setIsLoadApp(false)
        }
    }
    //API Call for TOP External IP
    const getExternalIP = async (data) => {
        try {
            setIsloadexternalIp(true)
            let AllIP = await dbApi.postApi("Opensearch/Network_Top_External_IP", data);
            console.log(AllIP);
            let ExternalIPLog = []
            if (Object.keys(AllIP.Network_Top_External_IP).length !== 0) {
                let All_IP = AllIP.Network_Top_External_IP.PublicIP;
                const defaultRows = All_IP.map((log) => {
                    const IpAddress = log.country_code ? `${log.key} (${log.country_code})` : log.key;
                    const IPcount = log.country_code ? log.country_count : log.doc_count;
                    const countryCode = log.country_code ? log.country_code : '';
                    return {
                        externalIP: IpAddress,
                        count: IPcount,
                        nextapiKey: log.key,
                        countrycode: countryCode,
                    };
                });
                ExternalIPLog = defaultRows

                setExternalIP(ExternalIPLog)
            }
            else {
                setExternalIP(ExternalIPLog)
            }
            setIsloadexternalIp(false)
        }
        catch (error) {
            console.log(error)
            setExternalIP([])
            setIsloadexternalIp(false)
        }
    }

    //API for Top Connection
    const getTopConnection = async (data, selected) => {
        try {
            setIsloadConnection(true)
            let AllConnection = await dbApi.postApi("Opensearch/Network_Top_Connection", data);
            console.log(AllConnection);
            if (Object.keys(AllConnection.Network_More_Connection_Chart).length !== 0) {
                // let All_ConnLink = AllConnection.Network_More_Connection_Chart.More_Connection_Data;
                // let All_ConnName = AllConnection.Network_More_Connection_Chart.More_Connection_Name;
                // let Top_connection_Name = AllConnection.Network_Top_Connection_Chart.Top_Connection_Name;
                // let Top_connection_link = AllConnection.Network_Top_Connection_Chart.Top_Connection_Data
                let Connection_Summary = AllConnection.Network_Connection_Summary.Table;
                // let ConnectionSeries = []
                // let ConnectionName = []
                // let MoreConnectionSeries = []
                // let MoreConnectionName = []
                let ConnectionSummary = []
                // if (Top_connection_Name.length > 0) {
                //     ConnectionName = Top_connection_Name
                // }
                // if (Top_connection_link.length > 0) {
                //     ConnectionSeries = Top_connection_link
                // }
                // if (All_ConnLink.length > 0) {
                //     MoreConnectionSeries = All_ConnLink
                // }
                // if (All_ConnName.length > 0) {
                //     MoreConnectionName = All_ConnName
                // }
                if (Connection_Summary.length > 0) {
                    ConnectionSummary = Connection_Summary

                }
                // setConnectionSeries(ConnectionSeries)
                // setConnectionName(ConnectionName)
                // setMoreConnectionSeries(MoreConnectionSeries)
                // setMoreConnectionName(MoreConnectionName)
                setConnectionSummary(ConnectionSummary)
                setMoreConnectionSummary(moreconnectionsummary)
            }
            else {
                // setConnectionSeries([])
                // setConnectionName([])
                // setMoreConnectionSeries([])
                // setMoreConnectionName([])
                setConnectionSummary([])
                setMoreConnectionSummary([])
            }
            setIsloadConnection(false)
        }
        catch (error) {
            console.log(error)
            // setConnectionSeries([])
            // setConnectionName([])
            // setMoreConnectionSeries([])
            // setMoreConnectionName([])
            setConnectionSummary([])
            setMoreConnectionSummary([])
            setIsloadConnection(false)
        }
    }

    const getNewTopConnection = async (data) => {
        try {
            setIsloadConnection(true)
            let res = await dbApi.postApi("Opensearch/New_Network_Top_Connection", data)
            console.log(res)
            let ConnectionSeries = []
            let ConnectionName = []
            let MoreConnectionSeries = []
                let MoreConnectionName = []
            if (Object.keys(res.New_Network_Top_Connection).length !== 0) {
                let Top_connection_Name = res.New_Network_Top_Connection.Connection_Name;
                let Top_connection_link = res.New_Network_Top_Connection.Connection_Data;
                let More_ConnLink = res.New_Network_More_Connection.Connection_Data_More;
                let More_ConnName = res.New_Network_More_Connection.Connection_Name_More;
                if (Top_connection_Name.length > 0) {
                    ConnectionName = Top_connection_Name
                }
                if (Top_connection_link.length > 0) {
                    ConnectionSeries = Top_connection_link
                }
                if (More_ConnLink.length > 0) {
                    MoreConnectionSeries = More_ConnLink
                }
                if (More_ConnName.length > 0) {
                    MoreConnectionName = More_ConnName
                }
                setConnectionSeries(ConnectionSeries)
                setConnectionName(ConnectionName)
                setMoreConnectionSeries(MoreConnectionSeries)
                setMoreConnectionName(MoreConnectionName)
            }
            else {
                setConnectionSeries([])
                setConnectionName([])
                setMoreConnectionSeries([])
                setMoreConnectionName([])
            }
            setIsloadConnection(false)
        } catch (error) {
            console.log(error)
            setIsloadConnection(false)
            setConnectionSeries([])
            setConnectionName([])
            setMoreConnectionSeries([])
                setMoreConnectionName([])
        }
    }
    //API for Anomaly Alerts
    const getAnamolyAlerts = async (data) => {
        try {
            setIsLoadAnamoly(true)
            let Allalerts = await dbApi.postApi("Opensearch/Network_Anamoly_Alerts", data);
            console.log(Allalerts)
            let AnamolyAlertsLog = [];
            let ScrollID = ''
            if (Object.keys(Allalerts.Network_Anamoly_Alerts).length !== 0) {
                let All_alerts = Allalerts.Network_Anamoly_Alerts.body.hits
                let ScrollID = Allalerts.Network_Anamoly_Alerts.body._scroll_id
                AnamolyAlertsLog = All_alerts.hits;
                ScrollID = ScrollID

                setAnamolyAlertslog(AnamolyAlertsLog)
                setAnamolyScrollID(ScrollID)
            }
            else {
                setAnamolyAlertslog(AnamolyAlertsLog)
                setAnamolyScrollID(ScrollID)
            }
            setIsLoadAnamoly(false)

        } catch (error) {
            console.log(error)
            setAnamolyAlertslog([])
            setAnamolyScrollID('')
            setIsLoadAnamoly(false)
        }
    }
    useEffect(() => {
        if (childAnomalyalertsRef.current) {
            childAnomalyalertsRef.current.setAnomalyAlerts(anamolyalertslog, anamolyscrollID, modalStates.modalAnomalyalertss);
        }
    }, [anamolyalertslog, anamolyscrollID, modalStates.modalAnomalyalertss]);

    const setAnamolyAlertsData = () => {
        let data = anamolyalertslog
        setAnamolyAlertslog([])
        setTimeout(function () { setAnamolyAlertslog(data) }, 100);
    }
    //API for top signatures
    const getTopSignatures = async (data) => {
        try {
            setIsLoadSignature(true)
            let allsign = await dbApi.postApi("Opensearch/Network_Top_Signature", data)
            console.log(allsign)
            let Signatures = []
            if (Object.keys(allsign.Network_Top_Signature).length !== 0) {
                let All_Sign = allsign.Network_Top_Signature.body.aggregations.Top_Signature.buckets;
                if (All_Sign.length > 0) {
                    const defaultRows = All_Sign.flatMap((log) => {
                        return log.Severity.buckets.map((severity) => {
                            return {
                                signatures: log.key,
                                count: log.doc_count,
                                severity: severity.key
                            };
                        })

                    });
                    Signatures = defaultRows
                }
                setSignatures(Signatures)
            }
            else {
                setSignatures(Signatures)
            }
            setIsLoadSignature(false)
        } catch (error) {
            console.log(error)
            setSignatures([])
            setIsLoadSignature(false)
        }
    }


    const NetworkInsightDashboardApi = async (startDate, endDate, allFields) => {
        const Fields = allFields
        const data = {
            startDate: startDate,
            endDate: endDate,
            filter: JSON.stringify(Fields),
        };
        try {
            // setStartDate(startDate);
            // setEndDate(endDate)
            getDomainandUrl(data);
            getTopCountry(data);
            getTopCity(data);
            getTopProto(data);
            getTopSrc(data);
            getTopDestination(data);
            getTopApplication(data);
            getTopConnection(data, '10');
            getNewTopConnection(data)
            getExternalIP(data);
            getAnamolyAlerts(data);
            getTopSignatures(data);
            console.log(data)
        }
        catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (props?.dates.startDate != "") {
            let Dates = props.dates
            NetworkInsightDashboardApi(Dates.startDate, Dates.endDate, props.filterformdata)
        }
    }, [props?.dates.startDate, props?.dates.endDate, props?.filterformdata,]);

    const onSelect = (_event, value) => {
        setSelected(value);
        setIsOpen(false);
    };
    const onToggleClick = () => {
        setIsOpen(!isOpen);
    };
    const toggle = toggleRef =>
        <MenuToggle ref={toggleRef} onClick={onToggleClick}
            isExpanded={isOpen}> {selected}</MenuToggle>

    const selectItems = <SelectList>
        <SelectOption key="option1" value="10" > 10 Source IPs </SelectOption>
        <SelectOption key="option2" value="20"> 20 Source IPs</SelectOption>
        <SelectOption key="option3" value="30"> 30 Source IPs</SelectOption>
        <SelectOption key="option4" value="40"> 40 Source IPs</SelectOption>
        <SelectOption key="option5" value="50"> 50 Source IPs</SelectOption>
    </SelectList>;
    const headerActionsTopConnection =
        <Select id="single-select" isOpen={isOpen}
            selected={selected}
            onSelect={onSelect}
            onOpenChange={isOpen =>
                setIsOpen(!isOpen)
            }
            toggle={toggle} shouldFocusToggleOnSelect
        >
            {selectItems}
        </Select>


    const DomainURL = useMemo(() => {
        return (
            isloadDomain ? <Loader /> : domainurldata.length > 0 ?
                <EWordCloud cloudseries={domainurldata.slice(0, 50)} height={300} filterFields={'data.query'} />
                :
                <Emptystatefornodata />
        )
    }, [isloadDomain, domainurldata])

    const TopCountries = useMemo(() => {
        return (
            isloadMap ? <Loader /> :
                <NetInBarChart TopData={countryseries.slice(0, 5)} height={200} filterFields={'GeoLocation.country_name'} />
        )
    }, [isloadMap, countryseries])

    const TopCities = useMemo(() => {
        return (
            isloadcity ? <Loader /> :
                <NetInBarChart TopData={cityseries.slice(0, 5)} height={200} filterFields={'GeoLocation.city_name'} />
        )
    }, [isloadcity, cityseries])

    const TopExternalIP = useMemo(() => {
        return (
            isloadexternalIp ? <Loader /> :
                <ExternalIP ExternalIPData={externalIP.slice(0, 5)} Pagination={false} />
        )
    }, [isloadexternalIp, externalIP])

    const TopConnectionChart = useMemo(() => {
        return (
            isloadConnection ? <Loader /> :
                <TopConnection ConnectionlinkData={connectionseries} ConnectionName={connectionname} height={400} topheight={'10%'} />
        )
    }, [isloadConnection, connectionseries, connectionname])


    const ConnectionSummaryTable = useMemo(() => {
        return (
            isloadConnection ? <Loader /> :
                <ConnectionSummary Tablelog={connectionsummary.slice(0, 8)} />
        )
    }, [isloadConnection, connectionsummary])

    const TopSourceIP = useMemo(() => {
        return (
            isloadSource ? <Loader /> :
                <NetInBarChart TopData={sourceseries.slice(0, 5)} height={200} />
        )
    }, [isloadSource, sourceseries])

    const TopDestinationIP = useMemo(() => {
        return (
            isloadDest ? <Loader /> :
                <NetInBarChart TopData={destinationseries.slice(0, 5)} height={200} />
        )
    }, [isloadDest, destinationseries])

    const TopProtocols = useMemo(() => {
        return (
            isloadProto ? <Loader /> :
                <TopProtoandApp TopData={protoseries.slice(0, 10)} height={210} Name={'Top Protocols'} filterFields={'data.protocol'} filterformdata={props?.filterformdata} />
        )
    }, [isloadProto, protoseries, props?.filterformdata])

    const TopApplication = useMemo(() => {
        return (
            isLoadApp ? <Loader /> :
                <TopProtoandApp TopData={appseries.slice(0, 10)} height={210} Name={'Top Applications'} filterFields={'data.service'} filterformdata={props?.filterformdata} />
        )
    }, [isLoadApp, appseries, props?.filterformdata])


    return (<>
        <Grid hasGutter>
            <GridItem md={5} lg={6} sm={12}>
                <Card isFullHeight >
                    <CardTitle >Geo Map</CardTitle>
                    <Divider />
                    <CardBody> {
                        isloadMap ? <Loader /> :
                            countryseries.length == 0 ? <Emptystatefornodata /> :
                                <GeoMap TopLocation={countryseries} />} </CardBody>
                </Card>
            </GridItem >
            <GridItem md={7} lg={6} sm={12}>
                <Card isFullHeight>
                    <CardTitle > Top Domains and URLs</CardTitle>
                    <Divider />
                    <CardBody>{DomainURL}</CardBody>
                    {domainurldata.length > 0 && <CardFooter>
                        <Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
                            <Button variant="link" onClick={() => { setActiveTabKey(0); handleModalToggle('modaldomainurl'); }}>
                                More
                            </Button>
                            <Modal
                                variant={ModalVariant.large}
                                title={`Top Domains and URLs (${domainurldata.length})`}
                                isOpen={modalStates.modaldomainurl}
                                onClose={() => handleModalToggle('modaldomainurl')}
                            >
                                <Divider className='modal-Divider' />
                                <Card>
                                    <Tabs activeKey={activeTabKey} onSelect={handleTabClick} aria-label="Tabs in the default example" role="region">
                                        <Tab eventKey={0} title={<TabTitleText>Table View</TabTitleText>}>
                                            <TableViewForChart Tabledata={domainurldata} columnData={["Domain & Url", "Count"]} filterFields={'data.query'} />
                                        </Tab>
                                        <Tab eventKey={1} title={<TabTitleText>Graph View</TabTitleText>} aria-label="graph">
                                            {activeTabKey == '1' && <TabContentBody className='pf-v5-u-p-xs'>
                                                <EWordCloud cloudseries={domainurldata.slice(0, 200)} height={300} filterFields={'data.query'} />
                                            </TabContentBody>}
                                        </Tab>
                                    </Tabs>
                                </Card>
                            </Modal>
                        </Flex>
                    </CardFooter>}
                </Card>
            </GridItem>
        </Grid>
        <div className="pf-v5-u-mt-md">
            <Grid hasGutter >
                <GridItem md={6} lg={4} sm={12}>
                    <Card isFullHeight>
                        <CardTitle > Top Country ({countryseries.length})</CardTitle>
                        <Divider />
                        <CardBody> {TopCountries} </CardBody>
                        {countryseries.length > 0 && <CardFooter>
                            <Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
                                <Button variant="link" onClick={() => { setActiveTabKey(0); handleModalToggle('modalCountries'); }}>
                                    More
                                </Button>
                                <Modal
                                    variant={ModalVariant.large}
                                    title={`Top Countries (${countryseries.length})`}
                                    isOpen={modalStates.modalCountries}
                                    onClose={() => handleModalToggle('modalCountries')}
                                >
                                    <Divider className='modal-Divider' />
                                    {/* Top CVE Data Here pass all data */}
                                    <Card>
                                        <Tabs activeKey={activeTabKey} onSelect={handleTabClick} aria-label="Tabs in the default example" role="region">
                                            <Tab eventKey={0} title={<TabTitleText>Table View</TabTitleText>}>
                                                <TableViewForChart Tabledata={countryseries} columnData={["Country", "Count"]} filterFields={'GeoLocation.country_name'} />
                                            </Tab>
                                            <Tab eventKey={1} title={<TabTitleText>Graph View</TabTitleText>} aria-label="graph">
                                                {activeTabKey == '1' && <TabContentBody className='pf-v5-u-p-xs'>
                                                    <NetInBarChart TopData={countryseries.slice(0, 30)} height={800} filterFields={'GeoLocation.country_name'} />
                                                </TabContentBody>}
                                            </Tab>
                                        </Tabs>
                                    </Card>
                                </Modal>
                            </Flex>
                        </CardFooter>}
                    </Card>
                </GridItem>
                <GridItem md={6} lg={4} sm={12}>
                    <Card isFullHeight>
                        <CardTitle >Top City ({cityseries.length})</CardTitle>
                        <Divider />
                        <CardBody>{TopCities} </CardBody>
                        {cityseries.length > 0 && <CardFooter>
                            <Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
                                <Button variant="link" onClick={() => { setActiveTabKey(0); handleModalToggle('modalCities'); }}>
                                    More
                                </Button>
                                <Modal
                                    variant={ModalVariant.large}
                                    title={`Top Cities (${cityseries.length})`}
                                    isOpen={modalStates.modalCities}
                                    onClose={() => handleModalToggle('modalCities')}
                                >
                                    <Divider className='modal-Divider' />
                                    {/* Top CVE Data Here pass all data */}
                                    <Card>
                                        <Tabs activeKey={activeTabKey} onSelect={handleTabClick} aria-label="Tabs in the default example" role="region">

                                            <Tab eventKey={0} title={<TabTitleText>Table View</TabTitleText>}>
                                                <TableViewForChart Tabledata={cityseries} columnData={["City", "Count"]} filterFields={'GeoLocation.city_name'} />
                                            </Tab>
                                            <Tab eventKey={1} title={<TabTitleText>Graph View</TabTitleText>} aria-label="graph">
                                                {activeTabKey == '1' && <TabContentBody className='pf-v5-u-p-xs'>
                                                    <NetInBarChart TopData={cityseries.slice(0, 30)} height={800} filterFields={'GeoLocation.city_name'} />
                                                </TabContentBody>}
                                            </Tab>
                                        </Tabs>
                                    </Card>
                                </Modal>
                            </Flex>
                        </CardFooter>}
                    </Card>
                </GridItem>
                <GridItem md={12} lg={4} sm={12}>
                    <Card isFullHeight>
                        <CardTitle > Top External IP ({externalIP.length})</CardTitle>
                        <Divider />
                        <CardBody>{TopExternalIP}</CardBody>
                        {externalIP.length > 0 && <CardFooter>
                            <Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
                                <Button variant="link" onClick={() => { handleModalToggle('modalexternalIP'); }}>
                                    More
                                </Button>
                                <Modal
                                    variant={ModalVariant.large}
                                    title={`External IP (${externalIP.length})`}
                                    isOpen={modalStates.modalexternalIP}
                                    onClose={() => handleModalToggle('modalexternalIP')}
                                >
                                    <Divider className='modal-Divider' />
                                    <Card><ExternalIP ExternalIPData={externalIP} Pagination={true} /></Card>
                                </Modal>
                            </Flex>
                        </CardFooter>}
                    </Card>
                </GridItem>
            </Grid>
        </div>
        <div className="pf-v5-u-mt-md">
            <Grid hasGutter>
                <GridItem md={6} lg={6} sm={12}>
                    <Card isFullHeight>
                        <CardTitle>Top Connection</CardTitle>
                        <Divider />
                        <CardBody>{TopConnectionChart}</CardBody>
                        {moreconnectionseries.length > 0 && moreconnectionname.length > 0 && <CardFooter>
                            <Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
                                <Button variant="link" onClick={() => { handleModalToggle('modalTopConnection'); }}>
                                    More
                                </Button>
                                <Modal
                                    // variant={ModalVariant.large}
                                    tabIndex={0}
                                    title='Top Connections'
                                    // help={headerActionsTopConnection}
                                    isOpen={modalStates.modalTopConnection}
                                    onClose={() => handleModalToggle('modalTopConnection')}
                                >
                                    <Divider className='modal-Divider' />
                                    <Card> <TopConnection ConnectionlinkData={moreconnectionseries} ConnectionName={moreconnectionname} height={2000} topheight={'3%'} /></Card>
                                </Modal>
                            </Flex>
                        </CardFooter>}
                    </Card>
                </GridItem>
                <GridItem md={6} lg={6} sm={12}>
                    <Card isFullHeight>
                        <CardTitle>Connection Summary</CardTitle>
                        <Divider />
                        <CardBody>{ConnectionSummaryTable}</CardBody>
                        {connectionsummary.length > 0 && <CardFooter>
                            <Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
                                <Button variant="link" onClick={() => { handleModalToggle('modalConnSummary'); }}>
                                    More
                                </Button>
                                <Modal
                                    variant={ModalVariant.large}
                                    title='Notable Connections'
                                    isOpen={modalStates.modalConnSummary}
                                    onClose={() => handleModalToggle('modalConnSummary')}
                                >
                                    <Divider className='modal-Divider' />
                                    <Card>  <ConnectionSummary Tablelog={connectionsummary} /></Card>
                                </Modal>
                            </Flex>
                        </CardFooter>}
                    </Card>
                </GridItem>
            </Grid>
        </div>
        <div className="pf-v5-u-mt-md">
            <Grid hasGutter >
                <GridItem md={6} lg={3} sm={12}>
                    <Card isFullHeight>
                        <CardTitle > Top Source IP ({sourceseries.length})</CardTitle>
                        <Divider />
                        <CardBody>{TopSourceIP}</CardBody>
                        {sourceseries.length > 0 && <CardFooter>
                            <Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
                                <Button variant="link" onClick={() => { setActiveTabKey(0); handleModalToggle('modalSourceIP'); }}>
                                    More
                                </Button>
                                <Modal
                                    variant={ModalVariant.large}
                                    title={`Top Source IP (${sourceseries.length})`}
                                    isOpen={modalStates.modalSourceIP}
                                    onClose={() => handleModalToggle('modalSourceIP')}
                                >
                                    <Divider className='modal-Divider' />
                                    <Card>
                                        <Tabs activeKey={activeTabKey} onSelect={handleTabClick} aria-label="Tabs in the default example" role="region">
                                            <Tab eventKey={0} title={<TabTitleText>Table View</TabTitleText>}>
                                                <TableViewForChart Tabledata={sourceseries} columnData={["Source IP", "Count"]} />
                                            </Tab>
                                            <Tab eventKey={1} title={<TabTitleText>Graph View</TabTitleText>} aria-label="graph">
                                                {activeTabKey == '1' && <TabContentBody className='pf-v5-u-p-xs'>
                                                    <NetInBarChart TopData={sourceseries.slice(0, 30)} height={800} />
                                                </TabContentBody>}
                                            </Tab>
                                        </Tabs>
                                    </Card>
                                </Modal>
                            </Flex>
                        </CardFooter>}
                    </Card>
                </GridItem>
                <GridItem md={6} lg={3} sm={12}>
                    <Card isFullHeight>
                        <CardTitle >Top Destination IP ({destinationseries.length}) </CardTitle>
                        <Divider />
                        <CardBody>{TopDestinationIP} </CardBody>
                        {destinationseries.length > 0 && <CardFooter>
                            <Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
                                <Button variant="link" onClick={() => { setActiveTabKey(0); handleModalToggle('modalDestinationIP'); }}>
                                    More
                                </Button>
                                <Modal
                                    variant={ModalVariant.large}
                                    title={`Top Destination IP (${destinationseries.length}) `}
                                    isOpen={modalStates.modalDestinationIP}
                                    onClose={() => handleModalToggle('modalDestinationIP')}
                                >
                                    <Divider className='modal-Divider' />
                                    <Card>
                                        <Tabs activeKey={activeTabKey} onSelect={handleTabClick} aria-label="Tabs in the default example" role="region">

                                            <Tab eventKey={0} title={<TabTitleText>Table View</TabTitleText>}>
                                                <TableViewForChart Tabledata={destinationseries} columnData={["Destination IP", "Count"]} />
                                            </Tab>
                                            <Tab eventKey={1} title={<TabTitleText>Graph View</TabTitleText>} aria-label="graph">
                                                {activeTabKey == '1' && <TabContentBody className='pf-v5-u-p-xs'>
                                                    <NetInBarChart TopData={destinationseries.slice(0, 30)} height={800} />
                                                </TabContentBody>}
                                            </Tab>
                                        </Tabs>
                                    </Card>
                                </Modal>
                            </Flex>
                        </CardFooter>}
                    </Card>
                </GridItem>
                <GridItem md={6} lg={3} sm={12}>
                    <Card isFullHeight>
                        <CardTitle > Top protocol ({protoseries.length})</CardTitle>
                        <Divider />
                        <CardBody>{TopProtocols} </CardBody>
                        {protoseries.length > 0 && <CardFooter>
                            <Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
                                <Button variant="link" onClick={() => { setActiveTabKey(0); handleModalToggle('modalProto') }}>
                                    More
                                </Button>
                                <Modal
                                    variant={ModalVariant.large}
                                    title={`Top protocol (${protoseries.length})`}
                                    isOpen={modalStates.modalProto}
                                    onClose={() => handleModalToggle('modalProto')}
                                >
                                    <Divider className='modal-Divider' />
                                    <Card> <Tabs activeKey={activeTabKey} onSelect={handleTabClick} aria-label="Tabs in the default example" role="region">

                                        <Tab eventKey={0} title={<TabTitleText>Table View</TabTitleText>}>
                                            <TableViewForChart Tabledata={protoseries} columnData={["Protocol", "Count"]} />
                                        </Tab>
                                        <Tab eventKey={1} title={<TabTitleText>Graph View</TabTitleText>} aria-label="graph">
                                            {activeTabKey == '1' && <TabContentBody className='pf-v5-u-p-xs'>
                                                <TopProtoandApp TopData={protoseries.slice(0, 50)} height={400} Name={'Top Protocols'} filterFields={'data.protocol'} />
                                            </TabContentBody>}
                                        </Tab>
                                    </Tabs>
                                    </Card>
                                </Modal>
                            </Flex>
                        </CardFooter>}
                    </Card>
                </GridItem>
                <GridItem md={6} lg={3} sm={12}>
                    <Card isFullHeight>
                        <CardTitle > Top Application ({appseries.length})</CardTitle>
                        <Divider />
                        <CardBody>{TopApplication} </CardBody>
                        {appseries.length > 0 && <CardFooter>
                            <Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
                                <Button variant="link" onClick={() => { setActiveTabKey(0); handleModalToggle('modalApp'); }}>
                                    More
                                </Button>
                                <Modal
                                    variant={ModalVariant.large}
                                    title={`Top Application (${appseries.length})`}
                                    isOpen={modalStates.modalApp}
                                    onClose={() => handleModalToggle('modalApp')}
                                >
                                    <Divider className='modal-Divider' />
                                    <Card>
                                        <Tabs activeKey={activeTabKey} onSelect={handleTabClick} aria-label="Tabs in the default example" role="region">

                                            <Tab eventKey={0} title={<TabTitleText>Table View</TabTitleText>}>
                                                {isLoadApp ? <Loader /> : <TableViewForChart Tabledata={appseries} columnData={["Application", "Count"]} filterFields={'data.service'} filterformdata={props?.filterformdata} />}
                                            </Tab>
                                            <Tab eventKey={1} title={<TabTitleText>Graph View</TabTitleText>} aria-label="graph">
                                                {activeTabKey == '1' && <TabContentBody className='pf-v5-u-p-xs'>
                                                    {isLoadApp ? <Loader /> : <TopProtoandApp TopData={appseries.slice(0, 50)} height={400} Name={'Top Applications'} filterFields={'data.service'} filterformdata={props?.filterformdata} />}
                                                </TabContentBody>}
                                            </Tab>
                                        </Tabs>
                                    </Card>
                                </Modal>
                            </Flex>
                        </CardFooter>}
                    </Card>
                </GridItem>
            </Grid>
        </div>
        <div className="pf-v5-u-mt-md">
            <Grid hasGutter>
                <GridItem md={12} lg={4} sm={12}>
                    <Card isFullHeight >
                        <CardTitle>Top Anomaly Signatures ({signatures.length}) </CardTitle><Divider />
                        <CardBody>
                            {isLoadSignature ? <Loader /> :
                                <TopSignatures SignatureData={signatures.slice(0, 10)} />
                            }
                        </CardBody>

                        {signatures.length > 0 && <CardFooter>
                            <Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
                                <Button variant="link" onClick={() => { handleModalToggle('modalsignatures'); }}>
                                    More
                                </Button>
                                <Modal
                                    variant={ModalVariant.large}
                                    title={`Signatures (${signatures.length}) `}
                                    isOpen={modalStates.modalsignatures}
                                    onClose={() => handleModalToggle('modalsignatures')}
                                >
                                    <Divider className='modal-Divider' />

                                    <Card><TopSignatures SignatureData={signatures} /> </Card>
                                </Modal>
                            </Flex>
                        </CardFooter>}
                    </Card>
                </GridItem>
                <GridItem md={12} lg={8} sm={12}>
                    <Card isFullHeight >
                        {/* <CardHeader className="pf-v5-u-align-items-flex-start"  actions={{actions:headerActions,hasNoOffset:false}} > */}
                        <CardTitle>Top Anomaly Alerts</CardTitle>
                        {/* </CardHeader> */}
                        <Divider />
                        <CardBody>
                            {isLoadAnamoly ? <Loader /> :
                                <AlertsAnamolyLog ref={childAnomalyalertsRef} />
                            }
                        </CardBody>
                        <CardFooter>
                            <Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
                                <Button variant="link" onClick={() => { handleModalToggle('modalAnomalyalertss'); setAnamolyAlertsData() }}> More </Button>
                                <Modal
                                    // variant={ModalVariant}
                                    title='Anomaly Alerts'
                                    isOpen={modalStates.modalAnomalyalertss}
                                    onClose={() => handleModalToggle('modalAnomalyalertss')}
                                >
                                    <Divider className='modal-Divider' />
                                    <Card>  <AlertsAnamolyLog ref={childAnomalyalertsRef} /> </Card>
                                </Modal>
                            </Flex>
                        </CardFooter>
                    </Card>
                </GridItem>
            </Grid>
        </div>
    </>
    )
}
)
const mapStateToProps = (state) => ({
    dates: { startDate: state.startdate, endDate: state.enddate },
    filterformdata: state.FilterData,
    selectedGroup: state.SelectedGroup,
    // mapStateToProps content remains unchanged if you have it
});

const mapDispatchToProps = (dispatch) => { return {} };

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(NetworkInsightDashboard)
