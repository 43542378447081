

//new sorted table 
import React, { useState, useEffect } from 'react';
import {
    Pagination,
    Icon,
    Toolbar,
    ToolbarContent,
    ToolbarItem,
    SearchInput,
    Button,
    TextVariants,
    Text,
    Badge,
    EmptyState,
    EmptyStateHeader,
    EmptyStateIcon,
    EmptyStateBody,
    EmptyStateActions,
    Bullseye,
    EmptyStateFooter,
    Card,
    Progress,
    ProgressVariant,
    ProgressSize,
    ProgressMeasureLocation,
    HelperText,
    HelperTextItem
} from '@patternfly/react-core';
import { Table, Thead, Tr, Th, Tbody, Td, InnerScrollContainer, } from '@patternfly/react-table';
import dbApi from '../../services/DbApi'
import { RedoIcon, SearchIcon, TrendDownIcon, TrendUpIcon } from '@patternfly/react-icons';
import { Emptystatefornodata } from '../../util/EmptyStateCard';
import { Link } from 'react-router-dom';
import { Loader } from '../../util/Loadingstate';
import { connect } from 'react-redux';

const AIenabled = (props) => {
    const defaultColumns = {
        group_name: 'Group',
        agent_id: "Agent",
        rank: "Rank",
        cases: "Cases",
        event: "Events",
        vulnerability: "Vulnerability",
        sca: "SCA",
        tip: "TIP",
        current_risk_score: "Risk Score",
        riskometer: "Riskometer",
    };

    const [columns, setColumns] = useState(defaultColumns);
    const [isLoading, setIsLoading] = useState(false)
    const [rows, setRows] = useState([]);
    const [selectedRowName, setSelectedRowName] = useState('');
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [activeSortIndex, setActiveSortIndex] = useState(2);
    const [totalItem, setTotalItem] = useState(0)
    const [activeSortDirection, setActiveSortDirection] = useState('desc');
    const [searchValue, setSearchValue] = useState('');
    const excludeOrder = ['Riskometer']
    let orderCol = 'current_risk_score'
    if (activeSortIndex != 2) {
        orderCol = Object.keys(columns)[activeSortIndex]
        orderCol = orderCol
    }

    const getRiskRank = async () => {
        let data = {
            limitStart: (perPage * page) - perPage,
            limitLength: perPage,
            search: searchValue,
            sortBy: orderCol,
            sortOrder: activeSortDirection,
            watchListId:props.watchListId
        }
        try {
            setIsLoading(true)
            let res = await dbApi.postApi("Opensearch/agent_risk_list", data)
            console.log("riskrank->", res)
            let rankdata = []
            if (res.data.data.length > 0) {
                rankdata = res.data.data
            }
            setRows(rankdata)
            setTotalItem(res.data.totalRecord)
            setIsLoading(false)
        } catch (error) {
            console.log(error)
            setIsLoading(false)
        }
    }

    useEffect(() => {
        getRiskRank()
    }, [page, perPage, activeSortIndex, activeSortDirection, searchValue, props?.selectedGroup,props.Refresh])


    const onSort = (event, index, direction) => {
        setActiveSortIndex(index);
        setActiveSortDirection(direction);
    };
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleModalToggle = _event => {
        setIsModalOpen(!isModalOpen);
    };


    const handleSetPage = (_evt, newPage) => {
        setPage(newPage);
    };
    const handlePerPageSelect = (_evt, newPerPage) => {
        setPerPage(newPerPage);
    };

    const renderPagination = (variant, isCompact) => (
        <Pagination
            isCompact={isCompact}
            itemCount={totalItem}
            page={page}
            perPage={perPage}
            onSetPage={handleSetPage}
            onPerPageSelect={handlePerPageSelect}
            variant={variant}
            titles={{
                paginationAriaLabel: `${variant} pagination`
            }}
        />
    );

    const tableToolbar = <Toolbar>
        <ToolbarContent >
            <ToolbarItem style={{ width: '60%' }} variant="search-filter">
                <SearchInput placeholder="Search..."
                    value={searchValue}
                    onChange={(_event, value) => setSearchValue(value)}
                    // onSearch={(_event, value) => setSearchValue(value)}
                    onClear={() => setSearchValue('')} />
            </ToolbarItem>
            <ToolbarItem>
                <Button icon={<RedoIcon />}
                    onClick={() => { getRiskRank(); setPerPage(10); setPage(1) }}
                    style={{ width: '100%', maxWidth: '100%' }} />
            </ToolbarItem>
            <ToolbarItem align={{ default: 'alignRight' }}>
                <ToolbarItem> {renderPagination('top', false)}</ToolbarItem>
            </ToolbarItem>
        </ToolbarContent>
    </Toolbar>
    const emptyState = <EmptyState>
        <EmptyStateHeader headingLevel="h4" titleText="No results found" icon={<EmptyStateIcon icon={SearchIcon} />} />
        <EmptyStateBody>No results match the filter criteria. Clear all filters and try again.</EmptyStateBody>
        <EmptyStateFooter>
            <EmptyStateActions>
                <Button variant="link" onClick={() => { setSearchValue(''); }}>Clear all filters </Button>
            </EmptyStateActions>
        </EmptyStateFooter>
    </EmptyState>;

    return <React.Fragment>
        {/* {rows.length == 0 ? <Emptystatefornodata /> : */}
        <> {tableToolbar}
            {isLoading ?
                <Card>
                    <Loader />
                </Card>
                :
                <>
                {/* {alert("child component")} */}
                <InnerScrollContainer key={'scroller'}>
                    <Table aria-label="Sortable Table"
                        variant='compact'
                        isStickyHeader
                        gridBreakPoint="grid-md"
                    >
                        <Thead  key="thead">
                            <Tr  key="theadrow">
                                {Object.values(columns).map((column, columnIndex) => {
                                    const stickyColumnsIndex = [0, 1];
                                    const isSticky = stickyColumnsIndex.includes(columnIndex);
                                    const sortParams = {
                                        sort: {
                                            sortBy: {
                                                index: activeSortIndex,
                                                direction: activeSortDirection
                                            },
                                            onSort,
                                            columnIndex
                                        }
                                    };
                                    // return <Th key={columnIndex} {...sortParams} isStickyColumn={isSticky}>
                                    //     {column}
                                    // </Th>;
                                    return !excludeOrder.includes(column) ? <Th key={columnIndex} {...sortParams} isStickyColumn={isSticky} >{column}</Th> : <Th key={columnIndex} isStickyColumn={isSticky}>{column}</Th>;
                                })}
                            </Tr>
                        </Thead>

                        {rows.map((row, rowIndex) => {
                            // const effectiveIndex = (page - 1) * perPage + rowIndex;
                            // const BackgroundColor = page === 1 && effectiveIndex < 10;
                            let variant;
                            if (row?.risk_score_percentage > 50 && row?.risk_score_percentage <= 75) {
                                variant = ProgressVariant.warning;
                            } else if (row?.risk_score_percentage > 75) {
                                variant = ProgressVariant.danger;
                            }
                            let BackgroundColor;
                            if (row?.risk_score_percentage > 50 && row?.risk_score_percentage <= 75) {
                                BackgroundColor = '--pf-v5-c-progress--m-warning__bar--BackgroundColor';
                            } else if (row?.risk_score_percentage > 75) {
                                BackgroundColor = '--pf-v5-c-progress--m-danger__bar--BackgroundColor';
                            }
                            let title;
                            if (row?.risk_score_percentage > 50 && row?.risk_score_percentage <= 75) {
                                title = 'Risky';
                            } else if (row?.risk_score_percentage > 75) {
                                title = 'Very risky';
                            }

                            return (
                                <Tbody>
                                    <Tr key={rowIndex}
                                        onRowClick={() => setSelectedRowName(rowIndex)}
                                        isSelectable
                                        isClickable
                                        isRowSelected={selectedRowName === rowIndex}
                                    // className={BackgroundColor ? "pf-v5-u-background-color-danger" : ""}
                                    >
                                        <>
                                            <Td width={10} dataLabel={columns.group_name} key={rowIndex + 'groupName'}>{row?.group_name}</Td>
                                            <Td width={10} dataLabel={columns.agent_id} key={rowIndex + 'agid'}>
                                                {row?.agent_id.includes('AL') ? `${row?.agent_id} : ${row?.agent_name}` :
                                                    <Link to={"/Individual-Agent-Dashboard/" + row?.agent_name + '/' + row?.agent_id}>{row?.agent_id} : {row?.agent_name}</Link>
                                                } </Td>
                                            <Td width={10} dataLabel={columns.rank} key={rowIndex + 'rank'}>
                                                {row?.difference > 0 ? <Icon status="danger"><TrendUpIcon /></Icon> : <Icon status="success"><TrendDownIcon /></Icon>} {row?.rank}</Td>
                                            <Td width={10} dataLabel={columns.cases} key={rowIndex + 'agcase'}>{row?.cases}</Td>
                                            <Td width={10} dataLabel={columns.event} key={rowIndex + 'ageve'}>{row?.event}</Td>
                                            <Td width={10} dataLabel={columns.vulnerability} key={rowIndex + 'agvul'}>{row?.vulnerability}</Td>
                                            <Td width={10} dataLabel={columns.sca} key={rowIndex + 'agsca'} className={row?.sca > 75 ? "pf-v5-u-success-color-100 pf-v5-u-font-weight-bold" : "pf-v5-u-warning-color-100 pf-v5-u-font-weight-bold"}>{row?.sca}%</Td>
                                            <Td width={10} dataLabel={columns.tip} key={rowIndex + 'agtip'}>{row?.tip}</Td>
                                            <Td width={10} dataLabel={columns.current_risk_score} key={rowIndex + 'agriskscore'}>{row?.current_risk_score}</Td>
                                            <Td width={10} dataLabel={columns.riskometer} key={rowIndex + 'agriskometer'}>
                                                {/* {null} */}
                                                {row?.risk_score_percentage > 50 &&
                                                    <Progress aria-label="solved"
                                                    key={rowIndex+'progressbar'}
                                                        value={parseFloat(row?.risk_score_percentage)}
                                                        variant={variant}
                                                        // helperText={helperText}
                                                        title={title}
                                                        // label={`${row?.risk_score_percentage}%`}
                                                        style={{
                                                            '--pf-v5-c-progress--GridGap': '0',
                                                            '--pf-v5-c-progress__status-icon--Color': '#fff'
                                                        }}
                                                        // measureLocation={ProgressMeasureLocation.inside}
                                                        size={ProgressSize.sm}
                                                    />
                                                }
                                            </Td>
                                        </>
                                    </Tr>
                                </Tbody>
                            )
                        })}


                        {rows.length === 0 &&
                            <Tbody>
                                <Tr key={'rowIndex'}>
                                    <Td colSpan={10} key={'tdindex'}>
                                        <Bullseye>{emptyState}</Bullseye>
                                    </Td>
                                </Tr>
                            </Tbody>}


                    </Table>
                </InnerScrollContainer>
                </>
            }
            {renderPagination('bottom', false)}
        </>
    </React.Fragment >;
};
const mapStateToProps = (state) => ({
    selectedGroup: state.SelectedGroup,
});

export default connect(
    mapStateToProps,
    null,
    null,
    { forwardRef: true }
)(AIenabled)