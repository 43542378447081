import * as echarts from 'echarts';
import React, { useEffect } from 'react';
import { generateUniqueID } from '../../util/UniqueIdGenerator';
import { formatValue } from '../../util/ChartHelper';
import { UpdateFilterData } from '../../util/FilterHelper';
import { FilterData } from '../../Redux/Action';
import { connect, useDispatch } from 'react-redux';
const RequirementDistributionchart = (props) => {
    const id = generateUniqueID()
    const dispatch = useDispatch();
    const handleFilter = (fields, value) => {
        const updatedFields = UpdateFilterData(props.filterformdata, fields, 'is', value);
        dispatch(FilterData(updatedFields));
    }
    useEffect(() => {
        if (props.seriesData && props.seriesData.length > 0) {
            const chartDom = document.getElementById(id + 'reqhipadist');
            const myChart = echarts.init(chartDom);
            const textcolor = props.theme == 'dark-theme' ? '#fff' : '#000'
            const linecolor = props.theme == 'dark-theme' ? '#595757' : '#d9d9d9'

            const xAxisData = props.seriesData.map(item => item.key);

            // Extract unique HIPAA requirements for the legend
            const hipaaRequirements = [...new Set(props.seriesData.flatMap(item => item.Requirement.map(req => req.key)))];

            const seriesData = hipaaRequirements.map(req => ({
                name: req,
                type: 'bar',
                stack: 'total',
                barWidth: '40',
                emphasis: {
                    focus: 'series',
                },
                data: props.seriesData.map(agent => {
                    const hipaaItem = agent.Requirement.find(requirement => requirement.key == req);
                    return hipaaItem ? hipaaItem.doc_count : 0;
                }),
            }));

            const legendData = hipaaRequirements;

            console.log(xAxisData)
            const option = {
                tooltip: {
                    trigger: 'axis',
                    textStyle: {
                        color: "#000"
                    }
                },
                title: {
                    text: "agent.name",
                    top: 'bottom',
                    left: 'center',
                    textStyle: {
                        fontWeight: 450,
                        fontSize: 14,
                        color: textcolor,
                        fontfamily: 'RedHatText, helvetica, arial, sans-serif',
                    },
                },
                legend: {
                    type: 'scroll',
                    orient: 'vertical',
                    right: 'right',
                    top: 10,
                    // bottom: 20,
                    data: legendData,
                    itemWidth: 9.5,
                    itemHeight: 10,
                    itemGap: 6.5,
                    tooltip: {
                        trigger: 'item',
                        show: true,
                        position: [60, 10],
                        textStyle: {
                            color: "#000"
                        }
                    },
                    icon: "rect",
                    pageIconSize: 12,
                    textStyle: {
                        width: 180,
                        overflow: 'truncate',
                        fontSize: 12,
                        color: textcolor
                    }
                    // data: data.legendData
                },

                grid: {
                    left: '2%',
                    right: '15%',
                    bottom: '10%',
                    top: '10%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: true,
                        axisLabel: {
                            interval: 0,
                            rotate: -50,
                            width: 70,
                            overflow: 'truncate',
                            fontSize: 12,
                            color: textcolor
                        },
                        data: xAxisData,
                        axisTick: {
                            alignWithLabel: true
                        }
                    }
                ],
                yAxis: [
                    {
                        axisLine: {
                            show: true
                        },
                        splitLine: {
                            lineStyle: {
                                color: linecolor
                            }
                        },
                        axisLabel: {
                            formatter: formatValue,
                            color: textcolor
                        }
                    }
                ],

                color: ['#0066cc', '#f4c145', '#4cb140', '#5752d1', '#ec7a08', '#40199A', '#C8EB79', '#C9190B', '#008BAD'],
                series: seriesData
            };
            option && myChart.setOption(option);
            myChart.on('legendselectchanged', function (param) {
                handleFilter(props.filterFields, param.name)
            });
            const resizeChart = () => {
                if (myChart) { // Check if chart exists before resizing
                    myChart.resize();
                }
            };

            window.addEventListener('resize', resizeChart);
            return () => {
                myChart.dispose();
                window.removeEventListener('resize', resizeChart);
            };
        }

    }, [props.seriesData, props.theme, props.sidebarState, props.dataField, props.height])

    return <div className="container">
        <div id={id + 'reqhipadist'} style={{ width: '100%', height: props?.height }} ></div>
    </div>;
}
const mapStateToProps = (state) => ({
    dates: { startDate: state.startdate, endDate: state.enddate },
    filterformdata: state.FilterData,
    theme: state.theme,
    sidebarState: state.sidebarstate
    // mapStateToProps content remains unchanged if you have it
});

export default connect(
    mapStateToProps,
    null,
    null,
    { forwardRef: true }
)(RequirementDistributionchart)
