import React, { forwardRef, useEffect, useState } from 'react'
import {
    Badge,
    Grid,
    GridItem,
    List,
    ListItem,
    Page,
    PageSidebar,
    PageSidebarBody,
    PageToggleButton,
    Sidebar,
    SidebarContent,
    Tab,
    TabContentBody,
    TabTitleText,
    Tabs,
    Text,
    Truncate
} from '@patternfly/react-core'
import { ExchangeAltIcon } from '@patternfly/react-icons'
import dbApi from '../../services/DbApi'
// import EventBar from '../CommonEventPages/EventBar'
import UBMEventTable from './UBMEventTable'
import AddFilterForm from '../CommonEventPages/AddFilterForm'
import EventBar from '../CommonEventPages/EventBar'
import { Loader,TextSkeleton } from '../../util/Loadingstate'
import { connect } from 'react-redux'


const UBMDashboard = forwardRef(function UBMDashboard(props, ref) {
    const [isSidebarExpanded, setIsSidebarExpanded] = useState(true)
    const [isloaduser, setIsLoadUser] = useState(false);
    const [isloaduserData, setIsLoadUserData] = useState(false)
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [users, setUsers] = useState()
    const [riskscore, setRiskscore] = useState(0)
    const [userdetails, setUserDetails] = React.useState([]);
    const [selectedUsername, setSelectedUsername] = useState(null);
    const [activeTabKey, setActiveTabKey] = React.useState(0);
    const handleTabClick = (event, tabIndex) => {
        setActiveTabKey(tabIndex);
    };

    const getUsers = async (data) => {
        try {
            setIsLoadUser(true)
            let allusers = await dbApi.postApi("elasticsearch/User_Behaviour_Monitoring_Top_Dstuser_Srcuser", data);
            console.log(allusers);
            const all_users = allusers.User_Behaviour_Monitoring_Top_Dstuser_Srcuser;
            let users = []
            if (all_users.length > 0) {
                users = all_users
            }
            setUsers(users);
            setIsLoadUser(false)
        }
        catch (error) {
            console.log(error)
            setIsLoadUser(false)
        }
    }

    const getuserdata = async (data) => {
        try {
            setIsLoadUserData(true)
            let user_data = await dbApi.postApi("elasticsearch/User_Behaviour_Monitoring_Filtered_User ", data)
            console.log(user_data)
            if (Object.keys(user_data.User_Behaviour_Monitoring_Filtered_User).length !== 0) {
                const user_details = user_data.User_Behaviour_Monitoring_Filtered_User.body.hits
                let userdetails = [];
                if (user_details.hits.length > 0) {
                    userdetails = user_details.hits;
                }
                setUserDetails(userdetails)
            }
            else {
                setUserDetails([])
            }
            setIsLoadUserData(false)
        }
        catch (error) {
            console.log(error)
            setIsLoadUserData(false)
        }
    }

    const handleUsernameClick = async (username) => {
        setSelectedUsername(username);
        const data = {
            startDate: startDate,
            endDate: endDate,
            filter: JSON.stringify([]),
            user: username,
        };
        console.log("api called 2 =", data)
        await getuserdata(data);
    }

    const UBMDashboardApi = async (startDate, endDate, allFields) => {
        const Fields = allFields
        const data = {
            startDate: startDate,
            endDate: endDate,
            filter: JSON.stringify(Fields),
        };

        console.log(data)
        setStartDate(startDate)
        setEndDate(endDate)
        await getUsers(data);
    }

    const toggleSidebar = () => {
        setIsSidebarExpanded((prevIsSidebarExpanded) => !prevIsSidebarExpanded);
    };

    useEffect(() => {

        if (props.dates.startDate != "") {
            let Dates = props.dates
            UBMDashboardApi(Dates.startDate, Dates.endDate, props.filterformdata,)
            //childSecurityAlertsRef.current.setSecurityAlertsTable(securityAlertsLog, SAscrollId,);
        }
    }, [props?.dates.startDate, props?.dates.endDate, props?.filterformdata,]);

    const filterfield = [{}]
    const Sidebarin =
        <div className="pf-v5-u-p-md">
            {isloaduser ?
                <>
                    <b> User Names</b>
                    <TextSkeleton /><br />
                    <TextSkeleton /><br />
                    <TextSkeleton /><br />
                    <TextSkeleton /><br />
                    <TextSkeleton /><br />
                    <TextSkeleton /><br />
                </> :


                users && users.length > 0 ? (
                    <List isPlain>
                        <b>User Names</b>
                        {users
                            .filter(item => !item.key.includes('$'))
                            .map((item, index) => (
                                //  onClick={()=>handleUsernameClick(item.key)}
                                <ListItem key={index}><a onClick={() => handleUsernameClick(item.key)}><Truncate content={item.key} /> <Badge key={index} >{item.doc_count}</Badge></a>
                                    {item.score !== undefined && item.score !== 0 ? ( // Checking if score exists
                                        <Text className='pf-v5-u-danger-color-200'>
                                            Risk Score: {item.score}
                                        </Text>
                                    ) : null}
                                </ListItem>
                            ))}
                    </List>

                ) : (
                    <b>No User Name</b>
                )}

        </div>

    return (
        <div className="pf-v5-u-p-sm">
            {/* <EventBar onClick={apiCall} /> */}
            <EventBar />
            <AddFilterForm filter={filterfield} />
            <Grid hasGutter>
                <GridItem>
                    <Page isManagedSidebar>
                        <Sidebar style={{ overflow: "auto" }}  >
                            {/* <SidebarPanel variant="sticky">{Sidebarin}</SidebarPanel> */}
                            <PageSidebar theme="light" style={{ "--pf-c-page__sidebar--Width": "20rem" }}>
                                <PageSidebarBody>{Sidebarin}</PageSidebarBody>
                            </PageSidebar>
                            <PageToggleButton variant="plain" id="nav-toggle">
                                <ExchangeAltIcon onClick={toggleSidebar} />
                            </PageToggleButton>
                            <div style={{ width: "100%" }}>
                                <SidebarContent className="Sidebar-content">
                                    <Tabs activeKey={activeTabKey} onSelect={handleTabClick} aria-label="UBMTabsection" role="region">
                                        {/* <Tab eventKey={0} title={<TabTitleText>Events</TabTitleText>} >
                                            <TabContentBody hasPadding>
                                                {isloaduserData ? <Loader /> :
                                                    <UBMEventTable UserLog={userdetails} />
                                                }
                                            </TabContentBody>
                                        </Tab> */}
                                         <Tab eventKey={0} title={<TabTitleText>Topology</TabTitleText>}>
                                            <TabContentBody hasPadding>
                                                Topology
                                            </TabContentBody>
                                        </Tab> 
                                        {/* <Tab eventKey={2} title={<TabTitleText>KillChain</TabTitleText>}>
                                            <TabContentBody hasPadding>
                                                KillChain
                                            </TabContentBody>
                                        </Tab>  */}
                                    </Tabs>
                                </SidebarContent>
                            </div>
                        </Sidebar>
                    </Page>
                </GridItem>
            </Grid>
        </div>
    )
})
const mapStateToProps = (state) => ({
    dates: { startDate: state.startdate, endDate: state.enddate },
    filterformdata: state.FilterData,
    selectedGroup: state.SelectedGroup,
    // mapStateToProps content remains unchanged if you have it
});

const mapDispatchToProps = (dispatch) => {}

export default connect(
    mapStateToProps,
    null,
    null,
    { forwardRef: true }
)(UBMDashboard)
