import React, { useEffect, useRef, useState } from 'react';
import { Alert, Menu, MenuContent, MenuItem, MenuList, Popper, SearchInput, Truncate } from '@patternfly/react-core';
import { connect, useDispatch, useSelector } from 'react-redux';
import dbApi from '../../services/DbApi'
import { FilterData, SearchedText, alertBoxData } from '../../Redux/Action';
import AlertBox from '../AlertBox/AlertBox';
import { generateUniqueID } from 'src/util/UniqueIdGenerator';

const SearchAutocomplete = (props) => {
    const fieldData = useSelector(state => state.MappingKeys);
    const uniqueid = generateUniqueID()
    const dispatch = useDispatch();
    const [value, setValue] = useState('');
    const [hint, setHint] = useState('');
    const [autocompleteOptions, setAutocompleteOptions] = useState([]);
    const [oldString, setOldString] = useState('')
    const [isAutocompleteOpen, setIsAutocompleteOpen] = useState(false);
    const [currentSearchVal, setCurrentSearchVal] = useState("")
    const [lastOpenDropDown, setLastOpenDropDown] = useState('')
    const [fieldValues, setFieldValues] = useState([])
    const [showError, setShowError] = useState(false)
    const searchInputRef = useRef(null);
    const autocompleteRef = useRef(null);

    const onClear = () => {
        setValue('');
        setLastOpenDropDown('')
        // console.log("cleardata", props.filterformdata)
        const filteredData = props.filterformdata.filter(item => item.filtertype !== 'Searchbar');
        dispatch(FilterData(filteredData));
        dispatch(SearchedText(''))
    };

    const fetchData = async (field) => {
        try {
            const data = {
                startDate: props.dates.startDate,
                endDate: props.dates.endDate,
                field: field,
                filter: JSON.stringify(props.filterformdata),
                Index_Name: props?.groupindex == '' ? 'wazuh-alerts-*' : props?.groupindex
            }
            const fetchedFieldValues = await dbApi.postApi('elasticsearch/distinctvalue', data);
            return fetchedFieldValues;
        } catch (error) {
            console.error('Error fetching data:', error);
            return [];
        }
    };

    const onChange = async (_event, newValue) => {

        if (newValue === '') {
            onClear()
        }
        setValue(newValue)
        const lastIndexcolon = newValue.lastIndexOf(":");
        const lastIndexgreater = newValue.lastIndexOf(">");
        const lastIndexless = newValue.lastIndexOf("<");
        const lastIndexEqual = newValue.lastIndexOf("=");
        const lastIndexOr = newValue.toUpperCase().lastIndexOf(" OR ");
        const lastIndexAnd = newValue.toUpperCase().lastIndexOf(" AND ");
        const len = newValue.length - 1;
        // console.log("newValue",newValue)
        if (!newValue.includes('=') && !newValue.includes(':')) {
            const operatorCount = (newValue.toUpperCase().match(/\b(OR|AND)\b/g) || []).length;
            const secondOperatorIndexOR = newValue.toUpperCase().indexOf("OR", newValue.toUpperCase().indexOf("OR") + 1);
            const secondOperatorIndexAND = newValue.toUpperCase().indexOf("AND", newValue.toUpperCase().indexOf("AND") + 1);

            if (operatorCount > 1) {
                setShowError(true);
                if (secondOperatorIndexOR !== -1) {
                    // console.log("or")
                    setValue(newValue.slice(0, secondOperatorIndexOR));
                }
                else if (secondOperatorIndexAND !== -1) {
                    // console.log("and")
                    setValue(newValue.slice(0, secondOperatorIndexAND))
                }
                dispatch(alertBoxData([{ title: 'More than one logical operator is not allowed', variant: "warning" }]));
            } else {
                setValue(newValue);
            }
        }

        else {
            if (
                (lastIndexcolon > 0 && lastIndexcolon == len) ||
                (lastIndexgreater > 0 && lastIndexgreater == len) ||
                (lastIndexless > 0 && lastIndexless == len)
            ) {
                setOldString(newValue)
                setCurrentSearchVal("value")
                setLastOpenDropDown("value")

            }
            else if (
                (lastIndexEqual == 0 && lastIndexEqual == len) ||
                (lastIndexOr > 0 && lastIndexOr == (len - 3)) ||
                (lastIndexAnd > 0 && lastIndexAnd == (len - 4))) {
                setOldString(newValue)
                setLastOpenDropDown("field")
                setCurrentSearchVal("field")
            } else {
                if (currentSearchVal != "") { setCurrentSearchVal("") }

            }

        }
    };

    useEffect(() => {

        if (lastOpenDropDown == 'field') {
            fieldOPtionsData()
        } else if (lastOpenDropDown == 'value') {
            setFieldValueOption()
        }

    }, [lastOpenDropDown, value, fieldValues])

    function fieldOPtionsData() {
        let replacetext = value.toLowerCase().replace(/.*?\sand/g, '').replace(/=/, '').replace(/.*?\sor/g, '')
        try {
            let options = fieldData.sort()
                .filter((key) => key.fields.toLowerCase().includes(replacetext.toLowerCase().substring(1)))
                .map((key, index) => (
                    <MenuItem itemId={key.fields} key={`${index}+${key.id}+${uniqueid}`}>
                        {key.fields}
                    </MenuItem>

                ));

            setAutocompleteOptions(options);
            setIsAutocompleteOpen(options.length > 0);
        } catch (error) {
            console.log(error)
        }
    }

    function setFieldValueOption() {
        let replacetext = value.replace(/.*?:/g, '').replace(/.*?(<|\s)/g, '').replace(/.*?(>|\s)/g, '')
        try {
            let options = fieldValues
                .filter((key) =>
                    // key.includes(replacetext.substring(1)))
                    String(key.key).toLowerCase().includes(String(replacetext).toLowerCase().substring(1)))
                .map((item, index) => (
                    <MenuItem itemId={`${item.key}`} key={`${index}${item.key}`}>
                        <Truncate content={String(item.key)} />
                    </MenuItem>
                ));
            setAutocompleteOptions(options);
            setIsAutocompleteOpen(options.length > 0);
        }
        catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        if (typeof props?.SearchValue != "undefined" && props?.SearchValue != "") {
            setValue(props?.SearchValue)
        }
    }, [props?.SearchValue])

    useEffect(() => {
        (async () => {
            // if (value != '') {
            if (currentSearchVal == "field") {
                fieldOPtionsData()
            }
            else if (currentSearchVal == "value") {
                const lastIndex = Math.max(value.lastIndexOf(':'), value.lastIndexOf('>'), value.lastIndexOf('<'))
                if (lastIndex !== -1) {
                    const firstPart = value.substring(0, lastIndex);
                    let secondPart = value.substring(lastIndex + 1);
                    let lastPart = ""
                    if (secondPart != "") { lastPart = secondPart }
                    else { lastPart = firstPart }
                    const slastIndex = value.lastIndexOf(' ');
                    if (slastIndex > -1) { lastPart = value.substring(slastIndex + 1); }

                    lastPart = lastPart.replace(":", "").replace(">", "").replace("<", "").replace("=", "")
                    const fieldValues = await fetchData(lastPart);
                    if (fieldValues && fieldValues.distinct && fieldValues.distinct.body && fieldValues.distinct.body.aggregations && fieldValues.distinct.body.aggregations.distinct_value && fieldValues.distinct.body.aggregations.distinct_value.buckets) {
                        const buckets = fieldValues.distinct.body.aggregations.distinct_value.buckets;
                        if (buckets.length > 0) {
                            setFieldValues(buckets)
                            setFieldValueOption()
                        }
                    }
                    else { setFieldValues([]) }
                }
            }
            // }
        })()

    }, [currentSearchVal])

    const NestedCondition = (InputString, orCondition) => {
        const filterData = InputString
            .replace(/\b(?:and|or)\b/gi, match => match.toUpperCase())
            .split(/\s+(=|AND|OR)\s+/) // Split by AND or OR, considering whitespace
            .filter(filter => filter.trim() !== '') // Exclude empty strings
            .map((filter) => {
                if (filter.includes(':')) {
                    const parts = filter.replace("=", "").split(':');
                    if (parts.length === 2) { // Check if split resulted in two parts
                        return {
                            fields: parts[0].trim(''),
                            condition: orCondition ? 'Nested_OR' : 'Nested_AND', // Set condition based on OR presence
                            value: parts[1].trim(),
                            filtertype: 'Searchbar'
                        };
                    }
                }
                else if (filter.includes('>')) {
                    const isgreaterPresent = filter.includes('>')
                    const parts = filter.replace("=", "").split('>');
                    if (parts.length === 2) { // Check if split resulted in two parts
                        return {
                            fields: parts[0].trim(''),
                            condition: isgreaterPresent ? 'Search_Greater_than' : 'Search_less_than', // Set condition based on OR presence
                            value: parts[1].trim(),
                            filtertype: 'Searchbar'
                        };
                    }
                }
                else if (filter.includes('<')) {
                    const islessPresent = filter.includes('<')
                    const parts = filter.replace("=", "").split('<');

                    if (parts.length === 2) { // Check if split resulted in two parts
                        return {
                            fields: parts[0].trim(''),
                            condition: islessPresent ? 'Search_less_than' : 'Search_Greater_than', // Set condition based on OR presence
                            value: parts[1].trim(),
                            filtertype: 'Searchbar'
                        };
                    }
                }
                return null;
            })
            .filter(filter => filter !== null); // Exclude null entries

        const updatedFields = [...props.filterformdata, ...filterData.flat()];
        dispatch(FilterData(updatedFields));
        // dispatch(FilterData(filterData.flat()));

    }
    const CombineCondition = (InputString) => {
        const filterData = InputString
            .replace(/\b(?:and|or)\b/gi, match => match.toUpperCase())
            .split(/\s+(=|AND|OR)\s+/)
            .filter(filter => filter.trim() !== '')
            .map((filter, index, filters) => {
                let logicalOperator = '';
                if (filter.includes(':')) {
                    const parts = filter.replace("=", "").split(':');
                    if (parts.length === 2) { // Check if split resulted in two parts
                        if (index === 0) {
                            const nextFilter = filters[index + 1].toUpperCase();
                            logicalOperator = nextFilter;
                            // console.log(nextFilter)
                        } else if (index === filters.length - 1) {
                            const prevFilter = filters[index - 1].toUpperCase();
                            logicalOperator = prevFilter
                            // console.log(filters[index - 1])
                        } else {
                            const prevFilter = filters[index - 1].toUpperCase();
                            logicalOperator = prevFilter;
                            // console.log(prevFilter)
                        }
                        return {
                            fields: parts[0].trim(''),
                            condition: "COMBINE_OR_AND", // Set condition based on OR presence
                            value: parts[1].trim(),
                            input: logicalOperator,
                            filtertype: 'Searchbar'
                        };
                    }
                }
                else if (filter.includes('>')) {
                    const parts = filter.replace("=", "").split('>');
                    if (parts.length === 2) { // Check if split resulted in two parts
                        return {
                            fields: parts[0].trim(''),
                            condition: 'Search_Greater_than', // Set condition based on OR presence
                            value: parts[1].trim(),
                            filtertype: 'Searchbar'
                        };
                    }
                }
                else if (filter.includes('<')) {
                    const parts = filter.replace("=", "").split('<');
                    if (parts.length === 2) { // Check if split resulted in two parts
                        return {
                            fields: parts[0].trim(''),
                            condition: 'Search_less_than', // Set condition based on OR presence
                            value: parts[1].trim(),
                            filtertype: 'Searchbar'
                        };
                    }
                }
                return null;
            })
            .filter(filter => filter !== null); // Exclude null entries

        const updatedFields = [...props.filterformdata, ...filterData.flat()];
        dispatch(FilterData(updatedFields));
        // dispatch(FilterData(filterData.flat()));
    }



    const onSearchHandle = (inputString) => {
        setValue(inputString)

        const isEqual = inputString.includes('=')
        const isORPresent = inputString.toUpperCase().includes(' OR ');
        const isANDPresent = inputString.toUpperCase().includes(' AND ');
        const colonpresent = inputString.includes(':');
        const isgreaterPresent = inputString.includes('>');
        const islessPresent = inputString.includes('<')
        dispatch(SearchedText(inputString))
        if (value != '') {
            // string pattern->=agent.name:towersrv and rule.group:windows or rule.level:7
            if (isEqual) {
                if ((isANDPresent && isORPresent) && (colonpresent || isgreaterPresent || islessPresent)) {
                    CombineCondition(inputString)
                }
                else if (isANDPresent || isORPresent || colonpresent || isgreaterPresent || islessPresent) {
                    NestedCondition(inputString, isORPresent)
                }
            }
            // string pattern->agent.name:towersrv and rule.group:windows or rule.level(>:)7
            else if ((isANDPresent && isORPresent) && (colonpresent || isgreaterPresent || islessPresent)) {
                // alert("2")
                CombineCondition(inputString)
            }
            // string pattern->agent.name:towersrv and rule.group:windows ?? agent.name:towersrv
            // else if (isANDPresent || isORPresent || colonpresent || isgreaterPresent || islessPresent) {
            else if ((isANDPresent || isORPresent) && (colonpresent || isgreaterPresent || islessPresent)) {
                // alert("3")
                NestedCondition(inputString, isORPresent)
            }
            else if (colonpresent || isgreaterPresent || islessPresent) {
                // alert("4")
                NestedCondition(inputString, isORPresent)
            }
            // string pattern->root and windows
            else if (isORPresent || isANDPresent) {
                // console.log("isANDPresent", isANDPresent)
                // alert("1")
                const filterData = inputString
                    .replace(/\b(?:and|or)\b/gi, match => match.toUpperCase())
                    .split(/\s+/).filter((filter) => filter !== 'AND' && filter !== 'OR').map((filter) => {
                        return {
                            condition: isORPresent ? 'Multi_OR_Search' : 'Multi_AND_Search', // Set condition based on OR presence
                            value: filter,
                            filtertype: 'Searchbar'
                        };
                    })
                const updatedFields = [...props.filterformdata, ...filterData.flat()];
                dispatch(FilterData(updatedFields));
                // dispatch(FilterData(filterData.flat()));
                // console.log("filterData", filterData)
            }
            else {
                const additionalArray = [{
                    condition: 'Multi_AND_Search',
                    value: inputString,
                    filtertype: 'Searchbar'
                }]
                const updatedFields = [...props.filterformdata, ...additionalArray];
                dispatch(FilterData(updatedFields));

            }

        }

    };
    // Whenever an autocomplete option is selected, set the search input value, close the menu, and put the browser
    // focus back on the search input
    const onSelect = (e, itemId) => {
        e.stopPropagation();
        setValue(oldString + itemId);
        // setValue((prevValue) => prevValue + itemId);
        setIsAutocompleteOpen(false);
        setLastOpenDropDown('')
        searchInputRef.current.focus();
    };

    const handleMenuKeys = (event) => {
        // If there is a hint while the browser focus is on the search input, tab or right arrow will 'accept' the hint value
        // and set it as the search input value
        // alert(event.key)
        if (hint && (event.key === 'Tab' || event.key === 'ArrowRight') && searchInputRef.current === event.target) {
            setValue(hint);
            setHint('');
            setIsAutocompleteOpen(false);
            if (event.key === 'ArrowRight') {
                event.preventDefault();
            }
            // if the autocomplete is open and the browser focus is on the search input,
        } else if (isAutocompleteOpen && searchInputRef.current && searchInputRef.current === event.target) {
            // the escape key closes the autocomplete menu and keeps the focus on the search input.

            if (event.key === 'Escape') {
                setIsAutocompleteOpen(false);
                searchInputRef.current.focus();
                // the up and down arrow keys move browser focus into the autocomplete menu

            } else if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
                const firstElement = autocompleteRef.current.querySelector('li > button:not(:disabled)');
                firstElement && firstElement.focus();
                event.preventDefault(); // by default, the up and down arrow keys scroll the window
                // the tab, enter, and space keys will close the menu, and the tab key will move browser
                // focus forward one element (by default)

            } else if (event.key === 'Tab' || event.key === 'Enter' || event.key === 'Space') {

                setIsAutocompleteOpen(false);
                if (event.key === 'Enter' || event.key === 'Space') {
                    event.preventDefault();
                }

            }
            // If the autocomplete is open and the browser focus is in the autocomplete menu
            // hitting tab will close the autocomplete and but browser focus back on the search input.
        } else if (isAutocompleteOpen && autocompleteRef.current.contains(event.target) && event.key === 'Tab') {
            event.preventDefault();
            setIsAutocompleteOpen(false);
            searchInputRef.current.focus();
        }
    };

    // The autocomplete menu should close if the user clicks outside the menu.
    const handleClickOutside = (event) => {
        if (
            isAutocompleteOpen &&
            autocompleteRef &&
            autocompleteRef.current &&
            !autocompleteRef.current.contains(event.target)
        ) {
            setIsAutocompleteOpen(false);
        }
    };
    React.useEffect(() => {
        window.addEventListener('keydown', handleMenuKeys);
        window.addEventListener('click', handleClickOutside);
        return () => {
            window.removeEventListener('keydown', handleMenuKeys);
            window.removeEventListener('click', handleClickOutside);
        };
    }, [isAutocompleteOpen, hint, searchInputRef.current]);

    // const TypeDisabled = showError;

    const searchInput = (
        <>
            <SearchInput
                onSearch={(_event, value) => value != '' && onSearchHandle(value)}
                value={value}
                // isDisabled={TypeDisabled}
                placeholder='search...'
                onChange={onChange}
                onClear={onClear}
                ref={searchInputRef}
                hint={hint}
                id="autocomplete-search"
            />
            {showError && (<AlertBox />)}
        </>
    );

    const autocomplete = (
        <Menu ref={autocompleteRef} onSelect={onSelect} isScrollable>
            <MenuContent>
                <MenuList>{autocompleteOptions}</MenuList>
            </MenuContent>
        </Menu>
    );

    return (
        <Popper
            trigger={searchInput}
            triggerRef={searchInputRef}
            popper={autocomplete}
            popperRef={autocompleteRef}
            isVisible={isAutocompleteOpen}
            enableFlip={false}
        // append the autocomplete menu to the search input in the DOM for the sake of the keyboard navigation experience
        // appendTo={() => document.querySelector('#autocomplete-search')}
        />
    );
};
const mapStateToProps = (state) => ({
    dates: { startDate: state.startdate, endDate: state.enddate },
    filterformdata: state.FilterData,
    FieldData: state.FieldData,
    SearchValue: state.SearchText,
    groupindex: state.groupIndexName
    // mapStateToProps content remains unchanged if you have it
});

export default connect(
    mapStateToProps,
    null,
    null,
    { forwardRef: true }
)(SearchAutocomplete)
