
//2nd which is perfectly run and usable
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import {
    ExpandableRowContent,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    Table,
    InnerScrollContainer,
} from "@patternfly/react-table";
import {
    Bullseye,
    Button,
    Checkbox,
    EmptyState,
    EmptyStateHeader,
    EmptyStateBody,
    EmptyStateIcon,
    EmptyStateFooter,
    EmptyStateActions,
    Flex,
    FlexItem,
    Toolbar,
    ToolbarContent,
    ToolbarItem,
    ToolbarGroup,
    SearchInput,
    TextContent,
    TextVariants,
    Text,
    Tab,
    Tabs,
    TabContentBody,
    TextListVariants,
    TextListItemVariants,
    TextListItem,
    TextList,
    Badge,
    DescriptionList,
    DescriptionListGroup,
    DescriptionListTerm,
    DescriptionListDescription,
    Pagination,
    Tooltip,
} from "@patternfly/react-core";
import dbApi from 'src/services/DbApi'
import { Emptystatefornodata } from "src/util/EmptyStateCard";
import { formatTableTimestamp } from "src/util/Timeutils";
import { SearchIcon } from "@patternfly/react-icons";
import { Link } from "react-router-dom";
import { UpdateFilterData } from "src/util/FilterHelper";
import { FilterData } from "src/Redux/Action";
import { connect, useDispatch } from "react-redux";

 const LatestPassedfailedLog = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const [latestpfData, setLatestpfData] = React.useState([])
    const [ScrollID, setScrollID] = React.useState('')
    const [Modalstate, setModalstate] = React.useState(false)

    useImperativeHandle(ref, () => ({
        setLatestPFLog(data = [], scrollId = '', modalstate = '') {
            setBeforescrolldata([])
            setLatestpfData(data)
            setModalstate(modalstate)
            //console.log("message:-",data)
            setScrollID(scrollId)
        }
    }));

    const defaultColumns = [
        "Time",
        "Agent Name ",
        "Rule Description",
    ];
    const [itemcount, setItemcount] = useState(0)
    const [columns, setColumns] = useState(defaultColumns);
    const [defaultRows, setDefaultRows] = useState([]);
    const [rows, setRows] = useState([]);
    const [scrolldata, setScrollData] = useState([]);
    const [beforescrolldata, setBeforescrolldata] = useState([]);
    const initialExpandedRowNames = [];
    const [areAllExpanded, setAreAllExpanded] = React.useState(false);
    const [collapseAllAriaLabel, setCollapseAllAriaLabel] = React.useState("Expand all");
    const [expandedRowNames, setExpandedRowNames] = React.useState(initialExpandedRowNames);
    const [expandedRowData, setExpandedRowData] = useState({});
    const [searchValue, setSearchValue] = React.useState('')
    const [selectedRowName, setSelectedRowName] = React.useState('');
    const [page, setPage] = React.useState(1);
    const [perPage, setPerPage] = React.useState(5);


    //Scroll API call for next data
    const latestpfpagination = async (ScrollID) => {
        const data = {
            scroll_Id: ScrollID
        }
        try {
            let Scrolldata = await dbApi.postApi("Opensearch/Latest_scroll", data)
            console.log(Scrolldata)
            const nextdata = Scrolldata.Latest_scroll
            let scrolldata = []
            if (nextdata.length > 0) {
                scrolldata = nextdata
            }
            setScrollData(scrolldata);
        }
        catch (error) {
            console.log(error)
        }
    }
    const resetPagination = () => {
        setPage(page);
        setPerPage(perPage); // Set to your desired default perPage value
    };

    useEffect(() => {
        let afterScrolldata = []
        if (beforescrolldata.length === 0) {
            afterScrolldata = latestpfData
        } else {
            afterScrolldata = [...beforescrolldata, ...scrolldata]
        }
        setBeforescrolldata(afterScrolldata)
        resetPagination()

        const newdefaultRows = afterScrolldata.map((log) => {
            const { formattedTimeStamp } = formatTableTimestamp(log._source.timestamp);
            return {
                id: log._id,
                agentID: log._source.agent.id,
                checkid: log._source.data.sca?.check?.id,
                timestamp: formattedTimeStamp,
                agentName: log._source.agent.name,
                ruledesc: log._source.rule.description,
                result: log._source.data.sca?.check?.result,
                //data.command or data.registry add
                details: log
            };
        });
        // console.log(newdefaultRows)
        setDefaultRows(newdefaultRows)
        setItemcount(newdefaultRows.length)
        const startIndex = (page - 1) * perPage;
        const endIndex = startIndex + perPage;
        const slicedRows = newdefaultRows.slice(startIndex, endIndex);
        setRows(slicedRows)
        // if (defaultRows.length !== rows.length) {
        //     const slicedRows = defaultRows;
        //     setRows(slicedRows);
        // }
        const allExpanded = expandedRowNames.length === rows.length;
        setAreAllExpanded(allExpanded);
        setCollapseAllAriaLabel(allExpanded ? "Collapse all" : "Expand all");

    }, [latestpfData, scrolldata]);

    //Use Effect for pagination 
    useEffect(() => {
        const startIndex = (page - 1) * perPage;
        const endIndex = startIndex + perPage;
        const slicedRows = defaultRows.slice(startIndex, endIndex);
        // console.log("after slicing", "", startIndex, endIndex, slicedRows)
        setRows(slicedRows);
    }, [defaultRows, page, perPage]);

    //for search filter
    const onSearchChange = value => {
        setSearchValue(value);
    };
    const onFilter = repo => {
        if (searchValue === '') {
            return true;
        }

        let input;
        try {
            input = new RegExp(searchValue, 'i');
        } catch (err) {
            input = new RegExp(searchValue.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'i');
        }
        return repo.agentName.search(input) >= 0;
    };
    const filteredRows = rows.filter(onFilter);



    //for expansion details 
    const keyify = (details) => {

        if (!details || !details._source) {
            return [];
        }
        const { data } = details._source;
        const { sca } = data

        if (!sca || !sca.check) {
            return [{ key: '', value: 'No details available' }];
        }
        const { compliance } = sca?.check || {};
        const rationale = sca?.check?.rationale || 'N/A';
        const remediation = sca?.check?.remediation || 'N/A';
        const description = sca?.check?.description || 'N/A';
        const registry = sca?.check?.registry || 'N/A';
        const command = sca?.check?.command || 'N/A';
        const directory = sca?.check?.directory || 'N/A';
        const process = sca?.check?.process || 'N/A';
        const file = sca?.check?.file || 'N/A';



        let targetsection;
        if (command !== 'N/A') {
            targetsection = (
                <>
                    <TextList component={TextListVariants.dl}>
                        <TextListItem component={TextListItemVariants.dt}>Command: </TextListItem>
                        <TextListItem component={TextListItemVariants.dd}><Badge style={{ "--pf-v5-c-badge--m-unread--BackgroundColor": "#3e8635" }}>{command}</Badge></TextListItem>
                    </TextList>
                </>
            );
        } else if (registry !== 'N/A') {
            targetsection = (
                <>   <TextList component={TextListVariants.dl}>
                    <TextListItem component={TextListItemVariants.dt}>Registry:</TextListItem>
                    <TextListItem component={TextListItemVariants.dd}><Badge style={{ "--pf-v5-c-badge--m-unread--BackgroundColor": "#3e8635" }}>{registry}</Badge></TextListItem>
                </TextList>
                </>
            );
        }
        else if (file !== 'N/A') {
            targetsection = (
                <>   <TextList component={TextListVariants.dl}>
                    <TextListItem component={TextListItemVariants.dt}>File:</TextListItem>
                    <TextListItem component={TextListItemVariants.dd}> <Badge style={{ "--pf-v5-c-badge--m-unread--BackgroundColor": "#3e8635" }}>{file}</Badge></TextListItem>
                </TextList>
                </>
            );
        }
        else if (directory !== 'N/A') {
            targetsection = (
                <>   <TextList component={TextListVariants.dl}>
                    <TextListItem component={TextListItemVariants.dt}>Directory:</TextListItem>
                    <TextListItem component={TextListItemVariants.dd}><Badge style={{ "--pf-v5-c-badge--m-unread--BackgroundColor": "#3e8635" }}>{directory}</Badge></TextListItem>
                </TextList>
                </>
            );
        }
        else if (process !== 'N/A') {
            targetsection = (
                <>   <TextList component={TextListVariants.dl}>
                    <TextListItem component={TextListItemVariants.dt}>Process:</TextListItem>
                    <TextListItem component={TextListItemVariants.dd}><Badge style={{ "--pf-v5-c-badge--m-unread--BackgroundColor": "#3e8635" }}>{process}</Badge></TextListItem>
                </TextList>
                </>
            );
        }

        let complianceItems = [];
        if (compliance) {
            complianceItems = Object.entries(compliance).map(([key, value]) => ({
                compliance_item: (
                    <DescriptionList isHorizontal isCompact>
                        <DescriptionListGroup>
                            <DescriptionListTerm>{key}:</DescriptionListTerm>
                            <DescriptionListDescription>{value}</DescriptionListDescription>
                        </DescriptionListGroup>
                    </DescriptionList>
                ),
            }
            ))
        }
        return [
            { key: 'Rationale', value: rationale },
            { key: 'Remediation', value: remediation },
            { key: 'Description', value: description },
            { key: 'Target', value: targetsection },
            { key: 'Compliance', value: complianceItems.map((item => item.compliance_item)) },
        ];
    };
    //for pagination
    const onSetPage = (_event, newPage) => {
        setPage(newPage);
    };
    const onPerPageSelect = (_event, newPerPage, newPage) => {
        setPerPage(newPerPage);
        setPage(newPage);
    };

    const renderPagination = variant =>
        <Pagination
            itemCount={itemcount}
            perPage={perPage}
            page={page}
            onSetPage={onSetPage}
            variant={variant}
            onPerPageSelect={onPerPageSelect}
        />

    const emptyState = <EmptyState>
        <EmptyStateHeader headingLevel="h4" titleText="No results found" icon={<EmptyStateIcon icon={SearchIcon} />} />
        <EmptyStateBody>No results match the filter criteria. Clear all filters and try again.</EmptyStateBody>
        <EmptyStateFooter>
            <EmptyStateActions>
                <Button variant="link" onClick={() => { setSearchValue(''); }}>Clear all filters </Button>
            </EmptyStateActions>
        </EmptyStateFooter>
    </EmptyState>;

    //Row Expansion  Code
    const setRowExpanded = (row, isExpanding) => {
        const otherExpandedRowNames = expandedRowNames.filter((r) => r !== row.id);
        setExpandedRowNames(
            isExpanding ? [...otherExpandedRowNames, row.id] : otherExpandedRowNames
        );
        if (!expandedRowData[row.id]) {
            const keyifiedData = keyify(row?.details);
            setExpandedRowData((prevData) => ({
                ...prevData,
                [row.id]: keyifiedData,
            }));
        }
        // setRowExpanded(row, !isRowExpanded(row));
        const updatedExpandedRowNames = isRowExpanded(row)
            ? expandedRowNames.filter((r) => r !== row.id)
            : [...expandedRowNames, row.id];
        setExpandedRowNames(updatedExpandedRowNames);

    };
    const isRowExpanded = (row) => {
        return expandedRowNames.includes(row.id);
    };

    const onCollapseAll = (_event, _rowIndex, isOpen) => {
        // setExpandedRowNames(isOpen ? [...rows.map((row) => row.id)] : []);
        if (areAllExpanded) {
            setExpandedRowNames([]);
        } else {
            const allRowIds = filteredRows.map((row) => row.id);
            setExpandedRowNames(allRowIds);
        }
        setAreAllExpanded(!areAllExpanded);
    };


    const searchInput = <SearchInput placeholder="Search..."
        value={searchValue}
        onChange={(_event, value) => onSearchChange(value)}
        onClear={() => onSearchChange('')}
    />;
    // value={searchValue} onChange={(_event, value) => onSearchChange(value)} onClear={() => onSearchChange('')}
    const checkbox = <React.Fragment>
        <Flex className="pf-v5-u-align-self-center">
            <FlexItem> <Checkbox label="Passed" id="controlled-Passed" name="Passed" /></FlexItem>
            <FlexItem>  <Checkbox label="Failed" id="controlled-Failed" name="Failed" /></FlexItem>
            <FlexItem>  <Checkbox label="Not Applicable" id="controlled-not-applicable" name="not-applicable" /></FlexItem>
        </Flex>
    </React.Fragment>
    const toolbar = <Toolbar id="search-input-filter-toolbar">
        <ToolbarContent>
            <ToolbarItem variant="search-filter" widths="90% ">{searchInput}</ToolbarItem>
            {/* <ToolbarItem variant="icon-button-group" align={{ default: 'alignRight' }}>{checkbox}</ToolbarItem> */}
            {Modalstate ?
                (<ToolbarGroup align={{ default: 'alignRight' }}>
                    <ToolbarItem> <Button onClick={() => latestpfpagination(ScrollID)} size="sm" >Next</Button></ToolbarItem>
                    <ToolbarItem variant="pagination" >{renderPagination('top', false)}</ToolbarItem>
                </ToolbarGroup>)
                : (null)}
        </ToolbarContent>
    </Toolbar>;
    const handleFilter = (fields, value) => {
        const updatedFields = UpdateFilterData(props.filterformdata, fields, 'is', value);
        dispatch(FilterData(updatedFields));
    }
    const renderResult = (result) => {
        let badgeStyle = {};
        if (result === 'passed') {
            badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#3e8635" };
            return (<Badge style={badgeStyle} ><a className='pf-v5-u-color-light-100' onClick={() => { handleFilter('data.sca.check.result', result) }}>{result}</a></Badge>)
        }
        else if (result === "failed") {
            badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#A30000 " };
            return (<Badge style={badgeStyle} ><a className='pf-v5-u-color-light-100' onClick={() => { handleFilter('data.sca.check.result', result) }}>{result}</a></Badge>)
        }
        else if (result === "not applicable") {
            badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#2B9AF3  " };
            return (<Badge style={badgeStyle} ><a className='pf-v5-u-color-light-100' onClick={() => { handleFilter('data.sca.check.result', result) }}>{result}</a></Badge>)
        }
        else {
            return ' ';
        }
    };

    return (

        <React.Fragment>
            {latestpfData.length === 0 ? (
                <Emptystatefornodata />
            ) : (
                <>
                    {toolbar}
                    <InnerScrollContainer>
                    <Table variant="compact" aria-label="latestpflog" id="latestpf-log-table" isStickyHeader>
                        <Thead>
                            <Tr>
                                <Th
                                    key={rows.id}
                                    expand={{
                                        areAllExpanded: !areAllExpanded,
                                        collapseAllAriaLabel: collapseAllAriaLabel,
                                        onToggle: onCollapseAll,
                                    }}
                                ></Th>
                                {columns.map((column, index) => (
                                    <Th key={index} >{column}</Th>
                                ))}
                            </Tr>
                        </Thead>
                        {filteredRows.length > 0 && filteredRows.map((row, rowIndex) => (
                            <Tbody key={rowIndex + 'body'} isExpanded={isRowExpanded(row)}>
                                <Tr key={rowIndex + 'row'}
                                    onRowClick={() => setSelectedRowName(row.id)}
                                    isSelectable
                                    isClickable
                                    isRowSelected={selectedRowName === row.id} >
                                    <Td
                                        expand={
                                            row.details
                                                ? {
                                                    rowIndex: rowIndex,
                                                    isExpanded: isRowExpanded(row),
                                                    onToggle: () =>
                                                        setRowExpanded(row, !isRowExpanded(row)),
                                                }
                                                : undefined
                                        }
                                    >
                                        <ExpandableRowContent key={rowIndex}>
                                            <pre> {row.details ? (
                                                <pre>{JSON.stringify(row.details, null, 2)}</pre>
                                            ) : (
                                                <p>No details available.</p>
                                            )}</pre>
                                        </ExpandableRowContent>
                                    </Td>

                                    <Td width={20} dataLabel="Time">{row?.timestamp}</Td>
                                    <Td width={20} dataLabel="Agent Name"><Link to={"/Individual-Agent-Dashboard/" + row?.agentName + '/' + row?.agentID}>{row?.agentID}:{row?.agentName}</Link></Td>
                                    <Td width={60} dataLabel="Rule Description"><a><Badge key={"badge" + rowIndex}>{row?.checkid}</Badge></a> {row?.ruledesc} {renderResult(row?.result)} </Td>
                                </Tr>
                                {/* <a className='pf-v5-u-color-light-100' onClick={() => { handleFilter('data.vulnerability.status', status) }}>{status}</a> */}
                                <Tr isExpanded={isRowExpanded(row)} >
                                    {/* <Td></Td> */}
                                    <Td colSpan={11} noPadding>
                                        <ExpandableRowContent key={rowIndex}>
                                            {expandedRowData[row.id]?.map((item, index) =>
                                                <React.Fragment key={index}>
                                                    <div className="pf-v5-u-p-sm">
                                                        <TextContent>
                                                            <Text component={TextVariants.h3}>{item.key} </Text>
                                                            <Text  >{item.value}</Text>
                                                        </TextContent>
                                                    </div>
                                                </React.Fragment>
                                            )}


                                        </ExpandableRowContent>
                                    </Td>
                                </Tr>
                            </Tbody>
                        ))}
                        <Tbody>
                            {filteredRows.length === 0 &&
                                <Tr>
                                    <Td colSpan={10}>
                                        <Bullseye>{emptyState}</Bullseye>
                                    </Td>
                                </Tr>}
                        </Tbody>
                    </Table>
                    </InnerScrollContainer>

                    {Modalstate ?
                        (<Flex justifyContent={{ default: 'justifyContentFlexEnd' }} spaceItems={{ default: 'spaceItemsNone' }}>
                            <FlexItem alignSelf={{ default: 'alignSelfCenter' }}>
                                <Button onClick={() => latestpfpagination(ScrollID)} size="sm">Next</Button>
                            </FlexItem>
                            <FlexItem>
                                {renderPagination('bottom', false)}
                            </FlexItem>
                        </Flex>) : (null)
                    }


                </>
            )}
        </React.Fragment>
    );
});
const mapStateToProps = (state) => ({
    filterformdata: state.FilterData,
  });
  
  export default connect(
    mapStateToProps,
    null,
    null,
    { forwardRef: true }
  )(LatestPassedfailedLog)
