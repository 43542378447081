export const StaticTactics = [
    { name: 'Reconnaissance', index: 13 },
    { name: 'Impact', index: 12 },
    { name: 'Exfiltration', index: 11 },
    { name: 'Command and Control', index: 10 },
    { name: 'Collection', index: 9 },
    { name: 'Discovery', index: 8 },
    { name: 'Lateral Movement', index: 7 },
    { name: 'Credential Access', index: 6 },
    { name: 'Defense Evasion', index: 5 },
    { name: 'Privilege Escalation', index: 4 },
    { name: 'Persistence', index: 3 },
    { name: 'Execution', index: 2 },
    { name: 'Initial Access', index: 1 },
     // { "text": "Initial Access", "max": maxCount, "index": 1 },
        //     // { "text": "Execution", "max": maxCount, "index": 2 },
        //     // { "text": "Persistence", "max": maxCount, "index": 3 },
        //     // { "text": "Privilege Escalation", "max": maxCount, "index": 4 },
        //     // { "text": "Defense Evasion", "max": maxCount, "index": 5 },
        //     // { "text": "Credential Access", "max": maxCount, "index": 6 },
        //     // { "text": "Lateral Movement", "max": maxCount, "index": 7 },
        //     // { "text": "Discovery", "max": maxCount, "index": 8 },
        //     // { "text": "Collection", "max": maxCount, "index": 9 },
        //     // { "text": "Command and Control", "max": maxCount, "index": 10 },
        //     // { "text": "Exfiltration", "max": maxCount, "index": 11 },
        //     // { "text": "Impact", "max": maxCount, "index": 12 }
]