import * as echarts from 'echarts';
import React, { useEffect } from 'react';
import { generateUniqueID } from '../../util/UniqueIdGenerator';
import { addInterval, calculateTimeDifference, formatTableTimestamp, formatTime, formatTimestamp } from '../../util/Timeutils';
import { formatValue } from '../../util/ChartHelper';
import { Emptystatefornodata } from '../../util/EmptyStateCard';
import { connect, useDispatch } from 'react-redux';
import { StartEndDate } from '../../Redux/Action';
const TestChart = ({ EventSeries, theme, EventLabel, StartDateTime, EndDateTime, sidebarState, RealTimeDashApi }) => {
    const id = generateUniqueID()
    const dispatch = useDispatch();
    useEffect(() => {
        if (EventSeries && EventSeries.length > 0) {

            const chartDom = document.getElementById(id + 'eventchart');
            const myChart = echarts.init(chartDom);
            const textcolor = theme == 'dark-theme' ? '#fff' : '#000'
            const linecolor = theme == 'dark-theme' ? '#595757' : '#d9d9d9'
            const { resultInMinutes, resultInHours, resultInDays } = calculateTimeDifference(StartDateTime, EndDateTime);

            const Label = EventLabel;


            const uniqueDatesSet = new Set();
            for (let i = 0; i < EventSeries.length; i++) {
                const xvalue = EventSeries[i].labelDate;
                uniqueDatesSet.add(xvalue);
            }

            const xAxisData = []
            // const WholeDate = []
            const uniqueDatesArray = [...uniqueDatesSet];
            for (let i = 0; i < uniqueDatesArray.length; i++) {
                const { ConvertTimestamp, hours, minutes, seconds } = formatTimestamp(uniqueDatesArray[i]);//localStorage.getItem("timezone")
                const formattedTime = formatTime(resultInMinutes, resultInHours, resultInDays, hours, minutes, seconds, ConvertTimestamp);
                xAxisData.push({ x: formattedTime });
                // WholeDate.push({ Date: `${ConvertTimestamp}:${hours}:${minutes}:${seconds}` });
            }


            let interval = ''
            let lineheight = ''
            if (Label === '30 seconds') {
                interval = 4
                lineheight = 100
            }
            else if (Label === 'hour') {
                interval = 3
                lineheight = 80
            }
            else if (Label === 'day') {
                interval = 0
                lineheight = 150
            }
            else {
                interval = 1
                lineheight = 100
            }

            // console.log("xAxisData", xAxisData)

            const option = {
                tooltip: {
                    trigger: 'axis',
                    textStyle: {
                        color: "#000"
                    },
                },
                title: {
                    text: `Timestamp per ${Label}`,
                    top: 'bottom',
                    left: 'center',
                    textStyle: {
                        fontWeight: 450,
                        fontSize: 14,
                        color: textcolor,
                        fontfamily: 'RedHatText, helvetica, arial, sans-serif',
                    },
                },
                grid: {
                    left: '2%',
                    // right: '5%',
                    bottom: '8%',
                    top: '8%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: true,
                    axisLabel: { interval: interval, rotate: -50, margin: 2, color: textcolor },
                    // data: times,
                    data: xAxisData.map(item => item.x),
                },
                yAxis: {
                    axisLine: {
                        show: true
                    },
                    splitLine: {
                        lineStyle: {
                            color: linecolor
                        }
                    },
                    axisLabel: {
                        formatter: formatValue,
                        color: textcolor
                    }
                },
                color: ['#0066cc'],
                series: [
                    {
                        name: 'count',

                        data: EventSeries.map(item => {
                            if (item.Rule_level >= 12) {
                                return {
                                    name: item.labelDate,
                                    value: item.doc_count,
                                    itemStyle: {
                                        color: '#a90000'
                                    }
                                }
                            } else {
                                return {
                                    name: item.labelDate,
                                    value: item.doc_count,
                                }
                            }
                        }),


                        // data: EventSeries.map((item) => {
                        //     return {
                        //         name: item.key_as_string, 
                        //         value: item.doc_count
                        //     }
                        // }),
                        type: 'bar',
                        barWidth: '100%',
                    }
                ]
            };
            option && myChart.setOption(option);
            // console.log(myChart.getOption(option.series))
            myChart.on('click', 'series.bar', function (param) {
                const startDate = param.name;
                // console.log(startDate)
                const compareDates = EventSeries.find(item => {
                    return item.labelDate === startDate
                })

                if (compareDates) {
                    // console.log("startDate", compareDates.labelDate)
                    let StartDate = new Date(compareDates.labelDate);
                    let clickInterval = ''
                    let clickUnit = ''
                    if (Label === '30 seconds') {
                        clickInterval = 30
                        clickUnit = 'seconds'
                    }
                    else if (Label === '30 minutes') {
                        clickInterval = 30
                        clickUnit = 'minutes'
                    }
                    else if (Label === 'hour') {
                        clickInterval = 1
                        clickUnit = 'hour'
                    }
                    else if (Label === 'day') {
                        clickInterval = 24
                        clickUnit = 'hour'
                    }
                    else if (Label === 'week') {
                        clickInterval = 168
                        clickUnit = 'hour'
                    }
                    else if (Label === 'year') {
                        clickInterval = 365 * 24
                        clickUnit = 'hour'
                    }

                    let EndDate = addInterval(StartDate, clickInterval, clickUnit);
                    dispatch(StartEndDate(StartDate, EndDate, `${StartDate}-${EndDate}`));

                }
            });

            const resizeChart = () => {
                if (myChart) { // Check if chart exists before resizing
                  myChart.resize();
                }
              };
    
            window.addEventListener('resize', resizeChart);
            return () => {
                myChart.dispose();
                window.removeEventListener('resize', resizeChart);
            };
        }

    }, [EventSeries, EventLabel, StartDateTime, EndDateTime, theme,sidebarState])

    return <> <div className="container">
        {EventSeries.length == 0 ?
            RealTimeDashApi ?
                <div id={id + 'eventchart'} style={{ width: '100%', height: '290px' }} > </div>
                :
                (<>
                    <div id={id + 'eventchart'} style={{ width: '1px', height: '1px' }}> </div>
                    < Emptystatefornodata />
                </>)
            : (
                <div id={id + 'eventchart'} style={{ width: '100%', height: '290px' }} > </div>
            )
        }
        {/* <div id={id + 'eventchart'} style={{ width: '100%', height: '290px' }} > </div> */}
    </div>
    </>
}
const mapStateToProps = (state) => ({
    dates: { startDate: state.startdate, endDate: state.enddate },
    filterformdata: state.FilterData,
    theme: state.theme,
    sidebarState:state.sidebarstate
    // mapStateToProps content remains unchanged if you have it
});

export default connect(
    mapStateToProps,
    null,
    null,
    { forwardRef: true }
)(TestChart)