import React, { useEffect, useState, forwardRef, useMemo } from 'react'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Divider,
  Flex,
  Grid,
  GridItem,
  Modal,
  ModalVariant,
  Tab,
  TabTitleText,
  Tabs,

} from '@patternfly/react-core';
import dbApi from '../../services/DbApi';
import  ActionChart  from './ActionChart';
import  EventSummary  from './EventsSummary'
import { TopUsers } from './TopUsers';
import EAreaChart from '../AllCharts/EAreaChart';
import EDonutChart from '../AllCharts/EDonutChart';
import { connect, useDispatch } from 'react-redux';
import TableViewForChart from '../AllCharts/TableViewForChart';
import { Emptystatefornodata } from '../../util/EmptyStateCard';
import { Loader } from '../../util/Loadingstate';

const IntegrityDashboard = forwardRef(function IntegrityEvents(props, ref) {

  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [ruleseries, setRuleseries] = useState([]);
  const [actionseries, setActionseries] = useState([]);
  const [agentseries, setAgentseries] = useState([]);
  const [alertactionseries, setAlertActionSeries] = useState([]);
  const [alertlabel, setAlertLabel] = useState();
  const [eventseries, setEventSeries] = useState([]);
  const [summarylabel, setSummaryLabel] = useState();
  const [userTable, setUserTable] = useState([]);
  const [activeTabKey, setActiveTabKey] = useState(0);
  const [modifyfile, setModifyFile] = useState([])
  const [deletefile, setDeleteFile] = useState([])
  const [addedfile, setAddedFile] = useState([])
  //Loading States
  const [isloadalert, setIsLoadAlert] = useState(true)
  const [isloadevent, setIsLoadEvent] = useState(true)
  const [isloadagent, setIsLoadAgent] = useState(true)
  const [isloadrule, setIsLoadRule] = useState(true)
  const [isloadaction, setIsLoadAction] = useState(true)
  const [isloadmodify, setIsLoadModify] = useState(true)
  const [isloaddelete, setIsLoadDelete] = useState(true)
  const [isloadadded, setIsLoadAdded] = useState(true)
  const [isloadUsers, setIsLoadUsers] = useState(true)

  //Modal states
  const [modalStates, setModalStates] = useState({
    modalRuleDistribution: false,
    modalAction: false,
    modalAgents: false,
    modalTable: false,
    modalActiveusers: false,
    modalfileadd: false,
    modalfilemodify: false,
    modalfiledelete: false,
  });

  const handleModalToggle = (modalName) => {
    setModalStates((prevStates) => ({
      ...prevStates,
      [modalName]: !prevStates[modalName],
    }));
  };

  const handleTabClick = (event, tabIndex) => {
    setActiveTabKey(tabIndex);
  };
  const getAlertsByAction = async (data) => {
    try {
      setIsLoadAlert(true)
      let all_alerts = await dbApi.postApi("Opensearch/Integrity_Monitoring_Dashboard_Alerts_By_Action_Over_Time", data);
      console.log(all_alerts)
      let alertactionseries = [];
      let alertlabel = "";
      // Integrity_Monitoring_Dashboard_Alerts_By_Action_Over_Time
      if (Object.keys(all_alerts.Integrity_Monitoring_Dashboard_Alerts_By_Action_Over_Time).length !== 0) {
        let alert_by_action = all_alerts.Integrity_Monitoring_Dashboard_Alerts_By_Action_Over_Time.data.body.aggregations.Alerts_by_action_over_time;
        let alert_label = all_alerts.Integrity_Monitoring_Dashboard_Alerts_By_Action_Over_Time.interval

        alertactionseries = alert_by_action.buckets
        alertlabel = alert_label
        setAlertActionSeries(alertactionseries)
        setAlertLabel(alertlabel)
      }
      else {
        setAlertActionSeries([])
        setAlertLabel('')
      }
      setIsLoadAlert(false)
    } catch (error) {
      console.log(error)
      setAlertActionSeries([])
      setAlertLabel('')
      setIsLoadAlert(false)
    }
  }
  const getTopAgents = async (data) => {
    try {
      setIsLoadAgent(true)
      let all_agents = await dbApi.postApi("Opensearch/Integrity_Monitoring_Dashboard__Top_5_Agents ", data);
      console.log(all_agents)
      // Integrity_Monitoring_Dashboard__Top_5_Agents
      let agentIM = all_agents.Integrity_Monitoring_Dashboard__Top_5_Agents
      let AgentSeries = [];
      if (Object.keys(agentIM).length > 0) {
        AgentSeries = agentIM.body.aggregations.Top_5_agents.buckets
        setAgentseries(AgentSeries)
      }
      else {
        setAgentseries(AgentSeries)
      }
      setIsLoadAgent(false)
    } catch (error) {
      console.log(error)
      setAgentseries([])
      setIsLoadAgent(false)
    }
  }
  const getRuleDistribution = async (data) => {
    try {
      setIsLoadRule(true)
      let all_rule = await dbApi.postApi("Opensearch/Integrity_Monitoring_Dashboard_Rule_Distribution", data);
      console.log(all_rule)
      // Integrity_Monitoring_Dashboard_Rule_Distribution
      let RuleSeries = [];
      if (Object.keys(all_rule.Integrity_Monitoring_Dashboard_Rule_Distribution).length !== 0) {
        let rule_distribution = all_rule.Integrity_Monitoring_Dashboard_Rule_Distribution.body.aggregations.Rule_distribution;
        RuleSeries = rule_distribution.buckets
        setRuleseries(RuleSeries)
      }
      else {
        setRuleseries(RuleSeries)
      }
      setIsLoadRule(false)
    } catch (error) {
      console.log(error)
      setRuleseries([])
      setIsLoadRule(false)
    }
  }
  const getActions = async (data) => {
    try {
      setIsLoadAction(true)
      let all_action = await dbApi.postApi("Opensearch/Integrity_Monitoring_Dashboard_Actions", data);
      console.log(all_action)
      let ActionSeries = [];
      if (Object.keys(all_action.Integrity_Monitoring_Dashboard_Actions).length !== 0) {
        let Actions = all_action.Integrity_Monitoring_Dashboard_Actions.body.aggregations.Actions;
        ActionSeries = Actions.buckets
        setActionseries(ActionSeries)
      }
      else {
        setActionseries(ActionSeries)
      }
      setIsLoadAction(false)
    } catch (error) {
      console.log(error)
      setActionseries([])
      setIsLoadAction(false)
    }
  }
  // Opensearch/Integrity_Monitoring_Dashboard_Events_Summary 

  const getEventSummary = async (data) => {
    try {
      setIsLoadEvent(true)
      let all_events = await dbApi.postApi("Opensearch/Integrity_Monitoring_Dashboard_Events_Summary", data);
      console.log(all_events)
      let EventSeries = [];
      let SummaryLabel = ""
      let Info = all_events.Integrity_Monitoring_Dashboard_Events_Summary
      // Integrity_Monitoring_Dashboard_Events_Summary
      if (Object.keys(Info).length !== 0) {
        EventSeries = Info.data.body.aggregations.date_histogram_agg.buckets;
        SummaryLabel = Info.interval
        setEventSeries(EventSeries)
        setSummaryLabel(SummaryLabel)
      }
      else {
        setEventSeries(EventSeries)
        setSummaryLabel(SummaryLabel)
      }
      setIsLoadEvent(false)
    } catch (error) {
      console.log(error)
      setEventSeries([])
      setSummaryLabel('')
      setIsLoadEvent(false)
    }
  }

  const getTopUsers = async (data) => {
    try {
      setIsLoadUsers(true)
      let all_users = await dbApi.postApi("Opensearch/Integrity_Monitoring_Dashboard_Top_5_users", data);
      console.log(all_users)
      let UserTable = [];
      let allUsers = all_users.Integrity_Monitoring_Dashboard_Top_5_users;
      if (Object.keys(allUsers).length > 0) {
        UserTable = allUsers.sort((a, b) => b.count - a.count);
        setUserTable(UserTable)
      }
      else {
        setUserTable(UserTable)
      }
      setIsLoadUsers(false)
    } catch (error) {
      console.log(error)
      setUserTable([])
      setIsLoadUsers(false)
    }
  }

  //Modified file individual
  const getModifiedFile = async (data) => {
    try {
      setIsLoadModify(true)
      let all_Modified_Files = await dbApi.postApi("Opensearch/Integrity_Monitoring_Files_Modified", data);
      console.log(all_Modified_Files)
      let ModifyFile = [];
      if (Object.keys(all_Modified_Files.Integrity_Monitoring_Files_Modified).length !== 0) {
        let ModifiedFiles = all_Modified_Files.Integrity_Monitoring_Files_Modified.body.aggregations.File_Modified.buckets;
        ModifyFile = ModifiedFiles
        setModifyFile(ModifyFile)
      }
      else {
        setModifyFile(ModifyFile)
      }
      setIsLoadModify(false)
    } catch (error) {
      console.log(error)
      setModifyFile([])
      setIsLoadModify(false)
    }
  }
  //deleted file individual
  const getDeletedFile = async (data) => {
    try {
      setIsLoadDelete(true)
      let all_Delete_File = await dbApi.postApi("Opensearch/Integrity_Monitoring_Files_Deleted", data);
      console.log(all_Delete_File)
      let DeleteFile = []
      if (Object.keys(all_Delete_File.Integrity_Monitoring_Files_Deleted).length !== 0) {
        DeleteFile = all_Delete_File.Integrity_Monitoring_Files_Deleted.body.aggregations.File_Deleted.buckets;
        setDeleteFile(DeleteFile)
      }
      else {
        setDeleteFile(DeleteFile)
      }
      setIsLoadDelete(false)
    } catch (error) {
      console.log(error)
      setDeleteFile([])
      setIsLoadDelete(false)
    }
  }
  // Opensearch/Integrity_Monitoring_File_added
  const getAddedFile = async (data) => {
    try {
      setIsLoadAdded(true)
      let all_Added_File = await dbApi.postApi("Opensearch/Integrity_Monitoring_File_added", data);
      console.log(all_Added_File)
      let AddedFile = [];
      if (Object.keys(all_Added_File.Integrity_Monitoring_File_added).length !== 0) {
        AddedFile = all_Added_File.Integrity_Monitoring_File_added.body.aggregations.File_added.buckets;
        setAddedFile(AddedFile)
      }
      else {
        setAddedFile(AddedFile)
      }
      setIsLoadAdded(false)
    } catch (error) {
      console.log(error)
      setAddedFile([])
      setIsLoadAdded(false)
    }
  }
  const IntegritydashboardApi = async (startDate, endDate, allFields) => {
    const Fields = allFields
    const data = {
      startDate: startDate,
      endDate: endDate,
      filter: JSON.stringify(Fields),
    };
    try {
      setStartDate(startDate);
      setEndDate(endDate);
      getTopAgents(data);
      getRuleDistribution(data);
      getActions(data);
      getAlertsByAction(data);
      getEventSummary(data);
      getTopUsers(data);
      if (props?.hideCard) {
        getModifiedFile(data)
        getDeletedFile(data)
        getAddedFile(data)
      }
      console.log(data)
      // await getLatestCve(data);
    }
    catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (props.dates.startDate != "") {
      let Dates = props.dates
      IntegritydashboardApi(Dates.startDate, Dates.endDate, props.filterformdata,)
    }
  }, [props?.dates.startDate, props?.dates.endDate, props?.filterformdata]);


  // const setActionData = () => {
  //   let actiondata = moreactionseries
  //   setMoreActionseries([])
  //   setTimeout(function () { setMoreActionseries(actiondata) }, 100);
  // }

  const colorforalerts = ['#8BC1F7', '#06C', '#002F5D']
  const colorforruledist = ['#519DE9', '#004B95', '#8BC1F7', '#06C', '#002F5D']

  const TopAgents = useMemo(() => {
    return (
      isloadagent ? <Loader /> :
        <EDonutChart seriesData={agentseries.slice(0, 5)} height='210px' color={colorforruledist} filterFields={'agent.name'} />
    )
  }, [isloadagent, agentseries])

  const IMRuleDistribution = useMemo(() => {
    return (
      isloadrule ? <Loader /> :
        <EDonutChart seriesData={ruleseries.slice(0, 5)} height='210px' color={colorforruledist} filterFields={'rule.description'} />
    )
  }, [isloadrule, ruleseries])

  const IMTopUsers = useMemo(() => {
    return (
      isloadUsers ? <Loader /> :
        userTable.length == 0 ? <Emptystatefornodata /> :
          <TopUsers UserTableData={userTable.slice(0, 5)} defaultColumn={["Top User", "Agent Name", "Count",]} InModal={modalStates.modalTable} />
    )
  }, [isloadUsers, userTable])

  const MostActiveUsers = useMemo(() => {
    return (
      isloadUsers ? <Loader /> :
        <EDonutChart seriesData={userTable.slice(0, 5)} height='210px' color={colorforruledist} filterFields={'syscheck.uname_after'} />
    )
  }, [isloadUsers, userTable])

  const ModifiedFile = useMemo(() => {
    return (
      isloadmodify ? <Loader /> :
        <EDonutChart
          seriesData={modifyfile.slice(0, 5)}
          height='210px' color={colorforruledist}
          filterFields={'syscheck.path'} />
    )
  }, [isloadmodify, modifyfile])
  const IMAddedFile = useMemo(() => {
    return (
      isloadadded ? <Loader /> :
        <EDonutChart seriesData={addedfile.slice(0, 5)} height='210px' color={colorforruledist} filterFields={'syscheck.path'} />
    )
  }, [isloadadded, addedfile])
  const IMDeleteFile = useMemo(() => {
    return (
      isloaddelete ? <Loader /> :
        <EDonutChart seriesData={deletefile.slice(0, 5)} height='210px' color={colorforruledist} filterFields={'syscheck.path'} />
    )
  }, [isloaddelete, deletefile])

  return (<>
    <div>
      <Grid hasGutter>
        <GridItem md={12} sm={12} lg={6}>
          <Card  isFullHeight>
            <CardTitle>Alerts by action over time</CardTitle>
            <Divider />
            <CardBody>
              {isloadalert ? <Loader /> :
                <EAreaChart
                  alertlevelSeries={alertactionseries}
                  label={alertlabel}
                  StartDateTime={startDate}
                  EndDateTime={endDate}
                  color={colorforalerts}
                  filterFields={'syscheck.event'}
                />
              }
             
            </CardBody>
          </Card>
        </GridItem>
        <GridItem md={12} sm={12} lg={6} >
          <Card  isFullHeight>
            <CardTitle>Event Summary</CardTitle>
            <Divider />
            <CardBody>
              {isloadevent ? <Loader /> :
                eventseries.length > 0 ?
                  <EventSummary
                    EventSummarydata={eventseries}
                    EventSummarylabel={summarylabel}
                    StartDateTime={startDate}
                    EndDateTime={endDate}
                  /> :
                  <Emptystatefornodata />
              }
            </CardBody>
          </Card>
        </GridItem>
      </Grid>
    </div>
    <div className="pf-v5-u-mt-md">
      <Grid hasGutter>
        <GridItem md={12} sm={12} lg={4} >
          {props?.hideCard ?
            <Card  isFullHeight>
              <CardTitle>Most Active Users</CardTitle>
              <Divider />
              <CardBody>{MostActiveUsers} </CardBody>
              {userTable.length > 0 &&
                <CardFooter>
                  <Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
                    <Button variant="link" onClick={() => { setActiveTabKey(0); handleModalToggle('modalActiveusers'); }}>
                      More
                    </Button>
                    <Modal
                      variant={ModalVariant.large}
                      title={`Most Active Users (${userTable.length})`}
                      isOpen={modalStates.modalActiveusers}
                      onClose={() => handleModalToggle('modalActiveusers')}
                    >
                      <Divider className='modal-Divider' />
                      <Card>
                      <Tabs activeKey={activeTabKey} onSelect={handleTabClick} aria-label="Tabs in the default example" role="region">
                        <Tab eventKey={0} title={<TabTitleText>Table View</TabTitleText>}>
                          {isloadUsers ? <Loader /> : <TableViewForChart Tabledata={userTable} columnData={["Active User", "Count"]} filterFields={'syscheck.uname_after'} />}
                        </Tab>
                        <Tab eventKey={1} title={<TabTitleText>Graph View</TabTitleText>} aria-label="graph">
                          <EDonutChart seriesData={userTable.slice(0, 30)} height='700px' color={colorforruledist} filterFields={'syscheck.uname_after'} />
                        </Tab>
                      </Tabs>
                      </Card>
                    </Modal>
                  </Flex>
                </CardFooter>
              }
            </Card>
            :
            <Card  isFullHeight>
              <CardTitle>Top Agents</CardTitle>
              <Divider />
              <CardBody> {TopAgents}</CardBody>
              {agentseries.length > 0 &&
                <CardFooter>
                  <Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
                    <Button variant="link" onClick={() => { setActiveTabKey(0); handleModalToggle('modalAgents'); }}>
                      More
                    </Button>
                    <Modal
                      variant={ModalVariant.large}
                      title={`Top Agents (${agentseries.length})`}
                      isOpen={modalStates.modalAgents}
                      onClose={() => handleModalToggle('modalAgents')}
                    >
                      <Divider className='modal-Divider' />
                      <Card>
                      <Tabs activeKey={activeTabKey} onSelect={handleTabClick} aria-label="Tabs in the default example" role="region">
                        <Tab eventKey={0} title={<TabTitleText>Table View</TabTitleText>}>
                          {isloadagent ? <Loader /> : <TableViewForChart Tabledata={agentseries} columnData={["Agent", "Count"]} filterFields={'agent.name'} />}
                        </Tab>
                        <Tab eventKey={1} title={<TabTitleText>Graph View</TabTitleText>} aria-label="graph">
                          <EDonutChart seriesData={agentseries.slice(0, 30)} height='1000px' color={colorforruledist} filterFields={'agent.name'} />
                        </Tab>
                      </Tabs>
                      </Card>
                    </Modal>
                  </Flex>
                </CardFooter>
              }
            </Card>
          }
        </GridItem>

        <GridItem md={12} sm={12} lg={4}>
          <Card  isFullHeight>
            <CardTitle>Top Rule distribution</CardTitle>
            <Divider />
            <CardBody>{IMRuleDistribution} </CardBody>
            {ruleseries.length > 0 && <CardFooter>
              <Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
                <Button variant="link" onClick={() => { setActiveTabKey(0); handleModalToggle('modalRuleDistribution'); }}>
                  More
                </Button>
                <Modal
                  variant={ModalVariant.large}
                  title={`Top Rule distribution (${ruleseries.length})`}
                  isOpen={modalStates.modalRuleDistribution}
                  onClose={() => handleModalToggle('modalRuleDistribution')}
                >
                  <Divider className='modal-Divider' />
                  <Card>
                  <Tabs activeKey={activeTabKey} onSelect={handleTabClick} aria-label="Tabs in the default example" role="region">
                    <Tab eventKey={0} title={<TabTitleText>Table View</TabTitleText>}>
                      {isloadrule ? <Loader /> : <TableViewForChart Tabledata={ruleseries} columnData={["Rule Distribution", "Count"]} filterFields={'rule.description'} />}
                    </Tab>
                    <Tab eventKey={1} title={<TabTitleText>Graph View</TabTitleText>} aria-label="graph">
                    {activeTabKey==1 &&  <EDonutChart seriesData={ruleseries} height='700px' color={colorforruledist} filterFields={'rule.description'} />}
                    </Tab>
                  </Tabs>
                </Card>
                </Modal>
              </Flex>
            </CardFooter>}
          </Card>
        </GridItem>
        <GridItem md={12} sm={12} lg={4}>
          <Card  isFullHeight>
            <CardTitle>Actions</CardTitle>
            <Divider />
            <CardBody>
              {isloadaction ? <Loader /> :
                actionseries.length > 0 ?
                  <ActionChart ActionData={actionseries} height="210px" color={colorforruledist} filterFields={'syscheck.event'} filterformdata={props.filterformdata} /> :
                  <Emptystatefornodata />
              }
            </CardBody>
          </Card>
        </GridItem>

      </Grid>
    </div>
    {props?.hideCard ? '' :
      <div className="pf-v5-u-mt-md">
        <Grid hasGutter>
          <GridItem md={12} sm={12} lg={12}>
            <Card  isFullHeight height={180}>
              <CardTitle>Top 5 Users</CardTitle>
              <Divider />
              <CardBody>{IMTopUsers} </CardBody>
              {userTable.length > 0 &&
                <CardFooter>
                  <Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
                    <Button variant="link" onClick={() => { handleModalToggle('modalTable'); }}>
                      More
                    </Button>
                    <Modal
                      variant={ModalVariant.large}
                      title='Top Users'
                      isOpen={modalStates.modalTable}
                      onClose={() => handleModalToggle('modalTable')}
                    >
                      <Divider className='modal-Divider' />
                      <Card>
                      <TopUsers UserTableData={userTable} defaultColumn={["Top User", "Agent Name", "Count",]} InModal={modalStates.modalTable} />
                    </Card>
                    </Modal>
                  </Flex>
                </CardFooter>
              }
            </Card>
          </GridItem>
        </Grid>
      </div>
    }
    {props?.hideCard ?
      <div className="pf-v5-u-mt-md">
        <Grid hasGutter>
          <GridItem md={12} sm={12} lg={4}>
            <Card  isFullHeight>
              <CardTitle>Files Modified</CardTitle>
              <Divider />
              <CardBody> {ModifiedFile} </CardBody>

              {modifyfile.length > 0 && <CardFooter>
                <Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
                  <Button variant="link" onClick={() => { setActiveTabKey(0); handleModalToggle('modalfilemodify'); }}>
                    More
                  </Button>
                  <Modal
                    variant={ModalVariant.large}
                    title={`Files Modified (${modifyfile.length})`}
                    isOpen={modalStates.modalfilemodify}
                    onClose={() => handleModalToggle('modalfilemodify')}
                  >
                    <Divider className='modal-Divider' />
                    <Card>
                    <Tabs activeKey={activeTabKey} onSelect={handleTabClick} aria-label="Tabs in the default example" role="region">
                    
                      <Tab eventKey={0} title={<TabTitleText>Table View</TabTitleText>}>
                        {isloadmodify ? <Loader /> : <TableViewForChart Tabledata={modifyfile} columnData={["Modified File", "Count"]} filterFields={'syscheck.path'} />}
                      </Tab>
                      <Tab eventKey={1} title={<TabTitleText>Graph View</TabTitleText>} aria-label="graph">
                        <EDonutChart seriesData={modifyfile.slice(0, 10)} height='1000px' color={colorforruledist} filterFields={'syscheck.path'} />
                      </Tab>
                    </Tabs>
                    </Card>
                  </Modal>
                </Flex>
              </CardFooter>}
            </Card>
          </GridItem>
          <GridItem md={12} sm={12} lg={4}>
            <Card  isFullHeight>
              <CardTitle>Files Added</CardTitle>
              <Divider />
              <CardBody> {IMAddedFile} </CardBody>
              {addedfile.length > 0 && <CardFooter>
                <Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
                  <Button variant="link" onClick={() => { setActiveTabKey(0); handleModalToggle('modalfileadd'); }}>
                    More
                  </Button>
                  <Modal
                    variant={ModalVariant.large}
                    title={`Files Added (${addedfile.length})`}
                    isOpen={modalStates.modalfileadd}
                    onClose={() => handleModalToggle('modalfileadd')}
                  >
                    <Divider className='modal-Divider' />
                    <Card>
                    <Tabs activeKey={activeTabKey} onSelect={handleTabClick} aria-label="Tabs in the default example" role="region">
                    
                      <Tab eventKey={0} title={<TabTitleText>Table View</TabTitleText>}>
                        {isloadadded ? <Loader /> : <TableViewForChart Tabledata={addedfile} columnData={["Added File", "Count"]} filterFields={'syscheck.path'} />}
                      </Tab>
                      <Tab eventKey={1} title={<TabTitleText>Graph View</TabTitleText>} aria-label="graph">
                        <EDonutChart seriesData={addedfile.slice(0, 15)} height='700px' color={colorforruledist} filterFields={'syscheck.path'} />
                      </Tab>
                    </Tabs>
                    </Card>
                  </Modal>
                </Flex>
              </CardFooter>}

            </Card>
          </GridItem>
          <GridItem md={12} sm={12} lg={4}>
            <Card  isFullHeight>
              <CardTitle>Files Deleted</CardTitle>
              <Divider />
              <CardBody>{IMDeleteFile}</CardBody>
              {deletefile.length > 0 && <CardFooter>
                <Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
                  <Button variant="link" onClick={() => { setActiveTabKey(0); handleModalToggle('modalfiledelete'); }}>
                    More
                  </Button>
                  <Modal
                    variant={ModalVariant.large}
                    title={`Files deleted (${deletefile.length})`}
                    isOpen={modalStates.modalfiledelete}
                    onClose={() => handleModalToggle('modalfiledelete')}
                  >
                    <Divider className='modal-Divider' />
                    <Card>
                    <Tabs activeKey={activeTabKey} onSelect={handleTabClick} aria-label="Tabs in the default example" role="region">
                      <Tab eventKey={0} title={<TabTitleText>Table View</TabTitleText>}>
                        {isloaddelete ? <Loader /> : <TableViewForChart Tabledata={deletefile} columnData={["Deleted File", "Count"]} filterFields={'syscheck.path'} />}
                      </Tab>
                      <Tab eventKey={1} title={<TabTitleText>Graph View</TabTitleText>} aria-label="graph">
                        <EDonutChart seriesData={deletefile} height='400px' color={colorforruledist} filterFields={'syscheck.path'} />
                      </Tab>
                    </Tabs>
                    </Card>
                  </Modal>
                </Flex>
              </CardFooter>}
            </Card>
          </GridItem>
        </Grid>
      </div>
      : ''}
  </>
  )
})

const mapStateToProps = (state) => ({
  dates: { startDate: state.startdate, endDate: state.enddate },
  filterformdata: state.FilterData,
  selectedGroup: state.SelectedGroup,
  // indfilterdata: state.IndFilter,

  // mapStateToProps content remains unchanged if you have it
});

const mapDispatchToProps = (dispatch) => { return {}; };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(IntegrityDashboard)




