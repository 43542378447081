//2nd which is perfectly run and usable
import React, { useEffect, useState } from "react";
import {
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Table,
} from "@patternfly/react-table";
import { Badge, Card, Pagination, Toolbar, ToolbarContent, ToolbarItem } from "@patternfly/react-core";
import { Link } from "react-router-dom";
export const TopUsers = ({ UserTableData, defaultColumn, InModal }) => {

  const [columns, setColumns] = useState(defaultColumn);
  const [rows, setRows] = useState([]);
  const [defaultRows, setDefaultRows] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [selectedRowName, setSelectedRowName] = useState('');

  useEffect(() => {
    const newdefaultRows = UserTableData.map((log) => {
      return {

        topuser: log?.topuser,
        agentid: log?.agentid,
        agentname: log?.agentname,
        count: log?.count,
        //external ip card for individual dash 
        externalip: log?.key,
        ipcount: log?.doc_count,
        //data for agent list 
        agentID: log?.AgentID,
        agentDevice: log?.AgentDevice,
        agentName: log?.AgentName,
        agentIP: log?.AgentIP,
        agentGroup: log?.AgentGroup,
        agentDesc: log?.AgentDesc,
        agentStatus: log?.AgentStatus,
        agentRegDate: log?.AgentRegDate,
        agentlastupdate: log?.Agentlastupdate
      };
    });

    setDefaultRows(newdefaultRows)
    const startIndex = (page - 1) * perPage;
    const endIndex = startIndex + perPage;
    const slicedRows = newdefaultRows.slice(startIndex, endIndex);
    setRows(slicedRows);
  }, [UserTableData, rows.length]);

  const handleSetPage = (_evt, newPage) => {
    setPage(newPage);
  };
  const handlePerPageSelect = (_evt, newPerPage, newPage) => {

    setPage(newPage);
    setPerPage(newPerPage);
  };

  useEffect(() => {
    const startIndex = (page - 1) * perPage;
    const endIndex = startIndex + perPage;
    const slicedRows = defaultRows.slice(startIndex, endIndex);
    // console.log("after slicing", "", startIndex, endIndex, slicedRows)
    setRows(slicedRows);
  }, [defaultRows, page, perPage]);

  const renderPagination = (variant,compact) =>
    <Pagination
      itemCount={defaultRows.length}
      page={page}
      isCompact={compact}
      perPage={perPage}
      onSetPage={handleSetPage}
      onPerPageSelect={handlePerPageSelect}
      variant={variant}
    />

  return (

    <React.Fragment>
      <>
    
        {InModal ?
          <Toolbar>
            <ToolbarContent>
              <ToolbarItem variant="pagination">{renderPagination('top', false)}</ToolbarItem>
            </ToolbarContent>
          </Toolbar> : null}
        <Table variant="compact" aria-label="userlog" id="user-log-table" isStickyHeader>
          <Thead>
            <Tr>
              {columns.map((column) => (
                <Th key={column}>{column}</Th>
              ))}
            </Tr>
          </Thead>
          {rows.map((row, rowIndex) => {
            const effectiveIndex = (page - 1) * perPage + rowIndex;
            const BackgroundColor = page === 1 && effectiveIndex < 10;
            return(
            <Tbody key={rowIndex} >
              <Tr key={rowIndex}
                onRowClick={() => setSelectedRowName(rowIndex)}
                isSelectable
                isClickable
                className={InModal?BackgroundColor ? "pf-v5-u-background-color-danger" : "":''}
                isRowSelected={selectedRowName === rowIndex}  >
                {columns.includes('Top User') && <Td width={30} dataLabel={'Top User'}>{row?.topuser}</Td>}
                {columns.includes('Agent Name') && <Td width={30} dataLabel={'Agent Name'}> <Link to={"/Individual-Agent-Dashboard/" + row?.agentname + '/' + row?.agentid}>{row?.agentid}: {row?.agentname}</Link></Td>}
                {columns.includes('Count') && <Td width={15} dataLabel={'Count'}>{row?.count}</Td>}
                {columns.includes('External IP') && <Td width={15} dataLabel={'External IP'}>{row?.externalip}</Td>}
                {columns.includes('count') && <Td width={15} dataLabel={'count'}>{row?.ipcount}</Td>}
                {/* Data for agent less list */}
                {columns.includes('ID') && <Td width={10} dataLabel={'ID'}>{row?.agentID} </Td>}
                {columns.includes('Name') && <Td width={10} dataLabel={'Name'}>{row?.agentName}</Td>}
                {columns.includes('Device type') && <Td width={10} dataLabel={'Device type'}>{row?.agentDevice}</Td>}
                {columns.includes('Description') && <Td width={10} dataLabel={'Description'}>{row?.agentDesc}</Td>}
                {columns.includes('IP') && <Td width={10} dataLabel={'IP'}>{row?.agentIP}</Td>}
                {columns.includes('Group(s)') && <Td width={10} dataLabel={'Group(s)'}>{row?.agentGroup}</Td>}
                {columns.includes('Registration Date') && <Td width={10} dataLabel={'Registration Date'}>{row?.agentRegDate}</Td>}
                {columns.includes('Last Update Date') && <Td width={20} dataLabel={'Last Update Date'}>{row?.agentlastupdate}</Td>}
                {columns.includes('Status') && <Td width={10} dataLabel={'Status'}>{row?.agentStatus}</Td>}

              </Tr>
            </Tbody>
            )
          })}
        </Table>
      {InModal &&renderPagination('bottom',false)}
    
      </>
    </React.Fragment>
  );
};
