//2nd which is perfectly run and usable
import React, { useEffect, useState } from "react";
import {
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Table,
} from "@patternfly/react-table";
import dbApi from '../../services/DbApi'
import { Emptystatefornodata } from "../../util/EmptyStateCard";
import { Divider, Modal, ModalVariant, Pagination, Toolbar, ToolbarContent, ToolbarItem } from "@patternfly/react-core";
import { Loader } from "src/util/Loadingstate";

export const ExternalIP = (props) => {
  const defaultColumns = [
    "External IP",
    "Count",
  ];
  const [columns, setColumns] = useState(defaultColumns);
  const [defaultRows, setDefaultRows] = useState([]);
  const [rows, setRows] = useState([]);
  const [selectedRowName, setSelectedRowName] = React.useState('');
  const [ipdetails, setIPDetails] = React.useState('')
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isloading, setIsLoading] = useState(false)
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen)
  };
  const handleSetPage = (_evt, newPage) => {
    setPage(newPage);
  };
  const handlePerPageSelect = (_evt, newPerPage, newPage) => {
    setPage(newPage);
    setPerPage(newPerPage);
  };

  useEffect(() => {
    // console.log("message",ExternalIPData)
    const newdefaultRows =props?.ExternalIPData.map((log) => {
      return {
        externalIP: log.externalIP,
        count: log.count,
        detail: log.nextapiKey,
        countrycode: log?.countrycode
      };
    });

    setDefaultRows(newdefaultRows)
    const startIndex = (page - 1) * perPage;
    const endIndex = startIndex + perPage;
    const slicedRows = newdefaultRows.slice(startIndex, endIndex);
    // if (defaultRows.length !== rows.length) {
    //   const slicedRows = defaultRows;
    setRows(slicedRows);
  }, [props.ExternalIPData, rows.length]);


  useEffect(() => {
    const startIndex = (page - 1) * perPage;
    const endIndex = startIndex + perPage;
    const slicedRows = defaultRows.slice(startIndex, endIndex);
    // console.log("after slicing", "", startIndex, endIndex, slicedRows)
    setRows(slicedRows);
  }, [defaultRows, page, perPage]);


  //API for particular IP details 
  const detailModalApi = async (data) => {
    const data1 = {
      type: 'IP',
      value: data
    }
    try {
      setIsLoading(true)
      let res = await dbApi.postApi("Opensearch/Network_Check_ExternalIP", data1)
      console.log(res)
      setIPDetails(res.Network_Check_ExternalIP);
      setIsLoading(false)
    }
    catch (error) {
      console.log(error)
      setIsLoading(false)
    }
  }

const renderPagination = () =>
    <Pagination
      itemCount={defaultRows.length}
      page={page}
      perPage={perPage}
      onSetPage={handleSetPage}
      onPerPageSelect={handlePerPageSelect}
    />;

  return (
    <React.Fragment>
      <Modal
        variant={ModalVariant.medium}
        title={"Detail Of External IP "}
        isOpen={isModalOpen}
        onClose={() => handleModalToggle()}
      >
        <Divider className='modal-Divider' />
        {isloading ? <Loader /> :
          <pre>{JSON.stringify(ipdetails, null, 2)}</pre>
        }
      </Modal>
      {props?.ExternalIPData.length === 0 ? (
        <Emptystatefornodata />
      ) : (
        <>
         <Toolbar>
            <ToolbarContent>
              <ToolbarItem variant="pagination">{props.Pagination !== false && renderPagination()}</ToolbarItem>
            </ToolbarContent>
          </Toolbar>
          <Table variant="compact" aria-label="IPlog" id="IPlog-table" isStickyHeader>
            <Thead >
              <Tr>
                {columns.map((column) => (
                  <Th key={column}>{column}</Th>
                ))}
              </Tr>
            </Thead>
            {rows.map((row, rowIndex) => (
              <Tbody key={rowIndex}  >
                <Tr key={rowIndex}
                  onRowClick={() => setSelectedRowName(rowIndex)}
                  isSelectable
                  isClickable
                  isRowSelected={selectedRowName === rowIndex}  >
                  <Td width={70}>  <a onClick={async () => { await detailModalApi(row?.detail); handleModalToggle(); }}>
                    {row?.countrycode != '' && <img width='20' src={`https://flagcdn.com/48x36/${row?.countrycode.toLowerCase()}.png`} />
                    } {row?.externalIP} </a> </Td>
                  <Td width={30} >{row?.count}</Td>
                </Tr>
              </Tbody>
            ))}
          </Table>
          {props.Pagination !== false && <div className="pf-v5-u-mt-md">{renderPagination()}</div>}
        </>
      )}
    </React.Fragment>
  );
};
