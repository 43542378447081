//2nd which is perfectly run and usable
import React, { useEffect, useState } from "react";
import {
  ExpandableRowContent,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Table,
} from "@patternfly/react-table";
import {
  Badge,
  DescriptionList,
  DescriptionListDescription,
  DescriptionListGroup,
  DescriptionListTerm,
  Tab,
  Tabs,
  TabTitleText,
  TabContentBody,
  Text,
  Tooltip,
  ListItem,
  List,
  Toolbar,
  ToolbarItem,
  Pagination,
  ToolbarGroup,
  ToolbarContent,
  FlexItem,
  Flex,
  Button,
  SearchInput,
} from "@patternfly/react-core";
import { ArrowLeftIcon, ArrowRightIcon, SearchIcon } from "@patternfly/react-icons";
import dbApi from '../../services/DbApi'
import { formatTableTimestamp } from "../../util/Timeutils";
import { Emptystatefornodata } from "../../util/EmptyStateCard";
import { Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { UpdateFilterData } from "src/util/FilterHelper";
import { FilterData } from "src/Redux/Action";

const { forwardRef, useRef, useImperativeHandle } = React;

const AlertsAnomalyLog = forwardRef((props, ref) => {
  const dispatch = useDispatch()
  const [anomalyalertslog, setAnomalyalertslog] = React.useState([])
  const [ScrollID, setScrollID] = React.useState('')
  const [Modalstate, setModalstate] = React.useState(false)

  useImperativeHandle(ref, () => ({
    setAnomalyAlerts(data = [], scrollId = '', modalstate = '') {
      setBeforescrolldata([])
      setAnomalyalertslog(data)
      setModalstate(modalstate)
      //console.log("message:-",data)
      setScrollID(scrollId)
    }
  }));

  const defaultColumns = {
    timestamp: "Time",
    agent: 'Agent Name',
    severity: "Severity",
    signature: "Signature",
    srcip: "Source IP : Port",
    connection: '',
    dstip: "Destination IP : Port",

  };
  // const [loading, setLoading] = useState(true);
  const [itemcount, setItemcount] = useState(0)
  const [defaultRows, setDefaultRows] = useState([]);
  const [scrolldata, setScrollData] = useState([]);
  const [beforescrolldata, setBeforescrolldata] = useState([]);
  // const [columns, setColumns] = useState(defaultColumns);
  const [rows, setRows] = useState([]);
  const [activeTabKey, setActiveTabKey] = React.useState(0);
  const initialExpandedRowNames = [];
  const [areAllExpanded, setAreAllExpanded] = React.useState(false);
  const [collapseAllAriaLabel, setCollapseAllAriaLabel] = React.useState("Expand all");
  const [expandedRowNames, setExpandedRowNames] = React.useState(initialExpandedRowNames);
  const [expandedRowData, setExpandedRowData] = useState({});
  const [selectedRowName, setSelectedRowName] = React.useState('');
  const [searchValue, setSearchValue] = React.useState('')
  const [page, setPage] = React.useState(1);
  const [perPage, setPerPage] = React.useState(10);

  const handleTabClick = (event, tabIndex) => {
    setActiveTabKey(tabIndex);
  };
  //Scroll API call for next data
  const anamolypagination = async (ScrollID) => {
    const data = {
      scroll_Id: ScrollID
    }
    try {
      let Scrolldata = await dbApi.postApi("Opensearch/Latest_scroll", data)
      console.log(Scrolldata)
      const nextdata = Scrolldata.Latest_scroll
      let scrolldata = []
      if (nextdata.length > 0) {
        scrolldata = nextdata
      }
      setScrollData(scrolldata);
    }
    catch (error) {
      console.log(error)
    }
  }

  const resetPagination = () => {
    setPage(page);
    setPerPage(perPage); // Set to your desired default perPage value
  };

  //useEffect for main data 
  useEffect(() => {
    // console.log('useEffect triggered with new data:', anomalyalertslog);
    let afterScrolldata = []

    if (beforescrolldata.length === 0) {
      afterScrolldata = anomalyalertslog
    } else {
      afterScrolldata = [...beforescrolldata, ...scrolldata]
    }

    //  console.log("new data",afterScrolldata)
    setBeforescrolldata(afterScrolldata)
    resetPagination() // Reset pagination when new data is set
    // console.log(beforescrolldata)
    const newdefaultRows = afterScrolldata.map((log) => {
      const { formattedTimeStamp } = formatTableTimestamp(log._source.timestamp);
      return {
        id: log._id,
        timestamp: formattedTimeStamp,
        agentName: log._source?.agent?.name,
        agentId: log._source?.agent?.id,
        severity: log._source?.data?.alert?.severity,
        signature: log._source?.data?.alert?.signature,
        srcip: log._source?.data?.srcip,
        srcport: log._source?.data?.srcport,
        dstip: log._source?.data?.dstip,
        dstport: log._source?.data?.dstport,
        vlan: log._source?.data?.vlan,
        details: log,
      };
    });
    // console.log(newdefaultRows)
    setDefaultRows(newdefaultRows)
    setItemcount(newdefaultRows.length)
    const startIndex = (page - 1) * perPage;
    const endIndex = startIndex + perPage;
    const slicedRows = newdefaultRows.slice(startIndex, endIndex);
    setRows(slicedRows);

    const allExpanded = expandedRowNames.length === rows.length;
    setAreAllExpanded(allExpanded);
    setCollapseAllAriaLabel(allExpanded ? "Collapse all" : "Expand all");

  }, [anomalyalertslog, scrolldata]);

  //Use Effect for pagination 
  useEffect(() => {
    const startIndex = (page - 1) * perPage;
    const endIndex = startIndex + perPage;
    const slicedRows = defaultRows.slice(startIndex, endIndex);
    // console.log("after slicing", "", startIndex, endIndex, slicedRows)
    setRows(slicedRows);
  }, [defaultRows, page, perPage]);

  //Filter function for searching from table
  const onSearchChange = value => {
    setSearchValue(value);
  };
  const onFilter = repo => {
    if (searchValue === '') {
      return true;
    }
    let input;
    try {
      input = new RegExp(searchValue, 'i');
    } catch (err) {
      input = new RegExp(searchValue.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'i');
    }
  
    // Check if the search value matches any of the fields: signature, srcip, or dstip
    return (
      repo.signature.search(input) >= 0 ||
      repo.srcip.search(input) >= 0 ||
      repo.dstip.search(input) >= 0
    );
    // let input;
    // try {
    //   input = new RegExp(searchValue, 'i');
    // } catch (err) {
    //   input = new RegExp(searchValue.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'i');
    // }
    // return repo.signature.search(input) >= 0;
  };

  const filteredRows = rows.filter(onFilter);

  const onSetPage = (_event, newPage) => {
    setPage(newPage);
  };
  const onPerPageSelect = (_event, newPerPage, newPage) => {
    setPerPage(newPerPage);
    setPage(newPage);
  };

  //code for row  expansion 
  const setRowExpanded = (row, isExpanding) => {
    const otherExpandedRowNames = expandedRowNames.filter((r) => r !== row.id);
    setExpandedRowNames(
      isExpanding ? [...otherExpandedRowNames, row.id] : otherExpandedRowNames
    );
    if (!expandedRowData[row.id]) {

      let agentGroupName = typeof row?.details?._source?.agent?.labels?.group != "undefined" ? row?.details?._source?.agent?.labels?.group : "";
      let agentName = typeof row?.details?._source?.agent?.name != "undefined" ? row?.details?._source?.agent?.name : "";
      let agentID = typeof row?.details?._source?.agent?.id != "undefined" ? row?.details?._source?.agent?.id : "";
      const keyifiedData = keyify(row?.details, "", agentGroupName, agentName, agentID);
      setExpandedRowData((prevData) => ({
        ...prevData,
        [row.id]: keyifiedData,
      }));
    }
    // setRowExpanded(row, !isRowExpanded(row));
    const updatedExpandedRowNames = isRowExpanded(row)
      ? expandedRowNames.filter((r) => r !== row.id)
      : [...expandedRowNames, row.id];
    setExpandedRowNames(updatedExpandedRowNames);

  };
  const isRowExpanded = (row) => {
    return expandedRowNames.includes(row.id);
  };
  const handleFilter = (fields, value) => {
    const updatedFields = UpdateFilterData(props.filterformdata, fields, 'is', value);
    dispatch(FilterData(updatedFields));
  }
  // design for Severity data
  const renderSeveritycolumn = (severity) => {
    let badgeStyle = {};
    if (severity === '1') {
      badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#A30000" };
      return (<Badge style={badgeStyle}><a className='pf-v5-u-color-light-100' onClick={() => { handleFilter('data.alert.severity', severity) }}>High</a></Badge>)
    }
    else if (severity === '2') {
      badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#F0AB00 " };
      return (<Badge style={badgeStyle} ><a className='pf-v5-u-color-light-100' onClick={() => { handleFilter('data.alert.severity', severity) }}>Medium</a></Badge>)
    }
    else {
      return ' ';
    }
  };

  const hyperlinkConditions = {
    'agent.id': (value) => value !== '000',
    'agent.name': (value) => value !== '000',
    'data.capture_file': (value) => value !== '000',
  };
  const keyify = (obj, prefix = "", agentGroupName = "", agentName = '', agentID = '') => {
    const skipKeys = ["_score", "_version", "_index", "sort", "@timestamp"];
    const result = [];

    Object.keys(obj).forEach((el) => {
      const fullkey = prefix + el;
      if (skipKeys.includes(el)) {
        return;
      }
      else if (Array.isArray(obj[el])) {
        const value = obj[el].join(", ");
        result.push({ key: fullkey, value });
      } else if (typeof obj[el] === "object" && obj[el] !== null) {
        if (el === "_source") {
          result.push(...keyify(obj[el], prefix, agentGroupName, agentName, agentID));
        }
        else {
          result.push(...keyify(obj[el], fullkey + ".", agentGroupName, agentName, agentID));
        }
      }
      else {
        if (hyperlinkConditions[fullkey] && hyperlinkConditions[fullkey](obj[el])) {
          if (fullkey == 'data.capture_file' && agentGroupName != "") {
            const matchingGroup = props?.allgroups.find(group => group.groupName.toUpperCase() == agentGroupName);
            if (matchingGroup.tap_ip != null) {
              const tipIp = matchingGroup.tap_ip;
              const capturefile = obj[el].split("/")
              result.push({
                key: fullkey,
                value: <a href={`http://${tipIp}:8085/webshark/index.html?file=${capturefile[capturefile.length - 1]}`} target="_blank" rel="noopener noreferrer">{obj[el]}</a>
              });
            }
            else {
              result.push({ key: fullkey, value: obj[el] })
            }
          }
          else if (fullkey === 'agent.id' || fullkey === 'agent.name') {
            result.push({ key: fullkey, value: <Link to={`/individual-agent-dashboard/${agentName}/${agentID}`}>{obj[el]}</Link> });
          }
        }
        else if (typeof obj[el] === 'boolean') {
          result.push({ key: fullkey, value: obj[el] ? 'true' : 'false' });
        }
        else { result.push({ key: fullkey, value: obj[el] }); }
      }
    });
    const sortedResult = result.sort((a, b) => {
      if (a.key < b.key) return -1;
      if (a.key > b.key) return 1;
      return 0
    });

    return sortedResult;
  };

  const preStyle = {
    wordBreak: "break-all",
    whiteSpace: "pre-wrap",
  };

  //pagination code
  const renderPagination = variant => <Pagination
    itemCount={itemcount}
    perPage={perPage}
    page={page}
    onSetPage={onSetPage}
    variant={variant}
    onPerPageSelect={onPerPageSelect}
  />

  const toolbar = <> {Modalstate ?
    (<Toolbar inset={{
      default: 'insetNone',
      md: 'insetSm',
      xl: 'inset2xl',
      '2xl': 'insetLg'
    }} >
      <ToolbarContent>
        <ToolbarItem variant="search-filter" style={{ width: '50%' }}>
          <SearchInput
            placeholder="Search..."
            value={searchValue}
            onChange={(_event, value) => onSearchChange(value)}
            onClear={() => onSearchChange('')} />
        </ToolbarItem>
        <ToolbarGroup align={{ default: 'alignRight' }}>
          <ToolbarItem> <Button onClick={() => anamolypagination(ScrollID)} size="sm" >Next</Button></ToolbarItem>
          <ToolbarItem variant="pagination" >{renderPagination('top', false)}</ToolbarItem>
        </ToolbarGroup>
      </ToolbarContent>
    </Toolbar >)
    :
    " "
  }
  </>

  return (<>
    {rows.length > 0 ? (
      <React.Fragment>
        {toolbar}
        <Table variant="compact"
          aria-label="anamolyalerts"
          id="anamolyalerts-log-table"
          isStickyHeader
          gridBreakPoint="grid-md"
        >
          <Thead key={`th-${rows.id}`}>
            <Tr key={`head-${rows.id}`} >
              <Th key={rows.id} aria-label="expand-event" />
              {Object.keys(defaultColumns).map(key => (
                <Th key={key} aria-label={defaultColumns[key]} >{defaultColumns[key]}</Th>
              ))}
            </Tr>
          </Thead>


          <Tbody key='tbody'>
            {rows.length > 0 ?
              filteredRows.map((row, rowIndex) => (
                <>
                  <Tr
                    key={`row-${rowIndex}`}
                    onRowClick={() => setSelectedRowName(rowIndex)}
                    isSelectable
                    isClickable
                    isRowSelected={selectedRowName === rowIndex}   >
                    <Td
                      expand={
                        row.details
                          ? {
                            rowIndex: rowIndex,
                            isExpanded: isRowExpanded(row),
                            onToggle: () =>
                              setRowExpanded(row, !isRowExpanded(row)),
                          }
                          : undefined
                      }
                      key={"exp-" + rowIndex}
                    >
                      <ExpandableRowContent key={rowIndex}>
                        <pre> {row.details ? (
                          <pre>{JSON.stringify(row.details, null, 2)}</pre>
                        ) : (
                          <p>No details available.</p>
                        )}</pre>
                      </ExpandableRowContent>
                    </Td>
                    <Td width={20} key={"tm-" + rowIndex} dataLabel="Time" >{row?.timestamp} </Td>
                    <Td width={20} key={"agent" + rowIndex} dataLabel="Agent Name" ><Link to={"/individual-agent-dashboard/" + row?.agentName + '/' + row?.agentId}>{row?.agentId}: {row?.agentName}</Link> </Td>
                    <Td width={10} key={"ser-" + rowIndex} dataLabel="Severity">{renderSeveritycolumn(row?.severity)}</Td>
                    <Td width={30} key={"sign-" + rowIndex} modifier="truncate" dataLabel="Signature" ><a className="pf-v5-u-color-100" onClick={() => { handleFilter('data.alert.signature', row?.signature) }}>{(row?.signature)} </a> </Td>
                    <Td width={15} key={"src-" + rowIndex} dataLabel="Source IP :Port"><b><a className="pf-v5-u-color-100" onClick={() => { handleFilter('data.srcip', row?.srcip) }}>{row?.srcip}</a></b> : {row?.srcport}  </Td>
                    <Td width={10} key={"vlan-" + rowIndex}> {row?.vlan ? row?.vlan.map((item, index) => <div><Badge key={`badge-${index}`}><a className='pf-v5-u-color-light-100' onClick={() => { handleFilter('data.vlan', item) }}> <ArrowLeftIcon />  VLAN:{item} <ArrowRightIcon /></a></Badge></div>) : " "}</Td>
                    <Td width={15} key={"dst-" + rowIndex} dataLabel="Destination IP : Port"><b><a className="pf-v5-u-color-100" onClick={() => { handleFilter('data.dstip', row?.dstip) }}>{row?.dstip}</a></b> : {row?.dstport}</Td>
                  </Tr>
                  <Tr isExpanded={isRowExpanded(row)} key={"exp-more-" + rowIndex} >

                    <Td colSpan={11} noPadding key={`td-${rowIndex}`}>
                      <ExpandableRowContent key={rowIndex}>
                        <Tabs
                          activeKey={activeTabKey}
                          onSelect={handleTabClick}
                          style={{ marginTop: "0px" }}
                          aria-label="Tabs in the Security Alerts"
                          role="region"
                        >
                          <Tab
                            eventKey={0}
                            title={<TabTitleText>Table</TabTitleText>}
                            aria-label="Table data"
                          >
                            <TabContentBody hasPadding  >

                              <DescriptionList isHorizontal horizontalTermWidthModifier={{ default: '30ch' }} >
                                {expandedRowData[row.id]?.map((item, index) => (
                                  <React.Fragment key={index}>
                                    <DescriptionListGroup >
                                      <DescriptionListTerm key={"item-" + index} > {item.key}</DescriptionListTerm>
                                      <DescriptionListDescription key={"desc-" + index}  >{item.value}</DescriptionListDescription>
                                    </DescriptionListGroup>
                                  </React.Fragment>
                                ))}
                              </DescriptionList>
                            </TabContentBody>
                          </Tab>
                          <Tab eventKey={1} title={<TabTitleText>JSON</TabTitleText>}>
                            <TabContentBody hasPadding >
                              <pre style={preStyle} >
                                {row.details ? (
                                  <pre>{JSON.stringify(row.details, null, 2)}</pre>
                                ) : (
                                  <p>No details available.</p>
                                )}
                              </pre>
                            </TabContentBody>
                          </Tab>
                        </Tabs>
                      </ExpandableRowContent>
                    </Td>
                  </Tr>
                </>

              ))
              :
              <Emptystatefornodata />
            }
          </Tbody>
        </Table>
        {Modalstate ?
          <Flex justifyContent={{ default: 'justifyContentFlexEnd' }} spaceItems={{ default: 'spaceItemsNone' }}>
            <FlexItem alignSelf={{ default: 'alignSelfCenter' }}>
              <Button onClick={() => anamolypagination(ScrollID)} size="sm">Next</Button>
            </FlexItem>
            <FlexItem>
              {renderPagination('bottom', false)}
            </FlexItem>
          </Flex>
          :
          " "
        }
      </React.Fragment>
    )
      :
      (<Emptystatefornodata />)}

  </>
  );
});

const mapStateToProps = (state) => ({
  dates: { startDate: state.startdate, endDate: state.enddate },
  filterformdata: state.FilterData,
  selectedGroup: state.SelectedGroup,
  allgroups: state.Allgroups,
  // mapStateToProps content remains unchanged if you have it
});

const mapDispatchToProps = (dispatch) => { return {} };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(AlertsAnomalyLog)