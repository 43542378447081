import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Button, Card, CardBody, CardHeader, CardTitle, Icon, PageSection } from '@patternfly/react-core'
import { AngleLeftIcon, SaveIcon } from '@patternfly/react-icons'
import { CodeEditor, Language } from '@patternfly/react-code-editor';
import dbApi from 'src/services/DbApi';
import { alertBoxData } from 'src/Redux/Action';
import { connect, useDispatch } from 'react-redux';
import { Loader } from 'src/util/Loadingstate';

const GroupConfigEditor = (props) => {
    const { groupname } = useParams()
    const { filename } = useParams()
    const { id } = useParams()
    const [code, setCode] = useState('');
    const [isDarkTheme, setIsDarkTheme] = React.useState(false);
    const [isloadsave, setIsloadSave] = useState(false)
    const [isloading, setIsLoading] = useState(false)
    const dispatch = useDispatch();
    // const onEditorDidMount = (editor, monaco) => {
    //     editor.layout();
    //     editor.focus();
    //     monaco.editor.getModels()[0].updateOptions({
    //       tabSize: 5
    //     });
    //   };
    const getCode = async () => {
        let param = {
            name: groupname,
            fileName: filename
        }
        try {
            setIsLoading(true)
            let res = await dbApi.postApi("Opensearch/get_group_files ", param)
            console.log(res)
            setCode(res.data)
            setIsLoading(false)
        } catch (error) {
            console.log(error)
            setIsLoading(false)
        }
    }
    useState(() => {
        getCode()
    }, [])


   
    useEffect(() => {
        if (props.theme == 'dark-theme')
            setIsDarkTheme(true);
    }, [props.theme])

    const onChange = value => {
        setCode(value)
    };

    const editFileConfig = async () => {
        let param = {
            name: groupname,
            body: code
        }
        try {
            setIsloadSave(true)
            let res = await dbApi.postApi("Opensearch/Edit_group_files", param)
            console.log(res)
            if (res.data.error == 0) {
                dispatch(alertBoxData([{ title: res.data.message, variant: "success" }]));
                getCode()
            }
            else {
                dispatch(alertBoxData([{ title: res.data.message, variant: "danger" }]));
            }
            setIsloadSave(false)
        } catch (error) {
            console.log(error)
            setIsloadSave(false)
        }
    }
    const headerActions = <>
        {id == '1' && <Button icon={<SaveIcon />} variant='primary' isLoading={isloadsave} onClick={() => editFileConfig()}>Save</Button>}
    </>
    return (<div>
        <PageSection className="pf-v5-u-p-sm">
            <Card>
                <CardHeader actions={{ actions: (headerActions) }}>
                    <CardTitle component='h1'>
                        <Link to={"/management/groups"} className='pf-v5-u-color-100'>
                            <Icon size='md' isInline><AngleLeftIcon /></Icon>
                            agent.conf of {groupname} group
                        </Link></CardTitle>
                </CardHeader>
                <CardBody>
                    <CodeEditor
                        isLineNumbersVisible
                        isLanguageLabelVisible
                        isDarkTheme={isDarkTheme}
                        // isCopyEnabled
                        // isUploadEnabled
                        // isDownloadEnabled
                        loading={isloading}
                        isReadOnly={id == '2'}
                        code={code}
                        onChange={onChange}
                        language={Language.xml}
                        // onEditorDidMount={onEditorDidMount}
                        height="400px"
                    />
                </CardBody>
            </Card>
        </PageSection>
    </div>
    )
}


const mapStateToProps = (state) => ({
    theme: state.theme
    // mapStateToProps content remains unchanged if you have it
});

export default connect(
    mapStateToProps,
    null,
    null,
)(GroupConfigEditor)