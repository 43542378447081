import {
    Grid, GridItem, Spinner, Title, Button, InputGroup, InputGroupItem, Card, CardBody, ToggleGroup, ToggleGroupItem, TextArea,
    Flex,
    FlexItem,
    Icon,
    Toolbar,
    Tooltip,
    Gallery,
    GalleryItem,
    DescriptionList,
    DescriptionListGroup,
    DescriptionListTerm,
    DescriptionListDescription,
    CardTitle,
    TextContent,
    TextVariants,
    Text
} from '@patternfly/react-core';
import { EditIcon, CheckIcon, TimesIcon, UserCircleIcon, CalendarIcon, TrashIcon, CalendarAltIcon, PlusCircleIcon, ListAltIcon, DownloadIcon, UploadIcon, FileIcon, FolderIcon, FilePdfIcon, FileExcelIcon, FileWordIcon } from '@patternfly/react-icons';
import React, { useState, useEffect, useRef } from 'react';
import dbApi from 'src/services/DbApi';
import { useParams, useLocation } from 'react-router-dom';
import { alertBoxData } from 'src/Redux/Action';
import { useDispatch } from 'react-redux';
import ConfirmBox from 'src/Components/ConfirmBox/ConfirmBox';
import { formatTableTimestamp } from 'src/util/Timeutils';
import ModalCommon from 'src/Components/CommonEventPages/ModalCommon';

const IncidentTaskDetail = () => {
    const dispatch = useDispatch();
    const modalRef = useRef();
    const fileInputRef = useRef(null);
    const { id, taskid } = useParams();
    const location = useLocation();
    const [taskDetail, setTaskDetail] = useState(null); // Combined task detail state
    const [taskLogs, setTaskLogs] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [editingField, setEditingField] = useState(null);
    const [isAddingLog, setIsAddingLog] = useState(false);
    const [taskLog, setTaskLog] = useState('');
    const [editingLogIndex, setEditingLogIndex] = useState(null);
    const [editedLogValue, setEditedLogValue] = useState('');
    const [selectedFile, setSelectedFile] = useState('');
    const [selectedFileName, setSelectedFileName] = useState(null);
    const [imagePreviewUrl, setImagePreviewUrl] = useState('');
    const [modalImage, setModalImage] = useState(null);
    const [confirmModalData, setConfirmBox] = useState({
        show: false, msg: '',
        funCall: () => { }, funParam: {}, title: '',
        position: '',
        iconvariant: '',
        submitbtnvariant: '',
    })

    useEffect(() => {
        getTaskDetail();
        // getTaskLogDetail();
    }, [taskid]);

    const getTaskDetail = async () => {
        try {
            setIsLoading(true);
            const taskRes = await dbApi.postApi("incident_management/get_task", { taskUniqueId: taskid });
            const logRes = await dbApi.postApi("incident_management/get_task_log", { taskUniqueId: taskid });

            if (taskRes.data && taskRes.data.length > 0) {
                const detail = taskRes.data[0];
                setTaskDetail({
                    title: detail.title,
                    description: detail.description,
                    status: detail.status,
                    assigneeName: detail.assignee_name,
                    createDateTime: detail.last_datetime
                });
            }
            if (logRes) {
                setTaskLogs(logRes);
            }
            setIsLoading(false);
        } catch (error) {
            console.error(error);
            setIsLoading(false);
        }
    };

    const handleEditClick = (fieldName) => {
        setEditingField(fieldName);
        if (fieldName === 'title' || fieldName === 'description') {
            setInputValue(taskDetail[fieldName]);
        }
    };
    const handleFileChange = (event, taskid) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);  // Set the selected file in state

            console.log("file")
            console.log(file)
            setSelectedFileName(file.name);  // Optional: Set the file name in state for UI display

            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreviewUrl(prevPreviews => ({
                    ...prevPreviews,
                    [taskid]: reader.result
                }));
            };
            reader.readAsDataURL(file);
            console.log('File selected:', file);
        } else {
            console.log('No file selected or the selected file is invalid.');
        }
    };
    // const staticFileId = 'aff4f3486f9a11ef';
    const downloadFile = async (docUniqueId) => {
        try {
            const response = await dbApi.getApi(`incident_management/download/${docUniqueId}`,true,'blob');
            const contentDisposition = response.headers['content-disposition'];
            let fileName = "";
            if (contentDisposition) {
                const match = contentDisposition.match(/filename="?(.+)"?/);
                if (match && match[1]) {
                    fileName = match[1];
                }
            }
            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error during file download:', error);
        }
    };

    const handleSaveLog = async () => {
        try {
            if (!taskid || !taskLog) {
                console.error('Task ID or task log is missing');
                return;
            }
            const formData = new FormData();
            formData.append("taskUniqueId", taskid);
            formData.append("description", taskLog);
            console.log('taskid', taskid);
            console.log('description', taskLog);

            // Append the file if it's selected
            if (selectedFile) {
                formData.append("file", selectedFile);
                console.log('File being sent:', selectedFile);
            } else {
                console.log('No file selected');
            }

            // Send the FormData to your API
            const res = await dbApi.postFormDataApi("incident_management/add_task_log", formData);

            if (!res.error) {
                dispatch(alertBoxData([{ title: res.message, variant: 'success' }]));

                const fileType = selectedFile ? selectedFile.type : null;
                const imageData = res.image_data || '';  // Default to empty if no image data
                const documentName = res.document || 'No document';  // Fallback document name
                const docUniqueId = res.doc_unique_id || null;  // Handle null doc_unique_id

                // Update the task logs with the new log entry
                const newLog = {
                    description: taskLog,
                    fileType: fileType,  // Store the file type
                    image_data: imageData,  // Set image data
                    document: documentName, // Set document name
                    doc_unique_id: docUniqueId // Set unique doc ID or null
                };
                console.log('newslog', newLog)
                setTaskLogs([newLog, ...taskLogs]);
                setTaskLog('');  // Reset the log input
                setIsAddingLog(false);
                getTaskDetail();

                // Clear file input and reset selected file state
                if (fileInputRef.current) {
                    fileInputRef.current.value = '';
                }
                setSelectedFile(null);
                setSelectedFileName('');

            } else {
                dispatch(alertBoxData([{ title: res.message, variant: 'danger' }]));
            }
        } catch (error) {
            console.error('Failed to add task log:', error);
        }
    };


    const handleStatusChange = async (newStatus) => {
        try {
            const res = await dbApi.putApi("incident_management/update_task", {
                taskUniqueId: taskid,
                status: newStatus
            });

            if (!res.error) {
                setTaskDetail(prev => ({ ...prev, status: newStatus }));
                setEditingField(null);
                dispatch(alertBoxData([{ title: res.message, variant: 'success' }]));
                getTaskDetail();
            }
        } catch (error) {
            console.error('Failed to update status:', error);
            dispatch(alertBoxData([{ title: error.message, variant: 'danger' }]));
        }
    };

    const handleSaveClick = async (fieldName) => {
        try {
            const res = await dbApi.putApi("incident_management/update_task", {
                taskUniqueId: taskid,
                [fieldName]: inputValue
            });

            if (!res.error) {
                setTaskDetail(prev => ({ ...prev, [fieldName]: inputValue }));
                dispatch(alertBoxData([{ title: res.message, variant: 'success' }]));
                getTaskDetail();
                setEditingField(null);

            } else {
                dispatch(alertBoxData([{ title: res.message, variant: 'danger' }]));
            }
        } catch (error) {
            console.error('Failed to update task:', error);
        }
    };

    const handleCancelClick = () => {
        setEditingField(null);
    };

    const handleAddLogClick = () => {
        setIsAddingLog(!isAddingLog);
        setTaskLog('');
        setSelectedFileName('')
    };

    // const handleSaveLog = async () => {
    //     try {
    //         const formData = new FormData();
    //         formData.append("taskUniqueId", taskid);
    //         formData.append("description", taskLog);
    //         formData.append("file", selectedFile);
    //         console.log('taskid', taskid);
    //         console.log('taskLog', taskLog);
    //         console.log(' selected file', selectedFile);
    //         const res = await dbApi.postFormDataApi("incident_management/add_task_log", formData);
    //         console.log('formdata', formData)

    //         if (!res.error) {
    //             dispatch(alertBoxData([{ title: res.message, variant: 'success' }]));
    //             const newLog = { description: taskLog };
    //             setTaskLogs([newLog, ...taskLogs]);
    //             getTaskDetail();
    //             setTaskLog(''); //empty after the save click
    //             setIsAddingLog(false);//input task log field close after the added
    //             fileInputRef.current.value = '';  // Reset the file input
    //             setSelectedFile(null);            // Reset the file object
    //             setSelectedFileName('');

    //         } else {
    //             dispatch(alertBoxData([{ title: res.message, variant: 'danger' }]));
    //         }
    //     } catch (error) {
    //         console.error('Failed to add task log:', error);
    //     }
    // };
    const handleImageClick = (imageData) => {
        setModalImage(imageData); // Set the clicked image
        modalRef.current.setModalBox(true); // Open the modal
    };


    const handleEditLogClick = (index) => {
        setEditingLogIndex(index);
        setEditedLogValue(taskLogs[index].description);
    };

    const handleSaveEditedLog = async () => {
        try {
            const logId = taskLogs[editingLogIndex]?.unique_id;
            console.log('unique_log_id', logId)
            const res = await dbApi.putApi("incident_management/update_task_log", {
                uniqueId: logId,
                description: editedLogValue
            });
            console.log('updated_task_log', res);
            if (!res.error) {
                const updatedLogs = [...taskLogs];
                updatedLogs[editingLogIndex] = { ...updatedLogs[editingLogIndex], description: editedLogValue };
                setTaskLogs(updatedLogs);
                dispatch(alertBoxData([{ title: res.message, variant: 'success' }]));
                setEditingLogIndex(null);
            } else {
                dispatch(alertBoxData([{ title: res.message, variant: 'danger' }]));
            }
        } catch (error) {
            console.error('Failed to update task log:', error);
        }
    };

    const handleCancelLogEdit = () => {
        setEditingLogIndex(null);
    };

    const fields = [
        { label: 'Title', name: 'title', value: taskDetail?.title },
        { label: 'Description', name: 'description', value: taskDetail?.description },
        { label: 'Status', name: 'status', value: taskDetail?.status, options: ['Waiting', 'InProgress', 'Completed'] },
        // { label: 'Assignee', name: 'assignee', value: taskDetail?.assigneeName },
        // { label: 'Created At', name: 'createdAt', value: taskDetail?.createDateTime }
    ];

    const deleteGroup = async (logId) => {
        try {
            setIsLoading(true);
            let res = await dbApi.deleteApi("incident_management/delete_task_log", { uniqueId: logId });
            if (!res.error) {
                dispatch(alertBoxData([{ title: res.message, variant: "success" }]));
                // Remove the deleted log from the taskLogs state
                setTaskLogs(taskLogs.filter(log => log.unique_id !== logId));
            } else {
                dispatch(alertBoxData([{ title: res.message, variant: "danger" }]));
            }
            setIsLoading(false);
        } catch (error) {
            console.error(error.message || error);
            setIsLoading(false);
        }
    };

    const onDeleteGroup = (logId) => {
        setConfirmBox({
            show: true,
            msg: `Permanently delete this log?`,
            funCall: () => deleteGroup(logId),
            funParam: {},
            position: 'default',
            iconvariant: 'warning',
            submitbtnvariant: 'danger',
        });
    };


    return (
        <>
            <Card>
                <CardBody>
                    <>
                        <DescriptionList className='pf-v5-u-mt-md' isHorizontal columnModifier={{ lg: '2Col', md: '2Col', xl: '2Col' }}>
                            {isLoading ? (
                                <Spinner />
                            ) : (
                                fields.map((field, index) => (
                                    <DescriptionListGroup key={index}>
                                        <DescriptionListTerm className="pf-v5-u-font-weight-bold">
                                            {field.label}:
                                        </DescriptionListTerm>
                                        <DescriptionListDescription>
                                            {editingField === field.name ? (
                                                field.name === 'status' ? (
                                                    <ToggleGroup aria-label="Status toggle group">
                                                        {field.options.map((option) => (
                                                            <ToggleGroupItem
                                                                key={option}
                                                                text={option}
                                                                isSelected={taskDetail.status === option}
                                                                onChange={() => handleStatusChange(option)}
                                                            />
                                                        ))}
                                                    </ToggleGroup>
                                                ) : (
                                                    <InputGroup>
                                                        <input
                                                            type="text"
                                                            value={inputValue}
                                                            onChange={(e) => setInputValue(e.target.value)}
                                                        />
                                                        <InputGroupItem>
                                                            <Button variant="plain" onClick={() => handleSaveClick(field.name)}>
                                                                <CheckIcon />
                                                            </Button>
                                                            <Button variant="plain" onClick={handleCancelClick}>
                                                                <TimesIcon />
                                                            </Button>
                                                        </InputGroupItem>
                                                    </InputGroup>
                                                )
                                            ) : (
                                                <div>
                                                    {field.value}
                                                    <Button variant="plain" onClick={() => handleEditClick(field.name)}>
                                                        <EditIcon />
                                                    </Button>
                                                </div>
                                            )}
                                        </DescriptionListDescription>
                                    </DescriptionListGroup>
                                ))
                            )}
                        </DescriptionList>
                    </>

                    {/* Task Logs Section */}
                    <Card isPlain >
                        <CardBody className='custom-card'>
                            <Grid>
                                <GridItem lg={2} className='pf-v5-u-mb-sm pf-v5-u-font-weight-bold' >Task Logs</GridItem>
                                <GridItem lg={9} className='pf-v5-u-mb-sm'>
                                    {isAddingLog ? (
                                        <InputGroup >
                                            <InputGroupItem isFill >
                                                <TextArea
                                                    type="text"
                                                    value={taskLog}
                                                    onChange={(e) => setTaskLog(e.target.value)}
                                                    aria-label="Add log"
                                                    resizeOrientation="horizontal"
                                                    placeholder="Enter your task log here..."
                                                />
                                            </InputGroupItem>
                                            <InputGroupItem>
                                                {/* Hidden file input */}
                                                <input
                                                    type="file"
                                                    onChange={handleFileChange}
                                                    aria-label="Upload file"
                                                    style={{ display: 'none' }}  // Hide the input element
                                                    ref={fileInputRef}  // Use ref to programmatically trigger click
                                                />

                                                {/* Button with upload icon */}
                                                <Tooltip content="Upload File">
                                                    <Button variant="plain" onClick={() => fileInputRef.current.click()}>
                                                        <UploadIcon />
                                                    </Button>
                                                </Tooltip>
                                            </InputGroupItem>
                                            <InputGroupItem>
                                                {selectedFileName &&
                                                    <Card><CardBody>
                                                        <p><strong>Selected file:</strong> {selectedFileName}</p>
                                                    </CardBody></Card>}

                                            </InputGroupItem>
                                            <InputGroupItem>
                                                <Button variant="plain" onClick={handleSaveLog}>
                                                    <CheckIcon />
                                                </Button>
                                            </InputGroupItem>
                                            <InputGroupItem>
                                                <Button variant="plain" onClick={handleAddLogClick}>
                                                    <TimesIcon />
                                                </Button>
                                            </InputGroupItem>
                                        </InputGroup>
                                    ) : (
                                        <Button variant="plain" onClick={handleAddLogClick}>
                                            <Tooltip content="Add task log">
                                                <Icon status="info">
                                                    <PlusCircleIcon />
                                                </Icon>
                                            </Tooltip>

                                        </Button>
                                    )}
                                </GridItem>
                            </Grid>

                            <Grid>
                                <GridItem lg={12}>
                                    {taskLogs.length > 0 && (
                                        <Gallery hasGutter minWidths={{ default: '250px' }}>
                                            {taskLogs.map((log, index) => {
                                                // Format the timestamp
                                                const isImage = log.image_data && log.fileType && log.fileType.startsWith('image');

                                                const { formattedTimeStamp: lastDatetime } = log?.last_datetime
                                                    ? formatTableTimestamp(log.last_datetime)
                                                    : { formattedTimeStamp: ' ' };

                                                return (
                                                    <GalleryItem key={index}>
                                                        <Card isCompact isFullHeight>
                                                            <CardBody>
                                                                {editingLogIndex === index ? (
                                                                    <>
                                                                        <TextArea
                                                                            value={editedLogValue}
                                                                            onChange={(e) => setEditedLogValue(e.target.value)}
                                                                            aria-label="Edit task log"
                                                                        />
                                                                        <div className="pf-v5-u-mt-md">
                                                                            <Button variant="plain" onClick={handleSaveEditedLog}>
                                                                                <CheckIcon />
                                                                            </Button>
                                                                            <Button variant="plain" onClick={handleCancelLogEdit}>
                                                                                <TimesIcon />
                                                                            </Button>
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                    <div>
                                                                        <Flex>
                                                                            <FlexItem>
                                                                                <p>
                                                                                    <strong className='pf-v5-u-mr-md'><UserCircleIcon /></strong>
                                                                                    {taskDetail?.assigneeName}
                                                                                </p>
                                                                            </FlexItem>
                                                                            <FlexItem align={{ default: 'alignRight' }}>
                                                                                <Tooltip content="Delete comment">
                                                                                    <Button
                                                                                        variant="plain"
                                                                                        aria-label="Delete log"
                                                                                        onClick={() => onDeleteGroup(log.unique_id)}
                                                                                        style={{ marginLeft: '5px' }}
                                                                                    >
                                                                                        <Icon status="danger"><TrashIcon /></Icon>
                                                                                    </Button>
                                                                                </Tooltip>
                                                                            </FlexItem>
                                                                        </Flex>
                                                                        <Flex>
                                                                            <FlexItem>
                                                                                <>
                                                                                    <strong className='pf-v5-u-mr-md'><CalendarAltIcon /></strong>
                                                                                    {lastDatetime}
                                                                                </>
                                                                            </FlexItem>
                                                                        </Flex>
                                                                        <Flex>
                                                                            <ListAltIcon />
                                                                            <FlexItem>
                                                                                <div>{log.description}</div>
                                                                            </FlexItem>
                                                                            <FlexItem>
                                                                                <Button variant="plain" onClick={() => handleEditLogClick(index)}>
                                                                                    <EditIcon />
                                                                                </Button>
                                                                            </FlexItem>
                                                                        </Flex>

                                                                        {/* Display Uploaded File */}
                                                                        {
                                                                            log.image_data ? (
                                                                                <Flex>
                                                                                    <FlexItem>
                                                                                        <img
                                                                                            src={log.image_data}
                                                                                            alt="Task Image"
                                                                                            style={{ maxWidth: '50%', maxHeight: '70px', marginTop: '10px', cursor: 'pointer' }}
                                                                                            onClick={() => handleImageClick(log.image_data)}
                                                                                        />
                                                                                    </FlexItem>
                                                                                    <FlexItem>
                                                                                        {log.doc_unique_id && log.document && (
                                                                                            <Button variant="link" isInline onClick={() => downloadFile(log.doc_unique_id)}>
                                                                                                {log.document}
                                                                                            </Button>
                                                                                        )}
                                                                                    </FlexItem>
                                                                                </Flex>
                                                                            ) : (
                                                                                // Check if there's a document, doc_unique_id, and file type, and display an appropriate icon
                                                                                log.doc_unique_id && log.document && (
                                                                                    <Card>
                                                                                        <CardBody>
                                                                                            <Flex direction={{ default: 'column' }}>
                                                                                                <FlexItem>
                                                                                                    <Icon size="lg">
                                                                                                        {/* Show specific icons based on file extension */}
                                                                                                        {log.document.toLowerCase().endsWith('.pdf') && <FilePdfIcon />}
                                                                                                        {log.document.toLowerCase().endsWith('.txt') && <FileIcon />}
                                                                                                        {log.document.toLowerCase().endsWith('.xlsx') && <FileExcelIcon />}
                                                                                                        {log.document.toLowerCase().endsWith('.xls') && <FileExcelIcon />}
                                                                                                        {log.document.toLowerCase().endsWith('.doc') && <FileWordIcon />}
                                                                                                        {log.document.toLowerCase().endsWith('.docx') && <FileWordIcon />}
                                                                                                        {/* Default file icon if no specific match */}
                                                                                                        {!['.pdf', '.txt', '.xlsx', '.xls', '.doc', '.docx'].some(ext => log.document.toLowerCase().endsWith(ext)) && <FileIcon />}
                                                                                                    </Icon>
                                                                                                </FlexItem>
                                                                                                <FlexItem>
                                                                                                    <Tooltip content="Download File" position="bottom">
                                                                                                        <Button variant="link" isInline onClick={() => downloadFile(log.doc_unique_id)}>
                                                                                                            {log.document || 'file name'}
                                                                                                        </Button>
                                                                                                    </Tooltip>
                                                                                                </FlexItem>
                                                                                            </Flex>
                                                                                        </CardBody>
                                                                                    </Card>
                                                                                )
                                                                            )
                                                                        }
                                                                    </div>
                                                                )}

                                                            </CardBody>
                                                        </Card>
                                                    </GalleryItem>
                                                );
                                            })}
                                        </Gallery>
                                    )}
                                </GridItem>

                            </Grid>

                        </CardBody>
                    </Card>

                </CardBody >
            </Card >
            <ConfirmBox confirmModal={confirmModalData} />
            <ModalCommon
                ref={modalRef}
                title="Image Preview"
                onClose={() => setModalImage(null)} // Clear image on close
            >
                {modalImage && (
                    <img
                        src={modalImage}
                        alt="Modal Task Image"
                        style={{ maxWidth: '100%', Height: '300px' }} // Ensure proper scaling inside the modal
                    />
                )}
            </ModalCommon>
        </>
    );
};

export default IncidentTaskDetail;
