import React, { useState, useRef, useEffect } from 'react';
import {
    Button,
    Flex,
    PageSection,
    PageSectionVariants,
    Tab,
    TabContentBody,
    TabTitleText,
    Tabs,
    Text,
    TextVariants,
} from '@patternfly/react-core';
import VulnerabilitiesDashboard from './VulnerabilitiesDashboard';
import EventBar from '../CommonEventPages/EventBar';
import VulnerabilitiesEvents from './VulnerabilitiesEvents';
import AddFilterForm from '../CommonEventPages/AddFilterForm';
import { connect } from 'react-redux';
import LatestVulnerabilitiesCve from './LatestVulnerabilities'
import Vulnerabilitytablepage from './Vulnerabilitytablepage';

const Vulnerabilities = (props) => {
    const [activeTabKey, setActiveTabKey] = useState(0);
    const handleTabClick = (_event, tabIndex) => {
        setActiveTabKey(tabIndex);
    };
    const filterfield = [
        {
            'fields': 'data.vulnerability.status',
            'condition': 'is_one_of',
            'value': ['Active', 'Solved']
        },
        // {
        //     'fields':'rule.groups',
        //     'condition':'is',
        //     'value':'vulnerability_detector'
        //  },
    ]

    return (
        <div>
            <PageSection variant={PageSectionVariants.light} padding={{ default: 'noPadding' }}>

                <Tabs variant='light300' activeKey={activeTabKey} onSelect={handleTabClick} aria-label="Tabs-individual" role="region" isOverflowHorizontal isBox width={40}>
                    <Tab eventKey={0} title={<TabTitleText className='pf-v5-u-font-weight-bold'>Dashboard</TabTitleText>} aria-label="Horizontal overflow content users">
                        {activeTabKey == 0 &&
                            <TabContentBody className='pf-v5-u-p-xs'>
                                <EventBar AllTimeDisplay={true} />
                                <AddFilterForm filter={filterfield}  VulnerabilityDistinct={true}/>
                                <VulnerabilitiesDashboard hideCard={false} />
                            </TabContentBody>}
                    </Tab>
                    {/* <Tab eventKey={1} title={<TabTitleText className='pf-v5-u-font-weight-bold'>Latest Vulnerabilities(CVE)</TabTitleText>} aria-label="Horizontal overflow content users">
                        {activeTabKey == 1 &&
                            <TabContentBody className='pf-v5-u-p-xs'>
                                  <EventBar AllTimeDisplay={true} />
                                  <AddFilterForm filter={filterfield} />
                                <Vulnerabilitytablepage/>
                            </TabContentBody>}
                    </Tab> */}
                    <Tab eventKey={1} title={<TabTitleText className='pf-v5-u-font-weight-bold'>Events</TabTitleText>} aria-label="Horizontal overflow content users">
                        {activeTabKey == 1 &&
                            <TabContentBody className='pf-v5-u-p-xs'>
                                <EventBar AllTimeDisplay={true} />
                                <AddFilterForm filter={filterfield} />
                                <VulnerabilitiesEvents />
                            </TabContentBody>}
                    </Tab>
                   
                </Tabs>
            </PageSection>
        </div>
    );
};

const mapStateToProps = (state) => ({
    dates: { selectedTime: state.Term },
});


export default connect(
    mapStateToProps,
    null,
    null,
    { forwardRef: true }
)(Vulnerabilities)

// export default Vulnerabilities;
