export function emptyCheck(value, message) {
  if (isNaN(value)) {
    value = value.trim()
  }
  if (value != '') {
    return 'success'
  } else {
    return 'error'
  }
}

export function selectmenuoption(value) {
  if (value !== '') {
    return 'success';
  } else {
    return 'danger';
  }
}
export function selectmultipleoption(value) {
  if (value.length > 0) {
    return 'success';
  } else {
    return 'danger';
  }
}

export function emptyFormCheck(value) {
  if (value != null && value != undefined && !isNaN(value)) {
    value = value.toString().trim();
  }
  if (value !== '') {
    return 'success';
  } else {
    return 'error';
  }
}


export function emailCheck(value) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (emailRegex.test(value)) {
    return 'success'
  } else {
    return 'error'
  }
}

export function emptyArrayCheck(value, message) {
  if (value.length > 0) {
    return 'success'
  } else {
    return 'error'
  }
}


export function checkMaxLimit(value, max, message = '') {
  if (value.length <= max) {
    return { status: 'success', message: message }
  } else {
    if (message == '') { message = 'The value should be less than or equal to ' + max }
    return { status: 'error', message: message }
  }
}


export function emptyNumericCheck(value, message) {
  let status = emptyCheck(value, message)
  if (status == 'success') {
    if (!isNaN(value)) {
      return 'success'
    } else {
      return 'error'
    }
  } else {
    return status
  }
}

export function emptyNumericNotZeroCheck(value, message) {
  let status = emptyCheck(value, message)
  if (status == 'success') {
    if (!isNaN(value)) {
      if (value != 0) {
        return 'success'
      } else {
        return 'error'
      }
    } else {
      return 'error'
    }
  } else {
    return status
  }
}


export function phoneCheck(value) {
  const phoneRegex = /^[0-9]{10}$/;
  if (phoneRegex.test(value)) {
    return 'success';
  } else {
    return 'error';
  }
}
export function IpCheck(value) {
  const ipRegex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
  if (ipRegex.test(value)) {
    return 'success';
  } else {
    return 'error';
  }
}