import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react'
import dbApi from '../../services/DbApi'
import { TimeHandler } from '../../util/Timeutils';
import {
    ActionGroup,
    Button,
    Card,
    DatePicker,
    DescriptionList,
    DescriptionListGroup,
    DescriptionListTerm,
    Divider,
    Flex,
    FlexItem,
    Form,
    FormGroup,
    Grid,
    GridItem,
    Icon,
    InputGroup,
    InputGroupItem,
    MenuToggle,
    Modal, ModalVariant,
    Popover,
    SearchInput,
    Select,
    SelectList,
    SelectOption,
    Switch,
    Tab,
    TabContentBody,
    TabTitleText,
    Tabs,
    Text,
    TextArea,
    TextInput,
    TextInputGroup,
    TextInputGroupMain,
    TextInputGroupUtilities,
    TextVariants,
    TimePicker,
    Tooltip,
    Truncate
} from '@patternfly/react-core'
import {
    AngleDownIcon,
    FilterIcon,
    OutlinedCalendarAltIcon,
    PlayIcon,
    RedoIcon,
    SaveIcon,
    TimesIcon
} from '@patternfly/react-icons';
import { FilterData, Filterrulelevel, GloablInterval, StartEndDate } from '../../Redux/Action';
import { connect, useDispatch } from 'react-redux';
import SearchAutocomplete from './SearchInputItem';
import DateTimeRangePicker from './DateTimePicker';
import { useLocation } from 'react-router-dom';
import { UpdateFilterData } from 'src/util/FilterHelper';


const RuleLevelOption = [
    { id: '1', value: '3', children: ' 3' },
    { id: '2', value: '4', children: ' 4' },
    { id: '3', value: '5', children: ' 5' },
    { id: '4', value: '6', children: ' 6' },
    { id: '5', value: '7', children: ' 7' },
    { id: '6', value: '8', children: ' 8' },
    { id: '7', value: '9', children: ' 9' },
    { id: '8', value: '10', children: ' 10' },
    { id: '9', value: '11', children: ' 11' },
    { id: '10', value: '12', children: ' 12' },
    { id: '11', value: '13', children: ' 13' },

]
const EventBar = forwardRef(function EventBar(props, ref) {
    const dispatch = useDispatch();
    const timeInputRef = useRef(null);
    const [fields, setFields] = useState({
        savequery: {
            name: { value: "", error: "", status: "" },
            desc: { value: "", error: "", status: "" },
        },
    });
    const [loading, setLoading] = useState(false)
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const [savequerymodalopen, setSaveQuerymodalopen] = useState(false);
    const [InputValue, setInputValue] = useState("Last");
    const [RefreshTime, setRefreshTime] = useState('')
    // const [timeValue, setTimeValue] = useState("");
    // const [unitValue, setUnitValue] = useState("");
    const [searchValue, setSearchValue] = useState('')
    const [GloablIntervalDuration, setGlobalIntervalduration] = useState('')
    const [selectedTime, setSelectedTime] = useState(24);
    const [selectedUnit, setSelectedUnit] = useState('hours');
    const [selectedunitopen, setslectedunitopen] = useState(false);
    const [refreshunit, setRefreshUnit] = useState('select');
    const [refreshunitopen, setRefreshunitopen] = useState(false);
    const [selectedcommontime, setSelectedCommonTime] = useState('Last 24 hours')
    // const [currenttime, setCurrentTime] = useState('')
    // const [entireStartDate, setEntireStartDate] = useState('')
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    //rule dropbox
    const [rulelevelopen, setRulelevelOpen] = useState(false)
    const [selectedrule, setSelectedRule] = useState('select')
    const [activeTabKey, setActiveTabKey] = useState(0);
    const handleTabClick = (_event, tabIndex) => {
        setActiveTabKey(tabIndex);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFields((prevFields) => ({
            ...prevFields,
            savequery: {
                ...prevFields.savequery,
                [name]: {
                    ...prevFields.savequery[name],
                    value: value,
                },
            },
        }));
    };
    const commontime = [
        { term: "All Time" },
        { term: "Today" },
        { term: "This week" },
        { term: "This month" },
        { term: "Last 15 minutes" },
        { term: "Last 30 minutes" },
        { term: "Last 1 hour" },
        { term: "Last 24 hours" },
        { term: "Last 7 days" },
        { term: "Last 30 days" },
        { term: "Last 90 days" },
        { term: "Last 1 year" },
    ];

    const FilteredCommonTime = props.AllTimeDisplay ? commontime : commontime.slice(1)


    const SaveQueryModalToggle = _event => {
        setSaveQuerymodalopen(!savequerymodalopen);
    };
    const onunitToggleclick = () => {
        setslectedunitopen(!selectedunitopen);
    };
    const onUnitSelect = (_event, value) => {
        setSelectedUnit(value);
        setslectedunitopen(false);
    };
    const onRefreshClick = () => {
        setRefreshunitopen(!refreshunitopen);
    };
    const onRefreshUnitSelect = (_event, value) => {
        setRefreshUnit(value);
        setRefreshunitopen(false);
    };
    const RefreshStartButton = (value) => {
        setGlobalIntervalduration(value)
        dispatch(GloablInterval(25000))
    }

    const TimeApi = async () => {
        try {
            let currentDateTime = await dbApi.getApi("elasticsearch/getCurrentUtcDateTime");
            const CurrentTime = new Date(currentDateTime);
            return CurrentTime;
        }
        catch (error) {
            console.log(error);
        }
    }
    // elasticsearch/Entire_log
    const entireLog = async () => {
        try {
            // let entirelog = await dbApi.getApi("elasticsearch/Entire_log");//from elastic api
            let entirelog = await dbApi.getApi("opensearch/get_vulnerability_datetime");
            // opensearch/get_vulnerability_datetime
            console.log("entirelog", entirelog)
            // const timestamp = entirelog.Entire_log.body.hits.hits[0]._source["timestamp"];
            const timestamp = entirelog.data.startDate
            const entireStartDate = new Date(timestamp);
            // setEntireStartDate(entireStartDate)
            return entireStartDate;
        }
        catch (error) {
            console.log(error);
        }
    }
    //when manual time entered and click on apply
    const handleApplyClick = async (event) => {
        // alert(event)
        const timeValue = timeInputRef.current.value;
        const unitValue = selectedUnit; // Fetch unit value from state
        const inputValue = InputValue; // Fetch input value from state

        const endDate = await TimeApi();
        let startDate = new Date(endDate);
        switch (unitValue) {
            case "seconds":
                startDate.setSeconds(startDate.getSeconds() - timeValue);
                startDate = startDate;

                // interval='1s'
                break;
            case "minutes":
                startDate.setMinutes(startDate.getMinutes() - timeValue);
                startDate = startDate;
                // interval="1m"
                break;
            case "hours":
                startDate.setHours(startDate.getHours() - timeValue);
                startDate = startDate;
                console.log(startDate, endDate)
                // interval="5m"
                break;
            case "days":
                startDate.setDate(startDate.getDate() - timeValue);
                startDate = startDate;
                // interval="1h"
                break;

            case "months":
                startDate.setMonth(startDate.getMonth() - timeValue);
                startDate = startDate;
                // interval="1d"
                break;
            case "years":
                startDate.setFullYear(startDate.getFullYear() - timeValue);
                startDate = startDate;
                // interval="1M"
                break;
            default:
                break;
        }

        // setTimeValue(timeValue);
        // setUnitValue(unitValue);
        setInputValue(inputValue);
        setIsPopoverOpen(false);
        setStartDate(startDate);
        setEndDate(endDate);
        setSelectedCommonTime(`${inputValue} ${timeValue} ${unitValue}`);
        dispatch(StartEndDate(startDate, endDate, `${inputValue} ${timeValue} ${unitValue}`));
    };

    //Fixed or common use time 
    const handleItemClick = async (term) => {
        const {
            startDate,
            endDate,
            selectedTime,
            selectedUnit
        } = await TimeHandler(term, TimeApi, entireLog);
        setSelectedCommonTime(term);
        setIsPopoverOpen(false);
        // setLoading(true);
        setStartDate(startDate);
        setEndDate(endDate);
        setSelectedTime(selectedTime);
        setSelectedUnit(selectedUnit);
        // console.log("sd,",startDate,'ed,',endDate)
        dispatch(StartEndDate(startDate, endDate, term));
    }

    const fetchData = async () => {
        try {
            if (props?.dates.startDate == "") {
                handleItemClick('Last 24 hours');
            }
            else if (props?.dates.selectedcommontime === 'All Time' && !props.AllTimeDisplay) {
                handleItemClick('Last 24 hours');
            }
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        fetchData();
    }, [props?.dates.startDate, props?.dates.endDate,]);

    const renderRuleLevelBox = useMemo(() => {
        const onToggleClick = () => {
            setRulelevelOpen(!rulelevelopen);
        };
        // console.log("asdfg",props?.FilterRuleLevel)
        const onSelect = (_event, value) => {
            // console.log(value)
            // setSelectedRule(value);
            dispatch(FilterData(UpdateFilterData(props.filterformdata,'rule.level', 'Search_Greater_than', value )))
            dispatch(Filterrulelevel(value));
            setRulelevelOpen(false);
        };

        // console.log("props.filterformdata",props.filterformdata)
        const updatedFilters = props.filterformdata.filter(filter => filter.fields !== 'rule.level');
        // console.log("updatedFilters",updatedFilters)
        const toggle = toggleRef => <MenuToggle ref={toggleRef} onClick={onToggleClick} isExpanded={rulelevelopen} style={{ width: props?.FilterRuleLevel == 'select' ? '70px' : '110px' }}>

            {props?.FilterRuleLevel == 'select' ?
                <FilterIcon />
                :
                <TextInputGroup isPlain style={{ marginTop: '-10px', marginBottom: '-10px' }} >
                    <TextInputGroupMain value={props?.FilterRuleLevel} onClick={onToggleClick}
                        id="create-typeahead-select-input" autoComplete="off"
                        placeholder="select" role="combobox" isExpanded={rulelevelopen} aria-controls="select-create-typeahead-listbox" />

                    {props?.FilterRuleLevel !== 'select' && <TextInputGroupUtilities>
                        {!!props?.FilterRuleLevel && <Button variant="plain" style={{ margin: '-20px' }} onClick={() => {
                            // setSelectedRule('select');
                            dispatch(Filterrulelevel('select'));
                            dispatch(FilterData(updatedFilters))
                            
                        }} aria-label="Clear input value">
                            <TimesIcon aria-hidden />
                        </Button>}
                    </TextInputGroupUtilities>}
                </TextInputGroup>

            }
        </MenuToggle>;
        return (<React.Fragment>
            <Select id="single-select" isOpen={rulelevelopen}
                selected={props?.FilterRuleLevel} onSelect={onSelect} onOpenChange={isOpen => setRulelevelOpen(isOpen)}
                toggle={toggle} shouldFocusToggleOnSelect >
                <SelectList>
                    {RuleLevelOption.map((item, index) => <SelectOption key={index} value={item.value} onClick={() => dispatch(Filterrulelevel(item.children))}>{item.children}</SelectOption>)}
                </SelectList>
            </Select>
        </React.Fragment>);
    }, [rulelevelopen, setRulelevelOpen, selectedrule, setSelectedRule,props.filterformdata,props?.FilterRuleLevel])

    return (<>
        <Grid hasGutter>
            <GridItem sm={6} md={7} lg={8}>
                <InputGroup >
                    <Tooltip content={'Level >='} position='bottom'>
                        <InputGroupItem>
                            <Card isCompact> {renderRuleLevelBox}</Card>
                        </InputGroupItem>
                    </Tooltip>
                    <InputGroupItem isFill>
                        <SearchAutocomplete />
                    </InputGroupItem>
                </InputGroup>
            </GridItem>
            <GridItem sm={6} md={3} lg={3}>
                <InputGroup >
                    <InputGroupItem>
                        <Popover
                            aria-label="Basic popover"
                            showClose={false}
                            hasAutoWidth
                            bodyContent={(hide) => (
                                <Tabs activeKey={activeTabKey} onSelect={handleTabClick} aria-label="Tabs-individual" role="region" >
                                    <Tab eventKey={0} title={<TabTitleText className="pf-v5-u-font-weight-bold">Quick select</TabTitleText>} aria-label="Agentlists">
                                        <TabContentBody className='pf-v5-u-mt-sm'>
                                            <Form>
                                                <Flex spaceItems={{ default: "spaceItemsXs" }}>
                                                    <FlexItem>
                                                        <FormGroup>
                                                            <TextInput type="text" id="simple-text" value={InputValue} style={{ width: "100px" }} />
                                                        </FormGroup>
                                                    </FlexItem>
                                                    <FlexItem>
                                                        <FormGroup>
                                                            <TextInput
                                                                type="number"
                                                                id="simple-form-text"
                                                                ref={timeInputRef}
                                                                style={{ width: "100px" }}
                                                                value={selectedTime}
                                                                onChange={(_event, value) => setSelectedTime(value)}
                                                            />
                                                        </FormGroup>
                                                    </FlexItem>
                                                    <FlexItem>
                                                        <FormGroup>
                                                            <Select id="unit-select" isOpen={selectedunitopen} selected={selectedUnit} onSelect={onUnitSelect}
                                                                onOpenChange={isOpen => setslectedunitopen(isOpen)}
                                                                toggle={(toggleRef) =>
                                                                    <MenuToggle ref={toggleRef}
                                                                        onClick={onunitToggleclick}
                                                                        isExpanded={selectedunitopen}>
                                                                        {selectedUnit}
                                                                    </MenuToggle>}
                                                                shouldFocusToggleOnSelect>
                                                                <SelectList>
                                                                    <SelectOption value="seconds">seconds</SelectOption>
                                                                    <SelectOption value="minutes">minutes</SelectOption>
                                                                    <SelectOption value="hours">hours</SelectOption>
                                                                    <SelectOption value="days" >days</SelectOption>
                                                                    <SelectOption value="months" >months</SelectOption>
                                                                    <SelectOption value="years" >years</SelectOption>
                                                                </SelectList>
                                                            </Select>
                                                        </FormGroup>
                                                    </FlexItem>
                                                    <FlexItem>
                                                        <FormGroup>
                                                            <Button variant="secondary" onClick={() => { handleApplyClick(); hide(); }}> Apply </Button>
                                                        </FormGroup>
                                                    </FlexItem>
                                                </Flex>
                                            </Form>
                                            <div className="pf-v5-u-mt-sm">
                                                <Text component={TextVariants.h5}  >Commonly Used </Text>
                                                <DescriptionList columnModifier={{ default: "3Col" }} isCompact>
                                                    {FilteredCommonTime.map((item, index) => (
                                                        <DescriptionListGroup key={index}>
                                                            <DescriptionListTerm onClick={() => handleItemClick(item.term)} >
                                                                <Button variant="link" isInline onClick={() => { hide() }}> {item.term} </Button>
                                                            </DescriptionListTerm>
                                                        </DescriptionListGroup>
                                                    ))}
                                                </DescriptionList>
                                                <Divider />
                                            </div>
                                        </TabContentBody>
                                    </Tab>
                                    <Tab eventKey={1} title={<TabTitleText className="pf-v5-u-font-weight-bold">Custom dates</TabTitleText>} aria-label="Agentlists-agentlesslists">
                                        <TabContentBody className='pf-v5-u-mt-sm'>
                                            <DateTimeRangePicker onClose={() => hide()}
                                                initialStartDate={startDate}
                                                initialEndDate={endDate}
                                                onDatesChange={(newStartDate, newEndDate) => {
                                                    setStartDate(newStartDate);
                                                    setEndDate(newEndDate);
                                                }} />
                                        </TabContentBody>
                                    </Tab>
                                </Tabs>
                            )}
                            footerContent={
                                <div>
                                    <Text component={TextVariants.h6}>Refresh every</Text>
                                    <Form>
                                        <Flex spaceItems={{ default: "spaceItemsMd" }}>
                                            <FlexItem>
                                                <TextInput type="number" id="simple-form-number"
                                                    style={{ width: "150px" }}
                                                    value={RefreshTime}
                                                    onChange={(_event, value) => setRefreshTime(value)}
                                                />
                                            </FlexItem>
                                            <FlexItem>
                                                <Select id="unit-select" isOpen={refreshunitopen} selected={refreshunit} onSelect={onRefreshUnitSelect}
                                                    onOpenChange={isOpen => setRefreshunitopen(isOpen)}
                                                    toggle={(toggleRef) =>
                                                        <MenuToggle ref={toggleRef}
                                                            onClick={onRefreshClick}
                                                            isExpanded={refreshunitopen}>
                                                            {refreshunit}
                                                        </MenuToggle>}
                                                    shouldFocusToggleOnSelect>
                                                    <SelectList>
                                                        <SelectOption value="seconds">seconds</SelectOption>
                                                        <SelectOption value="minutes">minutes</SelectOption>
                                                        <SelectOption value="hours">hours</SelectOption>
                                                        <SelectOption value="days" >days</SelectOption>
                                                        <SelectOption value="months" >months</SelectOption>
                                                        <SelectOption value="years" >years</SelectOption>
                                                    </SelectList>
                                                </Select>
                                            </FlexItem>
                                            <FlexItem>
                                                <Button isDisabled={refreshunit !== 'select' && RefreshTime ? false : true}
                                                    icon={<PlayIcon />}
                                                    onClick={() => RefreshStartButton(RefreshTime * 1000)}
                                                >Start</Button>
                                                {/* icon={refreshunit !== 'select' && RefreshTime ?<PauseIcon/> :<PlayIcon />} */}
                                                {/* {refreshunit !== 'select' && RefreshTime ? 'Pause' : 'Start'} */}
                                            </FlexItem>
                                        </Flex>
                                    </Form>
                                </div>
                            }
                        >

                            <Button variant="control" aria-label="popover-calender-button">
                                <OutlinedCalendarAltIcon /> <AngleDownIcon />
                            </Button>
                        </Popover>
                    </InputGroupItem>
                    <InputGroupItem isFill>
                        <TextInput
                            name="textInput-with-popover-1"
                            id="textInput-with-popover-1"
                            type="text"
                            isStartTruncated
                            aria-label="first input example with popover"
                            value={props?.dates.selectedcommontime}
                            onChange={(e) => setSelectedCommonTime(e.target.value)}
                        />
                    </InputGroupItem>
                </InputGroup>
            </GridItem>
            <GridItem sm={6} md={2} lg={1}>
                <Button icon={<RedoIcon />} onClick={() => handleItemClick(props?.dates.selectedcommontime)} style={{ width: '100%', maxWidth: '100%' }} />
            </GridItem>
        </Grid>
    </>
    )
})

const mapStateToProps = (state) => ({
    dates: { startDate: state.startdate, endDate: state.enddate, selectedcommontime: state.Term },
    filterformdata: state.FilterData,
    FilterRuleLevel:state.filterrulelevel
});

const mapDispatchToProps = (dispatch) => {
    return {
        SetDates: (startDate, endDate) => { (StartEndDate(startDate, endDate)); },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(EventBar)


{/* <Popover
                            aria-label='popover-query'
                            showClose={false}
                            headerContent={<div>Saved Queries</div>}

                            bodyContent={<>
                                <Divider /><br />
                                <div>There are no saved queries. Save query text and filters that you want to use again.</div>
                            </>}
                            footerContent={hide =>
                                <div>
                                    <Button onClick={() => { SaveQueryModalToggle() }} style={{ marginTop: "20px" }} size="sm">Save current query</Button>


                                    <Modal
                                        variant={ModalVariant.small}
                                        title="Save query"
                                        isOpen={savequerymodalopen}
                                        onClose={() => SaveQueryModalToggle()}
                                        actions={[
                                            <Button key="create" variant="primary" form="modal-with-form-form" onClick={() => SaveQueryModalToggle()}> Save</Button>,
                                            <Button key="cancel" variant="link" onClick={() => SaveQueryModalToggle()} >Cancel</Button>,
                                        ]}
                                    >
                                        <small> Save query text and filters that you want to use again </small>
                                        <div className="pf-v5-u-mt-md">
                                            <Form>
                                                <FormGroup label="Name" isRequired fieldId="name">
                                                    <TextInput isRequired type="text" id="name" name="name" value={fields.savequery.name.value}
                                                        onChange={handleInputChange} />
                                                </FormGroup>
                                                <FormGroup label="Description" isRequired fieldId="Description">
                                                    <TextArea isRequired type="text" id="Description" name="desc" value={fields.savequery.desc.value}
                                                        onChange={handleInputChange} />
                                                </FormGroup>
                                            </Form>
                                        </div>
                                    </Modal>
                                </div>
                            }
                        >
                            <Button variant="control" aria-label="popover-button-search"> <SaveIcon /> <AngleDownIcon /> </Button>
                        </Popover> */}