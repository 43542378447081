import * as echarts from 'echarts';
import React, { useEffect } from 'react';
import { generateUniqueID } from '../../util/UniqueIdGenerator';
import { connect } from 'react-redux';
const AgentStatus = ({ StatusData, theme,height,sidebarState }) => {
  const id = generateUniqueID()
  useEffect(() => {
    const chartDom = document.getElementById(id + 'asc');
    const myChart = echarts.init(chartDom);
    const textcolor = theme == 'dark-theme' ? '#fff' : '#000'
     const bordercolor = theme == 'dark-theme' ? '#262525' : '#fff'
    let StatusOption = {
      active: StatusData.active,
      disconnected: StatusData.disconnected,
      pending: StatusData.pending,
      neverConnected: StatusData.neverConnected
    };
    const colors = {
      disconnected: '#C9190B',
      active: '#3E8635',
      pending: '#F4C145',
      neverConnected: '#8A8D90'
      // Define colors for other categories if needed
    };
    const seriesData = Object.keys(StatusOption).map(key => ({
      name: key,
      value: StatusOption[key],
    }));
    const option = {
      tooltip: {
        trigger: 'item',
        textStyle: {
          color: "#000"
        }
      },

      legend: {
        orient: 'vertical',
        top: 10,
        right: 'right',
        itemWidth: 9.5,
        itemHeight: 10,
        itemGap: 6.5,
        icon: "rect",
        textStyle:{
          width:85,
          fontSize: 12,
          color: textcolor,
          overflow: 'truncate',
        }
      },
      // color: ['#3E8635', '#C9190B','#8A8D90','#F4C145'],
      color: seriesData.map(item => colors[item.name]),
      series: [
        {
          type: 'pie',
          top:40,
          radius: ['70%', '50%'],
          center: ['33%', '50%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: 'center'
          },
          itemStyle: {
            borderRadius: 0,
            borderColor:bordercolor,
            borderWidth: 2,
            // color: colors[seriesData.map((item=>item.name))] 
          },
          emphasis: {
            label: {
              show: false,
            }
          },
          labelLine: {
            show: false
          },
          data: seriesData
        }
      ]
    };

    option && myChart.setOption(option);
    const resizeChart = () => {
      if (myChart) { // Check if chart exists before resizing
        myChart.resize();
      }
    };

  window.addEventListener('resize', resizeChart);
  return () => {
      myChart.dispose();
      window.removeEventListener('resize', resizeChart);
  };

  }, [StatusData, theme,height,sidebarState])

  return <div className="container">
    <div id={id + 'asc'} style={{ width: '100%', height: height?height:'230px' }} ></div>
  </div>;
}

const mapStateToProps = (state) => ({
  dates: { startDate: state.startdate, endDate: state.enddate },
  filterformdata: state.FilterData,
  theme: state.theme,
  sidebarState:state.sidebarstate
  // mapStateToProps content remains unchanged if you have it
});

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(AgentStatus)