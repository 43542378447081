import React, { forwardRef, useEffect, useMemo, useState } from 'react'
import {
    ActionGroup,
    Button,
    Checkbox,
    Chip,
    ChipGroup,
    Flex,
    FlexItem,
    Form,
    FormGroup,
    Grid,
    GridItem,
    Popover,
    Text,
    TextInput,
    TextVariants,
    Truncate
} from '@patternfly/react-core'
import CommonSelectBox from './CommonSelectBox';
import { PlusIcon, } from '@patternfly/react-icons';
import CommonMultiSelectBox from './CommonMultiSelectBox';
import { connect, useDispatch } from 'react-redux';
import dbApi from '../../services/DbApi'
import { FilterData, IncludeNetworkeve } from '../../Redux/Action';
import { useLocation } from 'react-router-dom';
import { UpdateFilterData } from '../../util/FilterHelper';

const AddFilterForm = forwardRef(function AddFilterForm(props, ref) {
    const dispatch = useDispatch()
    const [isIncludeNw, setIsIncludeNw] = useState(false);
    const [isFilterPopoverOpen, setIsFilterPopoverOpen] = useState(false);
    const [multiselectedOptions, setmultiSelectedOptions] = useState([]);
    const [isloading, setIsloading] = useState(true)
    const [selectedField, setSelectedField] = useState(null);
    const [selectedoperator, setSelectedOperator] = useState(null);
    const [selectedvalue, setSelectedValue] = useState([]);
    const [addedFields, setAddedFields] = useState([]);
    const [operatorFilter, setOperatorFilter] = useState([]);
    const [isSaveButtonEnabled, setisSaveButtonEnabled] = useState(false)
    // const [validated, setValidated] = React.useState('error');
    const [isDisabledselectValue, setIsDisabledselectValue] = useState(true);
    const [isDisabledselectOperator, setIsDisabledselectOperator] = useState(true);
    const [fieldvalues, setFieldValues] = useState([])
    const [startRange, setStartRange] = useState('')
    const [endRange, setEndRange] = useState('')
    // const [rulelevelopen, setRulelevelOpen] = useState(false)
    // const [selectedrule, setSelectedRule] = useState('select')

    const operatoroptions = [
        { value: "is", children: "is", type: ['boolean', 'date', 'integer', 'geo_point', 'ip', 'long', 'double', 'text', 'keyword'] },
        { value: "is_not", children: "is not", type: ['boolean', 'date', 'integer', 'geo_point', 'ip', 'long', 'double', 'text', 'keyword'] },
        { value: "is_one_of", children: "is one of", type: ['integer', 'geo_point', 'ip', 'long', 'double', 'text', 'keyword'] },
        { value: "is_not_one_of", children: "is not one of", type: ['integer', 'geo_point', 'ip', 'long', 'double', 'text', 'keyword'] },
        { value: "exists", children: "exists", type: ['boolean', 'date', 'integer', 'geo_point', 'ip', 'long', 'double', 'text', 'keyword'] },
        { value: "does_not_exist", children: "does not exist", type: ['boolean', 'date', 'integer', 'geo_point', 'ip', 'long', 'double', 'text', 'keyword'] },
        { value: "is_between", children: "is between", type: ['date', 'integer', 'geo_point', 'ip', 'long', 'double'] },
        { value: "is_not_between", children: "is not between", type: ['date', 'integer', 'geo_point', 'ip', 'long', 'double'] },
        { value: "is_Greater_than", children: "is Greater than", type: ['integer', 'geo_point', 'ip', 'long', 'double', 'text', 'keyword'] },
        { value: "is_less_than", children: "is less than", type: ['integer', 'geo_point', 'ip', 'long', 'double', 'text', 'keyword'] },
    ]

    const handleNetworkEvents = (_event, checked) => {
        setIsIncludeNw(checked);
        dispatch(IncludeNetworkeve(checked))
    };

    const handleMultiSelectChange = (selected) => {
        setmultiSelectedOptions(selected)
    };
    const handleCancelFilter = () => {
        setIsFilterPopoverOpen(false)
    }

    const updateFieldSelectBox = (value, type = 'fieldselect', index = 'field') => {
        setSelectedField(value)
        if (value === '') {
            setIsDisabledselectValue(true)
            setIsDisabledselectOperator(true)
        }
        setIsDisabledselectOperator(false)
        setIsDisabledselectValue(true)

    }

    const distinctValue = async (Field) => {
        if (Field) {
            try {
                const updatedFilter = props.filter;
                const mergedFilter = [...updatedFilter, ...props.filterformdata];
                const data = {
                    startDate: props.dates.startDate,
                    endDate: props.dates.endDate,
                    field: Field,
                    filter: JSON.stringify(mergedFilter),
                }
                setIsloading(true)
                const field_values = await dbApi.postApi('elasticsearch/distinctvalue', data);
                console.log("distinct", field_values);
                if (Object.keys(field_values.distinct).length > 0) {
                    let buckets = field_values.distinct.body.aggregations.distinct_value
                    let Fieldvalues = [];
                    if (buckets.buckets.length > 0) {
                        Fieldvalues = buckets.buckets.map((item, index) => ({
                            value: item.key,
                            children: item.key,
                        }))
                    }
                    setFieldValues(Fieldvalues)
                }
                else {
                    setFieldValues([])
                }

                setIsloading(false)
            } catch (error) {
                console.log(error);
            }
        }
    }
    // VulnerabilityDistinct
    const vuldistinctValue = async (Field) => {
        // opensearch/get_vulnerability_distinct_value
        if (Field) {
            try {
                const updatedFilter = props.filter;
                const mergedFilter = [...updatedFilter, ...props.filterformdata];
                const data = {
                    startDate: props.dates.startDate,
                    endDate: props.dates.endDate,
                    fieldName: Field,
                    filter: JSON.stringify(mergedFilter),
                }
                setIsloading(true)
                const field_values = await dbApi.postApi('opensearch/get_vulnerability_distinct_value', data);
                console.log("vul distinct", field_values);
                let buckets = field_values.data
                let Fieldvalues = [];
                if (buckets.length > 0) {
                    Fieldvalues = buckets.map((item, index) => ({
                        value: item.key,
                        children: item.key,
                    }))
                    setFieldValues(Fieldvalues)
                }
                else {
                    setFieldValues([])
                }

                setIsloading(false)
            } catch (error) {
                console.log(error);
            }
        }
    }
    useEffect(() => {
        if (selectedField != "" && !props.VulnerabilityDistinct) {
            distinctValue(selectedField);
        }
        else if (props.VulnerabilityDistinct) {
            vuldistinctValue(selectedField)
        }
    }, [selectedField]);

    const updateOperatorSelectBox = (value, type = 'operatorselect', index = 'operator') => {
        setSelectedOperator(value)
        setIsDisabledselectValue(false)

    }
    const updateValueselectBox = (value, type = 'valueselect', index = 'Value') => {
        setSelectedValue(value)
    }


    const SaveButtonEnabled = () => {
        const isExistOperator = selectedoperator === 'exists' || selectedoperator === 'does_not_exist';
        const condition1 = selectedField != '' && selectedoperator != '' && (selectedvalue != '' || (startRange != '' && endRange != ''))
        const condition2 = selectedField != '' && isExistOperator
        const condition3 = !isDisabledselectOperator && !isDisabledselectValue
        setisSaveButtonEnabled((condition1 || condition2) && condition3 ? true : false)
    };
    useEffect(() => {
        SaveButtonEnabled()
    }, [selectedoperator, selectedField, selectedvalue, startRange, endRange])

    const handleSaveFilter = (event) => {
        let updatedValues;
        if (startRange !== null && startRange !== "" && endRange !== null && endRange !== "") {
            updatedValues = [startRange, endRange];
        } else {
            // Use the existing values
            updatedValues = selectedvalue
        }
        const updatedFields = UpdateFilterData(props.filterformdata, selectedField, selectedoperator, updatedValues)//[startRange, endRange]
        setAddedFields(updatedFields)
        dispatch(FilterData(updatedFields));
        setOperatorFilter([...operatorFilter]);
        setSelectedField('');
        setSelectedOperator('');
        setSelectedValue([]);
        setIsDisabledselectValue(true);
        setIsDisabledselectOperator(true);
    };
    const OnAddfilterFormclick = () => {
        setIsFilterPopoverOpen(true)
        // setFieldValues([])
        setSelectedField("")
        setSelectedOperator("")
        setSelectedValue([])
        setIsDisabledselectValue(true);
        setIsDisabledselectOperator(true);
    }

    const getChipText = (filter) => {
        switch (filter.condition) {
            case 'is_not':
                return `NOT ${filter.fields} : ${filter.value}`;
            case 'is_one_of':
                return `${filter.fields} : is one of ${filter.value.slice(0, 5)}....`;
            case 'is_not_one_of':
                return `NOT ${filter.fields} : is one of ${filter.value.slice(0, 7)}`;
            case 'exists':
                return `${filter.fields} : ${filter.condition}`;
            case 'is_Greater_than':
                return `${filter.fields} : > ${filter.value}`;
            case 'is_less_than':
                return `${filter.fields} : < ${filter.value}`;
            case 'does_not_exist':
                return `NOT ${filter.fields} : exists`;
            case 'is_between':
                return `${filter.fields} : ${startRange} to ${endRange}`;
            case 'is_not_between':
                return `Not ${filter.fields} : ${startRange} to ${endRange}`;
            default:
                return `${filter.fields} : ${filter.value}`;
                break;
        }
    };

    const handleDeleteChip = (operatorFilterToDelete) => {
        setOperatorFilter(prevOperatorFilter => prevOperatorFilter.filter(value => value !== operatorFilterToDelete));

        const updatedFormData = props.filterformdata.filter((_, index) => index !== operatorFilterToDelete);
        setAddedFields(updatedFormData);
        dispatch(FilterData(updatedFormData));
    };


    const updateSelectOption = (option) => {
        setFieldValues(option)
    }

    const selectedFieldType = props?.fieldData.find(field => field.fields === selectedField)?.type

    const filteredOperatorOptions = operatoroptions.filter(option => {
        if (!option.type) {
            return true
        } else {
            return option.type.includes(selectedFieldType); // Only show options with matching selectedFieldType
        }
    });

    const rangeInputBox = useMemo(() => {
        return (
            <Flex direction={{ default: 'row' }}>
                <FlexItem>
                    <FormGroup label='Start Range' id='startrange'>
                        <TextInput
                            type={selectedFieldType == 'date' ? 'text' : 'number'}
                            id="simple-form-text-satrtrange"
                            value={startRange}
                            onChange={(_event, value) => { setStartRange(value) }}
                        />
                    </FormGroup>
                </FlexItem>
                <FlexItem>to</FlexItem>
                <FlexItem>
                    <FormGroup label='End Range' id='endrange'>
                        <TextInput
                            type={selectedFieldType == 'date' ? 'text' : 'number'}
                            id="simple-form-text-endrange"
                            value={endRange}
                            onChange={(_event, value) => { setEndRange(value) }}
                        />
                    </FormGroup>
                </FlexItem>
            </Flex>
        )
    }, [selectedFieldType, startRange, endRange])

    const multiValueInputbox = useMemo(() => {
        return (
            <FormGroup label='value' fieldid='add-filter-operator'>
                <CommonMultiSelectBox
                    initialOptions={fieldvalues}
                    createOption={true}
                    onSelectChange={handleMultiSelectChange}
                    isDisabled={isDisabledselectValue}
                    updateValue={updateValueselectBox}
                    placeholderText="Select a value"
                    ariaLabel="Your aria label"
                    clearButtonAriaLabel="Clear input value"
                    selectedOptions={selectedvalue}
                    updateOptions={updateSelectOption}
                />
            </FormGroup>
        )
    }, [updateValueselectBox, fieldvalues, isDisabledselectValue, selectedvalue])

    const singleValueInputbox = useMemo(() => {
        return (
            <FormGroup label='value' fieldid='add-filter-operator'>
                <CommonSelectBox
                    options={fieldvalues}
                    isLoading={isloading}
                    placeholder="Select"
                    isDisabled={isDisabledselectValue}
                    updateValue={updateValueselectBox}
                    createOption={true}
                    selectedOptions={selectedvalue}
                    updateOptions={updateSelectOption}
                />
            </FormGroup>
        )
    }, [fieldvalues, isloading, isDisabledselectValue, selectedvalue])


    const ConditionFormGroup = (ConditionalOperator) => {
        if (ConditionalOperator === 'is_one_of' || ConditionalOperator === 'is_not_one_of') {
            return multiValueInputbox
        }
        else if (ConditionalOperator === 'is_between' || ConditionalOperator === 'is_not_between') {
            return rangeInputBox
        }
        else {
            return singleValueInputbox
        }
    }

    const filterform = useMemo(() => {
        return (
            <div>
                <Form>
                    <Grid hasGutter md={6}>
                        <FormGroup label='Field' fieldid='add-filter-field'>
                            <div>
                                <CommonSelectBox
                                    options={props.fieldData && props.fieldData.map(obj => ({
                                        value: obj.fields,
                                        children: obj.fields
                                    }))}
                                    isLoading={false}
                                    placeholder="Select field name"
                                    isDisabled={false}
                                    updateValue={updateFieldSelectBox}
                                    createOption={false}
                                    selectedOptions={selectedField}
                                />
                            </div>
                        </FormGroup>
                        <FormGroup label='Operator' fieldid='add-filter-operator'>
                            <div>
                                <CommonSelectBox
                                    options={filteredOperatorOptions}
                                    isLoading={false}
                                    placeholder="Select operator"
                                    isDisabled={isDisabledselectOperator}
                                    updateValue={updateOperatorSelectBox}
                                    createOption={false}
                                    selectedOptions={selectedoperator}
                                />
                            </div>
                        </FormGroup>
                        <GridItem span={12}>
                            {selectedoperator !== 'exists' && selectedoperator !== 'does_not_exist' &&
                                ConditionFormGroup(selectedoperator)}
                        </GridItem>
                    </Grid>
                </Form>
            </div>
        )
    }, [props.fieldData, filteredOperatorOptions, isDisabledselectOperator, selectedoperator, updateOperatorSelectBox, selectedField, updateFieldSelectBox])

    const pathName = useLocation().pathname;
    const segments = pathName.split('/').filter(segment => segment !== '')
    const isLocationValid = segments.includes('individual-agent-dashboard');

    // console.log("props.filterformdata", props.filterformdata)
    return (
        <>
            <Flex className='pf-v5-u-mt-xs pf-v5-u-mb-xs' spaceItems={{ default: 'spaceItemsXs' }}>
                <FlexItem key={'addfilterChipflex'}>
                    {props.filterformdata && props.filterformdata.length > 0 && (
                        props.filterformdata.map((filter, index) => {
                            if (Object.keys(filter).length > 0
                                && filter.condition !== 'Nested_AND' && filter.condition !== 'Nested_OR'
                                && filter.condition !== 'Multi_AND_Search' && filter.condition !== 'Multi_OR_Search'
                                && filter.condition !== 'Search_Greater_than' && filter.condition !== 'Search_less_than'
                                && filter.condition !== 'COMBINE_OR_AND' && filter.condition !== 'Random_Search'
                            ) {
                                const chipProps = isLocationValid && filter.fields == 'agent.name' ?
                                    { isReadOnly: true } :
                                    { onClick: () => handleDeleteChip(index) };
                                return <>
                                    <ChipGroup>
                                        <Chip key={index + 'chipprop'} {...chipProps}>
                                            {getChipText(filter)}
                                        </Chip>
                                    </ChipGroup>
                                </>

                            }
                            else {
                                return null
                            }
                        })
                    )}
                </FlexItem>
                <FlexItem key={'popupfilter'}>
                    <Popover
                        aria-label="Basic popover"
                        showClose={false}
                        maxWidth='500px'
                        hideOnOutsideClick={true}
                        key={'filter-popover'}
                        headerContent={
                            <div> <Text component={TextVariants.small}>Edit Filter</Text> </div>}
                        bodyContent={
                            <React.Fragment>
                                {filterform}
                            </React.Fragment>
                        }
                        footerContent={(hide) => (
                            <ActionGroup className='pf-v5-u-display-flex pf-v5-u-justify-content-flex-end'>
                                <Button variant="link" onClick={() => { handleCancelFilter(); hide() }} key={'cancelbutton'}>Cancel </Button>
                                <Button key={'savebutton'} variant="primary" isDisabled={!isSaveButtonEnabled} onClick={() => { handleSaveFilter(); hide() }} >Save</Button>
                            </ActionGroup>
                        )
                        }
                    >
                        <Button variant="link" size="sm" icon={<PlusIcon />} onClick={() => OnAddfilterFormclick()} > Add Filter </Button>
                    </Popover>
                </FlexItem>

                {pathName.includes('security-events') && <FlexItem align={{ default: 'alignRight' }}>
                    <Checkbox label="Include Network events"
                        id="uncontrolled-check-1"
                        isChecked={isIncludeNw}
                        onChange={handleNetworkEvents} />
                </FlexItem>
                }
            </Flex>
        </>
    )
})
const mapStateToProps = (state) => ({
    dates: { startDate: state.startdate, endDate: state.enddate },
    filterformdata: state.FilterData,
    selectedGroup: state.SelectedGroup,
    fieldData: state.MappingKeys
});

const mapDispatchToProps = (dispatch) => {
    return {
        SetFilterData: (Filterformdata) => { (FilterData(Filterformdata)); },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(AddFilterForm)
