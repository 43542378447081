import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import GroupAgent from '../GroupManagement/GroupAgent'
import {
    Button,
    Card,
    CardBody,
    Checkbox,
    EmptyState,
    EmptyStateHeader,
    EmptyStateBody,
    EmptyStateIcon,
    MenuToggle,
    Modal,
    ModalVariant,
    Pagination,
    SearchInput,
    Select,
    SelectOption,
    SelectList,
    SelectGroup,
    Toolbar,
    ToolbarContent,
    ToolbarToggleGroup,
    ToolbarGroup,
    ToolbarItem,
    Tooltip,
    Icon,
    FlexItem,
    Flex,
    ProgressSize,
    ProgressVariant,
    Progress,
    ProgressMeasureLocation,

} from '@patternfly/react-core'
import dbApi from '../../services/DbApi'
import { Table, Thead, Tr, Th, Tbody, Td, InnerScrollContainer } from '@patternfly/react-table';
import { SortAmountDownIcon } from '@patternfly/react-icons/dist/esm/icons/sort-amount-down-icon';
import OrganizationGroupForm from './OrganizationGroupForm'
import { BanIcon, CheckCircleIcon, EditAltIcon, PlusCircleIcon, TrashAltIcon } from '@patternfly/react-icons';
import { Loader } from 'src/util/Loadingstate';
import { alertBoxData } from 'src/Redux/Action';
import { formatTableTimestamp } from 'src/util/Timeutils'
// import OnBoardingForm from './OnBoardingForm'
import { Emptystatefornodata } from 'src/util/EmptyStateCard'
import ConfirmBox from '../ConfirmBox/ConfirmBox'
import UpdateOrganisation from './UpdateOrganisation'
const OrganizationList = forwardRef(function SecurityDashboard(props, ref) {
    const defaultColumns = {
        unique_id: 'Unique ID',
        name: "Name",
        contact_person:'Contact Person Name',
        // detail: "Detail",
        contact: "Contact",
        email: "Email",
        // status: 'Status',
        // no_of_duration: " Requested Duration"
        
        created_datetime: "Request Datetime",
        start_datetime: 'Commencement Date',
        end_datetime: 'Expiry Date',
        RemainDays: 'Remaining Days',
        Action: 'Action'
    };
    const childOrganizationFormRef = useRef(null);
    const childUpdateOnBoardFormRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false)
    const [columns, setColumns] = useState(defaultColumns);
    const [rows, setRows] = useState([]);
    const [selectedRowName, setSelectedRowName] = useState('');
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [searchValue, setSearchValue] = useState('');
    const [isSortDropdownOpen, setIsSortDropdownOpen] = useState(false);
    const [totalItem, setTotalItem] = useState(0)
    const [activeSortIndex, setActiveSortIndex] = useState(0);
    const [activeSortDirection, setActiveSortDirection] = useState('desc');
    const excludeOrder = ['Request Datetime', 'Action', 'Remaining Days']
    const [dataFilters, setDataFilters] = useState({ status: '' })
    const [confirmModalData, setConfirmBox] = useState({
        show: false,
        msg: '',
        funCall: '',
        funParam: {},
        title: '',
        position: '',
        iconvariant: '',
        submitbtnvariant: '',
    })
    const dispatch = useDispatch();
    // const handleModalToggle = (CurrentOrg) => {
    //     setCurrentOrg(CurrentOrg)
    //     setModalOpen(!isModalOpen);
    // };

    const handleCheckChange = (event, checked, type) => {
        const checkboxId = event.target.id;
        console.log("checkboxId", checkboxId)
        let temp = dataFilters
        temp[type] = checked ? checkboxId : ''
        // if (checked) {
        //     temp[type].push(checkboxId)
        // } else {
        //     temp[type] = temp[type].filter(obj => obj != checkboxId)
        // }
        setDataFilters(prevDataFilters => ({
            ...prevDataFilters,
            temp
        }));

    }
    const getOrganiztionList = async () => {
        let status = ''
        if (dataFilters.status.length > 0) {
            status = dataFilters.status
        }
        let data = {
            limitStart: (perPage * page) - perPage,
            limitLength: perPage,
            search: searchValue,
            sortBy: Object.keys(columns)[activeSortIndex],
            sortOrder: activeSortDirection,
            status: status,
            // status: ''
        }
        try {
            setIsLoading(true)
            let res = await dbApi.postApi("opensearch/get_customer_on_board_list", data)
            console.log(res)
            let ALlOrg = []
            if (res.data.data.length > 0) {
                ALlOrg = res.data.data
                setRows(ALlOrg)
                setTotalItem(res.data.totalRecord)
                setIsLoading(false)
            }
            else {
                setRows(ALlOrg)
                setTotalItem(0)
                setIsLoading(false)
            }
            setIsLoading(false)
        } catch (error) {
            console.log(error)
            setIsLoading(false)
        }
    }

    const finalDelete = async (data) => {
        try {
            let param = {
                id: data
            }
            let res = await dbApi.postApi("opensearch/delete_customer_on_board", param)
            console.log('delete', res)
            if (!res.error) {
                dispatch(alertBoxData([{ title: res.message, variant: 'success' }]));
                getOrganiztionList()
            }
            else {
                dispatch(alertBoxData([{ title: res.message, variant: 'danger' }]))
            }
        } catch (error) {
            console.log(error)
        }

    }
    const deleteOrganization = (orgId,orgName) => {
        setConfirmBox({
            show: true,
            msg: 'This organization will be deleted from your list',
            funCall: finalDelete,
            funParam: orgId,
            title: `Permanently delete ${orgName} organization?`,
            position: 'default',
            iconvariant: 'warning',
            submitbtnvariant: 'danger',
        })
    }

    useEffect(() => {
        getOrganiztionList()
    }, [
        page,
        perPage,
        activeSortIndex,
        activeSortDirection,
        searchValue,
        dataFilters,
    ])


    //sorting
    const onSort = (event, index, direction) => {
        setActiveSortIndex(index);
        setActiveSortDirection(direction);
    };
    const handleSetPage = (_evt, newPage) => {
        setPage(newPage);
    };
    const handlePerPageSelect = (_evt, newPerPage) => {
        setPerPage(newPerPage);
    };
    //Pagination in Agent table 
    const renderPagination = () => <Pagination
        itemCount={totalItem}
        // isCompact
        page={page}
        perPage={perPage}
        onSetPage={handleSetPage}
        onPerPageSelect={handlePerPageSelect}
    />;

    const tableToolbar = <Toolbar id="sortable-toolbar" >
        <ToolbarContent>
            <ToolbarItem visibility={{
                md: 'hidden'
            }}>
                <Select isOpen={isSortDropdownOpen} selected={[activeSortDirection, activeSortIndex]} onOpenChange={isOpen => setIsSortDropdownOpen(isOpen)} onSelect={(event, value) => {
                    if (value === 'asc' || value === 'desc') {
                        onSort(event, activeSortIndex, value);
                    } else {
                        onSort(event, value, activeSortDirection !== 'none' ? activeSortDirection : 'asc');
                    }
                }} toggle={toggleRef => <MenuToggle ref={toggleRef} onClick={() => setIsSortDropdownOpen(!isSortDropdownOpen)} isExpanded={isSortDropdownOpen} variant="plain">
                    <SortAmountDownIcon />
                </MenuToggle>}>
                    <SelectGroup label="Sort column">
                        <SelectList>
                            {Object.keys(columns).map((column, columnIndex) => <SelectOption key={column} value={columnIndex} isSelected={activeSortIndex === columnIndex}>
                                {column}
                            </SelectOption>)}
                        </SelectList>
                    </SelectGroup>
                    <SelectGroup label="Sort direction">
                        <SelectList>
                            <SelectOption isSelected={activeSortDirection === 'asc'} value="asc" key="ascending">
                                Ascending
                            </SelectOption>
                            <SelectOption isSelected={activeSortDirection === 'desc'} value="desc" key="descending">
                                Descending
                            </SelectOption>
                        </SelectList>
                    </SelectGroup>
                </Select>
            </ToolbarItem>
            <ToolbarGroup role="group" style={{ alignItems: 'center' }} >
                <ToolbarItem style={{width:'70%'}}>
                    <SearchInput placeholder="Search..."
                        value={searchValue}
                        onChange={(_event, value) => setSearchValue(value)}
                        // onSearch={(_event, value) => setSearchValue(value)}
                        onClear={() => setSearchValue('')} />
                </ToolbarItem>
                <ToolbarGroup role="org-status"  >
                    <ToolbarItem> <Checkbox label="Active" key='active' isChecked={dataFilters.status === '1'} onChange={(event, checked) => handleCheckChange(event, checked, 'status')} aria-label="Active" id="1" /></ToolbarItem>
                    <ToolbarItem> <Checkbox label="Inactive" key='inactive' isChecked={dataFilters.status === '0'} onChange={(event, checked) => handleCheckChange(event, checked, 'status')} aria-label="Inactive" id="0" /></ToolbarItem>
                </ToolbarGroup>
            </ToolbarGroup>
            <ToolbarItem align={{ default: 'alignRight' }}>
                {renderPagination()}
            </ToolbarItem>
        </ToolbarContent>
    </Toolbar>;


    const groupForm = <OrganizationGroupForm ref={childOrganizationFormRef} />

    const EditOrganization = <UpdateOrganisation ref={childUpdateOnBoardFormRef} callUpdateList={getOrganiztionList} openModal={true} />
    
    return (
        <React.Fragment>
            {groupForm}

            <ConfirmBox confirmModal={confirmModalData} />
            <div className="pf-v5-u-p-sm">
                {tableToolbar}
                {isLoading ? <Loader /> :
                    <>
                        <InnerScrollContainer key={'innercontroller'}>
                            <Table variant='compact' isStickyHeader gridBreakPoint="grid-md" >
                                <Thead key={'header-row'}>
                                    <Tr key={'row-row'}>
                                        {Object.values(columns).map((column, columnIndex) => {
                                            const stickyColumnsIndex = [0, 1];
                                            const isSticky = stickyColumnsIndex.includes(columnIndex);
                                            const sortParams = {
                                                sort: {
                                                    sortBy: {
                                                        index: activeSortIndex,
                                                        direction: activeSortDirection,

                                                    },
                                                    onSort,
                                                    columnIndex
                                                }
                                            };
                                            // return <Th key={columnIndex} {...sortParams} isStickyColumn={isSticky}>{column} </Th>;
                                            return (
                                                <>
                                                    {!excludeOrder.includes(column) ? <Th modifier='truncate' key={columnIndex + column} {...sortParams} isStickyColumn={isSticky}>{column}</Th>
                                                        :
                                                        <Th modifier='truncate' key={columnIndex + column} isStickyColumn={isSticky}>{column}</Th>}

                                                </>)
                                        })}
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {rows.length > 0 ?
                                        rows && rows.map((row, rowIndex) => {
                                            const { formattedTimeStamp: requesttime } = formatTableTimestamp(row?.created_datetime);
                                            const { formattedTimeStamp: startdatetime } = row?.start_datetime ? formatTableTimestamp(row?.start_datetime) : { formattedTimeStamp: null };
                                            const { formattedTimeStamp: enddatetime } = row?.end_datetime ? formatTableTimestamp(row?.end_datetime) : { formattedTimeStamp: null };
                                            const duration = row?.duration == '1' ? 'Month' : 'Year'
                                            const daypercent = (row?.remaingDay) / (row?.totalDay) * 100
                                            let variant = ''
                                            if (daypercent < 15) {
                                                variant = ProgressVariant.danger
                                            }
                                            else {
                                                variant = ProgressVariant.success
                                            }
                                            return (
                                                <>
                                                    <Tr key={row.agent_id}
                                                        onRowClick={() => setSelectedRowName(rowIndex)}
                                                        isSelectable
                                                        isClickable
                                                        isRowSelected={selectedRowName === rowIndex}
                                                    >
                                                        <Td width={15} dataLabel="Unique ID" key={rowIndex + 'uniqueid'}>
                                                            <Flex display={{ default: 'inlineFlex' }} spaceItems={{ default: 'spaceItemsXs' }} alignItems={{ default: 'alignItemsCenter' }}>
                                                                <FlexItem>
                                                                    {row?.status == 1 ?
                                                                        <Tooltip content='Active'><React.Fragment> <Icon status='success'> <CheckCircleIcon /></Icon> </React.Fragment></Tooltip>
                                                                        :
                                                                        <Tooltip content='Inactive'>
                                                                            <React.Fragment>  <Icon status='danger'> <BanIcon /> </Icon> </React.Fragment>
                                                                        </Tooltip>
                                                                    }</FlexItem>
                                                                <FlexItem> {row?.unique_id}</FlexItem>
                                                            </Flex>
                                                        </Td>
                                                        <Td width={15} dataLabel="Name" key={rowIndex + 'org_name'} > {row?.name}</Td>
                                                        <Td dataLabel="Contact Person Name" key={rowIndex + 'contact_person'} >{row?.contact_person}</Td>
                                                        <Td dataLabel="Contact" key={rowIndex + 'Contact'} > {row?.contact} </Td>
                                                        <Td dataLabel="Email" key={rowIndex + 'email'} modifier='' > {row?.email} </Td>
                                                        {/* <Td dataLabel="Address" key={rowIndex + 'address'}> {row?.address} </Td>
                                                        <Td dataLabel="No. Of Users" key={rowIndex + 'no_of_users'}>{row?.no_of_users}</Td> */}
                                                        {/* <Td dataLabel="Status" key={rowIndex + 'status'}>{row?.status == 0 ? 'InActive' : 'Active'}</Td> */}
                                                        {/* <Td dataLabel="Website" key={rowIndex + 'website'}>{row?.website}</Td> */}
                                                        {/* <Td   dataLabel="Requested Duration" key={rowIndex + 'no_of_duration'}>{row?.no_of_duration} {duration}</Td> */}
                                                        <Td width={15} dataLabel="Request Datetime" key={rowIndex + 'rdt'}>{requesttime}</Td>
                                                        <Td width={15} dataLabel="Commencement Date" key={rowIndex + 'sdt'}>{startdatetime}</Td>
                                                        <Td width={20} dataLabel="Expiry Date" key={rowIndex + 'edt'}>{enddatetime}</Td>
                                                        <Td width={10} dataLabel="Remaining Days" key={rowIndex + 'remaindays'}>
                                                            {/* <Progress aria-label="active"
                                                                value={daypercent}
                                                                variant={variant}
                                                                size={ProgressSize.sm}
                                                                measureLocation={ProgressMeasureLocation.inside}
                                                                label={`${row?.remaingDay} days`} /> */}
                                                            <Progress
                                                                value={row?.remaingDay}
                                                                min={0}
                                                                max={row?.totalDay}
                                                                title=" "
                                                                style={{ "--pf-v5-c-progress--GridGap": "0",
                                                                     '--pf-v5-c-progress__status-icon--Color': props?.theme=='dark-theme'?'#1b1d21':'#fff' }}
                                                                variant={variant}
                                                                size={ProgressSize.sm}
                                                                measureLocation={ProgressMeasureLocation.top}
                                                                label={`${row?.remaingDay} days`}
                                                                valueText={`${row?.remaingDay} of ${row?.totalDay}"}`}
                                                            />
                                                        </Td>
                                                        <Td width={10} dataLabel="Action" key={rowIndex + 'action'}>
                                                            <Flex spaceItems={{ default: 'spaceItemsSm' }}>
                                                                <FlexItem key={'editorg'}>
                                                                    <Tooltip content='Edit organization details'  ><Icon onClick={() => { childUpdateOnBoardFormRef.current.setUpdateOnBoardForm(row?.unique_id, row?.name) }}><EditAltIcon /></Icon></Tooltip>
                                                                    {EditOrganization}
                                                                </FlexItem>
                                                                <FlexItem key={'addgroup'}>
                                                                    <Tooltip content='Add Group to organization'><Icon
                                                                        onClick={() => childOrganizationFormRef.current.setOrganizationForm(row?.id, row?.name)}
                                                                    ><PlusCircleIcon /></Icon></Tooltip>
                                                                </FlexItem>
                                                                <FlexItem key={'remove org'}>
                                                                    <Tooltip content='delete organization'><Icon status='danger' onClick={() => deleteOrganization(row?.id,row?.name)} ><TrashAltIcon /></Icon></Tooltip>
                                                                </FlexItem>
                                                            </Flex>
                                                        </Td>
                                                    </Tr>
                                                </>
                                            )
                                        })
                                        :
                                        <Tr key='empty-row'>
                                            <Td colSpan={10} key='empty-data'>
                                                <Emptystatefornodata />
                                            </Td>
                                        </Tr>
                                    }
                                </Tbody>
                            </Table>
                        </InnerScrollContainer>
                        <Card> <CardBody style={{ margin: '-10px' }}>{renderPagination()}</CardBody></Card>
                    </>
                }
            </div>
        </React.Fragment>
    )
})

const mapStateToProps = (state) => ({
    theme:state.theme
})

const mapDispatchToProps = {}

export default connect(mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(OrganizationList)