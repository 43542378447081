// export default TopProto;
import React, { useEffect, useState } from 'react';
import * as echarts from 'echarts';
import { generateUniqueID } from '../../util/UniqueIdGenerator';
import { Emptystatefornodata } from '../../util/EmptyStateCard';
import { UpdateFilterData } from '../../util/FilterHelper';
import { FilterData } from '../../Redux/Action';
import { connect, useDispatch } from 'react-redux';
const TopProtoandApp = (props) => {
  const id = generateUniqueID()
  const dispatch = useDispatch();
  const handleFilter = (fields, value) => {
    const updatedFields = UpdateFilterData(props.filterformdata, fields, 'is', value);
    dispatch(FilterData(updatedFields));
  }
  useEffect(() => {
    // Initialize ECharts instance
    const chartDom = document.getElementById(id + '-data');
    const myChart = echarts.init(chartDom);
 const textcolor = props.theme == 'dark-theme'?'#fff':'#000'
    const option = {
      legend: {
        top: 'bottom',
        itemWidth: 9.5,
        itemHeight: 10,
        itemGap: 6.5,
        icon: "rect",
        textStyle: {
          width: 200,
          overflow: 'truncate',
          fontSize: 12,
          color:textcolor
        }
      },
      tooltip: {
        trigger: 'item',
        textStyle: {
          color: "#000"
        }
      },
      series: [
        {
          name: props.Name,
          type: 'pie',
          left: 0,
          right: 0,
          top: 0,
          bottom: 50,
          radius: '70%',
          center: ['50%', '45%'],
          roseType: 'area',
          label:{
            color:textcolor
          },
          itemStyle: {
            borderRadius: 10,
          },
          data: props.TopData.map(item => ({
            name: item.key,
            value: item.doc_count,
            // nestedkey1: item.type,
          })),
        },

      ],
    };

    option && myChart.setOption(option);
    myChart.on('legendselectchanged', function (param) {
      // console.log("asdf", param.data.name)
      // console.log("data", param.nestedkey)
      // const Fields = props.filterFields ? props.filterFields:param.nestedkey;
      const Fields = props.filterFields;
      handleFilter(Fields, param.name)
    });
    const resizeChart = () => {
      if (myChart) { // Check if chart exists before resizing
        myChart.resize();
      }
    };

  window.addEventListener('resize', resizeChart);
  return () => {
      myChart.dispose();
      window.removeEventListener('resize', resizeChart);
  };
  }, [props.TopData, props.height, props.Name, props.filterFields,props.theme,props.sidebarState]);


  return <> <div className="container">
    {props.TopData.length == 0 ?
      (<>
        <div id={id + '-data'} style={{ width: '1px', height: '1px' }}> </div>
        < Emptystatefornodata />
      </>)
      : (
        <div id={id + '-data'} style={{ width: '100%', height: props.height }} > </div>
      )
    }
  </div>
  </>
};
// export default TopProtoandApp
const mapStateToProps = (state) => ({
  dates: { startDate: state.startdate, endDate: state.enddate },
  filterformdata: state.FilterData,
  theme: state.theme,
  sidebarState:state.sidebarstate
  // mapStateToProps content remains unchanged if you have it
});

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(TopProtoandApp)