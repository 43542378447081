import React, { useMemo, useRef, useState } from 'react';
import {
  Button,
  Card,
  DataList,
  DataListCheck,
  DataListItem,
  DataListItemRow,
  DataListCell,
  DataListItemCells,
  Label,
  Toolbar,
  ToolbarContent,
  ToolbarToggleGroup,
  ToolbarGroup,
  ToolbarItem,
  ToolbarFilter,
  MenuToggle,
  Modal,
  OverflowMenu,
  OverflowMenuGroup,
  OverflowMenuItem,
  OverflowMenuDropdownItem,
  Dropdown,
  DropdownList,
  Pagination,
  PaginationVariant,
  TextContent,
  Tooltip,
  Icon,
  Select, SelectOption, SearchInput, List, ListItem, Chip, ListVariant, Popover, TextVariants, Text, ChipGroup, Badge,
  Truncate
} from '@patternfly/react-core';

import { Table, TableText, Thead, Tr, Th, Tbody, Td, ExpandableRowContent, InnerScrollContainer } from '@patternfly/react-table';
import FilterIcon from '@patternfly/react-icons/dist/esm/icons/filter-icon';
import ArrowCircleUpIcon from '@patternfly/react-icons/dist/esm/icons/arrow-circle-up-icon';
import ArrowCircleDownIcon from '@patternfly/react-icons/dist/esm/icons/arrow-circle-down-icon';
import EllipsisVIcon from '@patternfly/react-icons/dist/esm/icons/ellipsis-v-icon';
import {
  PlusCircleIcon, SortAmountDownIcon, TrashIcon, PlayIcon, CloseIcon, RedoIcon, StopIcon, ClockIcon,
  CheckIcon, HourglassHalfIcon, TimesCircleIcon, PauseIcon, PausedIcon, UserIcon, InfoCircleIcon
} from '@patternfly/react-icons';

import dbApi from '../../../services/DbApi';
import { useDispatch } from 'react-redux'
import { alertBoxData } from 'src/Redux/Action';
import ConfirmBox from '../../ConfirmBox/ConfirmBox'
import { useLocation, useNavigate, Link } from 'react-router-dom/dist';
// import { Emptystatefornodata } from 'src/util/EmptyStateCard';
import { Loader } from 'src/util/Loadingstate';
import { formatTableTimestamp } from 'src/util/Timeutils';
import { useParams } from 'react-router-dom';
import { EmptystateforIncident } from 'src/util/EmptyStateCard';
import AlertLogModal from '../IncidentAlert/AlertLogModal';

const IncidentAlertList = (props) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const childAlertModelRef = useRef(null);

  //Start DataTable State
  const defaultColumns = ['Severity', 'Read', 'Title', 'Incident Id', 'Occur Date', 'Creation Date'];
  const defaultRows = [];
  const excludeOrder = []
  const excludeWrapCol = ['']
  const orderColumns = [
    {
      label: 'Severity',
      col: 'severity'
    },
    {
      label: 'Read',
      col: 'read'
    },
    {
      label: 'Title',
      col: 'title'
    },
    {
      label: 'Incident Id',
      col: 'Incident Id'
    },
    {
      label: 'Occur Date',
      col: 'Occur_Date'
    },
    {
      label: 'Creation Date',
      col: 'Creation_Date'
    }
  ]

  const [dataFilters, setDataFilters] = useState({
    name: [],
    status: [],
    statusChip: [],
    severity: [],
    severityChip: []
  })
  const [toast, setToast] = useState({
    title: '',
    variant: ''
  })
  const [expandedRowId, setExpandedRowId] = useState([]);
  const [isloading, setIsLoading] = useState(false)
  const [selectedRows, setSelectedRows] = useState([]);
  const [inputValue, setInputValue] = useState('')
  const [isCategoryDropdownOpen, setIsCategoryDropdownOpen] = useState(false)
  const [currentCategory, setCurrentCategory] = useState('Name')
  const [isFilterDropdownOpen, setIsFilterDropdownOpen] = useState(false)
  const [isKebabDropdownOpen, setIsKebabDropdownOpen] = useState(false);
  const [filters, setFilters] = useState([]);
  const [filteredColumns, setFilteredColumns] = useState(defaultColumns);
  const [filteredRows, setFilteredRows] = useState(defaultRows);
  const [managedColumns, setManagedColumns] = useState(defaultColumns);
  const [managedRows, setManagedRows] = useState(defaultRows);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [checkedState, setCheckedState] = useState(Array(defaultColumns.length).fill(true));
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [paginatedRows, setPaginatedRows] = useState([]);
  const [totalItem, setTotalItem] = useState(0)
  const [activeSortIndex, setActiveSortIndex] = useState(0);
  const [activeSortDirection, setActiveSortDirection] = useState('desc');

  const [confirmModalData, setConfirmBox] = useState({
    show: false,
    msg: '',
    funCall: '',
    funParam: {}
  })

  const statusIcon = {
    'Waiting': <PauseIcon color='#337ab7' />,
    'InProgress': <HourglassHalfIcon color='#f39c12' />,
    'Completed': <CheckIcon color='#3c763d' />
  };

  //Start DataTable Code
  const matchCheckboxNameToColumn = (name) => {
    switch (name) {
      case 'check1':
        return 'Severity';
      case 'check2':
        return 'Task';
      case 'check3':
        return 'Created At';
    }
  };
  const matchSelectedColumnNameToAttr = (name) => {
    switch (name) {
      case 'Severity':
        return 'severity';
      case 'Read':
        return 'read';
      case 'Title':
        return 'title';
      case 'Case_Id':
        return 'case_id';
      case 'Occur_Date':
        return 'occur_date';
      case 'Creation_Date':
        return 'creation_date';
      default:
        return '';
    }
  };

  const onDelete = (type = '', id = '') => {
    if (type) {
      let temp = dataFilters
      temp[type].splice(0, 1);
      if (type == "status") {
        temp['statusChip'].splice(0, 1);
      } else if (type == "severity") {
        temp['severityChip'].splice(0, 1);
      }
      setDataFilters(prevDataFilters => ({
        ...prevDataFilters,
        temp
      }));
    } else {
      setDataFilters({ name: [], status: [], statusChip: [], severity: [], severityChip: [] })
    }
  };

  const onFilterToggle = () => {
    setIsFilterDropdownOpen(!isFilterDropdownOpen)
  };

  const onCategorySelect = event => {
    setCurrentCategory(event.target.innerText)
    setIsCategoryDropdownOpen(false)
    setIsFilterDropdownOpen(false)
  };

  const onCategoryToggle = () => {
    setIsCategoryDropdownOpen(!isCategoryDropdownOpen)
  };

  const onInputChange = newValue => {
    setInputValue(newValue)
  };

  const onNameInput = event => {
    if (event.key && event.key !== 'Enter') {
      return;
    }
    let temp = dataFilters
    temp.name[0] = inputValue
    setDataFilters(prevDataFilters => ({
      ...prevDataFilters,
      temp
    }));
  };

  const onFilterSelect = (event, selection, type, chipType) => {
    const checked = event.target.checked;
    let text = event.target.innerText
    setIsFilterDropdownOpen(false)
    let temp = dataFilters
    temp[type][0] = selection
    temp[chipType][0] = text
    setDataFilters(prevDataFilters => ({
      ...prevDataFilters,
      temp
    }));
  };

  const setRowSelected = (row, isSelecting = true) => {
    setSelectedRows((prevSelected) => {
      const otherSelectedRows = prevSelected.filter((r) => r !== row.unique_id);
      return isSelecting ? [...otherSelectedRows, row.unique_id] : otherSelectedRows;
    });
  }

  const isRowSelected = (row) => selectedRows.includes(row.unique_id);

  //Call When click on column sort
  const onSort = (event, index, direction) => {
    setActiveSortIndex(index);
    setActiveSortDirection(direction);
  };

  // Pagination logic
  const handleSetPage = (_evt, newPage) => {
    setPage(newPage);
  };

  const handlePerPageSelect = (_evt, newPerPage, newPage) => {
    setPerPage(newPerPage);
    if (newPage !== page) { setPage(newPage); }
  };

  const renderPagination = () => (
    <Pagination
      itemCount={totalItem}
      page={page}
      perPage={perPage}
      onSetPage={handleSetPage}
      onPerPageSelect={handlePerPageSelect}
    />
  );

  // Removes attribute from each node object in Data.jsx
  const removePropFromObject = (object, keys) =>
    keys.reduce((obj, prop) => {
      const { [prop]: _, ...keep } = obj;
      return keep;
    }, object);

  // Filters columns out of table that are not selected in the column management modal
  const filterData = (checked, name) => {
    const selectedColumn = matchSelectedColumnNameToAttr(name);
    const filteredRows = [];
    if (checked) {
      const updatedFilters = filters.filter((item) => item !== selectedColumn);
      // Only show the names of columns that were selected in the modal
      const filteredColumns = defaultColumns.filter(
        (column) => !updatedFilters.includes(matchSelectedColumnNameToAttr(column))
      );
      // Remove the attributes (i.e. "columns") that were not selected
      managedRows.forEach((item) => filteredRows.push(removePropFromObject(item, updatedFilters)));

      setFilters(updatedFilters);
      setFilteredColumns(filteredColumns);
      setFilteredRows(filteredRows);
    } else {
      let updatedFilters = filters;
      if (typeof selectedColumn != 'undefined') {
        updatedFilters.push(selectedColumn);
      }

      // Only show the names of columns that were selected in the modal
      const filteredColumns = managedColumns.filter(
        (column) => !filters.includes(matchSelectedColumnNameToAttr(column))
      );

      // Remove the attributes (i.e. "columns") that were not selected
      managedRows.forEach((item) => filteredRows.push(removePropFromObject(item, updatedFilters)));

      setFilters(updatedFilters);
      setFilteredColumns(filteredColumns);
      setFilteredRows(filteredRows);
    }
  };
  const unfilterAllData = () => {
    setFilters([]);
    setFilteredColumns(defaultColumns);
    setFilteredRows(managedRows);
  };

  const handleChange = (event, checked) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    // Remove any columns from the table that aren't checked
    filterData(checked, matchCheckboxNameToColumn(target.name));
    const checkedIndex = defaultColumns.findIndex((element) => element === matchCheckboxNameToColumn(target.name));

    const updatedCheckedState = [...checkedState];
    updatedCheckedState[checkedIndex] = value;

    setCheckedState(updatedCheckedState);
  };

  const handleModalToggle = (_event) => {
    setIsModalOpen(!isModalOpen);
  };

  const onSave = () => {
    setManagedColumns(filteredColumns);
    setPaginatedRows(filteredRows);
    setIsModalOpen(!isModalOpen);
  };

  const selectAllColumns = () => {
    unfilterAllData();
    setCheckedState(Array(defaultColumns.length).fill(true));
  };

  async function fetchDataTable() {
    try {
      setIsLoading(true);
      let orderCol = 'create_datetime';
      if (activeSortIndex > 0) {
        const orderResult = orderColumns.find((obj) => obj.label == filteredColumns[activeSortIndex])
        orderCol = orderResult.col;
      }

      const data = {
        incidentId: props?.incidentId ? props.incidentId : '',
        readStatus: dataFilters.status.length > 0 ? dataFilters.status[0] : '',
        severity: dataFilters.severity.length > 0 ? dataFilters.severity[0] : '',
        search: dataFilters.name.length > 0 ? dataFilters.name[0] : '',
        sortByCol: orderCol,
        sortOrder: activeSortDirection,
        limitStart: (perPage * page) - perPage,
        limitLength: perPage
      };

      const ret = await dbApi.postApi('incident_management/alert_list', data);
      const result = ret.data;
      setIsLoading(false);
      if (result?.data?.length > 0) {
        setTotalItem(result.totalRecord);
        setManagedRows(result.data);
        setPaginatedRows(result.data);
        console.log('show alert data', result.data)
      } else {
        setTotalItem(0);
        setManagedRows([]);
        setPaginatedRows([]);
      }

    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }

  }

  React.useEffect(() => {
    fetchDataTable()
  }, [page, perPage, activeSortIndex, activeSortDirection, dataFilters]);
  //End DataTable Code

  const finalRemoveTask = async () => {
    try {
      setIsLoading(true);
      console.log({ taskUniqueId: selectedRows });
      const ret = await dbApi.deleteApi('incident_management/delete_alert', { uniqueId: selectedRows });
      let type = 'danger';
      if (!ret.error) {
        type = 'success';
        fetchDataTable();
      }
      dispatch(alertBoxData([{ title: ret.message, variant: type }]));
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  const removeTask = () => {
    setConfirmBox({
      show: true,
      title: 'Permanently delete alert?',
      msg: "Are you sure you want to delete this alert?",
      funCall: finalRemoveTask,
      funParam: {},
      position: 'default',
      iconvariant: 'warning',
      submitbtnvariant: 'danger',
    })
  }

  const reloadData = () => {
    setSelectedRows([])
    fetchDataTable()
  }

  const getAlertData = (uniqueId, incidentId)  => {
         alert(" uniqueId = "+uniqueId+" == incidentId = "+incidentId);
  }
 
  const renderModal = () => {
    return (
      <Modal
        title="Manage columns"
        isOpen={isModalOpen}
        variant="small"
        description={
          <TextContent>
            <Button isInline onClick={selectAllColumns} variant="link">
              Select all
            </Button>
          </TextContent>
        }
        onClose={handleModalToggle}
        actions={[
          <Button key="save" variant="primary" onClick={onSave}>
            Save
          </Button>,
          <Button key="cancel" variant="secondary" onClick={handleModalToggle}>
            Cancel
          </Button>
        ]}
      >
        <DataList aria-label="Table column management" id="table-column-management" isCompact>
          {
            defaultColumns.map((obj, index) => {
              return <DataListItem key={index} aria-labelledby={"col-mgmt-item-" + index}>
                <DataListItemRow>
                  <DataListCheck
                    aria-labelledby={"col-mgmt-item-" + index}
                    checked={checkedState[index]}
                    name={"check" + (index + 1)}
                    id={"check" + index}
                    onChange={handleChange}
                  />
                  <DataListItemCells
                    dataListCells={[
                      <DataListCell id={"col-mgmt-item-" + index} key={"col-mgmt-item-" + index}>
                        <label htmlFor={"check" + index}>{obj}</label>
                      </DataListCell>
                    ]}
                  />
                </DataListItemRow>
              </DataListItem>
            })
          }
        </DataList>
      </Modal>
    );
  };


  const tableActionItems = [
    <OverflowMenuDropdownItem key="1" onClick={() => handleModalToggle()}  >  Manage Columns</OverflowMenuDropdownItem>,
    <OverflowMenuDropdownItem key="2" onClick={() => removeTask()} isDisabled={selectedRows.length == 0}  >  Remove</OverflowMenuDropdownItem>,
  ];

  const tableAction =
    <Dropdown onSelect={() => setIsKebabDropdownOpen(!isKebabDropdownOpen)} onOpenChange={isKebabDropdownOpen => setIsKebabDropdownOpen(isKebabDropdownOpen)} toggle={toggleRef => <MenuToggle ref={toggleRef} aria-label="overflow menu" variant="plain" onClick={() => setIsKebabDropdownOpen(!isKebabDropdownOpen)} isExpanded={false}>
      <EllipsisVIcon />
    </MenuToggle>} isOpen={isKebabDropdownOpen}>
      <DropdownList >{tableActionItems}</DropdownList>
    </Dropdown>

  function buildFilterDropdown() {
    const statusMenuItems = [
      <SelectOption key="1" value="1" isSelected={dataFilters.status.includes("1")}>Read</SelectOption>,
      <SelectOption key="2" value="0" isSelected={dataFilters.status.includes("0")}>Unread</SelectOption>,
    ];

    const severityMenuItems = [
      <SelectOption key="1" value="L" isSelected={dataFilters.status.includes("L")}>Low</SelectOption>,
      <SelectOption key="2" value="M" isSelected={dataFilters.status.includes("M")}>Medium</SelectOption>,
      <SelectOption key="3" value="H" isSelected={dataFilters.status.includes("H")}>High</SelectOption>,
      <SelectOption key="4" value="C" isSelected={dataFilters.status.includes("C")}>Critical</SelectOption>
    ];

    return <React.Fragment>
      <ToolbarFilter chips={dataFilters.name} deleteChip={onDelete} categoryName="name" showToolbarItem={currentCategory === 'Name'}>
        <SearchInput aria-label="name filter" placeholder="Filter by name..." onChange={(_event, value) => onInputChange(value)} value={inputValue} onClear={() => {
          onInputChange('');
        }} onSearch={onNameInput} />
      </ToolbarFilter>

      <ToolbarFilter chips={dataFilters.statusChip} deleteChip={onDelete} categoryName="status" showToolbarItem={currentCategory === 'Read Status'}>
        <Select aria-label="Status" isOpen={isFilterDropdownOpen} onSelect={(event, selection) => onFilterSelect(event, selection, 'status', 'statusChip')} selected={dataFilters.status} toggle={toggleRef => <MenuToggle ref={toggleRef} onClick={onFilterToggle} isExpanded={isFilterDropdownOpen} style={{
          width: '100%',
          verticalAlign: 'text-bottom'
        }}>
          Filter by read status
        </MenuToggle>}>
          {statusMenuItems}
        </Select>
      </ToolbarFilter>

      <ToolbarFilter chips={dataFilters.severityChip} deleteChip={onDelete} categoryName="severity" showToolbarItem={currentCategory === 'Severity'}>
        <Select aria-label="Severity" isOpen={isFilterDropdownOpen} onSelect={(event, selection) => onFilterSelect(event, selection, 'severity', 'severityChip')} selected={dataFilters.severity} toggle={toggleRef => <MenuToggle ref={toggleRef} onClick={onFilterToggle} isExpanded={isFilterDropdownOpen} style={{
          width: '100%',
          verticalAlign: 'text-bottom'
        }}>
          Filter by severity
        </MenuToggle>}>
          {severityMenuItems}
        </Select>
      </ToolbarFilter>
    </React.Fragment>;
  }

  function buildCategoryDropdown() {
    const categoryMenuItems = [
      <SelectOption key="1" value="Name">Name</SelectOption>,
      <SelectOption key="2" value="Read Status">Read Status</SelectOption>,
      <SelectOption key="3" value="Severity">Severity</SelectOption>,

    ];
    return <ToolbarItem>
      <Select onSelect={onCategorySelect} selected={currentCategory} toggle={toggleRef => <MenuToggle ref={toggleRef} onClick={onCategoryToggle} isExpanded={isCategoryDropdownOpen} icon={<FilterIcon />} style={{
        width: '100%',
        verticalAlign: 'text-bottom'
      }}>
        {currentCategory}
      </MenuToggle>} isOpen={isCategoryDropdownOpen}>
        {categoryMenuItems}
      </Select>
    </ToolbarItem>;
  }

  function renderToolbar() {
    return <Toolbar id="toolbar-with-chip-groups" clearAllFilters={onDelete} collapseListedFiltersBreakpoint="xl">
      <ToolbarContent>
        <ToolbarToggleGroup toggleIcon={<FilterIcon />} breakpoint="xl">
          <ToolbarGroup variant="filter-group" style={{
            lineHeight: '22px',
            alignItems: 'center'
          }}>
            {buildCategoryDropdown()}
            {buildFilterDropdown()}
          </ToolbarGroup>
        </ToolbarToggleGroup>
        <ToolbarItem variant="overflow-menu">
          <OverflowMenu breakpoint="md">
            <OverflowMenuGroup groupType="button" isPersistent>
              {/* <OverflowMenuItem>
                <Link to={'/user-profile'} className="route" ><Button variant="primary" size="sm">New</Button></Link>
              </OverflowMenuItem> */}
              <OverflowMenuItem isPersistent>
                {tableAction}
              </OverflowMenuItem>
            </OverflowMenuGroup>
          </OverflowMenu>
        </ToolbarItem>
        <ToolbarItem variant="pagination">{renderPagination()}</ToolbarItem>
      </ToolbarContent>
    </Toolbar>;
  }

  const tableHeader = useMemo(() => {
    return <Thead>
      <Tr>
        <Th key={'chk'} screenReaderText='key' />
        <Th key={'status'} />
        {managedColumns.map((column, columnIndex) => {
          const sortParams = {
            sort: {
              sortBy: {
                index: activeSortIndex,
                direction: activeSortDirection
              },
              onSort,
              columnIndex
            }
          };
          return !excludeOrder.includes(column) ? <Th modifier={!excludeWrapCol.includes(column) && 'wrap'} key={columnIndex} {...sortParams} >{column}</Th> : <Th modifier={!excludeWrapCol.includes(column) && 'wrap'} key={columnIndex} >{column}</Th>;
        })}
      </Tr>
    </Thead>

  }, [activeSortIndex, activeSortDirection, managedColumns])

  return (
    <div className="pf-v5-u-p-sm">
      <React.Fragment>
        <Card>
          {renderToolbar()}
          {isloading ? <Loader /> :
            <InnerScrollContainer key="scroller">
              <Table variant="compact" aria-label="Column Management Table">
                {tableHeader}
                <Tbody>
                  {
                    paginatedRows.length != 0 ? <>
                      {paginatedRows.map((row, rowIndex) => {
                        const { formattedTimeStamp: createDatetime } = formatTableTimestamp(row.create_datetime);
                        const { formattedTimeStamp: occurDateTime } = formatTableTimestamp(row.log_arrive_datetime);
                        return (
                          <React.Fragment key={rowIndex} >
                            <Tr key={rowIndex}>
                              <Td
                                key={"chk-" + rowIndex}
                                select={{
                                  rowIndex,
                                  onSelect: (_event, isSelecting) => setRowSelected(row, isSelecting),
                                  isSelected: isRowSelected(row),
                                  isDisabled: row?.process_status == 1,
                                }}
                              />
                              {
                                <Td key={rowIndex + "-status"}  >
                                  <Tooltip content={<div>{row.status}</div>} >
                                    {statusIcon[row.status]}
                                  </Tooltip>
                                </Td>
                              }
                              {
                                typeof row?.severity != 'undefined' && <Td key={rowIndex + "-severity"} >
                                  {
                                    <Tooltip content={<div>{row.severity}</div>} >
                                      <Badge className={{
                                        'primary': row.severity === "L",
                                        'warning': row.severity === "M",
                                        'danger': row.severity === "H",
                                        'critical': row.severity === "C",
                                      }} key={1} screenReaderText="Severity">{row.severity}</Badge>
                                    </Tooltip>
                                  }
                                </Td>
                              }
                              <Td  key={rowIndex + "-read"}>
                                <Badge
                                  isRead={row.read_status === 1}
                                  style={{ backgroundColor: row.read_status === 1 ? '#38812F' : '#C9190B', color: 'white' }}
                                >
                                  {row.read_status === 1 ? 'Read' : 'Unread'}
                                </Badge>
                              </Td>
                              <Td width={30} key={rowIndex + "-title"}>
                                <Truncate content={row.rule_description} /> <Button variant="link" onClick={() => { childAlertModelRef.current.getAlertDetail(row.unique_id) }} icon={<InfoCircleIcon />}></Button>
                              </Td>

                              <Td width={20} dataLabel={row.incident_id}>
                                <Link to={`/incident-detail/${row.incident_id}/alert/${row.unique_id}`}>{row.incident_id}</Link>
                              </Td>

                              {
                                typeof row?.log_arrive_datetime != 'undefined' && <Td width={30} key={rowIndex + "-occur_date"}  >
                                  {occurDateTime}
                                </Td>
                              }
                              {
                                typeof row?.create_datetime != 'undefined' && <Td width={20} key={rowIndex + "-create_datetime"}  >
                                  {createDatetime}
                                </Td>
                              }
                            </Tr>
                          </React.Fragment>)
                      }
                      )}
                    </> : <Tr><Td colSpan={6}><EmptystateforIncident /></Td></Tr>
                  }
                </Tbody>
              </Table>
            </InnerScrollContainer>
          }
          <div className='pf-v5-u-mt-md pf-v5-u-mb-md '> {renderPagination()}</div>
          {renderModal()}
        </Card>
        <ConfirmBox confirmModal={confirmModalData} />
        <AlertLogModal ref={childAlertModelRef} />

      </React.Fragment>
    </div>
  );
};
export default IncidentAlertList;

