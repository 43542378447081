import React, { useRef } from 'react'
import {
  Button,
  PageSection,
  PageSectionVariants,
  Tooltip,
  Text,
  TextContent,
  Flex,
  FlexItem,
  Card,
  ModalVariant,
  CardBody
} from '@patternfly/react-core';
import IncidentAlertList from './IncidentAlertList';

const IncidentAlertMain = () => {
  const [count, setCount] = React.useState(0);
  const setAlertTotalCount = (totalCount)=>{
    setCount(totalCount);
  }

  return (
    <div>
      <PageSection padding={{ default: 'noPadding' }} className='pf-v5-u-p-sm'>
        <Card>
          <CardBody>
            <div className='pf-v5-u-mt-sm'>
              <Flex>
                <FlexItem align={{ default: 'alignLeft' }}>
                  <TextContent>
                    <Text className="pf-v5-u-font-size-3xl">
                      My Alerts ({count})
                    </Text>
                  </TextContent>
                </FlexItem>
              </Flex>
              <IncidentAlertList setTaskCount={setAlertTotalCount} />
            </div>
          </CardBody>
        </Card>

      </PageSection>
    </div>
  )
}

export default IncidentAlertMain;