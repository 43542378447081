// import { configureStore } from '@reduxjs/toolkit';
// import { changeState } from './reducer';


// const store = configureStore({
//   reducer: {
//     main: changeState, // Provide a name for your slice
//   },
// });

// export default store;


import { createStore } from 'redux'
import { changeState } from './Reducer'

const store = createStore(changeState)

export default store