import {
    Button,
    Card,
    CardBody,
    Flex,
    FlexItem,
    PageSection,
    MenuToggle,
    Select,
    SelectOption,
    SelectList,
    SelectGroup,
    Text,
    Toolbar,
    ToolbarContent,
    ToolbarItem,
    Icon,
    Pagination,
    SearchInput,
    TextVariants,
    ListItem,
    List,
    ChipGroup,
    Tooltip,
    Chip
} from '@patternfly/react-core'
import { RedoIcon, UploadIcon, TrashAltIcon, PencilAltIcon, SortAmountDownIcon } from '@patternfly/react-icons'
import { Table, Tbody, Td, Th, Thead, Tr } from '@patternfly/react-table'
import React, { useMemo, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { Emptystatefornodata } from 'src/util/EmptyStateCard'
import dbApi from 'src/services/DbApi'
import { Loader } from 'src/util/Loadingstate'

const RuleList = (props) => {
    //table state
    const dispatch = useDispatch()
    const defaultColumns = {
        id: "ID",
        description: "Description",
        groups: 'Groups',
        regulatory_compliances: 'Regulatory Compliances',
        level: 'Level',
        filename: 'File',
        relative_dirname: "Path",


    };
    const [columns, setColumns] = useState(defaultColumns);
    const [rows, setRows] = useState([]);
    const [selectedRowName, setSelectedRowName] = useState('');
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [activeSortIndex, setActiveSortIndex] = useState(0);
    const [totalItem, setTotalItem] = useState(0)
    const [isSortDropdownOpen, setIsSortDropdownOpen] = useState(false);
    const [activeSortDirection, setActiveSortDirection] = useState('asc');
    const [inputValue, setInputValue] = useState('')
    const [searchValue, setSearchValue] = useState('');
    const [loadRulelist, setLoadRulelist] = useState(false)
    const [confirmModalData, setConfirmBox] = useState({
        show: false, msg: '',
        funCall: () => { }, funParam: {}, title: '',
        position: '',
        iconvariant: '',
        submitbtnvariant: '',
    })

    const excludeOrder = ['Groups', 'Regulatory Compliances']

    const getRulesList = async () => {
        let sort = ''
        if (activeSortDirection === 'asc') {
            sort = `+${Object.keys(columns)[activeSortIndex]}`
        }
        else {
            sort = `-${Object.keys(columns)[activeSortIndex]}`
        }
        let param = {
            offset: (perPage * page) - perPage,
            limit: perPage,
            search: searchValue,
            sort: sort,
        }
        try {
            setLoadRulelist(true)
            let res = await dbApi.postApi("Opensearch/Rule_Lists", param)
            console.log(res)
            let rules = []
            if (!res.Rule_List.error) {
                rules = res.Rule_List.Rule
                setRows(rules)
                setTotalItem(res.Rule_List.Total)
            } else {
                setRows([])
                setTotalItem(0)
            }
            setLoadRulelist(false)
        } catch (error) {
            console.log(error)
            setLoadRulelist(false)
        }
    }

    useEffect(() => {
        getRulesList()
    }, [page,
        perPage,
        activeSortIndex,
        activeSortDirection, searchValue])


    const onSort = (event, index, direction) => {
        setActiveSortIndex(index);
        setActiveSortDirection(direction);
    };
    const handleSetPage = (_evt, newPage) => {
        setPage(newPage);
    };
    const handlePerPageSelect = (_evt, newPerPage) => {
        setPerPage(newPerPage);
    };

    const onSearch = event => {
        if (event.key && event.key !== 'Enter') {
            return;
        }
        setSearchValue(inputValue)
        setPage(1)
    }
    const renderPagination = () => (
        <Pagination
            itemCount={totalItem}
            page={page}
            perPage={perPage}
            onSetPage={handleSetPage}
            onPerPageSelect={handlePerPageSelect}
        />
    );



    const tabletoolbar = <React.Fragment>
        <Toolbar>
            <ToolbarContent>
                <ToolbarItem visibility={{ md: 'hidden' }}>
                    <Select isOpen={isSortDropdownOpen} selected={[activeSortDirection, activeSortIndex]} onOpenChange={isOpen => setIsSortDropdownOpen(isOpen)} onSelect={(event, value) => {
                        if (value === 'asc' || value === 'desc') {
                            onSort(event, activeSortIndex, value);
                        } else {
                            onSort(event, value, activeSortDirection !== 'none' ? activeSortDirection : 'asc');
                        }
                    }} toggle={toggleRef => <MenuToggle ref={toggleRef} onClick={() => setIsSortDropdownOpen(!isSortDropdownOpen)} isExpanded={isSortDropdownOpen} variant="plain">
                        <SortAmountDownIcon />
                    </MenuToggle>}>
                        <SelectGroup label="Sort column">
                            <SelectList>
                                {Object.keys(columns).map((column, columnIndex) => <SelectOption key={column} value={columnIndex} isSelected={activeSortIndex === columnIndex}>
                                    {column}
                                </SelectOption>)}
                            </SelectList>
                        </SelectGroup>
                        <SelectGroup label="Sort direction">
                            <SelectList>
                                <SelectOption isSelected={activeSortDirection === 'asc'} value="asc" key="ascending">
                                    Ascending
                                </SelectOption>
                                <SelectOption isSelected={activeSortDirection === 'desc'} value="desc" key="descending">
                                    Descending
                                </SelectOption>
                            </SelectList>
                        </SelectGroup>
                    </Select>
                </ToolbarItem>
                <ToolbarItem style={{ width: '60%' }} variant="search-filter">
                    <SearchInput placeholder="Search..."
                        value={inputValue}
                        onChange={(_event, value) => setInputValue(value)}
                        onSearch={onSearch}
                        onClear={() => { setSearchValue(''); setInputValue('') }} />
                </ToolbarItem>
                <ToolbarItem align={{ default: 'alignRight' }}>
                    <ToolbarItem> {renderPagination()}</ToolbarItem>
                </ToolbarItem>
            </ToolbarContent>
        </Toolbar>
    </React.Fragment>

    const complianceFrameworks = ["pci_dss", "gpg13", "gdpr", "hipaa", "nist_800_53", "tsc", "mitre"];
    const generateComplianceChips = (row) => {
        return (
            <>
                {complianceFrameworks.map((framework) => {
                    const complianceItems = row[framework] && row[framework].length > 0 ? row[framework].join(", ") : "";
                    if (complianceItems) {
                        return (
                            <ChipGroup key={framework} >
                                <Tooltip content={complianceItems}>
                                    <Chip isReadOnly>{framework.toUpperCase()}</Chip>
                                </Tooltip>
                            </ChipGroup>
                        );
                    }
                    return null;
                })}
            </>
        );
    };

    return (
        <div>
            <PageSection className="pf-v5-u-p-sm">
                <Card>
                    <CardBody>
                        <>
                            <Flex spaceItems={{ default: 'spaceItemsXs' }} >
                                <FlexItem> <Text component={TextVariants.h1} className="pf-v5-u-font-weight-bold">Rules ({totalItem ?? 0})
                                    <br />
                                    <Text component={TextVariants.small}>From here you can manage your rules.</Text>
                                </Text>
                                </FlexItem>
                                {/*
                                <FlexItem >  <Button variant='link' icon={<FileIcon />}>Manage rule files</Button>
                                </FlexItem> */}
                                <FlexItem align={{ default: 'alignRight' }}> <Button variant='link' icon={<RedoIcon />} onClick={() => { getRulesList() }}>refresh</Button></FlexItem>
                                {/* <FlexItem > <Button variant='link' icon={<ExportIcon />} isDisabled={true}>Export formatted</Button></FlexItem> */}
                            </Flex>
                            {loadRulelist ? <Loader /> :
                                <>
                                    {tabletoolbar}
                                    <Table variant='compact'>
                                        <Thead>
                                            <Tr>
                                                {Object.values(columns).map((column, columnIndex) => {
                                                    const stickyColumnsIndex = [0, 1];
                                                    const isSticky = stickyColumnsIndex.includes(columnIndex);
                                                    const sortParams = {
                                                        sort: {
                                                            sortBy: {
                                                                index: activeSortIndex,
                                                                direction: activeSortDirection
                                                            },
                                                            onSort,
                                                            columnIndex
                                                        }
                                                    };
                                                    return !excludeOrder.includes(column) ? <Th key={columnIndex} {...sortParams} isStickyColumn={isSticky} >{column}</Th> : <Th key={columnIndex} isStickyColumn={isSticky}>{column}</Th>;
                                                })}
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {rows.length > 0 ?
                                                rows && rows.map((row, rowIndex) => {
                                                    return (
                                                        <>
                                                            <Tr key={rowIndex + 'agentid'}
                                                                onRowClick={() => setSelectedRowName(rowIndex)}
                                                                isSelectable
                                                                isClickable
                                                                isRowSelected={selectedRowName == rowIndex}
                                                            >

                                                                <Td dataLabel='ID' key={rowIndex + 'id'} width={10}>{row?.id}</Td>
                                                                <Td dataLabel='Description' key={rowIndex + 'desc'} width={30}>{row?.description}</Td>
                                                                <Td dataLabel='Groups' key={rowIndex + 'group'}>
                                                                    {row?.groups !== undefined && row?.groups !== null ?
                                                                        (<List isPlain >
                                                                            {row?.groups.map((item, index) => (
                                                                                <ListItem key={index} >{item}</ListItem>
                                                                            ))}
                                                                        </List>) : null}
                                                                </Td>
                                                                <Td dataLabel='Regulatory Compliances' key={rowIndex + 'rc'} >{generateComplianceChips(row)}</Td>
                                                                <Td dataLabel='Level' key={rowIndex + 'level'} >{row?.level}</Td>
                                                                <Td dataLabel='File' key={rowIndex + 'file'} >{row?.filename}</Td>
                                                                <Td dataLabel='Path' key={rowIndex + 'path'} >{row?.relative_dirname}</Td>
                                                            </Tr>
                                                        </>
                                                    )
                                                })
                                                :
                                                <Tr key='empty-row'>
                                                    <Td colSpan={10} key='empty-data'>
                                                        <Emptystatefornodata />
                                                    </Td>
                                                </Tr>
                                            }

                                        </Tbody>
                                    </Table>
                                    <div className='pf-v5-u-mt-sm'> {renderPagination()}</div>
                                </>
                            }
                        </>
                    </CardBody>
                </Card>
            </PageSection>
        </div>
    )
}

export default RuleList