

//new sorted table 
import React, { useState, useEffect } from 'react';
import {
    Button,
    Chip,
    MenuToggle,
    List,
    ListItem,
    Select,
    SelectOption,
    SelectList,
    SelectGroup,
    Toolbar,
    ToolbarContent,
    ToolbarGroup,
    ToolbarItem,
    Pagination,
    Badge,
    ChipGroup,
    SearchInput,
    ToolbarFilter,
    Checkbox,
    Modal,
    ModalVariant,
    Flex,
    FlexItem,
    Tooltip,
    Title,
    Card,
    CardBody,
    ExpandableSection,
    Stack,
    StackItem,
    ListVariant,
} from '@patternfly/react-core';
import dbApi from '../../services/DbApi'
import { Table, Thead, Tr, Th, Tbody, Td, InnerScrollContainer, ExpandableRowContent } from '@patternfly/react-table';
import SortAmountDownIcon from '@patternfly/react-icons/dist/esm/icons/sort-amount-down-icon';
import { formatTableTimestamp, vulformatDate } from '../../util/Timeutils';
import { Emptystatefornodata } from '../../util/EmptyStateCard';
import { Link } from 'react-router-dom';
import { Loader } from 'src/util/Loadingstate';
import { connect, useDispatch } from 'react-redux';
import { getBorderColor, UpdateFilterData } from 'src/util/FilterHelper';
import { FilterData } from 'src/Redux/Action';
import { DownloadIcon, FilterIcon } from '@patternfly/react-icons';
import PocTableData from './PocTableData';
import { exportToExcel } from 'src/util/FilterHelper';

const LatestVulnerabilitiesCve = (props) => {
    const defaultColumns = {
        data_vulnerability_cvss_cvss2_base_score: 'Risk Score',
        timestamp: "Detection Time",
        mitigation_time: 'Mitigation Time',
        mitigation_days: 'Days',
        agent_name: "Agent Name",
        data_vulnerability_status: "Status",
        data_vulnerability_cve: "CVE",
        // data_vulnerability_cvss_cvss2_base_score: "Cvss2 Score",
        // data_vulnerability_cvss_cvss3_base_score: "Cvss3 Score",
        // data_vulnerability_severity: "Severity",
        poc: 'Exploitable',
        data_vulnerability_package_name: "Package Name",
        // data_vulnerability_package_version:
        data_vulnerability_package_condition: 'Package Condition'
    };
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false)
    const [columns, setColumns] = useState(defaultColumns);
    const [rows, setRows] = useState([]);
    const [selectedRowName, setSelectedRowName] = useState('');
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(props.IsModal ? 10 : 5);
    const [inputValue, setInputValue] = useState('')
    const [searchValue, setSearchValue] = useState('');
    const [activeSortIndex, setActiveSortIndex] = useState(0);
    const [isSortDropdownOpen, setIsSortDropdownOpen] = useState(false);
    const [totalItem, setTotalItem] = useState(0)
    const [activeSortDirection, setActiveSortDirection] = useState('desc');
    const [expandedRowNames, setExpandedRowNames] = useState([])
    const [expandedRowData, setExpandedRowData] = useState({});
    const [dataFilters, setDataFilters] = useState({
        status: [],
        severity: [],
        attackVector: '',
        PackageCondition: ''
    })
    const [pocModalopen, setPocModalOpen] = useState(false);
    const [selectedCve, setSelectedCve] = useState('')
    const [cveDesc, setCveDesc] = useState('')
    const [selectedCveseverity, setSelectedCveseverity] = useState('')
    const [selectedCvePoC, setSelectedCvePoc] = useState([])

    //expansion states
    const [expandedSections, setExpandedSections] = useState(Array(5).fill(true));
    const onToggle = (index) => {
        const newExpandedSections = [...expandedSections];
        newExpandedSections[index] = !newExpandedSections[index]; // Toggle the expanded state of the clicked section
        setExpandedSections(newExpandedSections);
    };
    const handleModalToggle = (_event, cve, severity, poc, desc) => {
        setPocModalOpen(!pocModalopen);
        setSelectedCve(cve)
        setSelectedCveseverity(severity)
        setCveDesc(desc)
        setSelectedCvePoc(poc)

    };

    const excludeOrder = ['Exploitable', 'Risk Score']
    const handleFilter = (fields, value) => {
        const updatedFields = UpdateFilterData(props.filterformdata, fields, 'is', value);
        dispatch(FilterData(updatedFields));
    }

    const handleCheckChange = (event, checked, type) => {
        // alert("1")
        const checkboxId = event.target.id;
        // console.log("checkboxId", checkboxId)
        let temp = dataFilters
        if (type == 'attackVector') {
            temp[type] = checked ? checkboxId : ''
        }
        else if (type == 'PackageCondition') {
            temp[type] = checked ? checkboxId : ''
        }
        else {
            if (checked) {
                temp[type].push(checkboxId)
            } else {
                temp[type] = temp[type].filter(obj => obj != checkboxId)
            }
            console.log("temp", temp)
        }
        setDataFilters(prevDataFilters => ({
            ...prevDataFilters,
            temp
        }));
        setPage(1)
    }

    // console.log("datafilters",dataFilters)
    //Get agents by calling API
    const getLatestVulnerabilities = async (data) => {
        let status = []
        let severityStatus = []
        let attackVector = ''
        let packageCondition = ''
        if (dataFilters.status.length > 0) {
            status = dataFilters.status
        }
        if (dataFilters.severity.length > 0) {
            severityStatus = dataFilters.severity
        }
        if (dataFilters.attackVector != '') {
            attackVector = dataFilters.attackVector
        }
        if (dataFilters.PackageCondition != '') {
            packageCondition = dataFilters.PackageCondition
        }

        let param = {
            ...data,
            limitStart: (perPage * page) - perPage,
            limitLength: perPage,
            search: searchValue,
            sortBy: Object.keys(columns)[activeSortIndex],
            sortOrder: activeSortDirection,
            status: status,
            severityStatus: severityStatus,
            attackVector: attackVector,
            packageCondition: packageCondition
        }
        try {
            setIsLoading(true)
            let res = await dbApi.postApi("opensearch/get_vulnerability_list", param)
            console.log(res)
            let All_Vulnerabilities = []
            if (res.data.data.length > 0) {
                All_Vulnerabilities = res.data.data
                setRows(All_Vulnerabilities)
                setTotalItem(res.data.totalRecord)
            }
            else {
                setRows([])
                setTotalItem(0)
            }
            setIsLoading(false)
        } catch (error) {
            console.log(error)
            setIsLoading(false)
        }
    }

    useEffect(() => {
        const Fields = props.filterformdata
        const data = {
            startDate: props.dates.startDate,
            endDate: props.dates.endDate,
            filter: Fields.length > 0 ? JSON.stringify(Fields) : "[]",
        };
        if (props.dates.startDate != "") {
            getLatestVulnerabilities(data)
        }
    }, [
        page,
        perPage,
        activeSortIndex,
        activeSortDirection,
        searchValue,
        dataFilters,
        props?.dates.startDate,
        props?.dates.endDate,
        props?.filterformdata,
        props?.selectedGroup,])

    //sorting
    const onSort = (event, index, direction) => {
        setActiveSortIndex(index);
        setActiveSortDirection(direction);
    };
    // For Expansion
    const setRowExpanded = (row, isExpanding) => {
        const otherExpandedRowNames = expandedRowNames.filter((r) => r !== row.active_doc_id);
        //for all row expansion simultaneously
        setExpandedRowNames(
            // isExpanding ? [...otherExpandedRowNames, row.id] : otherExpandedRowNames
            isExpanding ? [row.active_doc_id] : []
        );
        if (!expandedRowData[row.active_doc_id]) {
            const keyifiedData = keyify(row);
            setExpandedRowData({ [row.active_doc_id]: keyifiedData });
        }
        const updatedExpandedRowNames = isRowExpanded(row)
            ? otherExpandedRowNames
            : [row.active_doc_id];
        setExpandedRowNames(updatedExpandedRowNames);

    };

    const isRowExpanded = (row) => {
        return expandedRowNames.includes(row.active_doc_id);
    };

    const hyperlinkConditions = {
        'agent_id': (value) => value !== '000',
    };
    // Expansion key and value format logic

    // const keyify = (obj, prefix = "") => {
    //     const result = [];
    //     Object.keys(obj).forEach((el) => {
    //         const fullkey = prefix + el;
    //         if (obj[el] === null || obj[el] === undefined || obj[el] === "") {
    //             return;
    //         }
    //         else if (Array.isArray(obj[el])) {
    //             const value = obj[el].join(", ");
    //             result.push({ key: fullkey, value });
    //         } else if (typeof obj[el] === "object" && obj[el] !== null) {
    //             if (el === "_source") {
    //                 result.push(...keyify(obj[el], prefix));
    //             }
    //             else {
    //                 result.push(...keyify(obj[el], fullkey + "."));
    //             }
    //         }
    //         else {
    //             if (hyperlinkConditions[fullkey] && hyperlinkConditions[fullkey](obj[el])) {
    //                 result.push({ key: fullkey, value: <a href="${obj[el]}">{obj[el]}</a> });
    //             }
    //             else if (typeof obj[el] === 'boolean') {
    //                 result.push({ key: fullkey, value: obj[el] ? 'true' : 'false' });
    //             }
    //             else { result.push({ key: fullkey, value: obj[el] }); }
    //         }
    //     });
    //     const sortedResult = result.sort((a, b) => {
    //         if (a.key < b.key) return -1;
    //         if (a.key > b.key) return 1;
    //         return 0
    //     });

    //     return sortedResult;
    // };
    const renderriskscore = (severity, cvss2Score, cvss3Score, PoCData) => {
        const score1 = cvss2Score ? parseFloat(cvss2Score) : 0;
        const score2 = cvss3Score ? parseFloat(cvss3Score) : 0;
        const riskscore = Math.max(score1, score2);
        let badgeStyle = {};
        if (severity == "High") {
            badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#C9190B" };
        }
        else if (severity == "Critical") {
            badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#A30000 " };
        }
        else if (severity == "Medium") {
            badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#F0AB00 " };
        }
        else if (severity == "Low") {
            badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#2B9AF3 " };
        }
        return (
            <Tooltip content={'Risk Score'}>
                <div>
                    <Badge style={badgeStyle}>{parseFloat(riskscore)} </Badge>
                </div>
            </Tooltip>
        );
    };

    const keyify = (row) => {
        if (!row || !row.active_doc_id) {
            return [{ key: '', value: 'No details available' }];
        }
        const PoCData = row?.poc ? JSON.parse(row?.poc) : null;
        const cvename = row?.data_vulnerability_cve || 'N/A';
        const cvss2Score = row?.data_vulnerability_cvss_cvss2_base_score || 0;
        const cvss3Score = row?.data_vulnerability_cvss_cvss3_base_score || 0;
        const vulnerabilitytype = row?.data_vulnerability_type || 'N/A';
        const vulnerabilitypubDate = row?.data_vulnerability_published || 'N/A';
        const vulnerabilityupdateDate = row?.data_vulnerability_updated || 'N/A';
        const vuldesc = row?.rule_description || 'N/A';
        const severity = row?.data_vulnerability_severity || 'N/A';
        const rationale = row?.data_vulnerability_rationale || 'N/A'
        const references = row?.data_vulnerability_references || null
        // JSON.parse(responseData.data_vulnerability_references)
        const exploitabledata = row?.data_vulnerability_cvss_cvss3_vector_attack_vector || null

        //To create a clickable cve from the parahraph of description and rationale in expansion of row 
        const splitTextWithCVELinks = (text) => {
            const cveRegex = /CVE-\d{4}-\d{4,7}/g;
            const parts = text.split(cveRegex);
            const matches = text.match(cveRegex);
            const result = [];
            parts.forEach((part, index) => {
                result.push(part);
                if (matches && matches[index]) {
                    const cve = matches[index];
                    result.push(
                        <a key={index} onClick={() => handleFilter('data.vulnerability.cve', cve)}> {cve}</a>
                    );
                }
            });
            return result;
        };

        return (<React.Fragment>
            <Flex gap={{ default: 'gap2xl' }} direction={{ default: 'column', sm: 'row', md: 'row' }} justifyContent={{ default: 'justifyContentSpaceBetween' }}>
                <FlexItem>
                    <Stack>
                        <StackItem><Title headingLevel='h4'>Risk Score</Title></StackItem>
                        <StackItem className='pf-v5-u-text-align-center-on-md'>{renderriskscore(severity, cvss2Score, cvss3Score)} </StackItem>
                    </Stack>
                </FlexItem>
                <FlexItem>
                    <Stack>
                        <StackItem><Title headingLevel='h4'>Severity</Title></StackItem>
                        <StackItem className='pf-v5-u-text-align-center-on-md'>{renderSeveritycolumn(severity)} </StackItem>
                    </Stack>
                </FlexItem>
                <FlexItem>
                    <Stack>
                        <StackItem><Title headingLevel='h4'>CVE-ID</Title></StackItem>
                        <StackItem className='pf-v5-u-text-align-center-on-md'><Badge><a className='pf-v5-u-color-light-100' onClick={() => { handleFilter('data.vulnerability.cve', cvename) }}>{cvename}</a></Badge> </StackItem>
                    </Stack>
                </FlexItem>
                <FlexItem>
                    <Stack>
                        <StackItem><Title headingLevel='h4'>Vulnerability Type</Title></StackItem>
                        <StackItem className='pf-v5-u-text-align-center-on-md'><Badge>{vulnerabilitytype ? vulnerabilitytype : '--'}</Badge> </StackItem>
                    </Stack>
                </FlexItem>
                <FlexItem>
                    <Stack>
                        <StackItem><Title headingLevel='h4'>Published Date</Title></StackItem>
                        <StackItem className='pf-v5-u-text-align-center-on-md'>{vulnerabilitypubDate ? vulformatDate(vulnerabilitypubDate) : '--'} </StackItem>
                    </Stack>
                </FlexItem>
                <FlexItem>
                    <Stack>
                        <StackItem><Title headingLevel='h4'>Update Date</Title></StackItem>
                        <StackItem className='pf-v5-u-text-align-center-on-md'>{vulnerabilityupdateDate ? vulformatDate(vulnerabilityupdateDate) : '--'} </StackItem>
                    </Stack>
                </FlexItem>
                <FlexItem>
                    <Stack>
                        <StackItem><Title headingLevel='h4'>Related exploits</Title></StackItem>
                        <StackItem className='pf-v5-u-text-align-center-on-md'>
                            {cvss3Score > 7 && exploitabledata !== null && PoCData ? <List variant={ListVariant.inline}>
                                <ListItem>
                                    {
                                        (cvss3Score > 7 && exploitabledata !== null) ?
                                            <Badge style={{ "--pf-v5-c-badge--m-unread--BackgroundColor": "#C9190B" }} key={'vector_attack'}>
                                                <a className='pf-v5-u-color-light-100' onClick={() => { handleFilter('data.vulnerability.cvss.cvss3.vector.attack_vector', exploitabledata) }}>exploitable : {exploitabledata}</a>
                                            </Badge>
                                            :
                                            "--"
                                    }
                                </ListItem>
                                <ListItem>
                                    {PoCData ?
                                        <Badge style={{ "--pf-v5-c-badge--m-unread--BackgroundColor": "#C9190B" }}>
                                            <a className='pf-v5-u-color-light-100' onClick={(e) => handleModalToggle(e, row?.data_vulnerability_cve, row?.data_vulnerability_severity, PoCData, row?.rule_description)}>PoC : {PoCData.length}</a>
                                        </Badge> : '--'}
                                </ListItem>
                            </List> : '--'}


                        </StackItem>
                    </Stack>
                </FlexItem>
            </Flex>
            <br />
            <br />
            <ExpandableSection displaySize="lg" toggleText={'Rationale'} onToggle={() => onToggle(0)} isExpanded={expandedSections[0]}>
                {rationale ? splitTextWithCVELinks(rationale) : '--'}
            </ExpandableSection>
            <br />
            <ExpandableSection displaySize="lg" toggleText={'Description'} onToggle={() => onToggle(1)} isExpanded={expandedSections[1]}>
                {vuldesc ? splitTextWithCVELinks(vuldesc) : '--'}
            </ExpandableSection>
            <br />
            <ExpandableSection displaySize="lg" toggleText={'References'} onToggle={() => onToggle(2)} isExpanded={expandedSections[2]}>
                {references !== null && references != "" ?
                    (<List isPlain >
                        {JSON.parse(references).map((url, index) => (
                            <ListItem key={index}>
                                <a href={url} target="_blank" rel="noopener noreferrer">
                                    {url}
                                </a>
                            </ListItem>
                        ))}
                    </List>)
                    : '--'}
            </ExpandableSection>
        </React.Fragment>
        )
    }
    const getExpandedRowContent = (row) => {
        if (!row || !row.active_doc_id) return <React.Fragment />;
        return keyify(row);
    };

    //Pagination in Agent table 
    const handleSetPage = (_evt, newPage) => {
        setPage(newPage);
    };
    const handlePerPageSelect = (_evt, newPerPage, newPage) => {
        setPerPage(newPerPage);
        setPage(newPage);
    };

    const renderPagination = (variant) => <Pagination
        itemCount={totalItem}
        // isCompact
        variant={variant}
        page={page}
        perPage={perPage}
        onSetPage={handleSetPage}
        onPerPageSelect={handlePerPageSelect}
    />;


    const renderStatuscolumn = (status) => {
        let badgeStyle = {};
        if (status === "Solved") {
            badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#3e8635" };
        }
        else {
            return ' ';
        }
        return (
            <div><Badge style={badgeStyle}><a className='pf-v5-u-color-light-100' onClick={() => { handleFilter('data.vulnerability.status', status) }}>{status}</a></Badge></div>
        );
    };

    const renderSeveritycolumn = (severity) => {
        let badgeStyle = {};
        if (severity == "High") {
            badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#C9190B" };
        }
        else if (severity == "Critical") {
            badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#A30000 " };
        }
        else if (severity == "Medium") {
            badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#F0AB00 " };
        }
        else if (severity == "Low") {
            badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#2B9AF3 " };
        }
        return (
            <div> <Badge style={badgeStyle}><a className='pf-v5-u-color-light-100' onClick={() => { handleFilter('data.vulnerability.severity', severity) }}>{severity}</a></Badge></div>
        );
    };

    const onSearch = event => {
        if (event.key && event.key !== 'Enter') {
            return;
        }
        setSearchValue(inputValue)
        setPage(1)
    }

    const onDeleteChip = (type, id) => {
        if (type === 'Severity') {
            setDataFilters({
                ...dataFilters,
                severity: dataFilters.severity.filter(fil => fil !== id)
            });
        } else {
            setDataFilters({
                ...dataFilters,
                severity: []
            });
        }
    };

    //severity Dropdown
    const [isFilterDropdownOpen, setIsFilterDropdownOpen] = React.useState(false);

    const onStatusSelect = (event, selection) => {
        const checked = event.target.checked;
        setDataFilters({
            ...dataFilters,
            severity: checked ? [...dataFilters.severity, selection] : dataFilters.severity.filter(value => value !== selection)
        });
        setPage(1)
        // setIsFilterDropdownOpen(false);
    };
    const onFilterToggle = () => {
        setIsFilterDropdownOpen(!isFilterDropdownOpen);
    };

    const severityMenuItem = [<SelectOption hasCheckbox key="Critical" value="Critical" isSelected={dataFilters.severity.includes('Critical')}>
        Critical
    </SelectOption>, <SelectOption hasCheckbox key="High" value="High" isSelected={dataFilters.severity.includes('High')}>
        High
    </SelectOption>, <SelectOption hasCheckbox key="Medium" value="Medium" isSelected={dataFilters.severity.includes('Medium')}>
        Medium
    </SelectOption>, <SelectOption hasCheckbox key="Low" value="Low" isSelected={dataFilters.severity.includes('Low')}>
        Low
    </SelectOption>, <SelectOption hasCheckbox key="Untriaged" value="Untriaged" isSelected={dataFilters.severity.includes('Untriaged')}>
        Untriaged
    </SelectOption>,
    ];

    const tableData = rows.map(row => {
        const { formattedTimeStamp: detectiontime } = formatTableTimestamp(row?.timestamp);
        const { formattedTimeStamp: mitigationtime } = row?.mitigation_time ? formatTableTimestamp(row?.mitigation_time) : { formattedTimeStamp: null };
        const PoCData = row?.poc ? JSON.parse(row?.poc) : null;
        const days = row?.mitigation_days > 0 ?
            (mitigationtime === null ? `Active Since ${row?.mitigation_days} days` : `Solved in ${row?.mitigation_days} days`
            )
            :
            (mitigationtime === null ? `Active less than a day` : `Solved in a day`)

        const exploitable = [
            row?.data_vulnerability_cvss_cvss3_base_score > 7 && row?.data_vulnerability_cvss_cvss3_vector_attack_vector
                ? `Exploitable: ${row?.data_vulnerability_cvss_cvss3_vector_attack_vector}`
                : '',
            PoCData != null ? `PoC: ${PoCData.length}` : ''
        ].filter(Boolean).join(', ');

        const score1 = row?.data_vulnerability_cvss_cvss2_base_score ? parseFloat(row?.data_vulnerability_cvss_cvss2_base_score) : 0;
        const score2 = row?.data_vulnerability_cvss_cvss3_base_score ? parseFloat(row?.data_vulnerability_cvss_cvss3_base_score) : 0;
        const riskscore = parseFloat(Math.max(score1, score2));
        const vulnerabilitypubDate = row?.data_vulnerability_published || 'N/A';
        const vulnerabilityupdateDate = row?.data_vulnerability_updated || 'N/A';
        // const references = row?.data_vulnerability_references || null
        return {
            'Risk Score': riskscore,
            'Severity': row?.data_vulnerability_severity,
            "Detection Time": detectiontime,
            'Mitigation Time': mitigationtime,
            'Days': days,
            "Agent Name": row?.agent_name,
            "Status": row?.data_vulnerability_status,
            "CVE": row?.data_vulnerability_cve,
            "Cvss2": row?.data_vulnerability_cvss_cvss2_base_score !== null && row?.data_vulnerability_cvss_cvss2_base_score !== '' ? parseFloat(row?.data_vulnerability_cvss_cvss2_base_score) : 0,
            "Cvss3": row?.data_vulnerability_cvss_cvss3_base_score !== null && row?.data_vulnerability_cvss_cvss3_base_score !== '' ? parseFloat(row?.data_vulnerability_cvss_cvss3_base_score) : 0,
            'Exploitable': exploitable,
            "Package Name": `${row?.data_vulnerability_package_name} (${row?.data_vulnerability_package_version})`,
            'Package Condition': row?.data_vulnerability_package_condition,
            'Vulnerability Type': row?.data_vulnerability_type,
            'Published Date': vulnerabilitypubDate ? vulformatDate(vulnerabilitypubDate) : '--',
            'Update Date': vulnerabilityupdateDate ? vulformatDate(vulnerabilityupdateDate) : '--',
            "rationale": row?.data_vulnerability_rationale,
            'Description': row?.rule_description,
            "references":row?.data_vulnerability_references 

        }
    });

    const tableToolbar = <>
        <Toolbar id="sortable-toolbar" clearAllFilters={() => setDataFilters({ ...dataFilters, severity: [] })}>
            <ToolbarContent>
                <ToolbarItem key={'sorting'} visibility={{ md: 'hidden' }}>
                    <Select isOpen={isSortDropdownOpen} selected={[activeSortDirection, activeSortIndex]} onOpenChange={isOpen => setIsSortDropdownOpen(isOpen)} onSelect={(event, value) => {
                        if (value === 'asc' || value === 'desc') {
                            onSort(event, activeSortIndex, value);
                        } else {
                            onSort(event, value, activeSortDirection !== 'none' ? activeSortDirection : 'asc');
                        }
                    }} toggle={toggleRef => <MenuToggle ref={toggleRef} onClick={() => setIsSortDropdownOpen(!isSortDropdownOpen)} isExpanded={isSortDropdownOpen} variant="plain">
                        <SortAmountDownIcon />
                    </MenuToggle>}>
                        <SelectGroup label="Sort column">
                            <SelectList>
                                {Object.keys(columns).map((column, columnIndex) => <SelectOption key={column} value={columnIndex} isSelected={activeSortIndex === columnIndex}>
                                    {column}
                                </SelectOption>)}
                            </SelectList>
                        </SelectGroup>
                        <SelectGroup label="Sort direction">
                            <SelectList>
                                <SelectOption isSelected={activeSortDirection === 'asc'} value="asc" key="ascending">
                                    Ascending
                                </SelectOption>
                                <SelectOption isSelected={activeSortDirection === 'desc'} value="desc" key="descending">
                                    Descending
                                </SelectOption>
                            </SelectList>
                        </SelectGroup>
                    </Select>
                </ToolbarItem>
                <ToolbarItem key={'search-input'} variant="search-filter">
                    <SearchInput placeholder="Search..."
                        value={inputValue}
                        onChange={(_event, value) => setInputValue(value)}
                        onSearch={onSearch}
                        onClear={() => { setSearchValue(''); setInputValue('') }} />
                </ToolbarItem>
                <ToolbarItem key={'filtergroup'}>
                    <ToolbarFilter chips={dataFilters.severity} deleteChip={(category, chip) => onDeleteChip(category, chip)} categoryName="Severity">
                        <Select aria-label="severity" isOpen={isFilterDropdownOpen} popperProps={{
                            minWidth: '100px'
                        }} onSelect={onStatusSelect} selected={dataFilters.severity} toggle={toggleRef => <MenuToggle ref={toggleRef} onClick={onFilterToggle}
                            isExpanded={isFilterDropdownOpen}
                            style={{ width: '100%', verticalAlign: 'text-bottom' }}>
                            Filter by Severity
                            {dataFilters.severity.length > 0 && <Badge isRead>{dataFilters.severity.length}</Badge>}
                        </MenuToggle>}>
                            {severityMenuItem}
                        </Select>
                    </ToolbarFilter>
                </ToolbarItem>
                <ToolbarGroup role="group" style={{ alignItems: 'center' }} >
                    <ToolbarItem> <Checkbox label="Active" key='active' isChecked={dataFilters.status.includes('Active')} onChange={(event, checked) => handleCheckChange(event, checked, 'status')} aria-label="Active" id="Active" /></ToolbarItem>
                    <ToolbarItem> <Checkbox label="Solved" key='solved' isChecked={dataFilters.status.includes('Solved')} onChange={(event, checked) => handleCheckChange(event, checked, 'status')} aria-label="Solved" id="Solved" /></ToolbarItem>
                    <ToolbarItem> <Checkbox label="Exploitable" key='exploitable' isChecked={dataFilters.attackVector.includes('1')} onChange={(event, checked) => handleCheckChange(event, checked, 'attackVector')} aria-label="Exploitable" id="1" /></ToolbarItem>
                    <ToolbarItem> <Checkbox label="Unfixed" key='unfixed' isChecked={dataFilters.PackageCondition.includes('Package Unfixed')} onChange={(event, checked) => handleCheckChange(event, checked, 'PackageCondition')} aria-label="Unfixed" id="Package Unfixed" /></ToolbarItem>
                </ToolbarGroup>
                {props.IsModal ? <ToolbarItem>
                    <Button onClick={() => exportToExcel(tableData, Object.keys(tableData[0]), 'LatestVulnerability.xlsx')} icon={<DownloadIcon />} isInline>Download Excel</Button>
                </ToolbarItem> : (null)}
                {props.IsModal ?
                    <ToolbarItem align={{ default: 'alignRight' }} key={'pagination'}>
                        {renderPagination('top')}
                    </ToolbarItem> : (null)}
            </ToolbarContent>
        </Toolbar>
    </>

    const PoCModal = <React.Fragment>
        <Modal title={<>
            <Flex spaceItems={{ default: 'spaceItemsSm' }} display={{ default: 'inlineFlex' }} alignItems={{ default: 'alignItemsCenter' }}>
                <FlexItem> {selectedCve}</FlexItem>
                <FlexItem>{renderSeveritycolumn(selectedCveseverity)}</FlexItem>
            </Flex></>}
            isOpen={pocModalopen}
            onClose={handleModalToggle}
            variant={ModalVariant.medium}
            description={cveDesc}
        >
            <PocTableData Tabledata={selectedCvePoC} columnData={["PoC URL", "Description"]} />
        </Modal>
    </React.Fragment>

    return <React.Fragment>
        {PoCModal}
        <> {tableToolbar}
            {isLoading ? <Loader /> :
                <InnerScrollContainer>
                    <Table variant='compact' isStickyHeader gridBreakPoint="grid-md" >
                        <Thead>
                            <Tr key={"header-row"}>
                                <Th key={'expand'} aria-label='expandall' />
                                {Object.values(columns).map((column, columnIndex) => {
                                    // const stickyColumnsIndex = [0, 1];
                                    // const isSticky = stickyColumnsIndex.includes(columnIndex);
                                    const sortParams = {
                                        sort: {
                                            sortBy: {
                                                index: activeSortIndex,
                                                direction: activeSortDirection
                                            },
                                            onSort,
                                            columnIndex
                                        }
                                    };
                                    // return <Th key={column} {...sortParams} isStickyColumn={isSticky} modifier="wrap">{column}</Th>;
                                    return !excludeOrder.includes(column) ? <Th key={columnIndex} {...sortParams} >{column}</Th> : <Th key={columnIndex} >{column}</Th>;
                                })}
                            </Tr>
                        </Thead>
                        <Tbody>
                            {rows.length > 0 ?
                                rows && rows.map((row, rowIndex) => {
                                    const { formattedTimeStamp: detectiontime } = formatTableTimestamp(row?.timestamp);
                                    const { formattedTimeStamp: mitigationtime } = row?.mitigation_time ? formatTableTimestamp(row?.mitigation_time) : { formattedTimeStamp: null };
                                    const PoCData = row?.poc ? JSON.parse(row?.poc) : null;
                                    return (
                                        <>
                                            <Tr key={rowIndex + row.agent_id}
                                                onRowClick={() => setSelectedRowName(rowIndex)}
                                                isSelectable
                                                isClickable
                                                style={{ borderLeft: getBorderColor(row?.data_vulnerability_severity) }}
                                                isRowSelected={selectedRowName === rowIndex}
                                            >
                                                <Td
                                                    key={'expand-td' + rowIndex}
                                                    expand={{
                                                        rowIndex: rowIndex,
                                                        isExpanded: isRowExpanded(row),
                                                        onToggle: () =>
                                                            setRowExpanded(row, !isRowExpanded(row)),
                                                    }}
                                                />
                                                <Td dataLabel="Risk Score" key={rowIndex + 'rss'}>{renderriskscore(row?.data_vulnerability_severity, row?.data_vulnerability_cvss_cvss2_base_score, row?.data_vulnerability_cvss_cvss3_base_score, PoCData)}
                                                </Td>
                                                <Td dataLabel="Detection Time" key={rowIndex + 'dete_time'}>{detectiontime}</Td>
                                                <Td dataLabel="Mitigation Time" key={rowIndex + 'miti_time'}>{mitigationtime}</Td>
                                                <Td dataLabel="Days" key={rowIndex + 'days'}>
                                                    {row?.mitigation_days > 0 ?
                                                        (mitigationtime === null ?
                                                            <div className='pf-v5-u-danger-color-200'>Active Since {row?.mitigation_days} days</div>
                                                            :
                                                            <div className='pf-v5-u-success-color-200'>Solved in {row?.mitigation_days} days</div>
                                                        )
                                                        :
                                                        (mitigationtime === null ?
                                                            <div className='pf-v5-u-danger-color-200'>Active less than a day</div>
                                                            :
                                                            <div className='pf-v5-u-success-color-200'>Solved in a day</div>)
                                                    }</Td>
                                                <Td dataLabel="Agent Name" key={rowIndex + 'agent_name'} ><Link to={"/individual-agent-dashboard/" + row?.agent_name + '/' + row?.agent_id} >{row?.agent_id} : {row?.agent_name}</Link></Td>
                                                <Td dataLabel="Status" key={rowIndex + 'status'}>{renderStatuscolumn(row?.data_vulnerability_status)}</Td>
                                                {/* data.vulnerability.status */}
                                                <Td dataLabel="CVE" key={rowIndex + 'cve'} >
                                                    <a className='pf-v5-u-color-100' onClick={() => { handleFilter('data.vulnerability.cve', row?.data_vulnerability_cve) }}> {row?.data_vulnerability_cve}</a>
                                                    <ChipGroup numChips={2}>
                                                        <Chip key={"chip" + 'cvss2'} isReadOnly><b>Cvss2 : {row?.data_vulnerability_cvss_cvss2_base_score !== null && row?.data_vulnerability_cvss_cvss2_base_score !== '' ? parseFloat(row?.data_vulnerability_cvss_cvss2_base_score) : 0}</b></Chip>
                                                        <Chip key={"chip" + 'cvss3'} isReadOnly><b>Cvss3 : {row?.data_vulnerability_cvss_cvss3_base_score !== null && row?.data_vulnerability_cvss_cvss3_base_score !== '' ? parseFloat(row?.data_vulnerability_cvss_cvss3_base_score) : 0}</b></Chip>
                                                    </ChipGroup>
                                                </Td>

                                                <Td width={10} dataLabel={'Exploitable'}>
                                                    <List isPlain>
                                                        <ListItem>
                                                            {(row?.data_vulnerability_cvss_cvss3_base_score > 7 &&
                                                                row?.data_vulnerability_cvss_cvss3_vector_attack_vector) ?
                                                                <Badge style={{ "--pf-v5-c-badge--m-unread--BackgroundColor": "#C9190B" }} key={rowIndex + 'vector_attack'}>
                                                                    <a className='pf-v5-u-color-light-100' onClick={() => { handleFilter('data.vulnerability.cvss.cvss3.vector.attack_vector', row?.data_vulnerability_cvss_cvss3_vector_attack_vector) }}>exploitable : {row?.data_vulnerability_cvss_cvss3_vector_attack_vector}</a>
                                                                </Badge>
                                                                : " "}
                                                        </ListItem>
                                                        <ListItem>
                                                            {PoCData != null ?
                                                                <Badge style={{ "--pf-v5-c-badge--m-unread--BackgroundColor": "#C9190B" }}>
                                                                    <a className='pf-v5-u-color-light-100' onClick={(e) => handleModalToggle(e, row?.data_vulnerability_cve, row?.data_vulnerability_severity, PoCData, row?.rule_description)}>PoC: {PoCData.length}</a>
                                                                </Badge> : ''
                                                            }
                                                        </ListItem>
                                                    </List>

                                                </Td>
                                                <Td dataLabel="Package Name" key={rowIndex + 'package_name'}>
                                                    <a className='pf-v5-u-color-100' onClick={() => { handleFilter('data.vulnerability.package.name', row?.data_vulnerability_package_name) }}>   {row?.data_vulnerability_package_name} ({row?.data_vulnerability_package_version})</a> </Td>
                                                <Td dataLabel="Package Condition" key={rowIndex + 'package_condition'}>{row?.data_vulnerability_package_condition}</Td>
                                            </Tr>
                                            <Tr isExpanded={isRowExpanded(row)} key={rowIndex + "exp"}>
                                                <Td colSpan={13} noPadding>
                                                    <ExpandableRowContent>
                                                        <div className='pf-v5-u-p-sm'>
                                                            <Card isFlat isRounded> <CardBody> {getExpandedRowContent(row)}</CardBody></Card>
                                                            {/* <DescriptionList isHorizontal horizontalTermWidthModifier={{ default: '30ch' }}>
                                                                {expandedRowData[row.active_doc_id]?.map((item, index) => (
                                                                    <React.Fragment key={index}>
                                                                        <DescriptionListGroup key={'desc_list'}>
                                                                            <DescriptionListTerm key={item.key}>{item.key}</DescriptionListTerm>
                                                                            <DescriptionListDescription key={item.value}>{item.value}</DescriptionListDescription>
                                                                        </DescriptionListGroup>
                                                                    </React.Fragment>
                                                                ))}
                                                            </DescriptionList> */}
                                                        </div>
                                                    </ExpandableRowContent>
                                                </Td>
                                            </Tr>
                                        </>
                                    )
                                })
                                :
                                <Tr key='empty-row'>
                                    <Td colSpan={10} key='empty-data'>
                                        <Emptystatefornodata />
                                    </Td>
                                </Tr>
                            }
                        </Tbody>
                    </Table>
                </InnerScrollContainer>
            }
            {props.IsModal && renderPagination('bottom')}
        </>

    </React.Fragment >;
};
const mapStateToProps = (state) => ({
    dates: { startDate: state.startdate, endDate: state.enddate, selectedtime: state.Term },
    filterformdata: state.FilterData,
    selectedGroup: state.SelectedGroup,

    // mapStateToProps content remains unchanged if you have it
});

const mapDispatchToProps = (dispatch) => { return {} }

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(LatestVulnerabilitiesCve)