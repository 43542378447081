import React, { useEffect, useState } from 'react'
import {
  Bullseye,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Divider,
  EmptyState,
  EmptyStateActions,
  EmptyStateBody,
  EmptyStateFooter,
  EmptyStateHeader,
  EmptyStateIcon,
  Flex,
  FlexItem,
  PageSection,
  Pagination,
  Popover,
  Toolbar,
  ToolbarContent,
  ToolbarItem,
  SearchInput,
  TextInput,
  Icon,
  Tooltip,
  Select,
  MenuToggle,
  SelectGroup,
  SelectList,
  SelectOption,
  Spinner,

} from '@patternfly/react-core'
import {
  ExportIcon,
  EyeIcon,
  PencilAltIcon,
  PlusCircleIcon,
  RedoIcon,
  SaveIcon,
  SortAmountDownIcon,
  TrashAltIcon,
} from '@patternfly/react-icons'
import {
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from '@patternfly/react-table'
import { Loader } from '../../util/Loadingstate'
import dbApi from '../../services/DbApi'
import { useDispatch } from 'react-redux'
import { alertBoxData } from 'src/Redux/Action'
import { Link, useNavigate } from 'react-router-dom'
import ConfirmBox from '../ConfirmBox/ConfirmBox';
import { Emptystatefornodata } from 'src/util/EmptyStateCard'

const Groups = () => {
  const defaultColumns = {
    name: 'Name',
    count: "Agent",
    configSum: "Configuration checksum",
    actions: "Action",
  };
  const [groupName, setGroupName] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const [columns, setColumns] = useState(defaultColumns);
  const [rows, setRows] = useState([]);
  const [totalItem, setTotalItem] = useState(0)
  const [selectedRowName, setSelectedRowName] = useState('');
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [activeSortIndex, setActiveSortIndex] = useState(0);
  const [isSortDropdownOpen, setIsSortDropdownOpen] = useState(false);
  const [activeSortDirection, setActiveSortDirection] = useState('desc');
  const [inputValue, setInputValue] = useState('')
  const [searchValue, setSearchValue] = useState('');
  const [isloadgroup, setIsloadGroup] = useState(false)
  const [loaddelete, setLoadDelete] = useState(false)
  const [isloasadd, setIsLoadAdd] = useState(false)
  const excludeOrder = ['Agent', 'Configuration checksum', 'Action']
  const [confirmModalData, setConfirmBox] = React.useState({
    show: false,
    msg: '',
    funCall: '',
    funParam: {}
  })
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleGroupName = (_event, value) => {
    setGroupName(value);
  };
  const addGroup = async () => {
    let param = {
      name: groupName
    }
    try {
      setIsLoadAdd(true)
      let res = await dbApi.postApi("Opensearch/Add_Group", param)
      console.log(res)
      if (!res.error) {
        dispatch(alertBoxData([{ title: res.message, variant: 'success' }]));
        setGroupName('')
        getAllGroups()
      }
      else {
        dispatch(alertBoxData([{ title: res.message, variant: 'danger' }]));
      }
      setIsLoadAdd(false)
    } catch (error) {
      console.log(error)
      setIsLoadAdd(false)
    }
  }
  const handleSavegroup = () => {
    addGroup()
  }

  const getAllGroups = async () => {
    let sort = ''
    if (activeSortDirection === 'asc') {
      sort = `+${Object.keys(columns)[activeSortIndex]}`
    }
    else {
      sort = `-${Object.keys(columns)[activeSortIndex]}`
    }
    try {
      let param = {
        offset: (perPage * page) - perPage,
        limit: perPage,
        search: searchValue,
        sort: sort
        // offset: '',
        // limit: '',
        // search: '',
        // sort: ''
      }
      setIsloadGroup(true)
      let res = await dbApi.postApi('Opensearch/get_groups', param);
      console.log('groups', res);
      let groups = []
      if (res.Groups.group.length > 0) {
        groups = res.Groups.group
        setRows(groups)
        setTotalItem(res.Groups.total)
      }else{
        setRows(groups)
        setTotalItem(0)
      }
      setIsloadGroup(false)
    } catch (error) {
      console.log(error);
      setIsloadGroup(false)
    }
  };
  useEffect(() => {
    getAllGroups()
  }, [page,
    perPage,
    activeSortIndex,
    activeSortDirection,
    searchValue,])

  const onSort = (event, index, direction) => {
    setActiveSortIndex(index);
    setActiveSortDirection(direction);
  };
  const handleSetPage = (_evt, newPage) => {
    setPage(newPage);
  };
  const handlePerPageSelect = (_evt, newPerPage) => {
    setPerPage(newPerPage);
  };

  const onSearch = event => {
    if (event.key && event.key !== 'Enter') {
      return;
    }
    setSearchValue(inputValue)
    setPage(1)
  }
  const renderPagination = (variant, isCompact) => (
    <Pagination
      isCompact={isCompact}
      itemCount={totalItem}
      page={page}
      perPage={perPage}
      onSetPage={handleSetPage}
      onPerPageSelect={handlePerPageSelect}
      variant={variant}
      titles={{
        paginationAriaLabel: `${variant} pagination`
      }}
    />
  );
  const tableToolbar = <Toolbar>
    <ToolbarContent >
      <ToolbarItem visibility={{ md: 'hidden' }}>
        <Select isOpen={isSortDropdownOpen} selected={[activeSortDirection, activeSortIndex]} onOpenChange={isOpen => setIsSortDropdownOpen(isOpen)} onSelect={(event, value) => {
          if (value === 'asc' || value === 'desc') {
            onSort(event, activeSortIndex, value);
          } else {
            onSort(event, value, activeSortDirection !== 'none' ? activeSortDirection : 'asc');
          }
        }} toggle={toggleRef => <MenuToggle ref={toggleRef} onClick={() => setIsSortDropdownOpen(!isSortDropdownOpen)} isExpanded={isSortDropdownOpen} variant="plain">
          <SortAmountDownIcon />
        </MenuToggle>}>
          <SelectGroup label="Sort column">
            <SelectList>
              {Object.keys(columns).map((column, columnIndex) => <SelectOption key={column} value={columnIndex} isSelected={activeSortIndex === columnIndex}>
                {column}
              </SelectOption>)}
            </SelectList>
          </SelectGroup>
          <SelectGroup label="Sort direction">
            <SelectList>
              <SelectOption isSelected={activeSortDirection === 'asc'} value="asc" key="ascending">
                Ascending
              </SelectOption>
              <SelectOption isSelected={activeSortDirection === 'desc'} value="desc" key="descending">
                Descending
              </SelectOption>
            </SelectList>
          </SelectGroup>
        </Select>
      </ToolbarItem>
      <ToolbarItem style={{ width: '60%' }} variant="search-filter">
        <SearchInput placeholder="Search..."
          value={inputValue}
          onChange={(_event, value) => setInputValue(value)}
          onSearch={onSearch}
          onClear={() => { setSearchValue(''); setInputValue('') }} />
      </ToolbarItem>
      <ToolbarItem align={{ default: 'alignRight' }}>
        <ToolbarItem> {renderPagination('top', false)}</ToolbarItem>
      </ToolbarItem>
    </ToolbarContent>
  </Toolbar>

  const headerActions = <>
    <Flex spaceItems={{ default: 'spaceItemsXs' }} justifyContent={{ default: 'justifyContentFlexEnd' }}>
      <FlexItem>
        <Popover aria-label="Add-new-group"
          isVisible={isVisible}
          position='bottom'
          shouldOpen={(_event, _fn) => setIsVisible(true)}
          shouldClose={(_event, _fn) => setIsVisible(false)}
          headerContent={<div>Introduce the group name</div>}

          bodyContent={<div>
            <TextInput type="text" id="modal-with-Agent-name" name="modal-with-Agent-name" value={groupName} onChange={handleGroupName} />
          </div>}
          footerContent={
            <div>
              <Button
                isDisabled={groupName == ""}
                icon={<SaveIcon />}
                onClick={() => (setIsVisible(false), handleSavegroup())}>Save new group</Button>
            </div>
          }>
          <Button variant="link" icon={<PlusCircleIcon />} onClick={() => setGroupName('')} isLoading={isloasadd}>Add new group</Button>
        </Popover>
      </FlexItem>
      <FlexItem>
        <Button variant="link" icon={<RedoIcon />} onClick={() => getAllGroups()}>Refresh</Button>
      </FlexItem>
      <FlexItem>
        {/* <CSVLink data={csvData}>Download CSV</CSVLink> */}
        <Button variant="link" icon={<ExportIcon />} onClick={() => { }}>Export formatted</Button>
      </FlexItem>
    </Flex>
  </>

  const deletegroup = async (groupname) => {
    try {
      let param = {
        groupName: groupname
      }
      setLoadDelete(true)
      let res = await dbApi.postApi("Opensearch/Delete_Group", param)
      console.log(res)
      if (!res.error) {
        dispatch(alertBoxData([{ title: res.message, variant: "success" }]));
        getAllGroups()
      }
      else {
        dispatch(alertBoxData([{ title: res.message, variant: "danger" }]));
      }
      setLoadDelete(false)
    } catch (error) {
      console.log(error)
      setLoadDelete(false)
    }
  }
  const onDeleteGroup = (groupname) => {

    setConfirmBox({
      show: true,
      msg: `Permanently delet group ${groupname}?`,
      funCall: () => { deletegroup(groupname) },
      funParam: {}
    })
  }

  return (
    <>
      <PageSection className="pf-v5-u-p-sm" padding={{ default: 'noPadding' }}>
        <ConfirmBox confirmModal={confirmModalData} />
        <Card>
          {loaddelete && <Bullseye><Spinner size='sm' /></Bullseye>}
          <CardHeader actions={{ actions: headerActions }}>
            <CardTitle>Groups({totalItem})
              <br />
              <small>From here you can list and check your groups, its agents and files.</small>
            </CardTitle>
          </CardHeader>
          <Divider />
          <CardBody>
            {tableToolbar}
            {isloadgroup ? <Loader /> :
              <Table aria-label="Sortable Table"
                variant='compact'
                isStickyHeader
                gridBreakPoint="grid-md"
              >
                <Thead>
                  <Tr>
                    {Object.values(columns).map((column, columnIndex) => {
                      const stickyColumnsIndex = [0, 1];
                      const isSticky = stickyColumnsIndex.includes(columnIndex);
                      const sortParams = {
                        sort: {
                          sortBy: {
                            index: activeSortIndex,
                            direction: activeSortDirection
                          },
                          onSort,
                          columnIndex
                        }
                      };
                      return !excludeOrder.includes(column) ? <Th key={columnIndex} {...sortParams} isStickyColumn={isSticky} >{column}</Th> : <Th key={columnIndex} isStickyColumn={isSticky}>{column}</Th>;
                    })}
                  </Tr>
                </Thead>
                {rows.map((row, rowIndex) => {
                  return (
                    <Tbody>
                      <Tr key={rowIndex}
                        onRowClick={() => { setSelectedRowName(rowIndex); }}
                        isSelectable
                        isClickable
                        isRowSelected={selectedRowName === rowIndex}
                      >
                        <>
                          <Td width={20} dataLabel={columns.name} key={rowIndex + 'groupName'}><Link to={"/management/groups-agent/" + row?.name}>{row?.name}</Link></Td>
                          <Td width={20} dataLabel={columns.count} key={rowIndex + 'agcount'}>{row?.count}</Td>
                          <Td width={40} dataLabel={columns.configSum} key={rowIndex + 'config'}>{row?.configSum}</Td>
                          <Td width={20} dataLabel={columns.actions} key={rowIndex + 'action'}>
                            <div>
                              <Flex spaceItems={{ default: 'spaceItemsMd' }}>
                                <FlexItem> <Link to={"/management/groups-agent/" + row?.name}><Tooltip content={`view ${row?.name} details`} ><Icon ><EyeIcon /></Icon></Tooltip> </Link></FlexItem>
                                <FlexItem> <Link to={"/management/groups-config/" + row?.name + '/' + 'agent.conf' + '/' + '1'}><Tooltip content={'Edit group configuration'} ><Icon isInline ><PencilAltIcon /></Icon></Tooltip> </Link></FlexItem>
                                <FlexItem> <Tooltip content={`Delete ${row?.name}`} ><Icon status="danger" onClick={() => onDeleteGroup(row?.name)} isInline><TrashAltIcon /></Icon></Tooltip></FlexItem>
                              </Flex>
                            </div>
                          </Td>
                        </>
                      </Tr>
                    </Tbody>
                  )
                })}
                {rows.length === 0 &&
                  <Tbody>
                    <Tr>
                      <Td colSpan={10}>
                        <Emptystatefornodata />
                      </Td>
                    </Tr>
                  </Tbody>}


              </Table>
            }
          </CardBody>
          <CardFooter>
            {renderPagination('bottom', false)}
          </CardFooter>
        </Card>
      </PageSection>
    </>
  )
}
export default Groups