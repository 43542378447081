import * as echarts from 'echarts';
import React, { useEffect } from 'react';
import { generateUniqueID } from '../../util/UniqueIdGenerator';
import { calculateTimeDifference, formatTime, formatTimestamp } from '../../util/Timeutils';
import { formatValue } from '../../util/ChartHelper';
import { connect } from 'react-redux';
const AgentEvolution = ({ theme, EvolutionTrend, label, StartDateTime, EndDateTime,sidebarState }) => {
  const id = generateUniqueID()
  useEffect(() => {
    if (EvolutionTrend && EvolutionTrend.length > 0) {
      const chartDom = document.getElementById(id + 'aec');
      const myChart = echarts.init(chartDom);
      const { resultInMinutes, resultInHours, resultInDays } = calculateTimeDifference(StartDateTime, EndDateTime);
      const Label = label;
      const textcolor = theme == 'dark-theme' ? '#fff' : '#000'
      const linecolor = theme == 'dark-theme' ? '#595757' : '#d9d9d9'

      const uniqueDatesSet = new Set();
      const xAxisData = []
      for (let i = 0; i < EvolutionTrend.length; i++) {
        for (let j = 0; j < EvolutionTrend[i].date_histogram_agg.buckets.length; j++) {
          const xvalue = EvolutionTrend[i].date_histogram_agg.buckets[j].key_as_string;
          uniqueDatesSet.add(xvalue);
        }
      }
      const uniqueDatesArray = [...uniqueDatesSet];
      for (let k = 0; k < uniqueDatesArray.length; k++) {
        const { ConvertTimestamp, hours, minutes, seconds } = formatTimestamp(uniqueDatesArray[k]);
        const formattedTime = formatTime(resultInMinutes, resultInHours, resultInDays, hours, minutes, seconds, ConvertTimestamp);
        xAxisData.push({ x: formattedTime });
      }

      const colors = {
        disconnected: '#C9190B',
        active: '#3E8635',
        pending: '#F4C145',
        never_connected: '#8A8D90'
        // Define colors for other categories if needed
      };


      const seriesData = EvolutionTrend.map((item) => ({
        name: item.key,
        type: 'line',
        emphasis: {
          focus: 'series',
        },
        itemStyle: {
          color: colors[item.key] // Assign color based on the key
        },
        data: item.date_histogram_agg.buckets.map((dataItem) => dataItem.doc_count),
      }));

      const option = {
        tooltip: {
          trigger: 'axis',
          textStyle: {
            color: "#000"
          }
        },
        legend: {
          type: 'scroll',
          orient: 'vertical',
          right: 0,
          top: 0,
          itemWidth: 9.5,
          itemHeight: 10,
          itemGap: 6.5,
          icon: "rect",
          textStyle: {
            color: textcolor
          }
          // data: ['active','disconnected','pending','never connected']
        },
        grid: {
          left: '2%',
          right: '33%',
          bottom: '10%',
          top: '10%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          // axisLabel: { interval: interval, rotate: -60 },
          boundaryGap: true,
          axisLabel: {
            inside: false,
            color: '#fff'
          },
          axisTick: {
            show: false
          },

          name: `Timestamp per ${Label}`,
          nameLocation: "middle",
          nameTextStyle: {
            align: "center",
            lineHeight: 32,
            fontSize: 14,
            fontstyle: 'RedHatText',
            color: textcolor,
          },
          data: xAxisData.map(item => item.x),

        },
        yAxis: {
          type: 'value',
          splitLine: {
            lineStyle: {
              color: linecolor
            }
          },
          axisLabel: {
            formatter: formatValue,
            color: textcolor
          },
          axisLine: {
            show: true,
            color: '#ccc'
          },
        },
        // color: ['#3E8635', '#C9190B', '#F4C145','#8A8D90'],

        series: seriesData,
      };
      option && myChart.setOption(option);
      const resizeChart = () => {
        if (myChart) { // Check if chart exists before resizing
          myChart.resize();
        }
      };

    window.addEventListener('resize', resizeChart);
    return () => {
        myChart.dispose();
        window.removeEventListener('resize', resizeChart);
    };

    }

  }, [EvolutionTrend, label, StartDateTime, EndDateTime, theme,sidebarState])
  return <div className="container">
    <div id={id + 'aec'} style={{ width: '100%', height: '230px' }} ></div>
  </div>;
}
const mapStateToProps = (state) => ({
  dates: { startDate: state.startdate, endDate: state.enddate },
  filterformdata: state.FilterData,
  theme: state.theme,
  sidebarState:state.sidebarstate
  // mapStateToProps content remains unchanged if you have it
});

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(AgentEvolution)