
import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  Card,
  Flex,
  FlexItem,
  Grid,
  GridItem,
  MenuToggle,
  PageSection,
  PageSectionVariants,
  Select,
  SelectList,
  SelectOption,
  Text,
  TextVariants
} from '@patternfly/react-core'
import dbApi from '../../services/DbApi'
import { connect, useDispatch } from 'react-redux'
import { StartEndDate } from '../../Redux/Action'
import { TimeHandler } from '../../util/Timeutils'
import RecentAlerts from './RecentAlerts'
import RealTimeHeatMap from './RealTimeHeatMap'
import RealTimeEventBar from './RealTimeEventBar'
import EWordCloud from '../AllCharts/EWordCloud'
import GeoMap from '../NetworkInsight/GeoMap'
import Radarchart from '../AllCharts/Radarchart'
import RealTimeTopAlerts from './RealTimeTopAlerts'
import NetInBarChart from '../NetworkInsight/ENIBarChart'
import RealTimeTopAgents from './RealTimeTopAgents'
const RealTimeDashboard = (props) => {
  const dispatch = useDispatch();
  const [filterfield, setFilterField] = useState([])
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [chartlabel, setChartLabel] = useState('')
  const [chartseries, setChartSeries] = useState([])
  const [TotalEvents, setTotalEvents] = useState("0");
  const [rulegroup, setRuleGroup] = useState([]);
  const [securityAlertsLog, setSecurityAlertsLog] = useState([]);
  const [SAscrollId, setSAscrollId] = useState('');
  const [severity, setSeverity] = useState([]);
  const [criticalcount, setCriticalcount] = useState(0)
  const [TDCount, setTDCount] = useState('0');
  const [geoLocation, setGeoLocation] = useState([]);
  const [criticalRules, setCriticalRules] = useState([])
  const [alerts, setAlerts] = useState([]);
  const [mitreSeries, setMitreSeries] = useState([]);
  const [agentSeries, setAgentSeries] = useState([])
  const [agentLabel, setAgentLabel] = useState('')
  const [refreshunitopen, setRefreshunitopen] = useState(false);
  const [refreshunit, setRefreshUnit] = useState('Refresh in 10 seconds');
  const [intervalDuration, setIntervalDuration] = useState(10000); // Default interval duration
  const [intervalId, setIntervalId] = useState(null);
  // //Loading States
  // const [iseventLoading, setIseventLoading] = useState(true)
  const [isloadMitre, setIsLoadMitre] = useState(true)
  const onRefreshClick = () => {
    setRefreshunitopen(!refreshunitopen);
  };
  const RefreshOption = [
    // { value: "5000", children: "Refresh in 5 seconds" },
    { value: "10000", children: "Refresh in 10 seconds" },
    { value: "15000", children: "Refresh in 15 seconds" },
    { value: "20000", children: "Refresh in 20 seconds" },
  ]

  const TimeApi = async () => {
    try {
      let currentDateTime = await dbApi.getApi("elasticsearch/getCurrentUtcDateTime");
      const CurrentTime = new Date(currentDateTime);
      // setCurrentTime(CurrentTime)
      return CurrentTime;
    }
    catch (error) {
      console.log(error);
    }
  }

  const handleGetTime = async () => {
    let term = "Last 15 minutes"
    // let term = "Last 24 hours"
    const {
      startDate,
      endDate,
      selectedTime,
      selectedUnit
    } = await TimeHandler(term, TimeApi, '');
    setStartDate(startDate);
    setEndDate(endDate);
    dispatch(StartEndDate(startDate, endDate, term));
  }
  //Events On Frame 
  const getEventonFrame = async (data) => {
    try {
      let all_eventlog = await dbApi.postApi("elasticsearch/EventLog", data);
      console.log(all_eventlog);
      if (Object.keys(all_eventlog.event).length !== 0) {
        let label = all_eventlog.event.interval
        let events = all_eventlog.event.result.body.hits
        let ChartData = all_eventlog.event.result.body.aggregations.date_histogram
        let chartlabel = ''
        let chartseries = []
        let TotalEvents = '0'
        if (events.total.value > 0) {
          TotalEvents = events.total.value;
        }
        if (ChartData.buckets.length > 0) {
          chartseries = ChartData.buckets;
          chartlabel = label
        }
        setChartLabel(chartlabel)
        setChartSeries(chartseries)
        setTotalEvents(TotalEvents)
      }
      else {
        setChartLabel('')
        setChartSeries([])
        setTotalEvents(0)
      }
    }
    catch (error) {
      console.log(error)
    }
  }
  //Critical Alerts Count
  const getCriticalcount = async (data) => {
    try {
      let Total_Level = await dbApi.postApi('elasticsearch/Security_Dashboard_Level', data);
      console.log(Total_Level);
      if (Object.keys(Total_Level.Security_Dasbhoard_Level).length !== 0) {
        let Dashboard_level = Total_Level.Security_Dasbhoard_Level.body.hits
        let criticalcount = '0';
        if (Dashboard_level.total.value > 0) { criticalcount = Dashboard_level.total.value };

        setCriticalcount(criticalcount)

      }
      else {
        setCriticalcount(0)
      }
    }
    catch (error) {
      console.log(error)
      // setIsLoadLevel(false)
    }
  }
  const getRuleGroups = async (data) => {
    try {

      let rulegroups = await dbApi.postApi('elasticsearch/Security_Dasbhoard_Top_5_Rule_Groups', data)
      console.log(rulegroups);
      if (Object.keys(rulegroups.Security_Dasbhoard_Top_5_Rule_Groups).length !== 0) {
        let rule_groups = rulegroups.Security_Dasbhoard_Top_5_Rule_Groups.body.aggregations.Top_Groups;
        let rulegroup = [];
        if (rule_groups.buckets.length > 0) {
          rulegroup = rule_groups.buckets
        }
        setRuleGroup(rulegroup)

      }
      else {
        setRuleGroup([])
      }
    }
    catch (error) {
      console.log(error)
    }
  }
  const getTopAlerts = async (data) => {
    try {
      let allalerts = await dbApi.postApi('elasticsearch/Security_Dasbhoard_Top_Rule_Id_Description', data)
      console.log(allalerts);
      let all_alerts = allalerts.Security_Dasbhoard_Top_Rule_Id_Description;
      let Alerts = [];

      if (all_alerts.length > 0) {
        const FlatLog = all_alerts.map((logname) => {
          return {
            key: logname.Description[0].key,
            doc_count: logname.Description[0].doc_count,
            // Critical_levels: logname.Description[0].Critical_levels
          };
        });

        Alerts = FlatLog
      }
      setAlerts(Alerts)
    }
    catch (error) {
      console.log(error)
    }
  }
  const getallTactics = async (data) => {
    try {
      let all_tactics = await dbApi.postApi("Opensearch/Mitre_Dashboard_Attack_Top_Tactics", data);
      console.log(all_tactics)
      if (Object.keys(all_tactics.Mitre_Dashboard_Attack_Top_Tactics).length !== 0) {
        let tactics = all_tactics.Mitre_Dashboard_Attack_Top_Tactics.body.aggregations.Top_Tactics;
        let mitreSeries = [];

        if (tactics.buckets.length > 0) {
          mitreSeries = tactics.buckets
        }
        setMitreSeries(mitreSeries)

      }
      else {
        setMitreSeries([])
      }

    }
    catch (error) {
      console.log(error)

    }
  }
  const getThreatDetection = async (data) => {
    try {
      let Total_TD = await dbApi.postApi('elasticsearch/Security_Dasbhoard_Tip_Threat_Detection', data);
      console.log(Total_TD);
      if (Object.keys(Total_TD.Security_Dasbhoard_Tip_Threat_Detection).length !== 0) {
        let Dashboard_TD = Total_TD.Security_Dasbhoard_Tip_Threat_Detection.Security_Dasbhoard_Tip.body.aggregations
        let tdcount = '0'
        if (Dashboard_TD.Threat_Detection.doc_count > 0) {
          tdcount = Dashboard_TD.Threat_Detection.doc_count
        };
        setTDCount(tdcount)
      }
      else {
        setTDCount('')
      }
    } catch (error) {
      console.log(error)
    }

  }
  const getCriticalRules = async (data) => {
    try {
      let allcritical = await dbApi.postApi("elasticsearch/Security_Dasbhoard_Rule_Description", data)
      console.log(allcritical)
      let critical_rules = allcritical.Security_Dasbhoard_Rule_Description
      let criticalRules = []
      if (critical_rules.length > 0) {
        criticalRules = critical_rules
      }
      setCriticalRules(criticalRules)
    } catch (error) {
      console.log(error)
    }
  }

  const getGeoLocation = async (data) => {
    try {
      let AllCountry = await dbApi.postApi("Opensearch/Network_Top_country", data);
      console.log(AllCountry);
      let All_Country = AllCountry.Network_Top_country.body.aggregations.Top_Country;
      let geoLocation = []
      if (All_Country.buckets.length > 0) {
        geoLocation = All_Country.buckets
      }
      setGeoLocation(geoLocation)
    }
    catch (error) {
      console.log(error)
    }
  }


  const getSecurityalerts = async (data) => {
    try {
      let all_security_Alerts = await dbApi.postApi('elasticsearch/Security_Dasbhoard_Security_Alerts', data)
      console.log(all_security_Alerts)
      if (Object.keys(all_security_Alerts.Security_Dasbhoard_Security_Alerts.Security_Dasbhoard_Security_Alerts).length !== 0) {
        let securityAlertsLog = [];
        let SAscrollId = ''
        let security_alerts = all_security_Alerts.Security_Dasbhoard_Security_Alerts.Security_Dasbhoard_Security_Alerts.body.hits
        if (security_alerts.hits.length > 0) {
          securityAlertsLog = security_alerts.hits;
          SAscrollId = all_security_Alerts.Security_Dasbhoard_Security_Alerts.Security_Dasbhoard_Security_Alerts.body._scroll_id
        }
        setSecurityAlertsLog(securityAlertsLog)
        setSAscrollId(SAscrollId)
      }
      else {
        setSecurityAlertsLog([])
        setSAscrollId('')
      }
    } catch (error) {
      console.log(error)
    }
  }
  const childSecurityAlertsRef = useRef(null);
  useEffect(() => {
    if (childSecurityAlertsRef.current) {
      childSecurityAlertsRef.current.setSecurityAlertsTable(securityAlertsLog, SAscrollId);//SAscrollId,searchafterkey,startDate,endDate
    }
  }, [securityAlertsLog, SAscrollId]);
  const getAgentSeverity = async (data) => {
    try {
      let agentseverity = await dbApi.postApi('elasticsearch/Agent_Name_By_Severity', data)
      console.log(agentseverity);
      let Severity = [];
      if (Object.keys(agentseverity.Agent_Name_By_Severity).length !== 0) {
        let agent_Severity = agentseverity.Agent_Name_By_Severity.Agent_Name;
        // if (agent_Severity.length > 0) {
        Severity = agent_Severity
        // }
        setSeverity(Severity)
      }
      else {
        setSeverity([])
      }
    }
    catch (error) {
      console.log(error)
      // setIsLoad(false)
    }
  }
  const getTopAgents = async (data) => {
    try {
      let res = await dbApi.postApi('elasticsearch/Security_Dasbhoard_Alerts_evolution_Top_5_agents', data)
      console.log(res)
      let agentseries = [];
      let label = '';
      let Top5 = res.Security_Dasbhoard_Alerts_evolution_Top_5_agents
      if (Object.keys(Top5).length !== 0) {
        agentseries = Top5.data.body.aggregations.Top_5_agents.buckets;
        label = Top5.interval
        setAgentSeries(agentseries)
        setAgentLabel(label)
      }
      else {
        setAgentSeries(agentseries)
        setAgentLabel(label)
      }

    } catch (error) {
      console.log(error)
      setAgentSeries([])
      setAgentLabel('')
    }

  }
  const RealTimeDashApi = async (startDate, endDate) => {
    // const Fields = allFields
    let staticfield = [{
      "fields": 'rule.groups',
      'condition': 'is_not',
      'value': 'zeek'
    }]
    const data = {
      startDate: startDate,
      endDate: endDate,
      filter: JSON.stringify([...staticfield]),
    };

    const data1 = {
      startDate: startDate,
      endDate: endDate,
      filter: JSON.stringify([{
        "fields": 'rule.level',
        'condition': 'is_Greater_than',
        'value': '8'
      }], ...staticfield),
    }; console.log(data)
    try {

      setStartDate(startDate)
      setEndDate(endDate)
      getEventonFrame(data)
      getTopAgents(data)
      getCriticalcount(data)
      getallTactics(data)
      getTopAlerts(data1)
      getThreatDetection(data)
      getGeoLocation(data)
      getSecurityalerts(data)
      getAgentSeverity(data)

    }
    catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    if (startDate !== '') {

      // console.log("intervalDuration = " + intervalDuration)
      RealTimeDashApi(startDate, endDate)
      const newIntervalId = setInterval(() => {
        handleGetTime();
        RealTimeDashApi(startDate, endDate);
      }, intervalDuration);
      setIntervalId(newIntervalId);

      // Clean up function to clear the interval when the component unmounts or when startDate changes
      return () => clearInterval(newIntervalId);
    }
  }, [startDate, endDate,])
  useEffect(() => {
    handleGetTime()
  }, [])
  const handleRefreshSelect = (interval) => {
    // setIntervalDuration(interval)
    // setRefreshUnit(interval);
    setRefreshunitopen(false);
  };

  const EventOnFrame = useMemo(() => {
    return (
      // "Bar Chart"
      <RealTimeEventBar
        EventSeries={chartseries}
        EventLabel={chartlabel}
        StartDateTime={startDate}
        EndDateTime={endDate}
        TotalEvents={TotalEvents}
        CrticalCount={criticalcount}
        TDCount={TDCount}
      />
    )
  }, [chartseries, chartlabel, startDate, endDate, TotalEvents, criticalcount, TDCount])

  const HeatMap = useMemo(() => {
    return (
      <RealTimeHeatMap severity={severity} />
    )
  }, [severity])

  const TopAgents = useMemo(() => {
    return (
      <RealTimeTopAgents TopData={agentSeries.slice(0, 10)} filterFields={'agent.name'} />
    )
  }, [agentLabel, agentSeries])
  const RTGeoMap = useMemo(() => {
    return (
      // "Bar Chart"
      <GeoMap TopLocation={geoLocation} title={'Top locations'} />
    )
  }, [geoLocation])

  const MitreRadar = useMemo(() => {
    return (
      // 'Radar chart'
      <Radarchart radarseries={mitreSeries} title={'Mitre ATT&CK'} height={270} Center={['50%', '57%']} />
    )
  }, [mitreSeries])

  const TopAlerts = useMemo(() => {
    return (
      // "Cloud Chart"
      <RealTimeTopAlerts cloudseries={alerts} height={300} filterFields={'rule.description'} reDirect={true} />
    )
  }, [alerts])

  const Topeventlog = useMemo(() => {
    return (
      <RecentAlerts ref={childSecurityAlertsRef} />
    )
  }, [childSecurityAlertsRef])
  return (
    // <PageSection padding={{ default: 'noPadding' }}>
    <div className="pf-v5-u-m-sm">
      <Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
        <FlexItem >
          <Card>
            <Select id="unit-select" isOpen={refreshunitopen} selected={refreshunit}
              onSelect={(event, value) => { handleRefreshSelect(value); }}
              onOpenChange={isOpen => setRefreshunitopen(isOpen)}
              toggle={(toggleRef) =>
                <MenuToggle ref={toggleRef}
                  onClick={onRefreshClick}
                  isExpanded={refreshunitopen}>
                  {refreshunit}
                </MenuToggle>}
              shouldFocusToggleOnSelect>
              <SelectList>
                {RefreshOption.map((item) =>
                  <SelectOption onClick={() => setRefreshUnit(item.children)} value={item.value}>{item.children}</SelectOption>
                )}
              </SelectList>
            </Select>
          </Card>
        </FlexItem>
      </Flex>
      <Grid hasGutter className="pf-v5-u-mt-sm">
        <GridItem md={4} sm={12} lg={4}>
          <Card isFullHeight >
            {EventOnFrame}
          </Card>
        </GridItem>
        <GridItem md={4} sm={12} lg={4}>
          <Card isFullHeight >
            {TopAgents}
          </Card>
        </GridItem>
        <GridItem md={4} sm={12} lg={4} >
          <Card isFullHeight >
            {HeatMap}
          </Card>
        </GridItem>
        </Grid>
        <Grid hasGutter className="pf-v5-u-mt-sm">
        <GridItem md={4} sm={12} lg={4}>
          <Card isFullHeight >
            {RTGeoMap}
          </Card>
        </GridItem>
        <GridItem md={4} sm={12} lg={4}>
          <Card isFullHeight >
            {MitreRadar}
          </Card>
        </GridItem>
        <GridItem md={4} sm={12} lg={4}>
          <Card isFullHeight >
            {TopAlerts}
          </Card>
        </GridItem>
      </Grid>
      <div className="pf-v5-u-mt-sm">
        <Card isFullHeight >
          {Topeventlog}
        </Card>
      </div>
    </div >

    // </PageSection>
  )
}

const mapStateToProps = (state) => ({
  dates: { startDate: state.startdate, endDate: state.enddate },
  filterformdata: state.FilterData,
  selectedGroup: state.SelectedGroup,
});

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(RealTimeDashboard)