import React, { useEffect, useState, forwardRef, useMemo } from 'react'
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    CardFooter,
    Divider,
    Flex,
    Grid,
    GridItem,
    Modal,
    Text,
    TextVariants,
    ModalVariant,
    Tabs,
    Tab,
    TabTitleText,
    Bullseye,
    TabContentBody,
    FlexItem,
    Tooltip,
} from '@patternfly/react-core'
import dbApi from '../../services/DbApi'
import { connect, } from 'react-redux';
import SystemAuditingGroups from '../SystemAuditing/SAGroups'
import { Emptystatefornodata } from 'src/util/EmptyStateCard';
import TableViewForChart from '../AllCharts/TableViewForChart';
import { Loader,TextSkeleton } from 'src/util/Loadingstate';
import EDonutChart from '../AllCharts/EDonutChart';
import Elinestackchart from '../AllCharts/Elinestackchart';
import RequirementDistributionNist from './RequirementDistributionNist';
import AlertsVolumeHeat from '../Hipaa/AlertsVolumeHeat';
import { formatValue } from 'src/util/ChartHelper';
const NistDashboard = forwardRef(function NistDashboard(props, ref) {

    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [activeTabKey, setActiveTabKey] = useState(0);
    const [activeagent, setActiveAgent] = useState([]);
    const [reqovertime, setReqovertime] = useState([])
    const [reqlabel, setReqlabel] = useState('')
    const [reqdist, setReqDist] = useState([])
    const [alertsvolume, setAlertsVolume] = useState([])
    const [totalalerts, setTotalalerts] = useState(0)
    const [maxrule, setMaxrule] = useState(0)
    const [toprequirements, setrequirements] = useState([]);
   
    // //Loading states
    const [isloadAgent, setIsLoadAgent] = useState(false)
    const [loadreqovertime, setLoadreqovertime] = useState(false)
    const [loadreqdist, setLoadreqDist] = useState(false)
    const [loadvolume, setLoadVolume] = useState(false)
    const [loadstats, setLoadStats] = useState(false)
    const [loadrequirements, setLoadRequirements] = useState(false)

    // modal states
    const [modalStates, setModalStates] = useState({
        modaltopreq: false,
        modalreqdist: false,
        modalReqovertime: false,
        modalactiveagent: false,
    });
    const handleModalToggle = (modalName) => {
        setModalStates((prevStates) => ({
            ...prevStates,
            [modalName]: !prevStates[modalName],
        }));
    };

    const handleTabClick = (event, tabIndex) => {
        setActiveTabKey(tabIndex)
    }

    const getActiveagent = async (data) => {
        try {
            setIsLoadAgent(true)
            let res = await dbApi.postApi("SecurityCompliances/Nist_Most_Active_Agents", data)
            console.log(res)
            let agents = []
            if (Object.keys(res.Nist_Most_Active_Agents).length !== 0) {
                agents = res.Nist_Most_Active_Agents
                setActiveAgent(agents)
            }
            else {
                setActiveAgent(agents)
            }
            setIsLoadAgent(false)
        } catch (error) {
            console.log(error)
            setActiveAgent([])
            setIsLoadAgent(false)
        }
    }
    // SecurityCompliances/Nist_Top_Requirements_over_time
    const getRequirementOvertime = async (data) => {
        try {
            setLoadreqovertime(true)
            let res = await dbApi.postApi('SecurityCompliances/Nist_Top_Requirements_over_time', data)
            console.log(res)
            let chartseries = [];
            let Label = '';
            if (Object.keys(res.Nist_Top_Requirements_over_time).length !== 0) {
                chartseries = res.Nist_Top_Requirements_over_time.data.body.aggregations.Rule_Nist.buckets;
                Label = res.Nist_Top_Requirements_over_time.interval
                setReqovertime(chartseries)
                setReqlabel(Label)
            }
            else {
                setReqovertime(chartseries)
                setReqlabel(Label)
            }
            setLoadreqovertime(false)
        }
        catch (error) {
            console.log(error)
            setReqovertime([])
            setReqlabel('')
            setLoadreqovertime(false)
        }
    }
    const getReqDistribution = async (data) => {
        try {
            setLoadreqDist(true)
            let res = await dbApi.postApi("SecurityCompliances/Nist_Requirements_Distribution_By_Agent", data)
            console.log(res)
            let chartseries = ''
            if (Object.keys(res.Nist_Requirements_Distribution_By_Agent).length !== 0) {
                chartseries = res.Nist_Requirements_Distribution_By_Agent
                setReqDist(chartseries)
            }
            else {
                setReqDist(chartseries)
            }
            setLoadreqDist(false)
        } catch (error) {
            console.log(error)
            setReqDist([])
            setLoadreqDist(false)
        }
    }
    const getAlertsvolumebyagent = async (data) => {
        try {
            setLoadVolume(true)
            let res = await dbApi.postApi("SecurityCompliances/Nist_Alerts_volume_By_agent ", data)
            console.log(res)
            let volume = []
            if (Object.keys(res.Nist_Alerts_volume_By_agent).length !== 0) {
                volume = res.Nist_Alerts_volume_By_agent
                setAlertsVolume(volume)
            }
            else {
                setAlertsVolume(volume)
            }
            setLoadVolume(false)
        } catch (error) {
            console.log(error)
            setAlertsVolume([])
            setLoadVolume(false)
        }
    }
    const getStats = async (data) => {
        try {
            setLoadStats(true)
            let res = await dbApi.postApi("SecurityCompliances/Nist_Status ", data)
            console.log(res)
            let total = ''
            let maxrule = ''
            if (Object.keys(res.Nist_Status).length !== 0) {
                total = res.Nist_Status.Total_count
                maxrule = res.Nist_Status.status
                setTotalalerts(total)
                setMaxrule(maxrule)
            }
            else {
                setTotalalerts(total)
                setMaxrule(maxrule)
            }
            setLoadStats(false)
        } catch (error) {
            console.log(error)
            setTotalalerts('')
            setMaxrule('')
            setLoadStats(false)
        }
    }
    // SecurityCompliances/Nist_Top_Requirements
    const getTopRequirements = async (data) => {
        try {
            setLoadRequirements(true)
            let res = await dbApi.postApi("SecurityCompliances/Nist_Top_Requirements", data)
            console.log(res)
            let alerts = []
            if (Object.keys(res.Nist_Top_Requirements).length !== 0) {
                alerts = res.Nist_Top_Requirements
                setrequirements(alerts)
            }
            else {
                setrequirements(alerts)
            }
            setLoadRequirements(false)
        } catch (error) {
            console.log(error)
            setrequirements([])
            setLoadRequirements(false)
        }
    }

    const NistDashboardApi = async (startDate, endDate, allFields) => {
        const Fields = allFields
        const data = {
            startDate: startDate,
            endDate: endDate,
            filter: JSON.stringify(Fields),
        };
        console.log(data)
        try {
            setStartDate(startDate);
            setEndDate(endDate);
            getActiveagent(data);
            getRequirementOvertime(data);
            getReqDistribution(data);
            getStats(data)
            getAlertsvolumebyagent(data)
            getTopRequirements(data)
        }
        catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (props.dates.startDate != "") {
            let Dates = props.dates
            NistDashboardApi(Dates.startDate, Dates.endDate, props.filterformdata)
        }
    }, [props?.dates.startDate, props?.dates.endDate, props?.filterformdata]);

    const colorforalerts = ['#0066cc', '#f4c145', '#4cb140', '#5752d1', '#ec7a08', '#40199A', '#C8EB79', '#C9190B', '#008BAD']
    const ActiveAgent = useMemo(() => {
        return (
            isloadAgent ?
                <Loader /> :
                activeagent.length > 0 ?
                    <EDonutChart seriesData={activeagent.slice(0, 5)} height={'250px'} filterFields={'agent.name'} color={['#519DE9', '#004B95', '#8BC1F7', '#06C', '#002F5D']} />
                    : <Emptystatefornodata />
        )
    }, [isloadAgent, activeagent])
    const RequirementOverTime = useMemo(() => {
        return (loadreqovertime ?
            <Loader />
            :
            <Elinestackchart
                lineseries={reqovertime.slice(0,10)}
                label={reqlabel}
                StartDateTime={startDate}
                EndDateTime={endDate}
                color={colorforalerts}
                legendwidth={100}
                height={'290px'}
                filterFields={'rule.nist_800_53'}
            />)
    }, [reqovertime, reqlabel, startDate, endDate,loadreqovertime, colorforalerts])
    const RequirementDistribution = useMemo(() => {
        return (
            loadreqdist ?
                <Loader /> :
                reqdist.length > 0 ?
                    <RequirementDistributionNist seriesData={reqdist.slice(0, 5)} filterFields={'rule.nist_800_53'} height={'290px'} />
                    : <Emptystatefornodata />
        )
    }, [loadreqdist, reqdist])

    const AlertsVolumeByAgent = useMemo(() => {
        return (loadvolume ? <Loader /> :
            alertsvolume.length > 0 ?
                <AlertsVolumeHeat SeriesData={alertsvolume.slice(0, 10)} filterFields={'agent.id'} height={'360px'} />
                : <Emptystatefornodata />
        )
    }, [loadvolume, alertsvolume])

    const TopRequirements = useMemo(() => {
        return (
            loadrequirements ?
                <Loader /> :
                toprequirements.length > 0 ?
                     <SystemAuditingGroups GroupData={toprequirements.slice(0, 5)} height={300} filterFields={'rule.nist_800_53'} />
                    // <EDonutChart seriesData={toprequirements.slice(0, 5)} height={'150px'} filterFields={'rule.hipaa'} color={['#519DE9', '#004B95', '#8BC1F7', '#06C', '#002F5D']} />
                    : <Emptystatefornodata />
        )
    }, [loadrequirements, toprequirements])

    return (
        <>
            <Grid hasGutter className="pf-v5-u-mt-md">
                <GridItem md={5} sm={12} lg={5}>
                    <Card isFullHeight>
                        <CardTitle >Top 10 requirements over time</CardTitle>
                        <Divider />
                        <CardBody > {RequirementOverTime} </CardBody>
                    </Card>
                </GridItem>
                <GridItem md={4} sm={12} lg={4}>
                    <Card isFullHeight>
                        <CardTitle > Requirements distribution by agent</CardTitle>
                        <Divider />
                        <CardBody > {RequirementDistribution} </CardBody>
                        {/* {reqdist.length > 0 && <CardFooter>
                <Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
                  <Button variant="link" onClick={() => { setActiveTabKey(0); handleModalToggle('modalreqdist'); }}>
                    More
                  </Button>
                  <Modal
                    variant={ModalVariant.large}
                    title={`Requirements distribution by agent (${reqdist.length})`}
                    isOpen={modalStates.modalreqdist}
                    onClose={() => handleModalToggle('modalreqdist')}
                  >
                    <Divider className='modal-Divider' />
                    <Card>
                      <Tabs activeKey={activeTabKey} onSelect={handleTabClick} aria-label="Tabs in the default example" role="region">
                        <Tab eventKey={0} title={<TabTitleText>Table View</TabTitleText>}>
                          <TabContentBody className='pf-v5-u-p-xs'>
                            {loadreqdist ? <Loader /> : <ReqDistTable Tabledata={reqdist} columnData={["Agent", "Requirements", "Count"]} filterFields={'agent.name'} />}
                          </TabContentBody>
                        </Tab>
                        <Tab eventKey={1} title={<TabTitleText>Graph View</TabTitleText>} aria-label="graph">
                          {activeTabKey == '1' && <TabContentBody className='pf-v5-u-p-xs'>
                            {loadreqdist ? <Loader /> :
                              reqdist.length > 0 ?
                              <RequirementDistributionNist seriesData={reqdist.slice(0, 10)} filterFields={'rule.hipaa'} height={'350px'} /> :
                                <Emptystatefornodata />}
                          </TabContentBody>}
                        </Tab>
                      </Tabs>
                    </Card>
                  </Modal>
                </Flex>

              </CardFooter>} */}

                    </Card>
                </GridItem>
                <GridItem md={3} sm={12} lg={3}>
                    <Card isFullHeight>
                        <CardTitle > Most active agents</CardTitle>
                        <Divider />
                        <CardBody > {ActiveAgent} </CardBody>
                        {activeagent.length > 0 && <CardFooter>
                        <Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
                            <Button variant="link" onClick={() => { setActiveTabKey(0); handleModalToggle('modalactiveagent') }}> More </Button>
                            <Modal
                                tabIndex={0}
                                variant={ModalVariant.large}
                                title={`Most active agents (${activeagent.length})`}
                                isOpen={modalStates.modalactiveagent}
                                onClose={() => handleModalToggle('modalactiveagent')}
                            >
                                <Divider className='modal-Divider' />
                                <Card>
                                    <Tabs activeKey={activeTabKey} onSelect={handleTabClick} aria-label="Tabs in the default example" role="region">
                                        <Tab eventKey={0} title={<TabTitleText>Table View</TabTitleText>}>
                                            {isloadAgent ?
                                                <Loader /> : <TableViewForChart Tabledata={activeagent} columnData={["Agent", "Count"]} filterFields={'agent.name'} />
                                            }
                                        </Tab>
                                        <Tab eventKey={1} title={<TabTitleText>Graph View</TabTitleText>} aria-label="graph">
                                            {activeTabKey == '1' && <TabContentBody>
                                                {isloadAgent ? <Loader /> :
                                                    activeagent.length > 0 ?
                                                        (<SystemAuditingGroups GroupData={activeagent.slice(0, 15)} height={300} filterFields={'agent.name'} />) : (<Emptystatefornodata />)
                                                }
                                            </TabContentBody>}
                                        </Tab>
                                    </Tabs>
                                </Card>
                            </Modal>
                        </Flex>
                    </CardFooter>}
                    </Card>
                </GridItem>
            </Grid>
            <div className="pf-v5-u-mt-md">
                <Grid hasGutter>
                <GridItem md={5} sm={12} lg={5}>
                        <Card isFullHeight>
                            <CardTitle >Alerts volume by agent</CardTitle>
                            <Divider />
                            <CardBody > {AlertsVolumeByAgent} </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem md={4} sm={12} lg={4}>
                        <Card isFullHeight>
                            <CardTitle > Top 10 requirements</CardTitle>
                            <Divider />
                            <CardBody > {TopRequirements} </CardBody>
                            {toprequirements.length > 0 && <CardFooter>
                        <Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
                            <Button variant="link" onClick={() => { setActiveTabKey(0); handleModalToggle('modaltopreq') }}> More </Button>
                            <Modal
                                tabIndex={0}
                                variant={ModalVariant.large}
                                title={`Top requirements (${toprequirements.length})`}
                                isOpen={modalStates.modaltopreq}
                                onClose={() => handleModalToggle('modaltopreq')}
                            >
                                <Divider className='modal-Divider' />
                                <Card>
                                    <Tabs activeKey={activeTabKey} onSelect={handleTabClick} aria-label="Tabs in the default example" role="region">
                                        <Tab eventKey={0} title={<TabTitleText>Table View</TabTitleText>}>
                                            {loadrequirements ?
                                                <Loader /> : <TableViewForChart Tabledata={toprequirements} columnData={["Requirement", "Count"]} filterFields={'rule.nist_800_53'} />
                                            }
                                        </Tab>
                                        <Tab eventKey={1} title={<TabTitleText>Graph View</TabTitleText>} aria-label="graph">
                                            {activeTabKey == '1' && <TabContentBody>   {loadrequirements ? <Loader /> :
                                                toprequirements.length > 0 ?
                                                    (<SystemAuditingGroups GroupData={toprequirements.slice(0, 15)} height={300} filterFields={'rule.nist_800_53'} />) : (<Emptystatefornodata />)
                                            }
                                            </TabContentBody>}
                                        </Tab>
                                    </Tabs>
                                </Card>
                            </Modal>
                        </Flex>
                    </CardFooter>}
                        </Card>
                    </GridItem>
                    <GridItem md={3} sm={12} lg={3}>
                        <Card isFullHeight>
                            <CardTitle >Stats</CardTitle>
                            <Divider />
                            <CardBody >  
                                 <Bullseye>
                            <Flex direction={{ default: 'column' }} spaceItems={{ default: 'spaceItemsLg' }} alignItems={{ default: 'alignItemsCenter' }}>
                                <FlexItem>
                                    <Tooltip content={`Alerts : ${totalalerts}`}>
                                        <Text className=' pf-v5-u-font-family-heading pf-v5-u-font-size-4xl pf-v5-u-text-align-center'>
                                            {loadstats ? <TextSkeleton /> : formatValue(totalalerts) ?? 0}
                                        </Text>
                                    </Tooltip>
                                    <Text component={TextVariants.h2} className='pf-v5-u-color-100 grid-text-center'>Total alerts</Text>
                                </FlexItem>
                                <FlexItem>
                                    <Text className=' pf-v5-u-font-family-heading pf-v5-u-font-size-4xl pf-v5-u-text-align-center'>
                                        {loadstats ? <TextSkeleton /> : maxrule ?? 0}
                                    </Text>
                                    <Text component={TextVariants.h2} className='pf-v5-u-color-100 grid-text-center'>Max rule level detected</Text>
                                </FlexItem>
                            </Flex>
                        </Bullseye> </CardBody>
                        </Card>
                    </GridItem>
                </Grid>

            </div>
           
        </>
    )
}
)
const mapStateToProps = (state) => ({
    dates: { startDate: state.startdate, endDate: state.enddate },
    filterformdata: state.FilterData,
    selectedGroup: state.SelectedGroup,
    // mapStateToProps content remains unchanged if you have it
});

const mapDispatchToProps = (dispatch) => { return {} }
export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(NistDashboard)

