import React, { useState, useRef, useEffect } from 'react';
import {
    Button,
    DualListSelector,
    Form,
    FormGroup,
    Modal,
    ModalVariant,
    PageSection,
    PageSectionVariants,
    Popover,
    Tab,
    TabAction,
    TabContentBody,
    TabTitleText,
    Tabs,
    TextInput,
    Tooltip,

} from '@patternfly/react-core';
import AIenabled from './AIenabled';
import dbApi from '../../services/DbApi'
import { EditAltIcon } from '@patternfly/react-icons';
import { connect, useDispatch } from 'react-redux';
import ConfirmBox from '../ConfirmBox/ConfirmBox';
import { alertBoxData } from 'src/Redux/Action';
import AlertBox from '../AlertBox/AlertBox';

const tabData = {
    key: 0,
    id: '',
    title: 'AI Enabled',
    Agents: '',

}
const WatchList = (props) => {
    const [storeWatchlist, setStorewatchlist] = useState([tabData])
    const getAllWatchlist = async () => {
        try {
            const getlist = await dbApi.getApi('opensearch/get_watch_list')
            console.log(getlist)
            let storedTab = []
            if (getlist.length > 0) {
                storedTab = getlist.map((item, index) => ({
                    key: index + 1,
                    id: item.id,
                    title: item.title,
                    Agents: item.agent_id,
                }))
                storedTab.unshift(tabData)
                setStorewatchlist(storedTab)
            }
            else {
                setStorewatchlist([tabData])
            }
        } catch (error) {
            console.log(error)
        }
    }
    const [confirmModalData, setConfirmBox] = useState({
        show: false,
        msg: '',
        funCall: '',
        funParam: {},
         title: '',
        position: '',
        iconvariant: '',
        submitbtnvariant: '',
    })
    const [isModalOpen, setModalOpen] = useState(false);
    const [currentId, setCurrentId] = useState('');
    const [actionType, setActionType] = useState('Add');
    const [tabNamevalue, setTabNameValue] = useState('');
    const [activeTabKey, setActiveTabKey] = useState(0);
    const [availableAgents, setAvailableAgents] = useState([]);
    const [formAgentList, setFormAgentList] = useState([]);
    const [chosenAgents, setChosenAgents] = useState([]);
    const [callApi, setcallApi] = useState('')
    const tabComponentRef = useRef();
    const firstMount = useRef(true);
    const dispatch = useDispatch();
    const handleModalToggle = (type) => {
        setActionType(type);
        setModalOpen(!isModalOpen);
    };
    const handleTabName = (_event, value) => {
        setTabNameValue(value);
    };
    const onListChange = (event, newAvailableAgents, newChosenAgents) => {
        //setAvailableAgents(newAvailableAgents.sort());
        setFormAgentList(newAvailableAgents.sort())
        setChosenAgents(newChosenAgents.sort());
    };

    const handleTabClick = (_event, tabIndex) => {
        setActiveTabKey(tabIndex);
    };
    const getAgents = async (data) => {
        try {
            let param = { groupId: '' }
            let allAgents = await dbApi.postApi('user/get_user_agents',param)
            console.log(allAgents);
            let agent = [];
            if (allAgents.data.length > 0) {
                agent = allAgents.data.map((item) => ({
                    id: item.agent_id,
                    text: item.agent_name
                }));
            }
            setAvailableAgents(agent)
        } catch (error) {
            console.log(error);
        }
    };

    // opensearch/add_watch_list
    const AddEditWatchlist = async (EditId) => {
        let id = '';
        let url = 'opensearch/add_watch_list'
        let data = {
            title: tabNamevalue,
            agentId: chosenAgents.map((item => item.id))
        }
        if (actionType == "Edit") {
            url = "opensearch/edit_watch_list"
            data.id = EditId
        }
        try {

            let res = await dbApi.postApi(url, data)
            console.log("Watchlist", res);
            if (res.watchListId !== 0) {
                id = res.watchListId;
            }
            setCurrentId(id);
            if (!res.error) {
                dispatch(alertBoxData([{ title: res.message, variant: 'success' }]));
                setcallApi(Date.now())
                // getAllWatchlist()
            }
            else {
                dispatch(alertBoxData([{ title: res.message, variant: 'danger' }]));
            }
        } catch (error) {
            console.log(error);
        }
        return id;
    };
    const deletewatchlist = async (id) => {
        try {
            // let status = 'danger'
            let res = await dbApi.postApi("opensearch/delete_watch_list", { id: id })
            console.log(res)
            if (!res.error) {
                dispatch(alertBoxData([{ title: res.message, variant: 'success' }]));
            }
            else {
                dispatch(alertBoxData([{ title: res.message, variant: 'danger' }]));
            }
        } catch (error) {
            console.log(error)
        }
    }
    const AddTab = <Modal
        variant={ModalVariant.small}
        // title="Add Custom Watchlist"
        title={actionType === 'Add' ? 'Add Custom Watchlist' : 'Edit Custom Watchlist'}
        isOpen={isModalOpen}
        onClose={handleModalToggle}
        actions={[
            <Button
                key="create"
                variant="primary"
                form="modal-with-form-form"
                onClick={() => { handleModalToggle(actionType); onConfirm(); }}
                isDisabled={chosenAgents.length !== 0 && tabNamevalue !== '' ? false : true}
            >
                Confirm
            </Button>,
            <Button key="cancel" variant="link" onClick={handleModalToggle}>
                Cancel
            </Button>
        ]}
    >
        <Form id="modal-with-form-form">
            <FormGroup
                label="Title"
                isRequired
                fieldId="modal-with-form-form-address"
            >
                <TextInput
                    isRequired
                    type="Tab Name"
                    id="modal-with-form-form-Tab Name"
                    name="modal-with-form-form-Tab Name"
                    value={tabNamevalue}
                    onChange={handleTabName}
                />
            </FormGroup>
            <FormGroup>
                <DualListSelector isSearchable
                    availableOptions={formAgentList}
                    availableOptionsTitle='Available Agents'
                    chosenOptionsTitle='Chosen Agents'
                    isTree
                    chosenOptions={chosenAgents}
                    addAllTooltip="Add all agents"
                    addAllTooltipProps={{ position: 'top' }}
                    addSelectedTooltip="Add selected agents"
                    addSelectedTooltipProps={{ position: 'right' }}
                    removeSelectedTooltip="Remove selected agents"
                    removeSelectedTooltipProps={{ position: 'left' }}
                    removeAllTooltip="Remove all agents"
                    removeAllTooltipProps={{ position: 'bottom' }}
                    onListChange={onListChange}
                    id="dual-list-selector-basic-search" />
            </FormGroup>
        </Form>
    </Modal>
    const onConfirm = async () => {
        const newTabTitle = tabNamevalue
        if (actionType === 'Edit') {
            await AddEditWatchlist(currentId)
            const updatedTabs = storeWatchlist.map(tab => {
                if (tab.key === activeTabKey) {
                    return { ...tab, title: newTabTitle, id: tab.id, Agents: tab.Agents, };
                }
                return tab;

            });
            setStorewatchlist(updatedTabs);
        } else if (actionType === 'Add') {
            const id = await AddEditWatchlist()
            if (newTabTitle) {
                let agents = chosenAgents.map(obj => obj.id).join(",")
                const newTab = {
                    key: storeWatchlist.length,
                    id: id,
                    title: newTabTitle,
                    Agents: agents,

                };
                setStorewatchlist(prevTabs => [...prevTabs, newTab]); // Functional form of setStorewatchlist to ensure correct state update
                setActiveTabKey(storeWatchlist.length);
            }
        }
    };

   
    const onDelete = (event, index) => {
        setConfirmBox({
            show: true,
            msg: `this will can't be reverted.`,
            funCall: deleteWatchList,
            funParam: { id: storeWatchlist[index]['id'], index: index },
            title: 'Permanently delete watchlist?',
            position: 'default',
            iconvariant: 'warning',
            submitbtnvariant: 'danger',
        })
        // dispatch(alertBoxData([]));
    }
    const deleteWatchList = async (params) => {
        deletewatchlist(params.id)
        const tabIndexNum = params.index;
        let nextTabIndex = activeTabKey;
        if (tabIndexNum < activeTabKey) {
            nextTabIndex = activeTabKey - 2 > 0 ? activeTabKey - 2 : 0;
        } else if (activeTabKey === storeWatchlist.length - 1) {
            nextTabIndex = storeWatchlist.length - 3 > 0 ? storeWatchlist.length - 3 : 0;
        }
        else if (tabIndexNum > activeTabKey) {
            nextTabIndex = storeWatchlist.length - 1 > 0 ? 0 : 0;
        }
        setActiveTabKey(nextTabIndex);
        setStorewatchlist(storeWatchlist.filter((tab, index) => index !== tabIndexNum));
        setTabNameValue('')
        setChosenAgents([])
    };
    useEffect(() => {
        if (firstMount.current) {
            firstMount.current = false;
            return;
        } else {
            const first = tabComponentRef.current.tabList.current.childNodes[activeTabKey];
            first && first.firstChild.focus();
        }
    }, [storeWatchlist]);
    useEffect(() => {
        getAllWatchlist();
        // getAgents();
    }, []);
    useEffect(()=>{
        getAgents(props.selectedGroup)
    },[props.selectedGroup])
    const onAdd = () => {
        handleModalToggle('Add');
        setFormAgentList(availableAgents)
        setChosenAgents([]);
        setTabNameValue('')
    }
    const onEdit = (index) => {
        handleModalToggle('Edit');
        setCurrentId(storeWatchlist[index]['id'])
        setTabNameValue(storeWatchlist[index]['title'])
        const agentId = storeWatchlist[index]['Agents'].split(',')
        console.log('agentId',agentId)
        console.log('ghhlk',availableAgents)
        const updatedAvailableAgents = availableAgents.filter(agent => !agentId.includes(agent.id));
        setFormAgentList(updatedAvailableAgents)
        const selectedAgents = agentId.map(agentId => {
            return availableAgents.find(agent => agent.id === agentId);
        });
        console.log('fhsdjka',selectedAgents)
        setChosenAgents(selectedAgents.map((item) => ({ id: item.id, text: item.text, ischecked: item.ischecked })))
    }
    const TabActions = (activeKey) =>
        <TabAction> <Tooltip content={'Edit Watchlist'}>
            <EditAltIcon onClick={() => onEdit(activeKey)} />
            {/* onClick={() => { handleModalToggle('Edit') }} */}
        </Tooltip> </TabAction>

    return (
        <div>
            <PageSection variant={PageSectionVariants.light} padding={{ default: 'noPadding' }}>
                <ConfirmBox confirmModal={confirmModalData} />
                <AlertBox />
                {AddTab}
                <Tabs variant='light300' activeKey={activeTabKey}
                    onSelect={handleTabClick}
                    aria-label="Tabs-individual"
                    role="region" isOverflowHorizontal
                    onClose={(event, value) => onDelete(event, value)}
                    onAdd={onAdd}
                    isBox width={40}
                    ref={tabComponentRef}
                >
                    {storeWatchlist.map((tab, index) => (
                        <Tab
                            key={index}
                            isCloseDisabled={tab.key === 0} // Disable close button for the default tab
                            eventKey={tab.key}
                            aria-label={`Dynamic ${tab}`}
                            title={<TabTitleText>{tab.title}</TabTitleText>}
                            closeButtonAriaLabel={`Close ${tab}`}
                            actions={tab.key !== 0 && TabActions(tab.key)}
                        >
                            <TabContentBody className='pf-v5-u-p-xs'>
                                <AIenabled watchListId={tab.id} Refresh={callApi} />
                            </TabContentBody>
                        </Tab>
                    ))}
                </Tabs>
            </PageSection>
        </div>
    );
};

const mapStateToProps = (state) => ({
    groups: state.Allgroups,
    selectedGroup: state.SelectedGroup,
    // Agents: state.AllAgents,

})
export default connect(mapStateToProps)(WatchList) 