import React, { useEffect, useMemo, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux'
import {
    ActionGroup,
    Button,
    DatePicker,
    Flex,
    FlexItem,
    Form,
    FormGroup,
    Grid,
    GridItem,
    InputGroup,
    InputGroupItem,
    InputGroupText,
    MenuToggle,
    Select,
    SelectList,
    SelectOption,
    TextArea,
    TextInput,
    ValidatedOptions,
    isValidDate,
    yyyyMMddFormat,
} from '@patternfly/react-core';
import { emptyCheck, emailCheck, phoneCheck } from '../../util/Validate.js';
import { alertBoxData } from 'src/Redux/Action.js';
import dbApi from '../../services/DbApi'
import ConfirmBox from '../ConfirmBox/ConfirmBox.js';
import { useNavigate } from 'react-router-dom';
import { Loader,TextSkeleton } from 'src/util/Loadingstate.js';
import { RedoIcon } from '@patternfly/react-icons';
const { forwardRef } = React;
const OnBoardingForm = forwardRef((props, ref) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isloadDetail, setIsLoadDetail] = useState(false)
    const [actionType, setActionType] = useState(props.actiontype)
    const [organizationName, setOrganizationName] = useState('');
    const [validOrgName, setValidOrgName] = useState('');
    const [orgDetail, setOrgDetail] = useState('');
    const [contactPerson, setContactPerson] = useState('')
    const [validContactPerson, setValidContactPerson] = useState('')
    const [validOrgDetail, setValidOrgDetail] = useState('');
    const [orgWeb, setOrgWeb] = useState('');
    const [noOfusers, setNoOfUsers] = useState(0)
    const [validNoOfusers, setValidNoOfUsers] = useState('')
    const [noOfassets, setNoOfAssets] = useState(0)
    const [validNoOfassets, setValidNoOfAssets] = useState(0)
    const [email, setEmail] = useState('');
    const [validEmail, setValidEmail] = useState('');
    const [address, setAddress] = useState('');
    const [validAddress, setValidAddress] = useState('');
    const [contact, setContact] = useState('');
    const [validContact, setValidContact] = useState('');
    const [timeperiod, setTimeperiod] = useState(0)
    const [timeSelection, setTimeSelection] = useState({ id: '1', text: 'Month' });
    const [isTimelistOpen, setTimeListOpen] = useState(false)
    const [orgStatus, setOrgStatus] = useState({ id: '0', text: 'Inactive' });
    const [isorgStatusOpen, setIsOrgStatusOpen] = useState(false)
    const [openorganization, setOpenOrganization] = useState('')
    const [orgUniqueID, setOrgUniqueID] = useState('')
    const [commencementdate, setCommencementDate] = useState('')
    const [comparestartdate, setCompareStartdate] = useState('')
    const [expiryDate, setExpiryDate] = useState('')
    const [retentionDate, setRetentionDate] = useState('')
    const [confirmModalData, setConfirmBox] = useState({
        show: false,
        msg: '',
        funCall: '',
        funParam: {},
        title: '',
        position: '',
        iconvariant: '',
        submitbtnvariant: '',
    })
    //captcha state
    const [captchasum, setCaptchaSum] = useState('')
    const [validcaptcha, setvalidcaptcha] = useState(true)
    const [captchavalue1, setCaptchavalue1] = useState(0)
    const [captchavalue2, setCaptchavalue2] = useState(0)
    const [captchauniqueId, setCaptchaUniqueId] = useState('')
    const [isloadcaptcha, setIsLoadCaptcha] = useState(false)
    const [captchadisable, setCaptchaDisable] = useState(false)

    const TimeperiodList = [
        { id: 1, timeName: "Month" },
        { id: 2, timeName: "Year" }
    ]
    const StatusOptions = [
        { id: 0, status: 'Inactive' },
        { id: 1, status: 'Active' },
    ]


    const getCaptcha = async () => {
        try {
            setIsLoadCaptcha(true)
            setCaptchaDisable(true)
            let res = await dbApi.getApi("login/captcha")
            console.log(res)
            if (!res.error) {
                setCaptchavalue1(res.value_1)
                setCaptchavalue2(res.value_2)
                setCaptchaUniqueId(res.unique_id)
            }
            setIsLoadCaptcha(false)
            setCaptchaDisable(false)
        }
        catch (error) {
            console.log(error)
            setIsLoadCaptcha(false)
            setCaptchaDisable(false)
        }
    }
    useEffect(() => {
        if (!props.openModal) { getCaptcha() }
    }, [props.openModal])

    useEffect(() => {
        if (props.OrgUniqueID !== '' && props.OrgName !== '') {
            setOrgUniqueID(props.OrgUniqueID)
            setOpenOrganization(props.OrgName)
        }
    }, [props.OrgUniqueID, props.OrgName])

    //Autocalculate Date
    const Autocalculate = (value, duration) => {
        duration = parseInt(duration);
        let startDate = new Date();
        let endDate = new Date();
        if (value === 1) { // Month
            endDate.setMonth(endDate.getMonth() + duration);
            endDate = endDate
        } else if (value === 2) { // Year
            endDate.setFullYear(endDate.getFullYear() + duration);
            endDate = endDate
        }
        setCommencementDate(startDate)
        setExpiryDate(endDate)
    }


    //set dates function 
    const toValidator = (date) => {
        return isValidDate(commencementdate) && yyyyMMddFormat(date) >= yyyyMMddFormat(commencementdate)
            ? ''
            : `The End Date must be after the Start Date'`;
    };
    const retentionValidator = date => {
        if (date < expiryDate) {
            return 'The Retention Date must be after the Expiry Date.';
        }
        return '';
    };


    const onStartDateChange = (_event, inputDate, newStartDate) => {
        if (isValidDate(commencementdate) && isValidDate(newStartDate) && inputDate === yyyyMMddFormat(newStartDate)) {
            newStartDate.setHours(commencementdate.getHours());
            newStartDate.setMinutes(commencementdate.getMinutes());
            newStartDate.setSeconds(commencementdate.getSeconds());
        }
        if (isValidDate(newStartDate) && inputDate === yyyyMMddFormat(newStartDate)) {
            setCommencementDate(new Date(newStartDate));
        }
    };

    const onEndDateChange = (_event, inputDate, newEndDate) => {
        if (isValidDate(expiryDate) && isValidDate(newEndDate) && inputDate === yyyyMMddFormat(newEndDate)) {
            newEndDate.setHours(expiryDate.getHours());
            newEndDate.setMinutes(expiryDate.getMinutes());
            newEndDate.setSeconds(expiryDate.getSeconds());
        }
        if (isValidDate(newEndDate) && inputDate === yyyyMMddFormat(newEndDate)) {
            setExpiryDate(newEndDate);
        }
    };

    const onRetentionDateChange = (_event, inputDate, newRetDate) => {
        if (isValidDate(retentionDate) && isValidDate(newRetDate) && inputDate === yyyyMMddFormat(newRetDate)) {
            newRetDate.setHours(retentionDate.getHours());
            newRetDate.setMinutes(retentionDate.getMinutes());
            newRetDate.setSeconds(retentionDate.getSeconds());
        }
        if (isValidDate(newRetDate) && inputDate === yyyyMMddFormat(newRetDate)) {
            setRetentionDate(newRetDate);
        }
    };


    const fieldChange = (value, type) => {
        switch (type) {
            case "OrgName":
                setOrganizationName(value)
                setValidOrgName(emptyCheck(value))
                break;
            case "contactperson":
                setContactPerson(value)
                setValidContactPerson(emptyCheck(value))
                break;
            case "OrgDetail":
                setOrgDetail(value)
                setValidOrgDetail(emptyCheck(value))
                break;
            case "contact":
                setContact(value)
                setValidContact(phoneCheck(value))
                break;
            case "email":
                setEmail(value)
                setValidEmail(emailCheck(value))
                break;

            case "address":
                setAddress(value)
                setValidAddress(emptyCheck(value))
                break;
            case "OrgWeb":
                setOrgWeb(value)
                break;
            case "noOfusers":
                setNoOfUsers(value)
                setValidNoOfUsers(emptyCheck(value))
                break;
            case "noOfassets":
                setNoOfAssets(value)
                setValidNoOfAssets(emptyCheck(value))
                break;
            case "captcha":
                setCaptchaSum(value);
                setvalidcaptcha(emptyCheck(value))
                break;
            case "timeperiod":
                setTimeperiod(value)
                if (value !== '') {
                    Autocalculate(timeSelection.id, value)
                }
                break;
        }
    };

    const getOrganizationDetails = async (data) => {
        try {
            setIsLoadDetail(true)
            let res = await dbApi.postApi("opensearch/get_organization_details", data)
            console.log(res)
            if (res.data.length > 0) {
                let detail = res.data[0]
                let result = StatusOptions.find((obj) => obj.id == detail.status)
                let timeduration = TimeperiodList.find((obj) => obj.id == detail.duration)
                setOrgStatus({ id: result.id, text: result.status })
                setTimeperiod(detail.no_of_duration)
                setTimeSelection({ id: timeduration.id, text: timeduration.timeName })
                setOrganizationName(detail.name)
                setAddress(detail.address)
                setContact(detail.contact)
                setEmail(detail.email)
                setOrgDetail(detail.detail)
                setNoOfUsers(detail.no_of_users)
                setNoOfAssets(detail.no_of_assets)
                setContactPerson(detail.contact_person)
                setOrgWeb(detail.website)
                setCommencementDate(new Date(detail.start_datetime))
                setCompareStartdate(new Date(detail.start_datetime))
                setExpiryDate(new Date(detail.end_datetime))
            }
            else {
                setOrgStatus({ id: '', text: 'InActive' })
                setTimeperiod({ id: '', text: 'month' })
                setOrganizationName('')
                setAddress('')
                setContact('')
                setEmail('')
                setOrgDetail('')
                setNoOfUsers(0)
                setNoOfAssets(0)
                setTimeperiod(0)
                setContactPerson('')
                setOrgWeb('')
                setCommencementDate('')
                setExpiryDate('')
            }
            setIsLoadDetail(false)
        }
        catch (error) {
            console.log(error)
            setIsLoadDetail(false)
            setOrgStatus({ id: '', text: 'InActive' })
            setTimeperiod({ id: '', text: 'month' })
            setOrganizationName('')
            setAddress('')
            setContact('')
            setEmail('')
            setOrgDetail('')
            setNoOfUsers(0)
            setNoOfAssets(0)
            setTimeperiod(0)
            setOrgWeb('')
            setCommencementDate('')
            setExpiryDate('')
        }
    }
    useEffect(() => {
        if (orgUniqueID !== '' && typeof orgUniqueID !== 'undefined') { getOrganizationDetails({ id: orgUniqueID }) }
    }, [orgUniqueID])

    function formValid() {
        let ret = true;
        let status = emptyCheck(organizationName)
        setValidOrgName(status)
        if (status == 'error') {
            ret = false
        }
        status = emptyCheck(orgDetail)
        setValidOrgDetail(status)
        if (status == 'error') {
            ret = false
        }
        status = emptyCheck(contactPerson)
        setValidContactPerson(status)
        if (status == 'error') {
            ret = false
        }
        status = phoneCheck(contact)
        setValidContact(status)
        if (status == 'error') {
            ret = false
        }
        status = emailCheck(email)
        setValidEmail(status)
        if (status == 'error') {
            ret = false
        }
        status = emptyCheck(address)
        setValidAddress(status)
        if (status == 'error') {
            ret = false
        }
        status = emptyCheck(noOfusers)
        setValidNoOfUsers(status)
        if (status == 'error') {
            ret = false
        }
        status = emptyCheck(noOfassets)
        setValidNoOfAssets(status)
        if (status == 'error') {
            ret = false
        }
        status = emptyCheck(captchasum)
        setvalidcaptcha(status)
        if (status == 'error') {
            ret = false
        }
        return ret
    }

    //Api call for submit form
    const finalSubmit = async () => {
        let data = {}
        if (actionType == 'Add') {
            data = {
                name: organizationName,
                detail: orgDetail,
                contact: contact,
                email: email,
                address: address,
                website: orgWeb,
                noOfUsers: noOfusers,
                noOfAssets: noOfassets,
                contactperson: contactPerson,
                noOfDuration: timeperiod,
                duration: timeSelection.id,
                unique_id: captchauniqueId,
                Total: captchasum
            }
        }
        else {
            props.updatemodalstate(false);
            data = {
                id: orgUniqueID,
                name: organizationName,
                detail: orgDetail,
                contactperson: contactPerson,
                contact: contact,
                email: email,
                address: address,
                status: orgStatus.id,
                website: orgWeb,
                noOfUsers: noOfusers,
                noOfAssets: noOfassets,
                noOfDuration: timeperiod,
                duration: timeSelection.id,
                startDateTime: commencementdate,
                endDateTime: expiryDate

            }
        }
        // console.log(data)
        let apiurl = ''
        if (actionType == 'Add') {
            apiurl = 'opensearch/add_customer_on_board'
        }
        else {
            apiurl = 'opensearch/update_customer_on_board'
        }
        console.log("Testing");
        console.log(props);
        // props.callUpdateList();
        // return "";
        const res = await dbApi.postApi(apiurl, data)
        props.UpdateStatus()
        let status = 'danger'
        console.log(res)
        if (!res.error) {
            status = 'success'
            if (actionType == "Add") {
                // childMessageModalref.current.setModalBox(true)
                // dispatch(alertBoxData([{ title: res.message, variant: status }]));
                props.showSuccessModal(res.message)
                // navigate('/Organizations-Management')
                setActionType('Add')
            }
            else {
                dispatch(alertBoxData([{ title: res.message, variant: status }]));
                props.callUpdateList();
                setActionType('Add');
            }
        }
        else {
            dispatch(alertBoxData([{ title: res.message, variant: status }]));
            setActionType('Add')
        }
    }

    const submitForm = () => {
        if (actionType == 'Add') {
            if (formValid()) {
                setConfirmBox({
                    show: true,
                    msg: 'Add new Organization.',
                    funCall: finalSubmit,
                    title: 'Are you sure? ',
                    position: 'default',
                    iconvariant: 'info',
                    submitbtnvariant: 'primary',
                })
            }
        }
        else {
            setConfirmBox({
                show: true,
                msg: `This edit cant't be reversed.`,
                funCall: finalSubmit,
                title: 'Are you sure? ',
                position: 'default',
                iconvariant: 'info',
                submitbtnvariant: 'primary',
            })
            // handleModalToggle();
            setOrgUniqueID('')
        }
    }
    const onCancelForm = () => {
        if (props.openModal) { props.updatemodalstate(false); }
        setTimeperiod({ id: '', text: 'month' })
        setOrganizationName('')
        setValidOrgName('')
        setAddress('')
        setValidAddress('')
        setContact('')
        setValidContact('')
        setEmail('')
        setValidEmail('')
        setOrgDetail('')
        setValidOrgDetail('')
        setNoOfUsers(0)
        setValidNoOfUsers('')
        setNoOfAssets(0)
        setValidNoOfAssets('')
        setTimeperiod(0)
        setOrgWeb('')
        setCommencementDate('')
        setExpiryDate('')
        setContactPerson('')
        setValidContactPerson('')
        setOrgUniqueID('')
        setCaptchaSum('')
    }

    const onSelectBoxToggle = (type) => {
        switch (type) {
            case 'timeSelection':
                setTimeListOpen(!isTimelistOpen);
                break;
            case 'orgStatus':
                setIsOrgStatusOpen(!isorgStatusOpen);
                break;
        }
    };

    const onSelectBox = (_event, value, type) => {
        let text = _event.target.innerText
        switch (type) {
            case 'timeSelection':
                setTimeSelection({ id: value, text: text })
                setTimeListOpen(!isTimelistOpen);
                Autocalculate(value, timeperiod)
                break;
            case 'orgStatus':
                setOrgStatus({ id: value, text: text })
                setIsOrgStatusOpen(!isorgStatusOpen);
                // alert(2,!isUserStatusOpen)
                break;
        }
    };

    const statusToggle = toggleRef => <MenuToggle ref={toggleRef} onClick={() => onSelectBoxToggle('orgStatus')} isExpanded={isorgStatusOpen} style={{ width: '150px' }}>
        {orgStatus?.text}
    </MenuToggle>;
    const orgStatusBox = <Select id="status-sel" isOpen={isorgStatusOpen}
        selected={orgStatus?.id} onSelect={(e, value) => onSelectBox(e, value, 'orgStatus')}
        onOpenChange={isOpen => onSelectBoxToggle('orgStatus')} toggle={statusToggle} shouldFocusToggleOnSelect>
        <SelectList>
            {StatusOptions.map((option, index) => <SelectOption key={index} value={option.id} >{option.status}</SelectOption>)}
        </SelectList>
    </Select>

    const TimeToggle = toggleRef => <MenuToggle placeholder='Select Status' ref={toggleRef} onClick={() => onSelectBoxToggle('timeSelection')} isExpanded={isTimelistOpen} isFullWidth={true} >
        {timeSelection?.text}
    </MenuToggle>;
    const TimeSelectionBox = <Select id="time-sel" isOpen={isTimelistOpen}
        selected={timeSelection?.id} onSelect={(e, value) => onSelectBox(e, value, 'timeSelection')}
        onOpenChange={isOpen => onSelectBoxToggle('timeSelection')} toggle={TimeToggle} shouldFocusToggleOnSelect>
        <SelectList>
            {TimeperiodList.map((option, index) => <SelectOption key={index} value={option.id} >{option.timeName}</SelectOption>)}
        </SelectList>
    </Select>

    // console.log(yyyyMMddFormat(expiryDate))
    const RegistrationForm = <>
        <Form>
            <Grid hasGutter md={props.openModal ? 4 : 4}>
                <GridItem span={12}>
                    <FormGroup label="Organization Name" isRequired fieldId="OrgName">
                        <TextInput isRequired type="text" id="OrgName" name="OrgName" aria-describedby="OrgName-helper" value={organizationName ?? ''} validated={validOrgName} onChange={(event, value) => fieldChange(value, 'OrgName')} />
                    </FormGroup>
                    <FormGroup label="About Your Organization" isRequired fieldId="Orgdetail">
                        <TextArea isRequired type="text" id="Orgdetail" name="Orgdetail" aria-describedby="Orgdetail-helper" value={orgDetail ?? ''} validated={validOrgDetail} onChange={(event, value) => fieldChange(value, 'OrgDetail')} />
                    </FormGroup>
                    <FormGroup label="Contact Person" isRequired fieldId="contactperson">
                        <TextInput isRequired type="text" id="contactperson" name="contactperson" aria-describedby="contactperson-helper" value={contactPerson ?? ''} validated={validContactPerson} onChange={(event, value) => fieldChange(value, 'contactperson')} />
                    </FormGroup>
                    <FormGroup label="Mobile No." isRequired fieldId="contact">
                        <TextInput type="tel" id="contact" isRequired maxLength={10} name="contact" aria-describedby="contact-helper" value={contact ?? ''} validated={validContact} onChange={(event, value) => fieldChange(value, 'contact')} />
                    </FormGroup>
                    <FormGroup label="Email" fieldId="email" isRequired>
                        <TextInput type="email" id="email" isRequired name="email" aria-describedby="email-helper" value={email ?? ''} validated={validEmail} onChange={(event, value) => fieldChange(value, 'email')} />
                    </FormGroup>
                    <FormGroup label="Address" fieldId="address">
                        <TextArea type="text" id="address" name="address" aria-describedby="address-helper" value={address ?? ''} validated={validAddress} onChange={(event, value) => fieldChange(value, 'address')} />
                    </FormGroup>
                    <FormGroup label="Organization Website (If any)" fieldId="OrgWeb">
                        <TextInput type="text" id="OrgWeb" name="OrgWeb" aria-describedby="OrgWeb-helper" value={orgWeb ?? ''} onChange={(event, value) => fieldChange(value, 'OrgWeb')} />
                    </FormGroup>
                </GridItem>
                <FormGroup label="Status" fieldId="modal-with-status" hidden={props.openModal ? false : true}>
                    {orgStatusBox}
                </FormGroup>
                <FormGroup label="Number Of Users" fieldId="noOfusers">
                    <div style={{ width: '80px' }}>
                        <TextInput type="Number" id="noOfusers" name="noOfusers" aria-describedby="noOfusers-helper" value={noOfusers ?? ''} validated={validNoOfusers} onChange={(event, value) => fieldChange(value, 'noOfusers')} />
                    </div>
                </FormGroup>
                <FormGroup label="Number Of Assets" fieldId="noOfassets">
                    <div style={{ width: '80px' }}>
                        <TextInput type="Number" id="noOfassets" name="noOfassets" aria-describedby="noOfassets-helper" value={noOfassets ?? ''} validated={validNoOfassets} onChange={(event, value) => fieldChange(value, 'noOfassets')} />
                    </div>
                </FormGroup>
                <FormGroup label="Duration of Service Usage" fieldId="timeperiod" >
                    <Flex spaceItems={{ default: 'spaceItemsXs' }}>
                        <FlexItem key={1} style={{ width: '55px' }}>
                            <TextInput type="Number" id="timeperiod" name="timeperiod" aria-describedby="timeperiod-helper" value={timeperiod ?? ''} onChange={(event, value) => fieldChange(value, 'timeperiod')} />
                        </FlexItem>
                        <FlexItem key={2}>
                            {TimeSelectionBox}
                        </FlexItem>
                    </Flex>
                </FormGroup>
                {props.openModal &&
                    <FormGroup label='Commencement date' id='commencementdate'>
                        <InputGroup>
                            <InputGroupItem>
                                <DatePicker
                                    value={isValidDate(commencementdate) ? yyyyMMddFormat(commencementdate) : commencementdate}
                                    onChange={onStartDateChange}
                                    aria-label="commencement date"
                                    placeholder="YYYY-MM-DD"
                                    validators={[date => date >= comparestartdate ? '' : 'Cannot select Past dates']}
                                />
                            </InputGroupItem>
                            {/* <InputGroupItem>
                                <TimePicker aria-label="commencement time"
                                    placeholder='hh:mm:ss' is24Hour
                                    onChange={onStartTimeChange} includeSeconds stepMinutes={15} />
                            </InputGroupItem> */}
                        </InputGroup>
                    </FormGroup>
                }
                {props.openModal &&
                    <FormGroup label='Expiry date' id='expirydate' >
                        <InputGroup >
                            <InputGroupItem>
                                <DatePicker
                                    value={isValidDate(expiryDate) ? yyyyMMddFormat(expiryDate) : expiryDate}
                                    onChange={onEndDateChange}
                                    // isDisabled={!isValidDate(commencementdate)}
                                    rangeStart={commencementdate}
                                    // validators={[toValidator]}
                                    validators={[toValidator]}//date => date >= CurrentDate ? '' : 'Cannot select Past dates'
                                    aria-label="Expiry date"
                                    placeholder="YYYY-MM-DD"
                                />
                            </InputGroupItem>
                            {/* <InputGroupItem>
                                <TimePicker
                                    placeholder='hh:mm:ss' is24Hour
                                    validators={[toValidator]}
                                    onChange={onEndTimeChange} isDisabled={!isValidDate(commencementdate)} includeSeconds stepMinutes={15} />
                            </InputGroupItem> */}
                        </InputGroup>
                    </FormGroup>
                }
                {props.openModal &&
                    <FormGroup label='Retention Period' id='retentiondate' >
                        <InputGroup >
                            <InputGroupItem>
                                <DatePicker
                                    value={isValidDate(retentionDate) ? yyyyMMddFormat(retentionDate) : retentionDate}
                                    onChange={onRetentionDateChange}
                                    // rangeStart={expiryDate}
                                    validators={[retentionValidator, date => date >= expiryDate ? '' : 'Cannot select Past dates']}
                                    aria-label="retention date"
                                    placeholder="YYYY-MM-DD"
                                />
                            </InputGroupItem>
                        </InputGroup>
                    </FormGroup>
                }
            </Grid>
            {!props.openModal && <FormGroup label='Solve this' isRequired fieldId='captchavalue' >
                <InputGroup>
                    <InputGroupText id="captchaequation" className="pf-v5-u-color-100" style={{ width: '100px', display: 'flex', justifyContent: 'center' }}> {isloadcaptcha ? <TextSkeleton width='70%' /> : `${captchavalue1 ?? 0} + ${captchavalue2 ?? 0} =`} </InputGroupText>
                    <InputGroupItem isFill>
                        <TextInput id="captchavalue" maxLength={3} validated={validcaptcha ? '' : ValidatedOptions.error} name='captchavalue' onChange={(event, value) => fieldChange(value, 'captcha')} type="text" aria-label="captcha" />
                    </InputGroupItem>
                    <InputGroupItem>
                        <Button variant="secondary" aria-label="captcha recall" isDisabled={captchadisable} onClick={() => getCaptcha()}><RedoIcon /> </Button>
                    </InputGroupItem>
                </InputGroup>
            </FormGroup>}
        </Form>
        <br />
        <ActionGroup>
            <Button variant="primary" onClick={() => submitForm()}  >{actionType == 'Add' ? 'Submit' : 'Update'}</Button>
            <Button variant="link" onClick={() => onCancelForm()}>Cancel</Button>
        </ActionGroup>
    </>
    return <React.Fragment>
        <ConfirmBox confirmModal={confirmModalData} />
        {isloadDetail ? <Loader /> : RegistrationForm}
        {/* {!props.openModal && RegistrationForm} */}
        {/* {props.openModal && <Modal variant={ModalVariant.medium} title={`Edit Organization Details (${openorganization})`}
            isOpen={isModalOpen}
            onClose={() => { handleModalToggle(); setOrgUniqueID('') }}>
            {isloadDetail ? <Loader /> : RegistrationForm}
        </Modal>} */}

    </React.Fragment>
});

const mapStateToProps = (state) => ({})

export default connect(
    mapStateToProps,
    null,
    null,
    { forwardRef: true }
)(OnBoardingForm)