import React, { useState, useEffect, forwardRef, useRef, useMemo } from 'react'
import {
    Bullseye,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    DescriptionList,
    DescriptionListDescription,
    DescriptionListGroup,
    DescriptionListTerm,
    Divider,
    Flex,
    FlexItem,
    Grid,
    GridItem,
    Icon,
    Modal,
    ModalVariant,
    Tab,
    TabContentBody,
    TabTitleText,
    Tabs,
} from '@patternfly/react-core';
import dbApi from '../../services/DbApi';
import TopOrigins from './TopOrigins';
import TopIOCs from './TopIocs';
import TopThreat from './TopThreats';
import { ThreatDetectionTable } from '../SecurityEvents/ThreatDetectionTable';
import { ActiveResponseTable } from '../SecurityEvents/ActiveResponseTable';
import TopAffectedAgents from './TopAffectedAgents';
import DetectionResponseTrend from './DetectionResponsetrend';
import { TrendDownIcon, TrendUpIcon } from '@patternfly/react-icons';
import { connect, useDispatch } from 'react-redux';
import { Emptystatefornodata } from '../../util/EmptyStateCard';
import { Loader } from '../../util/Loadingstate';
import ThreatRatio from './ThreatRatio';
import TableViewforChart from '../AllCharts/TableViewForChart';
import TopIOCTable from './TopIOCTable';

const ThreatIntelDashboard = forwardRef(function ThreatIntelDashboard(props, ref) {
    const dispatch = useDispatch()
    const childThreatDetectionRef = useRef(null);
    const childActiveResponseRef = useRef(null);
    const [activeTabKey, setActiveTabKey] = useState(0);
    const [ArTdactiveTabKey, setArTdActiveTabKey] = useState(0);
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [iocdata, setIOCData] = useState([]);
    const [affectedagents, setAffectedAgents] = useState([]);
    const [origins, setOrigins] = useState([]);
    const [threat, setThreat] = useState([]);
    const [Artrenddata, setArTrendData] = useState([]);
    const [Tdtrenddata, setTdTrendData] = useState([]);
    const [trendlabel, setTrendLabel] = useState();
    const [TDCount, setTDCount] = useState(0)
    const [Tdaddcount, setTdaddcount] = useState(0)
    const [Aradditionalcount, setAraddcount] = useState(0)
    const [TDlog, setTDlog] = useState([])
    const [TDscrollId, setTDscrollId] = useState('')
    const [ARCount, setARCount] = useState(0)
    const [ARlog, setARLog] = useState([])
    const [ARscrollId, setARScrollID] = useState('')
    const [insightratio, setInsightRatio] = useState([])
    //Loading States
    const [isloadInsight, setIsLoadInsight] = useState(true)
    const [isloadIoc, setIsLoadIoc] = useState(true)
    const [isloadAgent, setIsLoadAgent] = useState(true)
    const [isloadTrend, setIsLoadTrend] = useState(true)
    const [isloadorigin, setIsLoadorigin] = useState(true)
    const [isloadThreat, setIsLoadThreat] = useState(true)
    const [isloadTable, setIsLoadTable] = useState(true)
    //Modal States
    const [modalStates, setModalStates] = useState({
        modalThreat: false,
        modalIoc: false,
        modalAgents: false,
        modalTable: false,
    });
    const handleModalToggle = (modalName) => {
        setModalStates((prevStates) => ({
            ...prevStates,
            [modalName]: !prevStates[modalName],
        }));
    };

    const handleTabClick = (event, tabIndex) => {
        setActiveTabKey(tabIndex);
    };
    const handleArTdTabClick = (event, tabIndex) => {
        setArTdActiveTabKey(tabIndex);
    };

    const getIOCsData = async (data) => {
        try {
            setIsLoadIoc(true)
            let res = await dbApi.postApi("Opensearch/Threat_Top_Ioc", data);
            console.log(res);
            let IOCData = []
            if (Object.keys(res.Threat_Top_Ioc).length !== 0) {
                let All_IOC = res.Threat_Top_Ioc
                IOCData = All_IOC
                setIOCData(IOCData)
            }
            // if (Object.keys(res.Threat_Top_Ioc).length !== 0) {
            //     let All_IOC = res.Threat_Top_Ioc.body.aggregations.Top_Ioc
            //     IOCData = All_IOC.buckets
            //     setIOCData(IOCData)
            // }
            else { setIOCData(IOCData) }
            setIsLoadIoc(false)
        }
        catch (error) {
            console.log(error)
            setIOCData([])
            setIsLoadIoc(false)
        }

    }
    const getAffectedAgents = async (data) => {
        try {
            setIsLoadAgent(true)
            let Agents = await dbApi.postApi("Opensearch/Threat_Top_Affected_Agents", data);
            console.log(Agents);
            let Affectedagents = [];
            if (Object.keys(Agents.Threat_Top_Affected_Agents).length !== 0) {
                let All_Agents = Agents.Threat_Top_Affected_Agents.body.aggregations.Top_Agents
                if (All_Agents.buckets.length > 0) {
                    Affectedagents = All_Agents.buckets
                }
                setAffectedAgents(Affectedagents)
            }
            else { setAffectedAgents(Affectedagents) }
            setIsLoadAgent(false)
        }
        catch (error) {
            console.log(error)
            setAffectedAgents([])
            setIsLoadAgent(false)
        }

    }
    const getratio = async (data) => {
        try {
            setIsLoadInsight(true)
            let ratio = await dbApi.postApi('Opensearch/Threat_Ratio', data)
            console.log(ratio)
            let insightratio = []
            if (Object.keys(ratio.data).length !== 0) {
                insightratio = ratio.data
                setInsightRatio(insightratio)
            }
            else {
                setInsightRatio(insightratio)

            }
            setIsLoadInsight(false)

        } catch (error) {
            console.log(error)
            setInsightRatio([])
            setIsLoadInsight(false)
        }
    }
    //Opensearch/Threat_Detection_Response_Trend 
    const getThreatIntelStatusTrend = async (data) => {
        try {
            setIsLoadTrend(true)
            let statustrend = await dbApi.postApi("Opensearch/Threat_Detection_Response_Trend", data);
            console.log(statustrend)
            let ArResult = statustrend.Active_Response_Trend.data.body.aggregations.Detection_Response_Trend
            let Artrenddata = [];
            if (ArResult.buckets.length > 0) {
                Artrenddata = ArResult.buckets
            }

            let TdResult = statustrend.Threat_Detection_Trend.data.body.aggregations.Detection_Response_Trend
            let Tdtrenddata = [];
            if (TdResult.buckets.length > 0) {
                Tdtrenddata = TdResult.buckets
            }

            let label = statustrend.Threat_Detection_Trend.interval

            setArTrendData(Artrenddata)
            setTdTrendData(Tdtrenddata)
            setTrendLabel(label)
            setIsLoadTrend(false)
        }
        catch (error) {
            console.log(error)
            setArTrendData([])
            setTdTrendData([])
            setTrendLabel('')
            setIsLoadTrend(false)
        }
    }

    //Opensearch/Threat_Origin 
    const getOrigins = async (data) => {
        try {
            setIsLoadorigin(true)
            let origin = await dbApi.postApi("Opensearch/Threat_Origin", data);
            console.log(origin);
            if (Object.keys(origin.Threat_Origin).length !== 0) {
                let All_Origins = origin.Threat_Origin.body.aggregations.Top_Country
                let origins = [];
                if (All_Origins.buckets.length > 0) {
                    origins = All_Origins.buckets
                }
                setOrigins(origins)
            }
            else {
                setOrigins([])
            }
            setIsLoadorigin(false)
        }
        catch (error) {
            console.log(error)
            setOrigins([])
            setIsLoadorigin(false)
        }

    }
    const getThreats = async (data) => {
        try {
            setIsLoadThreat(true)
            let res = await dbApi.postApi("Opensearch/Threat_Top_Threat", data)
            console.log(res)
            let Threats = []
            // Threat_Top_Threat.length
            if (Object.keys(res.Threat_Top_Threat).length !== 0) {
                let All_Threats = res.Threat_Top_Threat;
                // Threat_Top_Threat.body.aggregations.Top_Threat.buckets
                if (All_Threats.length > 0) {
                    const defaultRows = All_Threats.flatMap((log) => {
                        if (log.Misp_Category && log.Misp_Category.length > 0) {
                            return log.Misp_Category.map((category) => {
                                return {
                                    threat: log.key,
                                    count: log.doc_count,
                                    category: category.key
                                };
                            });
                        } else {
                            return [{
                                threat: log.key,
                                count: log.doc_count,
                                category: 'C2/Malware'
                            }];
                        }
                    });
                    Threats = defaultRows
                }
                setThreat(Threats)
            }
            else { setThreat(Threats) }
            setIsLoadThreat(false)
        }
        catch (error) {
            console.log(error)
            setIsLoadThreat(false)
        }

    }

    const getThreatDetection = async (data) => {
        try {
            setIsLoadTable(true)
            let Total_TD = await dbApi.postApi('elasticsearch/Security_Dasbhoard_Tip_Threat_Detection', data);
            console.log(Total_TD);
            let misp_count = Total_TD.Security_Dasbhoard_Tip_Threat_Detection.Security_Dasbhoard_Tip.body.aggregations.Threat_Detection_Misp.doc_count
            let MispCount = 0
            let MispaddCount = 0
            let tip_count = Total_TD.Security_Dasbhoard_Tip_Threat_Detection.Security_Dasbhoard_Tip.body.aggregations.Threat_Detection_Tip.doc_count
            let tipCount = 0
            let tipaddcount = 0
            if (misp_count > 0) { MispCount = misp_count };
            if (tip_count > 0) { tipCount = tip_count };
            if (Total_TD.Threat_Detection_Additional_Data.Threat_Detection_Additional_Data.body.aggregations.Threat_Detection_Misp.doc_count > 0) {
                MispaddCount = Total_TD.Threat_Detection_Additional_Data.Threat_Detection_Additional_Data.body.aggregations.Threat_Detection_Misp.doc_count
            }
            if (Total_TD.Threat_Detection_Additional_Data.Threat_Detection_Additional_Data.body.aggregations.Threat_Detection_Tip.doc_count > 0) {
                tipaddcount = Total_TD.Threat_Detection_Additional_Data.Threat_Detection_Additional_Data.body.aggregations.Threat_Detection_Tip.doc_count
            }

            // else if (Total_TD.Threat_Detection_Additional_Data.Threat_Detection_Additional_Data.body.aggregations.Threat_Detection_Misp.doc_count > 0) { Tdaddcount = Total_TD.Threat_Detection_Additional_Data.Threat_Detection_Additional_Data.body.aggregations.Threat_Detection.doc_count }
            let threat_log = Total_TD.Security_Dasbhoard_Tip_Threat_Detection.Security_Dasbhoard_Tip.body.hits
            let TDlog = [];
            let TDscrollId = ''
            if (threat_log.hits.length > 0) {
                TDlog = threat_log.hits;
                TDscrollId = Total_TD.Security_Dasbhoard_Tip_Threat_Detection.Security_Dasbhoard_Tip.body._scroll_id
            }
            console.log("tipaddcount", tipaddcount)
            setTDlog(TDlog)
            setTDscrollId(TDscrollId)
            setTDCount(MispCount + tipCount)
            setTdaddcount(MispaddCount + tipaddcount)
            setIsLoadTable(false)
        } catch (error) {
            console.log(error)
            setTDlog([])
            setTDscrollId('')
            setTDCount(0)
            setTdaddcount(0)
            setIsLoadTable(false)
        }
    }
    useEffect(() => {
        if (childThreatDetectionRef.current) {
            childThreatDetectionRef.current.setThreatDetectionTable(TDlog, TDscrollId);
            // alert("TEst")
        }
    }, [TDlog, TDscrollId, activeTabKey, ArTdactiveTabKey]);


    const getActiveResponse = async (data) => {
        try {
            let Total_AR = await dbApi.postApi('elasticsearch/Security_Dasbhoard_Active_Response', data);
            console.log(Total_AR)
            let ActiveResponse_log = Total_AR.Security_Dasbhoard_Active_Response.Security_Dasbhoard_Active.body.hits
            let TIP_AR = Total_AR.Security_Dasbhoard_Active_Response.Security_Dasbhoard_Active.body.aggregations.Active_response
            let ARCount = '0'
            let Aradditionalcount = '0'
            if (TIP_AR.doc_count > 0) { ARCount = TIP_AR.doc_count };
            if (Total_AR.Active_Response_Additional_Data.Active_Response_Additional_Data.error) { Aradditionalcount = 0 }
            else if (Total_AR.Active_Response_Additional_Data.get_ActiveResponse_Data.body.aggregations.Active_response.doc_count.doc_count > 0) { Aradditionalcount = Total_AR.Active_Response_Additional_Data.get_ActiveResponse_Data.body.aggregations.Active_response.doc_count.doc_count }
            let ARlog = [];
            let ARscrollId = ''
            if (ActiveResponse_log.hits.length > 0) {
                ARlog = ActiveResponse_log.hits;
                ARscrollId = Total_AR.Security_Dasbhoard_Active_Response.Security_Dasbhoard_Active.body._scroll_id
            }
            // console.log("ARCount", ARCount)
            setARLog(ARlog);
            setARScrollID(ARscrollId)
            setARCount(ARCount)
            setAraddcount(Aradditionalcount)
        } catch (error) {
            console.log(error)
            setIsLoadTable(false)
        }
    }
    useEffect(() => {
        if (childActiveResponseRef.current) {
            childActiveResponseRef.current.setActiveResponseTable(ARlog, ARscrollId);
        }
    }, [ARlog, ARscrollId, activeTabKey, ArTdactiveTabKey]);

    const ThreatIntelDashboardApi = async (startDate, endDate, allFields) => {
        const Fields = allFields;
        const data = {
            startDate: startDate,
            endDate: endDate,
            filter: JSON.stringify(Fields),
        };
        try {
            setActiveTabKey(0)
            setStartDate(startDate);
            setEndDate(endDate)
            getratio(data)
            getThreatDetection(data);
            getActiveResponse(data)
            getIOCsData(data);
            getAffectedAgents(data);
            getThreatIntelStatusTrend(data)
            getOrigins(data);
            getThreats(data);
            console.log(data)

        }
        catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {

        if (props.dates.startDate != "") {
            let Dates = props.dates
            ThreatIntelDashboardApi(Dates.startDate, Dates.endDate, props.filterformdata)
        }
    }, [props?.dates.startDate, props?.dates.endDate, props?.filterformdata,]);

    const TDdifference = TDCount - Tdaddcount
    const ARdifference = ARCount - Aradditionalcount


    const TopIoCData = useMemo(() => {
        return (
            isloadIoc ? <Loader /> :
                <TopIOCs ThreatIntelData={iocdata.slice(0, 20)} filterFields={'data.misp.value'} />
        )
    }, [isloadIoc, iocdata])
    const TopAffectedAgent = useMemo(() => {
        return (
            isloadAgent ? <Loader /> :
                <TopAffectedAgents ThreatIntelData={affectedagents.slice(0, 20)} filterFields={'agent.name'} />
        )
    }, [isloadAgent, affectedagents])
    return (<>
        <Grid hasGutter>
            <GridItem md={12} lg={props?.hideCard ? 6 : 4} sm={12}>
                <Card isFullHeight >
                    <CardTitle>TIP<sup>+</sup> Insights</CardTitle>
                    <Divider />
                    {isloadInsight ? <Loader /> :
                        // TDCount != 0 && ARCount != 0 ?
                        insightratio.length != 0 ?
                            (
                                <CardBody className='tip-insight'>
                                    <Grid  >
                                        <GridItem md={6} lg={5} sm={6}>
                                            <Card isPlain >
                                                <CardTitle align="center">Ratio</CardTitle>
                                                <CardBody  >
                                                    <ThreatRatio RatioData={insightratio} height='150px' legend={false} />
                                                </CardBody>
                                            </Card>
                                        </GridItem>
                                        <GridItem md={6} lg={7} sm={6}>
                                            <Flex direction={{ default: 'column' }} rowGap={{ default: 'rowGap2xl' }} >
                                                <FlexItem className='pf-v5-u-mt-md'>
                                                    <DescriptionList isCompact isHorizontal horizontalTermWidthModifier={{ default: '14ch' }}>
                                                        <DescriptionListGroup>
                                                            <DescriptionListTerm className='pf-v5-u-font-size-lg'>Threat Detection</DescriptionListTerm>
                                                            <DescriptionListDescription className='pf-v5-u-danger-color-100 pf-v5-u-font-size-2xl  pf-v5-u-font-weight-bold'>{TDCount ?? 0}
                                                                <sup>
                                                                    {TDdifference > 0 ?
                                                                        <><Icon status='danger' isInline size='sm'><TrendUpIcon /></Icon><span className='pf-v5-u-danger-color-100' style={{ fontSize: '14px' }}>+{TDdifference ?? 0}</span></>
                                                                        :
                                                                        <> <Icon status='danger' isInline size='sm'><TrendDownIcon /></Icon><span className='pf-v5-u-danger-color-100' style={{ fontSize: '14px' }}>{TDdifference ?? 0}</span></>
                                                                    }
                                                                </sup>
                                                            </DescriptionListDescription>
                                                        </DescriptionListGroup>
                                                    </DescriptionList>
                                                </FlexItem>
                                                <FlexItem>
                                                    <DescriptionList isCompact isFluid isHorizontal horizontalTermWidthModifier={{ default: '14ch' }}>
                                                        <DescriptionListGroup>
                                                            <DescriptionListTerm className='pf-v5-u-font-size-lg'>Active Response</DescriptionListTerm>
                                                            <DescriptionListDescription className=' pf-v5-u-success-color-100 pf-v5-u-font-size-2xl  pf-v5-u-font-weight-bold'>{ARCount ?? 0}
                                                                <sup>
                                                                    {ARdifference > 0 ?
                                                                        <><Icon status='success' isInline size='sm'><TrendUpIcon /></Icon><span className='pf-v5-u-success-color-100' style={{ fontSize: '14px' }}>+{ARdifference ?? 0}</span></>
                                                                        :
                                                                        <> <Icon status='success' isInline size='sm'><TrendDownIcon /></Icon><span className='pf-v5-u-success-color-100' style={{ fontSize: '14px' }}>{ARdifference ?? 0}</span></>
                                                                    }
                                                                </sup>
                                                            </DescriptionListDescription>
                                                        </DescriptionListGroup>
                                                    </DescriptionList>
                                                </FlexItem>
                                            </Flex>
                                        </GridItem>
                                    </Grid>
                                </CardBody>
                            )
                            : (
                                <Emptystatefornodata />
                            )
                    }
                </Card>
            </GridItem>
            <GridItem md={6} lg={props?.hideCard ? 6 : 4} sm={12}>
                <Card isFullHeight >
                    <CardTitle>Top IOC's</CardTitle>
                    <Divider />
                    {TopIoCData}
                    {iocdata.length > 0 && <CardFooter>
                        <Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
                            <Button variant="link" onClick={() => { setActiveTabKey(0); handleModalToggle('modalIoc') }}> More </Button>
                            <Modal
                                tabIndex={0}
                                variant={ModalVariant.large}
                                title={`Top IOC's (${iocdata.length})`}
                                isOpen={modalStates.modalIoc}
                                onClose={() => handleModalToggle('modalIoc')}
                            >
                                <Divider className='modal-Divider' />
                                <Card>
                                    <Tabs activeKey={activeTabKey} onSelect={handleTabClick} aria-label="Tabs in the default example" role="region">

                                        <Tab eventKey={0} title={<TabTitleText>Table View</TabTitleText>} aria-label="graph">
                                            {isloadIoc ? <Loader /> :
                                                iocdata.length > 0 ?
                                                    <TopIOCTable Tabledata={iocdata} columnData={["IOC's", "Count"]} filterFields={'data.misp.value'} />
                                                    : (<Emptystatefornodata />)
                                            }
                                        </Tab>
                                        <Tab eventKey={1} title={<TabTitleText>Graph View</TabTitleText>}>
                                            {activeTabKey == '1' && <TabContentBody className='pf-v5-u-p-xs'>  {isloadIoc ?
                                                <Loader /> : <TopIOCs ThreatIntelData={iocdata.slice(0, 25)} filterFields={'data.misp.value'} />
                                            }</TabContentBody>}
                                        </Tab>
                                    </Tabs>
                                </Card>
                            </Modal>
                        </Flex>
                    </CardFooter>}
                </Card>
            </GridItem>
            {props?.hideCard ? '' :
                <GridItem md={6} lg={4} sm={12}>
                    <Card isFullHeight >
                        <CardTitle>Top Affected Agents </CardTitle>
                        <Divider />
                        {TopAffectedAgent}
                        {affectedagents.length > 0 && <CardFooter>
                            <Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
                                <Button variant="link" onClick={() => { setActiveTabKey(0); handleModalToggle('modalAgents') }}> More </Button>
                                <Modal
                                    tabIndex={0}
                                    variant={ModalVariant.large}
                                    title={`All Agents (${affectedagents.length})`}
                                    isOpen={modalStates.modalAgents}
                                    onClose={() => handleModalToggle('modalAgents')}
                                >
                                    <Divider className='modal-Divider' />
                                    <Card>
                                        <Tabs activeKey={activeTabKey} onSelect={handleTabClick} aria-label="Tabs in the default example" role="region">
                                            <Tab eventKey={0} title={<TabTitleText>Table View</TabTitleText>} aria-label="graph">
                                                {isloadAgent ? <Loader /> :
                                                    affectedagents.length > 0 ?
                                                        <TableViewforChart Tabledata={affectedagents} columnData={["Agent Name", "Count"]} filterFields={'agent.name'} />
                                                        : (<Emptystatefornodata />)
                                                }
                                            </Tab>
                                            <Tab eventKey={1} title={<TabTitleText>Graph View</TabTitleText>}>
                                                {activeTabKey == '1' && <TabContentBody className='pf-v5-u-p-xs'>  {isloadAgent ?
                                                    <Loader /> : <TopAffectedAgents ThreatIntelData={affectedagents.slice(0, 50)} filterFields={'agent.name'} />
                                                }</TabContentBody>}
                                            </Tab>
                                        </Tabs>
                                    </Card>
                                </Modal>
                            </Flex>
                        </CardFooter>}
                    </Card>
                </GridItem>
            }
        </Grid>
        <div className="pf-v5-u-mt-md">
            <Grid hasGutter>
                <GridItem md={12} lg={8} sm={12}>
                    <Card isFullHeight >
                        <CardTitle>Detection & Response Trend</CardTitle>
                        <Divider />
                        {isloadTrend ? <Loader /> :
                            <CardBody>
                                {Artrenddata.length !== 0 || Tdtrenddata.length !== 0 ?
                                    <>
                                        <DetectionResponseTrend
                                            ResponseData={Artrenddata}
                                            DetectionData={Tdtrenddata}
                                            TdArLabel={trendlabel}
                                            StartDateTime={startDate}
                                            EndDateTime={endDate}
                                        />
                                    </>
                                    :
                                    <Emptystatefornodata />
                                }
                            </CardBody>
                        }
                    </Card>
                </GridItem>
                <GridItem md={12} lg={4} sm={12}>
                    <Card isFullHeight >
                        <CardTitle>Threat Origins</CardTitle>
                        <Divider />
                        {isloadorigin ? <Loader /> :
                            <CardBody>
                                <TopOrigins OriginData={origins} />
                            </CardBody>
                        }
                    </Card>
                </GridItem>
            </Grid>
        </div>
        <div className="pf-v5-u-mt-md">
            <Grid hasGutter>
                <GridItem md={12} lg={4} sm={12}>
                    <Card isFullHeight >
                        <CardTitle>Top Threats</CardTitle>
                        <Divider />
                        {isloadThreat ? <Loader /> :
                            <CardBody>
                                <TopThreat ThreatData={threat.slice(0, 10)} Pagination={false} />
                            </CardBody>
                        }
                        {threat.length > 0 && <CardFooter>
                            <Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
                                <Button variant="link" onClick={() => { handleModalToggle('modalThreat'); }}>
                                    More
                                </Button>
                                <Modal
                                    variant={ModalVariant.large}
                                    title='Threats'
                                    isOpen={modalStates.modalThreat}
                                    onClose={() => handleModalToggle('modalThreat')}
                                >
                                    <Divider className='modal-Divider' />
                                    <Card> <TopThreat ThreatData={threat} Pagination={true} /></Card>
                                </Modal>
                            </Flex>
                        </CardFooter>}
                    </Card>
                </GridItem>
                <GridItem md={12} lg={8} sm={12}>
                    <Card isFullHeight >
                        <CardTitle>Threat Alerts & Responses</CardTitle>
                        <Divider />
                        {isloadTable ? <Loader /> :
                            <CardBody>
                                {/* <h1>{ARlog}</h1> */}
                                <Tabs activeKey={activeTabKey} onSelect={handleTabClick} aria-label="Tabs in the default example" role="region">
                                    <Tab eventKey={0} title={<TabTitleText>Threat Detection ({TDlog.length})</TabTitleText>} >
                                        <ThreatDetectionTable ref={childThreatDetectionRef} />
                                    </Tab>
                                    <Tab eventKey={1} title={<TabTitleText>Active Response ({ARlog.length})</TabTitleText>}>
                                        <ActiveResponseTable ref={childActiveResponseRef} />
                                    </Tab>
                                </Tabs>
                            </CardBody>
                        }
                        <CardFooter>
                            <Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
                                <Button variant="link" onClick={() => { setArTdActiveTabKey(2); handleModalToggle('modalTable'); }}> More </Button>
                                <Modal
                                    variant={ModalVariant.large}
                                    title={`Threat Alerts & Responses`}
                                    isOpen={modalStates.modalTable}
                                    onClose={() => { handleModalToggle('modalTable'); setArTdActiveTabKey(0) }}
                                >
                                    <Divider className='modal-Divider' />
                                    <Card>
                                        <Tabs activeKey={ArTdactiveTabKey} onSelect={handleArTdTabClick} aria-label="Tabs in the default example" role="region">
                                            <Tab eventKey={2} title={<TabTitleText>Threat Detection ({TDlog.length})</TabTitleText>} >
                                                <ThreatDetectionTable ref={childThreatDetectionRef} IsModal={true} />
                                            </Tab>
                                            <Tab eventKey={3} title={<TabTitleText>Active Response ({ARlog.length})</TabTitleText>}>
                                                <ActiveResponseTable ref={childActiveResponseRef} IsModal={true} />
                                            </Tab>
                                        </Tabs>
                                    </Card>
                                </Modal>
                            </Flex>
                        </CardFooter>
                    </Card>
                </GridItem>
            </Grid>
        </div>
    </>
    )
}
)

const mapStateToProps = (state) => ({
    dates: { startDate: state.startdate, endDate: state.enddate },
    filterformdata: state.FilterData,
    selectedGroup: state.SelectedGroup,
});

const mapDispatchToProps = (dispatch) => { return {} }

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(ThreatIntelDashboard)