//2nd which is perfectly run and usable
import React, { useEffect, useState } from "react";
import {
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Table,
} from "@patternfly/react-table";
import { Emptystatefornodata} from "src/util/EmptyStateCard";
import { Badge, Pagination, Toolbar, ToolbarContent, ToolbarItem } from "@patternfly/react-core";
import { UpdateFilterData } from "src/util/FilterHelper";
import { FilterData } from "src/Redux/Action";
import { connect, useDispatch } from "react-redux";
const AffectedAgent = (props) => {
  const dispatch = useDispatch();
  const columns = [
    "Agent Name",
    "Passed",
    "Failed",
    "Not Applicable",
  ];

  const [rows, setRows] = useState([]);
  const [selectedRowName, setSelectedRowName] = React.useState('');
  const [defaultRows, setDefaultRows] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const handleSetPage = (_evt, newPage, perPage, startIdx, endIdx) => {
    // setPaginatedRows(rows.slice(startIdx, endIdx));
    setPage(newPage);
  };
  const handlePerPageSelect = (_evt, newPerPage, newPage, startIdx, endIdx) => {
    // setPaginatedRows(rows.slice(startIdx, endIdx));
    setPage(newPage);
    setPerPage(newPerPage);
  };

  useEffect(() => {
    const newdefaultRows = props.AgentData.map((log) => {
      return {

        agentname: log.agent_name,
        passed: log.passed_count,
        failed: log.failed_count,
        notapplicable: log.not_applicable,
      };
    });

    setDefaultRows(newdefaultRows)
    const startIndex = (page - 1) * perPage;
    const endIndex = startIndex + perPage;
    const slicedRows = newdefaultRows.slice(startIndex, endIndex);
    setRows(slicedRows);
  }, [props.AgentData]);

  useEffect(() => {
    const startIndex = (page - 1) * perPage;
    const endIndex = startIndex + perPage;
    const slicedRows = defaultRows.slice(startIndex, endIndex);
    // console.log("after slicing", "", startIndex, endIndex, slicedRows)
    setRows(slicedRows);
  }, [defaultRows, page, perPage]);

  const handleFilter = (fields, value) => {
    const updatedFields = UpdateFilterData(props.filterformdata, fields, 'is', value);
    dispatch(FilterData(updatedFields));
  }
  const renderPagination = (variant, isCompact) =>
    <Pagination
      itemCount={defaultRows.length}
      page={page}
      perPage={perPage}
      onSetPage={handleSetPage}
      onPerPageSelect={handlePerPageSelect}
      variant={variant}
    />;
  return (

    <React.Fragment>
      {props.AgentData.length === 0 ? (
        <Emptystatefornodata />
      ) : (
        <>
          {props.AgentData.length > 5 && <Toolbar>
            <ToolbarContent>
              <ToolbarItem variant="pagination">{renderPagination('top', true)}</ToolbarItem>
            </ToolbarContent>
          </Toolbar>}
          <Table variant="compact" aria-label="affectedagentlog" id="affectedagentlog-table" isStickyHeader>
            <Thead>
              <Tr>
                {columns.map((column) => (
                  <Th key={column}>{column}</Th>
                ))}
              </Tr>
            </Thead>
            {rows.map((row, rowIndex) => (
              <Tbody key={rowIndex} >
                <Tr key={rowIndex}
                  onRowClick={() => setSelectedRowName(rowIndex)}
                  isSelectable
                  isClickable
                  isRowSelected={selectedRowName === rowIndex}  >
                  <Td width={60} ><a onClick={() => { handleFilter('agent.name', row?.agentname) }}>{row?.agentname}</a></Td>
                  <Td width={10} >{row?.passed}</Td>
                  <Td width={10} >{row?.failed}</Td>
                  <Td width={20} >{row?.notapplicable}</Td>
                </Tr>
              </Tbody>
            ))}
          </Table>
          {props.AgentData.length > 5 && renderPagination('bottom', true)}
        </>
      )}
    </React.Fragment>
  );
};
const mapStateToProps = (state) => ({
  filterformdata: state.FilterData,
});

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(AffectedAgent)
