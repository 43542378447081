import React, { Suspense } from 'react'
import './Assets/css/style.css'
import '@patternfly/react-core/dist/styles/base.css'
import '@patternfly/patternfly/patternfly.css'
import '@patternfly/patternfly/patternfly-addons.css'
import { HashRouter, Route, Routes, } from 'react-router-dom'
import MainLogin from './Components/Login/MainLogin'
import AppLayout from './Layout/AppLayout'
import ResetPassword from './Components/Login/NewPasswordjSet'
import OnboardLandingPage from './Components/OnBoarding/OnboardLandingpage'
import AppHomeLayout from './Layout/AppHomeLayout'

function App () {
  return (
    <div>
    <HashRouter>
    {/* <BrowserRouter> */}
      <Suspense>
        <Routes>
          <Route path='*' name='Home' element={<AppLayout/>} />
          <Route exact path="/" name="login" element={<MainLogin/>} />
          <Route path="/reset-password/:etoken"  element={<ResetPassword />} />
          <Route exact path='/onboardingform/:uniqueId' name="OnboardingForm" element={<OnboardLandingPage />} />
          <Route exact path='/landing/*' name="Landing" element={<AppHomeLayout />} />

        </Routes>
      </Suspense>
      {/* </BrowserRouter> */}
    </HashRouter>
    </div>
  )
}

export default App
