import * as echarts from 'echarts';
import React, { useEffect } from 'react';
import { generateUniqueID } from 'src/util/UniqueIdGenerator';
import { calculateTimeDifference, formatTime, formatTimestamp } from 'src/util/Timeutils';
import { formatValue } from 'src/util/ChartHelper';
import { UpdateFilterData } from 'src/util/FilterHelper';
import { FilterData } from 'src/Redux/Action';
import { connect, useDispatch } from 'react-redux';
const ScaPFTrend = (props) => {
  const id = generateUniqueID()
  const dispatch = useDispatch();
  const handleFilter = (fields, value) => {
    const updatedFields = UpdateFilterData(props.filterformdata, fields, 'is', value);
    dispatch(FilterData(updatedFields));
  }
  useEffect(() => {
    if (props.ScaTrendData && props.ScaTrendData.length > 0) {
      const chartDom = document.getElementById(id + 'vst');
      const myChart = echarts.init(chartDom);
      const textcolor = props.theme == 'dark-theme' ? '#fff' : '#000'
      const linecolor = props.theme == 'dark-theme' ? '#595757' : '#d9d9d9'
      const { resultInMinutes, resultInHours, resultInDays } = calculateTimeDifference(props.StartDateTime, props.EndDateTime);
      const Label = props.ScaTrendlabel;
      const uniqueDatesSet = new Set();
      const xAxisData = []
      // console.log("alldata", props.ScaTrendData)
      for (let i = 0; i < props.ScaTrendData.length; i++) {
        const xvalue = props.ScaTrendData[i].time_bucket;
        uniqueDatesSet.add(xvalue);
      }
      const uniqueDatesArray = [...uniqueDatesSet];
      for (let k = 0; k < uniqueDatesArray.length; k++) {
        const { ConvertTimestamp, hours, minutes, seconds } = formatTimestamp(uniqueDatesArray[k]);
        const formattedTime = formatTime(resultInMinutes, resultInHours, resultInDays, hours, minutes, seconds, ConvertTimestamp);
        xAxisData.push({ x: formattedTime });
      }

      let interval = ''
      if (Label === '30 seconds') {
        interval = 4
      }
      else if (Label === 'hour') {
        interval = 3
      }
      else {
        interval = 5
      }

      const option = {
        tooltip: {
          trigger: 'axis',
          textStyle: {
            color: "#000"
          }
        },
        title: {
          text: `Timestamp per ${Label}`,
          top: 'bottom',
          left: 'center',
          textStyle: {
            fontWeight: 450,
            fontSize: 14,
            color: textcolor,
            fontfamily: 'RedHatText, helvetica, arial, sans-serif',
          },
        },
        legend: {
          type: 'scroll',
          orient: 'vertical',
          right: 0,
          top: 0,
          itemWidth: 9.5,
          itemHeight: 10,
          itemGap: 6.5,
          icon: "rect",
          textStyle: {
            // width: props.legendwidth?props.legendwidth:200,
            // overflow: 'truncate',
            // fontSize: 12,
            color: textcolor
          },
          data: ['Passed', 'Failed', 'Not Applicable']
        },
        grid: {
          left: '1%',
          right: '18%',
          bottom: '10%',
          top: '8%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          axisLabel: { interval: interval, rotate: -60, color: textcolor },
          boundaryGap: true,
          data: xAxisData.map(item => item.x),
          axisTick: {
            alignWithLabel: true
          },

        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: formatValue,
            color: textcolor
          },
          splitLine: {
            lineStyle: {
              color: linecolor
            }
          },
          axisLine: {
            show: true
          },
        },
        series: [
          {
            name: 'Passed',
            type: 'line',
            data: props.ScaTrendData.map(item => item.passed_count),
            itemStyle: {
              color: '#3e8635', // Red for active, Green for solved
            },
            emphasis: {
              focus: 'series',
            },
          },
          {
            name: 'Failed',
            type: 'line',
            data: props.ScaTrendData.map(item => item.failed_count),
            itemStyle: {
              color: '#A30000', // Red for active, Green for solved
            },
            emphasis: {
              focus: 'series',
            },
          },
          {
            name: 'Not Applicable',
            type: 'line',
            data: props.ScaTrendData.map(item => item.not_applicable),
            itemStyle: {
              color: '#0066CC', // Red for active, Green for solved
            },
            emphasis: {
              focus: 'series',
            },
          }
        ]
      };
      option && myChart.setOption(option);
      myChart.on('legendselectchanged', function (param) {
        handleFilter('data.sca.check.result', param.name.toLowerCase())
      });
      const resizeChart = () => {
        if (myChart) { // Check if chart exists before resizing
          myChart.resize();
        }
      };

    window.addEventListener('resize', resizeChart);
    return () => {
        myChart.dispose();
        window.removeEventListener('resize', resizeChart);
    };

    }

  }, [props.ScaTrendData, props.ScaTrendlabel, props.StartDateTime, props.EndDateTime, 
    props.filterformdata, props.theme,props.sidebarState])
  return <div className="container">
    <div id={id + 'vst'} style={{ width: '100%', height: '290px' }} ></div>
  </div>;
}
const mapStateToProps = (state) => ({
  dates: { startDate: state.startdate, endDate: state.enddate },
  filterformdata: state.FilterData,
  theme: state.theme,
  sidebarState:state.sidebarstate
  // mapStateToProps content remains unchanged if you have it
});

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(ScaPFTrend)
