import React, { useEffect, useState } from "react";
import {
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    Table,
    InnerScrollContainer,
} from "@patternfly/react-table";
import { Badge, Button, Divider, Label, LabelGroup, List, ListItem, Pagination, Title, Toolbar, ToolbarContent, ToolbarItem, Tooltip, } from "@patternfly/react-core";
import { connect, useDispatch } from "react-redux";
import { FilterData } from "../../Redux/Action";
import { UpdateFilterData } from "../../util/FilterHelper";
import { formatTableTimestamp } from "src/util/Timeutils";
import { exportToExcel } from "src/util/FilterHelper";
import { DownloadIcon } from "@patternfly/react-icons";

const SecurityAlertDesc = (props) => {
    const dispatch = useDispatch();
    const columns = ["Timestamp", "Rule Level", "Technique(s)", "Tactic(s)", "Rule Description", "Count", "Rule Group"]
    // const [defaultRows, setDefaultRows] = useState([]);
    // const [rows, setRows] = useState([]);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [selectedRowName, setSelectedRowName] = useState('');
    const excludeOrder = ["Technique(s)", "Tactic(s)", "Rule Description", "Rule Group"]

    const handleFilter = (fields, value) => {
        const updatedFields = UpdateFilterData(props.filterformdata, fields, 'is', value);
        console.log(updatedFields)
        dispatch(FilterData(updatedFields));
    }


    const rows = props.Tabledata.map((log) => {
        const firstMitreID = log.Description?.[0]?.Timestamp?.[0]?.Mitre_ID?.[0] || null;
        return {
            // [0].Description[0].Timestamp[0].Mitre_ID[0].Tactic
            timestamp: log.Description?.[0]?.Timestamp?.[0]?.key_as_string,
            ruleLevel: log.Description?.[0]?.Critical_levels?.[0]?.key,
            mitre: log.Description?.[0]?.Timestamp?.[0]?.Mitre_ID, // techniques: '',
            tactics: firstMitreID,
            ruledesc: log.Description?.[0]?.key,
            count: log.Description?.[0]?.doc_count,
            ruleId: log.key,
            agents: log.Description?.[0]?.Agent_name,
            rulegroups: log.Description?.[0]?.groups,

        };

    });
    // alert(rows.length)
    // console.log("rows", rows)

    const sortRows = (rows, sortIndex, sortDirection) => [...rows].sort((a, b) => {
        let returnValue = 0;
        // console.log(typeof Object.values(a)[0])
        //    if (sortIndex === 0 || sortIndex === 6) {
        //   returnValue = 1;
        // } 
        if (sortIndex === 0) {
            // console.log(1)
            // console.log(new Date(Object.values(b)[sortIndex]))
            let diff = new Date(Object.values(b)[sortIndex]) - new Date(Object.values(a)[sortIndex])
            returnValue = new Date(diff)
            // console.log("Dates",new Date(returnValue))
            // return new Date(returnValue)
        }
        if (typeof Object.values(a)[sortIndex] === 'number') {
            // alert(typeof Object.values(a)[sortIndex])
            returnValue = Object.values(b)[sortIndex] - Object.values(a)[sortIndex];
        } else {
            // alert(3)
            // alert(typeof Object.values(a)[sortIndex])
            returnValue = Object.values(b)[sortIndex].localeCompare(Object.values(a)[sortIndex]);
        }
        if (sortDirection === 'asc') {
            return returnValue * -1;
            // returnValue = Object.values(a)[sortIndex] - Object.values(b)[sortIndex];
        }
        return returnValue;

    });

    const [sortedData, setSortedData] = useState([...sortRows(rows, 1, 'desc')]);
    const [sortedRows, setSortedRows] = useState([...sortedData]);
    const [activeSortIndex, setActiveSortIndex] = useState(1);
    const [activeSortDirection, setActiveSortDirection] = useState('desc');

    const onSort = (_event, index, direction) => {
        setActiveSortIndex(index);
        setActiveSortDirection(direction);
        setSortedData(sortRows(rows, index, direction));
    };
    // console.log('sortedRows', sortedRows)
    React.useEffect(() => {
        const startIndex = (page - 1) * perPage;
        const endIndex = startIndex + perPage;
        setSortedRows(sortedData.slice((page - 1) * perPage, page * perPage));
        // setSortedRows(sortedData.slice(startIndex,endIndex));
    }, [sortedData, page, perPage]);


    const handleSetPage = (_evt, newPage,) => {
        setPage(newPage);
    };
    const handlePerPageSelect = (_evt, newPerPage, newPage) => {
        setPage(newPage);
        setPerPage(newPerPage);
    };

    const renderPagination = (variant) =>
        <Pagination
            itemCount={rows.length}
            page={page}
            perPage={perPage}
            variant={variant}
            // variant={PaginationVariant.variant}
            onSetPage={handleSetPage}
            onPerPageSelect={handlePerPageSelect}
        />;
    const renderRuleLevelcolumn = (ruleLevel) => {
        let tooltipContent = "";
        let badgeStyle = {};
        if (ruleLevel > 6 && ruleLevel < 12) {
            tooltipContent = "Medium";
            badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#F0AB00" };
        }
        else if (ruleLevel >= 12) {
            tooltipContent = "Critical";
            badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#A30000 " };
        }
        else {
            tooltipContent = "low";
            badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#0066CC " };
        }
        return (
            <Tooltip content={tooltipContent}>
                <div><Badge style={badgeStyle}><a className='pf-v5-u-color-light-100' onClick={() => { handleFilter('rule.level', `${ruleLevel}`) }}>{ruleLevel}</a></Badge></div>
            </Tooltip>
        );

    };
    const tableData = rows.map(row => {
        const { formattedTimeStamp } = row?.timestamp ? formatTableTimestamp(row?.timestamp) : { formattedTimeStamp: null };
        const agents = row?.agents?.map(agent => agent.key).join(', ') || '';
        const tactics = row?.tactics?.Tactic?.map(tactic => tactic.key).join(', ') || '';
        const techniques = row?.mitre.map(tech => tech.key).join(', ') || '';
        const groups = row?.rulegroups.map(grp => grp.key).join(', ') || '';
        return {
            'Rule Description': row?.ruledesc,
            'Affected Assets': agents,
            'Rule Level': row?.ruleLevel,
            "Tactic(s)": tactics,
            'Technique(s)': techniques,
            "Rule Group": groups,
            "Timestamp": formattedTimeStamp,
            'Count': row?.count
        }
    });

    return (
        <React.Fragment>
            <>
                <Toolbar>
                    <ToolbarContent>
                        <ToolbarItem>
                            <Title headingLevel="h3">Security Alerts</Title>
                        </ToolbarItem>
                        <ToolbarItem align={{ default: 'alignRight' }}>
                            <Button onClick={() => exportToExcel(tableData, Object.keys(tableData[0]), 'securityalerts.xlsx')} icon={<DownloadIcon />} size="sm" isInline>Download Excel</Button>
                        </ToolbarItem>
                    </ToolbarContent>
                </Toolbar>
                <InnerScrollContainer>
                    <Table variant="compact" aria-label="IPlog" id="IPlog-table" isStickyHeader gridBreakPoint="grid-md">
                        <Thead  >
                            <Tr key={'cart-table-header'}>
                                {columns.map((column, columnIndex) => {
                                    const stickyColumnsIndex = [0, 1];
                                    const isSticky = stickyColumnsIndex.includes(columnIndex);
                                    const sortParams = {
                                        sort: {
                                            sortBy: {
                                                index: activeSortIndex,
                                                direction: activeSortDirection
                                            },
                                            onSort,
                                            columnIndex
                                        }
                                    };
                                    return !excludeOrder.includes(column) ? <Th key={columnIndex} {...sortParams} isStickyColumn={isSticky} modifier='wrap'>{column}</Th> : <Th modifier='wrap' key={columnIndex} isStickyColumn={isSticky}>{column}</Th>;
                                })}
                            </Tr>
                        </Thead>
                        <Tbody key={'tablebody'}>
                            {sortedRows.length > 0 && sortedRows.map((row, rowIndex) => {
                                const { formattedTimeStamp } = row?.timestamp ? formatTableTimestamp(row?.timestamp) : { formattedTimeStamp: null };//localStorage.getItem("timezone")
                                return (
                                    <Tr key={rowIndex + 'row'}
                                        onRowClick={() => setSelectedRowName(rowIndex)}
                                        isSelectable
                                        isClickable
                                        isRowSelected={selectedRowName === rowIndex}
                                        className={row?.ruleLevel >= 12 ? "pf-v5-u-background-color-danger" : ""}
                                    >

                                        <Td width={20} dataLabel={columns[0]} key={rowIndex + 'timestamp'}>{formattedTimeStamp}</Td>
                                        <Td dataLabel={columns[1]} key={rowIndex + 'rulelevel'}>{renderRuleLevelcolumn(row?.ruleLevel)}</Td>
                                        <Td dataLabel={columns[2]} >
                                            {row?.mitre !== undefined && row?.mitre !== null ?
                                                (<List isPlain>
                                                    {row?.mitre?.map((item, index) => (
                                                        <ListItem key={index}> <Badge key={index}><a className='pf-v5-u-color-light-100' onClick={() => { handleFilter('rule.mitre.id', item.key) }}>{item.key}</a> </Badge></ListItem>
                                                    ))}
                                                </List>) : (" ")}
                                        </Td>
                                        <Td dataLabel={columns[3]} >
                                            {row?.tactics !== undefined && row?.tactics !== null ?
                                                (<List isPlain >
                                                    {row?.tactics?.Tactic.map((item, index) =>
                                                        (<ListItem key={index} style={{ color: "#0066CC" }}>{item.key}</ListItem>)
                                                    )
                                                    } </List>)
                                                : (" ")}</Td>
                                        <Td width={50} dataLabel={columns[4]} key={rowIndex + 'ruledesc'}>
                                            <a className="pf-v5-u-color-100" onClick={() => { handleFilter('rule.id', row?.ruleId) }}>{row?.ruledesc}</a> {row?.agents !== undefined && row?.agents !== null ?
                                                (<LabelGroup numLabels={2} isCompact>
                                                    {row?.agents.map((item, index) => (
                                                        <Label isCompact key={"label" + index} onClick={() => { handleFilter('agent.name', item?.key) }} color='blue'>{item.key}</Label>
                                                    ))}
                                                </LabelGroup>) : null}</Td>
                                        <Td width={10} dataLabel={columns[5]} key={rowIndex + 'doccount'}><a onClick={() => { handleFilter('rule.id', row?.ruleId) }} >{row?.count}</a></Td>
                                        {/* <Td width={10} dataLabel={columns[6]} key={rowIndex + 'ruleid'}>{row?.ruleId} </Td> */}
                                        <Td dataLabel={columns[6]} key={rowIndex + 'rulegroup'}>
                                            {row?.rulegroups !== undefined && row?.rulegroups !== null ?
                                                <List isPlain>
                                                    {row?.rulegroups?.map((item, index) =>
                                                    (<Badge key={index}><ListItem key={"li" + index}><a className='pf-v5-u-color-light-100' onClick={() => { handleFilter('rule.groups', item.key) }}>{item.key}</a></ListItem></Badge>
                                                    ))} </List>
                                                : (" ")}
                                        </Td>
                                    </Tr>
                                )
                            }
                            )}
                        </Tbody>
                    </Table>
                </InnerScrollContainer>
                <div className="pf-v5-u-mt-sm" width='50px'>{renderPagination('bottom')}</div>
            </>
        </React.Fragment>
    );
}
const mapStateToProps = (state) => ({
    dates: { startDate: state.startdate, endDate: state.enddate },
    filterformdata: state.FilterData,
    // mapStateToProps content remains unchanged if you have it
});

export default connect(
    mapStateToProps,
    null,
    null,
    { forwardRef: true }
)(SecurityAlertDesc)
