import React, { useState, useRef, useEffect, useMemo } from 'react';
import { MenuToggle, Popper, Toolbar, ToolbarContent, ToolbarItem, Pagination, Flex, FlexItem, Button, Select, SelectList, SelectOption, SelectGroup, SearchInput, Tooltip, Card, CardBody, Icon, ModalVariant, OverflowMenu, OverflowMenuGroup, OverflowMenuItem, OverflowMenuDropdownItem, Dropdown, DropdownList, ToolbarToggleGroup, ToolbarGroup, ToolbarFilter, DataList, DataListItem, DataListItemRow, DataListCheck, DataListItemCells, DataListCell, Modal, TextContent } from '@patternfly/react-core';
import { Table, Thead, Tr, Th, Tbody, Td } from '@patternfly/react-table';
import { EllipsisVIcon, FilterIcon, LinkIcon, PlayCircleIcon, PlusCircleIcon, SortAmountDownIcon, StarIcon, TrashIcon, } from '@patternfly/react-icons';
import { useParams } from 'react-router-dom';
import dbApi from 'src/services/DbApi';
import { EmptystateforIncident, Emptystatefornodata } from 'src/util/EmptyStateCard';
import { Loader } from 'src/util/Loadingstate';
import { useDispatch } from 'react-redux';
import { alertBoxData } from 'src/Redux/Action';
import ConfirmBox from 'src/Components/ConfirmBox/ConfirmBox';
import ModalCommon from 'src/Components/CommonEventPages/ModalCommon';
import IncidentObservableModal from '../incidentObservable/IncidentObservableModal';
import IncidentTtModal from './IncidentTtpModal';
import { formatTableTimestamp } from 'src/util/Timeutils';



const IncidentTtpList = () => {
    const dispatch = useDispatch();
    const columns = {
        // incidentId: 'incidentId',
        tactic: 'Tactic',
        technique: 'Technique',
        sub_technique: 'SubTechnique',
        // ttpProcedure: 'TtpProcedure',
        last_datetime: 'Last Date and Time',
    };

    const orderColumns = [
        {
            label: 'Tactic',
            col: 'tactic'
        },
        {
            label: 'Technique',
            col: 'technique'
        },
        {
            label: 'SubTechnique',
            col: 'sub_technique'
        },
        {
            label: 'Last Date and Time',
            col: 'last_datetime'
        }
    ]
    const defaultColumns = ['Tactic', 'Technique', ' SubTechnique', 'Last Date and Time'];
    const defaultRows = [];
    const excludeOrder = ['SubTechnique']
    const excludeWrapCol = ['Last Date and Time']
    const { id } = useParams();
    const childTaskDetailModalref = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [activeSortIndex, setActiveSortIndex] = useState(3);
    const [activeSortDirection, setActiveSortDirection] = useState('DESC');
    const [isSortDropdownOpen, setIsSortDropdownOpen] = useState(false);
    const [totalItem, setTotalItem] = useState(0);
    const [searchValue, setSearchValue] = useState('');
    //  tactic, technique, sub_technique, last_datetime
    const [tactic, setTactic] = useState();
    const [technique, settechnique] = useState();
    const [subTechnique, setsetTechique] = useState();
    const [lastdatetime, setLastDateTime] = useState();
    const [isFilterDropdownOpen, setIsFilterDropdownOpen] = useState(false);
    const [loadDelete, setLoadDelete] = useState(false);
    const [isKebabDropdownOpen, setIsKebabDropdownOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [currentCategory, setCurrentCategory] = useState('Name');
    const [filters, setFilters] = useState([]);
    const [filteredColumns, setFilteredColumns] = useState(defaultColumns);
    const [filteredRows, setFilteredRows] = useState(defaultRows);
    const [managedColumns, setManagedColumns] = useState(defaultColumns);
    const [managedRows, setManagedRows] = useState(defaultRows);
    const [checkedState, setCheckedState] = useState(Array(defaultColumns.length).fill(true));
    const [paginatedRows, setPaginatedRows] = useState([]);
    const [dataFilters, setDataFilters] = useState({
        name: [],
    })

    const [confirmModalData, setConfirmBox] = useState({
        show: false,
        msg: '',
        funCall: '',
        funParam: {},
        title: '',
        position: '',
        iconvariant: '',
        submitbtnvariant: '',
    });
    const [selectedRows, setSelectedRows] = useState([]);

    const isRowSelected = row => selectedRows.includes(row.unique_id);
    const setRowSelected = (row, isSelecting) => {
        setSelectedRows(prevSelected => {
            const otherSelectedRows = prevSelected.filter(r => r !== row.unique_id);
            const newSelectedRows = isSelecting ? [...otherSelectedRows, row.unique_id] : otherSelectedRows;
            console.log('Selected Row IDs:', newSelectedRows); // Log the selected row IDs
            return newSelectedRows;
        });
    };
    //render icons 
    const renderIcon = (value, IconComponent, tooltipContent, color, disabledColor) => {
        const isDisabled = value === 0;
        const iconStyle = isDisabled ? { color: disabledColor, cursor: 'not-allowed' } : { color, cursor: 'pointer' };

        return isDisabled ? (
            <IconComponent style={iconStyle} className='pf-v5-u-mr-sm' />
        ) : (
            <Tooltip content={tooltipContent}>
                <IconComponent style={iconStyle} className='pf-v5-u-mr-sm' />
            </Tooltip>
        );
    };
    const getTtpList = async () => {
        let orderCol = 'last_datetime';
        if (activeSortIndex > 0) {
            const orderResult = orderColumns.find((obj) => obj.label == filteredColumns[activeSortIndex])
            orderCol = orderResult.col;
            console.log('orderResult', orderResult);
            console.log('orderCol', orderCol)
        }
        const data = {
            limitStart: (perPage * page) - perPage,
            limitLength: perPage,
            sortByCol: orderCol,
            sortOrder: activeSortDirection.toUpperCase(),
            search: dataFilters.name.length > 0 ? dataFilters.name[0] : '',
            incidentId: id,
        };

        try {
            setIsLoading(true);
            const res = await dbApi.postApi('incident_management/ttp_list', data);
            console.log('TTP_list', res);
            if (res && res.data) {
                setRows(res.data || []);
                setTotalItem(res.totalRecord);
                setManagedRows(res.data);
                setPaginatedRows(res.data);
            } else {
                setRows([]);
                setTotalItem(0);
                setManagedRows([]);
                setPaginatedRows([]);
            }
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching incidents:', error);
            setRows([]);  // Ensure rows is always an array even on error
            setTotalItem(0);  // Ensure totalItem is always a number even on error
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getTtpList();
    }, [page, perPage, activeSortIndex, activeSortDirection, searchValue, dataFilters]);


    const onSort = (event, index, direction) => {
        console.log('sortIndex', activeSortIndex)
        setActiveSortIndex(index);
        setActiveSortDirection(direction);
    };

    const handleSetPage = (_evt, newPage) => {
        setPage(newPage);
    };

    const handlePerPageSelect = (_evt, newPerPage, newPage) => {
        setPerPage(newPerPage);
        setPage(newPage);
    };

    const renderPagination = () => (
        <Pagination
            itemCount={totalItem}
            page={page}
            perPage={perPage}
            onSetPage={handleSetPage}
            onPerPageSelect={handlePerPageSelect}
        />
    );

    const matchCheckboxNameToColumn = (name) => {
        switch (name) {
            case 'check1':
                return 'Tactic';
            case 'check2':
                return 'Technique';
            case 'check3':
                return 'SubTechnique';
            case 'check4':
                return 'Last Date and Time';
        }
    };
    const matchSelectedColumnNameToAttr = (name) => {
        switch (name) {
            case 'Tactic':
                return 'tactic';
            case 'Technique':
                return 'technique';
            case 'SubTechnique':
                return 'sub_technique';
            case 'Last Date and Time':
                return 'last_datetime';

        }
    };

    const selectAllColumns = () => {
        unfilterAllData();
        setCheckedState(Array(defaultColumns.length).fill(true));
    };

    const handleModalToggle = (_event) => {
        setIsModalOpen(!isModalOpen);
    };

    const onSave = () => {
        setManagedColumns(filteredColumns);
        console.log("filteredColumns")
        console.log(filteredColumns)
        setPaginatedRows(filteredRows);
        setIsModalOpen(!isModalOpen);
    };
    const removePropFromObject = (object, keys) =>
        keys.reduce((obj, prop) => {
            const { [prop]: _, ...keep } = obj;
            return keep;
        }, object);

    const filterData = (checked, name) => {
        const selectedColumn = matchSelectedColumnNameToAttr(name);
        const filteredRows = [];
        if (checked) {
            const updatedFilters = filters.filter((item) => item !== selectedColumn);
            // Only show the names of columns that were selected in the modal
            const filteredColumns = defaultColumns.filter(
                (column) => !updatedFilters.includes(matchSelectedColumnNameToAttr(column))
            );
            // Remove the attributes (i.e. "columns") that were not selected
            managedRows.forEach((item) => filteredRows.push(removePropFromObject(item, updatedFilters)));

            setFilters(updatedFilters);
            setFilteredColumns(filteredColumns);
            setFilteredRows(filteredRows);
        } else {
            let updatedFilters = filters;
            if (typeof selectedColumn != 'undefined') {
                updatedFilters.push(selectedColumn);
            }

            // Only show the names of columns that were selected in the modal
            const filteredColumns = managedColumns.filter(
                (column) => !filters.includes(matchSelectedColumnNameToAttr(column))
            );

            // Remove the attributes (i.e. "columns") that were not selected
            managedRows.forEach((item) => filteredRows.push(removePropFromObject(item, updatedFilters)));

            setFilters(updatedFilters);
            setFilteredColumns(filteredColumns);
            setFilteredRows(filteredRows);
        }
    };

    const unfilterAllData = () => {
        setFilters([]);
        setFilteredColumns(defaultColumns);
        setFilteredRows(managedRows);
    };


    const handleChange = (event, checked) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        // Remove any columns from the table that aren't checked
        filterData(checked, matchCheckboxNameToColumn(target.name));
        const checkedIndex = defaultColumns.findIndex((element) => element === matchCheckboxNameToColumn(target.name));
        const updatedCheckedState = [...checkedState];
        updatedCheckedState[checkedIndex] = value;
        setCheckedState(updatedCheckedState);
    };

    const finalRemoveTask = async () => {
        try {
            setIsLoading(true);
            console.log({ taskUniqueId: selectedRows });
            const ret = await dbApi.deleteApi('incident_management/delete_ttp', { uniqueId: selectedRows });
            let type = 'danger';
            if (!ret.error) {
                type = 'success';
                getTtpList();
            }
            dispatch(alertBoxData([{ title: ret.message, variant: type }]));
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }


    const removeTask = () => {
        setConfirmBox({
            show: true,
            title: 'Permanently delete alert?',
            msg: "Are you sure you want to delete this alert?",
            funCall: finalRemoveTask,
            funParam: {},
            position: 'default',
            iconvariant: 'warning',
            submitbtnvariant: 'danger',
        })
    }

    const tableActionItems = [
        <OverflowMenuDropdownItem key="1" onClick={() => handleModalToggle()}>  Manage Columns</OverflowMenuDropdownItem>,
        <OverflowMenuDropdownItem key="2" onClick={() => removeTask()} isDisabled={selectedRows.length == 0}>  Remove</OverflowMenuDropdownItem>,
    ];

    const tableAction =
        <Dropdown onSelect={() => setIsKebabDropdownOpen(!isKebabDropdownOpen)} onOpenChange={isKebabDropdownOpen => setIsKebabDropdownOpen(isKebabDropdownOpen)} toggle={toggleRef => <MenuToggle ref={toggleRef} aria-label="overflow menu" variant="plain" onClick={() => setIsKebabDropdownOpen(!isKebabDropdownOpen)} isExpanded={false}>
            <EllipsisVIcon />
        </MenuToggle>} isOpen={isKebabDropdownOpen}>
            <DropdownList >{tableActionItems}</DropdownList>
        </Dropdown>


    const onFilterToggle = () => {
        setIsFilterDropdownOpen(!isFilterDropdownOpen)
    };


    const onFilterSelect = (event, selection, type, chipType) => {
        const checked = event.target.checked;
        let text = event.target.innerText
        setIsFilterDropdownOpen(false)
        let temp = dataFilters
        temp[type][0] = selection
        temp[chipType][0] = text
        setDataFilters(prevDataFilters => ({
            ...prevDataFilters,
            temp
        }));
    };


    const onCategorySelect = event => {
        setCurrentCategory(event.target.innerText)
        // setIsCategoryDropdownOpen(false)
        setIsFilterDropdownOpen(false)
    };



    const onDelete = (type = '', id = '') => {
        if (type) {
            let temp = dataFilters
            temp[type].splice(0, 1);
            if (type == "tlp") {
                temp['tlpChip'].splice(0, 1);
            }
            setDataFilters(prevDataFilters => ({
                ...prevDataFilters,
                temp
            }));
        } else {
            setDataFilters({ name: [] })
        }
    };

    const onInputChange = newValue => {
        setInputValue(newValue)
    };

    const onNameInput = event => {
        if (event.key && event.key !== 'Enter') {
            return;
        }
        let temp = dataFilters
        temp.name[0] = inputValue
        setDataFilters(prevDataFilters => ({
            ...prevDataFilters,
            temp
        }));
    };

    function buildFilterDropdown() {

        // const severityMenuItems = [
        //     <SelectOption key="1" value="L" isSelected={dataFilters.status.includes("L")}>Low</SelectOption>,
        //     <SelectOption key="2" value="M" isSelected={dataFilters.status.includes("M")}>Medium</SelectOption>,
        //     <SelectOption key="3" value="H" isSelected={dataFilters.status.includes("H")}>High</SelectOption>,
        //     <SelectOption key="4" value="C" isSelected={dataFilters.status.includes("C")}>Critical</SelectOption>
        // ];

        return <React.Fragment>

            <ToolbarFilter chips={dataFilters.name} deleteChip={onDelete} categoryName="name" showToolbarItem={currentCategory === 'Name'}>
                <SearchInput aria-label="name filter" placeholder="Filter by name..." onChange={(_event, value) => onInputChange(value)} value={inputValue} onClear={() => {
                    onInputChange('');
                }} onSearch={onNameInput} />
            </ToolbarFilter>
        </React.Fragment>;
    }

    // function buildCategoryDropdown() {
    //     const categoryMenuItems = [
    //         <SelectOption key="1" value="Name">Name</SelectOption>,
    //         // <SelectOption key="2" value="tlp">Tlp</SelectOption>
    //         // <SelectOption key="3" value="Severity">Severity</SelectOption>,

    //     ];
    //     return <ToolbarItem>
    //         <Select toggle={toggleRef => <MenuToggle ref={toggleRef} icon={<FilterIcon />} style={{
    //             width: '100%',
    //             verticalAlign: 'text-bottom'
    //         }}>
    //             {currentCategory}
    //         </MenuToggle>}>
    //             {/* {categoryMenuItems} */}
    //         </Select>
    //     </ToolbarItem>;
    // }
    function renderToolbar() {
        return <Toolbar id="toolbar-with-chip-groups" clearAllFilters={onDelete} collapseListedFiltersBreakpoint="xl">
            <ToolbarContent>
                <ToolbarToggleGroup toggleIcon={<FilterIcon />} breakpoint="xl">
                    <ToolbarGroup variant="filter-group" style={{
                        lineHeight: '22px',
                        alignItems: 'center'
                    }}>
                        {/* {buildCategoryDropdown()} */}
                        {buildFilterDropdown()}
                    </ToolbarGroup>
                </ToolbarToggleGroup>
                <ToolbarItem variant="overflow-menu">
                    <OverflowMenu breakpoint="md">
                        <OverflowMenuGroup groupType="button" isPersistent>
                            {/* <OverflowMenuItem>
                <Link to={'/user-profile'} className="route" ><Button variant="primary" size="sm">New</Button></Link>
              </OverflowMenuItem> */}
                            <OverflowMenuItem isPersistent>
                                {tableAction}
                            </OverflowMenuItem>
                        </OverflowMenuGroup>
                    </OverflowMenu>
                </ToolbarItem>
                <ToolbarItem variant="pagination">{renderPagination()}</ToolbarItem>
            </ToolbarContent>
        </Toolbar>;
    }

    const handleModalClose = async (isSuccess) => {
        if (isSuccess) {
            await getTtpList();
        }
        setIsLoading(false);
        // childTaskDetailModalref.current.onModalClose();
    };
    const tableHeader = useMemo(() => {
        return <Thead>
            <Tr>
                <Th key={'chk'} screenReaderText='key' />
                {/* <Th key={'status'} /> */}
                {managedColumns.map((column, columnIndex) => {
                    const sortParams = {
                        sort: {
                            sortBy: {
                                index: activeSortIndex,
                                direction: activeSortDirection
                            },
                            onSort,
                            columnIndex
                        }
                    };
                    return !excludeOrder.includes(column) ? <Th modifier={!excludeWrapCol.includes(column) && 'wrap'} key={columnIndex} {...sortParams} >{column}</Th> : <Th modifier={!excludeWrapCol.includes(column) && 'wrap'} key={columnIndex} >{column}</Th>;
                })}
            </Tr>
        </Thead>

    }, [activeSortIndex, activeSortDirection, managedColumns])

    const renderModal = () => {
        return (
            <Modal
                title="Manage columns"
                isOpen={isModalOpen}
                variant="small"
                description={
                    <TextContent>
                        <Button isInline onClick={selectAllColumns} variant="link">
                            Select all
                        </Button>
                    </TextContent>
                }
                onClose={handleModalToggle}
                actions={[
                    <Button key="save" variant="primary" onClick={onSave}>
                        Save
                    </Button>,
                    <Button key="cancel" variant="secondary" onClick={handleModalToggle}>
                        Cancel
                    </Button>
                ]}
            >
                <DataList aria-label="Table column management" id="table-column-management" isCompact>
                    {
                        defaultColumns.map((obj, index) => {
                            return <DataListItem key={index} aria-labelledby={"col-mgmt-item-" + index}>
                                <DataListItemRow>
                                    <DataListCheck
                                        aria-labelledby={"col-mgmt-item-" + index}
                                        checked={checkedState[index]}
                                        name={"check" + (index + 1)}
                                        id={"check" + index}
                                        onChange={handleChange}
                                    />
                                    <DataListItemCells
                                        dataListCells={[
                                            <DataListCell id={"col-mgmt-item-" + index} key={"col-mgmt-item-" + index}>
                                                <label htmlFor={"check" + index}>{obj}</label>
                                            </DataListCell>
                                        ]}
                                    />
                                </DataListItemRow>
                            </DataListItem>
                        })
                    }
                </DataList>
            </Modal>
        );
    };

    return (
        <>
            <Card>
                <CardBody>
                    <Flex className='pf-v5-u-mt-sm' justifyContent={{ default: 'justifyContentSpaceBetween' }}>
                        <Flex spaceItems={{ default: 'spaceItemsXs' }}>
                            <FlexItem>
                                <Button variant="primary" icon={<PlusCircleIcon />} onClick={() => childTaskDetailModalref.current.setModalBox(true)}> Add TTP</Button>
                            </FlexItem>
                        </Flex>
                    </Flex>
                </CardBody>
            </Card>
            {/* {tableToolbar} */}
            {renderToolbar()}
            {isLoading ? (
                <Loader />
            ) : (
                <Table aria-label="Incident Observable Table" variant="compact">
                    {tableHeader}
                    <Tbody>
                        {rows && rows.length > 0 ? (
                            paginatedRows.map((row, rowIndex) => {
                                const { formattedTimeStamp: lastDatetime } = formatTableTimestamp(row.last_datetime);

                                return (
                                    <Tr key={rowIndex + row.incident_id}>
                                        <Td
                                            key={"chk-" + rowIndex}
                                            select={{
                                                rowIndex,
                                                onSelect: (_event, isSelecting) => setRowSelected(row, isSelecting),
                                                isSelected: isRowSelected(row),
                                                isDisabled: row?.process_status == 1,
                                            }}
                                        />
                                        {
                                            typeof row?.tactic != 'undefined' &&
                                            <Td dataLabel={columns.tactic}>
                                                {row.tactic}
                                            </Td>
                                        }
                                        {
                                            typeof row?.technique != 'undefined' &&
                                            <Td dataLabel={columns.technique}>
                                                {row.technique}
                                            </Td>
                                        }
                                        {
                                            typeof row?.sub_technique != 'undefined' && <Td dataLabel={columns.sub_technique}>
                                                {row.sub_technique}
                                            </Td>
                                        }

                                        {
                                            typeof row?.last_datetime != 'undefined' &&
                                            <Td dataLabel={columns.last_datetime}>
                                                {lastDatetime}
                                            </Td>
                                        }

                                    </Tr>
                                );
                            })
                        ) : (
                            <Tr>
                                <Td colSpan={Object.keys(columns).length} key="empty-data">
                                    <EmptystateforIncident />
                                </Td>
                            </Tr>
                        )}
                    </Tbody>
                </Table>
            )}

            {/* {renderPagination()} */}
            {renderModal()}
            <ModalCommon
                title="Create new TTP(s)"
                ref={childTaskDetailModalref}
                variant={ModalVariant.medium}
                onclose={() => childTaskDetailModalref.current.setModalBox(false)}
            >
                <IncidentTtModal
                    onTaskAdded={getTtpList}
                    IncidentId={id}
                    onclose={() => childTaskDetailModalref.current.setModalBox(false)}
                    onModalClose={handleModalClose}
                />
            </ModalCommon>
            <ConfirmBox confirmModal={confirmModalData} />
        </>
    );
};
export default IncidentTtpList;
