import React, { useState } from 'react';
import { Alert, AlertGroup, AlertActionCloseButton, AlertVariant } from '@patternfly/react-core';
import { connect, useDispatch } from 'react-redux'
import { removeAlertData } from '../../Redux/Action'
const AlertBox = (props) => {
  const dispatch = useDispatch()
  const [alerts,setAlerts]=useState([]);
  const removeAlert = key => {
     dispatch(removeAlertData(key));
  };
  React.useEffect(() => {
       let alertData = props.alert.map(obj=>({title:obj.title,variant:obj?.variant != 'undefined' || obj?.variant != '' ? obj?.variant : 'success',key:obj.key}))
       setAlerts(alertData)
  }, [props?.alert])
  return alerts.length>0 && <AlertGroup isToast isLiveRegion>
    {alerts.map(({ key, variant, title }) =>
      <Alert variant={AlertVariant[variant]} title={title} timeout={4000}
        onTimeout={() => removeAlert(key)}
        actionClose={<AlertActionCloseButton title={title} variantLabel={`${variant} alert`}
          onClose={() => removeAlert(key)} />} key={key}
      />)}
  </AlertGroup>
};
const mapStateToProps = (state) => ({
  alert: state.alert
})
export default React.memo(connect(mapStateToProps)(AlertBox));