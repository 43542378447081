import React, {useState } from 'react';
import brandImg from '../../Assets/images/soc-brand-img.svg'
import bgImage from '../../Assets/images/black.jpeg'
import './Login.css';
import {
  Button,
  Form,
  FormGroup,
  TextInput,
  FormHelperText,
  HelperText,
  HelperTextItem,
  InputGroup,
  InputGroupItem,
  LoginPage,
} from '@patternfly/react-core';
import {
  EyeIcon,
  EyeSlashIcon,
} from '@patternfly/react-icons';
import { useNavigate, useParams } from 'react-router-dom';
import dbApi from '../../services/DbApi';
import { PASS_CHAR_RULE, PASS_LENGTH } from 'src/Constant/constants';
import { alertBoxData } from 'src/Redux/Action';
import { useDispatch } from 'react-redux';

const ResetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { etoken } = useParams();
  const [password, setPassword] = useState('');
  const [ruleLength, setRuleLength] = useState('indeterminate');
  const [ruleCharacters, setRuleCharacters] = useState('indeterminate');
  const [passwordHidden, setPasswordHidden] = useState(true);
  const [confirmpassword, setConfirmPassword] = useState('');
  const [validConfirmPass, setValidConfirmPass] = useState('default');

  const validateMainPassword = (password) => {
    if (password === '') {
      setRuleLength('indeterminate');
      setRuleCharacters('indeterminate');
      return 'indeterminate';
    }

    if (password.length < 8) {
      setRuleLength('error');
    } else {
      setRuleLength('success');
    }

    let rulesCount = 0;
    if (/[a-z]/g.test(password)) rulesCount++;
    if (/[A-Z]/g.test(password)) rulesCount++;
    if (/\d/g.test(password)) rulesCount++;
    if (/\W/g.test(password)) rulesCount++;

    if (rulesCount < 3) {
      setRuleCharacters('error');
      return 'error';
    } else {
      setRuleCharacters('success');
      return 'success';
    }
  };

  const validateConfirmPassword = (value) => {
    let status = value ? 'success' : 'error';
    if (status === 'success' && password !== value) {
      status = 'error';
    }
    setValidConfirmPass(status);
    return status;
  };

  const fieldChange = (value, type) => {
    switch (type) {
      case 'password':
        setPassword(value);
        validateMainPassword(value);
        break;
      case 'confirmpassword':
        setConfirmPassword(value);
        validateConfirmPassword(value);
        break;
      default:
        break;
    }
  };

  const formValid = () => {
    let ret = true;
    const passstatus = validateMainPassword(password);
    if (passstatus === 'error') {
      ret = false;
    }

    const status = validateConfirmPassword(confirmpassword);
    if (status === 'error') {
      ret = false;
    }
    return ret;
  };

  const resetPassword = async () => {
    if (formValid()) {
      const param = {
        token: etoken,
        password: confirmpassword,
      };
      try {
        const res = await dbApi.postApi('login/forgot_password', param);//login/forgot_password 
        console.log(res);
        if(!res.error){
          dispatch(alertBoxData([{ title: res.message, variant: "success" }]));
        }
        else{
          dispatch(alertBoxData([{ title: res.message, variant: "danger" }]));
        }
       
        navigate('/');
      } catch (error) {
        console.log(error);
      }
    }
  };

  const newPasswordForm = (
    <React.Fragment>
      <Form>
        <FormGroup label="New Password" isRequired fieldId="password">
          <InputGroup>
            <InputGroupItem isFill>
              <TextInput
                isRequired
                name="password"
                type={passwordHidden ? 'password' : 'text'}
                aria-label="Password input"
                value={password}
                onChange={(event, value) => fieldChange(value, 'password')}
              />
            </InputGroupItem>
            <InputGroupItem>
              <Button
                variant="control"
                onClick={() => setPasswordHidden(!passwordHidden)}
                aria-label={passwordHidden ? 'Show password' : 'Hide password'}
              >
                {passwordHidden ? <EyeIcon /> : <EyeSlashIcon />}
              </Button>
            </InputGroupItem>
          </InputGroup>
          <FormHelperText>
            <HelperText component="ul" aria-live="polite" id="password-field-helper">
              <HelperTextItem isDynamic variant={ruleLength} component="li">
                {PASS_LENGTH}
              </HelperTextItem>
              <HelperTextItem isDynamic variant={ruleCharacters} component="li">
                {PASS_CHAR_RULE}
              </HelperTextItem>
            </HelperText>
          </FormHelperText>
        </FormGroup>
        <FormGroup label="Confirm Password" isRequired fieldId="confirmpassword">
          <TextInput
            isRequired
            type="password"
            id="confirmpassword"
            name="confirmpassword"
            value={confirmpassword}
            validated={validConfirmPass}
            onChange={(event, value) => fieldChange(value, 'confirmpassword')}
          />
        </FormGroup>
        <br />
        <Button key="restpass" variant="primary" isBlock form="register-now" onClick={resetPassword}>
          Reset Password
        </Button>
      </Form>
    </React.Fragment>
  );

  return (
    <LoginPage
      brandImgSrc={brandImg}
      brandImgAlt="Brand logo"
      backgroundImgSrc={bgImage}
      textContent="SkyVirt® CyberRange is designed as a real-time emulation platform for conducting the training for full spectrum IT Infrastructure, Cyber Security & Digital Forensics."
      loginTitle="Reset Password"
    >
      {newPasswordForm}
    </LoginPage>
  );
};

export default ResetPassword;
