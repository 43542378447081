// import React from 'react';
// import { Chart, ChartAxis, ChartGroup, ChartLine, ChartScatter, ChartThemeColor, ChartVoronoiContainer, } from '@patternfly/react-charts';
// import {  getResizeObserver } from '@patternfly/react-core';
// import { calculateTimeDifference, formatTime, formatTimestamp } from '../../util/timeutils';
// import { emptystatefornodata } from '../../util/emptystate';

// export default class EventSummary extends React.Component {
//   constructor(props) {
//     super(props);
//     this.containerRef = React.createRef();
//     this.observer = () => { };
//     this.state = {
//       width: 0,
//       maxdoccount: 0,
//       xTickvalue: [],
//       alertsData: [],
//     };
//     this.handleResize = () => {
//       if (this.containerRef.current && this.containerRef.current.clientWidth) {
//         this.setState({ width: this.containerRef.current.clientWidth });
//       }
//     };
//   }

//   componentDidMount() {
//     this.observer = getResizeObserver(this.containerRef.current, this.handleResize);
//     this.handleResize();
//   }

//   componentDidUpdate(prevProps) {
//     let maxdoccount = 0
//     let xTickvalue = []
//     let alertsData = []
//     let xaxislabel = ""
//     if (this.props.EventSummarydata !== prevProps.EventSummarydata) {
//       const { EventSummarydata, EventSummarylabel, StartDateTime, EndDateTime } = this.props
//       const { resultInMinutes, resultInHours, resultInDays } = calculateTimeDifference(StartDateTime, EndDateTime);
//       if (EventSummarydata && EventSummarydata.length > 0) {
//         xaxislabel = EventSummarylabel
//       }
//       for (let i = 0; i < EventSummarydata.length; i++) {
//         // const datapoints = [];
//         const xvalue = EventSummarydata[i].key_as_string
//         const yvalue = EventSummarydata[i].doc_count
//         const { ConvertTimestamp, hours, minutes, seconds } = formatTimestamp(xvalue);

//         const formattedTime = formatTime(resultInMinutes, resultInHours, resultInDays, hours, minutes, seconds, ConvertTimestamp);

//         xTickvalue.push({
//           xlabel: formattedTime
//         })
//         alertsData.push({
//           x: formattedTime, y: parseFloat(yvalue)
//         })

//       }
//       for (const seriesData of alertsData) {
//         if (seriesData.y > maxdoccount) {
//           maxdoccount = seriesData.y
//         }
//       }

//       this.setState({
//         maxdoccount: maxdoccount,
//         xaxislabel: xaxislabel,
//         xTickvalue: xTickvalue,
//         alertsData: alertsData
//       })
//     }
//   }
//   componentWillUnmount() {
//     this.observer();
//   }

//   render() {
//     const { width, alertsData, maxdoccount, xTickvalue, xaxislabel } = this.state;
//     const formatValue = (value) => {
//       if (value >= 1000) {
//         return `${(value / 1000).toFixed(1)}K`;
//       }
//       else if (value >= 100000) {
//         return `${(value / 100000).toFixed(2)}Lac`;
//       }
//       else if (value >= 10000000) {
//         return `${(value / 10000000).toFixed(2)} Cr`
//       }
//       return value.toString();
//     };
//     const { EventSummarydata } = this.props
//     return (
//       <div ref={this.containerRef} >
//         {EventSummarydata.length === 0 ?
//           (
//             emptystatefornodata()
//           ) :
//           (<Chart
//             ariaDesc=""
//             ariaTitle=""
//             containerComponent={
//               <ChartVoronoiContainer
//                 labels={({ datum }) => datum.childName.includes('line') ? `Alerts: ${datum.y}` : null}
//                 constrainToVisibleArea
//               />
//             }
//             // domainPadding={{x:10}}
//             height={235}
//             name="EventSummary"
//             padding={{
//               bottom: 55,
//               left: 70,
//               right: 50,
//               top: 10
//             }}
//             maxDomain={{ y: maxdoccount + 100 }}
//             themeColor={ChartThemeColor.blue}
//             width={width}
//           >
//             <ChartAxis label={`Timestamp per ${xaxislabel}`} tickValues={xTickvalue} fixLabelOverlap />
//             <ChartAxis dependentAxis showGrid tickFormat={formatValue} />
//             <ChartGroup>
//               <ChartScatter data={alertsData} name="scatter" />
//             </ChartGroup>
//             <ChartGroup>
//               <ChartLine data={alertsData} name="line" />
//             </ChartGroup>
//           </Chart>)
//         }


//       </div>
//     );
//   }
// }


// ///////////////////////



import * as echarts from 'echarts';
import { useEffect } from 'react';
import { calculateTimeDifference, formatTime, formatTimestamp } from '../../util/Timeutils';
import { generateUniqueID } from '../../util/UniqueIdGenerator';
import { formatValue } from '../../util/ChartHelper';
import { connect } from 'react-redux';
const EventSummary = ({ EventSummarydata, EventSummarylabel, StartDateTime, EndDateTime, theme,sidebarState }) => {
  const id = generateUniqueID()
  useEffect(() => {
    if (EventSummarydata && EventSummarydata.length > 0) {
      const chartDom = document.getElementById(id + 'imes');
      const myChart = echarts.init(chartDom);
      const textcolor = theme == 'dark-theme' ? '#fff' : '#000'
       const linecolor = theme == 'dark-theme' ? '#595757' : '#d9d9d9'
      const { resultInMinutes, resultInHours, resultInDays } = calculateTimeDifference(StartDateTime, EndDateTime);
      const xAxisData = []
      const Label = EventSummarylabel;
      const uniqueDatesSet = new Set();
      for (let i = 0; i < EventSummarydata.length; i++) {
        const xvalue = EventSummarydata[i].key_as_string;
        uniqueDatesSet.add(xvalue);
      }

      const uniqueDatesArray = [...uniqueDatesSet];
      for (let i = 0; i < uniqueDatesArray.length; i++) {
        const { ConvertTimestamp, hours, minutes, seconds } = formatTimestamp(uniqueDatesArray[i]);
        const formattedTime = formatTime(resultInMinutes, resultInHours, resultInDays, hours, minutes, seconds, ConvertTimestamp);
        xAxisData.push({ x: formattedTime });
      }

      let interval = ''
      if (Label === '30 seconds') {
        interval = 4
      }
      else if (Label === 'hour') {
        interval = 3
      }
      else {
        interval = 3
      }

      const seriesData = EventSummarydata.map((item) => item.doc_count);
      const option = {
        tooltip: {
          trigger: 'axis',
          textStyle: {
            color: "#000"
          }
        },
        title: {
          text: `Timestamp per ${Label}`,
          top: 'bottom',
          left: 'center',
          textStyle: {
            fontWeight: 450,
            fontSize: 14,
            color: textcolor,
            fontfamily: 'RedHatText, helvetica, arial, sans-serif',
          },
        },
        grid: {
          left: '2%',
          right: '10%',
          bottom: '10%',
          top: '10%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: true,
          axisLabel: { interval: interval, rotate: -50, color: textcolor },
          data: [...new Set(xAxisData.map(item => item.x))],
          axisTick: {
            alignWithLabel: true
          },

        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: formatValue,
            color: textcolor
          }, 
          splitLine: {
            lineStyle: {
              color: linecolor
            }
          },
          axisLine: {
            show: true
          },
        },
        series: [
          {
            data: seriesData,
            type: 'line',
            name: 'alert'

          }
        ]
      };

      option && myChart.setOption(option);
      const resizeChart = () => {
        if (myChart) { // Check if chart exists before resizing
          myChart.resize();
        }
      };

    window.addEventListener('resize', resizeChart);
    return () => {
        myChart.dispose();
        window.removeEventListener('resize', resizeChart);
    };
    }

  }, [EventSummarydata, EventSummarylabel, StartDateTime, EndDateTime, theme,sidebarState])
  return <div className="container">
    <div id={id + 'imes'} style={{ width: '100%', height: '290px' }} ></div>
  </div>;

}


const mapStateToProps = (state) => ({
  dates: { startDate: state.startdate, endDate: state.enddate },
  filterformdata: state.FilterData,
  theme: state.theme,
  sidebarState:state.sidebarstate
  // mapStateToProps content remains unchanged if you have it
});

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(EventSummary)


// return <> <div className="container">
//     {EventSummarydata.length == 0 ?
//       (<>
//         <div id={id + 'imes'}> </div>
//         < Emptystatefornodata />
//       </>)
//       : (
//         <div id={id + 'imes'} style={{ width: '100%', height: "250px" }} > </div>
//       )
//     }
//   </div>
//   </>