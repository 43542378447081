import React from "react";
import { createRoot } from 'react-dom/client';
import "@patternfly/react-core/dist/styles/base.css";
import App from "./App";
import { Provider } from "react-redux";
import store from "./Redux/Store";
import AlertBox from "./Components/AlertBox/AlertBox";

// Add event listener to disable PWA install popup
window.addEventListener('beforeinstallprompt', (e) => {
   // Prevent the mini-infobar from appearing on mobile
   e.preventDefault();
   console.log('PWA install prompt disabled.');
   // Optionally store the event if you want to trigger it later
   // window.deferredPrompt = e;
 });
// const store = configureStore(changeState);
createRoot(document.getElementById('root')).render(
   <Provider store={store}>
      <App/>
      <AlertBox />
 </Provider>
);
//document.addEventListener('touchstart', handler, true);